<template>
    <div class="home">

        <div class="top-search">
            <el-form :model="form" class="demo-form-inline">
                <div class="select-line bostrap" :style="{height:(hide==false ?'43px':'auto')}" ref="searchLine">
                <!-- <div class="select-line bostrap" :style="{height:'auto'}" ref="searchLine"> -->
                    <el-form-item label="创建人" >
                        <el-select v-model="form.create_id" placeholder="请选择" clearable filterable>
                            <el-option v-for="item in createList" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="支出公司">
                        <el-select v-model="form.company" placeholder="请选择" clearable filterable>
                            <el-option v-for="item in companyList" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所属部门">
                        <!-- <el-cascader class="cascader" popper-class="ssdepart cascader-branch-select" placeholder="请选择" :options="branchList"
                            :show-all-levels="false" v-model="form.branch_id" :props="{ value: 'id', label: 'label',checkStrictly: true, }"
                            clearable>
                        </el-cascader> -->
                        <el-select v-model="form.branch_id" placeholder="请选择" clearable filterable>
                            <el-option v-for="item in branchList" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="开票人">
                        <el-select v-model="form.payer_id" placeholder="请选择" clearable>
                            <el-option v-for="item in openList" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="创建时间">
                        <el-date-picker v-model="form.createTime" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                </div>
                <div class="line"></div>
                <div class="btn-line">
                    <div class="btnpart">
                        <el-button class="confirm" @click="getList('search')" plain v-permission="'开票人设置-select'">查询</el-button>
                        <el-button class="cancel" @click="clearSeach" plain v-permission="'开票人设置-reset'">重置</el-button>
                    </div>
                    <!-- <div v-if="ishide" class="showpart hover" @click="showHide">
                        {{ actText }}
                        <local-svg iconClass="showdown" class="icon" :class="hide?'acticon':''" ></local-svg>
                    </div> -->
                </div>
            </el-form>
        </div>
        <div class="tablepart">
            <div class="top">
                <div class="span">开票人列表</div>
                <div class="btnlist btnpart">
                    <el-button class="iconbtn" @click="beginAdd" plain v-permission="'开票人设置-create'"><local-svg iconClass="add"
                            class="icon"></local-svg>新建</el-button>
                </div>
            </div>
            <memberTable :list="tableData" @transfer="wantAdd" @edit="wantEdit"
                @delete="deleteUser"></memberTable>
            <div class="pagetab">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <addUser v-if="dialogAdd" :show="dialogAdd" @adduser="addUser" :type="type"  :form="editform" @edituser="deitUser"
            @close="cancelAdd"></addUser>
    </div>
</template>

<script>
    import { alladmin } from '../houseCollection/api'
    import {branch} from '@/api/common'
    import {select,openbill,adddrawer,drawerdetail,deitdrawer,deldrawer,openuser} from '@/api/company'
    import memberTable from "@/components/company/table.vue"
    import addUser from '@/components/company/change.vue'
    export default{
        name:'member-terrace',
        data(){
            return{
                currentPage:1,
                pageSize:10,
                total:0,
                dialogAdd:false,
                hide:false,
                createList:[],
                branchList:[],
                companyList:[],
                openList:[],
                statusList:[
                    {name:'开启',id:1},
                    {name:'禁用',id:0},
                ],
                addform:{},
                editform:{},
                actText:'展开',
                form:{
                    create_id:'',
                    company:'',
                    branch_id:[],
                    payer_id:'',
                    createTime:[]
                },
                tableData:[],
                ishide:true,
                type:1
            }
        },
        components:{
            memberTable,
            addUser
        },
        mounted(){
            this.getList()
            this.getSelect()
            this.getAllUser()
            this.getOpen()
            // this.getBranchList()
        },
        methods:{
            getOpen(){
                openuser().then(res=>{
                    this.openList = res.data.drawer
                    this.companyList = res.data.company
                })
            },
            getAllUser(){
                alladmin().then(res=>{
                    this.createList = res.data
                })
            },
            getSelect(){
                select().then(res=>{
                    // this.openList = res.data.drawer
                    this.branchList = res.data.branch
                })
            },
            // getBranchList(){
            //     branch().then(res=>{
            //         this.branchList = res.data
            //     })
            // },
            deleteUser(msg){
                let data = {
                    id:msg
                }
                deldrawer(data).then(res =>{
                    if(res.code==200){
                        this.$message({
                            message:'删除成功',
                            type:'success'
                        })
                        this.getList()
                    }else{
                        this.$message({
                            message:res.msg,
                            type:'error'
                        })
                    } 
                })
            },
            clearSeach(){
                this.form = {
                    create_id:'',
                    company:'',
                    branch_id:[],
                    payer_id:'',
                    createTime:[]
                }
                this.getList('search')
            },
            // 新建用户
            beginAdd(){
                this.dialogAdd = true
            },
            // 获取用户列表
            getList(e){
                if(e){
                    this.currentPage = 1
                }
                let b_id = ''
                if(this.form.branch_id.length>0){
                    b_id = this.form.branch_id[this.form.branch_id.length-1]
                }
                let data = {
                    page:this.currentPage,
                    count:this.pageSize,
                    start_create_time:this.form.createTime.length>1?this.form.createTime[0]+' 00:00:00':'',
                    end_create_time:this.form.createTime.length>1?this.form.createTime[1]+' 23:59:59':'',
                    create_id:this.form.create_id, //开启1 关闭0
                    company:this.form.company,
                    branch_id:b_id,
                    payer_id:this.form.payer_id
                }
                openbill(data).then(res=>{
                    this.tableData = res.data.table
                    this.total = res.data.total
                })
            },
            wantEdit(msg){
                let data = {
                    id:msg
                }
                drawerdetail(data).then(res=>{
                    if(res.code==200){
                        this.editform = res.data
                        this.type = 2
                        this.dialogAdd = true
                    }else{
                        this.$message({
                            message:res.msg,
                            type:'error'
                        })
                    } 
                })
            },
            wantAdd(data){
                this.dialogAdd = data
            },
            addUser(data){
                adddrawer(data).then(res=>{
                    if(res.code==200){
                        this.$message({
                            message:res.msg,
                            type:'success'
                        })
                        this.dialogAdd = false
                        this.getList()
                    }else{
                        this.$message({
                            message:res.msg,
                            type:'error'
                        })
                    } 
                })
            },
            deitUser(msg){
                deitdrawer(msg).then(res=>{
                    if(res.code==200){
                        this.$message({
                            message:res.msg,
                            type:'success'
                        })
                        this.dialogAdd = false
                        this.getList()
                    }else{
                        this.$message({
                            message:res.msg,
                            type:'error'
                        })
                    } 
                })
            },
            showHide(){
                this.actText = this.hide?'展开':'收起'
                this.hide = !this.hide
            },
            cancelAdd(){
                this.dialogAdd = false
                this.type = 1
            },
            handleSizeChange(val){
                this.pageSize = val
                this.currentPage = 1
                this.getList()
            },
            handleCurrentChange(val){
                this.currentPage = val
                this.getList()
        },
    }
}

</script>

<style src="@/assets/scss/formstyle.scss" lang="scss" ></style>
<style lang="scss" scoped>
* {
    box-sizing: content-box;
}

.home {
    padding: 24px;

    .top-search {
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        .select-line {
            display: flex;
            padding: 0 20px 0 0;
        }

        .line {
            width: 100%;
            height: 1px;
            background: #F0F1F5;
        }

        .btn-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 11px 20px;

            .act {
                background: #2C57FF;
                color: white;
            }

            .btnpart button {
                height: 32px;
                width: 68px;
                line-height: 32px;
                padding: 0;

            }
            .showpart{
                height: 100%;
                display: flex;
                align-items: center;
                font-size: 14px;
                .icon{
                    width: 7px;
                    height: 16px;
                    margin-left: 6px;
                }
            }
            .action{
                transform: rotate(180deg);
            }
            .showpart:hover{
                color: #2C57FF;
            }
            .acticon{
                transform: rotate(180deg);
            }
            
        }


    }

    .tablepart {
        min-height: 658px;
        background: white;
        margin-top: 10px;
        border-radius: 4px;
        padding: 16px 20px;

        .pagetab {
            text-align: right;
            margin-top: 20px;
        }
    }

    .span {
        font-size: 14px;
        font-weight: bold;
        color: #474952;
    }

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon{
            margin-right: 6px;
        }
    }
}

</style>