<template>
    <div class="custom">
        <div class="border" v-if="!isDetail">
            <div class="top-search">
                <el-form :model="form" class="demo-form-inline" label-width="">
                    <div class="select-line bostrap" :style="{ height: (hide == false ? '43px' : 'auto') }" id="searchLine">
                        <!-- <el-form-item label="标题" class="">
                            <el-input class="" v-model="form.keyword" placeholder="请输入关键字搜索">
                            </el-input>
                        </el-form-item> -->
                        <el-form-item label="事项类别">
                            <el-select v-model="form.type" placeholder="请选择" clearable>
                                <el-option v-for="item in typeList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="申请人">
                            <el-select v-model="form.admin_id" placeholder="请选择" clearable filterable>
                                <el-option v-for="item in adminList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="创建时间" class="time">
                            <el-date-picker v-model="form.set_time" value-format="yyyy-MM-dd" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" type="daterange"
                                placeholder="请选择" :clearable="false"></el-date-picker>
                        </el-form-item>
                    </div>
                    <div class="line"></div>
                    <div class="btn-line">
                        <div class="btnpart">
                            <el-button class="confirm " @click="getList" plain v-permission="'待办事项-select'">查询</el-button>
                            <el-button class="cancel" @click="clearSeach" plain v-permission="'待办事项-reset'">重置</el-button>
                        </div>
                        <!-- <div class="showpart hover handel" @click="showHide">
                            {{ actText }}
                            <local-svg iconClass="showdown" class="icon"></local-svg>
                        </div> -->
                    </div>

                </el-form>
            </div>
            <div class="tablepart">
                <div class="top">
                    <div class="span">
                        <div class="title">事项列表</div>
                        <el-radio-group v-model="form.is_deal" @input="getList">
                            <el-radio label="0">待办事项</el-radio>
                            <el-radio label="1" >已办事项</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="btnlist btnpart">
                    </div>
                </div>
                <customTable :list="tableData" @transfer="wantAdd" @stopuser="setUserType" @deal="dealIt" :type="form.is_deal" :loading="load">
                </customTable>
                <div class="pagetab">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- <contractExam v-if="isDetail&&examType==1" @back="closeDetail" :info="detailData" @add="addFollow" :follow="followList" @refuse="wantRefuse" :isDetail="isExamine"></contractExam>
        <contbackExam v-if="isDetail&&examType==2" @back="closeDetail" :info="detailData" @add="addFollow" :follow="followList" @refuse="wantRefuse" :isDetail="isExamine"></contbackExam>
        <applyExam v-if="isDetail&&examType==3" @back="closeDetail" :info="detailData" @add="addFollow" :follow="followList" @refuse="wantRefuse" :isDetail="isExamine"></applyExam>
        <houseExam v-if="isDetail&&examType==4" @back="closeDetail" :info="detailData" @add="addFollow" :follow="followList" @refuse="wantRefuse" :isDetail="isExamine"></houseExam>
        <billExam v-if="isDetail&&examType==7" @back="closeDetail" :info="detailData" @add="addFollow" :follow="followList" @refuse="wantRefuse" :isDetail="isExamine"></billExam>
        <addSelect v-if="followDialog" :show="followDialog" @add="checkUser" @close="closeFollow"></addSelect> -->
    </div>
</template>

<script>
import { todeselect,todolist } from '@/api/message'
import customTable from "@/components/message/todo/table.vue"
import changeDialog from "@/components/custom/change.vue"
// import contractExam from "@/components/custom/change.vue"
// import contbackExam from "@/components/custom/change.vue"
// import applyExam from "@/components/custom/change.vue"
// import houseExam from "@/components/custom/change.vue"
// import billExam from "@/components/custom/change.vue"
// import addSelect from "@/components/contract/follow.vue"
export default {
    name: 'custom',
    data() {
        return {
            examType:0,
            hide: false,
            load: false,
            isDetail:false,
            followDialog:false,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            actText: '展开',
            adminList: [],
            typeList: [],
            form: {
                type: '',
                admin_id: '',
                set_time: [],
                keytype: 2,
                is_deal:'0'
            },
            tableData: [],
            load:true,
            detailData:{}
        }
    },
    components: {
        customTable,
        changeDialog,
        // contractExam,
        // contbackExam,
        // applyExam,
        // houseExam,
        // billExam,
        // addSelect
    },
    mounted() {
        this.getSearch()
        this.getList()
    },
    methods: {
        wantRefuse(msg){
            this.refuseDialog = true
            this.actId = msg
        },
        checkUser(msg){
            this.followList.push(msg)
            this.followDialog = false
        },
        closeFollow(){
            this.followDialog = false
        },
        closeDetail(){
            this.isDetail = false
        },
        dealIt(msg){
            let key = msg.type_id
            let value = msg.data_id
            if(key == 1){
                this.$router.replace({
                    name:'contract',
                    params:{
                        id:value,
                        type:'examine'
                    }
                })
            }
            else if(key == 2){
                this.$router.replace({
                    name:'contractback',
                    params:{
                        id:value,
                        type:'examine'
                    }
                })
            }
            else if(key == 3){
                this.$router.replace({
                    name:'constapply',
                    params:{
                        id:value,
                        type:'examine'
                    }
                })
            }
            else if(key == 4){
                this.$router.replace({
                    name:'houseCollection',
                    params:{
                        id:value,
                        type:'examine'
                    }
                })
            }
            else if(key == 7){
                this.$router.replace({
                    name:'openbill',
                    params:{
                        id:value,
                        type:'examine'
                    }
                })
            }
            else if(key == 5){
                this.$router.push({
                    name:'constapply',
                    params:{
                        id:value,
                        type:'examinebill'
                    }
                })
            }
            else if(key == 6){
                this.$router.push({
                    name:'constapply',
                    params:{
                        id:value,
                        type:'examineconfirm'
                    }
                })
            }
            else if(key == 10){
                this.$router.push({
                    name:'openbill',
                    params:{
                        id:value,
                        type:'improvebill'
                    }
                })
            }
            else if(key == 11){
                this.$router.push({
                    name:'sellchance',
                    params:{
                        id:value,
                        type:'improvesell'
                    }
                })
            }
            else if(key == 12){
                this.$router.replace({
                    name:'custom',
                    params:{
                        id:value,
                        type:'confirm',
                        out_name:msg.remind_other ? msg.remind_other  : '1',
                        remind_id:msg.id
                    }
                })
            }
        },
        getSearch() {
            todeselect().then(res => {
                this.typeList = res.data.type
                this.adminList = res.data.admin
            })
        },
        cancelChange() {
            this.changeDialog = false
        },
        clearSeach() {
            this.form = {
                type: '',
                admin_id: '',
                set_time: [],
                keytype: 2,
                is_deal:'0'
            }
            this.getList('search')
        },
        wantAdd() { },
        // 获取用户列表
        getList(e) {
            if(e){
                this.currentPage = 1
            }
            this.load = true
            let data = {
                page: this.currentPage,
                count: this.pageSize,
                admin_id:this.form.admin_id,
                type:this.form.type,
                start_time:this.form.set_time.length>0?this.form.set_time[0]:'',
                end_time:this.form.set_time.length>0?this.form.set_time[1]:'',
                is_deal:this.form.is_deal
            }
            todolist(data).then(res => {
                this.tableData = res.data.table
                this.total = res.data.total
                setTimeout(() => {
                    this.load = false
                }, 1000);
            })
        },
        setUserType() { },
        showHide() {
            this.actText = this.hide ? '展开' : '收起'
            this.hide = !this.hide
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.currentPage = 1
            this.getList()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getList()
        },
    }
}

</script>

<style src="@/assets/scss/formstyle.scss" lang="scss" ></style>
<style lang="scss" scoped>
* {
    box-sizing: content-box;
}
.custom{
    .border{
        padding: 24px;
    }

    .top-search {
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;

        .select-line {
            display: flex;
            padding: 0 20px 0 0;
        }

        .line {
            width: 100%;
            height: 1px;
            background: #F0F1F5;
        }

        .btn-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 11px 20px;

            .showpart {
                height: 100%;
                display: flex;
                align-items: baseline;
                font-size: 14px;

                .icon {
                    margin-left: 6px;
                    width: 8px;
                    height: 8px;
                }
            }

            .act {
                background: #2C57FF;
                color: white;
            }

            .btnpart button {
                height: 32px;
                width: 68px;
                line-height: 32px;
                padding: 0;

            }
        }
    }

    .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
            font-size: 14px;
            font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
            font-weight: bold;
            color: #474952;
            line-height: 16px;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .icon {
            width: 16px;
            height: 16px;
        }

        i {
            margin-right: 5px;
        }
    }

    .tablepart {
        height: calc(100vh - 260px);
        background: white;
        margin-top: 10px;
        border-radius: 4px;
        padding: 16px 20px;

        .icon {
            margin-right: 8px;
        }

        .pagetab {
            text-align: right;
            margin-top: 20px;
        }

        .span {
            font-size: 14px;
            font-weight: bold;
            color: #474952;
            display: flex;
            align-items: center;
            .title{
                margin-right: 30px;
            }
        }

        .top {
            display: flex;
            align-items: center;
        }
    }
}
</style>