<template>
    <div v-if="previewImg.popup" class="rlPopup">
        11111
        <div class="close-big-img" @click="close()">
            <!-- <em class="font_family icon-close-circle"></em> -->
            <local-svg iconClass="close" class="icon close"></local-svg>
        </div>
        <div class="prev handle-btn" v-if="!isSingle" @click="prev()">
            <local-svg iconClass="to" class="icon"></local-svg>
        </div>
        <div class="next handle-btn" v-if="!isSingle" @click="next()">
            <local-svg iconClass="to" class="icon"></local-svg>
        </div>
        <!--放大缩小旋转-->
        <div class="zoom-wrap">
            <i @click="handleActions('zoomOut')" class="el-icon-zoom-out"></i>
            <i @click="handleActions('zoomIn')" class="el-icon-zoom-in"></i>
            <i @click="handleActions('left')" class="el-icon-refresh-left"></i>
            <i @click="handleActions('right')" class="el-icon-refresh-right"></i>
            <i class="page">{{ previewImg.index+1 + '/' +previewImg.imgList.length}}</i>
        </div>
        <div class="img-list-wrap">
            <div v-for="(img, i) in previewImg.imgList" :key="i" class="imageBox">
                <img ref="img" :src="img.url" v-if="i === previewImg.index&&!img.img_url" :style="imgStyle" alt="">
                <img ref="img" :src="img.img_url" v-if="i === previewImg.index&&img.img_url" :style="imgStyle" alt="">
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'PreviewImage',
    props: {
        previewImg: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            transform: {
                scale: 1,
                degree: 0
            }
        }
    },
    computed: {
        isSingle() {
            return this.previewImg.imgList.length <= 1
        },
        isFirst() {
            return this.previewImg.index === 0
        },
        isLast() {
            return this.previewImg.index === this.previewImg.imgList.length - 1
        },
        imgStyle() {
            const { scale, degree } = this.transform
            const style = {
                transform: `scale(${scale}) rotate(${degree}deg)`
            }
            return style
        }
    },
    methods: {
        prev() {
            if (this.isFirst && !this.previewImg.infinite) return
            if (this.$parent.imageData.index > 0) {
                const len = this.previewImg.imgList.length
                this.$parent.imageData.index = (this.$parent.imageData.index - 1 + len) % len
                this.reset()
            }
        },
        next() {
            console.log(this.isLast)
            console.log(this.previewImg.infinite)
            if (this.isLast && !this.previewImg.infinite) return
            if (this.previewImg.imgList.length > this.$parent.imageData.index) {
                console.log(this.$parent.imageData.index)
                const len = this.previewImg.imgList.length
                this.$parent.imageData.index = (this.$parent.imageData.index + 1) % len
                this.reset()
            }
        },
        handleActions(action) {
            const { zoomRate, rotateDeg, enableTransition } = {
                zoomRate: 0.2,
                rotateDeg: 90,
                enableTransition: true
            }
            const { transform } = this
            switch (action) {
                case 'zoomOut':
                    if (transform.scale > 0.2) {
                        transform.scale = parseFloat((transform.scale - zoomRate).toFixed(3))
                    }
                    break
                case 'zoomIn':
                    transform.scale = parseFloat((transform.scale + zoomRate).toFixed(3))
                    break
                case 'left':
                    transform.degree -= rotateDeg
                    break
                case 'right':
                    transform.degree += rotateDeg
                    break
            }
            transform.enableTransition = enableTransition
        },
        reset() {
            this.transform = {
                scale: 1,
                degree: 0
            }
        },
        close() {
            this.$parent.imageData.popup = false
        }
    }
}
</script>
  
<style lang="scss" scoped>
// 查看大图
.rlPopup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3000;

    .close-big-img {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 10000;
        .icon{
            cursor: pointer;
            color: #ffffff;
            font-size: 20px;
        }
    }

    .handle-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 44px;
        height: 44px;
        line-height: 38px;
        font-size: 24px;
        color: #fff;
        background-color: #606266;
        border-color: #fff;
        border-radius: 50%;
        cursor: pointer;
        text-align: center;
        z-index: 10000;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .prev {
        left: 40px;
        .icon{
            transform: rotate(90deg);
        }
    }

    .next {
        right: 40px;
        .icon{
            transform: rotate(-90deg);
        }
    }

    .img-list-wrap {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            display: block;
            object-fit: scale-down;
            transition: all 0.3s;
            height: 720px;
            width: 1000px;
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
            -ms-user-select: none;
        }
    }

    .zoom-wrap {
        position: absolute;
        bottom: 16px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #606266;
        border-radius: 22px;
        z-index: 10000;
        width: 222px;
        height: 44px;
        line-height: 47px;
        padding: 0 23px;

        i {
            color: #ffffff;
            cursor: pointer;
            font-size: 22px;
            margin-right: 25px;

            &:last-child {
                margin-right: 0;
            }
        }
        .page{
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
            -ms-user-select: none;
        }
    }
}
</style>