import request from '../utils/scrm_request'

//获取企业信息
export function scrm_customlist(data){
    return request({
        url:'/searchDataCompany',
        method:'get',
        params:data
    })
}

//获取客户详情
export function scrm_customdetail(data){
    return request({
        url:'/findCompanyDetail',
        method:'get',
        params:data
    })
}

//获取跟进记录详情
export function scrm_followlist(data){
    return request({
        url:'/clientRecord',
        method:'get',
        params:data
    })
}

//获取微信情况
export function scrm_wechat(data){
    return request({
        url:'/clientWxType',
        method:'get',
        params:data
    })
}
