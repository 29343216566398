<template>
    <div class="adduser">
        <el-dialog :visible.sync="receiveDialog" width="1000px" class="addform receive" @close="cancelSelect">
            <div slot="title" class="dialog-title">
                <span class="title-text">单项合同</span>
                <div class="button-right">
                    <span class="title-close" @click="cancelSelect"></span>
                </div>
            </div>
            <div class="addform contractform receivetable outwork">
                <div class="formbox">
                    <div class="table-main" style="padding-top: 0 !important;">
                        <div class="table table-element-change">
                            <el-table
                                :data="singleList"
                                height="430px"
                                style="width: 100%"
                                :header-cell-style="{'background':'#F4F7FC','color':'#B0B1B9','fontWeight':'bold'}">
                                <template slot="empty">
                                    <el-empty :image-size="160" :image="image" description='暂无数据'></el-empty>
                                </template>
                                <el-table-column width="50" align="center">
                                    <template slot-scope="scope">
                                        <div class="select" >
                                            <div class="select-box hover" :class="actId==scope.row.id?'actline':''"
                                            @click="checkLine(scope.row)">
                                                <div class="actbox"></div>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <!-- <el-table-column type="index" label="序号"  align="center"></el-table-column> -->
                                <el-table-column label="名称" prop="name"></el-table-column>
                                <el-table-column label="价格(元)" prop="amount"></el-table-column>
                                <el-table-column label="创建时间" prop="create_time"></el-table-column>
                                <el-table-column label="业务类型" prop="business_type"></el-table-column>
                                <el-table-column label="业务明细" prop="business_detail"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer btnpart receive-dialog">
                <div class="page">
                    <el-pagination
                        layout="total,prev, pager, next"
                        :total="total"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage">
                    </el-pagination>
                </div>
                <div>
                    <el-button class="confirm" type="primary" @click="nextStep">下一步</el-button>
                    <el-button class="cancel" @click="cancelSelect">取消</el-button>
                </div>
            </div>
        </el-dialog>
        <el-dialog v-if="materialDialog" :visible.sync="materialDialog" width="1000px" class="addform receive" @close="closeMaterial">
            <div slot="title" class="dialog-title">
                <local-svg iconClass="back" class="icon handel hover" @click.native="closeMaterial"></local-svg>
                <span class="title-text" style="margin-left: 20px !important;">项目要求及所需材料</span>
                <div class="button-right">
                <span class="title-close" @click="closeMaterial"></span>
                </div>
            </div>
            <div class="headerselect btnpart"  style="padding-top: 20px;">
                <el-input class="select-input" type="text" v-model="selectName" placeholder="请输入关键字搜索">
                    <el-select  slot="prefix" placeholder="请选择" v-model="selectType" clearable>
                        <el-option v-for="item in selectList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                    </el-select>
                </el-input>
                <div class="status-select">
                    <span>状态</span>
                    <el-select placeholder="请选择" v-model="selectStatus" style="width: 200px !important;" clearable multiple collapse-tags>
                        <el-option v-for="item in statusList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                    </el-select>
                </div>
                <el-button type="primary" @click="getList('search')" class="confirm" plain>查询</el-button>
            </div>
            <div class="addform contractform receivetable material">
                <div class="formbox">
                    <div class="table-main">
                        <div class="table-title">
                            <span class="title">客户资料</span>
                            <div class="addpart handel hover" @click="addMaterial">
                                <local-svg iconClass="add" class="icon"></local-svg>
                                新建客户信息
                            </div>
                        </div>
                        <div class="table table-element-change">
                            <el-table
                                :data="outworkData"
                                height="440px"
                                style="width: 100%"
                                :header-cell-style="{'background':'#F4F7FC','color':'#B0B1B9','fontWeight':'bold'}">
                                <template slot="empty">
                                    <el-empty :image-size="160" :image="image" description='暂无数据'></el-empty>
                                </template>
                                <el-table-column label="外勤编号" prop="serial" width="120">
                                    <template slot-scope="scope">
                                        <el-tooltip placement="top" popper-class="popper-element-class" effect="light">
                                            <div slot="content">
                                                <div class="popper-main"> {{ scope.row.serial }} </div>
                                            </div>
                                            <div class="out-text">{{ scope.row.serial }}</div>
                                        </el-tooltip>
                                    </template>
                                </el-table-column>
                                <el-table-column label="业务类型" prop="type">
                                    <template slot-scope="scope">
                                        <el-tooltip placement="top" popper-class="popper-element-class" effect="light">
                                            <div slot="content">
                                                <div class="popper-main"> {{ scope.row.business_type?scope.row.business_type.name:'' }} </div>
                                            </div>
                                            <div class="out-text">{{ scope.row.business_type?scope.row.business_type.name:'' }}</div>
                                        </el-tooltip>
                                    </template>
                                </el-table-column>
                                <el-table-column label="业务明细" prop="detail">
                                    <template slot-scope="scope">
                                        <el-tooltip placement="top" popper-class="popper-element-class" effect="light">
                                            <div slot="content">
                                                <div class="popper-main"> {{ scope.row.business_detail?scope.row.business_detail.name:'' }} </div>
                                            </div>
                                            <div class="out-text">{{ scope.row.business_detail?scope.row.business_detail.name:'' }}</div>
                                        </el-tooltip>
                                    </template>
                                </el-table-column>
                                <el-table-column label="公司名称" prop="company_title">
                                    <template slot-scope="scope">
                                        <el-tooltip placement="top" popper-class="popper-element-class" effect="light">
                                            <div slot="content">
                                                <div class="popper-main"> {{ scope.row.company_title?scope.row.company_title:'/' }} </div>
                                            </div>
                                            <div class="out-text">{{ scope.row.company_title?scope.row.company_title:'/' }}</div>
                                        </el-tooltip>
                                    </template>
                                </el-table-column>
                                <el-table-column label="法定代表人" prop="legal_person">
                                    <template slot-scope="scope">
                                        <el-tooltip placement="top" popper-class="popper-element-class" effect="light">
                                            <div slot="content">
                                                <div class="popper-main"> {{ scope.row.legal_person?scope.row.legal_person:'/' }} </div>
                                            </div>
                                            <div class="out-text">{{ scope.row.legal_person?scope.row.legal_person:'/' }}</div>
                                        </el-tooltip>
                                    </template>
                                </el-table-column>
                                <el-table-column label="联系方式" prop="contact_way" width="120">
                                    <template slot-scope="scope">
                                        <el-tooltip placement="top" popper-class="popper-element-class" effect="light">
                                            <div slot="content">
                                                <div class="popper-main"> {{ scope.row.contact_way?scope.row.contact_way:'/' }} </div>
                                            </div>
                                            <div class="out-text">{{ scope.row.contact_way?scope.row.contact_way:'/' }}</div>
                                        </el-tooltip>
                                    </template>
                                </el-table-column>
                                <el-table-column label="状态" width="160" align="center">
                                    <template slot-scope="scope">
                                        <div class="status-line" >
                                            <div class="status" :class="{'status1':scope.row.review_status == 3,'status2':scope.row.review_status == 6,
                                            'status3':scope.row.review_status == 0||scope.row.review_status == -1,'status4':scope.row.review_status == 2,}">
                                                {{ scope.row.review_status == 0?'审核中':scope.row.review_status == 1?'进行中':scope.row.review_status == 2?'审核驳回':scope.row.review_status == 3?'已延期':scope.row.review_status == 6?'已完结':'未提交' }}
                                            </div>
                                            <el-tooltip placement="right" popper-class="popper-element-class" effect="light" v-if="scope.row.review_id == 2 ||scope.row.review_id == 3">
                                                <div slot="content">
                                                    <div class="popper-main"><span>延期原因：</span>{{ scope.row.review_id == 2?scope.row.verify_content:scope.row.delay_content }}</div>
                                                </div>
                                                <local-svg iconClass="tips" class="hover handel-onlyicon icon"></local-svg>
                                            </el-tooltip>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" width="160" align="center">
                                    <template slot-scope="scope">
                                        <div style="display: flex;justify-content: center;">
                                            <!-- <span class="handel hover" @click="perfectRow(scope.row)" v-if="scope.row.back_content">完善资料</span> -->
                                            <span class="handel hover" @click="perfectRow(scope.row)">完善资料</span>
                                            <span class="handel hover" @click="seeRow(scope.row)" style="margin-left: 12px;" >查看</span>
                                            <span class="handel hover" @click="delRow(scope.row)" style="margin-left: 12px;" >删除</span>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer btnpart receive-dialog">
                <div class="page">
                    <el-pagination
                        layout="total,prev, pager, next"
                        :total="total1"
                        @current-change="handleCurrentChange1"
                        :current-page="currentPage1">
                    </el-pagination>
                </div>
                <div>
                    <el-button class="confirm" type="primary" @click="submitMoreOutwork">保存并提交</el-button>
                    <!-- <el-button class="savebtn" type="primary" @click="addOk">保存</el-button> -->
                    <el-button class="cancel" @click="closeMaterial">取消</el-button>
                </div>
            </div>
        </el-dialog>
        <el-dialog v-if="formDialog" :visible.sync="formDialog"  class="dialog-element-change active-dialog" @close="cancelSubmitOutwork" style="background: transparent !important;">
            <div class="dialog-main">
                <div class="header fl-between">
                    <span class="title">客户信息</span>
                    <span class="close" @click="cancelSubmitOutwork"><local-svg iconClass="close" class="icon hover handel-header-icon"></local-svg></span>
                </div>
                <div class="custom-mian">
                    <el-form :form="addform" class="form-element-change input-element-change select-element-change textarea-element-change radio-element-change fl-between" style="align-items: flex-start;">
                        <div class="left-part">
                            <el-form-item label="流程名称">
                                <span slot="label"><span class="check-form-notice">*</span>流程名称</span>
                                <el-select v-model="addform.wp_id" placeholder="请选择" clearable>
                                    <el-option v-for="item in processList" :label="item.name" :value="item.id"
                                        :key="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="公司名称">
                                <span slot="label"><span class="check-form-notice">*</span>公司名称</span>
                                <el-input placeholder="请输入公司名称" v-model="addform.company_title"></el-input>
                            </el-form-item>
                            <el-form-item label="经营范围">
                                <span slot="label"><span class="check-form-notice">*</span>经营范围</span>
                                <el-input placeholder="请输入经营范围" type="textarea"  v-model="addform.business_scope"></el-input>
                            </el-form-item>
                            <el-form-item label="注册资金(元)">
                                <el-input placeholder="请输入注册资金" v-model="addform.register_capital" @blur="changThousand"></el-input>
                            </el-form-item>
                            <el-form-item label="联系方式">
                                <span slot="label"><span class="check-form-notice">*</span>联系方式</span>
                                <el-input placeholder="请输入联系方式" v-model="addform.contact_way" @blur="checkPhone"></el-input>
                            </el-form-item>
                            <el-form-item label="身份证信息">
                                <span slot="label"><span class="check-form-notice">*</span>身份证信息</span>
                                <div class="card-message fl-between">
                                    <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                                        accept="image/jpeg,image/png,image/jpg">
                                        <div v-if="!addform.font_image" class="card font fl-cloumn hover" @click="uploadChange(1)" >
                                            <local-svg iconClass="addcard" class="icon"></local-svg>
                                            <span>添加头像页</span>
                                        </div>
                                        <div v-else class="card fl-cloumn hover" @click="uploadChange(1)" >
                                            <img :src="addform.font_image" alt="">
                                        </div>
                                    </el-upload>
                                    <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                                        accept="image/jpeg,image/png,image/jpg">
                                        <div v-if="!addform.back_image"  class="card back fl-cloumn hover" @click="uploadChange(2)">
                                            <local-svg iconClass="addcard" class="icon"></local-svg>
                                            <span>添加国徽页</span>
                                        </div>
                                        <div v-else class="card fl-cloumn hover" @click="uploadChange(2)" >
                                            <img :src="addform.back_image" alt="">
                                        </div>
                                    </el-upload>
                                </div>    
                            </el-form-item>
                            <el-form-item label="法定代表人姓名">
                                <span slot="label"><span class="check-form-notice">*</span>法定代表人姓名</span>
                                <el-input placeholder="请输入法定代表人姓名" v-model="addform.legal_person" disabled></el-input>
                            </el-form-item>
                        </div>
                        <div class="right-part">
                            <el-form-item label="身份证地址">
                                <span slot="label"><span class="check-form-notice">*</span>身份证地址</span>
                                <el-input placeholder="请输入身份证地址" v-model="addform.card_address" disabled></el-input>
                            </el-form-item>
                            <el-form-item label="身份证号">
                                <span slot="label"><span class="check-form-notice">*</span>身份证号</span>
                                <el-input placeholder="请输入身份证号" v-model="addform.card_num" disabled></el-input>
                            </el-form-item>
                            <el-form-item label="有效期">
                                <span slot="label"><span class="check-form-notice">*</span>有效期</span>
                                <el-input placeholder="请输入有效期" v-model="addform.card_validity" disabled></el-input>
                            </el-form-item>
                            <el-form-item label="客户特殊要求">
                                <el-input placeholder="请输入客户特殊要求" v-model="addform.special_require"></el-input>
                            </el-form-item>
                            <el-form-item label="购买方主体是否本地">
                                <span slot="label"><span class="check-form-notice">*</span>购买方主体是否本地</span>
                                <div class="check-select">
                                    <el-radio-group v-model="addform.is_local">
                                        <el-radio :label="2">是</el-radio>
                                        <el-radio :label="3">否</el-radio>
                                    </el-radio-group>
                                </div>
                            </el-form-item>
                            <el-form-item label="核定税率">
                                <span slot="label"><span class="check-form-notice">*</span>核定税率</span>
                                <el-select v-model="addform.paper_tax" placeholder="请选择" clearable>
                                    <el-option v-for="item in taxList" :label="item.value" :value="item.id"
                                        :key="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="法人津心办实名认证">
                                <span slot="label"><span class="check-form-notice">*</span>法人津心办实名认证</span>
                                <div class="upload-part">
                                    <div class="upload-line" @click="uploadChange(3)">
                                        <el-upload class="upload-element-change hover upload-button" :show-file-list="false" action="" :http-request="uploadFile" 
                                            accept="image/jpeg,image/png,image/jpg">
                                            <local-svg iconClass="upload" class="icon"></local-svg>上传图片
                                        </el-upload>
                                        <span>最多上传9张</span>
                                    </div>
                                    <div class="image-part" v-show="fileList.length>0">
                                        <div class="leftpart">
                                            <div class="left-btn hover change" @click="lastFile" v-show="actIndex!=0">
                                                <local-svg iconClass="swipericon" class="lefticon"></local-svg>
                                            </div>
                                        </div>
                                        <div class="fileline" v-for="(item,index) in showFile" :key="index">
                                            <div class="file-box">
                                                <img :src="item.img_url" alt="" class="image hover" @click="seeImageList(index)">
                                                <div class="close hover" @click.stop="delFile(index)">
                                                    <local-svg iconClass="close" class="closeicon"></local-svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="rightpart">
                                            <div class="right-btn hover change" @click="nextFile" v-show="fileList.length>4&&actIndex+4!=fileList.length">
                                                <local-svg iconClass="swipericon" class="righticon"></local-svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-form-item>
                            <el-form-item label="邮编">
                                <el-input placeholder="请输入邮编" v-model="addform.postcode"></el-input>
                            </el-form-item>
                        </div>
                    </el-form>
                    <div class="button-part">
                        <button class="confirm hover" @click="submitOutwork">保存</button>
                        <button class="cancel hover" style="margin-left: 10px;" @click="cancelSubmitOutwork">取消</button>
                    </div>
                </div>
            </div>
        </el-dialog>
        <el-dialog v-if="specilaDialog" :visible.sync="specilaDialog" width="720px"  class="special-dialog-element-change" @close="cancelSubmitOutwork" style="background: transparent !important;">
            <div class="dialog-main">
                <div class="header fl-between">
                    <span class="title">客户信息</span>
                    <span class="close" @click="cancelSubmitOutwork"><local-svg iconClass="close" class="icon hover handel-header-icon"></local-svg></span>
                </div>
                <div class="special-main">
                    <div class="special" ref="scrollContainer">
                        <div class="between-box fl-between ">
                            <div class="special-header">
                                <span class="label">流程名称</span>
                                <div class="content select-element-change">
                                    <el-select v-model="wp_id" placeholder="请选择" clearable @change="checkTurn" ref="selectRef">
                                        <el-option v-for="item in processList" :label="item.name" :value="item.id"
                                            :key="item.id"></el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="special-header">
                                <span class="label">合同单号</span>
                                <div class="content input-element-change">
                                    <el-input placeholder="" class="input" v-model="contract_num" style="width: 217px;" disabled></el-input>
                                </div>
                            </div>
                        </div>
                        <div class="between-box fl-between top">
                            <div class="special-header">
                                <span class="label">系统来源</span>
                                <div class="content input-element-change">
                                    <el-input placeholder="" class="input" v-model="source" style="width: 217px;" disabled></el-input>
                                </div>
                            </div>
                            <div class="special-header">
                                <span class="label">渠道名称</span>
                                <div class="content input-element-change">
                                    <el-input placeholder="" class="input" v-model="channel" style="width: 217px;" disabled></el-input>
                                </div>
                            </div>
                        </div>
                        <formPart v-if="wp_id" :showList="formKeyWords" ref="specialComponent" @submit="submitOutwork" :taxList="taxList" :changeForm="changeForm" :regionList="regionList" :houseList="houseList"></formPart>
                    </div>
                    <div class="button-part">
                        <button class="confirm hover" @click="submitSpecial">保存</button>
                        <button class="cancel hover" style="margin-left: 10px;" @click="cancelSubmitOutwork">取消</button>
                    </div>
                </div>
            </div>
        </el-dialog>
        <showImage :previewImg="imageData"></showImage>
    </div>
</template>

<script>
import {getcontarctdown} from '@/api/contarct'
import {outworklist,cardinfo,addoutwork,outworkselect,deloutwork, outworkdetail,editoutwork,submitmore,allprocess,statuslist,regionselect} from '@/api/outwork'
import { buyerlist} from '@/api/bill'
import md5 from 'js-md5'
import service from "@/utils/outrequest.js";
import { Loading } from 'element-ui';
import showImage from '@/components/showImage.vue'
import formPart from '@/components/contract/special-form.vue'
export default {
    name: "adduser",
    components:{
        formPart,
        showImage,
    },
    props: {
        id:{
            type:Number,
        	default:()=>{
        		return 0
        	}
        },
        showdialog:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        actuser:{
            type:Number,
        	default:()=>{
        		return 0
        	}
        },
        contract_num:{
            type:String,
        	default:()=>{
        		return ''
        	}
        },
        channel:{
            type:String,
        	default:()=>{
        		return ''
        	}
        },
        source:{
            type:String,
        	default:()=>{
        		return ''
        	}
        }
    },
    data() {
        return {
            currentPage:1,
            currentPage1:1,
            total:0,
            total1:0,
            actId:0,
            step_index:0,
            actIndex:1,
            upload_type:0,
            tableData:[],
            singleList:[],
            outworkData:[],
            image:require('../../assets/image/none.png'),
            image_card_font:require('../../assets/image/card-font.png'),
            image_card_back:require('../../assets/image/card-back.png'),
            receiveDialog:false,
            materialDialog:false,
            formDialog:false,
            specilaDialog:false,
            is_change:false,
            selectStatus:[],
            selectName:'',
            selectType:'',
            single_num:'',
            bd_id:'',
            bt_id:'',
            wp_id:'',
            status:1,
            selectList:[{name:'公司名称',id:0},{name:'法人',id:1}],
            statusList:[{name:'未审核',id:0},{name:'审核通过',id:1},{name:'审核驳回',id:2},{name:'延期中',id:3},{name:'已流转',id:4},{name:'已完结',id:5}],
            taxList:[],
            processList:[],
            sellList:[],
            houseList:[],
            addform:{
                company_title:'',
                business_scope:'',
                register_capital:'',
                legal_person:'',
                contact_way:'',
                font_image:'',
                font_image_id:'',
                back_image:'',
                back_image_id:'',
                card_address:'',
                card_num:'',
                card_validity:'',
                paper_tax:'',
                postcode:'',
                wp_id:'',
                is_local:'',
                special_require:'',
            },
            changeForm:{},
            formKeyWords:['bank_money','work_content','legal','legal_old','legal_new','shareholder','shareholder_new','shareholder_old','supervisor','supervisor_old','supervisor_new',
            'business_range','business_range_new','company_name','company_name_new','regist_money','ratio','change_money','credit_code','special_require','paper_tax','postcode','is_local',
            'legal_person_identity_card_script','license_type','license','house_contract','certificate','tax','bill','book',
            'seal','legal_person_official_seal','receipt','seal_legal','financial_seal','licence','E-bank','coder','legal_card','abnormal',],
            fileList:[],
            showFile:[],
            regionList:[],
            imageData:{
                imgList:[], // 所有图片数组
                index: 0, // 当前点击的图片的索引
                infinite: true,  // 是否可以循环切换
                popup: false // 弹窗的显示隐藏
            },
        };
    },
    mounted(){
        this.receiveDialog = this.showdialog
        this.actId = this.actuser
        this.getSingleList()
        this.getStatusList()
        this.getSelectList()
        this.getProcessList()
        this.getRegionList()
        this.getHouseList()
    },
    methods: {
        handleScroll(e){
            this.$refs.specialComponent.handleScroll()
            this.$refs.selectRef.blur();
        },
        getStatusList(){
            statuslist().then(res =>{
                this.statusList = res.data
            })
        },
        getProcessList(){
            let data = {
                open_status : 1
            }
            allprocess(data).then(res=>{
                this.processList = res.data
            })
        },
        getRegionList(){
            regionselect().then(res=>{
                this.regionList = res.data.data
            })
        },
        getHouseList(){
            let data = {
                type:9
            }
            outworkselect(data).then(res=>{
                this.houseList = res.data
            })
        },
        checkTurn(e){
            this.processList.forEach(item =>{
                if(item.id == e){
                    this.formKeyWords = item.child
                    if (this.$refs.scrollContainer) {
                        const scrollContainer = this.$refs.scrollContainer;
                        scrollContainer.addEventListener('scroll', this.handleScroll);
                    }
                    return
                }
            })
            // this.formKeyWords = ['legal','shareholder','supervisor_border','is_tally','room_num','register_address','remark','files','legal_ratio','shareholder_ratio',]
            // this.formKeyWords = ['company_name_standby']
        },
        delRow(row){
            this.$confirm('此操作将永久删除所选数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id:row.id
                }
                deloutwork(data).then(res=>{
                    if(res.code == 200){
                        this.$message.success('删除成功！')
                        this.getList()
                    }else{
                        this.$message.error(res.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        seeRow(row){
            let url = `${this.$BASE_OUT_URL}#/jump?token=${localStorage.getItem('token')}&id=${row.id}&platform=oa`
            window.open(url,'_blank')
        },
        submitSpecial(){
            if(!this.wp_id){
                this.$message.error('请先选择流程名称！')
                return
            }
            this.$refs.specialComponent.submitForm()
        },
        uploadChange(index){
            this.upload_type = index
        },
        getSelectList(){
            let data = {
                type:1
            }
            outworkselect(data).then(res=>{
                this.taxList = res.data
            })
        },
        getSingleList(){
            let data = {
                page:this.currentPage,
                limit:10,
                contract_id:this.id
            }
            getcontarctdown(data).then(res =>{
                this.singleList = res.data.list.list
                this.total = res.data.list.total
                if(this.singleList.length == 1 && this.currentPage == 1){
                    this.actId = this.singleList[0].id
                    this.single_num = this.singleList[0].name
                    this.bd_id = this.singleList[0].bd_id
                    this.bt_id = this.singleList[0].bt_id
                }
            })
        },
        getList(e){
            let data = {
                page:this.currentPage1,
                limit:10,
                company_title:this.selectType === 0 ? this.selectName : '',
                legal_person:this.selectType === 1 ? this.selectName : '',
                status:this.selectStatus.toString(),
                single_id:this.actId,
                contract_id:this.id
            }
            outworklist(data).then(res=>{
                this.outworkData = res.data.list.list
                this.total1 = res.data.list.total
                if(this.total1 == 0&&!this.materialDialog){
                    this.specilaDialog = true
                }else{
                    this.materialDialog = true
                }
            })
        },
        getCardInfo(){
            if(this.addform.back_image_id && this.addform.font_image_id){
                let data = {
                    card_front:this.addform.font_image_id,
                    card_back:this.addform.back_image_id
                }
                cardinfo(data).then(res =>{
                    if(res.code == 200){
                        this.bd_id = res.data.detail.business_detail.id
                        this.bt_id = res.data.detail.business_type.id
                        this.addform.card_address = res.data.code_data.address
                        this.addform.card_num = res.data.code_data.num
                        this.addform.card_validity = res.data.code_data.start_date+'-'+res.data.code_data.end_date
                        this.addform.legal_person  = res.data.code_data.name
                    }else{
                        this.$message.error(res.msg)
                        this.addform.card_address = ''
                        this.addform.card_num = ''
                        this.addform.card_validity = ''
                        this.addform.legal_person  = ''
                    }
                }).catch(err =>{
                    this.$message.error("请重新上传")
                })
            }
        },
        perfectRow(row){
            let data = {
                id:row.id
            }
            outworkdetail(data).then(res =>{
                if(res.code == 200){
                    this.changeForm = res.data.detail
                    this.wp_id = res.data.detail.wp_id
                    this.checkTurn(this.wp_id)
                    this.is_change = true
                    this.specilaDialog = true
                    setTimeout(()=>{
                        if (this.$refs.scrollContainer) {
                            const scrollContainer = this.$refs.scrollContainer;
                            scrollContainer.addEventListener('scroll', this.handleScroll);
                        }
                    },1000)
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        checkPhone(){
            if( !(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(this.addform.contact_way))){
				this.$message.error('手机号格式有误！')
				return
			}
        },
        changThousand(){
            this.addform.register_capital = Number(this.addform.register_capital.replace(/,/g, "")).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 })
        },
        submitOutwork(data){
            // if(!/\d{6}$/.test(this.addform.postcode)&&this.addform.postcode){
            //     this.$message.error('请输入正确格式的邮编!')
            //     return
            // }
            // let list = []
            // this.fileList.forEach(item =>{
            //     list.push(item.id)
            // })
            // let data = {
            //     contract_id:this.id,
            //     single_id:this.actId,
            //     company_title:this.addform.company_title,
            //     business_scope:this.addform.business_scope,
            //     register_capital:  Number(this.addform.register_capital.replace(/,/g, "")),
            //     identity_card: this.addform.font_image_id+','+this.addform.back_image_id,
            //     legal_person:this.addform.legal_person,
            //     contact_way:this.addform.contact_way,
            //     card_address:this.addform.card_address,
            //     card_num:this.addform.card_num,
            //     card_validity:this.addform.card_validity,
            //     paper_tax:this.addform.paper_tax,
            //     jin_xin_ban:list,
            //     postcode:this.addform.postcode,
            //     wp_id:this.addform.wp_id,
            //     is_local:this.addform.is_local,
            //     special_require:this.addform.special_require,
            //     contract_num:this.contract_num,
            //     single_num:this.single_num,
            //     business_detail:this.bd_id,
            //     business_type:this.bt_id
            // }
            data.contract_id = this.id
            data.single_id = this.actId
            data.contract_num = this.contract_num
            data.single_num = this.single_num
            data.business_detail = this.bd_id
            data.business_type = this.bt_id
            data.wp_id = this.wp_id
            if(this.is_change){
                data.id = this.changeForm.id
                editoutwork(data).then(res=>{
                    if(res.code == 200){
                        this.$message.success('编辑成功')
                        this.materialDialog = true
                        this.specilaDialog = false
                        this.getList()
                        this.clearForm()
                        this.wp_id = ''
                    }else{
                        this.$message.error(res.msg)
                    }
                })
            }else{
                addoutwork(data).then(res=>{
                    if(res.code == 200){
                        this.$message.success('添加成功')
                        this.materialDialog = true
                        this.specilaDialog = false
                        this.getList()
                        this.clearForm()
                        this.wp_id = ''
                    }else{
                        this.$message.error(res.msg)
                    }
                })
            }
            
        },
        cancelSubmitOutwork(){
            if(this.wp_id){
                this.$refs.specialComponent.resetForm()
            }
            this.changeForm = {}
            this.wp_id = ''
            this.specilaDialog = false
        },
        clearForm(){
            this.is_change = false
            this.fileList = []
            this.showFile = []
            this.addform = {
                company_title:'',
                business_scope:'',
                register_capital:'',
                legal_person:'',
                contact_way:'',
                font_image:'',
                font_image_id:'',
                back_image:'',
                back_image_id:'',
                card_address:'',
                card_num:'',
                card_validity:'',
                paper_tax:'',
                postcode:'',
                wp_id:'',
                is_local:'',
                special_require:'',
            }
        },
        submitMoreOutwork(){
            let ids = []
            this.outworkData.forEach(item =>{
                if(item.review_status == -1){
                    ids.push(item.id)
                }
            })
            if(ids.length == 0){
                this.$message.error('暂无可提交的内容!')
                return
            }
            let data = {
                ids:ids.join(',')
            }
            submitmore(data).then(res=>{
                if(res.code == 200){
                    this.$message.success('提交成功')
                    this.getList()
                    this.cancelSelect()
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        addMaterial(){
            this.specilaDialog = true
        },
        addOk(){

        },
        nextStep(){
            if(!this.actId){
                this.$message.error('请先选择要添加的单项')
                return
            }
            this.getList()
        },
        checkLine(row){
            this.actId = row.id
            this.single_num = row.name
            this.bd_id = row.bd_id
            this.bt_id = row.bt_id
            console.log(this.addform)
        },
        closeMaterial(){
            this.outworkData = []
            this.materialDialog = false
            this.selectType = ''
            this.selectName = ''
            this.selectStatus = []
            this.total1 = 0
            this.currentPage1 = 1
        },
        cancelSelect(){
            this.$emit('closereceive',false)
        },
        handleCurrentChange(val){
            this.currentPage = val
            this.getSingleList()
        },
        handleCurrentChange1(val){
            this.currentPage1 = val
            this.getList()
        },
        
        nextFile(){
            this.actIndex = this.actIndex + 1
            this.showFile = this.fileList.slice(this.actIndex,this.actIndex + 4)
        },
        lastFile(){
            this.actIndex = this.actIndex - 1
            this.showFile = this.fileList.slice(this.actIndex,this.actIndex + 4)
        },
        seeImageList(index){
            this.imageData.popup = true
            this.imageData.imgList = this.fileList
            this.imageData.index = this.actIndex + index
        },
        delFile(index){
            this.fileList.splice(this.actIndex+index,1)
            this.actIndex = this.actIndex==0?this.actIndex:this.actIndex-1
            this.showFile = this.fileList.slice(this.actIndex,this.actIndex + 4)
        },
        uploadFile(obj){
            let fileObj = obj.file
            const { size, name } = fileObj
            let md5code = md5(name + new Date().getTime())
            let length = 500000
            let start = 0
            let end = 0
            let i = 1;
            Loading.service({
                text: "上传中...."
            });
            this.testHandle(md5code, length, start, end, size, name, fileObj, i)
        },
        testHandle(md5code, length, start, end, size, name, fileObj, i) {
            if (length > size) {
                length = size
            }
            if (end < size) {
                end = start + length
                if (end > size) {
                    end = size
                }
                let blob = ""
                blob = fileObj.slice(start, end)
                start = end
                let fd = new FormData()
                fd.append("file", blob)
                fd.append("code", md5code)
                let suc = 2
                if (end == size) {
                    suc = 1
                }
                fd.append("tag", suc)
                fd.append("sort", i)
                fd.append("name", name)
                fd.append("ext", name.split('.')[1])
                i = i + parseInt(1)
                service({
                    url: "/back/v1/oa/uploadFile",
                    method: "post",
                    data: fd,
                }).then(res => {
                    if (res.code == 200 || res.code == 201) {
                        if (suc == 2) {
                            this.testHandle(md5code, length, start, end, size, name, fileObj, i)
                        } else {
                            this.$message.success(res.msg)
                            Loading.service().close()

                            if(this.upload_type == 1 || this.upload_type == 2){
                                if(this.upload_type == 1 ){
                                    this.addform.font_image = res.data.file_url
                                    this.addform.font_image_id = res.data.file_id
                                }else{
                                    this.addform.back_image = res.data.file_url
                                    this.addform.back_image_id = res.data.file_id
                                }
                                this.getCardInfo()
                            }else{
                                this.fileList.push({ name: name, img_url: res.data.file_url ,id:res.data.file_id})
                                if(this.showFile.length<4){
                                    this.showFile.push({ name: name, img_url: res.data.file_url,id:res.data.file_id })
                                }
                            }
                        }
                    } else {
                        this.$message.error(res.msg)
                        Loading.service().close()
                    }
                })
                .catch(err => {
                    this.$message.error("请重新上传")
                    Loading.service().close()
                })
            }
        },
    },
    beforeDestroy() {
        // 组件销毁前移除监听器
        if (this.$refs.scrollContainer) {
            this.$refs.scrollContainer.removeEventListener('scroll', this.handleScroll);
        }
    },
}
</script>

<style lang="scss" scoped>
.adduser {
    .receive{
        .el-dialog__header {
            padding: 0;
            border: none;
            display: flex;
            align-items: center;
            height: 52px;
            border-bottom: 1px solid #f0f1f5;
            padding-left: 24px;
        }
        .dialog-title{
            width: 100%;
            .icon{
                width: 20px;
                height: 16px;
            }
            .title-text{
                margin-left: 0 !important;
                font-weight: bold;
            }
        }
    }
    .headerselect.btnpart{
        .status-select{
            display: flex;
            box-sizing: border-box;
            padding: 0 36px 0 20px;
            span{
                width: 28px;
                margin-right: 12px;
                font-size: 14px;
                color: #6B6D77;
                line-height: 32px;
            }
        }
    }
    .select{
        display: flex;
        justify-content: center;
        align-items: center;
        .select-box{
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border:1px solid #E2E5EA;
            box-sizing: border-box;
            background-color: white !important;
            padding: 2px;
            display: flex;
            justify-content: center;
            .actbox{
                width: 8px;
                height: 8px;
                background-color: #2C57FF;
                border-radius: 50%;
                display: none;
            }
        }
        .actline{
            border:1px solid #2C57FF !important;
            .actbox{
                display: flex;
            }
        }
    }
    
    .receivetable{
        .el-table__cell{
            padding: 7px 0 !important;
        }
        .el-radio__label{
            padding-left: 0 !important;
        }
        .el-radio__input.is-checked .el-radio__inner{
            border-color: #2C57FF;
            background: #fff;
            width: 14px;
            height: 14px;
        }
        .el-radio__inner::after{
            background-color: #2C57FF;
            width: 8px;
            height: 8px;
        }
        .status-line{
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            .status{
                width: 82px;
                height: 24px;
                text-align: center;
                background: #EBF9E3;
                color: #45B95F;
                font-size: 14px;
                line-height: 24px;
                border-radius: 4px;
            }
            .status1{
                background: #FFF5EC !important;
                color: #FA7F0F;
            }
            .status2{
                background: #EEEEEE;
                color: #9A9EAA;
            }
            .status3{
                background: #E8F1FF;
                color: #1D78FE;
            }
            .status4{
                background: #FFF1F1;
                color: #EB4B51;
            }
            .icon{
                width: 14px;
                height: 14px;
                position: absolute;
                right: 8px;
            }
        }
    }
    .receive-dialog{
        display: flex;
        justify-content: space-between;
    }
    .dialog-main{
        width: auto;
        height: auto;
        background: #fff;
        border-radius: 8px;
        .header{
            width: 100%;
            height: 54px;
            border-bottom: 1px solid #F0F1F5;
            box-sizing: border-box;
            padding: 20px;
            .title{
                font-size: 16px;
                font-weight: bold;
                color: #2B313D;
                line-height: 16px;
                margin-top: 4px;
            }
            .close{
                .icon{
                    height: 16px;
                    width: 16px;
                }
            }
        }
        .custom-mian{
            min-width: 876px;
            width: auto;
            height: auto;
            box-sizing: border-box;
            padding: 20px 24px 0;
            .card-message{
                width: 330px;
                height: 118px;
                align-items: normal;
                .card{
                    width: 159px;
                    height: 118px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    border: 1px dashed #E2E5EA;
                    .icon{
                        color: #B0B1B9;
                        width: 24px;
                        height: 24px;
                    }
                    span{
                        margin-top: 8px;
                        font-size: 12px;
                        color: #B0B1B9;
                        line-height: 12px;
                    }
                    img{
                        width: 157px;
                        height: 116px;
                        border-radius: 4px;
                    }
                }
                .card:hover{
                    .icon{
                        color: #9fa0ab;
                    }
                    span{
                        color: #9fa0ab;
                    }
                }
                .font{
                    background: url('../../assets/image/card-font.png') no-repeat;
                    background-size: 100% 100%;
                    
                }
                .back{
                    background: url('../../assets/image/card-back.png') no-repeat;
                    background-size: 100% 100%;
                }
            }
            .upload-part{
                width: 330px;
                .upload-line{
                    width: 100%;
                    display: flex;
                    height: 32px;
                    align-items: flex-end;
                    span{
                        margin-left: 10px;
                        font-size: 12px;
                        color: #D0D0D2;
                        line-height: 14px;
                    }
                }
            }
            .image-part{
                width: 390px;
                height: 72px;
                margin-top: 14px;
                margin-left: -60px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .fileline{
                    .file-box{
                        width: 72px;
                        height: 72px;
                        position: relative;
                        margin-right: 14px;
                        .image{
                            width: 100%;
                            height: 100%;
                            border-radius: 4px;
                        }
                        .close{
                            position: absolute;
                            right: -7px;
                            top: -7px;
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            background: #E6E6E6;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .closeicon{
                                width: 8px;
                                height: 8px;
                                fill: #B0B1B9;
                            }
                        }
                        .close:hover{
                            background: rgba(44, 87, 255, 0.2) !important;
                            .closeicon{
                                fill: #2C57FF !important;
                            }
                        }
                    }
                }
                .left-btn{
                    width: 20px;
                    height: 32px;
                    border-radius: 4px 0px 0px 4px;
                    background: rgba(0,0,0,0.2);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // margin-right: 4px;
                    .lefticon{
                        width: 7px;
                        height: 12px;
                    }
                }
                .right-btn{
                    width: 20px;
                    height: 32px;
                    border-radius: 0px 4px 4px 0px;
                    background: rgba(0,0,0,0.2);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // margin-left: -4px;
                    .righticon{
                        width: 7px;
                        height: 12px;
                        transform: rotate(180deg);
                    }
                }
                .change:hover{
                    background: rgba(0,0,0,0.3) !important;
                }
                .leftpart{
                    width: 20px;
                    margin-right: 10px;
                }
                .rightpart{
                    width: 20px;
                    margin-left:-4px;
                }
            }
            .check-select{
                width: 330px;
                height: 32px;
            }
            .button-part{
                width: 100%;
                display: flex;
                justify-content: flex-end;
                padding: 5px 24px 26px;
                box-sizing: border-box;
            }
        }
        .special-main{
            width: 720px;
            padding: 20px 24px 26px;
            box-sizing: border-box;
            .special{
                width: 100%;
                height: auto;
                max-height: calc(80vh - 203px);
                overflow-y: auto;
                min-height: 34px;
                .between-box{
                    height: 32px;
                }
                .special-header{
                    height: 32px;
                    // width: 100%;
                    display: flex;
                    align-items: center;
                    .label{
                        font-size: 14px;
                        color: #6B6D77;
                        line-height: 16px;
                    }
                    .content{
                        margin-left: 20px;
                    }
                }
                .top{
                    margin-top: 12px;
                }
                .special-box{
                    margin-top: 16px;
                    width: 100%;
                    height: auto;
                    border-radius: 4px ;
                    border: 1px solid #F0F1F5;
                    box-sizing: border-box;
                    .box-item{
                        height: auto;
                        display: flex;
                        width: 100%;
                        border-bottom: 1px #F0F1F5 solid;
                        height: auto;
                        box-sizing: border-box;
                        .label-part{
                            width: 140px;
                            background: #F8F8FA;
                            display: flex;
                            align-items: center;
                            padding: 0 18px;
                            box-sizing: border-box;
                            span{
                                font-size: 14px;
                                color: #6B6D77;
                                line-height: 22px;
                                .icon{
                                    margin-left: 3px;
                                }
                            }
                        }
                        .content-part{
                            width: calc(100% - 140px);
                            height: auto;
                            padding: 20px;
                            box-sizing: border-box;
                            .content-input{
                                width: 100%;
                                .input{
                                    width: 230px;
                                    margin-right: 10px;
                                }
                                .input:nth-child(n+3){
                                    width: 230px;
                                    margin-right: 10px;
                                    margin-top: 14px;
                                }
                            }
                            .content-card{
                                width: 100%;
                                display: flex;
                                align-items: center;
                                margin-top: 14px;
                                .card-item{
                                    width: 126px;
                                    height: 80px;
                                    box-sizing: border-box;
                                    border: 1px dashed #F0F1F5;
                                    margin-right: 10px;
                                    border-radius: 4px;
                                    display: flex;
                                    align-items: center;
                                    position: relative;
                                    margin-bottom: 16px;
                                    img{
                                        width: 126px;
                                        height: 80px;
                                    }
                                    .tip{
                                        position: absolute;
                                        top: 0;
                                        width: 100%;
                                        height: 100%;
                                        padding: 17px 0 0 ;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        box-sizing: border-box;
                                        .icon{
                                            width: 24px;
                                            height: 24px;
                                            color: #B0B1B9;
                                        }
                                        span{
                                            margin-top: 8px;
                                            font-size: 12px;
                                            color: #B0B1B9;
                                            line-height: 12px;
                                        }
                                    }
                                }
                            }
                            
                            .content-upload{
                                width: 100%;
                                height: auto;
                                .upload-line{
                                    width: 100%;
                                    display: flex;
                                    height: 32px;
                                    align-items: flex-end;
                                    span{
                                        margin-left: 10px;
                                        font-size: 12px;
                                        color: #D0D0D2;
                                        line-height: 14px;
                                    }
                                }
                                .image-part{
                                    width: 361px;
                                    height: 64px;
                                    margin-top: 14px;
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    position: relative;
                                    .fileline{
                                        .file-box{
                                            width: 64px;
                                            height: 64px;
                                            position: relative;
                                            margin-right: 10px;
                                            .image{
                                                width: 100%;
                                                height: 100%;
                                                border-radius: 4px;
                                            }
                                            .close{
                                                position: absolute;
                                                right: -6px;
                                                top: -6px;
                                                width: 16px;
                                                height: 16px;
                                                border-radius: 50%;
                                                background: #E6E6E6;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                .closeicon{
                                                    width: 8px;
                                                    height: 8px;
                                                    fill: #B0B1B9;
                                                }
                                            }
                                            .close:hover{
                                                background: rgba(44, 87, 255, 0.2) !important;
                                                .closeicon{
                                                    fill: #2C57FF !important;
                                                }
                                            }
                                        }
                                    }
                                    .left-btn{
                                        width: 20px;
                                        height: 64px;
                                        border-radius: 4px 0px 0px 4px;
                                        background: rgba(0,0,0,0.2);
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        // margin-right: 4px;
                                        .lefticon{
                                            width: 7px;
                                            height: 12px;
                                        }
                                    }
                                    .right-btn{
                                        width: 20px;
                                        height: 64px;
                                        border-radius: 0px 4px 4px 0px;
                                        background: rgba(0,0,0,0.2);
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        // margin-left: -4px;
                                        .righticon{
                                            width: 7px;
                                            height: 12px;
                                            transform: rotate(180deg);
                                        }
                                    }
                                    .change:hover{
                                        background: rgba(0,0,0,0.3) !important;
                                    }
                                    .leftpart{
                                        width: 20px;
                                        position: absolute;
                                        left: 0;
                                    }
                                    .rightpart{
                                        width: 20px;
                                        position: absolute;
                                        right: 0;
                                    }
                                }
                            }
                            .file-part{
                                height: auto;
                                width: 100%;
                                margin-top: 14px;
                                .file-item{
                                    display: flex;
                                    height: 28px;
                                    border-radius: 4px;
                                    background: #F4F7FC;
                                    width: 100%;
                                    align-items: center;
                                    margin-bottom: 6px;
                                    padding: 0 12px 0 8px;
                                    justify-content: space-between;
                                    box-sizing: border-box;
                                    .icon{
                                        margin-right: 7px;
                                        width: 12px;
                                        height: 14px;
                                    }
                                    .name{
                                        font-size: 12px;
                                        color: #474952;
                                    }
                                    .close{
                                        width: 16px;
                                        height: 16px;
                                        border-radius: 50%;
                                        background: rgba(63,69,124,0.1);
                                        display: none;
                                        align-items: center;
                                        justify-content: center;
                                        .closeicon{
                                            width: 8px;
                                            height: 8px;
                                            fill: #B0B1B9;
                                        }
                                    }
                                    .close:hover{
                                        background: rgba(44, 87, 255, 0.2) !important;
                                        .closeicon{
                                            fill: #2C57FF !important;
                                        }
                                    }
                                }
                                .file-item:hover{
                                    background:#EDF3FE !important;
                                    .close{
                                        display: flex;
                                    }
                                }
                            }
                        }
                    }
                    .box-item:last-child{
                        border: none;
                    }
                }
            }
            .button-part{
                width: 100%;
                text-align: right;
                margin-top: 24px;
            }
        }
    }
}
.handlerTab_tooltip{
    border: none !important;
        box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
        color: #6B6D77 !important;
        padding: 8px 16px !important;
        line-height: 20px !important;
}
.handlerTab_tooltip.is-light{
    border: none !important;
}
.handlerTab_tooltip .popper__arrow {
    /* 上方箭头 */
    border-top-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
    /* 下方箭头 */
    border-bottom-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
}
.remark{
    .el-textarea__inner{
        height: 80px !important;
        line-height: 26px !important;
        padding: 5px 12px !important;
        font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    }
    .el-textarea__inner:hover{
        border-color: #2C57FF !important;
        
    }
}
</style>
<style lang="scss">
.receivetable.outwork{
    .formbox{
        height: 430px !important;
    }
    .el-table{
        height: 430px !important;
    }
}
.receive{
    .status-select{
        .el-select{
            .el-input__icon{
                line-height: 32px !important;
            }
        }
    }
}
.receivetable.material{
    .formbox{
        height: 494px !important;
    }
    
}
</style>