<template>
    <div class="showselect">
        <el-dialog title="设置表格展示列" :visible.sync="showDialog" width="600px" @close="cancel">
            <div class="dialog-top">
                <div class="body-left">
                    <div class="line-tltle">
                        <span class="title">列表项</span>
                        <div class="btn selectbtn btnpart">
                            <el-button class="cancel" @click="checkAll">全选</el-button>
                            <el-button class="cancel" @click="cancelAll">取消全选</el-button>
                        </div>
                    </div>
                    <div class="out-box">
                        <div class="select-box">
                            <div class="box-inside">
                            </div>
                            <div v-for="(item,index) in headerList"
                            :key="index" :class="item.check?'active':''">
                                <div class="select-tab disabled" v-if="index==0">
                                    <span>{{item.name }}</span>
                                    <el-checkbox v-model="item.check" disabled></el-checkbox>
                                </div>
                                <div class="select-tab hover"  v-else>
                                    <div class="tab-name" @click.stop="checkTab(item,'list')">{{item.name }}</div>
                                    <el-checkbox v-model="item.check" @change="checkTab1(item)"></el-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="body-right">
                    <div class="line-tltle">
                        <span class="title">已选列</span>
                    </div>
                    <div class="out-box out-border">
                        <div class="select-box box-border">
                            <el-empty v-if="checkList.length==0" :image-size="160" :image="image" description='暂无数据'></el-empty>
                            <div v-for="(item,index) in checkList"
                            :key="index">
                                <div class="select-tab disabled" v-if="index==0">
                                    <div class="tab-left">
                                        <div class="icon">
                                            <local-svg class="disabled" iconClass="move" ></local-svg>
                                        </div>
                                        <span>{{item.name }}</span>
                                    </div>
                                    <!-- <div class="tab-right">
                                        <local-svg class="img disabled" iconClass="close" ></local-svg>
                                    </div> -->
                                </div>
                            </div>
                            <transition-group class="transition-wrapper" name="sort">
                                <div v-for="(item,index) in checkList"
                                :key="index" :draggable="true"
                                @dragstart="dragstart(item)"
                                @dragenter="dragenter(item, $event)"
                                @dragend="dragend(item, $event)"
                                @dragover="dragover($event)">
                                    <div class="select-tab hover check-show"  v-if="index>0">
                                        <div class="tab-left">
                                            <div class="icon">
                                                <local-svg class="hover" iconClass="move" ></local-svg>
                                            </div>
                                            <span>{{item.name }}</span>
                                        </div>
                                        <div class="tab-right" @click="delCheck(item,index)">
                                            <local-svg class="img hover" iconClass="close" ></local-svg>
                                        </div>
                                    </div>
                                </div>    
                            </transition-group>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer btnpart">
                <div>
                    <el-button class="confirm" type="primary" @click="bindOk">确 定</el-button>
                    <el-button class="cancel" @click="cancel">取 消</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "showselect",
    props: {
        show:{
            type:Boolean,
            default:{
                return:false
            }
        },
        list:{
            type: Array,
            default:{
                return :[]
            }
        }
    },
    data() {
        return {
            showDialog:false,
            headerList:[],
            checkList:[],
            newData:[],
            oldData:[],
            image:require('../../assets/image/none.png'),
        };
    },
    mounted(){
        this.showDialog = this.show
        this.headerList = this.list 
        this.setList()
        this.bindOk = this.$debounce(this.bindOk )
    },
    methods: {
        checkAll(){
            for(var a=0;a<this.headerList.length;a++){
                if(this.headerList[a].check==false){
                    this.$set(this.headerList[a],'check',true)
                    this.checkList.push(this.headerList[a])
                }
            }
        },
        cancelAll(){
            for(var b=0;b<this.headerList.length;b++){
                for(var a=0;a<this.checkList.length;a++){
                    if(this.headerList[b].id==this.checkList[a].id){
                        if(a==0){
                            break
                        }
                        this.$set(this.headerList[b],'check',false)
                        this.checkList.splice(a,1)
                        break
                    }
                }
            }
        },
        setList(){
            this.headerList.forEach(item=>{
                if(item.check){
                    this.checkList.push(item)
                }
            })
        },
        dragstart(val) {
            this.oldData = val;
        },
        // 拖曳过程
        dragenter(value, e) {
            this.newData = value;
            e.preventDefault();
        },
        // 拖拽结束
        dragend(value, e) {
            if (this.oldData !== this.newData) {
                let newlist = [];
                let oldIndex = this.checkList.indexOf(this.oldData);
                let newIndex = this.checkList.indexOf(this.newData);
                let newItems = [...this.checkList];
                newItems.splice(oldIndex, 1);
                newItems.splice(newIndex, 0, this.oldData);
                this.checkList = [...newItems];
            }
        },
        // 拖动事件
        dragover(e) {
            e.preventDefault();
        },
        bindOk(){
            let list = this.checkList
            this.$emit('show',list)
        },
        cancel(){
            this.$emit('cancel',false)
        },
        delCheck(item,index){
            console.log(1)
            for(var a =0;a<this.headerList.length;a++){
                if(this.headerList[a].id==item.id){
                    this.$set(this.headerList[a],'check',false)
                }
            }
            this.checkList.splice(index,1)
        },
        checkTab1(item){
            let type = 'checkbox'
            this.checkTab(item,type)
        },
        checkTab(item,type){
            if(type!='checkbox'){
                item.check = item.check?false:true
            }
            let have = false
            for(var a =0;a<this.checkList.length;a++){
                if(this.checkList[a].id==item.id){
                    this.checkList.splice(a,1)
                    have = true
                    break
                }
            }
            if(have == false){
                this.checkList.push(item)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
*{
    box-sizing: border-box;
}
</style>

<style lang="scss" scoped>
.showselect {
    .dialog-top{
        border-top: 1px solid #F0F1F5;
        height: 380px;
        display: flex;
        padding: 16px 24px;
        .body-left{
            width: 310px;
            height: 100%;
            .line-tltle{
                display: flex;
                justify-content: space-between;
                .title{
                    font-weight: bold;
                    font-size: 14px;
                    color: #474952;
                }
            }
            .out-box{
                box-sizing: border-box;
                padding: 10px 0 ;
                background: #F4F7FC;
                height: 330px;
                margin-top: 8px;
            }
            .select-box{
                height: 300px !important;
                background: #F4F7FC;
                border-radius:4px ;
                overflow-y: scroll;
                margin-top: 0 !important;
                padding: 0 !important;
                .select-tab{
                    width: 100%;
                    height: 32px;
                    line-height: 32px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 12px 0 20px !important;
                }
                .tab-name{
                    width: 250px;
                }
                .select-tab:hover{
                    background:#EDF3FE;
                }
            }
            .active{
                color: #a2b3f8;
            }
            
        }
        .body-right{
            width: 220px;
            height: 100%;
            margin-left: 12px;
            .line-tltle{
                display: flex;
                justify-content: space-between;
                .title{
                    font-weight: bold;
                    font-size: 14px;
                    color: #474952;
                    line-height:24px ;
                }
            }
            .out-box{
                box-sizing: border-box;
                padding: 10px 0 ;
                height: 330px;
                margin-top: 8px;
            }
            .select-box{
                height: 300px !important;
                border-radius: 4px 4px 4px 4px;
                overflow-y: auto;
                border: none !important;
                margin-top: 0 !important;
                padding: 0 !important;
                .select-tab{
                    height: 28px;
                    display: flex;
                    align-items: center;
                    background: #F1F6FC;
                    border-radius: 4px 4px 4px 4px;
                    margin-top: 6px;
                    padding: 5px 0;
                    justify-content: space-between;
                    padding-right: 12px;
                    color: #475B93;
                    .tab-left{
                        display: flex;
                        .icon{
                            padding: 2px 12px 0 12px;
                            border-right: 1px solid #D9E6F5;
                        }
                        span{
                            padding-left: 12px ;
                        }
                    }
                    .tab-right{
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background: #DFE5EF;
                        display: none;
                        justify-content: center;
                        align-items: center;
                        .img{
                            width: 8px;
                            height: 8px;
                        }
                    }
                    
                }
                .check-show:hover{
                    color: #2C57FF;
                    .tab-right{
                        display: flex;
                        .img{
                            color: #B0B1B9;
                        }
                        .img:hover{
                            color: #2C57FF;
                        }
                    }
                }
                .nomove{

                }
            }
            .out-border{
                border: 1px solid #F0F1F5;
                border-radius: 4px;
                .box-border{
                    padding: 0 10px !important;
                }
            }
            
        }
    }
    .dialog-footer{
        display: flex;
        justify-content: flex-end;
        padding-bottom: 10px;
    }
}
</style>
