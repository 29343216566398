<template>
    <div class="home">
        <div class="main">
            <div class="left">
                <div class="top">
                    <div class="top-title">平台角色</div>
                    <div class="top-add hover" @click="addNewRole" v-permission="'角色管理-create_role'">
                        <local-svg iconClass="addrole" class="img1"></local-svg>
                        <span>新建角色</span>
                    </div>
                </div>
                <roleList :list="roleList" @change="editRole" @delete="deleteRole" @ckeck="checkList" @search="searchUser"></roleList>
            </div>
            <div class="right">
                <roleTable v-if="tableData.length>0" :table="tableData" @save="savePower" ref="power"></roleTable>
            </div>
            <addRole v-if="showadd" :show="showadd" :form="roleForm" @close="cancelAdd" :type="partType" @change="partChange"  @edit="editRoleDetail" :numtype="numType"></addRole>
        </div>
    </div>
</template>

<script>
import {getrole,addrole,delrole,editrole,powerlist,addpower,powerdetail} from '@/api/terrace'
import addRole from '@/components/terrace/role/addrole.vue'
import roleList from '@/components/terrace/power/role.vue'
import roleTable from '@/components/terrace/power/table.vue'
import { off } from 'process'
export default {
    name: 'depart-terrace',
    data() {
        return {
            roleForm:{},
            showadd: false,
            roleList:[],
            tableData: [],
            cloneData:[],
            partType:1,//状态 1、添加  2、修改
            r_id:'',
            numType:'1'
        }
    },
    components: {
        roleList,
        addRole,
        roleTable
    },
    mounted(){
        this.getPowerList()
        this.getRoleList()
    },
    methods: {
        searchUser(msg){
            this.getRoleList(msg)
        },
        savePower(data){
            console.log(data.data)
            let table = data.data
            let menulist = []
            let pagelist = []
            let buttonlist = []
            for(var a = 0;a<table.length;a++){
                let item = table[a]
                if(item.checkmodel == true){
                    menulist.push(item.model_id)
                    if(item.checkpage == true){
                        pagelist.push(item.page_id)
                        for(var b = 0;b<item.checkbtn.length;b++){
                            buttonlist.push(item.checkbtn[b])
                        }
                    }
                }
            }
            let obj = {
                r_id:this.r_id,
                menu:[...new Set(menulist)].toString(),
                page:pagelist.toString(),
                button:buttonlist.toString(),
                data:data.numberType,
            }
            addpower(obj).then(res =>{
                if(res.code==200){
                    this.$message({
                        type: 'success',
                        message: res.msg
                    });
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
            })
        },
        getPowerList(){
            powerlist().then(res=>{
                this.tableData = this.setList(res.data.option)
                this.cloneData = res.data.option
                // localStorage.setItem('x_token',res.data.token)
            })
        },
        setList(powerlist){
            let list = powerlist
            let lastList = []
            for(var a = 0;a < list.length;a ++){
                for(var b = 0;b < list[a].page.length;b ++){
                    lastList.push({
                        model_id:list[a].id,
                        model:list[a].name,
                        page:list[a].page[b].name,
                        page_id:list[a].page[b].id,
                        btn:list[a].page[b].button,
                        sort:b==0?list[a].page.length:0,
                        checkbtn:[],
                        checkpage:false,
                        checkmodel:false,
                    })
                }
            }
            return lastList
        },
        getList(){
            let data = {
                r_id:this.r_id
            }
            powerdetail(data).then(res=>{
                // this.tableData = []
                // this.tableData = this.setList(this.cloneData)
                // console.log(this.tableData,111)
                this.numType = res.data.data_permission
                for(var a = 0;a<res.data.functional_permission.length;a++){
                    let item = res.data.functional_permission[a]
                    for(var b = 0;b<this.tableData.length;b++){
                        let initem = this.tableData[b]
                        if(item.name == initem.model){
                            this.$set(initem,'checkmodel',true)
                            for(var c = 0;c<item.page.length;c++){
                                let pageitem = item.page[c]
                                if(pageitem.name == initem.page){
                                    this.$set(initem,'checkpage',true)
                                    if(pageitem.button){
                                        initem.checkbtn = pageitem.button
                                    }
                                    
                                }
                            }
                        }
                    }
                }
                console.log(this.numType,111)
                this.$refs.power.changNum(this.numType)
            })
        },
        checkList(msg){
            this.r_id = msg
            for(var a = 0;a<this.tableData.length;a++){
                this.$set(this.tableData[a],'checkmodel',false)
                this.$set(this.tableData[a],'checkpage',false)
                this.$set(this.tableData[a],'checkbtn',[])
            }
            this.getList()
        },
        deleteRole(msg){
            let data = {
                id:msg.id
            }
            delrole(data).then(res=>{
                if(res.code==200){
                    this.getRoleList()  
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
            })
        },
        getRoleList(msg){
            let data = {
                name:msg?msg:''
            }
            getrole(data).then(res=>{
                this.roleList = res.data
                if(!this.r_id){
                    this.r_id = this.roleList[0].id
                }
                this.getList()
            })
        },
        editRole(msg){
            this.roleForm = msg
            this.showadd = true
            this.partType = 2
        },
        addNewRole(){
            this.showadd = true
            this.partType = 1
        },
        cancelAdd(){
            this.showadd = false
        },
        partChange(msg){
            addrole(msg).then(res=>{
                if(res.code==200){
                    this.getRoleList()  
                    this.showadd = false
                    this.$message({
                        type: 'success',
                        message: '添加成功!'
                    });
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
            })
        },
        editRoleDetail(msg){
            let data = {
                id:msg.id,
                name:msg.name,
                intro:msg.intro,
                code:msg.code,
                sort:msg.sort,
            }
            editrole(data).then(res=>{
                if(res.code==200){
                    this.getRoleList()
                    this.showadd = false
                    this.$message({
                        type: 'success',
                        message: '修改成功！'
                    });
                }else{
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                }
            })
        },
    },

}

</script>

<style lang="scss" scoped>
* {
    box-sizing: content-box;
}

.home {
    padding: 24px;

    .main {
        display: flex;

        .left {
            height: calc(100vh - 108px);
            width: 280px;
            background: white;
            border-radius: 4px;

            .top {
                height: 56px;
                border: 1px solid #F0F1F5;
                padding: 22px 20px 0 20px;
                display: flex;
                justify-content: space-between;
                box-sizing: border-box;

                .top-title {
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: bold;
                    color: #474952;
                }

                .top-add {
                    display: flex;
                    color: #475B93 !important;
                    .img1 {
                        width: 22px;
                        height: 20px;
                        color: #475B93;
                    }
                    span {
                        font-size: 14px;
                        line-height: 18px;
                        margin-left: 8px;
                    }
                }
                .top-add:hover{
                    color: #2C57FF !important;
                    .img1{
                        color: #2C57FF !important;
                    }
                    
                }
                .top-add:active{
                    color: #133CDC !important;
                    .img1{
                        color: #133CDC !important;
                    }
                }
            }
        }
        .right{
            width:calc(100% - 290px);
        }
    }
}</style>