<template>
    <div class="adduser">
        <el-dialog :title="title" :visible.sync="showDialog" width="400px" class="addform" @close="cancel">
            <div class="addform contractform">
                <div class="descript-main examine-again" style="margin-top: 16px;">
                    <el-descriptions :column="1" border>
                        <el-descriptions-item label="单项明细名称">{{ form.name ? form.name : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="价格（元）">{{ form.amount ? form.amount : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="业务类型">{{ form.business_type ? form.business_type : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="业务明细">{{ form.business_detail ? form.business_detail : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="创建人">{{ form.create_name ? form.create_name : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="创建时间">{{ form.create_time ? form.create_time : '/' }}</el-descriptions-item>
                    </el-descriptions>
                </div>
            </div>
            <div slot="footer" class="dialog-footer btnpart">
                <el-button class="cancel" @click="cancel">关闭</el-button>
            </div>
            
        </el-dialog>
    </div>
</template>

<script>
import {selectlist} from '@/api/contarct'
import localSvg from '@/icons/localSvg.vue'
import md5 from 'js-md5'
import service from "@/utils/request.js";
import { Loading } from 'element-ui';
export default {
  components: { localSvg },
    name: "adduser",
    props: {
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        form:{
            type:Object,
        	default:()=>{
        		return {}
        	}
        },
    },
    data() {
        return {
            uploadUrl:process.env.VUE_APP_BASE_URL+'/common/uploadImg',
            list:[],
            actIndex:0,
            tabList:[
                {name:'基本信息'},
                {name:'单项明细'}
            ],
            fileList:[],
            addform:{
            },
            sellList:[],
            title:'合同单项明细',
            showDialog:false,
            info:{

            }
        };
    },
    mounted(){
        this.showDialog = this.show
    },
    methods: {
        cancel(){
            this.$emit('cancel',false)
        },
        clean(){
            
        },
    }
}
</script>

<style lang="scss">
.adduser {
    .headtab{
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 15px 0 10px 0 ;
        .tab-box{
            display: flex;
            justify-content: space-between;
            width: 132px;
            font-size: 14px;
            border-bottom: 1px solid #F0F1F5;
            box-sizing: content-box;
            height: 25px;
            .tab{
                color: #B0B1B9;
                height: 100%;
            }
            .tab:hover{
                color: #2C57FF;
            }
            .acttab{
                font-weight: bold !important;
                color: #6B6D77 !important;
                border-bottom:2px solid #2C57FF !important;
                width: auto;
                height: 100%;
            }
        }
    }
    .filelistpart {
        .filelist{
            width: 100%;
            height: auto !important;
            overflow-y: auto;
            padding-right: 8px ;
            box-sizing: border-box;
            .fileline{
                display: flex;
                height: 28px;
                border-radius: 4px;
                background: #F4F7FC;
                width: 100%;
                align-items: center;
                margin-bottom: 6px;
                padding: 0 12px 0 8px;
                justify-content: space-between;
                box-sizing: border-box;
                .icon{
                    margin-right: 7px;
                    width: 12px;
                    height: 14px;
                }
                .name{
                    font-size: 12px;
                    color: #474952;
                }
                .close{
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background: rgba(63,69,124,0.1);
                    display: none;
                    align-items: center;
                    justify-content: center;
                    .closeicon{
                        width: 8px;
                        height: 8px;
                        fill: #B0B1B9;
                    }
                }
                .close:hover{
                    background: rgba(44, 87, 255, 0.2) !important;
                    .closeicon{
                        fill: #2C57FF !important;
                    }
                }
            }
            .fileline:hover{
                background:#EDF3FE !important;
                .close{
                    display: flex;
                }
            }
        }
        .nopaper{
            height: 270px  !important;
        }
    }
    .file{
        width: 100%;
        height: auto;
            .filepart{
            width: 100%;
            display: flex;
            .filetip{
                font-size: 10px;
                color: #D0D0D2;
                line-height: 45px;
                margin-left: 8px;
            }
            .icon{
                margin-right: 7px;
            }
        }
    }
    .formbox{
        height: 430px;
        .el-select .el-input .el-select__caret{
            display: flex; 
            justify-content: center;
            align-items: center;
        }
        .table-main{
            padding: 14px 0 ;
            box-sizing: border-box;
            .table-title{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                .title{
                    font-weight: bold;
                    color: #6B6D77;
                }
                .addpart{
                    .icon{
                        fill: #475B93 !important;
                    }
                }
                .addpart:hover{
                    color: #2C57FF !important;
                    .svg-icon{
                        fill: #2C57FF !important;
                    }
                }
                .addpart:active{
                    color: #133CDC !important;
                    .svg-icon{
                        fill: #133CDC !important;
                    }
                }
            }
            .table{
                margin-top: 12px;
            }
        }
    }
}
.handlerTab_tooltip{
    border: none !important;
        box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
        color: #6B6D77 !important;
        padding: 8px 16px !important;
        line-height: 20px !important;
}
.handlerTab_tooltip.is-light{
    border: none !important;
}
.handlerTab_tooltip .popper__arrow {
    /* 上方箭头 */
    border-top-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
    /* 下方箭头 */
    border-bottom-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
}
</style>
