<template>
    <div class="adduser">
        <el-dialog :title="title" :visible.sync="showDialog" width="800px" class="addform" @close="cancel">
            <div class="addform contractform">
                <div class="descript-main examine-again">
                    <el-descriptions :column="1" border>
                        <el-descriptions-item label="费用申请单号">{{ form.number ? form.number : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="支出事由(新)">{{ form.cause ? form.cause : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="支出金额">{{ form.amount ? form.amount : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="支出账户">{{ form.pay_account ? form.pay_account : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="实际支出时间">{{ form.pay_time ? form.pay_time : '/' }}</el-descriptions-item>
                    </el-descriptions>
                </div>
                <div class="between" style="margin: 12px 0 5px;width: 100%;display: flex;justify-content: space-between;align-items: center;">
                    <span><span class="check-form-notice">{{ type==1?'*':'' }}</span>{{ type==1?'票据上传':'票据信息' }}</span>
                    <div class="button-part" @click="downloadFile"  v-if="type == 2">
                        <button class="cancel-small hover">下载压缩包</button>
                    </div>
                </div>
                <el-form :model="addform">
                    <el-row>
                        <el-col :span="24">
                            <el-form-item class="long-input btnpart"  >
                                
                                <div class="file" v-if="type == 1">   
                                    <div class="filepart">
                                        <el-upload
                                            class="upload-demo"
                                            :http-request="uploadFile"
                                            action=""
                                            >
                                            <el-button class="uploadbtn"><local-svg iconClass="upload" class="icon" plain></local-svg>上传附件</el-button>
                                        </el-upload>
                                        <div class="filetip"><span>文件大小不超过50MB</span></div>
                                    </div>
                                </div>
                            </el-form-item>
                        </el-col>  
                        <el-col :span="24" class="filelistpart">
                            <div class="filelist" v-if="type ==1">
                                <div class="fileline hover" v-for="(item,index) in fileList" :key="index" @click="seeFile(item)">
                                    <div>
                                        <local-svg iconClass="file" class="icon"></local-svg>
                                        <span class="name">{{ item.name }}</span>
                                    </div>
                                    <div class="close" @click.stop="delFile(index)" v-if="type==1">
                                        <local-svg iconClass="close" class="closeicon"></local-svg>
                                    </div>
                                </div>
                            </div>
                            <div class="filelist" v-else>
                                <div class="fileline hover" v-for="(item,index) in form.file" :key="index" @click="seeFile2(item)">
                                    <div>
                                        <local-svg iconClass="file" class="icon"></local-svg>
                                        <span class="name">{{ item.name }}</span>
                                    </div>
                                    <div class="close" @click.stop="delFile(index)" v-if="type==1">
                                        <local-svg iconClass="close" class="closeicon"></local-svg>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer btnpart">
                <el-button v-if="type==1" class="confirm" type="primary" @click="addOk">提交</el-button>
                <el-button v-if="type==2" class="confirm" type="primary" @click="examineOk">确定</el-button>
                <el-button v-if="type==1" class="cancel" @click="cancel">取消</el-button>
                <el-button v-if="type==2" class="cancel" @click="examineBack">驳回</el-button>
            </div>
            
        </el-dialog>
    </div>
</template>

<script>
import {downloadrar} from '@/api/const'
import localSvg from '@/icons/localSvg.vue'
import md5 from 'js-md5'
import service from "@/utils/request.js";
import { Loading } from 'element-ui';
export default {
  components: { localSvg },
    name: "adduser",
    props: {
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        form:{
            type:Object,
        	default:()=>{
        		return {}
        	}
        },
        type:{
            type:Number,
            default:() =>{
                return 1
            }
        },
    },
    data() {
        return {
            uploadUrl:process.env.VUE_APP_BASE_URL+'/common/uploadImg',
            list:[],
            actIndex:0,
            tabList:[
                {name:'基本信息'},
                {name:'单项明细'}
            ],
            fileList:[],
            addform:{
            },
            sellList:[],
            title:'票据信息',
            showDialog:false,
            info:{

            }
        };
    },
    mounted(){
        this.showDialog = this.show
        if(this.type == 2 ){
            setTimeout(()=>{
                this.fileList = this.form.file
            },100)
        }
    },
    methods: {
        seeFile2(item){
            let url = item.image
            window.open(url,'_blank')
        },
        seeFile(item){
            // let url = process.env.VUE_APP_BASE_URL + '/' +item.file_url
            let url = item.file_url
            window.open(url,'_blank')
        },
        examineOk(){
            let data = {
                b_id:this.form.id
            }
            this.$emit('examine',data)
        },
        downloadFile(){
            Loading.service({
                text: "加载中...."
            });
            let data = {
                id:this.form.id
            }
            downloadrar(data).then(res=>{
                const elink = document.createElement('a');
                elink.download = '附件.rar';
                elink.style.display = 'none';
                const blob = new Blob([res], { type: 'application/x-msdownload' });
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                document.body.removeChild(elink);
                Loading.service().close()
                this.$message.success('下载成功')
            }).catch(error =>{
                Loading.service().close()
                this.$message.error('下载失败')
            })
        },
        examineBack(){
            let data = {
                b_id:this.form.id
            }
            this.$emit('noexamine',data)
        },
        delFile(index){
            this.fileList.splice(index,1)
        },
        uploadFile(obj){
            console.log(obj)
            let fileObj = obj.file
            const { size, name } = fileObj
            let md5code = md5(name + new Date().getTime())
            let length = 500000
            let start = 0
            let end = 0
            let i = 1;
            Loading.service({
                text: "上传中...."
            });
            this.testHandle(md5code, length, start, end, size, name, fileObj, i)
        },
        testHandle(md5code, length, start, end, size, name, fileObj, i) {
            if (length > size) {
                length = size
            }
            if (end < size) {
                end = start + length
                if (end > size) {
                    end = size
                }
                let blob = ""
                blob = fileObj.slice(start, end)
                start = end
                let fd = new FormData()
                fd.append("file", blob)
                fd.append("md5code", md5code)
                let suc = 2
                if (end == size) {
                    suc = 1
                }
                fd.append("suc", suc)
                fd.append("tmp", i)
                fd.append("name", name)
                i = i + parseInt(1)
                service({
                    url: "/common/uploadImg",
                    method: "post",
                    data: fd,
                }).then(res => {
                    if (res.code == 200) {
                        if (suc == 2) {
                            this.testHandle(md5code, length, start, end, size, name, fileObj, i)
                        } else {
                            this.$message.success(res.msg)
                            Loading.service().close()
                            if (res.data.url !== undefined) {
                                this.fileList.push({ name: name, file_url: res.data.url })
                                console.log(this.fileList,111)
                            }
                        }
                    } else {
                        this.$message.error(res.msg)
                        Loading.service().close()
                    }
                })
                .catch(err => {
                    this.$message.error("请重新上传")
                    Loading.service().close()
                })
            }
        },
        addOk(){
            if(this.fileList.length==0){
                this.$message.error('请上传票据')
                return
            }
            let list = []
            this.fileList.forEach(item =>{
                list.push({
                    name: item.name, 
                    image: item.file_url
                })
            })
            let data = {
                b_id:this.form.id,
                file:list
            }
            this.$emit('submit',data)
            
        },
        editOk(addform,type){
            this.$refs[addform].validate((valid) => {
                if (valid) {
                    this.addform.files = this.fileList
                    this.$emit('edit',this.addform)
                } else {
                    return false;
                }
            });
        },
        cancel(){
            this.$emit('cancel',false)
        },
        clean(){
            
        },
    }
}
</script>

<style lang="scss">
.adduser {
    .headtab{
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 15px 0 10px 0 ;
        .tab-box{
            display: flex;
            justify-content: space-between;
            width: 132px;
            font-size: 14px;
            border-bottom: 1px solid #F0F1F5;
            box-sizing: content-box;
            height: 25px;
            .tab{
                color: #B0B1B9;
                height: 100%;
            }
            .tab:hover{
                color: #2C57FF;
            }
            .acttab{
                font-weight: bold !important;
                color: #6B6D77 !important;
                border-bottom:2px solid #2C57FF !important;
                width: auto;
                height: 100%;
            }
        }
    }
    .filelistpart {
        .filelist{
            width: 100%;
            height: auto !important;
            overflow-y: auto;
            padding-right: 8px ;
            box-sizing: border-box;
            .fileline{
                display: flex;
                height: 28px;
                border-radius: 4px;
                background: #F4F7FC;
                width: 100%;
                align-items: center;
                margin-bottom: 6px;
                padding: 0 12px 0 8px;
                justify-content: space-between;
                box-sizing: border-box;
                .icon{
                    margin-right: 7px;
                    width: 12px;
                    height: 14px;
                }
                .name{
                    font-size: 12px;
                    color: #474952;
                }
                .close{
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background: rgba(63,69,124,0.1);
                    display: none;
                    align-items: center;
                    justify-content: center;
                    .closeicon{
                        width: 8px;
                        height: 8px;
                        fill: #B0B1B9;
                    }
                }
                .close:hover{
                    background: rgba(44, 87, 255, 0.2) !important;
                    .closeicon{
                        fill: #2C57FF !important;
                    }
                }
            }
            .fileline:hover{
                background:#EDF3FE !important;
                .close{
                    display: flex;
                }
            }
        }
        .nopaper{
            height: 270px  !important;
        }
    }
    .file{
        width: 100%;
        height: auto;
            .filepart{
            width: 100%;
            display: flex;
            .filetip{
                font-size: 10px;
                color: #D0D0D2;
                line-height: 45px;
                margin-left: 8px;
            }
            .icon{
                margin-right: 7px;
            }
        }
    }
    .formbox{
        height: 430px;
        .el-select .el-input .el-select__caret{
            display: flex; 
            justify-content: center;
            align-items: center;
        }
        .table-main{
            padding: 14px 0 ;
            box-sizing: border-box;
            .table-title{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                .title{
                    font-weight: bold;
                    color: #6B6D77;
                }
                .addpart{
                    .icon{
                        fill: #475B93 !important;
                    }
                }
                .addpart:hover{
                    color: #2C57FF !important;
                    .svg-icon{
                        fill: #2C57FF !important;
                    }
                }
                .addpart:active{
                    color: #133CDC !important;
                    .svg-icon{
                        fill: #133CDC !important;
                    }
                }
            }
            .table{
                margin-top: 12px;
            }
        }
    }
}
.handlerTab_tooltip{
    border: none !important;
        box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
        color: #6B6D77 !important;
        padding: 8px 16px !important;
        line-height: 20px !important;
}
.handlerTab_tooltip.is-light{
    border: none !important;
}
.handlerTab_tooltip .popper__arrow {
    /* 上方箭头 */
    border-top-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
    /* 下方箭头 */
    border-bottom-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
}
</style>
