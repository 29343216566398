<template>
    <div class="adduser">
        <el-dialog :title="title" :visible.sync="showDialog" width="1000px" class="addform" @close="cancel" :close-on-click-modal="false">
            <div class="addform contractform model">
                <div class="formbox">
                    <el-form :model="addform" :rules="rules" ref="addform" >
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="模版标题">
                                    <el-input v-model="addform.title" placeholder="请输入模版标题"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="消息内容模版" class="long-input">
                                    <el-input v-model="addform.content" type="textarea" placeholder="请输入消息内容模版"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="发布方式">
                                    <div class="tablepart">    
                                        <div class="top">   
                                            <el-radio-group v-model="addform.method_id">
                                                <el-radio label="7">站内</el-radio>
                                                <!-- <el-radio label="2">邮箱</el-radio> -->
                                                <el-radio label="8">短信</el-radio>
                                            </el-radio-group>                 
                                        </div>    
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="模版状态" class="opentype">
                                    <el-switch
                                        v-model="addform.open"
                                        active-color="#10C257"
                                        inactive-color="#DADCE3">
                                    </el-switch>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </div>
            <div slot="footer" class="dialog-footer btnpart">
                <el-button class="confirm" type="primary" @click="editOk('addform')">保存</el-button>
                <el-button class="cancel" @click="cancel">取消</el-button>
            </div>
            
        </el-dialog>
    </div>
</template>

<script>
import localSvg from '@/icons/localSvg.vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import md5 from 'js-md5'
import service from "@/utils/request.js";
import { Loading } from 'element-ui';
import receiveCost from '@/components/constapply/receive.vue'
import { ok } from 'assert';
export default {
    components: { localSvg},
    name: "adduser",
    props: {
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        form:{
            type:Object,
        	default:()=>{
        		return {}
        	}
        },
        type:{
            type:Number,
            default:() =>{
                return 1
            }
        },
    },
    components:{
        receiveCost,
        Editor,
        Toolbar
    },
    data() {
        return {
            list:[],
            addform:{
                radio:'1',
                time:1,
                num:1,
                value:true
            },
            title:'编辑消息模版',
            showDialog:false,
            rules: {},
        };
    },
    watch:{
    },
    created(){
        setTimeout(() => {
            this.html = ''
        }, 1500)
    },
    mounted(){
        this.showDialog = this.show
        if(this.type == 2 ){
            this.addform = this.form
            this.addform.method_id = this.form.method_id+''
            this.$set(this.addform,'open',this.addform.status==1?true:false)
        }
        
    },
    methods: {
        addOk(addform){
            this.$refs[addform].validate((valid) => {
                if (valid) {
                    this.$emit('add',this.addform)
                } else {
                    return false;
                }
            });
            
        },
        editOk(addform){
            this.$refs[addform].validate((valid) => {
                if (valid) {
                    this.$emit('edit',this.addform)
                } else {
                    return false;
                }
            });
        },
        cancel(){
            this.$emit('cancel',false)
        },
        clean(){
            
        },
        
    }
}

</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss">
.adduser {
    .headtab{
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 15px 0 10px 0 ;
        .tab-box{
            display: flex;
            justify-content: space-between;
            width: 132px;
            font-size: 14px;
            border-bottom: 1px solid #F0F1F5;
            box-sizing: content-box;
            height: 25px;
            .tab{
                color: #B0B1B9;
                height: 100%;
            }
            .tab:hover{
                color: #2C57FF;
            }
            .acttab{
                font-weight: bold !important;
                color: #6B6D77 !important;
                border-bottom:2px solid #2C57FF !important;
                width: auto;
                height: 100%;
            }
        }
    }
    .right{
        margin-left: 45px;
        .filelist{
            width: 100%;
            height: 200px;
            overflow-y: auto;
            padding-right: 8px ;
            box-sizing: border-box;
            .fileline{
                display: flex;
                height: 28px;
                border-radius: 4px;
                background: #F4F7FC;
                width: 100%;
                align-items: center;
                margin-bottom: 6px;
                padding: 0 12px 0 8px;
                justify-content: space-between;
                box-sizing: border-box;
                .icon{
                    margin-right: 7px;
                    width: 12px;
                    height: 14px;
                }
                .name{
                    font-size: 12px;
                    color: #474952;
                }
                .close{
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background: rgba(63,69,124,0.1);
                    display: none;
                    align-items: center;
                    justify-content: center;
                    .closeicon{
                        width: 8px;
                        height: 8px;
                        fill: #B0B1B9;
                    }
                }
                .close:hover{
                    background: rgba(44, 87, 255, 0.2) !important;
                    .closeicon{
                        fill: #2C57FF !important;
                    }
                }
            }
            .fileline:hover{
                background:#EDF3FE !important;
                .close{
                    display: flex;
                }
            }
        }
    }
    .file{
        width: 100%;
        height: auto;
            .filepart{
            width: 100%;
            display: flex;
            .filetip{
                font-size: 10px;
                color: #D0D0D2;
                line-height: 45px;
                margin-left: 8px;
            }
            .icon{
                margin-right: 7px;
            }
        }
    }
    .formbox{
        height: 430px;
        .el-select .el-input .el-select__caret{
            display: flex; 
            justify-content: center;
            align-items: center;
        }
        .table-main{
            padding: 14px 0 ;
            box-sizing: border-box;
            .table-title{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                .title{
                    font-weight: bold;
                    color: #6B6D77;
                }
                .addpart{
                    .icon{
                        fill: #475B93 !important;
                    }
                }
                .addpart:hover{
                    color: #2C57FF !important;
                    .svg-icon{
                        fill: #2C57FF !important;
                    }
                }
                .addpart:active{
                    color: #133CDC !important;
                    .svg-icon{
                        fill: #133CDC !important;
                    }
                }
            }
            .table{
                margin-top: 12px;
            }
        }
    }
    .set-timebox{
        width: 480px;
        height: 340px;
        border-radius: 4px;
        position: absolute;
        background: #fff;
        left: 170px;
        top: 42px;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        .box-header{
            display: flex;
            flex-wrap: nowrap;
            height: 52px;
            border-bottom: 1px #F0F1F5 solid;
            .date{
                width: 315px;
                text-align: center;
                line-height: 52px;
                font-size: 14px;
                color: #474952;
            }
            .week{
                width: 186px;
                text-align: center;
                line-height: 52px;
                font-size: 14px;
                color: #474952;
            }
            .time{
                width: 185px;
                text-align: center;
                line-height: 52px;
                font-size: 14px;
                color: #474952;
            }
        }
        .box-select{
            display: flex;
            flex-wrap: nowrap;
            height: 238px;
            border-bottom: 1px #F0F1F5 solid;
            .date{
                width: 315px;
                box-sizing: border-box;
                display: flex;
                flex-wrap: wrap;
                padding: 15px 3px 0 21px;
                .date-num{
                    margin-left: 3px;
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #6B6D77;
                    font-size: 14px;
                }
                .date-num:hover{
                    background-color: #F2F4FB;
                }
                .actdate{
                    background-color: #2C57FF !important;
                    color: white;
                }
            }
            .week{
                width: auto;
                box-sizing: border-box;
                display: flex;
                flex-wrap: wrap;
                overflow-y:auto;
                .date-num{
                    width: 100%;
                    height: 34px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #6B6D77;
                    font-size: 14px;
                }
                .date-num:hover{
                    background-color: #F2F4FB;
                }
                .actdate{
                    background-color: #2C57FF !important;
                    color: white;
                }
            }
            .week::-webkit-scrollbar {
                display: none;
            }
            .time{
                padding:0 0 ;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                width: 185px;
                .time-list{
                    width: 60px;
                    overflow-y:auto;
                    text-align: center;
                    box-sizing: border-box;
                    border-left: 1px #F0F1F5 solid;
                    .list-child{
                        line-height: 34px;
                        height: 34px;
                        color: #B0B1B9;
                    }
                    .list-child:hover{
                        color: #2C57FF;
                        font-weight: bold;
                    }
                    .acttime{
                        // background-color: ;
                        color:#6B6D77 !important;
                        background: #EDF3FE;
                        font-weight: bold;
                    }
                }
                .time-list::-webkit-scrollbar {
                    display: none;
                }
            }
        }
        .box-bottom{
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 8px 20px 11px 24px;
            align-items: center;
            .date{
                line-height: normal;
                color: #474952;
                font-weight: bold;
            }
            .btnlist{
                line-height: normal;
                display: flex;
                align-items: center;
                .rightnow{
                    margin-right: 20px;
                }
                button{
                    width: 56px !important;
                    text-align: center;
                    padding: 0;
                }
            }
        }
    }
    .set-timebox-week{
        width: 366px !important;
        
    }
    .set-timebox-week{
        // width:185px !important;
        
    }
}
.handlerTab_tooltip{
    border: none !important;
        box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
        color: #6B6D77 !important;
        padding: 8px 16px !important;
        line-height: 20px !important;
}
.handlerTab_tooltip.is-light{
    border: none !important;
}
.handlerTab_tooltip .popper__arrow {
    /* 上方箭头 */
    border-top-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
    /* 下方箭头 */
    border-bottom-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
}
.remark{
    .el-textarea__inner{
        height: 80px !important;
        line-height: 26px !important;
        padding: 5px 12px !important;
        font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    }
    .el-textarea__inner:hover{
        border-color: #2C57FF !important;
        
    }
}
.checkcontract{
    width: auto !important;
}
</style>
