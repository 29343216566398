<template>
    <div class="home">
        <div class="main">
            <div class="left">
                <div class="top">
                    <div class="top-title">公司各部门</div>
                    <div class="top-add hover" @click="addPart"  v-permission="'部门管理-create'">
                        <local-svg iconClass="addpart" class="img1"></local-svg>
                        <span >新建部门</span>
                    </div>
                </div>
                <treeList ref="treepart" @checkpart="checkPart" :data="data" @edit="beginEdit" @addchild="addTreeLeave" @deltree="deleteTree"></treeList>
            </div>
            <div class="right">
                <div class="top-serch">
                    <el-form :model="form" class="demo-form-inline" label-width="">
                        <div class="select-line">
                            <el-form-item label="用户名">
                                <el-input class="input-height" v-model="form.name" placeholder="请输入关键字搜索"></el-input>
                            </el-form-item>
                            <el-form-item label="状态">
                                <el-select v-model="form.freeze" placeholder="请选择">
                                    <el-option v-for="item in statusList" :label="item.name" :value="item.id"
                                        :key="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="职位状态" ref="search3">
                                <el-select v-model="form.resign" placeholder="请选择" clearable>
                                    <el-option v-for="item in resignList" :label="item.name" :value="item.id"
                                        :key="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="line"></div>
                        <div class="btn-line">
                            <div class="btnpart">
                                <el-button class="confirm" @click="getUserList('search')" plain v-permission="'部门管理-select'">查询</el-button>
                                <el-button class="cancel" @click="cleanSearch" plain v-permission="'部门管理-reset'">重置</el-button>
                            </div>
                            <div class="showpart hover handel"  style="display: none;">
                                {{ actText }}
                                <local-svg iconClass="showdown" class="icon"></local-svg>
                            </div>
                        </div>
                    </el-form>
                </div>
                <div class="bottom-table">
                    <partTable :list="tableData" @bind="bindUser" :select="part_id" @bindout="bindOut"></partTable>
                    <div class="pagetab">
                        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper" :total="total">
                        </el-pagination>
                    </div>
                    <addPart v-if="showadd" :show="showadd" :form="partForm" :departlist="data" @close="cancelAdd" :type="partType" @change="partChange" @edit="editPart" ></addPart>
                    <bindPart v-if="showbind" :show="showbind" :selectlist="selectList" @close="cancelBind" @bind="bindOk" :id="this.part_id"></bindPart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {partlist,addpart,delpart,editpart,binduser,partmenber,bindout} from '@/api/terrace'
import bindPart from '@/components/terrace/part/bindpart.vue'
import addPart from '@/components/terrace/part/addpart.vue'
import partTable from '@/components/terrace/part/part.vue'
import treeList from '@/components/terrace/part/tree.vue'
export default {
    name: 'depart-terrace',
    data() {
        return {
            hide:false,
            partForm:{},
            currentPage:1,
            pageSize:10,
            total:0,
            actText:'展开',
            showadd: false,
            showbind:false,
            tableData: [ ],
            form: {
                name: '',
                date: [],
                freeze:1,
                resign:1
            },
            data: [
                
            ],
            props: {
                label: 'name',
                children: 'zones'
            },
            selectList:[
                {
                    label:'全部',
                    value:''
                },
                {
                    label:'用户名',
                    value:'name'
                },
                {
                    label:'手机号',
                    value:'mobile'
                },
                {
                    label:'电子邮箱',
                    value:'email'
                },

            ],
            userData:[
                {
                    name: '陈一鸣 ',
                    part: '开发部|设计部…',
                    role: '开发|设计|测试…',
                    tel:'13333130331',
                    mail:'1376596654@qq.com',
                    is_open:false
                },
                {
                    name: '陈一鸣 ',
                    part: '开发部|设计部…',
                    role: '开发|设计|测试…',
                    tel:'13333130331',
                    mail:'1376596654@qq.com',
                    is_open:true
                },
                {
                    name: '陈一鸣 ',
                    part: '开发部|设计部…',
                    role: '开发|设计|测试…',
                    tel:'13333130331',
                    mail:'1376596654@qq.com',
                    is_open:true
                },
                {
                    name: '陈一鸣 ',
                    part: '开发部|设计部…',
                    role: '开发|设计|测试…',
                    tel:'13333130331',
                    mail:'1376596654@qq.com',
                    is_open:true
                }
            ],
            part_id:'',
            partType:1,//状态 1、添加  2、修改
            statusList:[
                {name:'禁用',id:0},
                {name:'启用',id:1}
            ],
            resignList:[
                {name:'在职',id:1},
                {name:'离职',id:0},
            ],
        }
    },
    components: {
        treeList,
        partTable,
        addPart,
        bindPart
    },
    mounted(){
        this.getList()
    },
    methods: {
        editPart(msg){
            editpart(msg).then(res=>{
                if(res.code==200){
                    this.getList()
                    this.showadd = false
                    this.$message({
                        type: 'success',
                        message: '修改成功!'
                    });
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
            })
        },
        checkPart(msg){
            console.log(msg)
            this.part_id = msg.toString()
            this.getUserList()
        },
        cleanSearch(){
            this.form = {
                name:'',
                date:[],
                resign:1,
                freeze:1
            }
            this.getUserList('search')
        },
        getUserList(e){
            if(e){
                this.currentPage = 1
            }
            let data = {
                page:this.currentPage,
                count:this.pageSize,
                name:this.form.name,
                freeze:this.form.freeze,
                resign:this.form.resign,
                b_id:this.part_id?this.part_id:this.data[0].id
            }
            partmenber(data).then(res=>{
                this.tableData = res.data.table
                let item =this.tableData
                let rolearray = ''
                // for(var a=0;a<item.length;a++){
                //     if(item[a].r_name){
                //         for(var b=0;b<item[a].r_name.length;b++){
                //             if(b==item[a].r_name.length-1){
                //                 rolearray = rolearray + item[a].r_name[b]
                //             }else{
                //                 rolearray = rolearray + item[a].r_name[b]+'/'
                //             }
                //         }
                //     }
                //     item[a].rolearray = rolearray
                //     rolearray = ''
                // }
                this.total = res.data.total
            })
        },
        deleteTree(msg){
            let data = { 
                id:msg
            }
            delpart(data).then(res=>{
                if(res.code==200){
                    this.getList()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
            })
        },
        addTreeLeave(msg){
            this.showadd = true
            this.partForm = msg
            this.partType = 1
        },
        getList(){
            partlist().then(res =>{
                this.data = res.data
                for(var a=0;a<this.data.length;a++){
                    this.$set(this.data[a],'father',true)
                }
                this.getUserList()
            })
        },
        bindOut(msg){
            console.log(msg)
            let data = {
                b_id:msg.bid,
                u_id:msg.id
            }
            bindout(data).then(res=>{
                if(res.code==200){
                    this.getUserList()
                    this.showbind = false
                    this.$message({
                        type: 'success',
                        message: '解绑成功！'
                    });
                }else{
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                }
            })
        },
        bindOk(msg){
            let data = {
                b_id:this.part_id,
                u_id:msg.toString()
            }
            binduser(data).then(res=>{
                if(res.code==200){
                    this.getUserList()
                    this.showbind = false
                    this.$message({
                        type: 'success',
                        message: '添加成功!'
                    });
                }else{
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                }
            })
        },
        cancelBind(){
            this.showbind = false
        },
        bindUser(){
            if(this.part_id==''){
                this.$message({
                    message:'请先选择要绑定的部门',
                    type:'error'
                })
                return
            }
            this.showbind = true
        },
        beginEdit(msg){
            this.showadd = true
            this.partForm = msg
            this.partType = 2
        },
        partChange(msg){
            addpart(msg).then(res=>{
                console.log(res)
                if(res.code==200){
                    this.getList()
                    this.$message({
                        message:res.msg,
                        type:'success'
                    })
                    this.type = 1
                    this.showadd = false
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
            })
            
        },
        addPart(){
            this.partForm = {}
            this.showadd = true
            this.partType = 1
        },
        setArr(){
            // this.partList = this.data
            // let item = this.partList
            // for(var a=0;a<item.length;a++){
            //     this.$set(this.data[a],'father',true)
            //     if(item[a].children){
            //         for(var b = 0;b<item[a].children.length;b++){
            //             item[a].children[b].children = null
            //         }
            //     }
                
            // }
        },
        cancelAdd(){
            this.showadd = false
        },
        handleSizeChange(val){
            this.pageSize = val
            this.currentPage = 1
            this.getUserList()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this.getUserList()
        },
    },

}

</script>

<style lang="scss" scoped>
* {
    box-sizing: content-box;
}

.home {
    padding: 24px;

    .main {
        display: flex;

        .left {
            height: 100%;
            width: 280px;
            background: white;
            border-radius: 4px;

            .top {
                height: 56px;
                border-bottom: 1px solid #F0F1F5;
                padding: 22px 20px 0 20px;
                display: flex;
                justify-content: space-between;
                box-sizing: border-box;

                .top-title {
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: bold;
                    color: #474952;
                }

                .top-add {
                    display: flex;
                    color: #475B93 !important;
                    .img1 {
                        width: 24px;
                        height: 20px;
                        color: #475B93;
                    }
                    span {
                        font-size: 14px;
                        line-height: 18px;
                        margin-left: 8px;
                    }
                }
                .top-add:hover{
                    color: #2C57FF !important;
                    .img1{
                        color: #2C57FF !important;
                    }
                    
                }
                .top-add:active{
                    color: #133CDC !important;
                    .img1{
                        color: #133CDC !important;
                    }
                }

            }
        }

        .right {
            width: calc(100% - 290px);
            margin-left: 10px;

            .top-serch {
                background: white;
                border-radius: 4px;
                height: auto !important;
                .select-line {
                    display: flex;
                    padding: 16px 20px 0 0;
                }

                .line {
                    width: 100%;
                    height: 1px;
                    background: #F0F1F5;
                }

                .btn-line {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 8px 20px;
                    .showpart{
                        height: 100%;
                        display: flex;
                        align-items: baseline;
                        .icon{
                            margin-left: 6px;
                            width: 8px;
                            height: 8px;
                        }
                    }
                    .act {
                        background: #2C57FF;
                        color: white;
                    }

                    .btnpart button {
                        height: 32px;
                        width: 68px;
                        line-height: 32px;
                        padding: 0;

                    }
                }
            }

            .bottom-table {
                min-height: 660px;
                border-radius: 4px;
                background: white;
                margin-top: 14px;
                padding: 16px 20px;

                .pagetab {
                    text-align: right;
                    margin-top: 20px;
                }
            }
        }
    }
}</style>