import request from '../utils/request'

//获取用户列表
export function getuser(data){
    return request({
        url:'/back/user',
        method:'get',
        params:data
    })
}

//新增用户
export function adduser(data){
    return request({
        url:'/back/user',
        method:'post',
        data
    })
}

//用户详情
export function userdetail(data){
    return request({
        url:'/back/admin/detail',
        method:'get',
        params:data
    })
}

//修改用户
export function edituser(data){
    return request({
        url:'/back/user',
        method:'put',
        data
    })
}


//禁用用户
export function stopuser(data){
    return request({
        url:'/back/handleFreeze',
        method:'put',
        data
    })
}

//删除用户
export function deleteuser(data){
    return request({
        url:'/back/user',
        method:'delete',
        params:data
    })
}

//部门列表
export function partlist(data){
    return request({
        url:'/back/branch',
        method:'get',
        params:data
    })
}

//新建部门
export function addpart(data){
    return request({
        url:'/back/branch',
        method:'post',
        data
    })
}

//删除部门
export function delpart(data){
    return request({
        url:'/back/branch',
        method:'delete',
        data
    })
}

//修改部门
export function editpart(data){
    return request({
        url:'/back/branch',
        method:'put',
        data
    })
}

//获取部门绑定角色
export function partuser(data){
    return request({
        url:'/back/bidAdmin',
        method:'get',
        params:data
    })
}

//获取部门详情
export function partdetail(data){
    return request({
        url:'/back/branch',
        method:'patch',
        data
    })
}

//获取部门用户
export function partmenber(data){
    return request({
        url:'/back/index',
        method:'get',
        params:data
    })
}

//获取角色列表
export function getrole(data){
    return request({
        url:'/back/role',
        method:'get',
        params:data
    })
}

//获取角色用户
export function rolemenber(data){
    return request({
        url:'/back/show',
        method:'get',
        params:data
    })
}

//新建角色
export function addrole(data){
    return request({
        url:'/back/role',
        method:'post',
        data
    })
}

//修改角色
export function editrole(data){
    return request({
        url:'/back/role',
        method:'put',
        data
    })
}

//获取角色详情
export function roledetail(data){
    return request({
        url:'/back/role/detail',
        method:'patch',
        data
    })
}

//删除角色
export function delrole(data){
    return request({
        url:'/back/role',
        method:'delete',
        params:data
    })
}

//绑定用户
export function binduser(data){
    return request({
        url:'/back/relevance',
        method:'post',
        params:data
    })
}

//接触绑定
export function bindout(data){
    return request({
        url:'/back/relevance',
        method:'delete',
        data
    })
}

//部门获取全部负责人
export function alluser(data){
    return request({
        url:'/back/selOption',
        method:'get',
        data
    })
}

//获取权限列表
export function powerlist(data){
    return request({
        url:'/permission/edit',
        method:'get',
        data
    })
}

//角色添加权限
export function addpower(data){
    return request({
        url:'/permission/edit',
        method:'post',
        data
    })
}

//获取角色权限
export function powerdetail(data){
    return request({
        url:'/permission/detail',
        method:'get',
        params:data
    })
}

//用户信息获取
export function personinfo(data){
    return request({
        url:'/back/person',
        method:'get',
        params:data
    })
}

//保存个人信息修改
export function savepersoninfo(data){
    return request({
        url:'/back/center',
        method:'post',
        data
    })
}

//保存个人信息修改
export function getpoweradd(data){
    return request({
        url:'',
        method:'get',
        params:data
    })
}