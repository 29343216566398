import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import depart from '../views/terrace/depart-terrace.vue'
import member from '../views/terrace/member-terrace.vue'
import role from '../views/terrace/role-terrace.vue'
import power from '../views/terrace/power-terrace.vue'
import personal from '../views/terrace/personal-terrace.vue'
import custom from '../views/custom/custom-main.vue'
import sellchance from '../views/sellChance/sellChance-main.vue'
import houseContract from '../views/houseContract/house-contract.vue'
import houseCollection from '../views/houseCollection/house-collection.vue'
import contract from '../views/contract/contract-main.vue'
import contractback from '../views/contractBack/contractBack-main.vue'
import constapply from '../views/constApply/constApply-main.vue'
import openbill from '../views/openBill/openBill-main.vue'
import todolist from '../views/message/todo-list.vue'
import notice from '../views/message/system-notice.vue'
import message from '../views/message/system-message.vue'
import systemdate from '../views/system/system-main.vue'
import companyset from '../views/system/company-set.vue'
import Jump from '../views/jumpPage/jump.vue'
import Login from '../views/Login/login.vue'
import unfind from '../views/404.vue'
import { routesData } from '@/utils/permission'
import store from "../store/index"
Vue.use(VueRouter)

export const routes = [
	{
		path: '/jump',
		name: 'jump',
		component: Jump
	},
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/',
		name: 'home',
		component: Home,
		redirect: "/custom",
		children: [
			{
			  path: '/channel',
			  name: 'channel',
			  component:  () => import(`../views/channel/channel-main.vue`)
			},
			{
			  path: '/house',
			  name: 'house',
			  component:  () => import(`../views/house/house-main.vue`)
			},
			// {
			//   path: '/system/companyset',
			//   name: 'companyset',
			//   component: companyset
			// },
			// {
			//   path: '',
			//   name: 'home',
			// },
			// {
			//   path: '/sellchance',
			//   name: 'sellchance',
			//   component: sellchance
			// },
			// {
			//   path: '/houseContract',
			//   name: 'houseContract',
			//   component: houseContract
			// },
			// {
			//   path: '/houseCollection',
			//   name: 'houseCollection',
			//   component: houseCollection
			// },
			// {
			//   path: '/terrace/depart',
			//   name: 'depart',
			//   component: depart
			// },
			// {
			//   path: '/terrace/member',
			//   name: 'member',
			//   component: member
			// },
			// {
			//   path: '/terrace/role',
			//   name: 'role',
			//   component: role
			// },
			// {
			//   path: '/terrace/power',
			//   name: 'power',
			//   component: power
			// },
			// {
			//   path: '/terrace/personal',
			//   name: 'personal',
			//   component: personal
			// },
			// {
			//   path: '/custom',
			//   name: 'custom',
			//   component: custom
			// },
			// {
			//   path: '/contract',
			//   name: 'contract',
			//   component: contract,
			// },
			// {
			//   path: '/contractback',
			//   name: 'contractback',
			//   component: contractback
			// },
			// {
			//   path: '/constapply',
			//   name: 'constapply',
			//   component: constapply
			// },
			// {
			//   path: '/openbill',
			//   name: 'openbill',
			//   component: openbill
			// },
			// {
			//   path: '/todolist',
			//   name: 'todolist',
			//   component: todolist
			// },
			// {
			//   path: '/message',
			//   name: 'message',
			//   component: message
			// },
			// {
			//   path: '/notice',
			//   name: 'notice',
			//   component: notice
			// },
			
			// {
			// 	path: '/system/params',
			// 	name: 'system/params',
			// 	component: () => import(`../views/system/params-manage.vue`),
			// },
		]
	},
	{
		path: '/404',
		name: '404',
		component: unfind
	},

]
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(error => error)
}



const router = new VueRouter({
	routes
})
router.reloadRouter = function () {
	router.matcher = new VueRouter({
		routes
	}).matcher
}
let hasRoles = true
router.beforeEach(async (to, from, next) => {
	if(to.matched.length === 0 && hasRoles == false){
		next('/404')
	}else{
		// 要去登录界面,直接放行
		if (to.path === '/login'||to.path === '/jump') {
			next();
		} else {
			const token = localStorage.getItem('token');
			if (!token) {
				next('/login')
			} else {
				if(hasRoles){
					await routesData(store.state.userpower)
					hasRoles = false
					next({...to,replace: true})
				}else{
					next();
				}
				next();
			}
		}
	}
	
})

export default router
