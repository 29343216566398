<template>
    <div class="custom">
        <div class="top-search">
            <el-form :model="form" class="demo-form-inline" label-width="">
                <div v-if="create == 2" class="select-line bostrap" :style="{ height: (hide == false ? '43px' : 'auto') }" id="searchLine">
                    <el-form-item label="模版名称" class="">
                        <el-input class="" v-model="form.title" placeholder="请输入关键字搜索">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select v-model="form.status" placeholder="请选择" clearable>
                            <el-option v-for="item in statusList" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="创建时间" class="time">
                        <el-date-picker v-model="form.set_time" value-format="yyyy-MM-dd" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" type="daterange"
                            placeholder="请选择"></el-date-picker>
                    </el-form-item>
                </div>
                <div v-else class="select-line bostrap" :style="{ height: (hide == false ? '43px' : 'auto') }" id="searchLine">
                    <el-form-item label="发件人" class="">
                        <el-input class="" v-model="form.founder" placeholder="请输入关键字搜索">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="收件人">
                        <el-input class="" v-model="form.recipient" placeholder="请输入关键字搜索">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="创建时间" class="time">
                        <el-date-picker v-model="form.set_time" value-format="yyyy-MM-dd" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" type="daterange"
                            placeholder="请选择"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="发送方式">
                        <el-select v-model="form.way" placeholder="请选择" clearable>
                            <el-option v-for="item in wayList" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="读取状态">
                        <el-select v-model="form.is_read" placeholder="请选择" clearable>
                            <el-option v-for="item in readList" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="line"></div>
                <div class="btn-line">
                    <div class="btnpart">
                        <el-button class="confirm" @click="getList('search')" plain v-permission="'系统消息-select'">查询</el-button>
                        <el-button class="cancel" @click="clearSeach" plain v-permission="'系统消息-reset'">重置</el-button>
                    </div>
                    <!-- <div class="showpart hover handel" @click="showHide">
                        {{ actText }}
                        <local-svg iconClass="showdown" class="icon"></local-svg>
                    </div> -->
                </div>

            </el-form>
        </div>
        <div class="tablepart">
            <div class="top">
                <div class="span">
                    <div class="title">系统消息</div>
                        <el-radio-group v-model="create" @input="changRadio">
                            <el-radio label="1">消息列表</el-radio>
                            <el-radio label="2" v-permission="'系统消息-template'">模版列表</el-radio>
                        </el-radio-group>
                </div>
                <div class="btnlist btnpart">
                <!-- <el-button class="iconbtn" @click="beginAdd" plain><local-svg iconClass="add"
                            class="icon"></local-svg>新建</el-button>  -->
                </div>
            </div>
            <customTable :list="tableData" @transfer="wantAdd"
                @tableedit="wantEdit" @stopuser="setUserType" @select="selectRow" :type="create" @changetype="changeType" :loading="load">
            </customTable>
            <div class="pagetab">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <changeDialog v-if="changeDialog" :form="detailData" :show="changeDialog" @cancel="cancelChange" @add="addUser"
            :type="addtype" @edit="deitUser"></changeDialog>
    </div>
</template>

<script>
import {modellist,msglist,msgselect,changestatus,modeldetail,editmodel} from '@/api/message'
import customTable from "@/components/message/report/table.vue"
import changeDialog from "@/components/message/report/change.vue"
export default {
    name: 'custom',
    data() {
        return {
            hide: false,
            changeDialog: false,
            load: false,
            currentPage: 1,
            pageSize: 10,
            total: 3,
            actText: '展开',
            userList: [],
            typeList: [],
            partList: [],
            statusList:[
                {name:'禁用',id:0},
                {name:'启用',id:1},
            ],
            form: {
                title:'',
                status:'',
                set_time: ['', ''],
                recipient:'',
                founder:'',
                way:'',
                is_read:'',
            },
            create:'1',
            tableData: [],
            selectionList: [],
            addtype: 1,
            detailData: {},
            readList:[],
            wayList:[],
            load:true
        }
    },
    components: {
        customTable,
        changeDialog,
    },
    mounted() {
        this.getSearch()
        this.getList()
    },
    methods: {
        changeType(msg){
            let data = {
                id:msg.data.id,
                status:msg.data.open?0:1
            }
            changestatus(data).then(res=>{
                if(res.code == 200){
                    this.$message({
                        message:res.msg,
                        type:'success'
                    })
                    this.getList()
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                    this.$set(this.tableData[msg.index],'open',!msg.data.open)
                }
            })
        },
        changRadio(){
            this.currentPage = 1
            this.form = {
                title:'',
                status:'',
                set_time: ['', ''],
                recipient:'',
                founder:'',
                way:'',
                is_read:'',
            },
            this.getList()
        },
        selectRow(row) {
            this.selectionList = row
        },
        deitUser(msg) {
            let data = {
                id:msg.id,
                title:msg.title,
                content:msg.content,
                method:msg.method_id,
                status:msg.open?1:0
            }
            editmodel(data).then(res => {
                if (res.code == 200) {
                    this.getList()
                    this.changeDialog = false
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
            })
        },
        beginAdd() {
            this.addtype = 1
            this.changeDialog = true
        },
        addUser(data) {
            editmodel(data).then(res => {
                if (res.code == 200) {
                    this.getList()
                    this.changeDialog = false
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
            })
        },
        getSearch() {
            msgselect().then(res => {
                this.readList = res.data.read
                this.wayList = res.data.way
            })
        },
        cancelChange() {
            this.changeDialog = false
        },
        clearSeach() {
            this.form = {
                title:'',
                status:'',
                set_time: ['', ''],
                recipient:'',
                founder:'',
                way:'',
                is_read:'',
            }
            this.getList('search')
        },
        wantAdd() { },
        // 获取用户列表
        getList(e) {
            if(e){
                this.currentPage = 1
            }
            this.load = true
            if(this.create == 2){
                let data = {
                    page: this.currentPage,
                    count: this.pageSize,
                    title:this.form.title,
                    start_time:this.form.set_time.length>0?this.form.set_time[0]:'',
                    end_time:this.form.set_time.length>0?this.form.set_time[1]:'',
                    status:this.form.status,
                }
                modellist(data).then(res => {
                    this.tableData = res.data.table
                    for(var a = 0;a<this.tableData.length;a++){
                        if(this.tableData[a].status == '开启'){
                            this.$set(this.tableData[a],'open',true)
                        }else{
                            this.$set(this.tableData[a],'open',false)
                        }
                    }
                    this.total = res.data.total
                    setTimeout(() => {
                        this.load = false
                    }, 1000);
                })
            }
            else{
                let otherdata = {
                    page: this.currentPage,
                    count: this.pageSize,
                    start_time:this.form.set_time.length>0?this.form.set_time[0]:'',
                    end_time:this.form.set_time.length>0?this.form.set_time[1]:'',
                    recipient:this.form.recipient,
                    founder:this.form.founder,
                    way:this.form.way,
                    is_read:this.form.is_read,
                }
                msglist(otherdata).then(res=>{
                    this.tableData = res.data.table
                    this.total = res.data.total
                    setTimeout(() => {
                        this.load = false
                    }, 1000);
                })
            }
            
            
        },
        wantEdit(msg) {
            let data = {
                id:msg.data.id
            }
            modeldetail(data).then(res=>{
                this.addtype = 2
                this.detailData = res.data
                this.changeDialog = true
            })
            
        },
        setUserType() { },
        showHide() {
            this.actText = this.hide ? '展开' : '收起'
            this.hide = !this.hide
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.currentPage = 1
            this.getList()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getList()
        },
    }
}

</script>

<style src="@/assets/scss/formstyle.scss" lang="scss" ></style>
<style lang="scss" scoped>
* {
    box-sizing: content-box;
}

.custom {
    padding: 24px;

    .top-search {
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;

        .select-line {
            display: flex;
            padding: 0 20px 0 0;
        }

        .line {
            width: 100%;
            height: 1px;
            background: #F0F1F5;
        }

        .btn-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 11px 20px;

            .showpart {
                height: 100%;
                display: flex;
                align-items: baseline;
                font-size: 14px;

                .icon {
                    margin-left: 6px;
                    width: 8px;
                    height: 8px;
                }
            }

            .act {
                background: #2C57FF;
                color: white;
            }

            .btnpart button {
                height: 32px;
                width: 68px;
                line-height: 32px;
                padding: 0;

            }
        }
    }

    .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
            font-size: 14px;
            font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
            font-weight: bold;
            color: #474952;
            line-height: 16px;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .icon {
            width: 16px;
            height: 16px;
        }

        i {
            margin-right: 5px;
        }
    }

    .tablepart {
        height: calc(100vh - 260px);
        background: white;
        margin-top: 10px;
        border-radius: 4px;
        padding: 16px 20px;

        .icon {
            margin-right: 8px;
        }

        .pagetab {
            text-align: right;
            margin-top: 20px;
        }

        .span {
            font-size: 14px;
            font-weight: bold;
            color: #474952;
            display: flex;
            align-items: center;
            .title{
                margin-right: 30px;
            }
        }

        .top {
            display: flex;
            align-items: center;
        }
    }
}
</style>