<template>
    <div class="box main-navbar">
        <el-menu class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse"
            :unique-opened="true" background-color="#2C57FF" text-color="#C2D3FF" active-text-color="#fff"
            :collapse-transition="false" :default-active="currentMenu">
            <div class="logo">
                <img src="../assets/image/Frame.png" alt="">
                <!-- <img src="../assets/image/Frame1.png" alt=""> -->
                <!-- <local-svg v-if="!isCollapse" iconClass="logo" class="logo-icon"></local-svg> -->
            </div>
            <div class="navbar-version hover" v-show="!isCollapse&&!isOpen" @click="seeVersionList">
                <span><span style="margin-right: 8px;color: rgb(86 129 225);">—</span>V{{ version }} <span style="margin-left: 4px;"></span> {{ change_date.split(' ')[0] }}<span style="margin-left: 8px;color: rgb(86 129 225);">—</span></span>
            </div>
            <div>
                <fragment v-for="(item, index) in list" :key="index">
                    <el-submenu   v-if="item.page && item.has_child==1" :index="item.route">
                        <template slot="title">
                            <local-svg :iconClass="item.icon+''" class="icon"></local-svg>
                            <span v-if="!isCollapse">{{ item.name }}</span>
                        </template>
                        <el-menu-item-group v-for="(tab, sonIndex) in item.page" :key="sonIndex">
                            <el-menu-item :index="tab.route" @click="changPage(tab)" class="child-nav">{{ tab.name }}</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                    <el-menu-item class="title-first" v-else :index="item.route " @click="changPage(item)">
                        <local-svg :iconClass="item.icon+''" class="icon"></local-svg>
                        <span slot="title">{{ item.name }}</span>
                    </el-menu-item>
                </fragment>
            </div>
        </el-menu>
        <el-dialog title="" :visible.sync="versionDialog" width="720px" class="version-dialog">
            <div class="dialog-main">
                <div class="header fl-between">
                    <span class="title">版本记录</span>
                    <span class="close" @click="closeVersionDialog"><local-svg iconClass="close" class="icon hover handel-header-icon"></local-svg></span>
                </div>
                <div class="table mine-table-change">
                    <el-table
                    :data="tableData"
                    height="100%"
                    style="width: 100%"
                    tooltip-effect="light">
                        <template slot="empty">
                            <el-empty :image-size="250" :image="image" description='暂无数据'></el-empty>
                        </template>
                        <el-table-column  label="版本号" width="100">
                            <template slot-scope="scope">
                                <div class="file-name out-text">
                                    {{ scope.row.version }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="create_time" label="更新时间" width="180"></el-table-column>
                        <el-table-column prop="intro" label="更新内容" show-overflow-tooltip> </el-table-column>
                    </el-table>
                </div>
                <div class="page page-element-change ">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-size="pageSize"
                        layout="total, prev, pager, next, jumper"
                        :total="total"
                        background
                        popper-class="select-element-change">
                    </el-pagination>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {Vnumber} from '@/api/common'
import vm from '@/assets/js/closeNav'
export default {
    name: "NavBarLeft",
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    data() {
        return {
            isCollapse: false,
            currentMenu: '/custom',
            version:'',
            change_date:'',
            isOpen:false,
            versionDialog:false,
            image:require('../assets/image/none.png'),
            tableData:[],
            currentPage:1,
            pageSize:10,
            total:100,
        };
    },
    mounted() {
        this.getVersion()
        this.$bus.$on('data', (data) => {

            if (data[2] === 1) {
                this.changPage(data[0])
                this.$router.push({
                    path: '/custom',
                    query: {
                        id: data[1].id,
                        num: 1
                    }
                })
            }
            if (data[2] === 2) {
                this.changPage(data[0])
                // console.log(data[1].client_id);
                this.$router.push({
                    path: '/custom',
                    query: {
                        id: data[1].client_id,
                        num: 2
                    }
                })
            } else {
                this.changPage(data[0])
                // console.log(data[1].client_id);
                this.$router.push({
                    path: '/housecontract',
                    query: {
                        id: data[1].h_c_id,
                    }
                })
            }

        })
        this.getType()
        this.currentMenu = this.$route.path
    },
    watch: {
        $route(e) {
            this.currentMenu = e.path;
            this.$store.commit('setRoute',e.path)
        },
    },
    methods: {
        seeVersionList(){
            let data = {
                page:this.currentPage,
                limit:this.pageSize,
                tag:1
            }
            Vnumber(data).then(res=>{
                this.total = res.data.count
                this.tableData = res.data.data
                this.versionDialog = true
            })
        },
        closeVersionDialog(){
            this.versionDialog = false
        },
        getVersion(){
            let data = {
                tag:0
            }
            Vnumber(data).then(res=>{
                this.version = res.data.version
                this.change_date = res.data.create_time
            })
        },
        handleOpen() {
            this.isOpen = true
        },
        handleClose() {
            this.isOpen = false
         },
        getType() {
            vm.$on('closetype', value => {
                this.isCollapse = value
            })
        },
        changPage(data) {
            // console.log(data);
            this.$router.push(data.route)
            let obj = data
            vm.$emit("closeobj", obj)
        },
        handleCurrentChange(val){
            this.currentPage = val
            this.getList()
        },
    }
}
</script>

<style lang="scss">
.box {
    height: 100vh;
    min-height: 100%;
    position: relative;
    .logo-icon{
        width: 7em !important;
        height: 7em !important;
    }
    .navbar-version{
        position: absolute;
        text-align: center;
        font-size: 12px;
        color: #759cf3;
        bottom: 30px;
        line-height: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-left: -10px;
    }
    .el-menu-vertical-demo {
        box-sizing: border-box;
        height: auto;
        min-height: 100%;
        // height: 100%;
        padding: 35px 10px 0 10px;

        .logo {
            width: 100%;
            text-align: center;

            img {
                height: 80px;
                width: 80px;
                margin-bottom: 30px;
            }
        }

        .icon {
            width: 20px;
            height: 20px;
            margin-right: 16px;
        }
    }

    .el-menu-item.is-active {
        background-color: rgba(0, 0, 0, 0.2) !important;
        color: #fff;
    }

    .el-menu-item:hover {
        background-color: rgba(0, 0, 0, 0.1) !important;
    }

    .el-submenu.is-active {
        background-color: rgba(0, 0, 0, 0.2) !important;
    }

    .el-submenu:hover {
        background-color: rgba(0, 0, 0, 0.1) !important;
    }

    .el-menu--popup-right-start {
        background-color: white !important;
    }

    .el-menu-vertical-demo {
        min-width: 80px !important;
    }

    .el-menu--collapse {
        width: 80px !important;
        text-align: center;
        color: #C2D3FF !important;

        .el-menu-item .is-active {
            background: rgba(0, 0, 0, 0.2) !important;
        }

        .logo img {
            width: 48px;
            height: 48px;
        }

        .is-active {
            color: white !important;
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 4px;
        }

        .el-tooltip {
            display: flex !important;
            justify-content: center;
            align-items: center;

            .icon {
                width: 20px;
                height: 20px;
                margin-right: 0;
            }
        }

        .el-submenu__title {
            display: flex !important;
            justify-content: center;
            align-items: center;

            .icon {
                width: 20px;
                height: 20px;
                margin-right: 0;
            }
        }
    }

    .el-submenu__icon-arrow {
        transform: rotate(-90deg);
    }

    .el-submenu.is-opened>.el-submenu__title .el-submenu__icon-arrow {
        transform: rotateZ(0);
    }
    .el-icon-arrow-right:before{
        display: none;
    }
    .title-first{
        height: 56px !important;
        line-height: 56px !important;
    }
    .title-first:hover{
        color: #fff !important;
    }
    .child-nav:hover{
        color: #fff !important;
    }
    .el-submenu__title{
        height: 56px !important;
        line-height: 56px !important;
    }
    .version-dialog{
        .el-dialog__header{
            display: none !important;
        }
        .el-dialog__body{
            padding: 0 !important;
        }
        .dialog-main{
            width: 100%;
            height: 680px;
            .header{
                width: 100%;
                height: 54px;
                border-bottom: 1px solid #F0F1F5;
                box-sizing: border-box;
                padding: 20px;
                .title{
                    font-size: 16px;
                    font-weight: bold;
                    color: #2B313D;
                    line-height: 16px;
                    margin-top: 4px;
                }
                .close{
                    .icon{
                        height: 16px;
                        width: 16px;
                    }
                }
            }
            .table{
                width: 100%;
                height: calc(100% - 147px);
                padding:  0 36px;
                box-sizing: border-box;
                margin-top: 18px;
                .file-name{
                    width: 190px;
                }
            }
            .el-table__empty-block{
                padding-bottom: 0 !important;
            }
            .page{
                width: 100%;
                text-align: right;
                box-sizing: border-box;
                padding: 0 36px;
                margin-top: 13px;
            }
        }
    }
}
</style>
