<template>
    <div class="table-part">
        <div class="table">
            <el-table :data="list" height="560px" style="width: 100%"
                :header-cell-style="{ 'background': '#F4F7FC', 'color': '#B0B1B9', 'fontWeight': 'bold' }"
                tooltip-effect="dark" :row-key="getRowKeys" v-loading="loading">
                <template slot="empty">
                    <el-empty :image-size="160" :image="image" description='暂无数据'></el-empty>
                </template>
                <!-- <el-table-column type="index" width="100" label="序号" align="center"></el-table-column> -->
                <el-table-column width="150" label="事项类别" align="center" prop="type">
                    <template slot-scope="scpoe">
                        <div class="msg-main">{{ scpoe.row.type_id==12 ? '客户管理' : scpoe.row.type}}</div>
                    </template>
                </el-table-column>
                <el-table-column width="150" label="申请人" align="center" prop="admin_id"></el-table-column>
                <el-table-column width="" label="事项内容" align="left" prop="content">
                    <template slot-scope="scpoe">
                        <div class="msg-main" v-if="scpoe.row.type_id==5">{{ scpoe.row.num }}该笔费用申请未提交相关票据</div>
                        <div class="msg-main" v-else-if="scpoe.row.type_id==6">{{ scpoe.row.admin_id }}发起的{{ scpoe.row.num }}已提交相关票据，请及时处理</div>
                        <div class="msg-main" v-else-if="scpoe.row.type_id==12">{{ scpoe.row.admin_id }}于{{ scpoe.row.create_time }}提交了{{ scpoe.row.remind_other }}的营业执照，判断是否更新客户名称</div>
                        <div class="msg-main" v-else>{{ scpoe.row.admin_id }}于{{ scpoe.row.create_time }}提交了{{ scpoe.row.num }}审批，请及时处理</div>
                    </template>
                </el-table-column>
                <el-table-column width="180" label="申请日期" align="center" prop="create_time" sortable></el-table-column>
                <el-table-column width="150" label="状态" align="center" prop="is_deal"></el-table-column>
                <el-table-column label="操作" align="center" width="150" v-if="type == 0">
                    <template slot-scope="scope">
                        <div class="chick">
                            <div class="hover handel" @click="deal(scope.row)" v-permission="'待办事项-handle'">处理</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import adduser from '@/utils/mutual.js'
export default {
    name: "NavBarLeft",
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        type:{
            type: String,
            default: () => {
                return '0'
            }
        },
        loading:{
            type: Boolean,
            default: () => {
                return true
            } 
        }
    },
    data() {
        return {
            image: require('../../../assets/image/none.png'),
            cellSty: {
                'background-color': '#aaa'
            },
            multipleSelection: [],
            // BatchStr: ''
        };
    },
    mounted() {
    },
    methods: {
        deal(row){
            this.$emit('deal',row)
        },
        seeContarct(row) {
            this.$emit('detail', row)
        },
        handleSelectionChange1(row) {
            this.$emit('select', row)
        },
        getRowKeys(row) {
            return row.id;
        },
        seeDeatail(row) {
            // console.log(row);
            this.$emit('detail', row)
        },
        setType(row) {
            console.log(row.is_open)
            let data = {
                id: row.id,
                freeze: row.is_open === true ? 1 : 0
            }
            this.$emit('stopuser', data)
        },
        addUser() {
            this.$emit('transfer', true)
        },
        editUser(row) {
            let data = {
                info: row,
                type: true
            }
            this.$emit('tableedit', data)
        },
        deleteUser(row) {
            this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                this.$emit('deluser', row.id)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            let Arr = val.map((item) => {
                return item.id
            })
            this.$emit('PL', Arr)
            let Str = Arr.join(',')
            this.BatchStr = Str
            this.$emit('BS', Str)

        },
        oddnum(row) {
            this.$emit('detail', row)
        },
        collnum(row) {
            this.$emit('detail', row)

        }
    }
}
</script>
<style lang="scss">
.el-table tbody tr:hover>td {
    background-color: #EDF3FE !important
}
</style>

<style lang="scss">
.table-part {
    .table {
        margin-top: 8px;
        width: 100%;
        height: 560px;

        .chick {
            display: flex;
            justify-content: space-evenly;
        }

        // .is-scrolling-left{
        //     height:512px !important;
        // }
        // .el-table__fixed-body-wrapper{
        //     height: 512px !important;
        //     top:48px !important;
        // }
        .type-status {
            span {
                font-size: 14px;
                border-radius: 4px;
                width: auto;
                padding: 3px 10px;
                line-height: 24px;
            }

            .status1 {
                color: #B0B1B9;
                background: #E9EAEE;
            }

            .status2 {
                color: #2C57FF;
                background: #EAF1FB;
            }

            .status3 {
                color: #10C257;
                background: #EDFBEF;
            }

            .status4 {
                color: #F74B4B;
                background: #FFF3F3;
            }
        }
        .sell{
            width: 76px;
            height: 24px;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            text-align: center;
            line-height: 24px;
            color: #B0B1B9;
            background: #E9EAEE;
        }
        .sell-status1{
            color: #DE8500;
            background: #FAF5E6;
        }
        .sell-status2{
            color: #0C80EB;
            background: #EAF7FF;
        }
        .sell-status3{
            color: #2C57FF;
            background: #EAF1FB;
        }
        .sell-status4{
            color: #08B54D;
            background:#EDFBEF;
        }

    }
}
</style>
