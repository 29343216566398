import axios from 'axios'
import ElementUI from 'element-ui';
import { message } from './message';
import router from '@/router'
// 创建axios实例
const service = axios.create({
    baseURL:'https://syncdata.tjlhyg.com',
    // baseURL: "http://192.168.9.47:8181/",
    timeout: 60000, // 请求超时时间
    withCredentials: true,
    data: {}
    // crossDomain: true
})

// request拦截器
service.interceptors.request.use(
    config => {
        let token = localStorage.getItem('token')
        config.headers['Authorization'] = token // 让每个请求携带自定义token 请根据实际情况自行修改
        var lang = localStorage.getItem('lang')//因为项目中使用到了i18n国际化语言配置，请根据实际情况自行修改
        if (!lang) {
            lang = 'zh_CN'
        }
        return config
    },
    error => {
        Promise.reject(error)
    }
)

// response 拦截器
service.interceptors.response.use(
    response => {
        if (response.data.code === 401) {
            //  store.dispatch('LogOut').then(() => {
            //      // 用户登录界面提示
            //      Cookies.set('point', 401)
            //      location.reload()
            // })
        } else if (response.data.code === 303) {
            message({
                message:'登录超时，请重新登录！',
                type:'error'
            })
            setTimeout(()=>{
                localStorage.removeItem('userpower')
                localStorage.removeItem('userbtn')
                localStorage.removeItem('username')
                localStorage.removeItem('userrole')
                localStorage.removeItem('route')
                localStorage.removeItem('x_token')
                localStorage.removeItem('userimage')
                localStorage.removeItem('token')
                localStorage.removeItem('tmp_token')
                router.push({ path: '/login' })
            },3000)
        } 
        return response.data
    },
    error => {
        let code = 0
        try {
            code = error.response.data.status
        } catch (e) {
            if (error.toString().indexOf('Error: timeout') !== -1) {
                console.log(1,1)
                return Promise.reject(error)
            }
        }
        if (code) {
            if (code === 401) {
                //  store.dispatch('LogOut').then(() => {
                //      // 用户登录界面提示
                //      Cookies.set('point', 401)
                //      location.reload()
                // })
            } else if (code === 303) {
                message({
                    message:'登录超时，请重新登录！',
                    type:'error'
                })
                setTimeout(()=>{
                    localStorage.removeItem('userpower')
                    localStorage.removeItem('userbtn')
                    localStorage.removeItem('username')
                    localStorage.removeItem('userrole')
                    localStorage.removeItem('route')
                    localStorage.removeItem('x_token')
                    localStorage.removeItem('userimage')
                    localStorage.removeItem('token')
                    localStorage.removeItem('tmp_token')
                    router.push({ path: '/login' })
                },3000)
                return
            } else {
                const errorMsg = error.response.data.message
                if (errorMsg !== undefined) {
                    // Notification.error({
                    //     title: errorMsg,
                    //     duration: 0
                    // })
                }
            }
        } else {
            // Notification.error({
            //     title: '接口请求失败',
            //     duration: 5000
            // })
        }
        return Promise.reject(error)
    }
)
export default service