<template>
    <div class="number-box">
        <div class="top-part btnpart">
            <span class="span">查询结果</span>
            <el-button class="iconbtn" v-permission="'角色管理-relevance'" style="height:32px !important ;padding: 0 14px;" @click="bindUser" plain><local-svg iconClass="add" class="icon"></local-svg>关联用户</el-button>
        </div>
        <div class="table">
            <el-table
                :data="list"
                height="560px"
                style="width: 100%"
                :header-cell-style="{'background':'#F4F7FC','color':'#B0B1B9','fontWeight':'bold'}">
                <template slot="empty">
                    <el-empty :image-size="160" :image="image" description='暂无数据'></el-empty>
                </template>
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <!-- <el-table-column type="index" label="序号"  align="center"></el-table-column> -->
                <el-table-column prop="name" label="用户名" ></el-table-column>
                <el-table-column prop="b_name" label="所属部门"></el-table-column>
                <el-table-column prop="r_name" label="角色类型"></el-table-column>
                <el-table-column label="状态" align="center" width="100">
                    <template slot-scope="scope">
                        <div class="message-box" :style="{'background':scope.row.resign=='1'?'#F0FAF5':'#FAF0F1','color':scope.row.resign=='1'?'#10C257':'#F74B4B'}">
                            <span class="circle" :style="{'background':scope.row.resign=='1'?'#10C257':'#F74B4B'}"></span>
                            <span v-if="scope.row.resign=='1'">启用</span>
                            <span v-else>禁用</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="150">
                    <template slot-scope="scope">
                        <div class="chick ">
                            <div class="hover handel" @click="cancelBind(scope.row)"  v-permission="'角色管理-cancel'">取消关联</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import adduser from '@/utils/mutual.js'
export default {
    name: "NavBarLeft",
    props: {
        list:{
        	type:Array,
        	default:()=>{
        		return []
        	}
        },
    },
    data() {
        return {
            image:require('../../../assets/image/none.png'),
            cellSty:{
                'background-color':'#aaa'
            }
        };
    },
    mounted(){
    },
    methods: {
        bindUser(){
            this.$emit('bind',true)
        },
        cancelBind(row){
            this.$confirm('此操作将取消此用户关联 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.$emit('bindout',row.id)
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });          
            });
        },
    }
}
</script>
<style lang="scss">
.el-table tbody tr:hover>td { background-color: #EDF3FE !important}
</style>

<style lang="scss">

.number-box {
    .top{
        display: flex;
        justify-content: space-between;
        span{
            font-size: 14px;
            color: #474952;
            font-weight: bold;
        }
        i{
            margin-right: 10px;
        }
    }
    .table{
        margin-top: 8px;
        width: 100%;
        .chick{
            display: flex;
            justify-content: space-evenly;
            color: #475B93;
        }
        .message-box{
            width: 82px;
            text-align: center;
        }
    }
}
</style>
