<template>
    <div class="table-part">
        <div class="table">
            <el-table :data="list" height="560px" style="width: 100%"
                :header-cell-style="{ 'background': '#F4F7FC', 'color': '#B0B1B9', 'fontWeight': 'bold' }"
                tooltip-effect="dark" :row-key="getRowKeys" v-if="type==2"
                v-loading="loading">
                <template slot="empty">
                    <el-empty :image-size="160" :image="image" description='暂无数据'></el-empty>
                </template>
                <!-- <el-table-column type="index" width="100" label="序号" align="center"></el-table-column> -->
                <el-table-column width="120" label="模板类型" align="center" prop="cate_id"></el-table-column>
                <el-table-column width="150" label="模版标题" align="left" prop="title"></el-table-column>
                <el-table-column width="" label="模版内容" align="left" prop="content"></el-table-column>
                <el-table-column width="150" label="发送方式" align="left" prop="method"></el-table-column>
                <el-table-column width="150" label="修改时间" align="left" prop="update_time"></el-table-column>
                <el-table-column width="150" label="创建时间" align="left" prop="create_time"></el-table-column>
                <el-table-column width="150" label="开启/禁用" align="left" v-if="$store.state.userbtn.indexOf('系统消息-enable')>0">
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.open"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            @change="changeMsg(scope.row,scope.$index)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="150">
                    <template slot-scope="scope">
                        <div class="chick">
                            <div class="hover handel" @click="editUser(scope.row)" v-permission="'系统消息-edit'">编辑</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-table :data="list" height="560px" style="width: 100%"
                :header-cell-style="{ 'background': '#F4F7FC', 'color': '#B0B1B9', 'fontWeight': 'bold' }"
                tooltip-effect="dark" :row-key="getRowKeys" v-else>
                <template slot="empty">
                    <el-empty :image-size="160" :image="image" description='暂无数据'></el-empty>
                </template>
                <!-- <el-table-column type="index" width="100" label="序号" align="center"></el-table-column> -->
                <el-table-column width="100" label="发件人" align="center" prop="founder"></el-table-column>
                <el-table-column width="100" label="收件人" align="left" prop="recipient"></el-table-column>
                <el-table-column width="100" label="消息类别" align="left" prop="category"></el-table-column>
                <!-- <el-table-column width="150" label="消息标题" align="left" prop="admin"></el-table-column> -->
                <el-table-column width="" label="消息内容" align="left" prop="content"></el-table-column>
                <el-table-column width="100" label="发送方式" align="left" prop="way"></el-table-column>
                <el-table-column width="100" label="读取状态" align="left" prop="is_read"></el-table-column>
                <el-table-column width="100" label="发送状态" align="left" prop="is_send"></el-table-column>
                <el-table-column width="150" label="创建时间" align="left" prop="update_time"></el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import adduser from '@/utils/mutual.js'
export default {
    name: "NavBarLeft",
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        type:{
            type:String,
            default: () => {
                return '1'
            }
        },
        loading:{
            type: Boolean,
            default: () => {
                return true
            } 
        }
    },
    data() {
        return {
            image: require('../../../assets/image/none.png'),
            cellSty: {
                'background-color': '#aaa'
            },
            multipleSelection: [],
            // BatchStr: ''
        };
    },
    mounted() {
    },
    methods: {
        changeMsg(msg,index){
            let data = {
                data:msg,
                index:index
            }
            this.$emit('changetype',data)
        },
        seeContarct(row) {
            this.$emit('detail', row)
        },
        handleSelectionChange1(row) {
            this.$emit('select', row)
        },
        getRowKeys(row) {
            return row.id;
        },
        seeDeatail(row) {
            // console.log(row);
            this.$emit('detail', row)
        },
        setType(row) {
            console.log(row.is_open)
            let data = {
                id: row.id,
                freeze: row.is_open === true ? 1 : 0
            }
            this.$emit('stopuser', data)
        },
        addUser() {
            this.$emit('transfer', true)
        },
        editUser(row) {
            let data = {
                data:row
            }
            this.$emit('tableedit', data)
        },
        deleteUser(row) {
            this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                this.$emit('deluser', row.id)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            let Arr = val.map((item) => {
                return item.id
            })
            this.$emit('PL', Arr)
            let Str = Arr.join(',')
            this.BatchStr = Str
            this.$emit('BS', Str)

        },
        oddnum(row) {
            this.$emit('detail', row)
        },
        collnum(row) {
            this.$emit('detail', row)

        }
    }
}
</script>
<style lang="scss">
.el-table tbody tr:hover>td {
    background-color: #EDF3FE !important
}
</style>

<style lang="scss">
.table-part {
    .table {
        margin-top: 8px;
        width: 100%;
        height: 560px;

        .chick {
            display: flex;
            justify-content: space-evenly;
        }

        // .is-scrolling-left{
        //     height:512px !important;
        // }
        // .el-table__fixed-body-wrapper{
        //     height: 512px !important;
        //     top:48px !important;
        // }
        .type-status {
            span {
                font-size: 14px;
                border-radius: 4px;
                width: auto;
                padding: 3px 10px;
                line-height: 24px;
            }

            .status1 {
                color: #B0B1B9;
                background: #E9EAEE;
            }

            .status2 {
                color: #2C57FF;
                background: #EAF1FB;
            }

            .status3 {
                color: #10C257;
                background: #EDFBEF;
            }

            .status4 {
                color: #F74B4B;
                background: #FFF3F3;
            }
        }
        .sell{
            width: 76px;
            height: 24px;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            text-align: center;
            line-height: 24px;
            color: #B0B1B9;
            background: #E9EAEE;
        }
        .sell-status1{
            color: #DE8500;
            background: #FAF5E6;
        }
        .sell-status2{
            color: #0C80EB;
            background: #EAF7FF;
        }
        .sell-status3{
            color: #2C57FF;
            background: #EAF1FB;
        }
        .sell-status4{
            color: #08B54D;
            background:#EDFBEF;
        }

    }
}
</style>
