<template>
    <div class="adduser">
        <el-dialog title="编辑房屋合同" :visible.sync="showDialog" width="800px" class="addform" @close="cancel">
            <div class="addform cunstom">
                <div>
                    <el-form :model="addform" :rules="rules" ref="addform" :validate-on-rule-change="false">
                        <el-row>
                            <el-col :span="11">
                                <el-form-item label="客户名称" prop="name" class="left"  >
                                    <!-- <el-input v-model="addform.name" placeholder="请输入用户名"></el-input> -->
                                    <el-select filterable v-model="addform.name" placeholder="请选择"  popper-class="custom-select">
                                        <el-option v-for="item in xialaList.client" :label="item.name" :value="item.id"
                                            :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item class="fwhtnew" label="租金总金额" prop="rentmoney">
                                    <el-input v-model="addform.rentmoney" placeholder="请输入租金总金额" @blur="changeThousand"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="现面(平米)" class="left">
                                    <!-- <el-select v-model="addform.trade_id" placeholder="请选择"> -->
                                    <el-input v-model="addform.nowarea" placeholder="请输入现面"></el-input>

                                    <!-- <el-option v-for="item in tradeList" :label="item.name" :value="item.id"
                                            :key="item.id"></el-option> -->
                                    <!-- </el-select> -->
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item class="fwhtnew" label="物业总金额(元)" prop="propertymoney">
                                    <el-input v-model="addform.propertymoney" placeholder="请输入物业总金额" @blur="changeThousand"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="原始面积(平米)" class="left">
                                    <el-input v-model="addform.oldarea" placeholder="请输入原始面积"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item class="fwhtnew" label="押金金额(元)" prop="depmoney">
                                    <el-input v-model="addform.depmoney" placeholder="请输入押金金额" @blur="changeThousand"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="租赁开始日期" class="left" prop="leasestarttime">
                                    <!-- <el-input v-model="addform.source_id" placeholder="请输入详细地址"></el-input> -->
                                    <el-date-picker v-model="addform.leasestarttime" value-format="yyyy-MM-dd" type="date"
                                        placeholder="选择日期"></el-date-picker>

                                    <!-- <el-select v-model="addform.source_id" placeholder="请选择"> -->
                                    <!-- <el-option v-for="item in sourceList" :label="item.name" :value="item.id"
                                            :key="item.id"></el-option> -->
                                    <!-- </el-select> -->
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item class="fwhtnew" label="回款方式">
                                    <!-- <el-input v-model="addform.pioneer" placeholder="请输入详细地址"></el-input> -->

                                    <el-select v-model="addform.collection" placeholder="请选择">
                                        <el-option v-for="item in edhuiway.pay_back" :label="item.name" :value="item.id"
                                            :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="租赁结束日期" class="left" prop="leaseendtime">
                                    <!-- <el-input v-model="addform.category_id" placeholder="请输入详细地址"></el-input> -->
                                    <el-date-picker v-model="addform.leaseendtime" value-format="yyyy-MM-dd" type="date"
                                        placeholder="选择日期"></el-date-picker>
                                    <!-- <el-select v-model="addform.category_id" placeholder="请选择"> -->
                                    <!-- <el-option v-for="item in categoryList" :label="item.name" :value="item.id"
                                            :key="item.id"></el-option> -->
                                    <!-- </el-select> -->
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item class="fwhtnew" label="签订日期" prop="settime">
                                    <!-- <el-input v-model="addform.remark" placeholder="请输入备注"></el-input> -->
                                    <el-date-picker v-model="addform.settime" value-format="yyyy-MM-dd" type="date"
                                        placeholder="选择日期"></el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="11">
                                <el-form-item label="优惠事项说明" class="left" prop="preferential">
                                    <span slot="label">
                                        优惠事项说明
                                        <el-tooltip content="具体赠送、折扣情况或无" placement="bottom" effect="light"
                                            popper-class="handlerTab_tooltip">
                                            <local-svg iconClass="tips" class="handelicon hover"
                                                style="margin-left: 4px;"></local-svg>
                                        </el-tooltip>
                                    </span>
                                    <el-input v-model="addform.preferential" placeholder="请输入优惠事项说明"></el-input>

                                    <!-- <el-select v-model="addform.type_id" placeholder="请选择"> -->
                                    <!-- <el-option v-for="item in typeList" :label="item.name" :value="item.id"
                                            :key="item.id"></el-option> -->
                                    <!-- </el-select> -->
                                </el-form-item>
                                <el-form-item label="优惠政策" class="left">
                                    <!-- <el-date-picker v-model="addform.set_time" value-format="yyyy-MM-dd" type="date"
                                        placeholder="选择日期"></el-date-picker> -->
                                    <el-input v-model="addform.policy" placeholder="请输入优惠政策"></el-input>

                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="房屋地址" class="long-input fwhtnew" prop="address">
                                    <el-input v-model="addform.address" placeholder="请输入房屋地址"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item class="fwhtnew" label="部门名称" prop="branch_id">
                                    <!-- <el-input v-model="addform.pioneer" placeholder="请输入详细地址"></el-input> -->

                                    <el-input v-model="addform.branch_name" disabled></el-input>
                                    <!-- <el-select v-model="addform.branch_id" placeholder="请选择">
                                        <el-option v-for="item in edhuiway.branch" :label="item.name" :value="item.id"
                                            :key="item.id"></el-option>
                                    </el-select> -->
                                </el-form-item>
                            </el-col>
                        </el-row>

                    </el-form>
                </div>
            </div>
            <div slot="footer" class="dialog-footer btnpart">
                <!-- <el-button v-if="type == 1" class="confirm" type="primary" @click="addOk('addform')">提 交</el-button> -->
                <el-button class="confirm" type="primary" @click="addhouse('addform')">保 存</el-button>
                <el-button class="cancel" @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { edsave } from '@/views/houseContract/api'
export default {
    name: 'edithouse',
    props: {
        show: {
            type: Boolean,
            default: () => {
                return false
            }

        },
        edlist: {
            type: Object
        },
        xialaList: {
            type: Object
        },
        edhuiway: {
            type: Object
        },
        xToken: {
            type: String
        }
    },
    data() {
        return {
            showDialog: false,
            addform: {
                name: '',//客户名称
                rentmoney: '',//租金总金额
                nowarea: '',//现面
                propertymoney: '',//物业总金额
                oldarea: '',//原始面积
                depmoney: '',//押金
                leasestarttime: '',//租赁开始日期
                leaseendtime: '',//租赁结束日期
                collection: '',//回款方式
                settime: '',//签订日期
                preferential: '',//优惠事项说明
                address: '',//房屋地址
                policy: '',//优惠政策
                branch_id: '',//部门名称
                xToken: '',
                id: ''
            },
            rules: {
                name: [
                    { required: true, message: '客户名称不能为空', trigger: 'blur' },
                ],
                rentmoney: [
                    { required: true, message: '租赁总金额不能为空', trigger: 'blur' },
                ],
                propertymoney: [
                    { required: true, message: '物业总金额不能为空', trigger: 'blur' },

                ],
                depmoney: [
                    { required: true, message: '押金金额不能为空', trigger: 'blur' },

                ],
                leasestarttime: [
                    { required: true, message: '租赁开始日期不能为空', trigger: 'blur' },

                ],
                leaseendtime: [
                    { required: true, message: '租赁结束日期不能为空', trigger: 'blur' },

                ],
                settime: [
                    { required: true, message: '签订日期不能为空', trigger: 'blur' },

                ],
                preferential: [
                    { required: true, message: '优惠事项不能为空', trigger: 'blur' },

                ],
                address: [
                    { required: true, message: '房屋地址不能为空', trigger: 'blur' },

                ],
                branch_id: [
                    { required: true, message: '部门名称不能为空', trigger: 'blur' },

                ]
            },
        }
    },
    methods: {
        changeThousand(){
            this.addform.rentmoney =this.addform.rentmoney?Number(this.addform.rentmoney.replace(/,/g, "")).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }):''
            this.addform.propertymoney =this.addform.propertymoney?Number(this.addform.propertymoney.replace(/,/g, "")).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }):''
            this.addform.depmoney =this.addform.depmoney?Number(this.addform.depmoney.replace(/,/g, "")).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }):''
        },
        cancel() {
            this.$emit('cancel', false)
            this.$refs.addform.resetFields();
        },
        addhouse(addform) {
            this.$refs[addform].validate(async (valid) => {
                if (valid) {
                    let data = {
                        xToken: this.addform.xToken,
                        'id': this.addform.id,
                        'c_id': this.addform.name,
                        'start_time': this.addform.leasestarttime,
                        'end_time': this.addform.leaseendtime,
                        'rent': this.addform.rentmoney.replace(/,/g, ""),
                        'pay_back': this.addform.collection,
                        'deposit': this.addform.depmoney.replace(/,/g, ""),
                        'property_costs': this.addform.propertymoney.replace(/,/g, ""),
                        'location': this.addform.address,
                        'old_area': this.addform.oldarea,
                        'sign_time': this.addform.settime,
                        'preferential_policy': this.addform.policy,
                        'now_area': this.addform.nowarea,
                        'preferential_matter': this.addform.preferential,
                        'branch_id': this.addform.branch_id

                    }
                    this.$loading({ text: '加载中...' });
                    edsave(data).then(res =>{
                        if (res.code === 200) {
                            this.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            this.addform = {
                                name: '',//客户名称
                                id: '',
                                rentmoney: '',//租金总金额
                                nowarea: '',//现面
                                propertymoney: '',//物业总金额
                                oldarea: '',//原始面积
                                depmoney: '',//押金
                                leasestarttime: '',//租赁开始日期
                                leaseendtime: '',//租赁结束日期
                                collection: '',//回款方式
                                settime: '',//签订日期
                                preferential: '',//优惠事项说明
                                address: '',//房屋地址
                                policy: '',//优惠政策
                                xToken: '',
                                branch_id: ''//部门名称
                            }
                            this.$emit('edit', false)
                        }
                        else{
                            this.$message.error(res.msg)
                        }
                        this.$loading().close()
                    }).catch(err => {
                        this.$message.error("请求超时")
                        this.$loading().close()
                    })
                }
            })
        }
    },
    mounted(){
        this.addhouse = this.$debounce(this.addhouse )
    },
    watch: {
        show: {
            handler(newValue, oldVal) {
                this.showDialog = newValue
            }
        },
        edlist: {
            handler(newValue, oldVal) {
                this.addform = {
                    name: newValue.client_id,
                    id: newValue.id,
                    rentmoney: newValue.rent,//租金总金额
                    nowarea: newValue.now_area,//现面
                    propertymoney: newValue.property_costs,//物业总金额
                    oldarea: newValue.old_area,//原始面积
                    depmoney: newValue.deposit,//押金
                    leasestarttime: newValue.start_time,//租赁开始日期
                    leaseendtime: newValue.end_time,//租赁结束日期
                    collection: newValue.pay_back_id === 0 ? '' : newValue.pay_back_id,//回款方式
                    settime: newValue.sign_time,//签订日期
                    preferential: newValue.preferential_matter,//优惠事项说明
                    address: newValue.location,//房屋地址
                    policy: newValue.preferential_policy,//优惠政策
                    branch_id: newValue.branch_id,
                    branch_name:newValue.branch_name
                }
                this.changeThousand()
            }
        },
        xToken: {
            handler(newV, oldV) {
                this.addform.xToken = newV
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.adduser {
    .right {
        margin-left: 45px;
    }
}

:deep(.el-form-item__label) {
    text-align: left;
    font-size: 14px;
    color: #6B6D77;
}

:deep(.el-form-item__label) {
    width: 130px !important;
}

:deep(.el-form-item__content) {
    margin-left: 122px !important;
}


:deep(.el-form-item__error) {
    padding-top: 0px !important;

}


:deep(.el-form-item__label:before) {
    position: absolute;
    // left: 23px !important;
}

:deep(.el-form-item__label) {
    padding-left: 0px !important;
    position: absolute;
}
</style>
<style lang="scss">
.fwhtnew {
    .el-form-item__label {
        width: 118px !important;
    }

    .el-form-item__content {
        margin-right: 0px !important;
    }
}
</style>