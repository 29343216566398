import { render, staticRenderFns } from "./change.vue?vue&type=template&id=f1256e0e&"
import script from "./change.vue?vue&type=script&lang=js&"
export * from "./change.vue?vue&type=script&lang=js&"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./change.vue?vue&type=style&index=1&id=f1256e0e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports