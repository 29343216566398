<template>
    <div class="contractdetail">
        <div class="banner-border" v-if="!isDetail">
            <div class="top-banner">
                <div class="background"
                    :style="{ 'background': 'url(' + image + ') no-repeat', 'backgroundSize': '1012px 100%', 'backgroundPosition': 'right', 'opacity': '0.9' }">
                    <div class="banner-left">
                        <div class="title">房屋合同单号：{{ info.num }}

                        </div>
                        <div class="message">
                            <div class="line">客户名称：<span>{{ info.client }}</span></div>
                            <div class="line">负责人：<span>{{ info.create_name }}</span></div>
                        </div>
                    </div>
                    <div class="banner-right">
                        <button class="print hover" @click="generateHouseBack">
                            <local-svg iconClass="generate"></local-svg>
                            <span>生成房屋回款</span>
                        </button>
                        <button class="btn hover" @click="goBack">
                            <local-svg iconClass="back"></local-svg>
                            <span>返回上一页</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="examine-banner">
            <div class="examine-header">
                <div class="header-left">
                    <local-svg icon-class="back" class="icon handel hover" @click.native="backList"></local-svg>
                    <div class="title">房屋合同单号：{{ info.num }}</div>
                </div>
                <div class="header-right btnpart" >
                    <!-- <el-button class="confirm" plain @click="agreeCheck" v-if="">审核同意</el-button>
                    <el-button class="cancel" plain @click="refuseCheck"  v-if="">审核拒绝</el-button> -->
                </div>
            </div>
        </div>
        <div class="main">
            <div class="descript auto-detail-part">
                <div class="header">
                    详细资料

                </div>
                <div class="descript-main">
                    <el-descriptions :column="2" border :labelStyle="LS" :contentStyle="CS">
                        <el-descriptions-item label="现面(平米)">{{ info.now_area ? info.now_area : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="原始面积(平米)">{{ info.old_area ? info.old_area : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="租金总金额(元)">{{ info.rent ? Number(info.rent).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="房屋地址">{{ info.location ? info.location : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="租赁开始日期">{{ info.start_time ? info.start_time : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="租赁结束日期">{{ info.end_time ? info.end_time : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="回款方式">{{ info.pay_back ? info.pay_back : '/'
                        }}</el-descriptions-item>

                        <el-descriptions-item label="所属部门">{{ info.branch_name ? info.branch_name : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="押金金额(元)">{{ info.deposit ? Number(info.deposit).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="物业费总金额(元)">{{ info.property_costs ? Number(info.property_costs).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '/'
                        }}</el-descriptions-item>
                        <!-- <el-descriptions-item label="累计电费回款(元)"></el-descriptions-item>
                        <el-descriptions-item label="累计物业回款(元)"></el-descriptions-item>
                        <el-descriptions-item label="累计租金回款(元)"></el-descriptions-item> -->
                        <el-descriptions-item label="修改人">{{ info.alter_name ? info.alter_name : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="签订日期">{{ info.sign_time ? info.sign_time : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="创建人">{{ info.create_name ? info.create_name : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="优惠事项说明">{{ info.preferential_matter ? info.preferential_matter :
                            '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="创建时间">{{ info.create_time ? info.create_time : '/'
                        }}</el-descriptions-item>

                        <el-descriptions-item label="优惠政策" :span="2">{{
                            info.preferential_policy ? info.preferential_policy : '/' }}</el-descriptions-item>


                    </el-descriptions>

                </div>
            </div>
            <div class="detail-right">

                <div style="margin-top: 0;" class="user">
                    <div class="table-title">
                        <span class="title">
                            <local-svg iconClass="detailuser" class="icon"></local-svg>跟进人</span>
                        <div class="addpart handel hover" @click="addfl">
                            <local-svg iconClass="add" class="icon"></local-svg>
                            添加
                        </div>
                    </div>

                    <div class="userlist" style="width: 250px;">

                        <!-- <el-empty :image-size="100" :image="image2" description="暂无跟进人，点击添加按钮操作"></el-empty> -->
                        <div v-if="fArr.length > 0">

                            <div style="width: 240px;" class="fileline hover" v-for="(item, index) in fArr" :key="index">
                                <div>
                                    <span class="name">{{ item.name }}</span>
                                </div>
                                <div class="closepart">
                                    <!-- <span>{{ item.type }}</span> -->
                                    <div class="close-box" @click="delflo(item.id)">
                                        <div class="close">
                                            <local-svg iconClass="close" class="closeicon"></local-svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <el-empty :image-size="100" :image="image2" description="暂无跟进人，点击添加按钮操作"></el-empty>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <refuseExamine v-if="refuseDialog" :show="refuseDialog" @close="cancelRefuse" @examine="confirmRefuse"></refuseExamine>
        <el-dialog title="添加跟进人" :visible.sync="showDialog" width="400px" class="addform" @close="cancel" top="15%">
            <div>
                <el-form :model="addform" :rules="rules" ref="addform" :validate-on-rule-change="false">
                    <el-form-item label="选择成员" class="left">
                        <span slot="label"><span class="check-form-notice">*</span>选择成员</span>
                        <!-- <el-input v-model="addform.name" placeholder="请输入用户名"></el-input> -->
                        <el-select v-model="addform.foloow" placeholder="请选择" filterable>
                            <el-option v-for="item in flowList" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer btnpart">
                <!-- <el-button v-if="type == 1" class="confirm" type="primary" @click="addOk('addform')">提 交</el-button> -->
                <el-button class="confirm" type="primary" @click="addhouse('addform')">确定</el-button>
                <el-button class="cancel" @click="cancel">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { addFollower, allfollow, delfollower, dandetails,examineHouse} from '@/views/houseContract/api'
import refuseExamine from '@/components/examine/refuse.vue'
export default {
    name: 'dandetail',
    props: {
        danList: {
            type: Object
        }
    },
    components: {
        refuseExamine
    },
    data() {
        return {
            isDetail:false,
            showDialog: false,
            refuseDialog:false,
            image: require('../../assets/image/detail.png'),
            image1: require('../../assets/image/none.png'),
            image2: require('../../assets/image/noneuser.png'),
            LS: {
                "word-break": "keep-all"
            },
            CS: {
                "max-width": "100px",
                "word-break": "break-all"
            },
            addform: {
                foloow: ''
            },
            rules: {
                foloow: [
                    { required: true, message: '成员不能为空', trigger: 'blur' },

                ]
            },
            flowList: [],
            fArr: this.danList.follower,
            info:{}
        }
    },
    mounted(){
        this.info = this.danList
    },
    methods: {
        generateHouseBack(){
            let data = {
                id:this.info.client_id,
                leasecon_name:this.info.num,
                leasecon:this.info.id,
                housearea:this.info.location,
                type:'generate',
            }
            this.$router.push({
                name:'housecollection',
                params:data
            })
        },
        cancelRefuse(){
            this.refuseDialog = false
        },
        confirmRefuse(msg){
            let data = {
                id:this.info.id,
                audit_status:19,
                refuse_reason:msg.reason
            }
            examinecontarct(data).then(res=>{
                if(res.code == 200){
                    this.seeDetail(this.info.id)
                    this.refuseDialog = false
                    this.$message({
                        message:res.msg,
                        type:'success'
                    })
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
            })
        },
        seeDetail(msg) {
            let data = {
                id: msg
            }
            dandetails(data).then(res => {
                this.info = res.data
            })
        },
        agreeCheck(){
            let data = {
                id:this.info.id,
                audit_status:18,
                refuse_reason:''
            }
            examinecontarct(data).then(res=>{
                if(res.code == 200){
                    this.seeDetail(this.info.id)
                    this.$message({
                        message:res.msg,
                        type:'success'
                    })
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
            })
        },
        refuseCheck(){
            this.refuseDialog = true
        },
        goBack() {
            this.$emit('back', false)
        },
        async addfl() {
            this.showDialog = true
            let res = await allfollow()

            this.flowList = res.data
            // console.log(res);
            // console.log(this.danList.branch_id);
        },
        cancel() {
            // this.$emit('cancel', false)
            this.$refs.addform.resetFields();
            this.addform = {
                foloow: ''
            }
            this.showDialog = false


        },
        addhouse(addform) {
            this.$refs[addform].validate(async (valid) => {
                if (valid) {
                    let data = {
                        id: this.info.id,
                        follower: this.addform.foloow
                    }
                    let res = await addFollower(data)
                    if (res.code === 200) {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.showDialog = false
                        let ress = await dandetails(this.info.id)
                        // console.log(ress.data.follower);
                        if (this.fArr !== ress.data.follower) {
                            this.fArr = ress.data.follower
                        }

                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                        this.showDialog = false
                    }
                }
            })
        },
        backList(){
            this.$emit('back', false)
        },
        delflo(id) {
            this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let res = await delfollower(id)
                if (res.code === 200) {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.showDialog = false
                    let ress = await dandetails(this.info.id)
                    // console.log(ress.data.follower);
                    if (this.fArr !== ress.data.follower) {
                        this.fArr = ress.data.follower
                    }

                }
                // this.$emit('deluser', row.id)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
            // console.log(1);
            // console.log(id);

            // console.log(res);
        }
    },
    // watch: {
    //     info: {
    //         handler(newV, oldV) {
    //             this.flowList = newV.follower
    //         },
    //         deep: true
    //     }
    // }
}
</script>
<style lang="scss" scoped>
.contractdetail {
    .filedialog {
        width: 800px !important;
        height: 500px !important;
    }

    .top-banner {
        width: 100%;
        height: 112px;
        border-radius: 4px;
        background: linear-gradient(90deg, #FFF 0%, #F0F4FE 50%, #F0F4FE 100%);
        display: flex;
        justify-content: flex-end;

        .background {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 26px 30px;
            display: flex;
            justify-content: space-between;

            .banner-left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .title {
                    font-size: 22px;
                    font-weight: bold;
                    color: #474952;
                    // max-width: 570px;
                    width: auto;
                    display: flex;
                    align-items: center;

                    .tip {
                        width: auto;
                        height: 24px;
                        line-height: 24px;
                        padding: 0 10px;
                        border-radius: 12px 2px 12px 2px;
                        font-size: 14px;
                    }

                    .status1 {
                        color: #8C8EA6;
                        background: #DADEEC;
                    }

                    .status2 {
                        color: #2C57FF;
                        background: #D4DEFF;
                    }

                    .status3 {
                        color: #08B54D;
                        background: #C9EFDD;
                    }

                    .status4 {
                        color: #F74B4B;
                        background: #FFE3E3;
                    }

                    .status5 {
                        color: #DE8500;
                        background: #FFEECD;
                    }
                }

                .message {
                    display: flex;

                    .line {
                        font-size: 14px;
                        color: #C4C5CB;
                        margin-right: 20px;

                        span {
                            color: #7D7F88;
                        }
                    }

                    .active {
                        color: #8999B1;
                    }
                }
            }

            .banner-right {
                display: flex;
                align-items: center;

                .btn {
                    width: 128px;
                    height: 32px;
                    box-shadow: 0px 2px 4px 0px rgba(55, 59, 92, 0.1);
                    background: linear-gradient(180deg, #F8F8FB 0%, #ECEFF6 100%);
                    border-radius: 4px 4px 4px 4px;
                    outline: none;
                    border: none;
                    font-size: 14px;
                    color: #6B6D77;

                    span {
                        margin-left: 8px;
                    }
                }

                .btn:hover {
                    background: linear-gradient(180deg, #ECEFF6 0%, #F8F8FB 100%);
                }
            }
        }
    }

    .main {
        width: 100%;
        height: 700px;
        // background: white;
        margin-top: 10px;
        display: flex;

        .title {
            font-weight: bold;
            font-size: 14px;
        }

        .descript {
            width: calc(100% - 292px);
            height: 700px;
            background-color: white;
            border-radius: 4px;

            .header {
                color: #474952;
                font-weight: 700;
                height: 55px;
                border-bottom: none !important;
                display: flex;
                padding: 24px 0 0 30px;
                box-sizing: border-box;
                font-size: 14px;

                .tab {
                    width: auto;
                    line-height: 16px;
                    margin-right: 40px;
                    color: #B0B1B9;
                }

                .tab:hover {
                    color: #2C57FF;
                }

                .acttab {
                    border-bottom: 2px solid #2C57FF;
                    font-weight: bold;
                    color: #474952 !important;
                }
            }

            .descript-main {
                padding: 28px 30px;
                box-sizing: border-box;
                margin-top: 5px;

                .filelist {
                    width: 100%;
                    height: 120px;
                    overflow-y: auto;
                    padding-right: 8px;
                    box-sizing: border-box;

                    .fileline {
                        display: flex;
                        height: 28px;
                        border-radius: 4px;
                        background: #F4F7FC;
                        width: 243px;
                        align-items: center;
                        margin-bottom: 6px;
                        padding: 0 12px 0 8px;
                        justify-content: space-between;
                        box-sizing: border-box;

                        .icon {
                            margin-right: 7px;
                            width: 12px;
                            height: 14px;
                        }

                        .name {
                            font-size: 12px;
                            color: #474952;
                        }

                        .close {
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            background: rgba(63, 69, 124, 0.1);
                            display: none;
                            align-items: center;
                            justify-content: center;

                            .closeicon {
                                width: 8px;
                                height: 8px;
                                fill: #B0B1B9;
                            }
                        }

                        .close:hover {
                            background: rgba(44, 87, 255, 0.2) !important;

                            .closeicon {
                                fill: #2C57FF !important;
                            }
                        }
                    }

                    .fileline:hover {
                        background: #EDF3FE !important;

                        .close {
                            display: flex;
                        }
                    }
                }

                .table {
                    .table-title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 14px;

                        .title {
                            font-weight: bold;
                            color: #6B6D77;
                            margin-bottom: 14px;
                        }

                        .addpart {
                            .icon {
                                fill: #475B93 !important;
                            }
                        }

                        .addpart:hover {
                            color: #2C57FF !important;

                            .svg-icon {
                                fill: #2C57FF !important;
                            }
                        }

                        .addpart:active {
                            color: #133CDC !important;

                            .svg-icon {
                                fill: #133CDC !important;
                            }
                        }
                    }
                }
            }
        }

        .detail-right {
            margin-left: 10px;
            width: 282px;

            .timetree {
                width: 100%;
                height: calc(100% - 256px);
                border-radius: 4px;
                background: white;
                padding: 24px 20px;
                box-sizing: border-box;

                .timetitle {
                    padding: 0 7px;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 16px;

                    .icon {
                        margin-right: 5px;
                    }
                }

                .tree {
                    height: calc(100% - 38px);
                    margin-top: 20px;
                    width: 100%;
                    overflow-y: auto;
                    padding: 0 4px;
                    box-sizing: border-box;

                    .el-timeline-item__node--normal {
                        width: 14px !important;
                        height: 14px !important;
                        left: -3px;
                    }

                    .el-timeline-item__node {
                        background: #FFF !important;
                        border: 1px solid #E2E5EA !important;
                    }

                    .el-timeline-item__wrapper {
                        top: 0 !important;
                        padding-left: 21px !important;
                    }

                    .el-timeline-item {
                        position: relative;
                        padding-bottom: 1px;
                    }

                    .el-timeline-item__tail {
                        border-left: 1px solid #E4E7ED !important;
                    }

                    .tree-info {
                        min-height: 64px;
                        width: 100%;
                        border-radius: 4px;
                        background: #E9EAEE;
                        padding: 16px 14px;
                        box-sizing: border-box;

                        .timeline {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 12px;
                            line-height: 14px;

                            .date {
                                color: #B0B1B9;
                            }

                            .status {
                                color: #B0B1B9;
                            }
                        }

                        .checkuser {
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: bold;
                            color: #6B6D77;
                            margin-top: 4px;
                        }

                        .reason {
                            color: #6B6D77;
                            line-height: 20px;
                            font-size: 12px;
                            margin-top: 6px;

                            .reason-title {
                                color: #B0B1B9;
                            }
                        }
                    }

                    .tree1 {
                        background: #F0FAF1;

                        .status {
                            color: #10C257 !important;
                        }
                    }

                    .tree2 {
                        background: #FAF5E6;

                        .status {
                            color: #DE8500 !important;
                        }
                    }

                    .tree3 {
                        background: #FFF4F4;

                        .status {
                            color: #F74B4B !important;
                        }
                    }

                }
            }

            .user {
                // margin-top: 10px;
                width: 100%;
                height: 246px;
                border-radius: 4px;
                background: white;
                padding: 24px 20px;
                box-sizing: border-box;

                .table-title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 14px;

                    .title {
                        font-weight: bold;
                        color: #474952;

                        .icon {
                            width: 14px;
                            height: 14px;
                            margin-right: 10px;

                            .svg-icon {
                                fill: #2C57FF !important;
                            }
                        }
                    }

                    .addpart {
                        .icon {
                            fill: #475B93 !important;
                        }
                    }

                    .addpart:hover {
                        color: #2C57FF !important;

                        .svg-icon {
                            fill: #2C57FF !important;
                        }
                    }

                    .addpart:active {
                        color: #133CDC !important;

                        .svg-icon {
                            fill: #133CDC !important;
                        }
                    }
                }

                .userlist {
                    margin-top: 16px;
                    height: 172px;
                    overflow-y: auto;
                    padding-right: 8px;
                    box-sizing: border-box;

                    .el-empty {
                        padding: 10px 0 !important;
                    }

                    .fileline {
                        display: flex;
                        height: 28px;
                        border-radius: 4px;
                        background: #F4F7FC;
                        width: 100%;
                        align-items: center;
                        margin-bottom: 6px;
                        padding: 0 12px 0 14px;
                        justify-content: space-between;
                        box-sizing: border-box;

                        .icon {
                            margin-right: 7px;
                            width: 12px;
                            height: 14px;
                        }

                        .name {
                            font-size: 12px;
                            color: #474952;
                        }

                        .close {
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            background: rgba(63, 69, 124, 0.1);
                            display: none;
                            align-items: center;
                            justify-content: center;

                            .closeicon {
                                width: 8px;
                                height: 8px;
                                fill: #B0B1B9;
                            }
                        }

                        .close:hover {
                            background: rgba(44, 87, 255, 0.2) !important;

                            .closeicon {
                                fill: #2C57FF !important;
                            }
                        }
                    }

                    .fileline:hover {
                        background: #EDF3FE !important;

                        .close {
                            display: flex;
                        }
                    }

                    .closepart {
                        display: flex;
                        align-items: center;

                        .close-box {
                            width: 16px;
                        }

                        span {
                            font-size: 14px;
                            line-height: 16px;
                            color: #B0B1B9;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}

:deep(.descript-main) {
    padding-top: 0 !important;
}
</style>
