<template>
    <div class="home">
        <div class="top-search" v-if="!isDetail">
            <el-form :model="form" class="demo-form-inline" label-width="">
                <div class="select-line bostrap" :style="{ height: (hide == false ? '43px' : 'auto') }" id="searchLine">
                    <el-form-item label="机会名称">
                        <el-input clearable class="input-height" v-model.trim="form.name" placeholder="请输入关键字搜索"></el-input>
                    </el-form-item>
                    <el-form-item class="cumClass" label="客户名称">
                        <el-select clearable filterable v-model="form.custom" placeholder="请选择" tooltip-content popper-class="custom-select">

                            <el-option :title="item.name" v-for="item in xialalist.client" :label="item.name"
                                :value="item.id" :key="item.id" >
                                <!-- <el-tooltip effect="light" class="item" :content="item.name" placement="bottom">
                                    <span style="float: left">{{ item.name }}</span></el-tooltip> -->
                            </el-option>

                        </el-select>
                    </el-form-item>
                    <el-form-item label="预计销售金额">
                        <div class="rangeinput">
                            <el-input clearable @focus="bordercolor" @blur="borderlost" v-model.trim="form.startmoney"
                                placeholder="请输入金额"></el-input>
                            至
                            <el-input clearable @focus="bordercolor" @blur="borderlost" v-model.trim="form.endmonet"
                                placeholder="请输入金额"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="销售阶段">
                        <el-select clearable v-model="form.stage" placeholder="请选择">
                            <el-option v-for="item in xialalist.stage" :label="item.name" :value="item.id" :key="item.id">

                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="预计成交日期">
                        <el-date-picker clearable v-model="form.date" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="负责人">
                        <el-select clearable v-model="form.head" placeholder="请选择" tooltip-content  filterable>
                            <el-option v-for="item in xialalist.admin" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="意向产品">
                        <el-select clearable v-model="form.intented" placeholder="请选择" tooltip-content  filterable>
                            <el-option v-for="item in xialalist.goods" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>


                </div>
                <div class="line"></div>
                <div class="btn-line">
                    <div class="btnpart">
                        <el-button class="confirm" @click="getList('serach')" v-permission="'销售机会-select'">查询</el-button>
                        <el-button class="cancel" @click="clearSeach" v-permission="'销售机会-reset'">重置</el-button>
                        <!-- <el-button class="cancel" @click="aaaaaa">1</el-button> -->
                    </div>
                    <div style="font-size: 14px;" class="showpart hover fi" @click="showHide" >
                        {{ actText }}
                        <!-- <img iconClass="showdown" class="icon" v-if="!hide" src="../../assets/image/showselect.png" alt="">
                        <img iconClass="showdown" class="icon" v-if="hide" src="../../assets/image/showselect.png" alt=""
                            style="transform: rotate(-180deg);"> -->
                        <local-svg iconClass="showdown" class="icon" :class="hide?'acticon':''"></local-svg>

                    </div>
                </div>
            </el-form>
        </div>
        <!-- 表单 -->
        <div class="tablepart main-table" v-if="!isDetail">
            <div class="top">
                <span class="span">销售机会</span>
                <div class="btnlist btnpart">
                    <el-button class="iconbtn" style="height:32px !important ;padding: 0 14px;" @click="batchDE" v-permission="'销售机会-batch_del'"
                        plain><local-svg iconClass="delete" class="icon"></local-svg>批量删除</el-button>
                    <el-button class="iconbtn" style="height:32px !important ;padding: 0 14px;" v-permission="'销售机会-sync'"
                        plain><local-svg iconClass="refsh" class="icon"></local-svg>数据同步</el-button>
                    <el-button class="iconbtn" style="height:32px !important ;padding: 0 14px;" @click="showDialog = true" v-permission="'销售机会-list'"
                        plain><local-svg iconClass="set" class="icon"></local-svg>设置列表展示列</el-button>
                    <el-button class="iconbtn" style="height:32px !important ;padding: 0 14px;" @click="exportDialog = true" v-permission="'销售机会-export'"
                        plain><local-svg iconClass="expor" class="icon"></local-svg>导出</el-button>
                    <el-button class="iconbtn" @click="newAdd" style="height:32px !important ;padding: 0 14px;" v-permission="'销售机会-create'"
                        plain><local-svg iconClass="add" class="icon"></local-svg>新建</el-button>
                </div>
            </div>
            <customTable v-if="headerList.length > 0" ref="dadtable" :list="tableData" :head="showHeader" @transfer="wantAdd"
                @tableedit="wantEdit" @stopuser="setUserType" @deluser="deleteUser" @detail="seeDetail" @BS="batchde" :loading="load" @perfect="perfectTableHose">
            </customTable>
            <div class="pagetab">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <newsale  :show="newDia" :List="sList" @cancel="cancelChange" :generate="generate_id" :channelList="channelList"></newsale>
        <editsale  :show="editDia" :List="sList" :edlist="edlist" @cancel="cancelChange" @change="change" :channelList="channelList"></editsale>
        <detail v-if="isDetail" @back="closeDetail" :info="detailData" :readDetail="readDetail"></detail>
        <setshowDialog v-if="showDialog" :list="headerList" :show="showDialog" @show="setShow" @cancel="closeShow">
        </setshowDialog>
        <exportDialog v-if="exportDialog" :list="headerList" :show="exportDialog" @export="exportTable"
            @cancel="cancelExport"></exportDialog>
        <pefecthouse v-if="perfectDialog" :show="perfectDialog" :sellName="sell_name"  @close="closePercect" @perfecthouse="perfectHouse">
        </pefecthouse>
    </div>
</template>
<script>
import { headlist, customlist } from '@/api/custom'
import { sellList, sellHead, sellLook, sellAddList, selldelete, selldesome, exportfile, perfectsell } from '@/views/sellChance/api'
import {saveheader} from '@/api/common'
import {channelselect} from '@/api/channel'
import customTable from "@/components/custom/table.vue"
import setshowDialog from "@/components/custom/show.vue"
import exportDialog from "@/components/custom/export.vue"
import changeDialog from "@/components/custom/change.vue"
import detailPart from "@/components/custom/detail.vue"
import detail from "@/components/sell/detail.vue"
import newsale from "@/components/sell/newsale.vue"
import editsale from '@/components/sell/editsale.vue'
import pefecthouse from '@/components/sell/perfect.vue'
export default {
    name: 'sellchance',
    data() {
        return {
            showDialog: false,
            exportDialog: false,
            perfectDialog:false,
            form: {
                name: '',
                custom: '',
                startmoney: '',
                endmonet: '',
                stage: '',
                date: '',
                head: '',
                intented: '',
                // start_time: this.form.date[0],
                // start_time: this.form.date[0] ? this.form.date[0] : '',
                // end_time: this.form.date[1] ? this.form.date[1] : '',


            },
            isDetail: false,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            hide: false,
            typeList: [
            ],
            roleList: [
            ],
            actText: '展开',
            headerList: [],
            tableData: [],
            showHeader: [],
            channelList:[],
            // 新建销售机会
            newDia: false,
            editDia: false,
            sList: {},
            xialalist: {},
            // detailData: {}
            // 批量删除id
            batchId: '',
            edlist: {},
            readDetail:0,
            load:true,
            generate_id:'',
            house_id:'',
            sell_name:''

        }
    },
    components: {
        customTable,
        setshowDialog,
        exportDialog,
        changeDialog,
        detailPart,
        newsale,
        detail,
        editsale,
        pefecthouse
    },
    computed: {
        start() {
            return this.form.date ? this.form.date[0] : ''
        },
        end() {
            return this.form.date ? this.form.date[1] : ''

        }
    },
    mounted() {
        if(this.$route.query.id){
            if(this.$route.query.type && this.$route.query.type=='detail'){
                let data = {
                    id:this.$route.query.id
                }
                this.seeDetail(data)
                this.readDetail = 1
            }
            if(this.$route.query.type && this.$route.query.type=='generate'){
                this.generate_id = this.$route.query.id
                this.newAdd()
            }
            if(this.$route.query.type && this.$route.query.type=='improvesell'){
                let data = {
                    id:this.$route.query.id
                }
                this.getHouseInfo(data)
            }
        }
        if(this.$route.params.id){
            if(this.$route.params.type && this.$route.params.type=='detail'){
                let data = {
                    id:this.$route.params.id
                }
                this.seeDetail(data)
                this.readDetail = 1
            }
            if(this.$route.params.type && this.$route.params.type=='generate'){
                this.generate_id = this.$route.params.id
                this.newAdd()
            }
            if(this.$route.params.type && this.$route.params.type=='improvesell'){
                let data = {
                    id:this.$route.params.id
                }
                this.getHouseInfo(data)
            }
        }
        this.getHeaderList()
        this.getList()
        this.xialaList()
        this.getChannelList()
        this.newAdd = this.$debounce(this.newAdd )
    },
    methods: {
        getChannelList(){
            channelselect().then(res=>{
                this.channelList = res.data
            })
        },
        perfectHouse(e){
            let data = {
                id:this.house_id,
                room_info:e.house
            }
            perfectsell(data).then(res =>{
                if(res.code == 200){
                    this.$message.success(res.msg)
                    this.perfectDialog = false
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        closePercect(){
            this.perfectDialog = false
        },
        perfectTableHose(msg){
            let data = {
                id:msg
            }
            this.getHouseInfo(data)
        },
        getHouseInfo(data){
            sellLook(data).then(res =>{
                this.sell_name = res.data.name
                this.house_id = res.data.id
                this.perfectDialog = true
            })
        },
        async seeDetail(msg) {
            let data = {
                id: msg.id
            }
            console.log(msg.id);
            let res = await sellLook(data)
            this.isDetail = true
            this.detailData = res.data
        },
        async getList(e) {
            if(e){
                this.currentPage = 1
            }
            this.load = true

            let data = {
                page: this.currentPage,
                count: this.pageSize,
                name: this.form.name,
                start_amount: this.form.startmoney,
                end_amount: this.form.endmonet,
                client: this.form.custom,
                stage_id: this.form.stage,
                start_time: this.start,
                end_time: this.end,
                goods_id: this.form.intented,
                admin_id: this.form.head

            }
            let res = await sellList(data)

            this.tableData = res.data.table
            this.total = res.data.total
            setTimeout(() => {
                this.load = false
            }, 1000);

        },
        clearSeach() {
            this.form = {
                name: '',
                custom: '',
                startmoney: '',
                endmonet: '',
                stage: ''
            }
            this.getList('search')
        },
        showHide() {
            this.actText = this.hide ? '展开' : '收起'
            this.hide = !this.hide
        },
        getHeaderList() {
            let data = {
                id:2
            }
            sellHead(data).then(res => {
                this.showHeader = []
                this.headerList = res.data
                this.headerList.forEach(item => {
                    this.$set(item, 'check', item.is_show==1?true:false)
                    if(item.is_show==1){
                        this.showHeader.push(item)
                    }
                })
                this.$nextTick(() => {
                    this.$refs.dadtable.$refs.showTable.doLayout()
                })
            })
        },
        closeDetail() {
            this.getList()
            this.isDetail = false
        },
        wantAdd() { },
        async wantEdit(val) {
            let data = {
                id: val.info.id
            }
            let list = await sellLook(data)
            this.editDia = true
            this.edlist = list.data
            let res = await sellAddList()
            this.sList = res.data.option
        },
        setUserType() { },
        setUserType() { },
        async deleteUser(id) {
            // console.log(id);
            let res = await selldelete(id)
            console.log(res);
            if (res.code === 200) {
                this.$message({
                    type: 'success',
                    message: res.msg
                });
                this.getList()
            } else {
                this.$message({
                    type: 'error',
                    message: '当前绑定数据不允许删除'
                });
            }


            // console.log(res);
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.currentPage = 1
            this.getList()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getList()
        },
        // 新建销售机会
        async newAdd() {
            let res = await sellAddList()
            this.sList = res.data.option
            this.newDia = true
        },
        //获取下拉
        async xialaList() {
            let res = await sellAddList()
            this.xialalist = res.data.option
            // console.log(res);
        },
        cancelChange(val) {
            this.generate_id = ''
            this.newDia = val
            this.editDia = val
            this.getList()
        },
        change(val) {
            this.editDia = val
            this.getList()
        },
        // 设置展示列保存&取消
        setShow(msg) {
            let ids = []
            msg.forEach(item =>{
                ids.unshift(item.id)
            })
            let data = {
                id:ids.toString(),
                type:2
            }
            saveheader(data).then(res =>{
                if(res.code == 200){
                    this.$message.success(res.msg)
                    this.getHeaderList()
                    
                    this.showDialog = false
                    this.showHeader = msg
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        closeShow(msg) {
            this.showDialog = false
        },
        exportTable(msg) {
            let list = []
            msg.forEach(item => {
                list.push(item.id)
            })
            let data = {
                name: this.form.name,
                start_amount: this.form.startmoney,
                end_amount: this.form.endmonet,
                client_id: this.form.custom,
                stage_id: this.form.stage,
                start_time: this.start,
                end_time: this.end,
                goods_id: this.form.intented,
                admin_id: this.form.head
            }
            window.open(`${this.$BASE_URL}sell/export?sell=${list.toString()}&name=${data.name}&start_amount=${data.start_amount}&end_amount=${data.end_amount}
            &client_id=${data.client_id}&stage_id=${data.stage_id}&start_time=${data.start_time}&end_time=${data.end_time}&goods_id=${data.goods_id}&admin_id=${data.admin_id}`, '_blank');
        },
        cancelExport() {
            this.exportDialog = false
        },
        batchde(val) {
            console.log(val);
            this.batchId = val
            // console.log(val);
        },
        batchDE() {
            if (this.batchId) {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    let res = await selldesome(this.batchId)
                    // console.log(res);
                    if (res.code === 403) {
                        this.$message({
                            type: 'error',
                            message: res.msg
                        });
                    } else {
                        this.$message({
                            type: 'success',
                            message: res.msg
                        });
                    }

                    this.getList()
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            } else {
                this.$message({
                    type: 'error',
                    message: '当前没有选中删除内容'
                });
            }


        },
        bordercolor() {

            document.querySelector('.rangeinput').classList.add('rangeinputborder')
        },
        borderlost() {
            document.querySelector('.rangeinput').classList.remove('rangeinputborder')

        },
        // aaaaaa() {
        //     this.$bus.$emit('data', {
        //         icon
        //             :
        //             "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAkxJREFUOE+llD9oFEEYxX/fesLdKSixSSFoEbAzimIuEIl2NknUFBYRTJeARVIETHHgCVdckSJlUolgCgXBOxQshJyo5CKiSWdh+hQikRgvRdgns9ldj3XzRzOwsMzM99tv3ns7xi5DUgYYAorAFlAB5szMvacO22lB0mAI6EjsWQJKZlZNq/0LKKkXmAIu7tY9kAqOgSGoBFzZA5RcrgPjZrbsFkxSZ3i0a/8ISm5/HkghqQlkDwiLyr854BhQBo4eELrm0hBoKKkduAeM/ke3DjTtHjP7YaV5Zc5mKNy8bO9C8H1geB/gTWDG6fagzkZnlp4b3Va3WkNvgR7BkkGpv2BVSWdCo66nyOBC/djJZGYrtYYGERWMDkHDAReAQlSYALsEuChFYAdyoV6pfVCvfKasJa8SrwINq4u6gygZnI7B4rXnMdHXZcthx1sRCD/4SJxXia9mTPYX7Fkc7IfzyrblGDGYBJxJ0XD5cp25cbulWxwIj/LnX8yVrm7/31Zb1Ag+4zJmvjeZdZNtOe6GETi+Q5RWfSgvNZk9BZkTWVz0hvGYsuqC1s3iDK4KKg58LM+RjCgiRrHt4AvWMKbzh5h+v87G+TxD5gwJTyTx0140dMHfvgz+/MPiizwqA1326Om82rM5xhBrucPMxCCfonO25QR1X0zGGtYaGpBzEM4lHf/U5KWbCzpKgAQfzWOi/5K9CTRMapQGRmwKtlqkCQzxoNjXbU9aGakXbOB4nluIssHJ1oI0Z/cERhuiKAEToSkV52wUkbQE/Aa/+/jywvZgUQAAAABJRU5ErkJggg=="
        //         , id
        //             :
        //             2
        //         , name
        //             :
        //             "icon1"
        //         , path
        //             :
        //             "/custom"
        //         , title
        //             :
        //             "客户管理"
        //     })
        // }
    },
}
</script>
<style src="@/assets/scss/formstyle.scss" lang="scss" ></style>

<style lang="scss" scoped>
.home {
    padding: 24px;

    .top-search {
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;

        .select-line {
            display: flex;
            padding: 16px 20px 0 0;
            box-sizing: content-box;
        }

        .line {
            width: 100%;
            height: 1px;
            background: #F0F1F5;
        }

        .btn-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 11px 20px;

            .act {
                background: #2C57FF;
                color: white;
            }

            .btnpart button {
                height: 32px;
                width: 68px;
                line-height: 31px;
                padding: 0;

            }
        }

        .rangeinput {
            width: 252px;
            height: 30px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #E2E5EA;
            display: flex;
            justify-content: space-between;
            align-items: center;

            :deep(.select-line .el-input__inner) {
                border: none !important;
            }
        }

        .rangeinputborder {
            border: 1px solid #2C57FF;

        }


    }

    .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
            font-size: 14px;
            color: #474952;
            font-weight: bold;
        }

        .icon {
            width: 16px;
            height: 16px;
        }

        i {
            margin-right: 5px;
        }
    }

    .tablepart {
        min-height: 72vh;
        // min-height: calc(72vh - 24px);
        background: white;
        margin-top: 10px;
        border-radius: 4px;
        padding: 16px 20px;

        .icon {
            margin-right: 8px;
            // width: 8px;
            // height: 8px;
        }

        .pagetab {
            text-align: right;
            margin-top: 20px;
        }

        .span {
            font-size: 14px;
            font-weight: bold;
            color: #474952;
        }

        .top {
            display: flex;
            align-items: center;
        }
    }
}

.fi {
    display: flex;
    align-items: center;
    color: #475B93 !important;
    .icon {
        width: 7px;
        height: 18px;
        margin-left: 5px;
        margin-top: 2px;
    }
    
}
.fi:hover {
    color: #2C57FF !important;
    
}
.acticon{
        transform: rotate(180deg);
    }
</style>
<style lang="scss">
.rangeinput {
    .el-input__inner {
        border: none !important;
        width: 112px !important;
        background-color: transparent !important;
    }


}

.cumClass {
    .el-select-dropdown {
        width: 204px !important;

    }
}
</style>