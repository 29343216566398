<template>
    <div class="adduser">
        <el-dialog :title="title" :visible.sync="showDialog" width="1000px" class="addform" @close="cancel" :close-on-click-modal="false" style="margin-top: -10vh !important;">
            <div class="addform contractform report">
                <div class="formbox">
                    <el-form :model="addform" :rules="rules" ref="addform" >
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="公告标题" prop="title">
                                    <span slot="label"><span class="check-form-notice">*</span>公告标题</span>
                                    <el-input v-model="addform.title" placeholder="请输入公告标题"></el-input>
                                </el-form-item>
                            </el-col> 
                            <el-col :span="24">
                                <el-form-item >
                                    <span slot="label"><span class="check-form-notice">*</span>公告内容</span>
                                    <div style="border-radius: 4px 4px 4px 4px;">
                                        <Toolbar
                                            style="border: 1px solid #E2E5EA;border-radius: 4px 4px 0 0;border-bottom: none;"
                                            :editor="editor"
                                            :defaultConfig="toolbarConfig"
                                            :mode="mode"
                                        />
                                        <Editor
                                            style="height: 220px; overflow-y: hidden;border: 1px solid #E2E5EA;border-radius: 0 0 4px 4px;"
                                            v-model="addform.content"
                                            :defaultConfig="editorConfig"
                                            :mode="mode"
                                            @onCreated="onCreated"
                                            aria-placeholder="请输入公告内容"
                                        />
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="发布方式">
                                    <span slot="label"><span class="check-form-notice">*</span>发布方式</span>
                                    <div class="tablepart">    
                                        <div class="top">     
                                            <el-radio-group v-model="addform.way">
                                                <el-radio label="1">立即发布</el-radio>
                                                <el-radio label="2">定时发布</el-radio>
                                            </el-radio-group>
                                        </div>    
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="" class="timedate">
                                    <!-- <div class="set-date">
                                        <el-select v-model="addform.time" style="width: 160px !important;margin-right: 10px !important;">
                                            <el-option v-for="item in dateList" :label="item.name" :value="item.id"
                                            :key="item.id"></el-option>
                                        </el-select>
                                        <el-input class="time" v-model="addform.timedate" style="width: 240px !important;" @focus="checkTime">
                                            <i slot="prefix" class="el-input__icon el-icon-time"></i>
                                        </el-input>
                                        <div class="set-timebox" v-if="checkmonth">
                                            <div class="box-header">
                                                <div class="date">选择日期</div>
                                                <div class="time">选择时间</div>
                                            </div>
                                            <div class="box-select">
                                                <div class="date">
                                                    <div v-for="(item,index) in monthList" :key="index" class="date-num hover" :class="{'actdate':actDate == index}" @click="checkDate(index)">
                                                        <span >{{ item }}</span>
                                                    </div>
                                                </div>
                                                <div class="time">
                                                    <div class="time-list" ref="hour" >
                                                        <div v-for="(item,index) in hourList" :key="index" >
                                                            <div v-if="item!==''" class="list-child hover" :class="{'acttime':actHour==index}" @click="checkHour(index)">
                                                                {{ item }}
                                                            </div>
                                                            <div v-else class="list-child" >
                                                                {{ item }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="time-list" ref="minute" >
                                                        <div v-for="(item,index) in minuteList" :key="index">
                                                            <div v-if="item!==''" class="list-child hover" :class="{'acttime':actMin==index}" @click="checkMin(index)">
                                                                {{ item }}
                                                            </div>
                                                            <div v-else class="list-child" >
                                                                {{ item }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="time-list" ref="second">
                                                        <div v-for="(item,index) in secondList" :key="index" >
                                                            <div v-if="item!==''" class="list-child hover" :class="{'acttime':actSec==index}" @click="checkSec(index)">
                                                                {{ item }}
                                                            </div>
                                                            <div v-else class="list-child" >
                                                                {{ item }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="box-bottom">
                                                <div class="date">{{date}}-{{ hour }}:{{ min }}:{{sec}}</div>
                                                <div class="btnlist btnpart">
                                                    <span class="rightnow handel hover" @click="rightNow">此刻</span>
                                                    <el-button class="confirm" @click="setOk" plain>确定</el-button>
                                                    <el-button class="cancel" @click="cancelSet" plain>取消</el-button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="set-timebox set-timebox-week" v-if="checkweek">
                                            <div class="box-header">
                                                <div class="week">选择日期</div>
                                                <div class="time">选择时间</div>
                                            </div>
                                            <div class="box-select">
                                                <div class="week" ref="week">
                                                    <div v-for="(item,index) in weekList" :key="index" class="date-num hover" :class="{'actdate':actWeek == index}" @click="checkWeek(index)">
                                                        <span >{{ item }}</span>
                                                    </div>
                                                </div>
                                                <div class="time">
                                                    <div class="time-list" ref="hour" >
                                                        <div v-for="(item,index) in hourList" :key="index" >
                                                            <div v-if="item!==''" class="list-child hover" :class="{'acttime':actHour==index}" @click="checkHour(index)">
                                                                {{ item }}
                                                            </div>
                                                            <div v-else class="list-child" >
                                                                {{ item }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="time-list" ref="minute" >
                                                        <div v-for="(item,index) in minuteList" :key="index">
                                                            <div v-if="item!==''" class="list-child hover" :class="{'acttime':actMin==index}" @click="checkMin(index)">
                                                                {{ item }}
                                                            </div>
                                                            <div v-else class="list-child" >
                                                                {{ item }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="time-list" ref="second">
                                                        <div v-for="(item,index) in secondList" :key="index" >
                                                            <div v-if="item!==''" class="list-child hover" :class="{'acttime':actSec==index}" @click="checkSec(index)">
                                                                {{ item }}
                                                            </div>
                                                            <div v-else class="list-child" >
                                                                {{ item }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="box-bottom">
                                                <div class="date">{{week}}-{{ hour }}:{{ min }}:{{sec}}</div>
                                                <div class="btnlist btnpart">
                                                    <span class="rightnow handel hover" @click="rightNow">此刻</span>
                                                    <el-button class="confirm" @click="setOk" plain>确定</el-button>
                                                    <el-button class="cancel" @click="cancelSet" plain>取消</el-button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="set-timebox set-timebox-time" v-if="checktime">
                                            <div class="box-header">
                                                <div class="time">选择时间</div>
                                            </div>
                                            <div class="box-select">
                                                <div class="time">
                                                    <div class="time-list" ref="hour" >
                                                        <div v-for="(item,index) in hourList" :key="index" >
                                                            <div v-if="item!==''" class="list-child hover" :class="{'acttime':actHour==index}" @click="checkHour(index)">
                                                                {{ item }}
                                                            </div>
                                                            <div v-else class="list-child" >
                                                                {{ item }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="time-list" ref="minute" >
                                                        <div v-for="(item,index) in minuteList" :key="index">
                                                            <div v-if="item!==''" class="list-child hover" :class="{'acttime':actMin==index}" @click="checkMin(index)">
                                                                {{ item }}
                                                            </div>
                                                            <div v-else class="list-child" >
                                                                {{ item }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="time-list" ref="second">
                                                        <div v-for="(item,index) in secondList" :key="index" >
                                                            <div v-if="item!==''" class="list-child hover" :class="{'acttime':actSec==index}" @click="checkSec(index)">
                                                                {{ item }}
                                                            </div>
                                                            <div v-else class="list-child" >
                                                                {{ item }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="box-bottom">
                                                <div class="date">{{ hour }}:{{ min }}:{{sec}}</div>
                                                <div class="btnlist btnpart">
                                                    <span class="rightnow handel hover" @click="rightNow">此刻</span>
                                                    <el-button class="confirm" @click="setOk" plain>确定</el-button>
                                                    <el-button class="cancel" @click="cancelSet" plain>取消</el-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <span slot="label"><span class="check-form-notice">*</span>发布时间</span>
                                    <el-date-picker
                                        class="timedateselect"
                                        v-model="addform.date"
                                        type="datetime"
                                        placeholder="选择日期时间"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        :disabled="addform.way==1">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24" >
                                <el-form-item class="numbercount">
                                    <span slot="label"><span class="check-form-notice"></span>发布优先级</span>
                                    <el-input-number v-model="addform.lever" @change="handleChange" :min="1" label="描述文字"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </div>
            <div slot="footer" class="dialog-footer btnpart">
                <el-button v-if="type==1" class="confirm" type="primary" @click="addOk('addform','1')">保存并提交</el-button>
                <el-button v-if="type==1" class="savebtn" type="primary" @click="addOk('addform','2')">保存</el-button>
                <el-button v-if="type==2" class="confirm" type="primary" @click="editOk('addform','1')">保存并提交</el-button>
                <el-button v-if="type==2" class="savebtn" type="primary" @click="editOk('addform','2')">保存</el-button>
                <el-button class="cancel" @click="cancel">取消</el-button>
            </div>
            
        </el-dialog>
    </div>
</template>

<script>
import localSvg from '@/icons/localSvg.vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import md5 from 'js-md5'
import service from "@/utils/request.js";
import { Loading } from 'element-ui';
import receiveCost from '@/components/constapply/receive.vue'
import { ok } from 'assert';
export default {
    components: { localSvg},
    name: "adduser",
    props: {
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        form:{
            type:Object,
        	default:()=>{
        		return false
        	}
        },
        type:{
            type:Number,
            default:() =>{
                return 1
            }
        },
    },
    components:{
        receiveCost,
        Editor,
        Toolbar
    },
    data() {
        return {
            editor: null,
            html:'',
            toolbarConfig: {
                excludeKeys:["uploadVideo","emotion",'group-indent','insertLink','group-image','group-video',]
            },
            editorConfig: { placeholder: '请输入内容...', },
            mode: 'default', // or 'simple'
            image:require('../../../assets/image/none.png'),
            list:[],
            actIndex:0,
            dateList:[{
                name:'周',
                id:1
            },{
                name:'月',
                id:2
            },{
                name:'日',
                id:3
            }],
            receivename:'-请选择收款方-',
            fileList:[],
            contracttyprList:[
                {id:2,name:'其他合同'},
                {id:1,name:'房屋合同'},
            ],
            havenoList:[
                {id:1,name:'有'},
                {id:2,name:'无'},
            ],
            addform:{
                title:'',
                content:'',
                way:'1',
                date:'',
                lever:1,
                is_release:1
            },
            weekList:['星期一', '星期二', '星期三', '星期四', '星期五', '星期六','星期日' ],
            sellList:[],
            monthList:[],
            hourList:[],
            minuteList:[],
            secondList:[],
            title:'新建系统公告',
            showDialog:false,
            rules: {
                // title: [
                //     { required: true, message: '请输入公告标题', trigger: 'blur' },
                // ],
                // content: [
                //     { required: true, message: '请输入公告内容', trigger: 'blur' },
                // ],
                // way: [
                //     { required: true, message: '请选择发布方式', trigger: 'change' },
                // ],
                // date: [
                //     { required: true, message: '请选择发布时间', trigger: 'change' }
                // ],
                // lever:[
                //     { required: true, message: '请设置发布优先级', trigger: 'change' }
                // ]
            },
            // checkmonth:false,
            // checkweek:false,
            // checktime:false,
            // week:'星期一',
            // date:'01',
            // hour:'00',
            // min:'00',
            // sec:'00',
            // actHour:0,
            // actMin:0,
            // actSec:0,
            // actDate:0,
            // actWeek:0,
        };
    },
    watch:{
    },
    created(){
        setTimeout(() => {
            this.html = ''
        }, 1500)
    },
    mounted(){
        this.showDialog = this.show
        // this.setTime()
        if(this.type == 2 ){
            this.addform = this.form
            this.addform.way  = this.form.way_id+''
            this.addform.content = this.setHtml(this.addform.content)
            let date = this.form.date
            this.$set(this.addform,'date',date)
        }
        
    },
    methods: {
        setHtml(str){
            return str
            .replace(str ? /&(?!#?\w+;)/g : /&/g, '&amp;')
            .replace(/&amp;nbsp;/g, "")
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&quot;/g, "\"")
            .replace(/&#39;/g, "\'")
        },
        handleChange(value){
            
        },
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
        onChange(editor) { console.log('onChange', editor.children) },
        onDestroyed(editor) { console.log('onDestroyed', editor) },
        onMaxLength(editor) { console.log('onMaxLength', editor) },
        onFocus(editor) { console.log('onFocus', editor) },
        onBlur(editor) { console.log('onBlur', editor) },
        customAlert(info, type) { window.alert(`customAlert in Vue demo\n${type}:\n${info}`) },
        customPaste(editor, event, callback) {
            console.log('ClipboardEvent 粘贴事件对象', event)
            // const html = event.clipboardData.getData('text/html') // 获取粘贴的 html
            // const text = event.clipboardData.getData('text/plain') // 获取粘贴的纯文本
            // const rtf = event.clipboardData.getData('text/rtf') // 获取 rtf 数据（如从 word wsp 复制粘贴）

            // 自定义插入内容
            editor.insertText('xxx')

            // 返回 false ，阻止默认粘贴行为
            event.preventDefault()
            callback(false) // 返回值（注意，vue 事件的返回值，不能用 return）

            // 返回 true ，继续默认的粘贴行为
            // callback(true)
        },
        delRow(row,index){
            this.addform.fee_apply_details.splice(index,1)
        },
        addDetail(){
            this.addform.fee_apply_details.push({
                contract_id:'',
                back_id:'',
                business_type:'',
                business_detail:'',
                type:'',
                backlist:[],
            })
        },
        changTab(index){
            this.actIndex = index
        },
        addOk(addform,type){
            this.$refs[addform].validate((valid) => {
                if (valid) {
                    this.addform.is_release = type==1?1:0
                    this.$emit('add',this.addform)
                } else {
                    return false;
                }
            });
            
        },
        editOk(addform,type){
            this.$refs[addform].validate((valid) => {
                if (valid) {
                    this.addform.is_release = type==1?1:0
                    this.$emit('edit',this.addform)
                } else {
                    return false;
                }
            });
        },
        cancel(){
            this.$emit('cancel',false)
        },
        clean(){
            
        },
        checkTime(){
            if(this.addform.time==1){
                this.checkmonth = true
            }else if(this.addform.time==2){
                this.checkweek=true
            }else{
                this.checktime = true
            }
        },
        setOk(){
            this.addform.timedate = this.addform.time==1? this.date+'-'+this.hour+':'+this.min+':'+this.sec:this.week+'-'+this.hour+':'+this.min+':'+this.sec
            this.checkmonth = false
            this.checkweek = false
            this.checktime = false
        },
        cancelSet(){
            this.checkmonth = false
            this.checkweek = false
            this.checktime = false
        },
        rightNow(){
            this.timeFormate(new Date());
        },
        timeFormate(timeStamp) {
            // let year = new Date(timeStamp).getFullYear();
            // let month =new Date(timeStamp).getMonth() + 1 < 10? "0" + (new Date(timeStamp).getMonth() + 1): new Date(timeStamp).getMonth() + 1;
            // let date =new Date(timeStamp).getDate() < 10? "0" + new Date(timeStamp).getDate(): new Date(timeStamp).getDate();
            // let hh =new Date(timeStamp).getHours() < 10? "0" + new Date(timeStamp).getHours(): new Date(timeStamp).getHours();
            // let mm =new Date(timeStamp).getMinutes() < 10? "0" + new Date(timeStamp).getMinutes(): new Date(timeStamp).getMinutes();
            // let ss =new Date(timeStamp).getSeconds() < 10? "0" + new Date(timeStamp).getSeconds(): new Date(timeStamp).getSeconds();
            // let wk = new Date(timeStamp).getDay()
            // let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
            // let week = weeks[wk]
            // for(var a =0;a<this.weekList.length;a++){
            //     if(week == this.weekList[a]){
            //         this.actWeek = a
            //     }
            // }
            // this.hour = hh
            // this.actHour = Number(hh)
            // this.min = mm
            // this.actMin = Number(mm)
            // this.sec = ss
            // this.actSec = Number(ss)
            // this.date = date
            // this.actDate = Number(date)
            // this.week = week
            // this.$refs.hour.scrollTop = this.actHour*34
            // this.$refs.minute.scrollTop = this.actMin*34
            // this.$refs.second.scrollTop = this.actSec*34
            // this.$refs.week.scrollTop = this.actWeek*34
        },
        checkDate(index){
            this.actDate = index
            this.date = index<9?'0'+this.monthList[index]:this.monthList[index]
        },
        checkHour(index){
            this.actHour = index
            this.hour = index<9?'0'+this.hourList[index]:this.hourList[index]
            this.$refs.hour.scrollTop = index*34
        },
        checkMin(index){
            this.actMin = index
            this.min = index<9?'0'+this.minuteList[index]:this.minuteList[index]
            this.$refs.minute.scrollTop = index*34
        },
        checkSec(index){
            this.actSec = index
            this.sec = index<9?'0'+this.secondList[index]:this.secondList[index]
            this.$refs.second.scrollTop = index*34
        },
        checkWeek(index){
            this.actWeek = index
            this.week = this.weekList[index]
            this.$refs.week.scrollTop = index*34
        },
        setTime(){
            for(var a = 0;a<60;a++){
                this.secondList.push(a)
            }
            for(var a = 0;a<60;a++){
                this.minuteList.push(a)
            }
            for(var a = 0;a<24;a++){
                this.hourList.push(a)
            }
            for(var a = 1;a<32;a++){
                this.monthList.push(a)
            }
            for(var a = 0;a<6;a++ ){
                this.hourList.push('')
                this.minuteList.push('')
                this.secondList.push('')
                this.weekList.push('')
            }
        },
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss">
.adduser {
    .headtab{
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 15px 0 10px 0 ;
        .tab-box{
            display: flex;
            justify-content: space-between;
            width: 132px;
            font-size: 14px;
            border-bottom: 1px solid #F0F1F5;
            box-sizing: content-box;
            height: 25px;
            .tab{
                color: #B0B1B9;
                height: 100%;
            }
            .tab:hover{
                color: #2C57FF;
            }
            .acttab{
                font-weight: bold !important;
                color: #6B6D77 !important;
                border-bottom:2px solid #2C57FF !important;
                width: auto;
                height: 100%;
            }
        }
    }
    .right{
        margin-left: 45px;
        .filelist{
            width: 100%;
            height: 200px;
            overflow-y: auto;
            padding-right: 8px ;
            box-sizing: border-box;
            .fileline{
                display: flex;
                height: 28px;
                border-radius: 4px;
                background: #F4F7FC;
                width: 100%;
                align-items: center;
                margin-bottom: 6px;
                padding: 0 12px 0 8px;
                justify-content: space-between;
                box-sizing: border-box;
                .icon{
                    margin-right: 7px;
                    width: 12px;
                    height: 14px;
                }
                .name{
                    font-size: 12px;
                    color: #474952;
                }
                .close{
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background: rgba(63,69,124,0.1);
                    display: none;
                    align-items: center;
                    justify-content: center;
                    .closeicon{
                        width: 8px;
                        height: 8px;
                        fill: #B0B1B9;
                    }
                }
                .close:hover{
                    background: rgba(44, 87, 255, 0.2) !important;
                    .closeicon{
                        fill: #2C57FF !important;
                    }
                }
            }
            .fileline:hover{
                background:#EDF3FE !important;
                .close{
                    display: flex;
                }
            }
        }
    }
    .file{
        width: 100%;
        height: auto;
            .filepart{
            width: 100%;
            display: flex;
            .filetip{
                font-size: 10px;
                color: #D0D0D2;
                line-height: 45px;
                margin-left: 8px;
            }
            .icon{
                margin-right: 7px;
            }
        }
    }
    .formbox{
        height: 430px;
        .el-select .el-input .el-select__caret{
            display: flex; 
            justify-content: center;
            align-items: center;
        }
        .table-main{
            padding: 14px 0 ;
            box-sizing: border-box;
            .table-title{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                .title{
                    font-weight: bold;
                    color: #6B6D77;
                }
                .addpart{
                    .icon{
                        fill: #475B93 !important;
                    }
                }
                .addpart:hover{
                    color: #2C57FF !important;
                    .svg-icon{
                        fill: #2C57FF !important;
                    }
                }
                .addpart:active{
                    color: #133CDC !important;
                    .svg-icon{
                        fill: #133CDC !important;
                    }
                }
            }
            .table{
                margin-top: 12px;
            }
        }
    }
    .set-timebox{
        width: 480px;
        height: 340px;
        border-radius: 4px;
        position: absolute;
        background: #fff;
        left: 170px;
        top: 42px;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        .box-header{
            display: flex;
            flex-wrap: nowrap;
            height: 52px;
            border-bottom: 1px #F0F1F5 solid;
            .date{
                width: 315px;
                text-align: center;
                line-height: 52px;
                font-size: 14px;
                color: #474952;
            }
            .week{
                width: 186px;
                text-align: center;
                line-height: 52px;
                font-size: 14px;
                color: #474952;
            }
            .time{
                width: 185px;
                text-align: center;
                line-height: 52px;
                font-size: 14px;
                color: #474952;
            }
        }
        .box-select{
            display: flex;
            flex-wrap: nowrap;
            height: 238px;
            border-bottom: 1px #F0F1F5 solid;
            .date{
                width: 315px;
                box-sizing: border-box;
                display: flex;
                flex-wrap: wrap;
                padding: 15px 3px 0 21px;
                .date-num{
                    margin-left: 3px;
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #6B6D77;
                    font-size: 14px;
                }
                .date-num:hover{
                    background-color: #F2F4FB;
                }
                .actdate{
                    background-color: #2C57FF !important;
                    color: white;
                }
            }
            .week{
                width: auto;
                box-sizing: border-box;
                display: flex;
                flex-wrap: wrap;
                overflow-y:auto;
                .date-num{
                    width: 100%;
                    height: 34px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #6B6D77;
                    font-size: 14px;
                }
                .date-num:hover{
                    background-color: #F2F4FB;
                }
                .actdate{
                    background-color: #2C57FF !important;
                    color: white;
                }
            }
            .week::-webkit-scrollbar {
                display: none;
            }
            .time{
                padding:0 0 ;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                width: 185px;
                .time-list{
                    width: 60px;
                    overflow-y:auto;
                    text-align: center;
                    box-sizing: border-box;
                    border-left: 1px #F0F1F5 solid;
                    .list-child{
                        line-height: 34px;
                        height: 34px;
                        color: #B0B1B9;
                    }
                    .list-child:hover{
                        color: #2C57FF;
                        font-weight: bold;
                    }
                    .acttime{
                        // background-color: ;
                        color:#6B6D77 !important;
                        background: #EDF3FE;
                        font-weight: bold;
                    }
                }
                .time-list::-webkit-scrollbar {
                    display: none;
                }
            }
        }
        .box-bottom{
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 8px 20px 11px 24px;
            align-items: center;
            .date{
                line-height: normal;
                color: #474952;
                font-weight: bold;
            }
            .btnlist{
                line-height: normal;
                display: flex;
                align-items: center;
                .rightnow{
                    margin-right: 20px;
                }
                button{
                    width: 56px !important;
                    text-align: center;
                    padding: 0;
                }
            }
        }
    }
    .set-timebox-week{
        width: 366px !important;
        
    }
    .set-timebox-week{
        // width:185px !important;
        
    }
}
.handlerTab_tooltip{
    border: none !important;
        box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
        color: #6B6D77 !important;
        padding: 8px 16px !important;
        line-height: 20px !important;
}
.handlerTab_tooltip.is-light{
    border: none !important;
}
.handlerTab_tooltip .popper__arrow {
    /* 上方箭头 */
    border-top-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
    /* 下方箭头 */
    border-bottom-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
}
.remark{
    .el-textarea__inner{
        height: 80px !important;
        line-height: 26px !important;
        padding: 5px 12px !important;
        font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    }
    .el-textarea__inner:hover{
        border-color: #2C57FF !important;
        
    }
}
.checkcontract{
    width: auto !important;
}
</style>
