<template>
    <div class="binduser">
        <el-dialog title="关联用户" :visible.sync="showDialog" width="800px" class="addform"  @close="cancel">
            <div class="header btnpart" style="padding-top: 20px;">
                <el-input type="text" class="select-input" v-model="selectName" placeholder="请输入关键字检索">
                    <el-select  slot="prefix" placeholder="请选择" v-model="selectType">
                        <el-option v-for="item in selectList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                    </el-select>
                </el-input>
                <el-button class="confirm" type="primary" @click="getList('search')" plain>查询</el-button>
            </div>
            <div class="body">
                <div class="table">
                    <el-table
                        ref="multipleTable"
                        :data="list"
                        height="375px"
                        style="width: 100%"
                        :header-cell-style="{'background':'#F4F7FC','color':'#B0B1B9','fontWeight':'bold'}"
                        tooltip-effect="dark"
                        @select="handleSelectionChange"
                        @select-all="selectAll"
                        v-loading="loading"
                        >
                        <template slot="empty">
                            <el-empty :image-size="160" image="" description='暂无数据'></el-empty>
                        </template>
                        <el-table-column type="selection" :reserve-selection="true" width="" align="center"></el-table-column>
                        <el-table-column type="index" width="" label="编号"  align="center"></el-table-column>
                        <el-table-column prop="name" label="用户名" width=""  show-overflow-tooltip></el-table-column>
                        <el-table-column prop="partarray" label="所属部门" width="150"  show-overflow-tooltip></el-table-column>
                        <el-table-column prop="rolearray" label="角色类型" width="150"  show-overflow-tooltip></el-table-column>
                        <el-table-column prop="mobile" label="手机号码" width="150"></el-table-column>
                        <el-table-column prop="email" label="电子邮箱" width="150"></el-table-column>
                        <el-table-column label="状态" align="center" width="100">
                            <template slot-scope="scope">
                                <div class="message-box" :style="{'background':scope.row.freeze=='否'?'#F0FAF5':'#FAF0F1','color':scope.row.freeze=='否'?'#10C257':'#F74B4B'}">
                                    <span class="circle" :style="{'background':scope.row.freeze=='否'?'#10C257':'#F74B4B'}"></span>
                                    <span v-if="scope.row.freeze=='否'">启用</span>
                                    <span v-else>禁用</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="selectbox">
                    <div class="top">
                        <div class="title">已选关联用户</div>
                        <div class="total">共计<span>{{ userList.length }}</span>人</div>
                    </div>
                    <div class="box">
                        <!-- <el-empty v-if="userList.length==0" description="暂无关联用户，请从左侧列表选择"
                        :image="image"></el-empty> -->
                        <div class="none" v-if="userList.length==0">暂无关联用户，请从左侧列表选择</div>
                        <div class="little-box hover handel" v-for="(item,index) in userList" :key="index">
                            {{ item.name }}
                            <div class="close hover " @click="delUser(item,index)">
                                <local-svg iconClass="close" class="closeicon icon" ></local-svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer btnpart" style="padding-top: 20px;">
                <div class="page">
                    <el-pagination
                        layout="total,prev, pager, next"
                        :total="total" :current-page="currentPage" :page-size="pageSize" @current-change="handleCurrentChange">
                    </el-pagination>
                </div>
                <div class="btnpart">
                    <el-button class="confirm" type="primary" @click="bindOk" plain>确 定</el-button>
                    <el-button class="cancel" @click="cancel" plain>取 消</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {getuser,rolemenber} from '@/api/terrace'
export default {
    name: "binduser",
    props: {
        id:{
            type:Number,
        	default:()=>{
        		return 0
        	} 
        },
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        selectlist:{
            type:Array,
        	default:()=>{
        		return []
        	}
        },
        bindlist:{
            type:Array,
        	default:()=>{
        		return []
        	}
        }
    },
    data() {
        return {
            image:require('../../../assets/image/none.png'),
            keword:'',
            type:'',
            showDialog:false,
            total:0,
            pageSize:10,
            currentPage:1,
            userList:[],
            list:[],
            first:true,
            loading:true,
            selectType:'',
            selectName:'',
            multipleSelection:[],
            selectList:[
                {id:'',name:'全部'},
                {id:1,name:'姓名'},
                {id:2,name:'手机号'},
                {id:3,name:'邮箱'},
            ]
        };
    },
    mounted(){
        this.showDialog = this.show
        this.getCheck()
        this.getList()
    },
    methods: {
        selectAll(val){
            if(val.length>=10){
                for(var a = 0;a<val.length;a++){
                    if(this.userList.some(item => item.id === val[a].id )){
                    }else{
                        this.userList.push(val[a])
                    }
                }
            }else{
                for(var a= 0;a<this.list.length;a++){
                    for(var b = 0;b<this.userList.length;b++){
                        if(this.list[a].id == this.userList[b].id){
                            this.userList.splice(b,1)
                            break
                        }
                    }
                }
            }
        },
        handleSelectionChange(val,row){
            this.multipleSelection = val
            console.log(1)
            if(!this.userList.some(item => item.id === row.id )){
                console.log(1)
                this.userList.push(row)
            }else{
                console.log(1)
                for(var a = 0;a<this.userList.length;a++){
                    if(this.userList[a].id == row.id){
                        this.userList.splice(a,1)
                    }
                }
            }
        },
        delUser(item,index){
            let havedata = false
            havedata = this.list.some(item1 => item1.id === item.id )
            if(havedata){
                for(var a=0;a<this.list.length;a++){
                    if(item.id == this.list[a].id){
                        this.$refs.multipleTable.toggleRowSelection(this.list[a], false)
                    }
                }
            }else{
                this.deleteList.push(item.id)
                console.log(this.deleteList)
            }
            this.userList.splice(index,1)
        },
        setBox(rows){
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row, true)
                })
            } else {
                this.$refs.multipleTable.clearSelection()
            }
        },
        getCheck(){
            let data = {
                page:1,
                count:999,
                r_id:this.id
            }
            rolemenber(data).then(res =>{
                this.userList = res.data.table
            })
        },
        getList(e){
            if(e){
                this.currentPage = 1
            }
            this.loading = true
            let data = {
                page:this.currentPage,
                count:this.pageSize,
                c_id:this.selectType,
                search:this.selectName,
            }
            getuser(data).then(res =>{
                this.list = res.data.table
                this.total = res.data.total
                for(var a = 0;a<this.userList.length;a++){
                    let item = this.userList[a]
                    for(var b = 0;b<this.list.length;b++){
                        let tableitem = this.list[b]
                        if(item.id == tableitem.id){
                            this.$refs.multipleTable.toggleRowSelection(tableitem, true)
                            item = tableitem
                        }
                    }
                }
                setTimeout(()=>{
                    this.loading = false
                },1000)
                // this.list = res.data.table
                // let item = this.list
                // let partarray = ''
                // let rolearray = ''
                // for(var a=0;a<item.length;a++){
                //     if(item[a].branch){
                //         for(var b=0;b<item[a].branch.length;b++){
                //             if(b==item[a].branch.length-1){
                //                 partarray = partarray + item[a].branch[b]
                //             }else{
                //                 partarray = partarray + item[a].branch[b]+'/'
                //             }
                //         }
                //     }
                //     if(item[a].role){
                //         for(var b=0;b<item[a].role.length;b++){
                //             if(b==item[a].role.length-1){
                //                 rolearray = rolearray + item[a].role[b]
                //             }else{
                //                 rolearray = rolearray + item[a].role[b]+'/'
                //             }
                //         }
                //     }
                //     item[a].partarray = partarray
                //     item[a].rolearray = rolearray
                //     partarray = ''
                //     rolearray = ''
                // }
                // this.total = res.data.total
                // let rows = []
                // this.userList.forEach(item => {
                //     this.list.forEach(item2 => {
                //         if (item.id === item2.id) {
                //             rows.push(item)
                //         }
                //     })
                // })
                // this.setBox(rows)
                // if(this.first){
                //     this.list = []
                //     this.handleCurrentChange(this.currentPage)
                //     this.list = []
                //     this.handleCurrentChange(this.currentPage)
                //     setTimeout(()=>{
                //         this.loading = false
                //     },1000)
                //     this.first = false
                // }
            })
        },
        selectRow(all,row){
            let ishave = false
            for(var a = 0;a<this.userList.length;a++){
                if(this.userList[a].id){
                    if(row.id == this.userList[a].id){
                        this.userList.splice(a,1)
                        ishave = true
                    }
                }
            }
            if(!ishave){
                this.userList.push(row)
            }
        },
        bindOk(){
            let list = []
            this.userList.forEach(item=>{
                list.push(item.id)
            })
            this.$emit('bind',list)
            // this.clean()
        },
        cancel(){
            this.$emit('close',false)
            // this.clean()
        },
        clean(){
            this.keword=''
            this.userList=[]
        },
        handleCurrentChange(val){
            this.currentPage = val
            this.getList()
        }
    }
}
</script>
<style lang="scss" scoped>
*{
    box-sizing: border-box;
}
</style>

<style lang="scss">
.binduser {
    .header{
        display: flex;
        button{
            margin-left: 36px;
        }
        // .el-input__inner{
        //     height: 32px !important;
        //     z-index: 99;
        // }
        // .el-input__prefix {
        //     height: 100%;
        //     left: 0;
        //     .el-input__inner{
        //         width: 107px;
        //         z-index: 10;
        //     }
        // }
        // .el-input__suffix{
        //     display: flex;
        //     align-items: center;
        // }
    }
    .body{
        display: flex;
        height: 375px;
        width: 100%;
        margin-top: 20px;
        justify-content: space-between;
        .table{
            width: 490px;
            .message-box{
                width: 82px;
                height: 24px;
                border-radius: 4px 4px 4px 4px;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                .circle{
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    margin-right: 8px;
                }
            }
        }
        .selectbox{
            width: 242px;
            height: 357px;
            .top{
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                padding: 4px 8px;
                .title{
                    line-height: 14px;
                    color: #474952;
                }
                .total{
                    line-height: 14px;
                    color: #B0B1B9;
                    span{
                        color: #3962FF;
                        padding: 0 3px;
                    }
                }
            }
            .box{
                width: 100%;
                height: 342px;
                border: 1px solid #E2E5EA;
                border-radius: 4px;
                padding: 4px 16px 16px 8px;
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
                overflow-y: auto;
                .little-box{
                    padding: 2px 8px 3px;
                    background: #F1F6FC;    
                    width: auto;
                    height: 20px;
                    font-size: 12px;
                    margin-left: 8px;
                    margin-top: 12px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    .close{
                       width: 14px;
                       height: 14px;
                       border-radius: 50%;
                       background: rgba(63,69,124,0.1);
                       display: flex;
                       justify-content: center;
                       align-items: center;
                       margin-left: 4px;
                    }
                }
            }
        }
    }
    .dialog-footer{
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
    }
}
</style>
