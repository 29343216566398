import request from '@/utils/request'
// 销售机会列表
export function sellList(data) {
    return request({
        url: '/sell/index',
        method: 'get',
        params: data,
        timeout: 100000

    })
}
// 销售机会添加
export function sellAdd(data) {
    return request({
        url: '/sell/save',
        method: 'post',
        data
    })
}
// 设置展示字段
export function sellHead(data) {
    return request({
        url: '/common/field',
        method: 'get',
        params:data
    })
}
// 销售机会查看
export function sellLook(data) {
    return request({
        url: '/sell/detail',
        method: 'get',
        params: data
    })
}
// 获取新建机会下拉
export function sellAddList() {
    return request({
        url: '/sell/save',
        method: 'get'
    })
}
// 销售机会删除
export function selldelete(id) {
    return request({
        url: '/sell/delete',
        method: 'delete',
        params: {
            id
        }
    })
}
// 销售机会批量删除
export function selldesome(data) {
    return request({
        url: '/sell/del',
        method: 'delete',
        params: {
            b_id: data
        }
    })
}
//导出文件
export function exportfile(data) {
    return request({
        url: '/sell/export',
        method: 'post',
        data
    })
}
// 编辑销售机会
export function selledit(data) {
    return request({
        url: '/sell/edit',
        method: 'post',
        data
    })
}
// 完善销售机会
export function perfectsell(data) {
    return request({
        url: '/sell/perfect',
        method: 'post',
        data
    })
}
