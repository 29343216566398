import request from '@/utils/request'
export function Login(data) {
    return request({
        url: '/login',
        method: 'post',
        data
    })
}
// 重置密码
export function reset(data) {
    return request({
        url: '/reset',
        method: 'post',
        data
    })
}
// 获取验证码
export function code(phone) {
    return request({
        url: '/code',
        method: 'post',
        data: {
            mobile: phone
        }
    })
}