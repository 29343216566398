<template>
    <div class="contractdetail">
        <div class="banner-border"  v-if="!isDetail">
            <div class="top-banner">
                <div class="background"
                    :style="{ 'background': 'url(' + image + ') no-repeat', 'backgroundSize': '1012px 100%', 'backgroundPosition': 'right', 'opacity': '0.9' }">
                    <div class="banner-left">
                        <div class="title">开票申请单号：{{ info.number }}
                            <div class="tip status1" v-if="info.audit_status==17"><local-svg iconClass="status1" ></local-svg>未提审</div>
                            <div class="tip status2" v-if="info.audit_status==18"><local-svg iconClass="status2"></local-svg>审核中</div>
                            <div class="tip status3" v-if="info.audit_status==19"><local-svg iconClass="status3"></local-svg>已开票</div>
                            <div class="tip status4" v-if="info.audit_status==20"><local-svg iconClass="status4"></local-svg>审核拒绝</div>
                        </div>
                        <div class="message">
                            <div class="line" :class="{ 'active': showWidth < 1350 }">客户名称：<span>{{ info.client }}</span></div>
                            <div class="line" :class="{ 'active': showWidth < 1350 }">开票申请人：<span>{{ info.applicant }}</span></div>
                        </div>
                    </div>
                    <div class="banner-right">
                        <button class="print hover" @click="toPrint">
                            <local-svg iconClass="print"></local-svg>
                            <span>打印</span>
                        </button>
                        <button class="btn hover" @click="goBack">
                            <local-svg iconClass="back"></local-svg>
                            <span>返回上一页</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="examine-banner">
            <div class="examine-header">
                <div class="header-left">
                    <local-svg icon-class="back" class="icon handel hover" @click.native="backList"></local-svg>
                    <div class="title">发票资料：{{ info.number }}</div>
                </div>
                <div class="header-right btnpart" v-if="isCheck">
                    <el-button class="confirm" plain @click="agreeCheck" v-if="info.audit_status==18" v-permission="'开票管理-agree'">审核同意</el-button>
                    <el-button class="cancel" plain @click="refuseCheck" v-if="info.audit_status==18" v-permission="'开票管理-agree'">审核拒绝</el-button>
                </div>
            </div>
        </div>    
        <div class="main">
            <div class="descript" :class="{'auto-detail-part':!isDetail,'auto-detail-examine-part':isDetail}">
                <div class="descript-main">
                    <el-descriptions :column="2" border v-if="actTab==0" title="开票详情">
                        <el-descriptions-item v-if="isDetail" label="客户名称">{{ info.client ? info.client : '/' }}</el-descriptions-item>
                        <el-descriptions-item v-if="isDetail" label="开票申请人">{{ info.applicant ? info.applicant : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="开票形式">{{ info.way_id ? info.way_id : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="开票公司">{{ info.company_id ? info.company_id : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="发票类型">{{ info.type_id ? info.type_id : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="纳税人识别号">{{ info.identifier ? info.identifier : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="是否回款" >{{ info.payback_id ? info.payback_id : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="购买方名称" >{{ info.purchaser_name ? info.purchaser_name : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="OA回款单号" >{{ info.back_number ? info.back_number : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="来源" v-if="info.way == 1">{{ info.source ? info.source : '无'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="渠道名称" v-if="info.way == 1">{{ info.channel ? info.channel : '无'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="购买方联系方式">{{ info.purchaser_phone ? info.purchaser_phone : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="开户行账号">{{ info.account ? info.account : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="购买方地址">{{ info.purchaser_address ? info.purchaser_address : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="所属部门">{{ info.branch ? info.branch : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="项目名称">{{ info.project  ? info.project : '/' }}</el-descriptions-item>
                        <el-descriptions-item v-if="info.way == 2" label="房屋合同单号">{{ info.house_number  ? info.house_number : '/' }}</el-descriptions-item>
                        <el-descriptions-item v-if="info.way == 2" label="房屋租赁费(元)">{{ info.rent  ? info.rent : '/' }}</el-descriptions-item>
                        <el-descriptions-item v-if="info.way == 2" label="房屋租赁面积(m²)">{{ info.area  ? info.area : '/' }}</el-descriptions-item>
                        <el-descriptions-item v-if="info.way == 2" label="租赁起止时间">{{ info.start_time  ? info.start_time : '/' }}{{ info.start_time  ? '-'+info.start_time :"/"}}</el-descriptions-item>
                        <el-descriptions-item label="税率(%)">{{ info.rate_id  ? info.rate_id : '/' }}</el-descriptions-item>
                        <el-descriptions-item v-if="info.way == 2" label="租赁地址">{{ info.address  ? info.address : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="含税金额(元)">{{ info.amount_tax  ? Number(info.amount_tax).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }): '/' }}</el-descriptions-item>
                        <el-descriptions-item label="规格型号" v-if="info.way == 1">{{ info.models ? info.models :'/' }}</el-descriptions-item>
                        <el-descriptions-item label="单位" v-if="info.way == 1">{{ info.unit ? info.unit :'/' }}</el-descriptions-item>
                        <el-descriptions-item label="数量" v-if="info.way == 1">{{ info.quantity ? info.quantity :'/' }}</el-descriptions-item>
                        <el-descriptions-item label="不含税金额">{{ info.no_tax_amount ? info.no_tax_amount :'/' }}</el-descriptions-item>
                        <el-descriptions-item label="单价">{{ info.unit_price ? info.unit_price :'/' }}</el-descriptions-item>
                        <el-descriptions-item label="备注">{{ info.remark  ? info.remark : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="审批意见" :span="2">{{ info.audit_status==20 && info.audit_reject ? info.audit_reject : '/'
                        }}</el-descriptions-item>
                    </el-descriptions>
                    <div class="table" v-else>
                        <div class="table-title">
                            <span class="title">单向明细列表</span>
                        </div>
                        <div class="table">
                            <el-table
                                :data="info.details"
                                height="300px"
                                style="width: 100%"
                                :header-cell-style="{'background':'#F4F7FC','color':'#B0B1B9','fontWeight':'bold'}">
                                <template slot="empty">
                                    <el-empty :image-size="160" :image="image1" description='暂无明细'></el-empty>
                                </template>
                                <!-- <el-table-column type="index" label="序号" width="100"  align="center"></el-table-column> -->
                                <el-table-column label="合同类型">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.type==1?'房屋合同':'其他合同' }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="合同单号" prop="contract_num"></el-table-column>
                                <el-table-column label="合同回款" prop="back_num"></el-table-column>
                                <el-table-column label="业务类型" prop="business_type"></el-table-column>
                                <el-table-column label="业务明细" prop="business_detail"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
                <div class="examinebtn">
                    <div class="header-right btnpart" v-if="isCheck&&info.handle&&!isDetail">
                        <el-button class="confirm" plain @click="agreeCheck" v-if="info.audit_status==18" v-permission="'开票管理-agree'">审核同意</el-button>
                        <el-button class="cancel" plain @click="refuseCheck" v-if="info.audit_status==18" v-permission="'开票管理-agree'">审核拒绝</el-button>
                    </div>
                </div>
            </div>
            <div class="detail-right">
                <div class="timetree">
                    <div class="timetitle">
                        <local-svg iconClass="cortanctdetail" class="icon"></local-svg>开票审批进度
                    </div>
                    <div class="tree">
                        <el-timeline>
                            <el-timeline-item
                            v-for="(item, index) in info.audit_process" :key="index" >
                                <div class="tree-info" :class="{'tree5':item.audit_status==18&&item.create_time,'tree1':item.audit_status==19,'tree3':item.audit_status==20,'tree4':item.audit_status==18&&item.audit=='审核中'}">
                                    <div class="timeline">
                                        <div class="date">{{ item.create_time?item.create_time:'-' }}</div>
                                        <div class="status">{{ item.audit }}</div>
                                    </div>
                                    <div class="checkuser">{{ item.name }}</div>
                                    <div class="reason" v-if="item.audit_reject">
                                        <span class="reason-title">拒绝原因：</span>
                                        {{ item.audit_reject }}
                                    </div>
                                </div>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                </div>
                <div class="user">
                    <div class="table-title">
                        <span class="title">
                            <local-svg iconClass="detailuser" class="icon"></local-svg>跟进人</span>
                        <div class="addpart handel hover" @click="addUser">
                            <local-svg iconClass="add" class="icon"></local-svg>
                            添加
                        </div>
                    </div>
                    <div class="userlist">
                        <el-empty :image-size="100" :image="image2" v-if="info.follower.length==0" description="暂无跟进人，点击添加按钮操作"></el-empty>
                        <div class="fileline hover" v-for="(item,index) in info.follower" :key="index">
                            <div>
                                <span class="name">{{ item.name }}</span>
                            </div>
                            <div class="closepart">
                                <span>{{ item.type }}</span>
                                <div class="close-box" @click="deleteFollow(item,index)">
                                    <div class="close">
                                        <local-svg iconClass="close" class="closeicon"></local-svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <refuseExamine v-if="refuseDialog" :show="refuseDialog" @close="cancelRefuse" @examine="confirmRefuse"></refuseExamine>
    </div>
</template>

<script>
import {addfollow,delfollow,billexamine,opendetail,bankchannel } from '@/api/bill'
import follow from '@/assets/js/addfollow'
import closefollow from '@/assets/js/closefollow'
import applyAgree from '@/components/examine/applyagree.vue'
import refuseExamine from '@/components/examine/refuse.vue'
export default {
    components: {
        refuseExamine,
        applyAgree
    },
    name: "detail",
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        info: {
            type: Object,
            default: () => {
                return {}
            }
        },
        follow: {
            type: Array,
            default: () => {
                return []
            }
        },
        isDetail: {
            type: Boolean,
            default: () => {
                return false
            }
        }
    },
    data() {
        return {
            isCheck:true,
            financeDialog:false,
            refuseDialog:false,
            fileDialog: true,
            activities: [{
                date: '2023-03-08  14:45',
                status: 1,
                user: '审批人'
            }, {
                date: '2023-03-08  14:45',
                status: 2,
                user: '审批人'
            }, {
                date: '2023-03-08  14:45',
                status: 3,
                user: '审批人'
            }, {
                date: '2023-03-08  14:45',
                status: 4,
                user: '审批人',
                reason: '合同金额与纸质合同金额不一致，这是一个文字较多的字段，这是一个文字较多的字段。'
            },],
            image1: require('../../assets/image/none.png'),
            image2: require('../../assets/image/noneuser.png'),
            tableData: [],
            tradeList: [],
            tabList: [
                { name: '详情资料' },
                { name: '合同单项' }
            ],
            fileList: [],
            actTab: 0,
            image: require('../../assets/image/detail.png'),
            ischange: false,
            showWidth: 1920,
            backfollowListener:null
        };
    },
    mounted() {
        console.log(this.info)
        let that = this
        window.onresize = () => {
            return (() => {
                that.showWidth = document.body.clientWidth
            })()
        },
        console.log(1)
        this.getFollow()
        this.checkBack()
    },
    methods: {
        checkBack(){
            if(this.info.way == 1){
                let obj = {
                    pay_back_id:this.info.back_number_id.toString()
                }
                bankchannel(obj).then(res =>{
                    this.$set(this.info,'channel',res.data.channel.length>0 ? res.data.channel.join('、') : '无')
                    this.$set(this.info,'source',res.data.source.length>0 ? res.data.source.join('、') : '无')
                }) 
            }
        },
        toPrint(){
            this.$emit('print',this.info)
        },
        backList(){
            this.$router.push({path:'/todolist'})
        },
        cancelRefuse(){
            this.refuseDialog = false
        },
        confirmRefuse(msg){
            let data = {
                id:this.info.id,
                audit_status:20,
                refuse_reason:msg.reason
            }
            this.$loading({ text: '加载中...' });
            billexamine(data).then(res=>{
                if(res.code == 200){
                    this.seeDetail(this.info.id)
                    this.refuseDialog = false
                    this.isCheck = false
                    this.$message({
                        message:res.msg,
                        type:'success'
                    })
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        seeDetail(msg) {
            let data = {
                id: msg
            }
            opendetail(data).then(res => {
                this.info = res.data
            })
        },
        agreeCheck(){
            let data = {
                id:this.info.id,
                audit_status:19,
                refuse_reason:''
            }
            this.$loading({ text: '加载中...' });
            billexamine(data).then(res=>{
                if(res.code == 200){
                    this.seeDetail(this.info.id)
                    this.isCheck = false
                    this.$message({
                        message:res.msg,
                        type:'success'
                    })
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        refuseCheck(){
            this.refuseDialog = true
        },
        deleteFollow(item, index) {
            this.$confirm('此操作将删除此跟进人, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: Number(item.id)
                }
                delfollow(data).then(res => {
                    if (res.code == 200) {
                        this.info.follower.splice(index, 1)
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        getFollow() {
            this.backfollowListener = value => {
                let data = {
                    id: this.info.id,
                    follower: value.id
                }
                addfollow(data).then(res => {
                    if (res.code == 200) {
                        closefollow.$emit("closefollow", true)
                        this.info.follower.push(value)
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                })

            }
            follow.$on('addfollow', this.backfollowListener);
        },
        handleClose() { },
        addUser() {
            this.$emit('add', true)
        },
        addDetail() { },
        changeTab(index) {
            this.actTab = index
        },
        goBack() {
            if (this.$route.query.id) {
                // console.log(1111);
                this.$router.go(-1)
            } else {
                this.$emit('back', false)
            }
        }
    },
    beforeDestroy(){
        follow.$off('addfollow', this.backfollowListener);
    },
}
</script>

<style lang="scss">
.contractdetail {
    min-height: calc(100vh - 108px);
    .filedialog {
        width: 800px !important;
        height: 500px !important;
    }
    .top-banner {
        width: 100%;
        height: 112px;
        border-radius: 4px;
        background: linear-gradient(90deg, #FFF 0%, #F0F4FE 50%, #F0F4FE 100%);
        display: flex;
        justify-content: flex-end;

        .background {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 26px 30px;
            display: flex;
            justify-content: space-between;

            .banner-left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .title {
                    font-size: 22px;
                    font-weight: bold;
                    color: #474952;
                    // max-width: 570px;
                    width: auto;
                    display: flex;
                    align-items: center;

                    .tip {
                        width: auto;
                        height: 24px;
                        line-height: 24px;
                        padding: 0 10px;
                        border-radius: 12px 2px 12px 2px;
                        font-size: 14px;
                        margin-left: 10px;
                    }

                    .status1 {
                        color: #8C8EA6;
                        background: #DADEEC;
                    }

                    .status2 {
                        color: #2C57FF;
                        background: #D4DEFF;
                    }

                    .status3 {
                        color: #08B54D;
                        background: #C9EFDD;
                    }

                    .status4 {
                        color: #F74B4B;
                        background: #FFE3E3;
                    }

                    .status5 {
                        color: #DE8500;
                        background: #FFEECD;
                    }
                }

                .message {
                    display: flex;

                    .line {
                        font-size: 14px;
                        color: #C4C5CB;
                        margin-right: 20px;

                        span {
                            color: #7D7F88;
                        }
                    }

                    .active {
                        color: #8999B1;
                    }
                }
            }

            .banner-right {
                display: flex;
                align-items: center;

                .btn {
                    width: 128px;
                    height: 32px;
                    box-shadow: 0px 2px 4px 0px rgba(55, 59, 92, 0.1);
                    background: linear-gradient(180deg, #F8F8FB 0%, #ECEFF6 100%);
                    border-radius: 4px 4px 4px 4px;
                    outline: none;
                    border: none;
                    font-size: 14px;
                    color: #6B6D77;

                    span {
                        margin-left: 8px;
                    }
                }

                .btn:hover {
                    background: linear-gradient(180deg, #ECEFF6 0%, #F8F8FB 100%);
                }
                .print {
                    width: 84px;
                    height: 32px;
                    // box-shadow: 0px 2px 4px 0px rgba(55, 59, 92, 0.1);
                    background: linear-gradient(180deg, #355EFF 0%, #1744F2 100%);
                    border-radius: 4px 4px 4px 4px;
                    outline: none;
                    border: none;
                    font-size: 14px;
                    color: white;
                    margin-right: 10px;
                    span {
                        margin-left: 8px;
                    }
                }
                
                .print:hover {
                    background: linear-gradient(180deg, #1744F2 0%, #355EFF 100%);
                }
            }
        }
    }

    .main {
        width: 100%;
        height: 700px;
        // background: white;
        margin-top: 10px;
        display: flex;

        .title {
            font-weight: bold;
            font-size: 14px;
        }

        .descript {
            width: calc(100% - 292px);
            height: 700px;
            background-color: white;
            border-radius: 4px;

            .header {
                height: 55px;
                border-bottom: 1px solid #F0F1F5;
                display: flex;
                padding: 24px 0 0 30px;
                box-sizing: border-box;
                font-size: 14px;

                .tab {
                    width: auto;
                    line-height: 16px;
                    margin-right: 40px;
                    color: #B0B1B9;
                }

                .tab:hover {
                    color: #2C57FF;
                }

                .acttab {
                    border-bottom: 2px solid #2C57FF;
                    font-weight: bold;
                    color: #474952 !important;
                }
            }

            .descript-main {
                padding: 28px 20px;
                box-sizing: border-box;


                .filelist {
                    width: 100%;
                    height: 120px;
                    overflow-y: auto;
                    padding-right: 8px;
                    box-sizing: border-box;

                    .fileline {
                        display: flex;
                        height: 28px;
                        border-radius: 4px;
                        background: #F4F7FC;
                        width: 100%;
                        align-items: center;
                        margin-bottom: 6px;
                        padding: 0 12px 0 8px;
                        justify-content: space-between;
                        box-sizing: border-box;

                        .icon {
                            margin-right: 7px;
                            width: 12px;
                            height: 14px;
                        }

                        .name {
                            font-size: 12px;
                            color: #474952;
                        }

                        .close {
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            background: rgba(63, 69, 124, 0.1);
                            display: none;
                            align-items: center;
                            justify-content: center;

                            .closeicon {
                                width: 8px;
                                height: 8px;
                                fill: #B0B1B9;
                            }
                        }

                        .close:hover {
                            background: rgba(44, 87, 255, 0.2) !important;

                            .closeicon {
                                fill: #2C57FF !important;
                            }
                        }
                    }

                    .fileline:hover {
                        background: #EDF3FE !important;

                        .close {
                            display: flex;
                        }
                    }
                }

                .table {
                    .table-title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 14px;

                        .title {
                            font-weight: bold;
                            color: #6B6D77;
                            margin-bottom: 14px;
                        }

                        .addpart {
                            .icon {
                                fill: #475B93 !important;
                            }
                        }

                        .addpart:hover {
                            color: #2C57FF !important;

                            .svg-icon {
                                fill: #2C57FF !important;
                            }
                        }

                        .addpart:active {
                            color: #133CDC !important;

                            .svg-icon {
                                fill: #133CDC !important;
                            }
                        }
                    }
                }
            }
        }

        .detail-right {
            margin-left: 10px;
            width: 282px;

            .timetree {
                width: 100%;
                border-radius: 4px;
                background: white;
                padding: 24px 20px;
                box-sizing: border-box;
                .timetitle {
                    padding: 0 7px;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 16px;

                    .icon {
                        margin-right: 5px;
                    }
                }

                .tree {
                    margin-top: 20px;
                    width: 100%;
                    max-height: 360px !important;
                    padding: 0 4px;
                    box-sizing: border-box;

                    .el-timeline-item__node--normal {
                        width: 14px !important;
                        height: 14px !important;
                        left: -3px;
                    }

                    .el-timeline-item__node {
                        background: #FFF !important;
                        border: 1px solid #E2E5EA !important;
                    }

                    .el-timeline-item__wrapper {
                        top: 0 !important;
                        padding-left: 21px !important;
                    }

                    .el-timeline-item {
                        position: relative;
                        padding-bottom: 1px;
                    }

                    .el-timeline-item__tail {
                        border-left: 1px solid #E4E7ED !important;
                    }

                    .tree-info {
                        min-height: 64px;
                        width: 100%;
                        border-radius: 4px;
                        background: #E9EAEE;
                        padding: 12px 14px 14px;
                        box-sizing: border-box;

                        .timeline {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 12px;
                            line-height: 14px;

                            .date {
                                color: #B0B1B9;
                            }

                            .status {
                                color: #B0B1B9;
                            }
                        }

                        .checkuser {
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: bold;
                            color: #6B6D77;
                            margin-top: 6px;
                        }

                        .reason {
                            color: #6B6D77;
                            line-height: 20px;
                            font-size: 12px;
                            margin-top: 6px;

                            .reason-title {
                                color: #B0B1B9;
                            }
                        }
                    }

                    .tree1 {
                        background: #F0FAF1 !important;

                        .status {
                            color: #10C257 !important;
                        }
                    }

                    .tree2 {
                        background: #FAF5E6 !important;

                        .status {
                            color: #DE8500 !important;
                        }
                    }

                    .tree3{
                        background: #FFF4F4 !important;

                        .status {
                            color: #F74B4B !important;
                        }
                    }
                    .tree4{
                        background: #EAF1FB !important;
                        .status{
                            color: #2C57FF !important;
                        } 
                    }
                    .tree5{
                        background: #EAF7FF !important;
                        .status{
                            color: #0C80EB !important;
                        } 
                    }

                }
            }

            .user {
                margin-top: 10px;
                width: 100%;
                height: 246px;
                border-radius: 4px;
                background: white;
                padding: 24px 20px;
                box-sizing: border-box;

                .table-title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 14px;

                    .title {
                        font-weight: bold;
                        color: #474952;

                        .icon {
                            width: 14px;
                            height: 14px;
                            margin-right: 10px;

                            .svg-icon {
                                fill: #2C57FF !important;
                            }
                        }
                    }

                    .addpart {
                        .icon {
                            fill: #475B93 !important;
                        }
                    }

                    .addpart:hover {
                        color: #2C57FF !important;

                        .svg-icon {
                            fill: #2C57FF !important;
                        }
                    }

                    .addpart:active {
                        color: #133CDC !important;

                        .svg-icon {
                            fill: #133CDC !important;
                        }
                    }
                }

                .userlist {
                    margin-top: 16px;
                    height: 172px;
                    overflow-y: auto;
                    padding-right: 8px;
                    box-sizing: border-box;

                    .el-empty {
                        padding: 10px 0 !important;
                    }

                    .fileline {
                        display: flex;
                        height: 28px;
                        border-radius: 4px;
                        background: #F4F7FC;
                        width: 100%;
                        align-items: center;
                        margin-bottom: 6px;
                        padding: 0 12px 0 14px;
                        justify-content: space-between;
                        box-sizing: border-box;

                        .icon {
                            margin-right: 7px;
                            width: 12px;
                            height: 14px;
                        }

                        .name {
                            font-size: 12px;
                            color: #474952;
                        }

                        .close {
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            background: rgba(63, 69, 124, 0.1);
                            display: none;
                            align-items: center;
                            justify-content: center;

                            .closeicon {
                                width: 8px;
                                height: 8px;
                                fill: #B0B1B9;
                            }
                        }

                        .close:hover {
                            background: rgba(44, 87, 255, 0.2) !important;

                            .closeicon {
                                fill: #2C57FF !important;
                            }
                        }
                    }

                    .fileline:hover {
                        background: #EDF3FE !important;

                        .close {
                            display: flex;
                        }
                    }

                    .closepart {
                        display: flex;
                        align-items: center;

                        .close-box {
                            width: 16px;
                        }

                        span {
                            font-size: 14px;
                            line-height: 16px;
                            color: #B0B1B9;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}
</style>
