<template>
    <div class="roleList">
        <div class="head ">
            <div class="title">{{step==1?'功能权限':'数据权限'}}</div>
            <div class="btnpart" v-if="!isChange">
                <el-button v-if="step==1" plain class="cancel" @click="cancelchangeBegin">取消编辑</el-button>
                <el-button v-if="step==1" plain class="confirm" @click="nextStep">下一步</el-button>
                <el-button v-if="step==2" plain class="cancel" @click="lastStep">上一步</el-button>
                <el-button v-if="step==2" plain class="confirm" @click="save">保存</el-button>
            </div>
            <div class="btnpart" v-else>
                <el-button plain class="confirm" @click="changeBegin" v-permission="'权限管理-edit'">编辑</el-button>
            </div>
        </div>
        <div class="table power-table">
            <el-table
                :data="tableData"
                height="calc(100vh - 184px)"
                style="width: 100%"
                :header-cell-style="{'background':'#F4F7FC','color':'#B0B1B9','fontWeight':'bold'}"
                border
                tooltip-effect="dark"
                :span-method="arraySpanMethod" 
                v-if="step==1">
                <el-table-column width="131" label="模块"  align="center" >
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.checkmodel" @change="changeModel(scope.row)" :disabled="isChange">{{scope.row.model}}</el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column width="140" label="页面"  align="center">
                    <template slot-scope="scope">
                        <el-checkbox v-if="scope.row.checkpage" v-model="scope.row.checkpage" :indeterminate="scope.row.checkbtn.length!==scope.row.btn.length" :disabled="!scope.row.checkmodel||isChange" @change="changePage(scope.row)">{{scope.row.page}}</el-checkbox>
                        <el-checkbox v-else v-model="scope.row.checkpage" :disabled="!scope.row.checkmodel||isChange" @change="changePage(scope.row)">{{scope.row.page}}</el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column width="" label="按钮权限"  align="center">
                    <template slot-scope="scope">
                        <div class="checkbox">
                            <el-checkbox-group v-model="scope.row.checkbtn" @change="handleCheckedCitiesChange">
                                <el-checkbox v-for="city in scope.row.btn" :label="city.id" :key="city.id" :disabled="!scope.row.checkpage||isChange">{{city.name}}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="checktype tablepart" v-else>
                <div class="title">限制方式：</div>
                <el-radio-group v-model="numberType" class="top number">
                    <el-radio :label="1" style="margin-right: 20px;">全部</el-radio>
                    <el-radio :label="2" style="margin-right: 2px;">部门职位</el-radio>
                </el-radio-group>
                <el-tooltip content="根据创建人所在部门判断" placement="bottom" effect="light"  popper-class="handlerTab_tooltip">
                    <local-svg iconClass="tips" class="handelicon hover" style="margin-left: 4px;"></local-svg>
                </el-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import {powerlist} from '@/api/terrace'
import follow from '@/assets/js/addfollow'
export default{
    name:'roleList',
    props:{
        table:{
            type:Array,
            default:()=>{
                return []
            }
        },
        numtype:{
            type:Number,
            default:()=>{
                return 1
            }
        }
    },
	data(){
		return{
            tableData:[],
            checkedCities:[],
            step:1,
            numberType:1,
            isChange:true
        }
	},
    mounted(){
        this.tableData = this.table
        this.numberType = this.numtype
        this.getType()
    },
    methods:{
        cancelchangeBegin(){
            this.isChange = true
        },
        changNum(num){
            this.numberType = num
        },
        getType(){
            follow.$on('close',value =>{
                this.step = 1
                this.isChange = value
            })
        },
        changeBegin(){
            this.isChange = false
            powerlist().then(res=>{
                localStorage.setItem('x_token',res.data.token)
            })
        },
        save(){
            let data = {
                numberType:this.numberType,
                data:this.tableData
            }
            this.$emit('save',data)
            this.isChange = true
            this.step = 1
        },
        changePage(val){
            let type = val.checkpage
            if(!type){
                this.$set(val,'checkbtn',[])
            }
            if(val.sort==1&&!type){
                this.$set(val,'checkmodel',false)
            }
            if(val.sort!=1&&type){
                let list = []
                for(var a = 0;a<val.btn.length;a++){
                    list.push(val.btn[a].id)
                }
                this.$set(val,'checkbtn',list)
                console.log(list)
            }
            
        },
        changeModel(val){
            let type = val.checkmodel
            if(val.sort==1&&type){
                this.$set(val,'checkpage',true)
                let list = []
                for(var a = 0;a<val.btn.length;a++){
                    list.push(val.btn[a].id)
                }
                this.$set(val,'checkbtn',list)
            }
            for(var a=0;a<this.tableData.length;a++){
                let item = this.tableData[a]
                if(type){
                    if(item.model == val.model){
                        this.$set(item,'checkmodel',true)
                    }
                }else{
                    if(item.model == val.model){
                        this.$set(item,'checkmodel',false)
                        this.$set(item,'checkpage',false)
                        this.$set(item,'checkbtn',[])
                    }
                }
            }
        },
        nextStep(){
            this.step+= 1
            console.log(this.tableData,1)
        },
        lastStep(){
            this.step-= 1
        },
        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                if(row.sort>0){
                    return {
                        rowspan: row.sort,
                        colspan: 1
                    };
                }else{
                    return {
                        rowspan: 0,
                        colspan: 0
                    };
                }
                
            }
        },
        handleCheckedCitiesChange(val){}
    },
}

</script>

<style lang="scss" scoped>
.roleList{
    width: 100%;
    margin-left: 10px;
    height: calc(100vh - 108px);
    background-color: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 16px 20px;
    .head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 9px;
        .title{
            font-size: 14px;
            font-weight: bold;
            color: #474952;
        }
    }
    .table{
        // height: auto;
        width: 100%;
        box-sizing: border-box;
        border-radius: 4px;
        border-spacing: 0px !important;
        .checkbox{
            text-align: left;
            padding: 0 33px;
            
        }
    }
    .checktype{
        display: flex;
        align-items: center;
        .title{
            font-size: 14px;
            color: #B0B1B9;
            margin-right: 30px;
        }
    }
} 
</style>
  