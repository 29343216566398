<template>
    <div class="refuse finance">
        <el-dialog :title="title" :visible.sync="showDialog" width="400px" class="addform"  @close="cancel">
            <div class="addform" style="padding-bottom:10px">
                <el-form :model="checkform" :rules="rules" ref="checkform">
                    <el-form-item label="实际到账时间" prop="real_time">
                        <span slot="label"><span class="check-form-notice">*</span>实际到账时间</span>
                        <el-date-picker v-model="checkform.real_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="对应到款账户" prop="get_account">
                        <span slot="label"><span class="check-form-notice">*</span>对应到款账户</span>
                        <el-select v-model="checkform.get_account" placeholder="请选择"  filterable>
                            <el-option v-for="item in arriveList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="财务确认意见" class="long-input" prop="reason">
                        <!-- <span slot="label"><span class="check-form-notice">*</span>财务确认意见</span> -->
                        <el-input v-model="checkform.finance_opinion" type="textarea"  resize="none" placeholder="请输入文字"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            
            <div slot="footer" class="dialog-footer btnpart">
                <el-button class="confirm" type="primary" @click="refuseOk('checkform')">提 交</el-button>
                <el-button class="cancel" @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {selectlist} from '@/api/contanctback'
export default {
    name: "adduser",
    props: {
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
    },
    mounted(){
        this.showDialog = this.show
        this.getSelectList()
    },
    data() {
        return {
            arriveList:[],
            checkform:{
                reason:'',
                get_account:'',
                real_time:''
            },
            showDialog:false,
            title:'财务审批',
            rules: {
                // real_time: [
                //     { required: true, message: '请选择实际到账时间', trigger: 'change' },
                // ],
                // get_account: [
                //     { required: true, message: '请选择到款账户', trigger: 'change' },
                // ],
            },
        };
    },
    methods: {
        getSelectList(){
            let data = {
                id:''
            }
            selectlist(data).then(res=>{
                this.arriveList = res.data.get_account
            })
        },
        cancel(){
            this.$emit('close',false)
            this.clean()
        },
        refuseOk(checkform){
            this.$refs[checkform].validate((valid) => {
                if (valid) {
                    this.$emit('examine',this.checkform)
                    this.clean()
                } else {
                    return false;
                }
            });
            
        },
        clean(){
        },
    }
}
</script>

<style lang="scss">
.refuse{
    .long-input{
        min-height: 100px !important;
        .el-textarea__inner{
            min-height: 100px !important;
            font-size: 14px;
            padding: 10 14px !important;
            font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
        }
        .el-input__suffix {
            right: 8px;
            transition: all .3s;
            pointer-events: none;
        } 
        .el-form-item__error{
            top: 103px !important;
        }
    }
    
}
.finance{
    .el-form-item__label{
        min-width: 104px !important;
    }
}
</style>
