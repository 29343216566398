<template>
    <div class="adduser">
        <el-dialog :title="title" :visible.sync="showDialog" width="400px" class="addform"  @close="cancel" style="margin-top: 15vh;">
            <div class="addform">
                <el-form :model="form" :rules="rules" ref="form">
                    <el-form-item label="" prop="">
                        <span slot="label"><span class="check-form-notice">*</span>机会名称</span>
                        <el-input v-model="sellName" placeholder="" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="">
                        <span slot="label"><span class="check-form-notice">*</span>房号信息</span>
                        <el-input v-model="form.house" placeholder="请填写房号信息"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer btnpart btnpart" style="margin-top:14px">
                <el-button class="confirm" type="primary" @click="confirm('form')" plain>确定</el-button>
                <el-button class="cancel" @click="cancel" plain>取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "adduser",
    props: {
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        sellName:{
            type:String,
        	default:()=>{
        		return ''
        	}
        }
    },
    data() {
        return {
            list:[],
            showDialog:false,
            title:'完善房号',
            form:{
                sell_name:'',
                house:''
            },
            rules: {},
            userData:{}
        };
    },
    mounted(){
        this.showDialog = this.show
    },
    methods: {
        cancel(){
            this.$emit('close',false)
            console.log(1)
        },
        partOk(form){
        },
        confirm(){
            this.$emit("perfecthouse",this.form)
        },
        getDetail(){
           
        },
    }
}
</script>

<style lang="scss">
.adduser {
    .el-textarea{
        margin-top: 5px !important;
    }
}
</style>
