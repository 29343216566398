<template>
    <div class="detail">
        <div class="header">
            <local-svg iconClass="back" class="hover icon handel" @click.native="backList"></local-svg>
            <span>公告详情</span>
        </div>
        <div class="detail-main">
            <div class="report">
                <div class="title">{{ detail.title }}</div>
                <div class="msg">
                    <div class="date">发布时间：{{ detail.date }}</div>
                    <div class="date">创建人：{{ detail.create_id }}</div>
                </div>
                <div class="content" v-html="detail.content">
                </div>
            </div>
            <div class="user">
                <div class="title">
                    <local-svg iconClass="user"></local-svg>
                    <span>查看人员</span>
                </div>
                <div class="scroll-part">
                    <div class="readpart">
                        <div class="msg">未读：</div>
                        <div class="userlist">
                            <div v-for="(item,index) in unreadList" :key="index" class="list">
                                <img :src="item.image" alt="">
                                <el-tooltip placement="top" :content="item.name">
                                    <div class="user-name">{{ item.name }}</div>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="readpart">
                        <div class="msg">已读：</div>
                        <div class="userlist">
                            <div v-for="(item,index) in readList" :key="index" class="list">
                                <img :src="item.image" alt="">
                                <el-tooltip placement="top" :content="item.name">
                                    <div class="user-name">{{ item.name }}</div>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import { readdetail } from '@/api/message'
export default{
    name:'home',
    props:{
        detail:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
	data(){
		return{
            info:{
                title:'关于2023年部分节假日安排的通知',
                date:'2023-05-09 10:53:00',
                user:'张美美',
                content:'<p>111</p><h1>11</h1>'
            },
            userList:[],
            readList:[],
            unreadList:[],
        }
	},
    mounted(){
        this.getReadList()
    },
    methods:{
        getReadList(){
            let data = {
                id:this.detail.id
            }
            readdetail(data).then(res=>{
                this.readList = res.data.read
                this.unreadList = res.data.unread
            })
        },
        backList(){
            console.log(1)
            this.$emit('close',false)
            this.$router.push({
                path:'notice'
            })
        },
    },
}

</script>

<style lang="scss" scoped>
*{
    box-sizing: border-box;
}
.detail{
    height:calc(100vh - 64px);
    padding-top: 4px;
    .header{
        padding: 0 30px;
        width: 100%;
        height: 72px;
        background-color: white;
        display: flex;
        align-items: center;
        .icon{
            width: 25px;
            height: 20px;
        }
        span{
            margin-left: 30px;
            font-size: 20px;
            font-weight: bold;
        }
    }
    .detail-main{
        display: flex;
        justify-content: center;
        padding: 10px 0 0 24px;
        height: calc(100% - 108px);
        align-items: flex-start;
        .report{
            width: 720px;
            height: 100%;
            background-color: #fff;
            border-radius: 4px;
            padding: 34px 50px;
            text-align: center;
            .title{
                color:#474952;
                font-size: 24px;
                font-weight: bold;
            }
            .msg{
                margin-top: 44px;
                display: flex;
                justify-content: space-between;
                color: #96A5BA;
                font-size: 14px;
            }
            .content{
                margin-top: 46px;
                max-height: calc(100% - 142px);
                height: auto;
                width: 100%;
                overflow: hidden;
                overflow-y: scroll;
                text-align: left;
                table {
                    border-top: 1px solid #ccc;
                    border-left: 1px solid #ccc;
                }
                table td,
                table th {
                    border-bottom: 1px solid #ccc;
                    border-right: 1px solid #ccc;
                    padding: 3px 5px;
                }
                table th {
                    border-bottom: 2px solid #ccc;
                    text-align: center;
                }
            }
            .content::-webkit-scrollbar {
                display: none;
            }
        }
        .user{
            max-height:100%;
            height: auto;
            width: 330px;
            margin-left: 10px;
            background-color: #fff;
            padding: 20px 0;
            .title{
                font-size: 14px;
                color: #474952;
                font-weight: bold;
                padding: 0 20px;
                span{ 
                    margin-left: 10px;
                }
            }
            .scroll-part{
                height:calc(100vh - 240px);
                overflow-y: scroll ;
                padding: 0 20px;
            }
            .scroll-part::-webkit-scrollbar {
                display: none;
            }
            .readpart{
                display: flex;
                margin-top: 20px;
                // height: calc(100% - 39px);
                .msg{
                    margin-top: 12px;
                    color: #B0B1B9;
                    font-size: 14px;
                    width: 44px;
                }
                .userlist{
                    display: flex;
                    width: 248px;
                    flex-wrap: wrap;
                    // overflow-y: auto;
                    height: auto;
                    .list{
                        width: 50px;
                        height: 62px;
                        display: flex;
                        flex-direction: column;
                        margin-right: 13px;
                        align-items: center;
                        margin-top: 20px;
                        img{
                            width: 36px;
                            height: 36px;
                            border-radius: 50%;
                        }
                        .user-name{
                            font-size: 12px;
                            color:#6B6D77;
                            width: 100%;
                            text-align: center;
                            margin-top: 8px;
                            width: 50px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                    .list:nth-child(4n){
                        margin-right: 0;
                    }
                }
            }
        }
    }
} 
</style>
<style lang="scss">
    .detail-main{
        .content{
            table {
                border: 1px solid #ccc;
                
            }
            table td,
            table th {
                border: 1px solid #ccc;
                
            }
            table th {
                border-bottom: 2px solid #ccc;
            }
        }
        img{
            width: 100%;
        }
    }
</style>
  