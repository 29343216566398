<template>
    <div class="showselect">
        <el-dialog title="导出用户" :visible.sync="showDialog" width="400px" @close="cancel">
            <div class="dialog-top">
                <div class="body-left">
                    <div class="line-tltle">
                        <span class="title">列表项</span>
                        <div class="btn selectbtn btnpart">
                            <el-button class="cancel" @click="checkAll">全选</el-button>
                            <el-button class="cancel" @click="cancelAll">取消全选</el-button>
                        </div>
                    </div>
                    <div class="out-box">
                        <div class="select-box">
                            <div v-for="(item, index) in headerList" :key="index" :class="item.check ? 'active' : ''">
                                <div class="select-tab hover" v-if="index == 0">
                                    <span>{{ item.name }}</span>
                                    <el-checkbox v-model="item.check" disabled></el-checkbox>
                                </div>
                                <div class="select-tab hover" v-else>
                                    <div class="tab-name" @click.stop="checkTab(item, 'list')">{{ item.name }}</div>
                                    <el-checkbox v-model="item.check" @change="checkTab1(item)"></el-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer btnpart">
                <div>
                    <el-button class="confirm" type="primary" @click="bindOk">确 定</el-button>
                    <el-button class="cancel" @click="cancel">取 消</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "showselect",
    props: {
        show: {
            type: Boolean,
            default: {
                return: false
            }
        },
        list: {
            type: Array,
            default: {
                return: []
            }
        }
    },
    data() {
        return {
            showDialog: false,
            headerList: [],
            image: require('../../assets/image/none.png'),
        };
    },
    mounted() {
        this.showDialog = this.show
        this.headerList = this.list
        this.bindOk = this.$debounce(this.bindOk )
    },
    methods: {
        checkAll() {
            for (var a = 0; a < this.headerList.length; a++) {
                if (this.headerList[a].check == false) {
                    this.$set(this.headerList[a], 'check', true)
                }
            }
        },
        cancelAll() {
            for (var b = 1; b < this.headerList.length; b++) {
                this.$set(this.headerList[b], 'check', false)
            }
        },
        bindOk() {
            let list = []
            this.headerList.forEach(item => {
                if (item.check == true) {
                    list.push(item)
                }
            })
            this.$emit('export', list)
        },
        cancel() {
            this.$emit('cancel', false)
        },
        checkTab1(item) {
            let type = 'checkbox'
            this.checkTab(item, type)
        },
        checkTab(item, type) {
            if (type != 'checkbox') {
                item.check = item.check ? false : true
            }
        }
    }
}
</script>
<style lang="scss" scoped>
* {
    box-sizing: border-box;
}
</style>

<style lang="scss">
.showselect {
    .dialog-top {
        border-top: 1px solid #F0F1F5;
        height: 380px;
        display: flex;
        padding: 16px 24px;

        .body-left {
            width: 350px;
            height: 100%;

            .line-tltle {
                display: flex;
                justify-content: space-between;

                .title {
                    font-weight: bold;
                    font-size: 14px;
                    color: #474952;
                }
            }

            .select-box {
                margin-top: 8px;
                height: 330px;
                background: #F4F7FC;
                border-radius: 4px;
                overflow-y: auto;
                padding: 14px 0;

                .select-tab {
                    width: 100%;
                    height: 32px;
                    line-height: 32px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 12px 0 20px;
                }

                .tab-name {
                    width: 250px;
                }

                .select-tab:hover {
                    background: #EDF3FE;
                }
            }

            .active {
                color: #a2b3f8;
            }

        }

        .body-right {
            width: 220px;
            height: 100%;
            margin-left: 12px;

            .line-tltle {
                display: flex;
                justify-content: space-between;

                .title {
                    font-weight: bold;
                    font-size: 14px;
                    color: #474952;
                    line-height: 24px;
                }
            }

            .select-box {
                height: 330px;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #F0F1F5;
                overflow-y: auto;

                .select-tab {
                    height: 28px;
                    display: flex;
                    align-items: center;
                    background: #F1F6FC;
                    border-radius: 4px 4px 4px 4px;
                    margin-top: 6px;
                    padding: 5px 0;
                    justify-content: space-between;
                    padding-right: 12px;

                    .tab-left {
                        display: flex;

                        .icon {
                            padding: 2px 12px 0 12px;
                            border-right: 1px solid #D9E6F5;
                        }

                        span {
                            padding-left: 12px;
                        }
                    }

                    .tab-right {
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background: #DFE5EF;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .img {
                            width: 8px;
                            height: 8px;
                        }
                    }

                }

                .nomove {}
            }
        }
    }

    .dialog-footer {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 10px;
        padding-top: 4px;
    }
}</style>
