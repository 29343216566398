import { render, staticRenderFns } from "./system-message.vue?vue&type=template&id=2b5bad76&scoped=true&"
import script from "./system-message.vue?vue&type=script&lang=js&"
export * from "./system-message.vue?vue&type=script&lang=js&"
import style0 from "@/assets/scss/formstyle.scss?vue&type=style&index=0&prod&lang=scss&"
import style1 from "./system-message.vue?vue&type=style&index=1&id=2b5bad76&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b5bad76",
  null
  
)

export default component.exports