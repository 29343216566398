import request from '../utils/request'

//获取用户列表
export function userlist(data){
    return request({
        url:'/common/allAdmin',
        method:'get',
        params:data
    })
}

//获取公司列表
export function allcustom(data){
    return request({
        url:'/common/allClient',
        method:'get',
        params:data
    })
}

//获取下拉列表
export function selectdown(data){
    return request({
        url:'/v1/house_num/options',
        method:'get',
        params:data
    })
}

//新建房号
export function houselist(data){
    return request({
        url:'/v1/house_num/list',
        method:'get',
        params:data
    })
}

//新建房号
export function addhouse(data){
    return request({
        url:'/v1/house_num/add',
        method:'post',
        data
    })
}

//房号详情
export function hosuedetail(data){
    return request({
        url:'/v1/house_num/detail',
        method:'get',
        params:data
    })
}

//编辑房号
export function edithouse(data){
    return request({
        url:'/v1/house_num/edit',
        method:'post',
        data
    })
}

//删除房号
export function delhouse(data){
    return request({
        url:'/v1/house_num/del',
        method:'post',
        data
    })
}