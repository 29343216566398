import { render, staticRenderFns } from "./showImage.vue?vue&type=template&id=79d2ed60&scoped=true&"
import script from "./showImage.vue?vue&type=script&lang=js&"
export * from "./showImage.vue?vue&type=script&lang=js&"
import style0 from "./showImage.vue?vue&type=style&index=0&id=79d2ed60&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79d2ed60",
  null
  
)

export default component.exports