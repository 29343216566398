<template>
    <div>
        <el-dialog title="新建销售机会" :visible.sync="showdia" width="400px" custom-class="custom-dia"
            :before-close="handleClose">
            <div class="diahead">
                <div style="margin-left: 24px;font-size: 16px;color: #474952;font-weight: 700">新建销售机会</div>
                <div class="chaa" @click="cancel('addform')">
                    <local-svg iconClass="close1" style="margin-right: 24px;cursor: pointer;" class="icon"></local-svg>
                </div>
            </div>
            <div class="diabody">
                <el-form :model="ruleForm" ref="addform" :rules="rules" :validate-on-rule-change="false" label-width="100px"
                    class="demo-ruleForm">
                    <el-form-item label="机会名称" prop="name">
                        <span slot="label"><span class="check-form-notice">*</span>机会名称</span>
                        <el-input clearable placeholder="请输入机会名称" v-model="ruleForm.name"></el-input>
                    </el-form-item>
                    <el-form-item label="客户名称" prop="client_id" >
                        <span slot="label"><span class="check-form-notice">*</span>客户名称</span>
                        <!-- <el-input v-model="ruleForm.client_id"></el-input> -->
                        <el-select clearable filterable v-model="ruleForm.client_id" placeholder="请选择客户名称"  popper-class="custom-select">
                            <el-option :title="item.name" v-for="item in List.client " :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="部门名称" prop="branch_id">
                        <span slot="label"><span class="check-form-notice">*</span>部门名称</span>
                        <!-- <el-input v-model="ruleForm.client_id"></el-input> -->
                        <el-select clearable v-model="ruleForm.branch_id" placeholder="请选择部门名称">
                            <el-option v-for="item in List.branch " :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="预计销售金额(元)" prop="plan_amount">
                        <span slot="label"><span class="check-form-notice">*</span>预计销售金额(元)</span>
                        <el-input clearable placeholder="请输入金额" v-model="ruleForm.plan_amount" @blur="changThousand"></el-input>
                    </el-form-item>
                    <el-form-item class="dt" label="预计成交日期" prop="plan_clinch_time">
                        <!-- <el-input v-model="ruleForm.plan_clinch_time"></el-input> -->
                        <span slot="label"><span class="check-form-notice">*</span>预计成交日期</span>
                        <el-date-picker v-model="ruleForm.plan_clinch_time" type="date" placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="来源" prop="source_id">
                        <!-- <el-input v-model="ruleForm.source_id"></el-input> -->
                        <span slot="label"><span class="check-form-notice">*</span>来源</span>
                        <el-select clearable v-model="ruleForm.source_id" placeholder="请选择">
                            <el-option v-for="item in List.source " :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="渠道名称" prop="channel_id" v-if="ruleForm.source_id == 23">
                        <span slot="label"><span class="check-form-notice">*</span>渠道名称</span>
                        <el-select clearable v-model="ruleForm.channel_id" placeholder="请选择" filterable> 
                            <el-option v-for="item in channelList" :label="item.title" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="意向产品" prop="goods_id">
                        <!-- <el-input v-model="ruleForm.goods_id"></el-input> -->
                        <span slot="label"><span class="check-form-notice">*</span>意向产品</span>
                        <el-select clearable v-model="ruleForm.goods_id" placeholder="请选择" filterable>
                            <el-option v-for="item in List.goods " :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="备注">
                        <span slot="label" v-if="ruleForm.source_id == 15"><span class="check-form-notice">*</span>备注</span>
                        <el-input clearable placeholder="请输入备注" v-model="ruleForm.remark"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer btnpart">
                <el-button class="confirm" @click="selladd('addform')" type="primary">保存</el-button>
                <el-button class="cancel" @click="cancel('addform')">取消</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { sellAdd, sellAddList } from '@/views/sellChance/api'
export default {
    name: 'newsale',
    props: {
        show: {
            type: Boolean,
            default: () => {
                return false
            }
        },
        List: {
            type: Object
        },
        generate:{
            type: String,
            default: () => {
                return ''
            }
        },
        channelList:{
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            Customer: [
                {
                    name: '测试',
                    id: 1
                }
            ],
            showdia: false,
            ruleForm: {
                name: '',
                client_id: '',
                plan_amount: '',
                plan_clinch_time: '',
                source_id: '',
                goods_id: '',
                remark: '',
                branch_id: '',
                channel_id:''

            },
            rules: {
                // name: [
                //     { required: true, message: '机会名称不能为空', trigger: 'blur' },
                // ],
                // client_id: [
                //     { required: true, message: '客户名称不能为空', trigger: 'blur' },
                // ],
                // plan_amount: [
                //     { required: true, message: '预计销售金额不能为空', trigger: 'blur' },
                // ],
                // plan_clinch_time: [
                //     { required: true, message: '预计成交日期不能为空', trigger: 'blur' },
                // ],
                // source_id: [
                //     { required: true, message: '来源不能为空', trigger: 'blur' },
                // ],
                // goods_id: [
                //     { required: true, message: '意向产品不能为空', trigger: 'blur' },
                // ],
                // branch_id: [
                //     { required: true, message: '部门名称不能为空', trigger: 'blur' },

                // ]

            }

        }
    },
    mounted(){
        this.selladd = this.$debounce(this.selladd )
    },
    watch: {
        show: {
            handler(newValue, oldVal) {
                this.showdia = newValue
                if(this.showdia){
                    if(this.List.branch.length==1){
                        this.$set(this.ruleForm,'branch_id',this.List.branch[0].id)
                    }
                    if(this.generate){
                        this.$set(this.ruleForm,'client_id',Number(this.generate))
                    }
                    else{
                        this.$set(this.ruleForm,'client_id','')
                    }
                }
            }
        }
    },
    methods: {
        changThousand(){
            this.ruleForm.plan_amount = Number(this.ruleForm.plan_amount.replace(/,/g, "")).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 })
        },
        handleClose(done) {
            this.$emit('cancel', false)
            this.$refs.addform.resetFields();
            this.ruleForm = {
                name: '',
                client_id: '',
                plan_amount: '',
                plan_clinch_time: '',
                source_id: '',
                channel_id:'',
                goods_id: '',
                remark: '',
                branch_id: ''

            },
                done()
        },
        cancel(addform) {
            this.$emit('cancel', false)
            this.$refs[addform].resetFields();
        },
        selladd(addform) {
            this.$refs[addform].validate(async (valid) => {
                if (valid) {
                    this.$loading({ text: '加载中...' });
                    this.ruleForm.plan_amount = this.ruleForm.plan_amount.replace(/,/g, "")
                    sellAdd(this.ruleForm).then(res=>{
                        if (res.code === 200) {
                            this.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            this.ruleForm = {
                                name: '',
                                client_id: '',
                                plan_amount: '',
                                plan_clinch_time: '',
                                source_id: '',
                                channel_id:'',
                                goods_id: '',
                                remark: '',
                                branch_id: ''
                            }
                            this.$emit('cancel', false)
                        }
                        this.$loading().close()
                    }).catch (err=> {
                        this.$message.error("请求超时")
                        this.$loading().close()
                    })
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped>
:deep(.el-dialog__header) {
    display: none !important;
}

:deep(.el-dialog__body) {
    padding: 0 !important;
}

.diahead {
    width: 100%;
    height: 52px;
    // padding: 20px 24px 24px 12px;
    border-bottom: 1px solid #F0F1F5;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.diabody {
    // width: 100%;
    padding: 16px 24px 0 24px;

    :deep(.el-input__inner) {
        width: 220px !important;
        height: 32px !important;
    }
}

:deep(.el-form-item__label) {
    text-align: left;
    font-size: 14px;
    color: #6B6D77;
}

:deep(.el-form-item__label) {
    width: 130px !important;
}

:deep(.el-form-item__content) {
    margin-left: 127px !important;
}

:deep(.el-form-item) {
    margin-bottom: 8px !important;
}

:deep(.el-form-item__error) {
    padding-top: 0px !important;

}

.dt {
    :deep(.el-input__suffix) {
        right: 23px !important;
    }
}

:deep(.el-form-item__label:before) {
    position: absolute;
    left: 23px !important;
}

:deep(.el-form-item__label) {
    padding-left: 0px !important;
    position: absolute;
}

.saveclick {
    width: 76px;
    height: 32px;
    background: #2C57FF;
    // border-radius: 4px 4px 4px 4px;
    opacity: 1;
    text-align: center;
    line-height: 9px;
}

.canclick {
    width: 76px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #E2E5EA;
    text-align: center;
    line-height: 9px;
}

.chaa:hover {

    // color: #2C57FF !important;
    // border: 1px solid #2C57FF !important;
    .svg-icon {
        fill: #2C57FF;
    }
}
</style>