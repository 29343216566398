<template>
    <div class="home">
        <div class="main-box">
            <div class="personal-form">
                <el-form :model="personalForm" :rules="rules" ref="personalForm" >
                    <el-form-item label="用户头像" class="avatar">
                        <div class="avatar-box">
                            <img class="image" :src="personalForm.image" alt="">
                            <div class="tip" v-show="!is_chang">
                                <el-upload
                                class="upload-demo"
                                :http-request="uploadFile"
                                action=""
                                >
                                    <div class="uploadbtn hover" >上传图片</div>
                                </el-upload>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="用户名" prop="name">
                        <el-input v-model="personalForm.name" autocomplete="off" placeholder="请输入用户名" :disabled="is_chang"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号">
                        <el-input v-model="personalForm.mobile" autocomplete="off" placeholder="请输入手机号" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="电子邮箱" prop="email">
                        <el-input v-model="personalForm.email" autocomplete="off" placeholder="请输入电子邮箱" :disabled="is_chang"></el-input>
                    </el-form-item>
                    <el-form-item label="所属部门">
                        <el-input v-model="personalForm.branch" autocomplete="off" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="角色类型">
                        <el-input v-model="personalForm.role" autocomplete="off" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="密码" >
                        <el-input type="password" v-model="personalForm.password" autocomplete="off" placeholder="请输入密码" :disabled="is_chang">
                            <span slot="suffix" v-show="!is_chang" class="changepwd hover" @click="changPwd">{{ editword }}</span>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" v-show="wantchange">
                        <el-input type="password" v-model="personalForm.repassword" autocomplete="off" placeholder="请输入再次密码" :disabled="is_chang"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="bottom btnpart">
            <el-button type="primary" v-if="is_chang" class="confirm" @click="editInfo" v-permission="'个人中心-edit'">编辑</el-button>
            <el-button type="primary" v-if="!is_chang" class="cancel" @click="cancel">取消编辑</el-button>
            <el-button type="primary" v-if="!is_chang" class="confirm" @click="addInfo('personalForm')" v-permission="'个人中心-edit'">保存</el-button>
        </div>
    </div>
</template>

<script>
import { personinfo,savepersoninfo } from '@/api/terrace'
import md5 from 'js-md5'
import service from "@/utils/request.js";
import { Loading } from 'element-ui';
export default{
    name:'personal-terrace',
    data(){
        return{
            editword:'修改密码',
            personalForm:{
                name:'陈一鸣',
                mobile:'18526271532',
                email:'leeyeonwook@163.com',
                part:'',
                role:'',
                password:'',
                image:'',
            },
            is_chang:true,
            typeList:[
                {id:1,name:'类型一'},
                {id:2,name:'类型二'},
            ],
            roleList:[
                {id:1,name:'角色一'},
                {id:2,name:'角色二'},
            ],
            wantchange:false,
            rules:{
                name: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                ],
                mobile: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                ],
                email: [
                    { required: true, message: '请输入电子邮箱', trigger: 'blur' },
                ],
            },
        }
    },
    mounted(){
        this.getDetail()
    },
    methods:{
        cancel(){
            this.getDetail()
            this.is_chang = true
        },
        uploadFile(obj){
            console.log(obj)
            let fileObj = obj.file
            const { size, name } = fileObj
            let md5code = md5(name + new Date().getTime())
            let length = 500000
            let start = 0
            let end = 0
            let i = 1;
            Loading.service({
                text: "上传中...."
            });
            this.testHandle(md5code, length, start, end, size, name, fileObj, i)
        },
        testHandle(md5code, length, start, end, size, name, fileObj, i) {
            if (length > size) {
                length = size
            }
            if (end < size) {
                end = start + length
                if (end > size) {
                    end = size
                }
                let blob = ""
                blob = fileObj.slice(start, end)
                start = end
                let fd = new FormData()
                fd.append("file", blob)
                fd.append("md5code", md5code)
                let suc = 2
                if (end == size) {
                    suc = 1
                }
                fd.append("suc", suc)
                fd.append("tmp", i)
                fd.append("name", name)
                i = i + parseInt(1)
                service({
                    url: "/common/uploadImg",
                    method: "post",
                    data: fd,
                }).then(res => {
                    if (res.code == 200) {
                        if (suc == 2) {
                            this.testHandle(md5code, length, start, end, size, name, fileObj, i)
                        } else {
                            this.$message.success(res.msg)
                            Loading.service().close()
                            if (res.data.url !== undefined) {
                                this.personalForm.image = res.data.url
                            }
                        }
                    } else {
                        this.$message.error(res.msg)
                        Loading.service().close()
                    }
                })
                .catch(err => {
                    // this.$message.error("请重新上传")
                    Loading.service().close()
                })
            }
        },
        changPwd(){
            this.wantchange = this.wantchange?false:true
            this.editword = this.wantchange?'取消修改':'修改密码'
            this.personalForm.password = this.wantchange?'':'******'
            this.$set(this.personalForm,'repassword','')
        },
        getDetail(){
            personinfo().then(res=>{
                this.personalForm = res.data
                this.$set(this.personalForm,'password','******')
                this.personalForm.mobile = this.personalForm.mobile+''
            })
        },
        addInfo(personalForm){
            if(this.wantchange){
                if(this.personalForm.password !== this.personalForm.repassword ){
                    this.$message.error("两次输入密码不一致")
                    return
                }
            }
            this.$refs[personalForm].validate((valid) => {
                if (valid) {
                    let data = {
                        name:this.personalForm.name,
                        mobile:this.personalForm.mobile,
                        email:this.personalForm.email,
                        password:this.wantchange?this.personalForm.password:'',
                        image:this.personalForm.image,
                    }
                    savepersoninfo(data).then(res=>{
                        if(res.code==200){
                            this.$message.success(res.msg)
                            this.$store.commit('setUserImage', this.personalForm.image)
                            this.is_chang = true
                        }else{
                            this.$message.error(res.msg)
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        editInfo(){
            this.is_chang = !this.is_chang
            console.log(this.is_chang)
        },
    }
}

</script>

<style src="@/assets/scss/formstyle.scss" lang="scss">

</style>

<style lang="scss" scoped>
*{
    box-sizing: content-box;
}
.home{
    padding: 24px;
    height: calc(100% - 60px);
    .main-box{
        width: 100%;
        height: calc(100% - 82px);
        background: white;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        padding-top: 60px;
        box-sizing: border-box;
        .personal-form{
            width: 400px;
            .avatar{
                height: 120px;
                .avatar-box{
                    width: 120px;
                    height: 120px;
                    margin-left: 70px;
                    overflow: hidden;
                    position: relative;
                    border-radius: 50%;
                    top: -16px;
                    .image{
                        width: 100%;
                        height: 100%;
                        z-index: 9;
                    }
                    .tip{
                        position: absolute;
                        z-index: 10;
                        bottom: 0;
                        color: white;
                        height: 40px;
                        width: 120px;
                        background: rgba(0,0,0,0.3);
                        text-align: center;
                        font-size: 12px;
                        .uploadbtn{
                            border: none;
                            background: transparent;
                            width: 100%;
                            height: 100%;
                            color: white;
                            font-size: 14px;
                        }
                    }
                }
            }
            .changpwd{
                margin-right: 10px;
            }
        }
        
    }
    .bottom{
        height: 82px;
        padding-right:40px;
        box-sizing: border-box;
        border-top: 1px solid #F0F1F5;
        width: 100%;
        background: white;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
</style>