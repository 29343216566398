<template>
    <div class="edit">
        <el-dialog title="编辑用户" :visible.sync="showDialog" width="400px" class="addform"  @close="cancel">
            <div class="addform numberedit">
                <el-form :model="editForm" :rules="rules" ref="editForm">
                    <el-form-item label="用户名"  prop="name">
                        <span slot="label"><span class="check-form-notice">*</span>用户名</span>
                        <el-input v-model="editForm.name" autocomplete="off" placeholder="请输入用户名"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="mobile">
                        <span slot="label"><span class="check-form-notice">*</span>手机号</span>
                        <el-input v-model="editForm.mobile" autocomplete="off" placeholder="请输入手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="电子邮箱" prop="email">
                        <span slot="label"><span class="check-form-notice">*</span>电子邮箱</span>
                        <el-input v-model="editForm.email" autocomplete="off" placeholder="请输入电子邮箱"></el-input>
                    </el-form-item>
                    <el-form-item label="所属部门" prop="part">
                        <span slot="label"><span class="check-form-notice">*</span>所属部门</span>
                        <el-cascader v-model="editForm.part" :options="typelist" collapse-tags :show-all-levels="false" :props="props1" placeholder="请选择所属部门" ></el-cascader>
                    </el-form-item>
                    <el-form-item label="角色类型">
                        <span slot="label"><span class="check-form-notice">*</span>角色类型</span>
                        <el-select v-model="editForm.role" placeholder="请选择角色类型"  multiple collapse-tags>
                            <el-option v-for="item in rolelist" :label="item.name" :value="item.id" :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="修改密码" >
                        <span slot="label"><span class="check-form-notice">*</span>修改密码</span>
                        <el-input type="password" v-model="editForm.password" autocomplete="off" placeholder="请输入新密码">
                            <span slot="suffix" class="changepwd hover handel" @click="changPwd">{{ editword }}</span>
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            
            <div slot="footer" class="dialog-footer btnpart">
                <el-button class="confirm" type="primary" @click="editOk('editForm')">保 存</el-button>
                <el-button class="cancel" @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { userdetail } from '@/api/terrace'
export default {
    name: "edit",
    props: {
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        typelist:{
        	type:Array,
        	default:()=>{
        		return []
        	}
        },
        rolelist:{
        	type:Array,
        	default:()=>{
        		return []
        	}
        },
        editform:{
            type:Object,
        	default:()=>{
        		return {}
        	}
        }
    },
    data() {
        return {
            props1:{
                multiple: true,
                value:'id',
                checkStrictly: true
            },
            editForm:{},
            showDialog:false,
            wantchang:false,
            editword:'确认修改',
            rules: {
                // name: [
                //     { required: true, message: '请输入活动名称', trigger: 'blur' },
                // ],
                // mobile: [
                //     { required: true, message: '请输入手机号码', trigger: 'blur' },
                //     {  pattern:/^13[0-9]{1}[0-9]{8}$|15[^4]{1}[0-9]{8}$|18[0-9]{1}[0-9]{8}$|17[0-8]{1}[0-9]{8}$|14[5-9]{1}[0-9]{8}$|16[6]{1}[0-9]{8}$|19[8-9]{1}[0-9]{8}$/, message: "请输入合法手机号/电话号", trigger: "blur" }
                // ],
                // email: [
                //     { required: true, message: '请输入邮箱', trigger: 'blur' },
                //     { pattern:/^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g, message: "请输入正确的邮箱", trigger: "blur"}
                // ],
                // part: [
                //     { required: true, message: '请选择部门', trigger: 'change' },
                // ],
                // password: [
                //     { required: true, message: '请输入密码', trigger: 'blur' },
                //     { min: 6, max: 8, message: '长度在 6 到 8 个字符', trigger: 'blur' }
                // ],
            }
        };
    },
    mounted(){
        this.showDialog = this.show
        let id = this.editform.id
        this.getDetail(id)
        // this.editForm = this.editform 
    },
    methods: {
        getDetail(id){
            let data = {
                id:id
            }
            userdetail(data).then(res=>{
                this.editForm = res.data
                let roleArray = this.editForm.r_id.split(',')
                let array = []
                roleArray.forEach(item => {
                    array.push(Number(item))
                })
                let part = res.data.b_id.split(',')
                let partlist = []
                for(var a = 0;a<part.length;a++){
                    partlist.push(this.changeDetSelect(part[a],this.typelist))
                }
                this.$set(this.editForm,'part',partlist)
                this.$set(this.editForm,'role',array)
                this.$set(this.editForm,'password','')
                this.editForm.id = id
            })
        },
        changeDetSelect(key, treeData) {
            let arr = []; // 在递归时操作的数组
            let returnArr = []; // 存放结果的数组
            let depth = 0; // 定义全局层级
            // 定义递归函数
            function childrenEach(childrenData, depthN) {
                for (var j = 0; j < childrenData.length; j++) {
                    depth = depthN; // 将执行的层级赋值 到 全局层级
                    arr[depthN] = childrenData[j].id;
                    if (childrenData[j].id== key) {
                        returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
                        break;
                    } else {
                        if (childrenData[j].children) {
                            depth++;
                            childrenEach(childrenData[j].children, depth);
                        }
                    }
                }
                return returnArr;
            }
            return childrenEach(treeData, depth);
        },
        changPwd(){
            this.$confirm('此操作将修改原密码, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '确认修改!'
                });
                this.wantchang = true
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消修改'
                });   
                this.editForm.password = ''       
            });
        },
        cancel(){
            this.editForm = {}
            this.$emit('close',false)
            this.showDialog = false
        },
        editOk(ediltForm){
            this.$refs[ediltForm].validate((valid) => {
                if (valid) {
                    if(this.wantchang == false && this.editForm.password != ''){
                        this.$message({
                            message:'请先确认是否修改密码！',
                            type:'error'
                        })
                        return
                    }
                    let id = []
                    let array = []
                    let str = ''
                    for(var a = 0; a<this.editForm.part.length;a++){
                        for(var b = 0; b<this.editForm.part[a].length;b++){
                            if(b==0){
                                str = str + this.editForm.part[a][b]
                            }else{
                                str = str + ';' + this.editForm.part[a][b]
                            }
                            
                        }
                        array.push(str)
                        str = ''
                    }
                    this.editForm.partArray = array.toString()
                    let item = this.editForm.part
                    for(var a=0;a<item.length;a++){
                        id.push(item[a][this.editForm.part[a].length-1])
                    }
                    this.editForm.b_id = id
                    this.$emit('edituser',this.editForm)
                } else {
                    return false;
                }
            });
        },
    }
}
</script>

<style lang="scss">
.edit {
    .changepwd{
        margin-right: 10px;
    }
}
</style>
