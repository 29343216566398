<template>
    <div class="adduser">
        <el-dialog :title="title" :visible.sync="showDialog" width="400px" class="addform" @close="cancel">
            <div class="addform openuser">
                <el-form :model="addform" :rules="rules" ref="addform">
                    <el-form-item label="支出公司">
                        <span slot="label"><span class="check-form-notice">*</span>支出公司</span>
                        <el-select v-model="addform.company" placeholder="请选择支出公司" clearable filterable>
                            <el-option v-for="item in companyList" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所属部门">
                        <span slot="label"><span class="check-form-notice">*</span>所属部门</span>
                        <el-select v-model="addform.branch_id" placeholder="请选择所属部门" clearable filterable @change="getOpen('check')">
                            <el-option v-for="item in branchList" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="开票人">
                        <span slot="label"><span class="check-form-notice">*</span>开票人</span>
                        <el-select v-model="addform.payer_id" :placeholder="addform.branch_id?'请选择开票人':'请先选择所属部门'" clearable filterable
                        :disabled="!addform.branch_id">
                            <el-option v-for="item in openList" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    
                </el-form>
                
            </div>
            <div slot="footer" class="dialog-footer btnpart">
                <el-button v-if="type==1" class="confirm" type="primary" @click="addOk('addform')" plain>确 定</el-button>
                <el-button v-else class="confirm" type="primary" @click="editOk('addform')" plain>提 交</el-button>
                <el-button class="cancel" @click="cancel" plain>取 消</el-button>
            </div>
            
        </el-dialog>
    </div>
</template>

<script>
import {select} from '@/api/company'
export default {
    name: "adduser",
    props: {
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        type:{
            type:Number,
            default:()=>{
                return 1
            }
        },
        form:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    data() {
        return {
            addform:{
                company:'',
                branch_id:'',
                payer_id:''
            },
            branchList:[],
            openList:[],
            companyList:[],
            showDialog:false,
            rules: {
                
            },
            title:'新建开票人'
        };
    },
    mounted(){
        this.showDialog = this.show
        this.getSelect()
        if(this.type==2){
            this.title = '编辑开票人'
            console.log(this.form)
            this.addform.company = this.form.company_id
            this.addform.branch_id = this.form.branch_id
            this.getOpen('return')
            this.$set(this.addform,'payer_id',this.form.payer_id)
            this.addform.id = this.form.id
        }
    },
    methods: {
        getSelect(){
            select().then(res=>{
                this.branchList = res.data.branch
                this.companyList = res.data.company
                // this.openList = res.data.drawer
            })
        },
        addOk(addform){
            this.$refs[addform].validate((valid) => {
                if (valid) {
                    this.$emit('adduser',this.addform)
                } else {
                    return false;
                }
            });
            
        },
        getOpen(msg){
            let data = {
                b_id:this.addform.branch_id
            }
            select(data).then(res=>{
                this.openList = res.data.drawer
                if(msg == 'check'){
                    this.addform.payer_id = ''
                }
            })
        },
        editOk(addform){
            this.$refs[addform].validate((valid) => {
                if (valid) {
                    this.$emit('edituser',this.addform)
                } else {
                    return false;
                }
            });
        },
        cancel(){
            this.$emit('close',false)
            this.showDialog = false
        },
    }
}
</script>

<style lang="scss">
.openuser{
    // padding-top:15px !important ;
}
</style>
