<template>
    <div class="custom">
        <div class="main" v-if="!detailDialog">
            <div class="top-search">
                <el-form :model="form" class="demo-form-inline" label-width="">
                    <div class="select-line bostrap" :style="{ height: (hide == false ? '43px' : 'auto') }" id="searchLine">
                        <el-form-item label="标题" class="">
                            <el-input class="" v-model="form.title" placeholder="请输入关键字搜索">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="发布方式">
                            <el-select v-model="form.way" placeholder="请选择" clearable filterable>
                                <el-option v-for="item in wayList" :label="item.label" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="创建时间" class="time">
                            <el-date-picker v-model="form.set_time" value-format="yyyy-MM-dd" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" type="daterange"
                                placeholder="请选择"></el-date-picker>
                        </el-form-item>
                    </div>
                    <div class="line"></div>
                    <div class="btn-line">
                        <div class="btnpart">
                            <el-button class="confirm" @click="getList('search')" plain v-permission="'系统公告-select'">查询</el-button>
                            <el-button class="cancel" @click="clearSeach" plain v-permission="'系统公告-reset'">重置</el-button>
                        </div>
                    </div>

                </el-form>
            </div>
            <div class="tablepart">
                <div class="top">
                    <div class="span">
                        <div class="title">系统公告</div>
                        <el-radio-group v-model="form.create_id" @input="getList">
                            <el-radio label="1">我发出的</el-radio>
                            <el-radio label="2" >我收到的</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="btnlist btnpart">
                    <el-button class="iconbtn" @click="beginAdd" plain><local-svg iconClass="add" v-permission="'系统公告-craete'"
                                class="icon"></local-svg>新建公告</el-button> 
                    </div>
                </div>
                <customTable :list="tableData" @tableedit="wantEdit" @detail="seeDetail" :type='form.create_id' :loading="load">
                </customTable>
                <div class="pagetab">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <changeDialog v-if="changeDialog" :form="detailData" :show="changeDialog" @cancel="cancelChange" @add="addUser"
            :type="addtype" @edit="deitUser"></changeDialog>
        <detailDialog v-if="detailDialog" :show="detailDialog" @close="closeDetail" :detail="detailData"></detailDialog>
    </div>
</template>

<script>
import { noticelist,addnotice,noticedetail,editdetail } from '@/api/message'
import { addcustom, customdetail, editcustom, delmoreuser } from '@/api/custom'
import customTable from "@/components/message/notice/table.vue"
import changeDialog from "@/components/message/notice/change.vue"
import detailDialog from "@/components/message/notice/detail.vue"
export default {
    name: 'custom',
    data() {
        return {
            hide: false,
            changeDialog: false,
            detailDialog: false,
            load: false,
            currentPage: 1,
            pageSize: 10,
            total: 3,
            actText: '展开',
            userList: [],
            typeList: [],
            wayList:[
                {label:'立即发布',id:1},
                {label:'定时发布',id:2},
            ],
            partList: [],
            form: {
                title:'',
                way:'',
                set_time:[],
                create_id:'1'
            },
            tableData: [],
            headerList: [
            ],
            selectionList: [],
            addtype: 1,
            detailData: {},
            load:true
        }
    },
    components: {
        customTable,
        changeDialog,
        detailDialog
    },
    created(){
        let type = this.$route.query.type
        if(type && type=='read'){
            this.$set(this.form,'create_id','2')
        }
        if(type && type=='detail'){
            this.$set(this.form,'create_id','2')
            let data = {
                id:this.$route.query.id
            }
            this.seeDetail(data)
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        seeDetail(msg){
            let data = {
                id: msg.id
            }
            noticedetail(data).then(res => {
                this.detailData = res.data
                this.detailData.content = this.setHtml(this.detailData.content)
                this.detailDialog = true
            })
        },
        setHtml(str){
            return str
            .replace(str ? /&(?!#?\w+;)/g : /&/g, '&amp;')
            .replace(/&amp;nbsp;/g, "")
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&quot;/g, "\"")
            .replace(/&#39;/g, "\'")
        },
        closeDetail(){
            this.detailDialog = false
        },
        selectRow(row) {
            this.selectionList = row
        },
        deleteMoreUser() {
            if (this.selectionList.length == 0) {
                this.$message({
                    message: '请先选择要删除的客户！',
                    type: 'error'
                })
                return
            }

            this.$confirm('此操作将永久删除所选用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let array = []
                this.selectionList.forEach(item => {
                    array.push(item.id)
                })
                let data = {
                    b_id: array.toString()
                }
                delmoreuser(data).then(res => {
                    if (res.code == 200) {
                        this.getList()
                        this.selectionList = []
                        this.changeDialog = false
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        deitUser(msg) {
            let data = {
                id: msg.id,
                title:msg.title,
                way:msg.way,
                date:msg.date,
                content:msg.content,
                lever:msg.lever,
                is_release:msg.is_release,
            }
            editdetail(data).then(res => {
                if (res.code == 200) {
                    this.getList()
                    this.changeDialog = false
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
            })
        },
        beginAdd() {
            this.addtype = 1
            this.changeDialog = true
        },
        addUser(data) {
            addnotice(data).then(res => {
                if (res.code == 200) {
                    this.getList()
                    this.changeDialog = false
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
            })
        },
        cancelChange() {
            this.changeDialog = false
        },
        clearSeach() {
            this.form = {
                title:'',
                way:'',
                set_time:[],
                create_id:'1'
            }
            this.getList('search')
        },
        // 获取用户列表
        getList(e) {
            if(e){
                this.currentPage = 1
            }
            this.load = true
            let data = {
                page: this.currentPage,
                count: this.pageSize,
                title:this.form.title,
                start_time:this.form.set_time.length>0?this.form.set_time[0]+' 00:00:00':'',
                end_time:this.form.set_time.length>0?this.form.set_time[1]+' 23:59:59':'',
                way:this.form.way,
                create_id:this.form.create_id
            }
            noticelist(data).then(res => {
                this.tableData = res.data.table
                this.total = res.data.total
                setTimeout(() => {
                    this.load = false
                }, 1000);
            })
        },
        wantEdit(msg) {
            let data = {
                id: msg.info.id
            }
            noticedetail(data).then(res => {
                this.addtype = 2
                this.detailData = res.data
                this.changeDialog = true
            })
        },
        setUserType() { },
        showHide() {
            this.actText = this.hide ? '展开' : '收起'
            this.hide = !this.hide
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.currentPage = 1
            this.getList()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getList()
        },
    }
}

</script>

<style src="@/assets/scss/formstyle.scss" lang="scss" ></style>
<style lang="scss" scoped>
* {
    box-sizing: content-box;
}

.custom {
    .main{
        padding: 24px;
    }

    .top-search {
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;

        .select-line {
            display: flex;
            padding: 0 20px 0 0;
        }

        .line {
            width: 100%;
            height: 1px;
            background: #F0F1F5;
        }

        .btn-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 11px 20px;

            .showpart {
                height: 100%;
                display: flex;
                align-items: baseline;
                font-size: 14px;

                .icon {
                    margin-left: 6px;
                    width: 8px;
                    height: 8px;
                }
            }

            .act {
                background: #2C57FF;
                color: white;
            }

            .btnpart button {
                height: 32px;
                width: 68px;
                line-height: 32px;
                padding: 0;

            }
        }
    }

    .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
            font-size: 14px;
            font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
            font-weight: bold;
            color: #474952;
            line-height: 16px;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .icon {
            width: 16px;
            height: 16px;
        }

        i {
            margin-right: 5px;
        }
    }

    .tablepart {
        height: calc(100vh - 260px);
        background: white;
        margin-top: 10px;
        border-radius: 4px;
        padding: 16px 20px;

        .icon {
            margin-right: 8px;
        }

        .pagetab {
            text-align: right;
            margin-top: 20px;
        }

        .span {
            font-size: 14px;
            font-weight: bold;
            color: #474952;
            display: flex;
            align-items: center;
            .title{
                margin-right: 30px;
            }
        }

        .top {
            display: flex;
            align-items: center;
        }
    }
}
</style>