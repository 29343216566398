<template>
    <div class="adduser">
        <el-dialog :title="title" :visible.sync="showDialog" width="400px" class="addform"  @close="cancel">
            <div class="addform">
                <el-form   :model="roleform" :rules="rules" ref="roleform">
                    <el-form-item label="角色代码" prop="code">
                        <span slot="label"><span class="check-form-notice">*</span>角色代码</span>
                        <el-input v-model="roleform.code"  autocomplete="off"  placeholder="请输入角色代码"></el-input>
                        <el-input v-model="roleform.sort" type="sort" class="index-select">
                            <span slot="prefix" class="unit-left">第</span>
                            <span slot="suffix" class="unit-right">个</span>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="角色名称" prop="name">
                        <span slot="label"><span class="check-form-notice">*</span>角色名称</span>
                        <el-input v-model="roleform.name" autocomplete="off" placeholder="请输入角色名称"></el-input>
                    </el-form-item>
                    <el-form-item label="描述" >
                        <el-input v-model="roleform.intro" autocomplete="off" type="textarea" rows="5" class="describe" placeholder="请输入角色介绍"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            
            <div slot="footer" class="dialog-footer btnpart">
                <el-button v-if="type==1" class="confirm" type="primary" @click="roleOk('roleform')">提 交</el-button>
                <el-button v-else class="confirm" type="primary" @click="editOk('roleform')">保 存</el-button>
                <el-button class="cancel" @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "adduser",
    props: {
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        rolelist:{
        	type:Array,
        	default:()=>{
        		return []
        	}
        },
        type:{
        	type:Number,
        	default:()=>{
        		return 0
        	}
        },
        form:{
        	type:Object,
        	default:()=>{
        		return {}
        	}
        },
    },
    mounted(){
        this.showDialog = this.show
        if(this.type==1){
            this.title = '新建角色'
        }else{
            this.title = '编辑角色'
            console.log(this.form)
            this.roleform = this.form
        }
    },
    data() {
        return {
            // props1:{
            //     multiple: false,
            //     value:'id',
            // },
            roleform:{
                sort:1,
                name:'',
                code:'',
                intro:''
            },
            showDialog:false,
            title:'新建角色',
            rules: {
                // code: [
                //     { required: true, message: '请输入角色代码', trigger: 'blur' },
                // ],
                // name: [
                //     { required: true, message: '请输入角色名称', trigger: 'blur' },
                // ],
            },
        };
    },
    methods: {
        cancel(){
            this.$emit('close',false)
            // this.clean()
        },
        editOk(roleform){
            this.$refs[roleform].validate((valid) => {
                if (valid) {
                    console.log(1)
                    this.$emit('edit',this.roleform)
                    // this.clean()
                } else {
                    return false;
                }
            });
            
        },
        roleOk(roleform){
            this.$refs[roleform].validate((valid) => {
                if (valid) {
                    this.$emit('change',this.roleform)
                    // this.clean()
                } else {
                    return false;
                }
            });
            
        },
        clean(){
            this.addform = {
                index:0,
                name:'',
                number:'',
                father:'',
                tip:''
            }
        },
    }
}
</script>

<style lang="scss">
.adduser {
    .index-select{
        width: 60%;
        margin-left: 8px;
        .el-input__inner {
            height: 32px !important;
            padding: 0 0 0 40px !important;
        }
        .el-input__suffix{
            // right: 30px;
        } 
        .describe textarea{
            height: 120px !important;
        }
    }
}
</style>
