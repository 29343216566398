<template>
    <div class="adduser improve-back">
        <el-dialog :title="title" :visible.sync="showDialog" width="400px" class="addform"  @close="cancel" style="margin-top: 15vh;">
            <div class="addform">
                <el-form :model="form" ref="form">
                    <el-form-item label="客户名称" prop="user">
                        <span slot="label"><span class="check-form-notice">*</span>客户名称</span>
                        <el-input  v-model="form.client" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="回款单号" prop="user">
                        <span slot="label"><span class="check-form-notice">*</span>回款单号</span>
                        <el-select v-model="checkList"  placeholder="请选择" multiple auto-complete collapse-tags  popper-class="OAselect" >
                            <el-option v-for="item in payList" :label="item.number" :value="item.id" :key="item.id" >
                                <span style="float: left;">{{item.number}}</span>
                                <span style="position: absolute; right: 35px; font-size: 12px; color: #8492a6; ">{{ item.create_time}}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer btnpart btnpart" style="margin-top:14px">
                <el-button class="confirm" type="primary" @click="submitBack" plain>确定</el-button>
                <el-button class="cancel" @click="cancel" plain>取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {adddownselect,improvebill} from '@/api/bill'
export default {
    name: "adduser",
    props: {
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        form:{
        	type:Object,
        	default:()=>{
        		return {}
        	}
        },
    },
    data() {
        return {
            showDialog:false,
            title:'完善回款单号',
            payList:[],
            checkList:[]
        };
    },
    mounted(){
        this.showDialog = this.show
        this.getPayList()
    },
    methods: {
        getPayList(){
            let data = {
                id:this.form.client_id
            }
            adddownselect(data).then(res=>{
                console.log(this.form.way)
                if(this.form.way == 1){
                    this.payList = res.data.pay
                }else{
                    this.payList = res.data.houseBack
                }
                console.log(this.payList)
            })
        },
        cancel(){
            this.$emit('close',false)
        },
        submitBack(){
            let data = {
                back_number:this.checkList.toString(),
                id:this.form.id,
                payback:8
            }
            this.$loading({ text: '加载中...' });
            improvebill(data).then(res=>{
                if(res.code == 200){
                    this.$message({
                        message:res.msg,
                        type:'success'
                    })
                    this.$emit('improve',true)
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
    }
}
</script>

<style lang="scss">
.adduser {
    .el-textarea{
        margin-top: 5px !important;
    }
}
.improve-back{
    .el-dialog__header{
        // display: none;
    }
}
</style>
