import request from '../utils/request'

//获取待办下拉列表
export function todeselect(data){
    return request({
        url:'/remind/option',
        method:'get',
        params:data
    })
}

//获取代办事件列表
export function todolist(data){
    return request({
        url:'/remind/index',
        method:'get',
        params:data
    })
}

//获取系统公告下拉列表
export function noticeselect(data){
    return request({
        url:'',
        method:'get',
        params:data
    })
}

//获取系统消息下拉
export function messageselect(data){
    return request({
        url:'',
        method:'get',
        params:data
    })
}

//获取系统消息列表
export function noticelist(data){
    return request({
        url:'/notice/index',
        method:'get',
        params:data
    })
}

//添加系统公告
export function addnotice(data){
    return request({
        url:'/notice/create',
        method:'post',
        data
    })
}

//系统消息模版列表
export function modellist(data){
    return request({
        url:'/template/index',
        method:'get',
        params:data
    })
}

//系统消息消息列表
export function msglist(data){
    return request({
        url:'/system/index',
        method:'get',
        params:data
    })
}

//系统消息查询列表
export function msgselect(data){
    return request({
        url:'/system/option',
        method:'get',
        params:data
    })
}


//公告详情
export function noticedetail(data){
    return request({
        url:'/notice/detail',
        method:'get',
        params:data
    })
}

//编辑公告
export function editdetail(data){
    return request({
        url:'/notice/edit',
        method:'post',
        data
    })
}

// 获取公告读取详情
export function readdetail(data){
    return request({
        url:'/notice/check',
        method:'get',
        params:data
    })
}

//更改模版状态
export function changestatus(data){
    return request({
        url:'/template/status',
        method:'post',
        data
    })
}

//模板详情
export function modeldetail(data){
    return request({
        url:'/template/detail',
        method:'get',
        params:data
    })
}

//编辑模版
export function editmodel(data){
    return request({
        url:'/template/edit',
        method:'post',
        data
    })
}