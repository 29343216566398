<template>
    <div class="tree">
        <div class="treebox">
            <el-tree
                :data="data"
                node-key="id"
                :expand-on-click-node="false"
                getNode
                ref="tree"
                :check-strictly="true"
                :default-expanded-keys="defaultExpandIds"
                @node-expand="handleNodeExpand"
                @node-collapse="handleNodeCollapse"
            >
                <span class="custom-tree-node"  style="width: 100%;" slot-scope="{ data }" @click="nodeClick(data)">
                    <span v-if="data.father" class="tree-title handel">
                        <local-svg iconClass="tree" style="width:18px; height:18px"></local-svg>
                        {{ data.label }}
                    </span>
                    <span class="handel tree-title"  v-else>
                        {{ data.label }}
                    </span>
                    <span class="right-btn">
                        <div class="right-btn right">
                            <!-- <local-svg iconClass="down" style="width:12px; height:12px;margin-right: 10px;"></local-svg> -->
                            <!-- <i class="el-icon-arrow-right"> </i> -->
                        </div>
                        <div class="right-btn edit " >
                            <div class="handel">
                                <local-svg iconClass="newpart" v-permission="'部门管理-create'" class=" " style="width:12px; height:12px;margin-right: 20px;" @click.native="addChild(data)"></local-svg>
                            </div>
                            <el-dropdown style="margin-right: 20px;" placement="bottom" trigger="click">
                                <span class="el-dropdown-link" >
                                    <div class="link-box handelicon">
                                        <local-svg iconClass="more " style="width:14px; height:3px"></local-svg>
                                    </div>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="editPart(data)" v-permission="'部门管理-edit'">编辑</el-dropdown-item>
                                    <el-dropdown-item @click.native="delPart(data)" v-permission="'部门管理-del'">删除</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        
                    </span>
                </span>
            </el-tree>
        </div>
    </div>
</template>

<script>
export default {
    name: "tree",
    props: {
        data:{
        	type:Array,
        	default:()=>{
        		return []
        	}
        },
    },
    data() {
        return {
            actpart:0,
            oldData:{},
            defaultExpandIds:[]
        };
    },
    mounted(){
        this.nodeClick(this.data[0])
        this.actpart = 0
        this.$refs.tree.setCheckedKeys([])
    },
    methods: {
        handleNodeExpand(data) {
            // 保存当前展开的节点
            let flag = false
            this.defaultExpandIds.some(item => {
                if (item === data.id) { // 判断当前节点是否存在， 存在不做处理
                    flag = true
                    return true
                }
            })
            if (!flag) { // 不存在则存到数组里
                this.defaultExpandIds.push(data.id)
            }
        },
        handleNodeCollapse(data) {
            // 删除当前关闭的节点
            this.defaultExpandIds.some((item, i) => {
                if (item === data.id) {
                this.defaultExpandIds.splice(i, 1)
                }
            })
            this.removeChildrenIds(data) // 这里主要针对多级树状结构，当关闭父节点时，递归删除父节点下的所有子节点
        },
        removeChildrenIds(data) {
            const ts = this
            if (data.children) {
                data.children.forEach(function(item) {
                    const index = ts.defaultExpandIds.indexOf(item.id)
                    if (index > 0) {
                        ts.defaultExpandIds.splice(index, 1)
                    }
                    ts.removeChildrenIds(item)
                })
            }
        },
        nodeClick(data) {
            if(this.actpart === data.id) {
                // this.actpart = 0
                // this.$refs.tree.setCheckedKeys([])
            }else {
            // 否则，覆盖原先的值
                this.currentCheckData = data
                this.$refs.tree.setCheckedKeys([data.id])
            }
            this.actpart = data.id
            let checkArray = [data.id]
            this.$emit('checkpart',checkArray)
        },
        addChild(item){
            this.$emit('addchild',item)
        },
        handleCheckChange(data, checked, indeterminate) {
            // let checkArray = this.$refs.tree.getCheckedKeys()
            // this.$emit('checkpart',checkArray)
        },
        editPart(item){
            this.$emit('edit',item)
        },
        delPart(item){
            this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.$emit('deltree',item.id)
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
            });
        },
        handleNodeClick(data) {
            console.log(data);
        },
    }
}
</script>

<style lang="scss" scoped>
.tree {
    overflow-y: auto;

    .treebox{
        height: 770px;
        width: 100%;
        box-sizing: border-box;
        padding: 18px 0px;
        .tree-title{
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap;
            width: 145px;
        }
        .right-btn{
            display: flex;
            align-items: center;
            .addicon{
                color: #B0B1B9 !important;
            }
        }
        
        .right{
            justify-content: flex-end;
            display: flex;
        }
        .edit{
            display: none;
            position: absolute;
            right: 0;
        }
        .custom-tree-node{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            position: relative;
            // padding-right: 8px;
            .link-box{
                width: 100%;
                height: 20px;
                display: flex;
                align-items: center;
            }
        }
        .custom-tree-node:hover{
            .edit{
                display: flex;
            }
            .right{
                display: none;
            }
        }
        .actpart{
            background: #EDF3FE;
            
        }
         .el-tree-node.is-current > .el-tree-node__content {
            background: #EDF3FE !important;
            color: #2C57FF;
        }
        

    }
    
}
::-webkit-scrollbar {
        width: 0 !important;
    }
::-webkit-scrollbar {
    width: 0 !important;height: 0;
}
.el-dropdown-menu__item:hover{
    color: #2C57FF !important;
    background: #F4F6FE !important;
}
</style>
