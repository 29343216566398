<template>
    <div class="refuse finance">
        <el-dialog :title="title" :visible.sync="showDialog" width="400px" class="addform"  @close="cancel">
            <div class="addform" style="padding-bottom:10px">
                <el-form :model="checkform" :rules="rules" ref="checkform" class="">
                    <el-form-item label="实际支出时间" prop="pay_time" style="position: relative;">
                        <span slot="label"><span class="check-form-notice">*</span>实际支出时间</span>
                        <el-date-picker v-model="checkform.pay_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="支出账户" prop="pay_account">
                        <span slot="label"><span class="check-form-notice">*</span>支出账户</span>
                        <el-select v-model="checkform.pay_account" placeholder="请选择" filterable>
                            <el-option v-for="item in receiveList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="票据情况" prop="bill_status" v-if="!is_order">
                        <span slot="label"><span class="check-form-notice">*</span>票据情况</span>
                        <el-select v-model="checkform.bill_status" placeholder="请选择">
                            <el-option v-for="item in billList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="财务确认意见" class="long-input" prop="reason">
                        <el-input v-model="checkform.finance_opinion" type="textarea"  resize="none" placeholder="请输入文字"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            
            <div slot="footer" class="dialog-footer btnpart">
                <el-button class="confirm" type="primary" @click="refuseOk('checkform')">提 交</el-button>
                <el-button class="cancel" @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {addselect} from '@/api/const'
export default {
    name: "adduser",
    props: {
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        is_order:{
            type:Boolean,
        	default:()=>{
        		return true
        	}
        }
    },
    mounted(){
        this.showDialog = this.show
        this.getSelectList()
    },
    data() {
        return {
            billList:[
                {id: 60, name: "已收到票据"},
                {id: 61, name: "未收到票据"},
            ],
            receiveList:[],
            checkform:{
                reason:'',
                get_account:'',
                real_time:''
            },
            showDialog:false,
            title:'财务审批',
            rules: {
                // pay_time: [
                //     { required: true, message: '请选择实际到账时间', trigger: 'change' },
                // ],
                // pay_account: [
                //     { required: true, message: '请选择支出帐户', trigger: 'change' },
                // ],
                // bill_status: [
                //     { required: true, message: '请选择票据情况', trigger: 'change' },
                // ],
            },
        };
    },
    methods: {
        getSelectList(){
            let data = {
                id:''
            }
            addselect(data).then(res=>{
                // this.billList = res.data.option.bill_status
                this.receiveList = res.data.option.pay_account
                localStorage.setItem('x_token',res.data.token)
            })
        },
        cancel(){
            this.$emit('close',false)
            this.clean()
        },
        refuseOk(checkform){
            this.$refs[checkform].validate((valid) => {
                if (valid) {
                    this.checkform.bill_status = this.is_order?59:this.checkform.bill_status
                    this.$emit('examine',this.checkform)
                    this.clean()
                } else {
                    return false;
                }
            });
            
        },
        clean(){
        },
    }
}
</script>

<style lang="scss">
.refuse{
    .long-input{
        min-height: 100px !important;
        .el-textarea__inner{
            min-height: 100px !important;
            font-size: 14px;
            padding: 10 14px !important;
            font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
        }
        .el-input__suffix {
            right: 8px;
            transition: all .3s;
            pointer-events: none;
        } 
        .el-form-item__error{
            top: 103px !important;
        }
    }
    
}
.finance{
    .el-form-item__label{
        min-width: 104px !important;
    }
}
</style>
