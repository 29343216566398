<template>
    <div class="change">
        <el-dialog v-if="showDialog" :visible.sync="showDialog"  class="dialog-element-change center-dialog" @close="closeOutWork" width="1160px" style="overflow: hidden;" top="0">
            <div class="dialog-box">
                <div class="dialog-main">
                    <div class="header fl-between">
                        <span class="title">新建线索客户</span>
                        <span class="close" @click="closeEnterprise"><local-svg iconClass="close" class="icon hover handel-header-icon"></local-svg></span>
                    </div>
                    <div class="custom-mian">
                        <div class="search">
                            <span class="label">{{ type == 1 ? '企业客户信息':'个人客户信息' }}</span>
                            <el-input v-model="search_text" class="input-element-change" style="width: 640px;" ref="inputRef" @focus="showSearchSelect = true" @input="getCustomList" placeholder="请选择或搜索企业名称或纳税人识别号">
                                <span slot="prefix" class="left"><local-svg iconClass="search" class="icon"></local-svg></span>
                            </el-input>
                            <div class="search-select" v-if="showSearchSelect">
                                <div class="search-part">
                                    <div class="search-item hover" v-for="(item,index) in searchList" :key="index" @click="getCustomDetail(item)">
                                        {{ item.Title?item.Title:item.name }}<span v-if="item.Tax_codes || item.social_code">（{{ item.Tax_codes?item.Tax_codes:item.social_code  }}）</span>
                                    </div>
                                </div>
                                <div class="button-part">
                                    <span>没有符合结果？</span>
                                    <button class="confirm hover" @click="chgangeDialog = true">{{ type == 1 ? '添加企业客户':'添加个人客户' }}</button>
                                </div>
                            </div>
                        </div>
                        <div class="info">
                            <div class="info-left">
                                <div class="inline">
                                    <div class="label">公司名称：</div>
                                    <div class="content out-text name" ref="company">
                                        {{info.Former_title?info.Former_title:'/'}}
                                        <el-tooltip placement="bottom" popper-class="popper-element-class" effect="light" v-if="old_name != '-' && old_name">
                                            <div slot="content">
                                                <div class="popper-main">
                                                    <!-- <div class="name-item" v-for="(item,index) in info.old" :key="index">
                                                        {{ item.name }}
                                                        <span class="date">({{ item.date }})</span>
                                                    </div> -->
                                                    <div class="name-item">
                                                        {{ old_name }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="content-old fl-center handel-icon hover">曾用名
                                                <local-svg iconClass="down" class="icon"></local-svg>
                                            </div>
                                        </el-tooltip>
                                     </div>
                                </div>
                                <div class="inline">
                                    <div class="label">统一社会信用代码：</div>
                                    <div class="content out-text">{{info.Unify_codes?info.Unify_codes:'/'}}</div>
                                </div>
                                <div class="inline">
                                    <div class="label">法定代表人：</div>
                                    <div class="content out-text">{{ info.legal_person?info.legal_person:'/' }}</div>
                                </div>
                                <div class="inline">
                                    <div class="label">注册资本/金(万元)：</div>
                                    <div class="content out-text">{{info.Reg_capital?info.Reg_capital:'/'}}</div>
                                </div>
                                <div class="inline">
                                    <div class="label">开业核准日期：</div>
                                    <div class="content out-text">{{info.Approved_date?info.Approved_date:'/'}}</div>
                                </div>
                            </div>
                            <div class="info-right">
                                <div class="inline">
                                    <div class="label">注册号：</div>
                                    <div class="content out-text">{{info.Reg_codes?info.Reg_codes:'/'}}</div>
                                </div>
                                <div class="inline">
                                    <div class="label">联系电话：</div>
                                    <div class="content out-text">
                                        <span class="out-text" ref="phone">{{info.company_phone.length>0?info.company_phone.toString():'/'}}</span>
                                        <el-tooltip placement="bottom" popper-class="popper-element-class" effect="light" v-if="more_phone">
                                            <div slot="content">
                                                <div class="popper-main">{{info.company_phone.toString()}}</div>
                                            </div>
                                            <span class="more hover handel-text">更多</span>
                                        </el-tooltip>
                                    </div>
                                </div>
                                <div class="inline">
                                    <div class="label">所属行业：</div>
                                    <div class="content out-text">
                                        <span class="out-text" ref="industry">{{info.Industry?info.Industry:'/'}}</span>
                                        <el-tooltip placement="bottom" popper-class="popper-element-class" effect="light" v-if="more_industry">
                                            <div slot="content">
                                                <div class="popper-main">{{info.Industry}}</div>
                                            </div>
                                            <span class="more hover handel-text">更多</span>
                                        </el-tooltip>
                                    </div>
                                </div>
                                <div class="inline">
                                    <div class="label">住所/经营场所：</div>
                                    <div class="content">
                                        <span class="out-text" ref="address">{{info.company_address?info.company_address:'/'}}</span>
                                        <el-tooltip placement="bottom" popper-class="popper-element-class" effect="light"  v-if="more_address">
                                            <div slot="content">
                                                <div class="popper-main">{{info.company_address?info.company_address:'/'}}</div>
                                            </div>
                                            <span class="more hover handel-text">更多</span>
                                        </el-tooltip>
                                    </div>
                                </div>
                                <div class="inline">
                                    <div class="label">经营范围：</div>
                                    <div class="content">
                                        <span class="out-text" ref="area">{{ info.Business_scope?info.Business_scope:'/' }}</span>
                                        <el-tooltip placement="bottom" popper-class="popper-element-class" effect="light" v-if="more_area">
                                            <div slot="content">
                                                <div class="popper-main">{{info.Business_scope}}</div>
                                            </div>
                                            <span class="more hover handel-text" >更多</span>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="other">
                            <div class="order-table">
                                <div class="table-header fl-between button-part">
                                    <span>联系方式</span>
                                    <button class="confirm-icon hover" @click="addUser" v-permission="'客户管理-addClueConnect'"><local-svg iconClass="add" class="icon"></local-svg>添加</button>
                                </div>
                                <div class="table table-element-change dialog-table">
                                    <el-table :data="tableData" v-loading="loading" height="100%" style="width: 100%;" ref="attractTable" tooltip-effect="light">
                                        <template slot="empty">
                                            <el-empty :image-size="250" :image="image" description='暂无数据'></el-empty>
                                        </template>
                                        <el-table-column  type='index' width="50" align="center" label="序号"></el-table-column>
                                        <el-table-column width="120" align="left" label="手机号">
                                            <template slot-scope="scope">
                                                <div v-if="!scope.row.is_edit">{{ scope.row.phone }}</div>
                                                <el-input v-else v-model="scope.row.phone" class="input-element-change" placeholder="请输入"></el-input>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="left" label="客户名称">
                                            <template slot-scope="scope">
                                                <div v-if="!scope.row.is_edit">{{ scope.row.name }}</div>
                                                <el-input v-else v-model="scope.row.name" class="input-element-change" placeholder="请输入"></el-input>
                                            </template>
                                        </el-table-column>
                                        <el-table-column props="tel" width="120" align="left" label="职位">
                                            <template slot-scope="scope">
                                                <div v-if="!scope.row.is_edit">{{ scope.row.job?scope.row.job:'-' }}</div>
                                                <el-input v-else v-model="scope.row.job" class="input-element-change" placeholder="请输入"></el-input>
                                            </template>
                                        </el-table-column>
                                        <el-table-column width="120" align="left" label="负责人">
                                            <template slot-scope="scope">
                                                <div v-if="!scope.row.is_edit">{{ scope.row.admin_name }}</div>
                                                <el-select v-else v-model="scope.row.admin_id" placeholder="请选择" clearable filterable  class="select-element-change option-element-change">
                                                    <el-option v-for="item in userList" :label="item.name" :value="item.id" 
                                                        :key="item.id"></el-option>
                                                </el-select>
                                            </template>
                                        </el-table-column>
                                        <el-table-column props="tel" width="100" align="center" label="操作">
                                            <template slot-scope="scope">
                                                <div class="fl-between">
                                                    <span v-if="scope.row.is_edit" class="handel-text hover" @click="confirmTable(scope.row)">确定</span>
                                                    <span v-if="scope.row.is_edit" class="handel-text hover" @click="cancelChange(scope.row)">取消</span>
                                                    <span v-if="!scope.row.is_edit" class="handel-text hover" @click="editChange(scope.row)">编辑</span>
                                                    <span v-if="!scope.row.is_edit" class="handel-text hover" @click="delChange(scope,scope.row)">删除</span>
                                                </div>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                                <div class="number-page-element-change page-change">
                                    <el-pagination
                                        @current-change="handleCurrentChange"
                                        :current-page="currentPage"
                                        :page-size="pageSize"
                                        layout="total, prev, pager, next"
                                        :total="total"
                                        :pager-count="5"
                                        popper-class="select-element-change">
                                    </el-pagination>
                                </div>
                            </div>
                            <div class="process-part">
                                <div class="title"><span>跟进记录</span></div>
                                <div class="process" v-if="porcessList.length>0">
                                    <div class="process-item" v-for="(item,index) in porcessList" :key="index">
                                        <div class="item-header">
                                            <local-svg iconClass="sellchance" class="icon"></local-svg>
                                            <span class="date">{{ item.create_time }}</span>
                                        </div>
                                        <div class="item-info">
                                            <div class="line" v-if="index != porcessList.length - 1"></div>
                                            <div class="main-box">
                                                <div class="name">{{ item.admin }}</div>
                                                <div class="desc">{{ item.follow_content }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="process" v-else>
                                    <div class="none">
                                        <img src="../../assets/image/none.png" alt="">
                                        <div class="tip">暂无跟进记录!</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="button-part">
                            <button class="confirm hover" @click="saveCustom">保存</button>
                            <button class="cancel hover" @click="closeEnterprise">关闭</button>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="chgangeDialog"  class="dialog-element-change center-dialog top-dialog" @close="cancelCustom" style="overflow: hidden;" top="0" width="640px">
            <div class="dialog-box">
                <div class="dialog-main">
                    <div class="header fl-between">
                        <span class="title">客户信息</span>
                        <span class="close" @click="cancelCustom"><local-svg iconClass="close" class="icon hover handel-header-icon"></local-svg></span>
                    </div>
                    <div class="custom-mian">
                        <el-form :form="addform" label-width="" class="form-element-change select-element-change textarea-element-change input-element-change add-custom">
                            <el-form-item label="公司名称">
                                <!-- <span slot="label"><span class="check-form-notice">*</span>公司名称</span> -->
                                <span slot="label"><span class="check-form-notice">*</span>公司名称</span>
                                <el-input v-model="addform.name" placeholder="请输入公司名称" ></el-input>
                            </el-form-item>
                            <el-form-item label="统一社会信用代码">
                                <span slot="label"><span class="check-form-notice">*</span>统一社会信用代码</span>
                                <el-input v-model="addform.social_code" placeholder="请输入统一社会信用代码" ></el-input>
                            </el-form-item>
                            <el-form-item label="法定代表人">
                                <el-input v-model="addform.legal" placeholder="请输入法定代表人" ></el-input>
                            </el-form-item>
                                <el-form-item label="开业核准日期"> <el-date-picker v-model="addform.set_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
                            </el-form-item>
                            <el-form-item label="所属行业">
                                <el-input v-model="addform.industry" placeholder="请输入所属行业" ></el-input>
                            </el-form-item>
                            <el-form-item label="住所/经营场所">
                                <el-input v-model="addform.address" placeholder="请输入地址" ></el-input>
                            </el-form-item>
                            <el-form-item label="注册号">
                                <el-input v-model="addform.res_code" placeholder="请输入地址" ></el-input>
                            </el-form-item>
                            <el-form-item label="联系电话">
                                <el-input v-model="addform.connect" placeholder="请输入联系电话" ></el-input>
                            </el-form-item>
                            <el-form-item label="注册资本/金(万元)">
                                <el-input v-model="addform.res_capital" placeholder="请输入金额" ></el-input>
                            </el-form-item>
                            <el-form-item label="经营范围">
                                <el-input placeholder="请输入经营范围" type="textarea"   v-model="addform.business" style="width: 452px;"></el-input>
                            </el-form-item>
                        </el-form>
                        <div class="button-part" style="margin-top: 21px;">
                            <button class="confirm hover" @click="submitCustom">确定</button>
                            <button class="cancel hover" @click="cancelCustom">取消</button>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { com_customlist,com_addcustom,com_editcustom,com_delcustom,followlist,com_customdetail  } from '@/api/custom'
import { scrm_customlist,scrm_customdetail } from '@/api/scrm_custom'
import { alluser } from '@/api/terrace'
import { forEach } from 'lodash'
export default{
    name:'monthchange',
    props:{
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        form:{
        	type:Object,
        	default:()=>{
        		return {}
        	}
        },
        type:{
            type:Number,
        	default:()=>{
        		return 1
        	}
        }
    },
	data(){
		return{
            loading:false,
            showDialog:false,
            more_area:false,
            more_industry:false,
            more_address:false,
            more_phone:false,
            showSearchSelect: false,
            isDestroyed:false,
            chgangeDialog:false,
            info:{
                Former_title:'',
                old:[],
                Unify_codes:'',
                legal_person:'',
                shareholder:'',
                Reg_capital:'',
                Approved_date:'',
                Reg_codes:'',
                company_phone:[],
                Industry:'',
                company_address:'',
                Business_scope:'',
                id:''
            },
            searchList:[],
            image:require('../../assets/image/none.png'),
            tableData:[ ],
            porcessList:[],
            selectList:[],
            search_text:'',
            currentPage:1,
            total:0,
            pageSize:10,
            search_type:1,
            old_name:'',
            addform:{
                name:'',
                social_code:'',
                legal:'',
                set_time:'',
                industry:'',
                address:'',
                res_code:'',
                connect:'',
                res_capital:'',
                business:'',
            }
        }
	},
    mounted() {
        this.addform = this.form
        this.showDialog = this.show
        document.addEventListener('click', this.handleDocumentClick);
        this.getAllUser()
    },
    beforeDestroy() {  
        this.isDestroyed = true;  
        document.removeEventListener('click', this.handleDocumentClick);
    },
    methods: {
        getAllUser(){
            alluser().then(res=>{
                this.userList = res.data
            })
        },
        getOldName(){
            let data = {
                client_id:this.info.id
            }
            com_customdetail(data).then(res=>{
                this.old_name = res.data.old_name
            })
        },
        getCustomList(e){
            let data = {
                name:e.name?e.name:this.search_text
            }
            com_customlist(data).then(res=>{
                if(res.code == 200) {
                    this.searchList = res.data
                    this.search_type = 1
                    if(e.name){
                        this.getCustomDetail(this.searchList[0])
                        this.$message.success('操作成功！')
                    }
                }else if(res.code == 202){
                    this.getOaCustomList()
                    this.showSearchSelect = true
                }
            })
        },
        getOaCustomList(){
            let data = {
                name:this.search_text,
                token:localStorage.getItem('token')
            }
            scrm_customlist(data).then(res =>{
                this.searchList = res.data.list
                this.search_type = 2
            })
        },
        getFollowList(){
            let data = {
                client_id:this.form.id
            }
            followlist(data).then(res=>{
                this.porcessList = res.data
            })
        },
        getCustomDetail(e){
            if(this.search_type == 1){
                console.log(e)
                this.info.id = e.id
                this.info.Former_title = e.name
                this.info.Unify_codes = e.social_code
                this.info.legal_person = e.legal
                // this.info.shareholder = e.name
                this.info.Reg_capital = e.res_capital
                this.info.Approved_date = e.set_time
                this.info.Reg_codes = e.res_code
                this.info.company_phone = e.connect?e.connect:''
                this.info.Industry = e.industry
                this.info.company_address = e.address
                this.info.Business_scope = e.business
                this.tableData = e.linkman
                this.tableData.forEach(item =>{
                    this.$set(item,'is_edit',false)
                })
                this.total = this.tableData.length
                this.showSearchSelect = false
                this.checkOut()
                this.getFollowList()
                this.getOldName()
            }else{
                let data = {
                    id:e.id,
                    token:localStorage.getItem('token')
                }
                scrm_customdetail(data).then(res=>{
                    this.info = res.data.detail
                    this.info.Former_title = e.Title
                    this.info.company_address = this.info.company_address.Latest_address != '-' && this.info.company_address.Latest_address ? this.info.company_address.Latest_address : this.info.company_address.Address
                    this.tableData = []
                    delete this.info.id
                    this.showSearchSelect = false
                    this.checkOut()
                    this.porcessList = []
                })
            }
        },
        submitCustom(){
            if(!this.addform.name){
                this.$message.error('公司名称不能为空！')
                return
            }
            if(!this.addform.social_code){
                this.$message.error('统一社会信用代码不能为空！')
                return
            }
            this.info.Former_title = this.addform.name
            this.info.Unify_codes = this.addform.social_code
            this.info.legal_person = this.addform.legal
            this.info.Reg_capital = this.addform.res_capital
            this.info.Approved_date = this.addform.set_time
            this.info.Reg_codes = this.addform.res_code
            this.info.company_phone = this.addform.connect ? this.addform.connect : []
            this.info.Industry = this.addform.industry
            this.info.company_address = this.addform.address
            this.info.Business_scope = this.addform.business
            this.$message.success('保存成功！')
            this.chgangeDialog = false
            this.resetChangeform()
        },
        cancelCustom(){
            this.chgangeDialog = false
            this.resetChangeform()
        },
        resetChangeform(){
            this.addform = {
                name:'',
                social_code:'',
                legal:'',
                set_time:'',
                industry:'',
                address:'',
                res_code:'',
                connect:'',
                res_capital:'',
                business:'',
            }
        },
        saveCustom(){
            let list = []
            this.tableData.forEach(item =>{
                let obj = {
                    name:item.name,
                    phone:item.phone,
                    job:item.job,
                    admin_id:item.admin_id,
                }

                if(this.info.id){
                    obj.id = item.id ? item.id :''
                }
                list.push(obj)
            })
            let data = {
                name:this.info.Former_title,
                social_code:this.info.Unify_codes,
                legal:this.info.legal_person,
                set_time:this.info.Approved_date,
                industry:this.info.Industry,
                address:this.info.company_address,
                res_code:this.info.Reg_codes,
                connect:this.info.company_phone,
                res_capital:this.info.Reg_capital,
                business:this.info.Business_scope,
                type:this.type,
                linkman:list
            }
            if(this.info.id){
                data.id = this.info.id
                com_editcustom(data).then(res=>{
                    if(res.code == 200){
                        this.$emit('close')
                        this.resetChangeform(),
                        this.$message.success('编辑成功！')
                    }else{
                        this.$message.error(res.msg)
                    }
                })
            }else{
                com_addcustom(data).then(res=>{
                    if(res.code == 200){
                        this.$emit('close')
                        this.resetChangeform(),
                        this.$message.success('添加成功！')
                    }else{
                        this.$message.error(res.msg)
                    }
                })
            }
        },
        closeEnterprise(){
            this.$emit('close',true)
        },
        checkOut(){
            this.$nextTick(()=>{
                if(this.$refs['area'].scrollWidth > 336){
                    this.more_area = true
                }
                if(this.$refs['industry'].scrollWidth > 336){
                    this.more_industry = true
                }
                if(this.$refs['address'].scrollWidth > 336){
                    this.more_address = true
                }
                if(this.$refs['phone'].scrollWidth > 336){
                    this.more_phone = true
                }
            })
        },
        setAdmin(e){
            let name = ''
            this.userList.forEach(item =>{
                if(item.id == e){
                    name = item.name
                }
            })
            return name
        },
        addUser(){
            // if(!this.info.Former_title){
            //     this.$message.error('请先选择客户！')
            //     return
            // }
            // if(!this.info.Unify_codes){
            //     this.$message.error('请先完善客户信息！')
            //     return
            // }
            this.tableData.push({
                id:'',
                name:'',
                phone:'',
                admin_id:'',
                admin:'',
                job:'',
                is_edit:true,
            })
        },
        handleCurrentChange(val){
            this.currentPage = val
        },
        confirmTable(row){
            row.admin_name = this.setAdmin(row.admin_id)
            row.is_edit = false
        },
        cancelChange(row){
            let data = {
                name:this.info.Former_title
            }
            this.getCustomList(data)
        },
        editChange(row){
            row.is_edit = true
        },
        delChange(scope,row){
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if(row.id){
                    let data = {
                        id:row.id
                    }
                    com_delcustom(data).then(res=>{
                        if(res.code == 200){
                            let data = {
                                name:this.info.Former_title
                            }
                            this.getCustomList(data)
                        }else{
                            this.$message.error(res.msg)
                        }
                    })  
                }else{
                    this.tableData.splice(scope.$index,1)
                }
                
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        handleDocumentClick(event) {  
            if (!this.isDestroyed && !this.showSearchSelect) {  
                return;  
            }  
            const inputElement = this.$refs.inputRef.$el;  
            const searchSelectElement = this.$el.querySelector('.search-select');  
            if (  !inputElement &&  
                !searchSelectElement ||
                !inputElement.contains(event.target) &&  
                !searchSelectElement || !inputElement.contains(event.target) && !searchSelectElement.contains(event.target) 
            ) {  
                this.showSearchSelect = false;  
            }  
        },
        closeOutWork(){},
    },
}

</script>

<style lang="scss" scoped>
.change{
    .dialog-box{
        width:100%;
        height: 94vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        padding-top: 2vh;
        box-sizing: border-box;
        .dialog-main{
            box-sizing: border-box;
            border-radius: 6px;
            width: 100%;
            background: #fff;
            .header{
                width: 100%;
                height: 54px;
                border-bottom: 1px solid #F0F1F5;
                box-sizing: border-box;
                padding: 20px;
                border-radius: 6px 6px 0 0 ;
                .title{
                    font-size: 16px;
                    font-weight: bold;
                    color: #2B313D;
                    line-height: 16px;
                    margin-top: 4px;
                }
                .close{
                    .icon{
                        height: 16px;
                        width: 16px;
                    }
                }
            }
            .custom-mian{
                width: 100%;
                height: auto;
                // min-height: 727px;
                padding: 20px 24px 24px;
                box-sizing: border-box;
                background: #Fff;
                border-radius: 0 0 6px 6px;
                .search{
                    width: 100%;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    .label{
                        font-size: 14px;
                        color: #6B6D77;
                        line-height: 16px;
                        margin-right: 12px;
                    }
                    .search-select{
                        width: 640px;
                        height: auto;
                        box-sizing: border-box;
                        padding: 17px 0 16px;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.1);
                        border-radius: 8px;
                        position: absolute;
                        top: 45px;
                        margin: 0 calc(50% - 96px) 0 50%;
                        .search-part{
                            width: 100%;
                            height: auto;
                            max-height: 160px;
                            overflow-y: auto;
                            .search-item{
                                width: 100%;
                                text-align: left;
                                font-size: 14px;
                                color: #6B6D77;
                                line-height: 30px;
                                box-sizing: border-box;
                                padding: 0 20px;
                                span{
                                    color: #B0B1B9;
                                }
                            }
                            .search-item:hover{
                                background: #EDF3FE;
                            }
                        }
                        .button-part{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 26px;
                            margin-top: 14px;
                            span{
                                font-size: 12px;
                                color: #B0B1B9;
                                line-height: 12px;
                            }
                            button{
                                padding: 0 16px !important;
                                height: 26px;
                                line-height: 26px;
                                margin-left: 14px;
                                font-size: 12px;
                                color: #FFFFFF;
                                line-height: 12px;
                            }
                        }
                    }
                }
                .info{
                    width: 100%;
                    height: auto;
                    background: #FAFAFB;
                    border-radius: 6px;
                    box-sizing: border-box;
                    padding: 14px 20px 18px;
                    margin-top: 23px;
                    display: flex;
                    justify-content: space-between;
                    .info-left{
                        width: 500px;
                        height: auto;
                        .inline{
                            width: 100%;
                            display: flex;
                            align-items: center;
                            .label{
                                width: 128px;
                                color: #B0B1B9;
                                line-height: 28px;
                            }
                            .content{
                                font-size: 14px;
                                color: #474952;
                                line-height: 28px;
                                margin-left: 4px;
                                width: calc(100% - 132px);
                                display: flex;
                                height: 28px;
                                .content-old{
                                    width: 74px;
                                    height: 22px;
                                    background: #FFFFFF;
                                    border-radius: 3px;
                                    border: 1px solid #E2E5EA;
                                    font-size: 12px;
                                    // color: #2C57FF;
                                    line-height: 22px;
                                    text-align: center;
                                    margin-left: 16px;
                                }
                            }
                            .name{
                                width: calc(100% - 212px);
                                .icon{
                                    width: 10px;
                                    height: 5px;
                                    margin-left: 8px;
                                }
                            }
                        }
                    }
                    .info-right{
                        width: 477px;
                        height: auto;
                        .inline{
                            width: 100%;
                            display: flex;
                            align-items: center;
                            .label{
                                width: 104px;
                                color: #B0B1B9;
                                line-height: 28px;
                            }
                            .content{
                                font-size: 14px;
                                color: #474952;
                                line-height: 28px;
                                height: 28px;
                                margin-left: 4px;
                                width: calc(100% - 108px);
                                display: flex;
                                align-items: center;
                                .out-text{
                                    width: 336px;
                                }
                                .more{

                                }
                            }
                        }
                    }
                }
                .other{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    height: 370px;
                    margin-top: 12px;
                    .order-table{
                        width: 770px;
                        height: 100%;
                        .table-header{
                            height: 32px;
                            span{
                                font-weight: bold;
                                font-size: 14px;
                                color: #474952;
                                line-height: 14px; 
                            }
                        }
                        .table{
                            width: 100%;
                            margin-top: 8px;
                            height:calc(100% - 74px);
                        }
                        .page-change{
                            margin-top: 16px;
                        }
                    }
                    .process-part{
                        width: 300px;
                        height: 743px;
                        .title{
                            height: 32px;
                            display: flex;
                            align-items: center;
                            span{
                                font-weight: bold;
                                font-size: 14px;
                                color: #474952;
                                line-height: 14px;
                                margin-top: 1px;
                            }
                        }
                        .process{
                            margin-top: 15px;
                            max-height: 300px;
                            overflow-y: auto;
                            .process-item{
                                height: auto;
                                width: 100%;
                                margin-bottom: 14px;
                                .item-header{
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    .icon{
                                       width: 14px;
                                       height: 14px; 
                                    }
                                    .date{
                                        font-size: 12px;
                                        color: #B0B1B9;
                                        line-height: 12px;
                                        margin-left: 14px;
                                    }
                                }
                                .item-info{
                                    margin-top: 2px;
                                    height: auto;
                                    padding: 0 0 0 29px;
                                    box-sizing: border-box;
                                    position: relative;
                                    .line{
                                        width: 1px;
                                        height: 100%;
                                        background: #F0F1F5;
                                        position: absolute;
                                        left: 8px;
                                    }
                                    .main-box{
                                        width: 100%;
                                        height: auto;
                                        box-sizing: border-box;
                                        padding: 14px;
                                        background: #F3F7FE;
                                        border-radius: 4px;
                                        margin-top:7px;
                                        .name{
                                            font-weight: bold;
                                            font-size: 12px;
                                            color: #474952;
                                            line-height: 12px;
                                        }
                                        .desc{
                                            font-size: 12px;
                                            color: #6B6D77;
                                            line-height: 18px;
                                            margin-top: 6px;
                                        }
                                    }
                                }
                            }
                            .process-item:last-child{
                                margin-bottom: 0 !important;
                            }
                            .none{
                                width: 100%;
                                display: flex;
                                align-items: center;
                                flex-direction: column;
                                .tip{
                                    font-size: 12px;
                                    color: #D0D0D2;
                                    line-height: 14px;
                                    letter-spacing:1px;
                                }
                            }
                        }
                    }
                }
                .button-part{
                    text-align: right;
                    button{
                        margin-left: 10px;
                    }
                }
            }
            .button-part{
                text-align: right;
                box-sizing: border-box;
                width: 100%;
            }
        }
    }
} 
</style>
  