<template>
    <div class="addcontarctback">
        <el-dialog :title="title" :visible.sync="showDialog" width="800px" class="addform" @close="cancel">
            <div class="addform contractbackform ">
                <div class="formbox">
                    <el-form :model="addform" :rules="rules" ref="addform" >
                        <el-row>
                            <el-col :span="11">
                                <el-form-item label="客户名称" prop="cl_id" class="left left-input ">
                                    <span slot="label"><span class="check-form-notice">*</span>客户名称</span>
                                    <el-select filterable v-model="addform.cl_id" placeholder="请选择" @change="getSellList" popper-class="custom-select" clearable>
                                        <el-option v-for="item in client" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="销售机会" prop="s_id" class="left-input">
                                    <span slot="label"><span class="check-form-notice">*</span>销售机会</span>
                                    <el-input v-model="addform.sell" disabled :placeholder="addform.cl_id?'请选择':'请先选择客户'">
                                        <div slot="suffix" style="padding: 0 5px;line-height: 44px;" class="hover" @click="checkSell">
                                            <local-svg iconClass="inputbox" style="width: 1.2rem;height: 1.1rem;"></local-svg>
                                        </div>
                                    </el-input>
                                    <!-- <el-select v-model="addform.s_id" :placeholder="addform.client?'请选择':'请先选择客户'" :disabled="addform.cl_id?false:true">
                                        <el-option v-for="item in sellList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select> -->
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="left  left-input">
                                <el-form-item label="渠道名称">
                                    <el-input v-model="addform.channel" disabled placeholder="请选择销售机会">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="系统来源" class="left-input">
                                    <el-input v-model="addform.source" disabled placeholder="请选择销售机会"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="left  left-input">
                                <el-form-item label="合同管理" prop="ca_id">
                                    <span slot="label"><span class="check-form-notice">*</span>合同管理</span>
                                    <el-input v-model="addform.contract" disabled :placeholder="addform.cl_id?'请选择':'请先选择客户'">
                                        <div slot="suffix" style="padding: 0 5px;line-height: 44px;" class="hover" @click="checkContrat">
                                            <local-svg iconClass="inputbox" style="width: 1.2rem;height: 1.1rem;"></local-svg>
                                        </div>
                                    </el-input>
                                    <!-- <el-select v-model="addform.ca_id" :placeholder="addform.client?'请选择':'请先选择客户'" :disabled="addform.cl_id?false:true"  @change="getSignList">
                                        <el-option v-for="item in contractList" :label="item.number" :value="item.id" :key="item.id"></el-option>
                                    </el-select> -->
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="单项明细" prop="c_s_id">
                                    <span slot="label"><span class="check-form-notice">*</span>单项明细</span>
                                    <el-input v-model="addform.single_name" disabled :placeholder="addform.contract?'请选择':'请先选择合同'">
                                        <div slot="suffix" style="padding: 0 5px;line-height: 44px;" class="hover" @click="checkBusiness">
                                            <local-svg iconClass="inputbox" style="width: 1.2rem;height: 1.1rem;"></local-svg>
                                        </div>
                                    </el-input>
                                    <!-- <el-select v-model="addform.c_s_id" :placeholder="addform.client?'请选择':'请先选择合同'" :disabled="addform.ca_id?false:true">
                                        <el-option v-for="item in signList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select> -->
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="left">
                                <el-form-item label="回款金额(元)" prop="amount">
                                    <span slot="label"><span class="check-form-notice">*</span>回款金额</span>
                                    <el-input v-model="addform.amount" placeholder="请输入" @input="changeBig" @blur="changThousand"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="回款方式" prop="back_type">
                                    <span slot="label"><span class="check-form-notice">*</span>回款方式</span>
                                    <el-select v-model="addform.back_type" placeholder="请选择">
                                        <el-option v-for="item in backtype" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="left">
                                <el-form-item label="大写金额" >
                                    <el-input v-model="addform.amount_yuan" :placeholder="addform.back_type==''?'输入回款金额后可查看':''" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right" >
                                <el-form-item label="回款日期" prop="back_time">
                                    <span slot="label"><span class="check-form-notice">*</span>回款日期</span>
                                    <el-date-picker v-model="addform.back_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="left">
                                <el-form-item label="开始时间" class="left" prop="start_time">
                                    <span slot="label"><span class="check-form-notice">*</span>开始时间</span>
                                    <el-date-picker v-model="addform.start_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="所属部门" class="left" prop="branch_id">
                                    <span slot="label"><span class="check-form-notice">*</span>所属部门</span>
                                    <el-input v-model="form.branch_name" disabled v-if="type==2&&addform.type!=42"></el-input>
                                    <el-select v-else v-model="addform.branch_id" placeholder="请选择">
                                        <el-option v-for="item in branchList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11"  v-if="type==2" class="left">
                                <el-form-item label="回款审核状态" class="left">
                                    <el-select v-model="addform.type" placeholder="请选择" disabled>
                                        <el-option v-for="item in typeList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11"  v-if="type==2" class="right">
                                <el-form-item label="拒绝原因" class="left">
                                    <el-input v-model="addform.refuse" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11"  v-if="addform.type==46" class="left">
                                <el-form-item label="实际到账时间" class="left">
                                    <span slot="label"><span class="check-form-notice">*</span>实际到账时间</span>
                                    <el-date-picker v-model="addform.real_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" :picker-options="pickerOptions"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11"  v-if="addform.type==46" class="right">
                                <el-form-item label="到款账户" class="left">
                                    <span slot="label"><span class="check-form-notice">*</span>到款账户</span>
                                    <el-select v-model="addform.get_account_id " placeholder="请选择">
                                        <el-option v-for="item in backAccountList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="11">
                                <el-form-item label="结束时间" class="left" prop="end_time">
                                    <span slot="label"><span class="check-form-notice">*</span>结束时间</span>
                                    <el-date-picker v-model="addform.end_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
                                </el-form-item>
                                <el-form-item label="回款摘要" class="left" prop="summary">
                                    <span slot="label"><span class="check-form-notice">*</span>回款摘要</span>
                                    <el-input v-model="addform.summary" placeholder="请输入"></el-input>
                                </el-form-item>
                                <el-form-item label="备注" class="left">
                                    <el-input v-model="addform.remark" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="" class="long-input btnpart uploadfile"  prop="file">
                                    <span slot="label"><span class="check-form-notice">*</span>图片</span>
                                    <div class="file">   
                                        <div class="filepart">
                                            <el-upload
                                                class="upload-demo"
                                                :http-request="uploadFile"
                                                action=""
                                                >
                                                <el-button class="uploadbtn"><local-svg iconClass="upload" class="icon" plain></local-svg>上传图片</el-button>
                                            </el-upload>
                                            <div class="filetip"><span style="color: #F74B4B;">最多上传9张</span></div>
                                            <div class="filesize">支持扩展名：.jpg .jpeg .png，大小不超过9M</div>
                                        </div>
                                    </div>
                                </el-form-item>
                            </el-col>  
                            <el-col :span="11" class="right">
                                <div class="filelist">
                                    <div class="leftpart">
                                        <div class="left-btn hover change" @click="lastFile" v-show="actIndex!=0">
                                            <local-svg iconClass="swipericon" class="lefticon"></local-svg>
                                        </div>
                                    </div>
                                    <div class="fileline" v-for="(item,index) in showFile" :key="index">
                                        <div class="file-box">
                                            <img :src="item.img_url" alt="" class="image hover" @click="seeImageList(index)">
                                            <div class="close hover" @click.stop="delFile(index)">
                                                <local-svg iconClass="close" class="closeicon"></local-svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="rightpart">
                                        <div class="right-btn hover change" @click="nextFile" v-show="fileList.length>4&&actIndex+4!=fileList.length">
                                            <local-svg iconClass="swipericon" class="righticon"></local-svg>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </div>
            <div slot="footer" class="dialog-footer btnpart">
                <el-button v-if="type==1" class="confirm" type="primary" @click="addOk('addform','1')">保存并提交</el-button>
                <el-button v-if="type==1" class="savebtn" type="primary" @click="addOk('addform','2')">保存</el-button>
                <el-button v-if="type==2" class="confirm" type="primary" @click="edit('addform','1')">保存并提交</el-button>
                <el-button v-if="type==2" class="savebtn" type="primary" @click="edit('addform','2')">保存</el-button>
                <el-button class="cancel" @click="cancel">取消</el-button>
            </div>
        </el-dialog>
        <showImage :previewImg="imageData"></showImage>
        <SellDialog :showdialog="sellDialog" v-if="sellDialog" :id="addform.cl_id" @cancel="closeSell" :actuser="addform.s_id" @add="getSell" :actname="addform.sell"></SellDialog>
        <ContractDialog :showdialog="contratDialog" v-if="contratDialog" :id="addform.cl_id" @cancel="closeContrat" :actname="addform.contract" :actuser="addform.ca_id" @add="getContrat"></ContractDialog>
        <BusinessDialog :showdialog="businessDialog" v-if="businessDialog" @canael="closeDetail" :id="addform.ca_id" :clent_id="addform.cl_id" @cancel="closeBusiness" :actname="addform.single_name" :actuser="addform.c_s_id" @add="getBusiness"></BusinessDialog>
    </div>
</template>

<script>
import {getaddselect} from '@/api/contanctback'
import md5 from 'js-md5'
import service from "@/utils/request.js";
import showImage from '@/components/showImage.vue'
import { Loading } from 'element-ui';
import SellDialog from '@/components/adddialog/sell.vue'
import ContractDialog from '@/components/adddialog/contract.vue'
import BusinessDialog from '@/components/adddialog/detail.vue'
export default {
    components: { 
        showImage,
        SellDialog,
        ContractDialog,
        BusinessDialog
    },
    name: "adduser",
    props: {
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        form:{
            type:Object,
        	default:()=>{
        		return false
        	}
        },
        type:{
            type:Number,
            default:() =>{
                return 1
            }
        },
        client:{
            type:Array,
            default:() =>{
                return []
            }
        },
        backtype:{
            type:Array,
            default:() =>{
                return []
            }
        },
    },
    data() {
        return {
            businessDialog:false,
            sellDialog:false,
            contratDialog:false,
            imageData:{
                imgList:[], // 所有图片数组
                index: 0, // 当前点击的图片的索引
                infinite: true,  // 是否可以循环切换
                popup: false // 弹窗的显示隐藏
            },
            uploadUrl:process.env.VUE_APP_BASE_URL+'/common/uploadImg',
            image:require('../../assets/image/none.png'),
            list:[],
            actIndex:0,
            tabList:[
                {name:'基本信息'},
                {name:'单向明细'}
            ],
            fileList:[],
            showFile:[],
            branchList:[],
            backAccountList:[],
            addform:{
                audit_status:42,
                client:''
            },
            typeList:[
                {id: 42, name: "未提审"},
                {id: 43, name: "审核中"},
                {id: 44, name: "审核通过"},
                {id: 45, name: "审核拒绝"},
                {id: 46, name: "财务已确认"}
            ],
            sellList:[],
            contractList:[],
            signList:[],
            title:'新建回款记录',
            showDialog:false,
            rules: {},
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            }
        };
    },
    mounted(){
        this.showDialog = this.show
        this.getBranchList()
        if(this.form.id){
            if(this.type == 2 ){
                this.addform = JSON.parse(JSON.stringify(this.form))
                this.getEditSellList()
                this.changThousand()
                this.changeBig()
                this.addform.single_name = this.form.single[0].name
                this.fileList = this.form.imgArr
                this.showFile = this.fileList.slice(0,4)
                this.addform.back_type = this.addform.back_type_id
                this.addform.back_time = this.$date1(this.form.back_time).split(' ')[0]
                this.title = '编辑回款记录'
            }else{
                this.addform.cl_id = Number(this.form.id)
                this.addform.sell = this.form.sell
                this.addform.s_id = Number(this.form.s_id)
                this.addform.contract = this.form.contract
                this.addform.channel = this.form.channel
                this.addform.source = this.form.source
                this.addform.ca_id = Number(this.form.ca_id)
            }
        }
        this.addOk = this.$debounce(this.addOk )
        this.edit = this.$debounce(this.edit )
    },
    methods: {
        changThousand(){
            this.addform.amount = Number(this.addform.amount.replace(/,/g, "")).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 })
        },
        foucsMoney(){
            this.addform.amount = Number(this.addform.amount.replace(/,/g, ""))
        },
        closeDetail(){
            this.businessDialog = false
        },
        getBusiness(msg){
            this.addform.c_s_id = msg.id
            this.addform.single_name = msg.name
            this.businessDialog = false
        },
        closeBusiness(){
            this.businessDialog = false
        },
        checkBusiness(){
            if(this.addform.ca_id){
               this.businessDialog = true 
            }else{
                this.$message.error('请先选择合同单号')
            }
        },
        getContrat(msg){
            this.addform.ca_id = msg.id
            this.addform.contract = msg.name
            this.contratDialog = false
        },
        closeContrat(){
            this.contratDialog = false
        },
        checkContrat(){
            if(this.addform.cl_id){
               this.contratDialog = true 
            }else{
                this.$message.error('请先选择客户名称')
            }
        },
        getSell(msg){
            this.addform.s_id = msg.id
            this.addform.sell = msg.name
            this.addform.channel = msg.channel ? msg.channel : '暂无'
            this.addform.source = msg.source
            this.sellDialog = false
        },
        closeSell(){
            this.sellDialog = false
        },
        checkSell(){
            if(this.addform.cl_id){
               this.sellDialog = true 
            }else{
                this.$message.error('请先选择客户名称')
            }
        },
        seeImageList(index){
            this.imageData.popup = true
            this.imageData.imgList = this.fileList
            this.imageData.index = this.actIndex + index
        },
        getBranchList(){
            let data = {
                client_id:'',
                contract_id:''
            }
            getaddselect(data).then(res=>{
                this.branchList = res.data.option.branch_id
                if(this.branchList.length==1&&this.type==1){
                    this.addform.branch_id = this.branchList[0].id
                }
            })
        },
        getEditSellList(){
            let data = {
                client_id:this.addform.cl_id?this.addform.cl_id:'',
                contract_id:this.addform.ca_id?this.addform.ca_id:''
            }
            getaddselect(data).then(res=>{
                this.signList = res.data.option.single
                this.sellList = res.data.option.sell
                this.contractList = res.data.option.contract
                this.backAccountList = res.data.option.get_account
            })
        },
        changeBig(){
            if(this.addform.amount == ''){
                this.addform.amount_yuan = ''
                return
            }
            let number = Number(this.addform.amount.replace(/,/g, ""))
            console.log(number)
            if(number && number>=0){
                this.addform.amount_yuan = this.NumberToChinese(number)
            }else if(number && number<0){
                this.addform.amount_yuan ='负' + this.NumberToChinese(Number(number)*-1)
            }
        },
        nextFile(){
            this.actIndex = this.actIndex + 1
            this.showFile = this.fileList.slice(this.actIndex,this.actIndex + 4)
        },
        lastFile(){
            this.actIndex = this.actIndex - 1
            this.showFile = this.fileList.slice(this.actIndex,this.actIndex + 4)
        },
        getSignList(){
            let data = {
                client_id:this.addform.cl_id?this.addform.cl_id:'',
                contract_id:this.addform.ca_id?this.addform.ca_id:''
            }
            getaddselect(data).then(res=>{
                this.signList = res.data.option.single
                // this.addform.c_s_id = ''
            })
        },
        getSellList(){
            let data = {
                client_id:this.addform.cl_id?this.addform.cl_id:'',
                contract_id:this.addform.ca_id?this.addform.ca_id:''
            }
            getaddselect(data).then(res=>{
                this.sellList = res.data.option.sell
                this.contractList = res.data.option.contract
                // this.addform.s_id = ''
                // this.addform.ca_id = ''
                // this.addform.c_s_id = ''
            })
        },
        getDetailList(row,index){
            let data = {
                id:row.business_type
            }
            selectlist(data).then(res=>{
                row.detailList = res.data.option.detail
            })
        },
        delFile(index){
            this.fileList.splice(this.actIndex+index,1)
            this.actIndex = this.actIndex==0?this.actIndex:this.actIndex-1
            this.showFile = this.fileList.slice(this.actIndex,this.actIndex + 4)
        },
        uploadFile(obj){
            console.log(obj)
            let fileObj = obj.file
            const { size, name } = fileObj
            let md5code = md5(name + new Date().getTime())
            let length = 500000
            let start = 0
            let end = 0
            let i = 1;
            Loading.service({
                text: "上传中...."
            });
            this.testHandle(md5code, length, start, end, size, name, fileObj, i)
        },
        testHandle(md5code, length, start, end, size, name, fileObj, i) {
            if (length > size) {
                length = size
            }
            if (end < size) {
                end = start + length
                if (end > size) {
                    end = size
                }
                let blob = ""
                blob = fileObj.slice(start, end)
                start = end
                let fd = new FormData()
                fd.append("file", blob)
                fd.append("md5code", md5code)
                let suc = 2
                if (end == size) {
                    suc = 1
                }
                fd.append("suc", suc)
                fd.append("tmp", i)
                fd.append("name", name)
                i = i + parseInt(1)
                service({
                    url: "/common/uploadImg",
                    method: "post",
                    data: fd,
                }).then(res => {
                    if (res.code == 200) {
                        if (suc == 2) {
                            this.testHandle(md5code, length, start, end, size, name, fileObj, i)
                        } else {
                            this.$message.success(res.msg)
                            Loading.service().close()
                            if (res.data.url !== undefined) {
                                this.fileList.push({ name: name, img_url: res.data.url })
                                if(this.showFile.length<4){
                                    this.showFile.push({ name: name, img_url: res.data.url })
                                }
                            }
                        }
                    } else {
                        this.$message.error(res.msg)
                        Loading.service().close()
                    }
                })
                .catch(err => {
                    this.$message.error("请重新上传")
                    Loading.service().close()
                })
            }
        },
        delRow(row,index){
            this.addform.single.splice(index,1)
        },
        addDetail(){
            this.addform.single.push({
                business_type:'',
                business_detail:'',
                amount:'',
                detailList:[],
            })
        },
        changTab(index){
            this.actIndex = index
        },
        addOk(addform,type){
            this.$refs[addform].validate((valid) => {
                if (valid) {
                    if(type==2){
                        this.addform.audit_status=42
                    }else{
                        this.addform.audit_status=43
                    }
                    this.addform.files = this.fileList
                    let data = JSON.parse(JSON.stringify(this.addform))
                    this.$emit('add',data)
                } else {
                    return false;
                }
            });
            
        },
        edit(addform,index){
            this.$refs[addform].validate((valid) => {
                if (valid) {
                    this.addform.audit_status = index==1?43:42
                    this.addform.files = this.fileList
                    let data = JSON.parse(JSON.stringify(this.addform))
                    this.$emit('edit',data)
                } else {
                    return false;
                }
            });
        },
        cancel(){
            this.$emit('cancel',false)
        },
        clean(){
            
        },
        NumberToChinese(money) {
            var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');	 //汉字的数字
            var cnIntRadice = new Array('', '拾', '佰', '仟'); //基本单位
            var cnIntUnits = new Array('', '万', '亿', '兆');  //对应整数部分扩展单位
            var cnDecUnits = new Array('角', '分', '毫', '厘'); //对应小数部分单位
            var cnInteger = '整';	//整数金额时后面跟的字符
            var cnIntLast = '元';	//整数完以后的单位
            //最大处理的数字
            var maxNum = 999999999999999.9999;
            var integerNum;	 //金额整数部分
            var decimalNum;	 //金额小数部分
            //输出的中文金额字符串
            var chineseStr = '';
            var parts;		//分离金额后用的数组，预定义
            if (money == '') { return ''; }
        
            money = parseFloat(money);
            if (money >= maxNum) {
                //超出最大处理数字
                return '超出最大处理数字';
            }
            if (money == 0) {
                chineseStr = cnNums[0] + cnIntLast + cnInteger;
                return chineseStr;
            }
            //四舍五入保留两位小数,转换为字符串
            money = Math.round(money * 100).toString();
            integerNum = money.substr(0,money.length-2);
            decimalNum = money.substr(money.length-2);
            if(decimalNum.length == 1){
                decimalNum = '0' + decimalNum
            }
            //获取整型部分转换
            if (parseInt(integerNum, 10) > 0) {
                var zeroCount = 0;
                var IntLen = integerNum.length;
                for (var i = 0; i < IntLen; i++) {
                    var n = integerNum.substr(i, 1);
                    var p = IntLen - i - 1;
                    var q = p / 4;
                    var m = p % 4;
                    if (n == '0') {
                        zeroCount++;
                    } else {
                        if (zeroCount > 0) {
                            chineseStr += cnNums[0];
                        }
                        //归零
                        zeroCount = 0;
                        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                    }
                    if (m == 0 && zeroCount < 4) {
                        chineseStr += cnIntUnits[q];
                    }
                }
                chineseStr += cnIntLast;
            }
            //小数部分
            if (decimalNum != '') {
                var decLen = decimalNum.length;
                for (var i = 0; i < decLen; i++) {
                    var n = decimalNum.substr(i, 1);
                    if (n != '0') {
                        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                    }
                }
            }
            if (chineseStr == '') {
                chineseStr += cnNums[0] + cnIntLast + cnInteger;
            } else if (decimalNum == '' || /^0*$/.test(decimalNum)) {
                chineseStr += cnInteger;
            }
            return chineseStr;
        }
    }
}
</script>

<style lang="scss">
.addcontarctback {
    .headtab{
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 15px 0 0 0 ;
        .tab-box{
            display: flex;
            justify-content: space-between;
            width: 132px;
            font-size: 14px;
            border-bottom: 1px solid #F0F1F5;
            box-sizing: content-box;
            height: 25px;
            .tab{
                color: #B0B1B9;
                height: 100%;
            }
            .tab:hover{
                color: #2C57FF;
            }
            .acttab{
                font-weight: bold !important;
                color: #6B6D77 !important;
                border-bottom:2px solid #2C57FF !important;
                width: auto;
                height: 100%;
            }
        }
    }
    .right{
        margin-left: 45px;
        .filelist{
            width: 100%;
            height: 95px;
            overflow-y: auto;
            padding: 15px 0px 0 0px ;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .fileline{
                .file-box{
                    width: 70px;
                    height: 70px;
                    position: relative;
                    margin-right: 8px;
                    .image{
                        width: 100%;
                        height: 100%;
                        border-radius: 4px;
                    }
                    .close{
                        position: absolute;
                        right: -7px;
                        top: -7px;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background: #E6E6E6;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .closeicon{
                            width: 8px;
                            height: 8px;
                            fill: #B0B1B9;
                        }
                    }
                    .close:hover{
                        background: rgba(44, 87, 255, 0.2) !important;
                        .closeicon{
                            fill: #2C57FF !important;
                        }
                    }
                }
            }
            .left-btn{
                width: 20px;
                height: 32px;
                border-radius: 4px 0px 0px 4px;
                background: rgba(0,0,0,0.2);
                display: flex;
                align-items: center;
                justify-content: center;
                // margin-right: 4px;
                .lefticon{
                    width: 7px;
                    height: 12px;
                }
            }
            .right-btn{
                width: 20px;
                height: 32px;
                border-radius: 0px 4px 4px 0px;
                background: rgba(0,0,0,0.2);
                display: flex;
                align-items: center;
                justify-content: center;
                // margin-left: -4px;
                .righticon{
                    width: 7px;
                    height: 12px;
                    transform: rotate(180deg);
                }
            }
            .change:hover{
                background: rgba(0,0,0,0.3) !important;
            }
            .leftpart{
                width: 20px;
                margin-right: 4px;
            }
            .rightpart{
                width: 20px;
                margin-left:-4px;
            }
        }
    }
    .file{
        width: 100%;
        height: auto;
            .filepart{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .filetip{
                font-size: 10px;
                color: #D0D0D2;
                margin-left: 8px;
                box-sizing: border-box;
                    padding-top: 8px;
                }
            .icon{
                margin-right: 7px;
            }
            .filesize{
                width: 100%;
                height: 12px !important;
                line-height: 12px;
                font-size: 12px;
                text-align: left;
                color: #D0D0D2;
            }
        }
    }
    .formbox{
        height: auto;
        .el-select .el-input .el-select__caret{
            display: flex; 
            justify-content: center;
            align-items: center;
        }
        .table-main{
            padding: 14px 0 ;
            box-sizing: border-box;
            .table-title{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                .title{
                    font-weight: bold;
                    color: #6B6D77;
                }
                .addpart{
                    .icon{
                        fill: #475B93 !important;
                    }
                }
                .addpart:hover{
                    color: #2C57FF !important;
                    .svg-icon{
                        fill: #2C57FF !important;
                    }
                }
                .addpart:active{
                    color: #133CDC !important;
                    .svg-icon{
                        fill: #133CDC !important;
                    }
                }
            }
            .table{
                margin-top: 12px;
            }
        }
    }
    .dialog-footer{
    }
}
.uploadfile{
    .el-form-item__error{
        padding-top: 3px !important;
    }
}
.handlerTab_tooltip{
    border: none !important;
        box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
        color: #6B6D77 !important;
        padding: 8px 16px !important;
        line-height: 20px !important;
}
.handlerTab_tooltip.is-light{
    border: none !important;
}
.handlerTab_tooltip .popper__arrow {
    /* 上方箭头 */
    border-top-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
    /* 下方箭头 */
    border-bottom-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
}
</style>
