<template>
    <div class="detail">
        <div class="top-banner">
            <div class="background"
                :style="{ 'background': 'url(' + image + ') no-repeat', 'backgroundSize': '1012px 100%', 'backgroundPosition': 'right', 'opacity': '0.9' }">
                <div class="banner-left">
                    <div style="display: flex;">
                        <div class="ltitle">{{ info.name }}</div>
                        <div v-if="info.stage === '赢单'" class="yd">
                            <img src="@/assets/image/lvb.png" alt="" style="margin-right: 6px;">
                            {{ info.stage }}
                        </div>
                        <div v-if="info.stage === '初步接洽'" class="chengd">
                            <img src="@/assets/image/chengb.png" alt="" style="margin-right: 6px;">
                            {{ info.stage }}
                        </div>
                        <div v-if="info.stage === '谈判审核'" class="zid">
                            <img src="@/assets/image/zib.png" alt="" style="margin-right: 6px;">
                            {{ info.stage }}
                        </div>
                        <div v-if="info.stage === '需求确定'" class="land">
                            <img src="@/assets/image/lanb.png" alt="" style="margin-right: 6px;">
                            {{ info.stage }}
                        </div>
                    </div>
                    <div style="font-size: 14px;color: #6B6D77;">
                        <span style="color:#B0B1B9 ;">负责人：&nbsp;&nbsp;</span>{{ info.admin_id
                        }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span style="color:#B0B1B9 ;">预计成交日期：&nbsp;&nbsp;</span>{{ info.plan_clinch_time }}
                    </div>
                </div>
                <div class="banner-right">
                    <button class="print hover" @click="generateContract">
                        <local-svg iconClass="generate"></local-svg>
                        <span>生成合同</span>
                    </button>
                    <button class="btn hover" @click="goBack">
                        <local-svg iconClass="back"></local-svg>
                        <span>返回上一页</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="main auto-detail-part">
                <div class="descript">
                    <el-descriptions title="详细资料" :column="2" border :labelStyle="LS" :contentStyle="CS">
                        <el-descriptions-item label="客户名称">{{ info.client ? info.client : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="客户需求">{{ info.client_need ? info.client_need : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="预计成交金额">{{ info.plan_amount ? Number(info.plan_amount).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="所属部门">{{ info.branch ? info.branch : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="创建人">{{ info.create_id ? info.create_id : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="创建时间">{{ info.create_time ? info.create_time : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="意向产品">{{ info.goods ? info.goods : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="来源">{{ info.source ? info.source : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="渠道名称" v-if="info.source_id == 23">{{ info.channel ? info.channel.title : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="备注" v-if="info.source_id == 23">{{ info.remark ? info.remark : '/'
                        }}</el-descriptions-item>
                        
                        <el-descriptions-item label="备注" :span="2" v-if="info.source_id != 23">{{ info.remark ? info.remark : '/'
                        }}</el-descriptions-item>
                    </el-descriptions>
                </div>

            </div>
            <div v-if="info.stage === '初步接洽'" class="speed">
                <div class="speedbox">
                    <div class="one">
                        <img src="@/assets/image/pass.png" alt="">
                        <!-- <div class="baiqiu"></div> -->
                        <div
                            style="width: 1px;height: 70px;background-color: #E2E5EA;position: absolute;left: 8px;top: 15.5px;">
                        </div>
                    </div>
                    <div style="margin-left: 10px;color: #2C57FF;">
                        <div class="speedtitle">初步接洽</div>
                        <div class="speedspan">已完成</div>
                    </div>
                </div>
                <div class="speedbox">
                    <div class="one">
                        <!-- <img src="@/assets/image/pass.png" alt=""> -->
                        <div class="baiqiu"></div>

                        <div
                            style="width: 1px;height: 70px;background-color: #E2E5EA;position: absolute;left: 8px;top: 15.5px;">
                        </div>
                    </div>
                    <div style="margin-left: 10px;">
                        <div style="color: #2C57FF;" class="speedtitle">需求确定</div>
                        <div style="color: #2C57FF;" class="speedspan">进行中</div>
                    </div>
                </div>
                <div class="speedbox">
                    <div class="one">
                        <!-- <img src="@/assets/image/pass.png" alt=""> -->
                        <div class="baiqiu"></div>

                        <div
                            style="width: 1px;height: 70px;background-color: #E2E5EA;position: absolute;left: 8px;top: 15.5px;">
                        </div>
                    </div>
                    <div style="margin-left: 10px;">
                        <div class="speedtitle">谈判审核</div>
                        <div class="speedspan">未启动</div>
                    </div>
                </div>
                <div class="speedbox">
                    <div class="one">
                        <!-- <img src="@/assets/image/pass.png" alt=""> -->
                        <div class="baiqiu"></div>

                        <!-- <div
                            style="width: 1px;height: 70px;background-color: #E2E5EA;position: absolute;left: 8px;top: 15px;">
                        </div> -->
                    </div>
                    <div style="margin-left: 10px;">
                        <div class="speedtitle">赢单</div>
                        <div class="speedspan">未启动</div>
                    </div>
                </div>
            </div>
            <div v-if="info.stage === '需求确定'" class="speed">
                <div class="speedbox">
                    <div class="one">
                        <img src="@/assets/image/pass.png" alt="">
                        <!-- <div class="baiqiu"></div> -->
                        <div
                            style="width: 1px;height: 70px;background-color: #E2E5EA;position: absolute;left: 8px;top: 15.5px;">
                        </div>
                    </div>
                    <div style="margin-left: 10px;color: #2C57FF;">
                        <div class="speedtitle">初步接洽</div>
                        <div class="speedspan">已完成</div>
                    </div>
                </div>
                <div class="speedbox">
                    <div class="one">
                        <img src="@/assets/image/pass.png" alt="">
                        <!-- <div class="baiqiu"></div> -->

                        <div
                            style="width: 1px;height: 70px;background-color: #E2E5EA;position: absolute;left: 8px;top: 15.5px;">
                        </div>
                    </div>
                    <div style="margin-left: 10px;">
                        <div class="speedtitle">需求确定</div>
                        <div class="speedspan">已完成</div>
                    </div>
                </div>
                <div class="speedbox">
                    <div class="one">
                        <!-- <img src="@/assets/image/pass.png" alt=""> -->
                        <div class="baiqiu"></div>

                        <div
                            style="width: 1px;height: 70px;background-color: #E2E5EA;position: absolute;left: 8px;top: 15.5px;">
                        </div>
                    </div>
                    <div style="margin-left: 10px;">
                        <div style="color: #2C57FF;" class="speedtitle">谈判审核</div>
                        <div style="color: #2C57FF;" class="speedspan">进行中</div>
                    </div>
                </div>
                <div class="speedbox">
                    <div class="one">
                        <!-- <img src="@/assets/image/pass.png" alt=""> -->
                        <div class="baiqiu"></div>

                        <!-- <div
                            style="width: 1px;height: 70px;background-color: #E2E5EA;position: absolute;left: 8px;top: 15px;">
                        </div> -->
                    </div>
                    <div style="margin-left: 10px;">
                        <div class="speedtitle">赢单</div>
                        <div class="speedspan">未启动</div>
                    </div>
                </div>
            </div>
            <div v-if="info.stage === '谈判审核'" class="speed">
                <div class="speedbox">
                    <div class="one">
                        <img src="@/assets/image/pass.png" alt="">
                        <!-- <div class="baiqiu"></div> -->
                        <div
                            style="width: 1px;height: 70px;background-color: #E2E5EA;position: absolute;left: 8px;top: 15.5px;">
                        </div>
                    </div>
                    <div style="margin-left: 10px;color: #2C57FF;">
                        <div class="speedtitle">初步接洽</div>
                        <div class="speedspan">已完成</div>
                    </div>
                </div>
                <div class="speedbox">
                    <div class="one">
                        <img src="@/assets/image/pass.png" alt="">
                        <!-- <div class="baiqiu"></div> -->

                        <div
                            style="width: 1px;height: 70px;background-color: #E2E5EA;position: absolute;left: 8px;top: 15.5px;">
                        </div>
                    </div>
                    <div style="margin-left: 10px;">
                        <div class="speedtitle">需求确定</div>
                        <div class="speedspan">已完成</div>
                    </div>
                </div>
                <div class="speedbox">
                    <div class="one">
                        <img src="@/assets/image/pass.png" alt="">
                        <!-- <div class="baiqiu"></div> -->

                        <div
                            style="width: 1px;height: 70px;background-color: #E2E5EA;position: absolute;left: 8px;top: 15.5px;">
                        </div>
                    </div>
                    <div style="margin-left: 10px;">
                        <div class="speedtitle">谈判审核</div>
                        <div class="speedspan">已完成</div>
                    </div>
                </div>
                <div class="speedbox">
                    <div class="one">
                        <!-- <img src="@/assets/image/pass.png" alt=""> -->
                        <div class="baiqiu"></div>

                        <!-- <div
                            style="width: 1px;height: 70px;background-color: #E2E5EA;position: absolute;left: 8px;top: 15px;">
                        </div> -->
                    </div>
                    <div style="margin-left: 10px;">
                        <div style="color: #2C57FF;" class="speedtitle">赢单</div>
                        <div style="color: #2C57FF;" class="speedspan">进行中</div>
                    </div>
                </div>
            </div>
            <div v-if="info.stage === '赢单'" class="speed">
                <div class="speedbox">
                    <div class="one">
                        <img src="@/assets/image/pass.png" alt="">
                        <!-- <div class="baiqiu"></div> -->
                        <div
                            style="width: 1px;height: 70px;background-color: #E2E5EA;position: absolute;left: 8px;top: 15.5px;">
                        </div>
                    </div>
                    <div style="margin-left: 10px;color: #2C57FF;">
                        <div class="speedtitle">初步接洽</div>
                        <div class="speedspan">已完成</div>
                    </div>
                </div>
                <div class="speedbox">
                    <div class="one">
                        <img src="@/assets/image/pass.png" alt="">
                        <!-- <div class="baiqiu"></div> -->

                        <div
                            style="width: 1px;height: 70px;background-color: #E2E5EA;position: absolute;left: 8px;top: 15.5px;">
                        </div>
                    </div>
                    <div style="margin-left: 10px;">
                        <div class="speedtitle">需求确定</div>
                        <div class="speedspan">已完成</div>
                    </div>
                </div>
                <div class="speedbox">
                    <div class="one">
                        <img src="@/assets/image/pass.png" alt="">
                        <!-- <div class="baiqiu"></div> -->

                        <div
                            style="width: 1px;height: 70px;background-color: #E2E5EA;position: absolute;left: 8px;top: 15.5px;">
                        </div>
                    </div>
                    <div style="margin-left: 10px;">
                        <div class="speedtitle">谈判审核</div>
                        <div class="speedspan">已完成</div>
                    </div>
                </div>
                <div class="speedbox">
                    <div class="one">
                        <img src="@/assets/image/pass.png" alt="">
                        <!-- <div class="baiqiu"></div> -->

                        <!-- <div
                            style="width: 1px;height: 70px;background-color: #E2E5EA;position: absolute;left: 8px;top: 15px;">
                        </div> -->
                    </div>
                    <div style="margin-left: 10px;">
                        <div class="speedtitle">赢单</div>
                        <div class="speedspan">已完成</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {}
            }
        },
        readDetail:{
            type:Number,
            default: () => {
                return 0
            }
        }
    },
    methods: {
        goBack() {
            // if(this.readDetail == 1){
            //     this.$router.go(-1)
            // }else{
                this.$emit('back', false)
            // }
        },
        generateContract(){
            let data = {
                id:this.info.id,
                client_id:this.info.client_id,
                sell_name:this.info.name,
                channel : this.info.channel ? this.info.channel :'暂无',
                source : this.info.source,
                type:'generate'
            }
            this.$router.push({
                path:'/contract',
                query:data
            })
        },
    },
    mounted(){
        this.generateContract = this.$debounce(this.generateContract )
    },
    data() {
        return {
            // image: require('../../assets/image/detail.png'),
            image: require('@/assets/image/detail.png'),
            LS: {
                "word-break": "keep-all"
            },
            CS: {
                "max-width": "100px",
                "word-break": "break-all"
            }

        }
    }
}
</script>
<style lang="scss" scoped>
.detail {
    .top-banner {
        width: 100%;
        height: 112px;
        border-radius: 4px;
        background: linear-gradient(90deg, #FFF 0%, #F0F4FE 50%, #F0F4FE 100%);
        display: flex;
        justify-content: flex-end;

        .background {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 26px 30px;
            display: flex;
            justify-content: space-between;

            .banner-left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .title {
                    font-size: 22px;
                    font-weight: bold;
                    color: #474952;
                    max-width: 570px;
                    display: flex;
                    align-items: center;

                    .tip {
                        width: 38px;
                        height: 24px;
                        line-height: 24px;
                        color: #FFF;
                        font-size: 14px;
                        border-radius: 4px;
                        background: #2C57FF;
                        margin-left: 10px;
                        text-align: center;
                        font-weight: normal;
                    }
                }

                .message {
                    display: flex;

                    .line {
                        font-size: 14px;
                        color: #C4C5CB;
                        margin-right: 20px;

                        span {
                            color: #7D7F88;
                        }
                    }

                    .active {
                        color: #8999B1;
                    }
                }

                .ltitle {
                    // width: 88px;
                    // height: 28px;
                    margin-right: 10px;
                    font-size: 22px;
                    font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
                    font-weight: bold;
                    color: #474952;
                    line-height: 26px;
                    -webkit-background-clip: text;
                    // -webkit-text-fill-color: transparent;
                }
            }

            .banner-right {
                display: flex;
                align-items: center;

                .btn {
                    width: 128px;
                    height: 32px;
                    box-shadow: 0px 2px 4px 0px rgba(55, 59, 92, 0.1);
                    background: linear-gradient(180deg, #F8F8FB 0%, #ECEFF6 100%);
                    border-radius: 4px 4px 4px 4px;
                    outline: none;
                    border: none;
                    font-size: 14px;
                    color: #6B6D77;

                    span {
                        margin-left: 8px;
                    }
                }

                .btn:hover {
                    background: linear-gradient(180deg, #ECEFF6 0%, #F8F8FB 100%);
                }
                .print {
                    width: auto;
                    height: 32px;
                    // box-shadow: 0px 2px 4px 0px rgba(55, 59, 92, 0.1);
                    background: linear-gradient(180deg, #355EFF 0%, #1744F2 100%);
                    border-radius: 4px 4px 4px 4px;
                    outline: none;
                    border: none;
                    font-size: 14px;
                    color: white;
                    margin-right: 10px;
                    padding: 0 16px;
                    span {
                        margin-left: 8px;
                    }
                }
                
                .print:hover {
                    background: linear-gradient(180deg, #1744F2 0%, #355EFF 100%);
                }
            }
        }
    }

    .main {
        width: 88%;
        height: 700px;
        background: white;
        margin-top: 10px;
        border-radius: 4px;
        padding: 34px 30px;
        box-sizing: border-box;

        .title {
            font-weight: bold;
            font-size: 14px;
        }

        .descript {}
    }

    .bottom {
        display: flex;

        .speed {
            margin-top: 13px;
            width: 12%;
            height: 390px;
            padding-left: 24px;
            padding-top: 10px;

            .speedbox {
                display: flex;
                margin-bottom: 48px;


                .speedtitle {
                    width: 56px;
                    height: 18px;
                    font-size: 14px;
                    font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
                    font-weight: bold;
                    color: #474952;
                    line-height: 16px;
                    -webkit-background-clip: text;
                    // -webkit-text-fill-color: transparent;
                }

                .speedspan {
                    width: 36px;
                    height: 15px;
                    font-size: 12px;
                    font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
                    font-weight: 400;
                    color: #B0B1B9;
                    line-height: 14px;
                    -webkit-background-clip: text;
                    margin-top: 5px;
                    // -webkit-text-fill-color: transparent;
                }
            }


            // background-color: #2C57FF;
            .one {
                position: relative;
            }
        }
    }
}

.yd {
    width: 68px;
    height: 24px;
    background: #D5F3E5;
    border-radius: 12px 2px 12px 2px;
    opacity: 1;


    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #08B54D;
}

.chengd {
    width: 96px;
    height: 24px;
    background: #FFEECD;
    border-radius: 12px 2px 12px 2px;
    opacity: 1;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #DE8500;
}

.land {
    width: 96px;
    height: 24px;
    background: #CDEAFF;
    border-radius: 12px 2px 12px 2px;
    opacity: 1;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0C80EB;
}

.zid {
    width: 96px;
    height: 24px;
    background: #D4DEFF;
    border-radius: 12px 2px 12px 2px;
    opacity: 1;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2C57FF;
}

.baiqiu {

    width: 16px;
    height: 16px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 50%;
    border: 1px solid #E2E5EA;
}

:deep(.el-descriptions__header) {
    margin-bottom: 15px !important;
}
</style>
