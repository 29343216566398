<template>
    <div class="adduser">
        <el-dialog :visible.sync="receiveDialog" width="1000px" class="addform receive" @close="cancelSelect">
            <div slot="title" class="dialog-title">
                <local-svg iconClass="back" class="icon handel hover" @click.native="cancelSelect"></local-svg>
                    <span class="title-text">选择购买方</span>
                <div class="button-right">
                    <span class="title-close" @click="cancelSelect"></span>
                </div>
            </div>
            <div class="headerselect btnpart"  style="padding-top: 20px;">
                <el-input class="select-input" type="text" v-model="selectName" placeholder="请输入关键字搜索" v-permission="'开票管理-tax_select'">
                    <el-select  slot="prefix" placeholder="请选择" v-model="selectType">
                        <el-option v-for="item in selectList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                    </el-select>
                </el-input>
                <el-button type="primary" @click="getList('search')" class="confirm" plain v-permission="'开票管理-tax_select'">查询</el-button>
            </div>
            <div class="addform contractform receivetable">
                <div class="formbox">
                    <div class="table-main">
                        <div class="table-title">
                            <span class="title">查询结果</span>
                            <div class="addpart handel hover" @click="addDetail" v-permission="'开票管理-tax_create'">
                                <local-svg iconClass="add" class="icon"></local-svg>
                                新建购买方
                            </div>
                        </div>
                        <div class="table">
                            <el-table
                                :data="tableData"
                                height="360px"
                                style="width: 100%"
                                :header-cell-style="{'background':'#F4F7FC','color':'#B0B1B9','fontWeight':'bold'}">
                                <template slot="empty">
                                    <el-empty :image-size="160" :image="image" description='暂无数据'></el-empty>
                                </template>
                                <el-table-column width="50" align="center">
                                    <template slot-scope="scope">
                                        <div class="select" >
                                            <div class="select-box hover" :class="actId==scope.row.id?'actline':''"
                                            @click="checkLine(scope.row)">
                                                <div class="actbox"></div>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <!-- <el-table-column type="index" label="序号"  align="center"></el-table-column> -->
                                <el-table-column label="购买方名称" >
                                    <template slot-scope="scope">
                                        <el-input v-if="scope.row.ischange" v-model="scope.row.purchaser" placeholder="请输入"></el-input>
                                        <span v-else>{{ scope.row.purchaser }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="纳税人识别号" >
                                    <template slot-scope="scope">
                                        <el-input v-if="scope.row.ischange" v-model="scope.row.taxpayer" placeholder="请输入"></el-input>
                                        <span v-else>{{ scope.row.taxpayer }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" width="100" align="center">
                                    <template slot-scope="scope">
                                        <span v-if="!scope.row.ischange" class="handel hover" @click="editRow(scope.row)"  v-permission="'开票管理-tax_edit'">编辑</span>
                                        <span v-if="!scope.row.ischange" style="margin-left: 10px;" class="handel hover" @click="delRow(scope.row,scope.$index)" v-permission="'开票管理-tax_del'">删除</span>
                                        <div v-permission="'开票管理-tax_create'">
                                            <span v-if="scope.row.ischange&&scope.row.isAdd" class="handel hover" @click="cancelRow(scope.row,scope.$index)">取消</span>
                                            <span v-if="scope.row.ischange&&scope.row.isAdd" class="handel hover" style="margin-left: 10px;" @click="addRow(scope.row,scope.$index)">提交</span>
                                        </div>
                                        <div  v-permission="'开票管理-tax_edit'">
                                            <span v-if="scope.row.ischange&&!scope.row.isAdd" class="handel hover" @click="cancelRow(scope.row,scope.$index)">取消</span>
                                            <span v-if="scope.row.ischange&&!scope.row.isAdd" class="handel hover" style="margin-left: 10px;" @click="addRow(scope.row,scope.$index)">确定</span>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer btnpart receive-dialog">
                <div class="page">
                    <el-pagination
                        layout="total,prev, pager, next"
                        :total="total"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage">
                    </el-pagination>
                </div>
                <div>
                    <el-button class="confirm" type="primary" @click="addOk">确定</el-button>
                    <el-button class="cancel" @click="cancelSelect">取消</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { buyerlist,addbuyer,editbuyer,delbuyer } from '@/api/bill'
import localSvg from '@/icons/localSvg.vue'
export default {
  components: { localSvg },
    name: "adduser",
    props: {
        showdialog:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        actuser:{
            type:Number,
        	default:()=>{
        		return 0
        	}
        },
        actname:{
            type:String,
        	default:()=>{
        		return ''
        	}
        },
        actnumber:{
            type:String,
        	default:()=>{
        		return ''
        	}
        }
    },
    data() {
        return {
            selectName:'',
            selectList:[
                // {name:'全部',id:0},
                {name:'购买方名称',id:1},
                {name:'纳税人识别号',id:2},
            ],
            selectType:'',
            currentPage:1,
            total:0,
            isChange:true,
            tableData:[],
            image:require('../../assets/image/none.png'),
            list:[],
            actIndex:0,
            receiveDialog:false,
            actId:0,
            actName:'',
            account:''
        };
    },
    mounted(){
        this.receiveDialog = this.showdialog
        this.actId = this.actuser
        this.getList()
    },
    methods: {
        getList(e){
            if(e){
                this.currentPage = 1
            }
            let data = {
                page:this.currentPage,
                count:10,
                taxpayer:this.selectType==2?this.selectName:'',
                purchaser:this.selectType==1?this.selectName:'',
            }
            buyerlist(data).then(res=>{
                this.total = res.data.total
                this.tableData = res.data.data
            })
        },
        editRow(row){
            this.$set(row,'ischange',true)
        },
        addRow(row){
            let data = {
                taxpayer:row.taxpayer,
                purchaser:row.purchaser,
            }
            if(row.id){
                data.id = row.id
                editbuyer(data).then(res=>{
                    if(res.code == 200){
                        row.ischange = false
                        this.$message({
                            message:res.msg,
                            type:'success'
                        })
                        this.getList()
                    }else{
                        this.$message({
                            message:res.msg,
                            type:'error'
                        })
                    }
                })
            }else{
                addbuyer(data).then(res=>{
                    if(res.code == 200){
                        row.ischange = false
                        row.isAdd = false
                        this.$message({
                            message:res.msg,
                            type:'success'
                        })
                        this.getList()
                    }else{
                        this.$message({
                            message:res.msg,
                            type:'error'
                        })
                    }
                })
            }
            
        },
        cancelRow(row,index){
            if(row.isAdd){
                this.tableData.splice(index,1)
            }
            this.getList()
            row.ischange = false
        },
        checkLine(row){
            this.actId = row.id
            this.actName =row.purchaser
            this.account = row.taxpayer
        },
        getTableSelect(){
            let list = this.addform.single
            list.forEach(item =>{
                let data = {
                    id:item.business_type
                }
                selectlist(data).then(res=>{
                    item.detail_list = res.data.option.detail
                })
            })
        },
        getSellList(){
            let data = {
                client_id:this.addform.client
            }
            selectlist(data).then(res=>{
                this.sellList = res.data.option.sell
            })
        },
        getDetailList(row,index){
            let data = {
                id:row.business_type
            }
            selectlist(data).then(res=>{
                row.detail_list = res.data.option.detail
            })
        },
        delRow(row,index){
            let data = {
                id:row.id
            }
            delbuyer(data).then(res=>{
                if(res.code == 200){
                    this.$message({
                        message:res.msg,
                        type:'success'
                    })
                    this.getList()
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
            })
        },
        addDetail(){
            this.tableData.unshift({
                taxpayer:'',
                purchaser:'',
                ischange:true,
                isAdd:true
            })
        },
        addOk(addform,type){
            if(this.actId==0){
                this.$message({
                    message:'请先选择购买',
                    type:'error'
                })
                return
            }
            let data = {
                id:this.actId,
                name:this.actName?this.actName:this.actname,
                number:this.account?this.account:this.actnumber
            }
            this.$emit('add',data)
            
        },
        cancelSelect(){
            console.log(1)
            this.$emit('closereceive',false)
        },
        clean(){
            
        },
        handleCurrentChange(val){
            this.currentPage = val
            this.getList()
        }
    }
}
</script>

<style lang="scss">
.adduser {
    .receive{
        .el-dialog__header {
            padding: 0;
            border: none;
            display: flex;
            align-items: center;
            height: 52px;
            border-bottom: 1px solid #f0f1f5;
            padding-left: 24px;
        }
        .dialog-title{
            width: 100%;
            .icon{
                width: 20px;
                height: 16px;
            }
            .title-text{
                margin-left: 20px;
                font-weight: bold;
            }
        }
    }
    .select{
        display: flex;
        justify-content: center;
        align-items: center;
        .select-box{
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border:1px solid #E2E5EA;
            box-sizing: border-box;
            background-color: white !important;
            padding: 2px;
            display: flex;
            justify-content: center;
            .actbox{
                width: 8px;
                height: 8px;
                background-color: #2C57FF;
                border-radius: 50%;
                display: none;
            }
        }
        .actline{
            border:1px solid #2C57FF !important;
            .actbox{
                display: flex;
            }
        }
    }
    .headerselect{
        display: flex;
        button{
            margin-left: 16px;
        }
        .select-input{
            .el-input__inner{
                height: 32px !important;
                padding: 0 14px 0 180px!important;
                width: 100% !important;
            }
            .el-input--suffix{
                width: 167px !important; 
            }
            .el-input__prefix {
                height: 100%;
                left: 0;
                .el-input__inner{
                    width: 167px !important; 
                    padding: 0 14px !important;
                }
            }
            .el-input__suffix{
                display: flex;
                align-items: center;
            }
        }
    }
    
    .receivetable{
        .el-table__cell{
            padding: 7px 0 !important;
        }
        .el-radio__label{
            padding-left: 0 !important;
        }
        .el-radio__input.is-checked .el-radio__inner{
            border-color: #2C57FF;
            background: #fff;
            width: 14px;
            height: 14px;
        }
        .el-radio__inner::after{
            background-color: #2C57FF;
            width: 8px;
            height: 8px;
        }
    }
    .receive-dialog{
        display: flex;
        justify-content: space-between;
    }
}
.handlerTab_tooltip{
    border: none !important;
        box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
        color: #6B6D77 !important;
        padding: 8px 16px !important;
        line-height: 20px !important;
}
.handlerTab_tooltip.is-light{
    border: none !important;
}
.handlerTab_tooltip .popper__arrow {
    /* 上方箭头 */
    border-top-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
    /* 下方箭头 */
    border-bottom-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
}
.remark{
    .el-textarea__inner{
        height: 80px !important;
        line-height: 26px !important;
        padding: 5px 12px !important;
        font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    }
    .el-textarea__inner:hover{
        border-color: #2C57FF !important;
        
    }
}
</style>
