import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    rightList:JSON.parse(sessionStorage.getItem('rightList')||'[]'),
    username:localStorage.getItem('username') || '',
    route:localStorage.getItem('route') || 'commit',
    userpower:JSON.parse(localStorage.getItem('userpower') || '[]'),
    userpath:JSON.parse(localStorage.getItem('userpath') || '[]'),
    userbtn:JSON.parse(localStorage.getItem('userbtn') || '[]'),
    userimage:localStorage.getItem('userimage') || '',
    userrole:localStorage.getItem('userrole') || ''
  },
  getters: {

  },
  mutations: {
      setRoute(state,data){
        state.route = data;
        localStorage.setItem('route',data)
      },
      setRightList(state,data){
        state.rightList = data
        localStorage.setItem('rightList',JSON.stringify(data))
      },
      setUserImage(state,data){
        state.userimage = data;
        localStorage.setItem('userimage',data)
      },
      setUserName(state,data){
        state.username = data;
        localStorage.setItem('username',data)
      },
      setUserRole(state,data){
        state.userrole = data;
        localStorage.setItem('userrole',data)
      },
      setUserPower(state,data){
        state.userpower = data;
        localStorage.setItem('userpower',JSON.stringify(data))
      },
      setUserPath(state,data){
        state.userpath = data;
        localStorage.setItem('userpath',JSON.stringify(data))
      },
      setUserBtn(state,data){
        state.userbtn = data;
        localStorage.setItem('userbtn',JSON.stringify(data))
      },

  },
  actions: {

  },
  modules: {
    
  }
})
