import request from '../utils/outrequest'

//获取外勤列表
export function outworklist(data){
    return request({
        url:'/back/v1/oa/list',
        method:'get',
        params:data
    })
}

//获取身份证信息
export function cardinfo(data){
    return request({
        url:'/back/v1/oa/recognizeUserCode',
        method:'post',
        data
    })
}

//获取外勤详情
export function outworkdetail(data){
    return request({
        url:'/back/v1/oa/detail',
        method:'get',
        params:data
    })
}

//新建外勤
export function addoutwork(data){
    return request({
        url:'/back/v1/oa/addClient',
        method:'post',
        data
    })
}

//编辑外勤
export function editoutwork(data){
    return request({
        url:'/back/v1/oa/editClient',
        method:'post',
        data
    })
}

//删除外勤
export function deloutwork(data){
    return request({
        url:'/back/v1/oa/del',
        method:'delete',
        data
    })
}

//表单下拉
export function outworkselect(data){
    return request({
        url:'/back/v1/common/confgiList',
        method:'get',
        params:data
    })
}

//批量提交
export function submitmore(data){
    return request({
        url:'/back/v1/oa/batchSubmit',
        method:'post',
        data
    })
}

//获取流程列表
export function allprocess(data){
    return request({
        url:'/back/v1/process/all',
        method:'get',
        params:data
    })
}

//获取状态下拉
export function statuslist(data){
    return request({
        url:'/back/v1/work/review_status',
        method:'get',
        params:data
    })
}

//获取反馈问题
export function getbackstatus(data){
    return request({
        url:'/back/v1/oa/completeData',
        method:'get',
        params:data
    })
}

//获取所属区域列表
export function regionselect(data){
    return request({
        url:'/back/v1/oa/regionDown',
        method:'get',
        params:data
    })
}