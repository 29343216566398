<template>
    <div class="adduser">
        <el-dialog :title="title" :visible.sync="showDialog" width="400px" class="addform"  @close="cancel" style="margin-top: 15vh;">
            <div class="addform">
                <el-form :model="form" :rules="rules" ref="form">
                    <el-form-item label="选择成员" prop="user">
                        <span slot="label"><span class="check-form-notice">*</span>选择成员</span>
                        <el-select v-model="form.user" placeholder="请选择跟进人" @change="checkUser"  filterable>
                            <el-option v-for="item in list" :label="item.name" :value="item.id" :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer btnpart btnpart" style="margin-top:14px">
                <el-button class="confirm" type="primary" @click="confirm('form')" plain>确定</el-button>
                <el-button class="cancel" @click="cancel" plain>取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import follow from '@/assets/js/addfollow'
import closefollow from '@/assets/js/closefollow'
import {getfollow} from '@/api/contarct'
export default {
    name: "adduser",
    props: {
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
    },
    data() {
        return {
            list:[],
            showDialog:false,
            title:'添加跟进人',
            form:{
                user:''
            },
            rules: {
                // user: [
                //     { required: true, message: '请选择跟进人', trigger: 'change' },
                // ],
            },
            userData:{}
        };
    },
    mounted(){
        this.showDialog = this.show
        this.getSelect()
        this.getClose()
    },
    methods: {
        getClose(){
            closefollow.$on('closefollow', value => {
                if(value == true){
                    this.$emit('add',false)
                }
            })
        },
        getSelect(){
            getfollow().then(res=>{
                this.list = res.data.option
            })
        },
        checkUser(e){
            this.list.forEach(item =>{
                if(item.id ==  this.form.user){
                    this.userData = item
                }
            })
        },
        cancel(){
            this.$emit('close',false)
            this.clean()
        },
        partOk(form){
        },
        confirm(form){
            this.$refs[form].validate((valid) => {
                if (valid) {
                    follow.$emit("addfollow",this.userData)
                } else {
                    return false;
                }
            });
        },
        getDetail(){
           
        },
        clean(){
            
        },
    }
}
</script>

<style lang="scss">
.adduser {
    .el-textarea{
        margin-top: 5px !important;
    }
}
</style>
