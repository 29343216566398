<template>
    <div class="adduser">
        <el-dialog :title="title" :visible.sync="showDialog" width="800px" class="addform" @close="cancel">
            <div class="addform cunstom">
                <div>
                    <el-form :model="addform" :rules="rules" ref="addform" :validate-on-rule-change="false">
                        <el-row>
                            <el-col :span="11">
                                <el-form-item label="客户名称" prop="name" class="left ">
                                    <span slot="label"><span class="check-form-notice">*</span>客户名称</span>
                                    <el-input v-model="addform.name" placeholder="请输入用户名"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="法定代表人">
                                    <el-input v-model="addform.legal" placeholder="请输入姓名"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="行业" class="left">
                                    <el-select v-model="addform.trade_id" placeholder="请选择" filterable>
                                        <el-option v-for="item in tradeList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="联系方式">
                                    <span slot="label"><span class="check-form-notice">*</span>联系方式</span>
                                    <el-input v-model="addform.connect" placeholder="请输入联系方式"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="住址" class="left">
                                    <el-input v-model="addform.domicile" placeholder="请输入住址"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="详细地址" prop="address">
                                    <span slot="label"><span class="check-form-notice">*</span>详细地址</span>
                                    <el-input v-model="addform.address"  placeholder="请输入详细地址"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="来源" prop="source_id" class="left">
                                    <span slot="label"><span class="check-form-notice">*</span>来源</span>
                                    <el-select v-model="addform.source_id" placeholder="请选择" >
                                        <el-option v-for="item in sourceList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="是否创业客户">
                                    <el-select v-model="addform.pioneer" placeholder="请选择" >
                                        <el-option v-for="item in pioneerList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="客户类别" class="left">
                                    <el-select v-model="addform.category_id" placeholder="请选择" >
                                        <el-option v-for="item in categoryList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="备注">
                                    <el-input v-model="addform.remark"  placeholder="请输入备注"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="11">
                                <el-form-item label="企业类型" class="left">
                                    <el-select v-model="addform.type_id" placeholder="请选择">
                                        <el-option v-for="item in typeList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="成立日期" class="left">
                                    <el-date-picker v-model="addform.set_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="经营范围" class="long-input">
                                    <el-input v-model="addform.business" type="textarea"  resize="none" placeholder="请输入经营范围"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="11">
                                <el-form-item label="所属部门" prop="branch_id" class="left">
                                    <span slot="label"><span class="check-form-notice">*</span>所属部门</span>
                                    <el-input v-if="type==2" v-model="addform.branch" disabled></el-input>
                                    <el-select v-else v-model="addform.branch_id" placeholder="请选择" >
                                        <el-option v-for="item in branchList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </div>
            <div slot="footer" class="dialog-footer btnpart">
                <el-button  v-if="type==1" class="confirm" type="primary" @click="addOk('addform')">提 交</el-button>
                <el-button v-else class="confirm" type="primary" @click="edit('addform')">保 存</el-button>
                <el-button class="cancel" @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {selectlist} from '@/api/custom'
export default {
    name: "adduser",
    props: {
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        form:{
            type:Object,
        	default:()=>{
        		return false
        	}
        },
        type:{
            type:Number,
            default:() =>{
                return 1
            }
        }
    },
    data() {
        return {
            addform:{},
            title:'新建客户信息',
            showDialog:false,
            rules: {
                // name: [
                //     { required: true, message: '请输入客户名称', trigger: 'blur' },
                // ],
                // connect: [
                //     { required: true, message: '请输入手机号码', trigger: 'blur' },
                //     {  pattern:/^13[0-9]{1}[0-9]{8}$|15[^4]{1}[0-9]{8}$|18[0-9]{1}[0-9]{8}$|17[0-8]{1}[0-9]{8}$|14[5-9]{1}[0-9]{8}$|16[6]{1}[0-9]{8}$|19[8-9]{1}[0-9]{8}$/, message: "请输入合法手机号/电话号", trigger: "blur"}
                // ],
                // address: [
                //     { required: true, message: ' ', trigger: 'blur' },
                // ],
                // source_id: [
                //     { required: true, message: '请选择来源', trigger: 'change' }
                // ],
                // branch_id:[
                //     { required: true, message: '请选择所属部门', trigger: 'change' }
                // ]
            },
            typeList:[],
            tradeList:[],
            sourceList:[],
            pioneerList:[],
            categoryList:[],
            branchList:[]
        };
    },
    mounted(){
        this.showDialog = this.show
        this.getList()
        if(this.type == 2 ){
            this.addform = this.form
            this.addform.branch_id = Number(this.addform.branch_id)
            this.addform.pioneer = this.addform.pioneer=='是'?36:35
            this.title = '编辑客户信息'
        }
        this.addOk = this.$debounce(this.addOk )
        this.edit = this.$debounce(this.edit )
    },
    methods: {
        getList(){
            selectlist().then(res=>{
                this.categoryList = res.data.option.category
                this.pioneerList = res.data.option.pioneer
                this.sourceList = res.data.option.source
                this.tradeList = res.data.option.trade
                this.typeList = res.data.option.type
                this.branchList = res.data.option.branch
                if(this.branchList.length==1&&this.type==1){
                    this.$set(this.addform,'branch_id',this.branchList[0].id)
                }
            })
        },
        addOk(addform){
            this.$refs[addform].validate((valid) => {
                if (valid) {
                    this.$emit('add',this.addform)
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
            
        },
        edit(addform){
            this.$refs[addform].validate((valid) => {
                if (valid) {
                    this.$emit('edit',this.addform)
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        cancel(){
            this.$emit('cancel',false)
        },
        clean(){
            
        },
    }
}
</script>

<style lang="scss">
.adduser {
    .right{
        margin-left: 45px;
    }
}
</style>
