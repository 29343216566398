import request from '@/utils/request'

// 获取新建机会下拉
export function sellAddList() {
    return request({
        url: '/sell/save',
        method: 'get'
    })
}
// 创建人
export function allAdmin() {
    return request({
        url: '/common/allAdmin',
        method: 'get'
    })
}
// 获取表单列表
export function sellList(data) {
    return request({
        url: '/houseContract/table',
        method: 'get',
        params: data
    })
}
// 表头
export function headlist(id) {
    return request({
        url: '/common/field',
        method: 'get',
        params: {
            id
        }
    })
}
// 房屋合同单号详情
export function dandetails(id) {
    return request({
        url: '/houseContract/detail',
        method: 'get',
        params: {
            id
        }
    })
}
// 回款方式下拉
export function huikuanway() {
    return request({
        url: '/houseContract/save',
        method: 'get',

    })
}
// 新建房屋回款
export function addsave(data) {
    return request({
        url: '/houseContract/save',
        method: 'post',
        data,
        headers: {
            'X-CSRF-TOKEN': data.xToken
        }
    })
}
// 删除房屋合同(批量)
export function delhouse(data) {
    return request({
        url: '/houseContract/del',
        method: 'delete',
        params: {
            b_id: data
        }
    })
}
// 编辑回款下拉
export function edhuikuanxiala() {
    return request({
        url: '/houseContract/edit',
        method: 'get'
    })
}
// 编辑房屋回款
export function edsave(data) {
    return request({
        url: '/houseContract/edit',
        method: 'post',
        data,
        headers: {
            'X-CSRF-TOKEN': data.xToken
        }
    })
}
// 添加跟进人
export function addFollower(data) {
    return request({
        url: '/houseContract/addFollower',
        method: 'get',
        params: {
            id: data.id,
            follower: data.follower
        }
    })
}
// 所有跟进人
export function allfollow(id) {
    return request({
        url: '/common/allAdmin',
        method: 'get',
        params: {
            b_id: id
        }
    })
}
// 删除跟进人
export function delfollower(id) {
    return request({
        url: '/houseContract/delFollower',
        method: 'delete',
        params: {
            id
        }
    })
}
// 导出字段
export function exportdata() {
    return request({
        url: '/houseContract/exportData',
        method: 'get',

    })
}
// 导出房屋合同
export function exportContract(data) {
    return request({
        url: '/houseContract/houseContract',
        method: 'get',
        params: data
    })
}

//房屋合同审核
export function examineHouse(data) {
    return request({
        url: '/houseContract/houseContract',
        method: 'get',
        params: data
    })
}
