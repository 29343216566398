<template>
    <div class="adduser">
        <el-dialog :title="title" :visible.sync="showDialog" width="1000px" class="addform" @close="cancel">
            <div class="headtab">
                <div class="tab-box hover">
                    <div class="tab" v-for="(item,index) in tabList" :key="index" :class="{'acttab':actIndex==index}" @click="changTab(index)">{{ item.name }}</div>
                </div>
            </div>
            <div class="addform contractform">
                <div class="formbox" v-show="actIndex==0">
                    <el-form :model="addform" :rules="rules" ref="addform" >
                        <el-row>
                            <el-col :span="11">
                                <el-form-item label="客户名称" prop="client" class="left ">
                                    <span slot="label"><span class="check-form-notice">*</span>客户名称</span>
                                    <el-select v-model="addform.client" placeholder="请选择" filterable popper-class="custom-select" clearable>
                                        <el-option v-for="item in admin" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="销售机会" prop="sell">
                                    <span slot="label"><span class="check-form-notice">*</span>销售机会</span>
                                    <el-input v-model="addform.sell_name" disabled :placeholder="addform.client?'请选择':'请先选择客户'">
                                        <div slot="suffix" style="padding: 0 5px;line-height: 44px;" class="hover" @click="checkSell">
                                            <local-svg iconClass="inputbox" style="width: 1.2rem;height: 1.1rem;"></local-svg>
                                        </div>
                                    </el-input>
                                    <!-- <el-select v-model="addform.sell" :placeholder="addform.client?'请选择':'请先选择客户'" :disabled="addform.client?false:true" filterable>
                                        <el-option v-for="item in sellList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select> -->
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="系统来源" class="left">
                                    <el-input v-model="addform.source" disabled placeholder="请选择销售机会"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="渠道名称">
                                    <el-input v-model="addform.channel" disabled placeholder="请选择销售机会"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="合同开始时间" class="left" prop="start_time">
                                    <span slot="label"><span class="check-form-notice">*</span>合同开始时间</span>
                                    <el-date-picker v-model="addform.start_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right" >
                                <el-form-item label="签订日期" prop="sign_time">
                                    <span slot="label"><span class="check-form-notice">*</span>签订日期</span>
                                    <el-date-picker v-model="addform.sign_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="是否有纸质合同" class="left" prop="paper">
                                    <span slot="label"><span class="check-form-notice">*</span>是否有纸质合同</span>
                                    <el-select v-model="addform.paper" placeholder="请选择">
                                        <el-option v-for="item in paper" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="合同结束时间" prop="end_time">
                                    <span slot="label"><span class="check-form-notice">*</span>合同结束时间</span>
                                    <el-date-picker v-model="addform.end_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="合同审核状态" class="left" prop="paper"  v-if="type==2">
                                    <el-select v-model="addform.a_s" placeholder="请选择" disabled>
                                        <el-option v-for="item in a_s" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right" v-if="type==2">
                                <el-form-item label="拒绝原因" prop="end_time">
                                    <el-input v-model="addform.refuse_reason" disabled></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="11">
                                <el-form-item label="付款方式" class="left" prop="pay_type">
                                    <span slot="label"><span class="check-form-notice">*</span>付款方式</span>
                                    <el-select v-model="addform.pay_type" placeholder="请选择" filterable>
                                        <el-option v-for="item in pay" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="合作类型" class="left">
                                    <span slot="label"><span class="check-form-notice">*</span>合作类型</span>
                                    <el-select v-model="addform.team_type" placeholder="请选择">
                                        <el-option v-for="item in team" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item class="left">
                                    <span slot="label">
                                        <span slot="label"><span class="check-form-notice">*</span>优惠事项说明</span>
                                        <el-tooltip content="具体赠送、折扣情况或无" placement="bottom" effect="light"  popper-class="handlerTab_tooltip">
                                            <local-svg iconClass="tips" class="handelicon hover" style="margin-left: 4px;"></local-svg>
                                        </el-tooltip>
                                    </span>
                                    <el-input v-model="addform.discount_info" placeholder="请输入"></el-input>
                                </el-form-item>
                                <el-form-item label="备注" class="left">
                                    <el-input v-model="addform.remark" placeholder="请输入"></el-input>
                                </el-form-item>
                                <el-form-item label="所属部门" class="left" prop="branch">
                                    <span slot="label"><span class="check-form-notice">*</span>所属部门</span>
                                    <el-input v-model="form.branch_name" disabled v-if="type==2&&addform.a_s !=16"></el-input>
                                    <el-select v-model="addform.branch" placeholder="请选择" v-else>
                                        <el-option v-for="item in branch" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item :label="addform.paper!=2?'文件资料':''" class="long-input btnpart"  >
                                    <div class="file" v-if="addform.paper!=2">   
                                        <div class="filepart">
                                            <el-upload
                                                class="upload-demo"
                                                :http-request="uploadFile"
                                                action=""
                                                >
                                                <el-button class="uploadbtn"><local-svg iconClass="upload" class="icon" plain></local-svg>上传附件</el-button>
                                            </el-upload>
                                            <div class="filetip"><span>文件大小不超过50MB</span></div>
                                        </div>
                                    </div>
                                </el-form-item>
                            </el-col>  
                            <el-col :span="11" class="right" >
                                <div class="filelist" v-if="addform.paper!=2" :class="{'nopaper1':type==2}" >
                                    <div class="fileline hover" v-for="(item,index) in fileList" :key="index" @click="seeFile(item)">
                                        <div>
                                            <local-svg iconClass="file" class="icon"></local-svg>
                                            <span class="name">{{ item.name }}</span>
                                        </div>
                                        <div class="close" @click="delFile(index)">
                                            <local-svg iconClass="close" class="closeicon"></local-svg>
                                        </div>
                                    </div>
                                </div>
                                <div  class="filelist" v-else :class="{'nopaper':type==2}"></div>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div class="formbox" v-show="actIndex==1">
                    <div class="table-main">
                        <div class="table-title">
                            <span class="title">单向明细列表</span>
                            <div class="addpart handel hover" @click="addDetail">
                                <local-svg iconClass="add" class="icon"></local-svg>
                                新建明细
                            </div>
                        </div>
                        <div class="table">
                            <el-table
                                :data="addform.single"
                                height="360px"
                                style="width: 100%"
                                :header-cell-style="{'background':'#F4F7FC','color':'#B0B1B9','fontWeight':'bold'}">
                                <template slot="empty">
                                    <el-empty :image-size="160" :image="image" description='请点击新建按钮，新建明细'></el-empty>
                                </template>
                                <!-- <el-table-column type="index" label="序号"  align="center"></el-table-column> -->
                                <el-table-column label="业务类型" >
                                    <template slot-scope="scope">
                                        <el-select v-model="scope.row.business_type" placeholder="请选择" @change="getDetailList(scope.row,scope.$index)" filterable>
                                            <el-option v-for="item in sign" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="detail" label="业务明细">
                                    <template slot-scope="scope">
                                        <el-select v-model="scope.row.business_detail" filterable :placeholder="scope.row.business_type==''?'请先选择业务类型':'请选择'"  :disabled="scope.row.business_type?false:true">
                                            <el-option v-for="item in scope.row.detail_list" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="money" label="价格">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.amount" placeholder="请输入" @blur="changThousand(scope.row)"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" width="70">
                                    <template slot-scope="scope">
                                        <span class="handel hover" @click="delRow(scope.row,scope.$index)">删除</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer btnpart">
                <el-button v-if="type==1" class="confirm" type="primary" @click="addOk('addform','1')">保存并提交</el-button>
                <el-button v-if="type==1" class="savebtn" type="primary" @click="addOk('addform','2')">保存</el-button>
                <el-button v-if="type==2" class="confirm" type="primary" @click="editOk('addform','1')">保存并提交</el-button>
                <el-button v-if="type==2" class="savebtn" type="primary" @click="editOk('addform','2')">保存</el-button>
                <el-button class="cancel" @click="cancel">取消</el-button>
            </div>
        </el-dialog>
        <SellDialog :showdialog="sellDialog" v-if="sellDialog" :id="addform.client" @cancel="closeSell" :actuser="addform.sell" :actname="addform.sell_name" @add="getSell"></SellDialog>
    </div>
</template>

<script>
import {selectlist} from '@/api/contarct'
import { custom } from '@/api/common';
import md5 from 'js-md5'
import service from "@/utils/request.js";
import { Loading } from 'element-ui';
import VueOfficeDocx from '@vue-office/docx'
import SellDialog from '@/components/adddialog/sell.vue'
export default {
    components:{
        VueOfficeDocx,
        SellDialog
    },
    name: "adduser",
    props: {
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        form:{
            type:Object,
        	default:()=>{
        		return false
        	}
        },
        type:{
            type:Number,
            default:() =>{
                return 1
            }
        },
        paper:{
            type:Array,
            default:() =>{
                return []
            }
        },
        pay:{
            type:Array,
            default:() =>{
                return []
            }
        },
        team:{
            type:Array,
            default:() =>{
                return []
            }
        },
        branch:{
            type:Array,
            default:() =>{
                return []
            }
        },
        sign:{
            type:Array,
            default:() =>{
                return []
            }
        },
        detail:{
            type:Array,
            default:() =>{
                return []
            }
        },
        a_s:{
            type:Array,
            default:() =>{
                return []
            }
        }
    },
    data() {
        return {
            sellDialog:false,
            uploadUrl:process.env.VUE_APP_BASE_URL+'/common/uploadImg',
            image:require('../../assets/image/none.png'),
            list:[],
            actIndex:0,
            tabList:[
                {name:'基本信息'},
                {name:'单项明细'}
            ],
            fileList:[],
            addform:{
                audit_status:17,
                single:[],
                client:''
            },
            sellList:[],
            admin:[],
            title:'新建合同',
            showDialog:false,
            rules: {
                // client: [
                //     { required: true, message: '请选择客户名称', trigger: 'change' },
                // ],
                // sell: [
                //     { required: true, message: '请选择销售机会', trigger: 'change' },
                // ],
                // start_time: [
                //     { required: true, message: '请选择合同开始时间', trigger: 'change' },
                // ],
                // sign_time: [
                //     { required: true, message: '请选择签订日期', trigger: 'change' },
                // ],
                // paper: [
                //     { required: true, message: '请选择是否有纸质合同', trigger: 'change' },
                // ],
                // end_time: [
                //     { required: true, message: '请选择合同结束时间', trigger: 'change' },
                // ],
                // pay_type: [
                //     { required: true, message: '请选择付款方式', trigger: 'change' },
                // ],
                // branch: [
                //     { required: true, message: '请选择所属部门', trigger: 'change' },
                // ],
            },
        };
    },
    mounted(){
        this.showDialog = this.show
        this.getCustomList()
        if(this.branch.length==1&&this.type==1){
            setTimeout(()=>{
                if(this.branch.length==1&&this.type==1){
                    this.$set(this.addform,'branch',this.branch[0].id)
                }
            },1000)
        }
        console.log(this.form)
        if(this.form.id){
            if(this.type == 2 ){
                this.addform = this.form
                this.fileList = this.form.fileArr
                this.addform.branch = this.form.branch_id
                this.addform.client = this.form.client_id
                this.addform.sell_name = this.form.sell
                this.addform.sell = this.form.sell_id
                this.addform.branch_id = this.addform.branch
                this.addform.channel = this.form.sell_channel ? this.form.sell_channel : '暂无'
                this.addform.source = this.form.sell_source
                this.getTableSelect()
                this.title = '编辑合同' 
            }else{
                this.addform.client = Number(this.form.id) 
                this.addform.sell_name = this.form.sell_name
                this.addform.sell = Number(this.form.sell)
                this.addform.channel = this.form.channel
                this.addform.source = this.form.source
                setTimeout(()=>{
                    if(this.branch.length==1&&this.type==1){
                        this.$set(this.addform,'branch',this.branch[0].id)
                    }
                },1000)
            }
        }
        this.addOk = this.$debounce(this.addOk )
        this.editOk = this.$debounce(this.editOk )
    },
    methods: {
        getCustomList(){
            let data = {
                tag:1
            }
            custom(data).then(res=>{
                this.admin = res.data
            })
        },
        changThousand(row){
            row.amount = Number(row.amount.replace(/,/g, "")).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 })
        },
        getSell(msg){
            this.addform.sell = msg.id
            this.addform.sell_name = msg.name
            this.addform.channel = msg.channel ? msg.channel :'暂无'
            this.addform.source = msg.source 
            this.sellDialog = false
        },
        getChannelInfo(){
            console.log(1)
        },
        closeSell(){
            this.sellDialog = false
        },
        checkSell(){
            if(this.addform.client){
               this.sellDialog = true 
            }else{
                this.$message.error('请先选择客户名称')
            }
        },
        seeFile(item){
            // let url = process.env.VUE_APP_BASE_URL + '/' +item.file_url
            let url = item.file_url
            window.open(url,'_blank')
        },
        rendered(){
            console.log("渲染完成")
        },
        getTableSelect(){
            let list = this.addform.single
            list.forEach(item =>{
                let data = {
                    id:item.business_type
                }
                selectlist(data).then(res=>{
                    item.detail_list = res.data.option.detail
                })
            })
        },
        getDetailList(row,index){
            let data = {
                id:row.business_type
            }
            selectlist(data).then(res=>{
                row.detail_list = res.data.option.detail
            })
        },
        delFile(index){
            this.fileList.splice(index,1)
        },
        uploadFile(obj){
            console.log(obj)
            let fileObj = obj.file
            const { size, name } = fileObj
            let md5code = md5(name + new Date().getTime())
            let length = 500000
            let start = 0
            let end = 0
            let i = 1;
            Loading.service({
                text: "上传中...."
            });
            this.testHandle(md5code, length, start, end, size, name, fileObj, i)
        },
        testHandle(md5code, length, start, end, size, name, fileObj, i) {
            if (length > size) {
                length = size
            }
            if (end < size) {
                end = start + length
                if (end > size) {
                    end = size
                }
                let blob = ""
                blob = fileObj.slice(start, end)
                start = end
                let fd = new FormData()
                fd.append("file", blob)
                fd.append("md5code", md5code)
                let suc = 2
                if (end == size) {
                    suc = 1
                }
                fd.append("suc", suc)
                fd.append("tmp", i)
                fd.append("name", name)
                i = i + parseInt(1)
                service({
                    url: "/common/uploadImg",
                    method: "post",
                    data: fd,
                }).then(res => {
                    if (res.code == 200) {
                        if (suc == 2) {
                            this.testHandle(md5code, length, start, end, size, name, fileObj, i)
                        } else {
                            this.$message.success(res.msg)
                            Loading.service().close()
                            if (res.data.url !== undefined) {
                                this.fileList.push({ name: name, file_url: res.data.url })
                                console.log(this.fileList,111)
                            }
                        }
                    } else {
                        this.$message.error(res.msg)
                        Loading.service().close()
                    }
                })
                .catch(err => {
                    this.$message.error("请重新上传")
                    Loading.service().close()
                })
            }
        },
        delRow(row,index){
            this.addform.single.splice(index,1)
        },
        addDetail(){
            this.addform.single.push({
                business_type:'',
                business_detail:'',
                amount:'',
                detail_list:[],
            })
        },
        changTab(index){
            this.actIndex = index
        },
        addOk(addform,type){
            
            this.$refs[addform].validate((valid) => {
                if (valid) {
                    if(this.addform.single.length==0){
                        this.$message({
                            message:'请先添加单项明细',
                            type:'error'
                        })
                        return
                    }
                    if(type==2){
                        this.addform.audit_status=16
                    }else{
                        this.addform.audit_status=17
                    }
                    this.addform.files = this.fileList
                    let data = JSON.parse(JSON.stringify(this.addform))
                    this.$emit('add',data)
                } else {
                    this.actIndex = 0
                    return false;
                }
            });
            
        },
        editOk(addform,type){
            this.$refs[addform].validate((valid) => {
                if (valid) {
                    if(type==2){
                        this.addform.audit_status=16
                    }else{
                        this.addform.audit_status=17
                    }
                    this.addform.files = this.fileList
                    let data = JSON.parse(JSON.stringify(this.addform))
                    this.$emit('edit',data)
                } else {
                    this.actIndex = 0
                    return false;
                }
            });
        },
        cancel(){
            this.$emit('cancel',false)
        },
        clean(){
            
        },
    }
}
</script>

<style lang="scss">
.adduser {
    .headtab{
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 15px 0 10px 0 ;
        .tab-box{
            display: flex;
            justify-content: space-between;
            width: 132px;
            font-size: 14px;
            border-bottom: 1px solid #F0F1F5;
            box-sizing: content-box;
            height: 25px;
            .tab{
                color: #B0B1B9;
                height: 100%;
            }
            .tab:hover{
                color: #2C57FF;
            }
            .acttab{
                font-weight: bold !important;
                color: #6B6D77 !important;
                border-bottom:2px solid #2C57FF !important;
                width: auto;
                height: 100%;
            }
        }
    }
    .right{
        margin-left: 45px;
        .filelist{
            width: 100%;
            height: 200px;
            overflow-y: auto;
            padding-right: 8px ;
            box-sizing: border-box;
            .fileline{
                display: flex;
                height: 28px;
                border-radius: 4px;
                background: #F4F7FC;
                width: 100%;
                align-items: center;
                margin-bottom: 6px;
                padding: 0 12px 0 8px;
                justify-content: space-between;
                box-sizing: border-box;
                .icon{
                    margin-right: 7px;
                    width: 12px;
                    height: 14px;
                }
                .name{
                    font-size: 12px;
                    color: #474952;
                }
                .close{
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background: rgba(63,69,124,0.1);
                    display: none;
                    align-items: center;
                    justify-content: center;
                    .closeicon{
                        width: 8px;
                        height: 8px;
                        fill: #B0B1B9;
                    }
                }
                .close:hover{
                    background: rgba(44, 87, 255, 0.2) !important;
                    .closeicon{
                        fill: #2C57FF !important;
                    }
                }
            }
            .fileline:hover{
                background:#EDF3FE !important;
                .close{
                    display: flex;
                }
            }
        }
        .nopaper{
            height: 270px  !important;
        }
        .nopaper1{
            height: 225px  !important;
        }
    }
    .file{
        width: 100%;
        height: auto;
            .filepart{
            width: 100%;
            display: flex;
            .filetip{
                font-size: 10px;
                color: #D0D0D2;
                line-height: 45px;
                margin-left: 8px;
            }
            .icon{
                margin-right: 7px;
            }
        }
    }
    .formbox{
        height: 430px;
        .el-select .el-input .el-select__caret{
            display: flex; 
            justify-content: center;
            align-items: center;
        }
        .table-main{
            padding: 14px 0 ;
            box-sizing: border-box;
            .table-title{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                .title{
                    font-weight: bold;
                    color: #6B6D77;
                }
                .addpart{
                    .icon{
                        fill: #475B93 !important;
                    }
                }
                .addpart:hover{
                    color: #2C57FF !important;
                    .svg-icon{
                        fill: #2C57FF !important;
                    }
                }
                .addpart:active{
                    color: #133CDC !important;
                    .svg-icon{
                        fill: #133CDC !important;
                    }
                }
            }
            .table{
                margin-top: 12px;
            }
        }
    }
}
.handlerTab_tooltip{
    border: none !important;
        box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
        color: #6B6D77 !important;
        padding: 8px 16px !important;
        line-height: 20px !important;
}
.handlerTab_tooltip.is-light{
    border: none !important;
}
.handlerTab_tooltip .popper__arrow {
    /* 上方箭头 */
    border-top-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
    /* 下方箭头 */
    border-bottom-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
}
</style>
