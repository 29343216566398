<template>
    <div class="contractdetail constapply">
        <div class="banner-border"  v-if="!isDetail">
            <div class="top-banner">
                <div class="background"
                    :style="{ 'background': 'url(' + image + ') no-repeat', 'backgroundSize': '1012px 100%', 'backgroundPosition': 'right', 'opacity': '0.9' }">
                    <div class="banner-left">
                        <div class="title">费用申请单号：{{ info.number }}
                            <div class="tip status1" v-if="info.audit_status==62"><local-svg iconClass="status1" ></local-svg>未提审</div>
                            <div class="tip status2" v-if="info.audit_status==63"><local-svg iconClass="status2"></local-svg>审核中</div>
                            <div class="tip status3" v-if="info.audit_status==64"><local-svg iconClass="status3"></local-svg>审核同意</div>
                            <div class="tip status4" v-if="info.audit_status==65"><local-svg iconClass="status4"></local-svg>审核拒绝</div>
                            <div class="tip status5" v-if="info.audit_status==66"><local-svg iconClass="status5"></local-svg>财务已确认</div>
                            <div class="tip status5" v-if="info.audit_status==67"><local-svg iconClass="status5"></local-svg>费用已退回</div>
                        </div>
                        <div class="message">
                            <div class="line" :class="{ 'active': showWidth < 1350 }">支出金额：
                                <!-- <span>{{ Number(info.amount).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span> -->
                                <span class="info-noedit" :class="{'is_edit':checkStatus('amount')}">
                                    {{ info.amount ? Number(info.amount).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '/' }}
                                    <el-tooltip placement="right" popper-class="popper-element-class" effect="light" v-if="checkStatus('amount')">
                                        <div slot="content">
                                            <div class="popper-main"><span>上次修改内容：</span>{{ Number(editForm.amount).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</div>
                                        </div>
                                        <local-svg iconClass="editor" class="icon handel-onlyicon hover"></local-svg>
                                    </el-tooltip>
                                </span>
                            </div>
                            <div class="line" :class="{ 'active': showWidth < 1350 }">负责人：
                                <span>{{ info.create_name }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="banner-right">
                        <button class="print hover" @click="toPrint">
                            <local-svg iconClass="print"></local-svg>
                            <span>打印</span>
                        </button>
                        <button class="btn hover" @click="goBack">
                            <local-svg iconClass="back"></local-svg>
                            <span>返回上一页</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="examine-banner">
            <div class="examine-header">
                <div class="header-left">
                    <local-svg icon-class="back" class="icon handel hover" @click.native="backList"></local-svg>
                    <div class="title">费用申请单号：{{ info.number }}</div>
                </div>
                <div class="header-right btnpart" v-if="isCheck&&info.handle">
                    <el-button class="confirm" plain @click="financeCheck" v-if="info.audit_status == 64" v-permission="'费用申请-finance'" >财务审核</el-button>
                    <el-button class="confirm" plain @click="agreeCheck"  v-if="info.audit_status == 63" v-permission="'费用申请-agree'">审核同意</el-button>
                    <el-button class="cancel" plain @click="refuseCheck" v-if="info.audit_status == 64" v-permission="'费用申请-finance'">审核拒绝</el-button>
                    <el-button class="cancel" plain @click="refuseCheck" v-if="info.audit_status == 63" v-permission="'费用申请-agree'">审核拒绝</el-button>
                </div>
            </div>
        </div>    
        <div class="main" style="min-height: calc(100% - 174px);height: auto !important;">
            <div class="descript" :class="{'auto-detail-part':!isDetail,'auto-detail-examine-part':isDetail}" style="min-height: 100% !important;height: auto !important;">
                <div class="header">
                    <div class="tab hover" v-for="(item, index) in tabList" :class="{ 'acttab': actTab == index }"
                        :key="index" @click="changeTab(index)">
                        <span v-if="index==1&&info.details.length>0">{{ item.name }}</span>
                        <span v-if="index==0">{{ item.name }}</span>
                    </div>
                </div>
                <div class="descript-main" :style="{'padding': isCheck&&info.handle&&!isDetail ? '28px 20px 60px !important' : ''}">
                    <el-descriptions :column="2" border v-if="actTab==0">
                        <el-descriptions-item v-if="isDetail" label="支出金额">
                            <!-- {{ info.amount ? Number(info.amount).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '/' }} -->
                            <span class="info-noedit" :class="{'is_edit':checkStatus('amount')}">
                                {{ info.amount ? Number(info.amount).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '/' }}
                                <el-tooltip placement="right" popper-class="popper-element-class" effect="light" v-if="checkStatus('amount')">
                                    <div slot="content">
                                        <div class="popper-main"><span>上次修改内容：</span>{{ Number(editForm.amount).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</div>
                                    </div>
                                    <local-svg iconClass="editor" class="icon handel-onlyicon hover"></local-svg>
                                </el-tooltip>
                            </span>
                        </el-descriptions-item>
                        <el-descriptions-item v-if="isDetail" label="负责人">
                            {{ info.create_name ? info.create_name : '/' }}
                        </el-descriptions-item>
                        <el-descriptions-item label="支出公司">
                            <span class="info-noedit" :class="{'is_edit':checkStatus('pay_company')}">
                                {{ info.pay_company ? info.pay_company : '/' }}
                                <el-tooltip placement="right" popper-class="popper-element-class" effect="light" v-if="checkStatus('pay_company')">
                                    <div slot="content">
                                        <div class="popper-main"><span>上次修改内容：</span>{{ editForm.pay_company }}</div>
                                    </div>
                                    <local-svg iconClass="editor" class="icon handel-onlyicon hover"></local-svg>
                                </el-tooltip>
                            </span>
                        </el-descriptions-item>
                        <el-descriptions-item label="支出方式">
                            <span class="info-noedit" :class="{'is_edit':checkStatus('pay_type')}">
                                {{ info.pay_type ? info.pay_type : '/' }}
                                <el-tooltip placement="right" popper-class="popper-element-class" effect="light" v-if="checkStatus('pay_type')">
                                    <div slot="content">
                                        <div class="popper-main"><span>上次修改内容：</span>{{ editForm.pay_type }}</div>
                                    </div>
                                    <local-svg iconClass="editor" class="icon handel-onlyicon hover"></local-svg>
                                </el-tooltip>
                            </span>
                        </el-descriptions-item>
                        <el-descriptions-item label="支出事由">
                            <span class="info-noedit" :class="{'is_edit':checkStatus('cause')}">
                                {{ info.cause ? info.cause : '/' }}
                                <el-tooltip placement="right" popper-class="popper-element-class" effect="light" v-if="checkStatus('cause')">
                                    <div slot="content">
                                        <div class="popper-main"><span>上次修改内容：</span>{{ editForm.cause }}</div>
                                    </div>
                                    <local-svg iconClass="editor" class="icon handel-onlyicon hover"></local-svg>
                                </el-tooltip>
                            </span>
                        </el-descriptions-item>
                        <el-descriptions-item label="支出账户">
                            <span class="info-noedit" :class="{'is_edit':checkStatus('pay_account')}">
                                {{ info.pay_account ? info.pay_account : '/' }}
                                <el-tooltip placement="right" popper-class="popper-element-class" effect="light" v-if="checkStatus('pay_account')">
                                    <div slot="content">
                                        <div class="popper-main"><span>上次修改内容：</span>{{ editForm.pay_account }}</div>
                                    </div>
                                    <local-svg iconClass="editor" class="icon handel-onlyicon hover"></local-svg>
                                </el-tooltip>
                            </span>
                        </el-descriptions-item>
                        <el-descriptions-item label="创建时间" >{{ info.create_time ? info.create_time : '/'}}</el-descriptions-item>
                        <el-descriptions-item label="实际支出日期" >{{ info.pay_time ? info.pay_time : '/'}}</el-descriptions-item>
                        <el-descriptions-item label="支出类型" >
                            <span class="info-noedit" :class="{'is_edit':checkStatus('business_type')}">
                                {{ info.business_type ? info.business_type : '/' }}
                                <el-tooltip placement="right" popper-class="popper-element-class" effect="light" v-if="checkStatus('business_type')">
                                    <div slot="content">
                                        <div class="popper-main"><span>上次修改内容：</span>{{ editForm.business_type }}</div>
                                    </div>
                                    <local-svg iconClass="editor" class="icon handel-onlyicon hover"></local-svg>
                                </el-tooltip>
                            </span>
                        </el-descriptions-item>
                        <el-descriptions-item label="支出明细" >
                            <span class="info-noedit" :class="{'is_edit':checkStatus('charge_detail')}">
                                {{ info.charge_detail ? info.charge_detail : '/' }}
                                <el-tooltip placement="right" popper-class="popper-element-class" effect="light" v-if="checkStatus('charge_detail')">
                                    <div slot="content">
                                        <div class="popper-main"><span>上次修改内容：</span>{{ editForm.charge_detail }}</div>
                                    </div>
                                    <local-svg iconClass="editor" class="icon handel-onlyicon hover"></local-svg>
                                </el-tooltip>
                            </span>
                        </el-descriptions-item>
                        <el-descriptions-item label="凭证" >
                            <span class="info-noedit" :class="{'is_edit':checkStatus('proof')}">
                                {{ info.proof ? info.proof : '/' }}
                                <el-tooltip placement="right" popper-class="popper-element-class" effect="light" v-if="checkStatus('proof')">
                                    <div slot="content">
                                        <div class="popper-main"><span>上次修改内容：</span>{{ editForm.proof }}</div>
                                    </div>
                                    <local-svg iconClass="editor" class="icon handel-onlyicon hover"></local-svg>
                                </el-tooltip>
                            </span>
                        </el-descriptions-item>
                        <el-descriptions-item label="票据情况">
                            <span class="info-noedit" :class="{'is_edit':checkStatus('bill_status')}">
                                {{ info.bill_status ? info.bill_status : '/' }}
                                <el-tooltip placement="right" popper-class="popper-element-class" effect="light" v-if="checkStatus('bill_status')">
                                    <div slot="content">
                                        <div class="popper-main"><span>上次修改内容：</span>{{ editForm.bill_status }}</div>
                                    </div>
                                    <local-svg iconClass="editor" class="icon handel-onlyicon hover"></local-svg>
                                </el-tooltip>
                            </span>
                        </el-descriptions-item>
                        <el-descriptions-item label="收款单位">
                            <span class="info-noedit" :class="{'is_edit':checkStatus('receive_name')}">
                                {{ info.receive_name ? info.receive_name : '/' }}
                                <el-tooltip placement="right" popper-class="popper-element-class" effect="light" v-if="checkStatus('receive_name')">
                                    <div slot="content">
                                        <div class="popper-main"><span>上次修改内容：</span>{{ editForm.receive_name }}</div>
                                    </div>
                                    <local-svg iconClass="editor" class="icon handel-onlyicon hover"></local-svg>
                                </el-tooltip>
                            </span>
                        </el-descriptions-item>
                        <el-descriptions-item label="收款账号">
                            <span class="info-noedit" :class="{'is_edit':checkStatus('receive_account')}">
                                {{ info.receive_account ? info.receive_account : '/' }}
                                <el-tooltip placement="right" popper-class="popper-element-class" effect="light" v-if="checkStatus('receive_account')">
                                    <div slot="content">
                                        <div class="popper-main"><span>上次修改内容：</span>{{ editForm.receive_account }}</div>
                                    </div>
                                    <local-svg iconClass="editor" class="icon handel-onlyicon hover"></local-svg>
                                </el-tooltip>
                            </span>
                        </el-descriptions-item>
                        <el-descriptions-item label="开户银行">
                            <span class="info-noedit" :class="{'is_edit':checkStatus('bank')}">
                                {{ info.bank ? info.bank : '/' }}
                                <el-tooltip placement="right" popper-class="popper-element-class" effect="light" v-if="checkStatus('bank')">
                                    <div slot="content">
                                        <div class="popper-main"><span>上次修改内容：</span>{{ editForm.bank }}</div>
                                    </div>
                                    <local-svg iconClass="editor" class="icon handel-onlyicon hover"></local-svg>
                                </el-tooltip>
                            </span>
                        </el-descriptions-item>
                        <el-descriptions-item label="有无回款明细">
                            <span class="info-noedit" :class="{'is_edit':checkStatus('details')}">
                                {{ info.details.length>0  ? '有' : '无' }}
                                <el-tooltip placement="right" popper-class="popper-element-class" effect="light" v-if="checkStatus('details')">
                                    <div slot="content">
                                        <div class="popper-main"><span>上次修改内容：</span>{{ info.details.length>0  ? '有' : '无' }}</div>
                                    </div>
                                    <local-svg iconClass="editor" class="icon handel-onlyicon hover"></local-svg>
                                </el-tooltip>
                            </span>
                        </el-descriptions-item>
                        <el-descriptions-item label="备注" >
                            <span class="info-noedit" :class="{'is_edit':checkStatus('remark')}">
                                {{ info.remark ? info.remark : '/' }}
                                <el-tooltip placement="right" popper-class="popper-element-class" effect="light" v-if="checkStatus('remark')">
                                    <div slot="content">
                                        <div class="popper-main"><span>上次修改内容：</span>{{ editForm.remark }}</div>
                                    </div>
                                    <local-svg iconClass="editor" class="icon handel-onlyicon hover"></local-svg>
                                </el-tooltip>
                            </span>
                        </el-descriptions-item>
                        <el-descriptions-item label="审批意见">{{ info.audit_status==65 && info.refuse ? info.refuse :info.audit_status==66 && info.finance_opinion ? info.finance_opinion : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="附件" :span="2">
                            <div class="file-box" v-if="info.image.length > 0">
                                <div class="filelist">
                                    <!-- <div class="fileline hover" v-for="(item,index) in info.image" :key="index" @click="downloadFile"> -->
                                    <div class="fileline hover" v-for="(item,index) in info.image" :key="index" @click="seeFile(item)">
                                        <div>
                                            <local-svg iconClass="file" class="icon"></local-svg>
                                            <span class="name out-text">{{ item.name }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="button-part" style="margin-top: 12px;text-align: right;margin-right: 8px;"  @click="downloadFile">
                                    <button class="confirm hover">下载压缩包</button>
                                </div>
                            </div>
                            <div class="filelist" v-else>
                                <!-- <div class="fileline hover" v-for="(item,index) in info.image" :key="index" @click="downloadFile"> -->
                                <div class="fileline hover" v-for="(item,index) in info.image" :key="index" @click="seeFile(item)">
                                    <div>
                                        <local-svg iconClass="file" class="icon"></local-svg>
                                        <span class="name">{{ item.name }}</span>
                                    </div>
                                </div>
                            </div>
                        </el-descriptions-item>
                    </el-descriptions>
                    <div class="table" v-else>
                        <div class="table-title">
                            <span class="title">回款明细列表</span>
                        </div>
                        <div class="table-scorlltab">
                            <el-table
                                :data="info.details"
                                height="100%"
                                style="width: 100%"
                                :header-cell-style="{'background':'#F4F7FC','color':'#B0B1B9','fontWeight':'bold'}"
                                tooltip-effect="light">
                                <template slot="empty">
                                    <el-empty :image-size="160" :image="image1" description='暂无明细'></el-empty>
                                </template>
                                <!-- <el-table-column type="index" label="序号" width="100"  align="center"></el-table-column> -->
                                <el-table-column label="回款类型">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.type=='房屋回款'?'房屋回款':'其他回款' }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="客户名称" prop="client" width="230"></el-table-column>
                                <el-table-column label="回款单号" prop="back_num" width="150">
                                    <template slot-scope="scope">
                                        <span class="handel hover" @click="getBackInfo(scope.row.back_id,scope.row.type)">{{ scope.row.back_num }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="回款支出金额" min-width="120">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.disburse_amount?Number(scope.row.disburse_amount).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }):'无'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="业务类型" prop="business_type" show-overflow-tooltip min-width="120">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.business_type?scope.row.business_type:'无' }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="业务明细" prop="business_detail" show-overflow-tooltip min-width="120">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.business_detail?scope.row.business_detail:'无'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="回款摘要" prop="back_amount" show-overflow-tooltip min-width="120">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.back_summary?scope.row.back_summary:'无'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="回款金额" prop="back_summary" min-width="120">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.back_amount?Number(scope.row.back_amount).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }):'无'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="实际到账时间" prop="source" show-overflow-tooltip  width="150">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.real_time?scope.row.real_time:'无'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="到款账户" prop="source" show-overflow-tooltip width="150">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.get_account?scope.row.get_account:'无'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="系统来源" prop="source" show-overflow-tooltip min-width="120">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.source?scope.row.source:'无'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="渠道名称" prop="channel" show-overflow-tooltip min-width="120">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.channel?scope.row.channel:'无'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="返点金额" prop="channel" show-overflow-tooltip min-width="120">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.return_amount?scope.row.return_amount:'/'}}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
                <div class="examinebtn" style="bottom: 18px !important;">
                    <div class="header-right btnpart"  v-if="isCheck&&info.handle&&!isDetail">
                        <el-button class="confirm" plain @click="financeCheck" v-if="info.audit_status == 64" v-permission="'费用申请-finance'" >财务审核</el-button>
                        <el-button class="confirm" plain @click="agreeCheck"  v-if="info.audit_status == 63" v-permission="'费用申请-agree'">审核同意</el-button>
                        <el-button class="cancel" plain @click="refuseCheck" v-if="info.audit_status == 64" v-permission="'费用申请-finance'">审核拒绝</el-button>
                        <el-button class="cancel" plain @click="refuseCheck" v-if="info.audit_status == 63" v-permission="'费用申请-agree'">审核拒绝</el-button>
                    </div>
                </div>
            </div>
            <div class="detail-right">
                <div class="timetree">
                    <div class="timetitle">
                        <local-svg iconClass="cortanctdetail" class="icon"></local-svg>支出审批进度
                    </div>
                    <div class="tree">
                        <el-timeline>
                            <el-timeline-item
                            v-for="(item, index) in info.audit_process" :key="index" >
                                <div class="tree-info" :class="{'tree5':item.audit_status==63&&item.create_time,'tree1':item.audit_status==64,'tree3':item.audit_status==65||item.audit_status==67,'tree2':item.audit_status==66,'tree4':item.audit_status==63&&item.audit=='审核中'}">
                                    <div class="timeline">
                                        <div class="date">{{ item.create_time?item.create_time:'-' }}</div>
                                        <div class="status">{{ item.audit }}</div>
                                    </div>
                                    <div class="checkuser fl-between">
                                        <span>{{ item.name }}</span>
                                        <span v-if="item.audit_status == 419" class="handel-text hover" style="font-weight: normal;font-size: 12px;" @click="getEditInfo(item.record_id)">修改记录</span>
                                    </div>
                                    <div class="reason" v-if="item.audit_reject">
                                        <span class="reason-title">拒绝原因：</span>
                                        {{ item.audit_reject }}
                                    </div>
                                </div>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="" :visible.sync="show_dialog" width="720px" class="version-dialog">
            <div class="dialog-main">
                <div class="header fl-between">
                    <span class="title">历史记录</span>
                    <span class="close" @click="colseHistoryDialog"><local-svg iconClass="close" class="icon hover handel-header-icon"></local-svg></span>
                </div>
                <div class="table mine-table-change">
                    <el-table
                    :data="tableData"
                    height="100%"
                    style="width: 100%"
                    tooltip-effect="light">
                        <template slot="empty">
                            <el-empty :image-size="250" :image="image1" description='暂无数据'></el-empty>
                        </template>
                        <el-table-column label="编号" width="80" prop="id" align="center"></el-table-column>
                        <el-table-column prop="create_time" label="修改时间" width="280"  show-overflow-tooltip align="center">
                            <template slot-scope="scope">
                                <span>{{ $date2(scope.row.c_t) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="admin" label="修改人"> </el-table-column>
                        <el-table-column label="操作" width="100">
                            <template >
                                <span class="handel-text hover">查看详情</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="page page-element-change ">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-size="pageSize"
                        layout="total, prev, pager, next, jumper"
                        :total="total"
                        background
                        popper-class="select-element-change">
                    </el-pagination>
                </div>
            </div>
        </el-dialog>
        <refuseExamine v-if="refuseDialog" :show="refuseDialog" @close="cancelRefuse" @examine="confirmRefuse"></refuseExamine>
        <applyAgree v-if="financeDialog" :show="financeDialog" @close="financeCancel" @examine="financeConfirm" :is_order="is_order"></applyAgree>
        <showImage :previewImg="imageData"></showImage>
        <el-dialog title="" :visible.sync="contract_back_dialog" width="1060px" class="version-dialog" v-if="contract_back_dialog">
            <div class="dialog-main">
                <div class="header fl-between">
                    <span class="title">{{back_type == 2 ? '房屋' : ''}}回款详情</span>
                    <span class="close" @click="colseContractBackDialog"><local-svg iconClass="close" class="icon hover handel-header-icon"></local-svg></span>
                </div>
                <div class="table mine-table-change info-desc" style="height: calc(100% - 127px);">
                    <div class="descript-main">
                        <el-descriptions title="" :column="2" border  v-if="back_type == 1">
                            <el-descriptions-item label="回款单号">{{ back_info.number  ? back_info.number  : '/' }}</el-descriptions-item>
                            <el-descriptions-item label="回款状态">{{ back_info.type==42? '未提交':back_info.type==43? '审核中':back_info.type==44? '审核同意':back_info.type==45? '审核拒绝': '财务已确认' }}</el-descriptions-item>
                            <el-descriptions-item label="客户名称">{{ back_info.client  ? back_info.client  : '/' }}</el-descriptions-item>
                            <el-descriptions-item label="负责人">{{ back_info.branch  ? back_info.branch  : '/' }}</el-descriptions-item>
                            <el-descriptions-item label="回款金额（元）">{{ back_info.amount  ? Number(back_info.amount).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 })  : '/' }}</el-descriptions-item>
                            <el-descriptions-item label="回款方式">{{ back_info.back_type  ? getReturn(showList1,back_info.back_type)  : '/' }}</el-descriptions-item>
                            <el-descriptions-item label="合同单号">{{ back_info.contract  ? back_info.contract_num  : '/' }}</el-descriptions-item>
                            <el-descriptions-item label="合同时间">{{ back_info.contract_time  ? back_info.contract_time  : '/' }}</el-descriptions-item>
                            <el-descriptions-item label="合作类型">{{ back_info.team_type  ? back_info.team_type  : '/' }}</el-descriptions-item>
                            <el-descriptions-item label="开票单号">{{ back_info.bill_num  ? back_info.bill_num  : '/' }}</el-descriptions-item>
                            <el-descriptions-item label="回款日期" >{{ back_info.back_time  ?   $date1(back_info.back_time).split(' ')[0]  : '/'}}</el-descriptions-item>
                            <el-descriptions-item label="实际到账日期" >{{ back_info.real_time ? back_info.real_time : '/'}}</el-descriptions-item>
                            <el-descriptions-item label="开始日期" >{{ back_info.start_time ? back_info.start_time : '/'}}</el-descriptions-item>
                            <el-descriptions-item label="到账账户">{{ back_info.get_account  ? getReturn(showList2,back_info.get_account )  : '/' }}</el-descriptions-item>
                            <el-descriptions-item label="结束日期">{{ back_info.end_time ? back_info.end_time : '/'}}</el-descriptions-item>
                            <el-descriptions-item label="回款摘要">{{ back_info.summary  ? back_info.summary : '/' }}</el-descriptions-item>
                            <el-descriptions-item label="创建时间" >{{ back_info.create_time ? back_info.create_time : '/'}}</el-descriptions-item>
                            <el-descriptions-item label="所属部门">{{ back_info.branch  ? back_info.branch : '/' }}</el-descriptions-item>
                            <el-descriptions-item label="业务类型">{{ back_info.business_type ? back_info.business_type : '/' }}</el-descriptions-item>
                            <el-descriptions-item label="业务明细">{{ back_info.business_detail ? back_info.business_detail : '/'}}</el-descriptions-item>
                            <el-descriptions-item label="客户微信">{{ back_info.wechat && back_info.wechat == 1 ?  '是' : '否'}}</el-descriptions-item>
                            <el-descriptions-item label="审批意见">{{ back_info.type==45 && back_info.refuse ? back_info.refuse :back_info.type==46 && back_info.finance_opinion ? back_info.finance_opinion : '/'}}</el-descriptions-item>
                            <el-descriptions-item label="备注" :span="2">{{ back_info.remark ? back_info.remark : '/'}}</el-descriptions-item>
                            <el-descriptions-item label="照片" :span="2">
                                <div class="image">
                                    <div class="addimg-box">
                                        <div class="showimage" v-if="back_info.imgArr && back_info.imgArr.length>0" :style="{'background': 'url(' + back_info.imgArr[0].img_url + ') no-repeat','backgroundSize':'100% 100%'}">
                                            <!-- <img :src="'http://test.tjlhyg.com/'+back_info.imgArr[0].img_url" alt="" > -->
                                            <div class="tip hover" @click="seeImage(back_info.imgArr)">点击查看大图</div>
                                        </div>
                                        <div class="img-total" v-if="back_info.imgArr && back_info.imgArr.length>0">共{{ back_info.imgArr.length }}张</div>
                                        <span v-else>无</span>
                                    </div>
                                </div>
                            </el-descriptions-item>
                        </el-descriptions>
                        <el-descriptions :column="2" border  v-else>
                            <el-descriptions-item label="回款单号">{{ back_info.house_back_num ? back_info.house_back_num : '/'  }}</el-descriptions-item>
                            <el-descriptions-item label="客户名称">{{ back_info.client ? back_info.client : '/'  }}</el-descriptions-item>
                            <el-descriptions-item label="负责人">{{ back_info.create_name ? back_info.create_name : '/' }}</el-descriptions-item>
                            <template v-for="(item,index) in back_info.business_arr" >
                                <el-descriptions-item :label="'业务明细'+(tableData.length  > 1 ? index+1 : '')" :key="index">{{ item.business_detail.name }}</el-descriptions-item>
                                <el-descriptions-item label="回款金额" :key="index">{{ item.pay_amount }} / {{ bigMoney(item.pay_amount) }}</el-descriptions-item>
                                <el-descriptions-item label="开始时间" :key="index">{{ item.start_time }}</el-descriptions-item>
                                <el-descriptions-item label="结束时间" :key="index">{{ item.end_time }}</el-descriptions-item>
                            </template>
                            <!-- <el-descriptions-item label="回款金额(元)">{{ back_info.pay_amount ? Number(back_info.pay_amount).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '/'}}</el-descriptions-item> -->
                            <el-descriptions-item label="回款方式">{{ back_info.pay_type ? back_info.pay_type : '/' }}</el-descriptions-item>
                            <!-- <el-descriptions-item label="开始日期">{{ back_info.start_time ? back_info.start_time : '/' }}</el-descriptions-item> -->
                            <el-descriptions-item label="房屋回款审核状态">{{ back_info.audit_status == 15 ?'未提审':back_info.audit_status == 16 ?'审核中':back_info.audit_status == 17 ?'审核通过':back_info.audit_status == 18 ?'审核拒绝':'财务已确认'}}</el-descriptions-item>
                            <!-- <el-descriptions-item label="结束日期">{{ back_info.end_time ? back_info.end_time : '/' }}</el-descriptions-item> -->
                            <el-descriptions-item label="实际到账日期">{{ back_info.real_time ? back_info.real_time : '/' }}</el-descriptions-item>
                            <el-descriptions-item label="创建时间">{{ back_info.create_time ? back_info.create_time : '/' }}</el-descriptions-item>
                            <el-descriptions-item label="到账账户">{{ back_info.pay_account ? back_info.pay_account : '/' }}</el-descriptions-item>
                            <!-- <el-descriptions-item label="业务明细">{{ back_info.business_detail ? back_info.business_detail : '/' }}</el-descriptions-item> -->
                            <el-descriptions-item label="所属部门">{{ back_info.branch_name ? back_info.branch_name : '/' }}</el-descriptions-item>
                            <el-descriptions-item label="租赁合同">{{ back_info.house_num ? back_info.house_num : '/' }}</el-descriptions-item>
                            <el-descriptions-item label="房屋地址" :span="2">
                                <div :title="back_info.location" style="width: 461px;overflow:hidden;text-overflow:ellipsis;height: 19px;white-space: nowrap;">
                                    <span>{{ back_info.location ? back_info.location : '/' }}</span>
                                </div>
                            </el-descriptions-item>
                            <el-descriptions-item label="照片" :span="2">
                                <div class="image">
                                    <div class="addimg-box">
                                        <div class="showimage" v-if="back_info.img && back_info.img.length>0" :style="{'background': 'url(' + back_info.img[0].url + ') no-repeat','backgroundSize':'100% 100%'}">
                                            <div class="tip hover" @click="seeImage">点击查看大图</div>
                                        </div>
                                        <div class="img-total" v-if="back_info.img && back_info.img.length>0">共{{ back_info.img.length }}张</div>
                                        <span v-else>无</span>
                                    </div>
                                </div>
                            </el-descriptions-item>
                            <el-descriptions-item label="拒绝原因" :span="2">{{ back_info.refuse ? back_info.refuse : '/' }}</el-descriptions-item>
                            <el-descriptions-item label="备注" :span="2">{{ back_info.remark ? back_info.remark : '/' }}</el-descriptions-item>
                        </el-descriptions>
                    </div>
                </div>
                <div class="button-part" style="text-align: right;padding:0 26px 0 0;display: flex;align-items: center;flex-direction: row-reverse;height: 54px">
                    <button class="cancel hover" type="button" @click="colseContractBackDialog">关闭</button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {constexamine,financeexamine,constdetail,commonexamin,addfollow,backdetail1,edithistory_list, edithistory,downloadrar } from '@/api/const'
import { backdetail } from '@/api/contanctback'
import { backde  } from '@/views/houseCollection/api'
import { scrm_wechat } from '@/api/scrm_custom'
import follow from '@/assets/js/addfollow'
import closefollow from '@/assets/js/closefollow'
import applyAgree from '@/components/examine/applyagree.vue'
import refuseExamine from '@/components/examine/refuse.vue'
import showImage from '@/components/showImage.vue'
import { selectlist } from '@/api/contanctback'
export default {
    components: {
        refuseExamine,
        applyAgree,
        showImage
    },
    name: "detail",
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        info: {
            type: Object,
            default: () => {
                return {
                }
            }
        },
        follow: {
            type: Array,
            default: () => {
                return []
            }
        },
        isDetail: {
            type: Boolean,
            default: () => {
                return false
            }
        }
    },
    data() {
        return {
            isCheck:true,
            financeDialog:false,
            refuseDialog:false,
            show_dialog:false,
            contract_back_dialog:false,
            currentPage:1,
            pageSize:10,
            total:0,
            back_type:1,
            image1: require('../../assets/image/none.png'),
            image2: require('../../assets/image/noneuser.png'),
            tableData: [],
            tradeList: [],
            editArray:[],
            tabList: [
                { name: '详情资料' },
                { name: '回款明细' }
            ],
            fileList: [],
            showList1:[],
            showList2:[],
            imageData:{
                imgList:[], // 所有图片数组
                index: 0, // 当前点击的图片的索引
                infinite: true,  // 是否可以循环切换
                popup: false // 弹窗的显示隐藏
            },
            editForm:{},
            actTab: 0,
            image: require('../../assets/image/detail.png'),
            ischange: false,
            showWidth: 1920,
            is_order:false,
            back_info:{
                imgArr:[]
            }
        };
    },
    mounted() {
        this.is_order = this.info.bill_status_id == 59? true : false
        let that = this
        // that.info.pay_company_isedit = true
        // that.info.pay_company_isedit_content = '111'
        that.info.details.forEach(item =>{
            item.channel = item.type!='房屋回款' && item.channel ? item.channel : '无'
            item.source = item.type!='房屋回款' && item.source ? item.source : '无'
            item.return_amount = item.type!='房屋回款' && item.channel_back_amount ? item.channel_back_amount.amount : '/'
        })
        window.onresize = () => {
            return (() => {
                that.showWidth = document.body.clientWidth
            })()
        },
        this.getFollow()
        this.getTableInfo()
        this.getBackShowList()
    },
    methods: {
        getBackInfo(id,type){
            this.back_type = type == '其他回款' ? 1 : 2
            this.back_info = {}
            if(this.back_type == 1){
                let data = {
                    id: id
                }
                backdetail(data).then(res => {
                    this.back_info = res.data
                    let obj = JSON.parse(JSON.stringify(this.back_info.contract))
                    this.$set(this.back_info,'contract_num',obj.id_num)
                    this.back_info.channel = obj.sell_info && obj.sell_info.channel ? obj.sell_info.channel : '暂无'
                    this.back_info.source = obj.sell_info && obj.sell_info.source ? obj.sell_info.source : '暂无'
                    this.back_info.team_type = this.back_info.contract && this.back_info.contract.team_type == 3 ? '新签' : '续约'
                    this.back_info.bill_num = this.back_info.releate_invoice && this.back_info.releate_invoice.number? this.back_info.releate_invoice.number : ''
                    this.back_info.bill_id = this.back_info.releate_invoice && this.back_info.releate_invoice.id? this.back_info.releate_invoice.id : ''
                    this.contract_back_dialog = true
                    this.getWechat(this.back_info.contract.id)
                })
            }else{
                backde(id).then(res => {
                    this.back_info = res.data
                    this.contract_back_dialog = true
                })
            }
        },
        getWechat(id){
            let data = {
                client_id:id,
                token:localStorage.getItem('token')
            }
            scrm_wechat(data).then(res =>{
                this.$set(this.back_info,'wechat',res.data.bind_type )
            })
        },
        getEditList(){
            let data = {
                page:this.currentPage,
                limit:10,
                fee_id:this.info.id
            }
            edithistory_list(data).then(res=>{
                this.tableData = res.data.list
                this.total = res.data.total
            })
        },
        getEditInfo(id){
            let data = {
                id:id
            }
            edithistory(data).then(res =>{
                this.editForm = res.data
                this.editArray = this.diffObjectKeys(this.info,this.editForm)
            })
        },
        diffObjectKeys(obj1, obj2) {  
            const diffKeys = [];  
        
            // 获取两个对象的所有键  
            const keys1 = Object.keys(obj1);  
            const keys2 = Object.keys(obj2);  
        
            // 找出两个对象共有的键  
            const commonKeys = keys1.filter(key => keys2.includes(key));  
        
            // 遍历共有的键，检查值是否不同  
            commonKeys.forEach(key => {  
                if (obj1[key] !== obj2[key]) {  
                    diffKeys.push(key);  
                }  
            });  
        
            return diffKeys;  
        },
        checkStatus(key){
            if(this.editArray.indexOf(key) != -1){
                return true
            }else{
                return false
            }
        },
        getTableInfo(){
            this.info.details.forEach(item =>{
                this.getTime(item)
            })
        },
        getTime(row,index){
            let data = {
                back_id:row.back_id,
                type:row.type=='房屋回款'? 1 : 2
            }
            backdetail1(data).then(res =>{
                row.real_time = row.type=='房屋回款'?res.data.houseBack.real_time:res.data.payBack.real_time
                row.get_account = row.type=='房屋回款'?res.data.houseBack.pay_account:res.data.payBack.get_account
            })
        },
        downloadFile(){
            let data = {
                id:this.info.id
            }
            downloadrar(data).then(res=>{
                const elink = document.createElement('a');
                elink.download = '附件.rar';
                elink.style.display = 'none';
                const blob = new Blob([res], { type: 'application/x-msdownload' });
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                document.body.removeChild(elink);
            })
        },
        seeFile(item){
            console.log(item)
            let url = item.image
            window.open(url,'_blank')
        },
        toPrint(){
            this.$emit('print',this.info)
        },
        seeImage(file){
            this.imageData.popup = true
            this.imageData.imgList = file ? file :this.info.image
        },
        backList(){
            this.$router.push({path:'/todolist'})
        },
        financeCheck(){
            this.financeDialog = true
        },
        financeConfirm(msg){
            let data = {
                id:this.info.id,
                bill_status:msg.bill_status,
                pay_time:msg.pay_time,
                finance_opinion:msg.finance_opinion,
                pay_account:msg.pay_account
            }
            this.$loading({ text: '加载中...' });
            financeexamine(data).then(res=>{
                if(res.code == 200){
                    this.seeDetail(this.info.id)
                    this.financeDialog = false
                    this.isCheck = false
                    this.$message({
                        message:res.msg,
                        type:'success'
                    })
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        financeCancel(){
            this.financeDialog = false
        },
        cancelRefuse(){
            this.refuseDialog = false
        },
        confirmRefuse(msg){
            let data = {
                id:this.info.id,
                audit_status:65,
                refuse:msg.reason
            }
            this.$loading({ text: '加载中...' });
            commonexamin(data).then(res=>{
                if(res.code == 200){
                    this.seeDetail(this.info.id)
                    this.refuseDialog = false
                    this.isCheck = false
                    this.$message({
                        message:res.msg,
                        type:'success'
                    })
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        seeDetail(msg) {
            let data = {
                id: msg
            }
            constdetail(data).then(res => {
                this.info = res.data
            })
        },
        agreeCheck(){
            let data = {
                id:this.info.id,
                audit_status:64,
                refuse:''
            }
            data.tackle = this.info.audit_process.length>1 && this.info.audit_process[1].audit_status == 63 ? 1 : 0
            this.$loading({ text: '加载中...' });
            commonexamin(data).then(res=>{
                if(res.code == 200){
                    this.seeDetail(this.info.id)
                    this.isCheck = false
                    this.$message({
                        message:res.msg,
                        type:'success'
                    })
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        refuseCheck(){
            this.refuseDialog = true
        },
        deleteFollow(item, index) {
            this.$confirm('此操作将删除此跟进人, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: item.id
                }
                delfollow(data).then(res => {
                    if (res.code == 200) {
                        this.info.follower.splice(index, 1)
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        getFollow() {
            follow.$on('addfollow', value => {
                let data = {
                    id: this.info.id,
                    follower: value.id
                }
                addfollow(data).then(res => {
                    if (res.code == 200) {
                        closefollow.$emit("closefollow", true)
                        this.info.follower.push(value)
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                })

            })
        },
        handleClose() { },
        addUser() {
            this.$emit('add', true)
        },
        addDetail() { },
        changeTab(index) {
            this.actTab = index
        },
        colseHistoryDialog(){
            this.show_dialog = false
        },
        colseContractBackDialog(){
            this.contract_back_dialog = false
        },
        handleCurrentChange(val){
            this.currentPage = val
        },
        goBack() {
            if (this.$route.query.id) {
                // console.log(1111);
                this.$router.go(-1)
            } else {
                this.$emit('back', false)
            }
        },
        
        bigMoney(num) {
            let number = Number(num)
            if(number && number>=0){
                return this.numToCapital(number)
            }else if(number && number<0){
                return '负' + this.numToCapital(Number(number)*-1)
            }
        },
        numToCapital(money) {
            var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');	 //汉字的数字
            var cnIntRadice = new Array('', '拾', '佰', '仟'); //基本单位
            var cnIntUnits = new Array('', '万', '亿', '兆');  //对应整数部分扩展单位
            var cnDecUnits = new Array('角', '分', '毫', '厘'); //对应小数部分单位
            var cnInteger = '整';	//整数金额时后面跟的字符
            var cnIntLast = '元';	//整数完以后的单位
            //最大处理的数字
            var maxNum = 999999999999999.9999;
            var integerNum;	 //金额整数部分
            var decimalNum;	 //金额小数部分
            //输出的中文金额字符串
            var chineseStr = '';
            var parts;		//分离金额后用的数组，预定义
            if (money == '') { return ''; }
        
            money = parseFloat(money);
            if (money >= maxNum) {
                //超出最大处理数字
                return '超出最大处理数字';
            }
            if (money == 0) {
                chineseStr = cnNums[0] + cnIntLast + cnInteger;
                return chineseStr;
            }
            //四舍五入保留两位小数,转换为字符串
            money = Math.round(money * 100).toString();
            integerNum = money.substr(0,money.length-2);
            decimalNum = money.substr(money.length-2);
            if(decimalNum.length == 1){
                decimalNum = '0' + decimalNum
            }
            //获取整型部分转换
            if (parseInt(integerNum, 10) > 0) {
                var zeroCount = 0;
                var IntLen = integerNum.length;
                for (var i = 0; i < IntLen; i++) {
                    var n = integerNum.substr(i, 1);
                    var p = IntLen - i - 1;
                    var q = p / 4;
                    var m = p % 4;
                    if (n == '0') {
                        zeroCount++;
                    } else {
                        if (zeroCount > 0) {
                            chineseStr += cnNums[0];
                        }
                        //归零
                        zeroCount = 0;
                        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                    }
                    if (m == 0 && zeroCount < 4) {
                        chineseStr += cnIntUnits[q];
                    }
                }
                chineseStr += cnIntLast;
            }
            //小数部分
            if (decimalNum != '') {
                var decLen = decimalNum.length;
                for (var i = 0; i < decLen; i++) {
                    var n = decimalNum.substr(i, 1);
                    if (n != '0') {
                        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                    }
                }
            }
            if (chineseStr == '') {
                chineseStr += cnNums[0] + cnIntLast + cnInteger;
            } else if (decimalNum == '' || /^0*$/.test(decimalNum)) {
                chineseStr += cnInteger;
            }
            return chineseStr;
        },
        getReturn(array,key){
            let value = ''
            array.forEach(item =>{
                if(item.id == key){
                    value = item.name
                }
            })
            return value
        },
        getBackShowList(){
            let data = {
                id: ''
            }
            selectlist(data).then(res => {
                this.showList1 = res.data.back_type
                this.showList2 = res.data.get_account
            })
        },
    }
}
</script>

<style lang="scss">
.contractdetail.constapply {
    height: calc(100vh - 60px) !important;
    .filedialog {
        width: 800px !important;
        height: 500px !important;
    }
    .top-banner {
        width: 100%;
        height: 112px;
        border-radius: 4px;
        background: linear-gradient(90deg, #FFF 0%, #F0F4FE 50%, #F0F4FE 100%);
        display: flex;
        justify-content: flex-end;

        .background {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 26px 30px;
            display: flex;
            justify-content: space-between;

            .banner-left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .title {
                    font-size: 22px;
                    font-weight: bold;
                    color: #474952;
                    // max-width: 570px;
                    width: auto;
                    display: flex;
                    align-items: center;

                    .tip {
                        width: auto;
                        height: 24px;
                        line-height: 24px;
                        padding: 0 10px;
                        border-radius: 12px 2px 12px 2px;
                        font-size: 14px;
                        margin-left: 10px;
                    }

                    .status1 {
                        color: #8C8EA6;
                        background: #DADEEC;
                    }

                    .status2 {
                        color: #2C57FF;
                        background: #D4DEFF;
                    }

                    .status3 {
                        color: #08B54D;
                        background: #C9EFDD;
                    }

                    .status4 {
                        color: #F74B4B;
                        background: #FFE3E3;
                    }

                    .status5 {
                        color: #DE8500;
                        background: #FFEECD;
                    }
                }

                .message {
                    display: flex;

                    .line {
                        font-size: 14px;
                        color: #C4C5CB;
                        margin-right: 20px;

                        span {
                            color: #7D7F88;
                        }
                    }

                    .active {
                        color: #8999B1;
                    }
                    .info-noedit{
                        .icon{
                            width: 14px;
                            height: 14px;
                            margin-left: 5px;
                        }
                    }
                    .is_edit{
                        color:#F74B4B !important;
                    }
                }
            }

            .banner-right {
                display: flex;
                align-items: center;

                .btn {
                    width: 128px;
                    height: 32px;
                    box-shadow: 0px 2px 4px 0px rgba(55, 59, 92, 0.1);
                    background: linear-gradient(180deg, #F8F8FB 0%, #ECEFF6 100%);
                    border-radius: 4px 4px 4px 4px;
                    outline: none;
                    border: none;
                    font-size: 14px;
                    color: #6B6D77;

                    span {
                        margin-left: 8px;
                    }
                }
                
                .btn:hover {
                    background: linear-gradient(180deg, #ECEFF6 0%, #F8F8FB 100%);
                }
                .print {
                    width: 84px;
                    height: 32px;
                    // box-shadow: 0px 2px 4px 0px rgba(55, 59, 92, 0.1);
                    background: linear-gradient(180deg, #355EFF 0%, #1744F2 100%);
                    border-radius: 4px 4px 4px 4px;
                    outline: none;
                    border: none;
                    font-size: 14px;
                    color: white;
                    margin-right: 10px;
                    span {
                        margin-left: 8px;
                    }
                }
                
                .print:hover {
                    background: linear-gradient(180deg, #1744F2 0%, #355EFF 100%);
                }
            }
        }
    }

    .main {
        width: 100%;
        height: calc(100% - 1112px);
        // background: white;
        margin-top: 10px;
        display: flex;

        .title {
            font-weight: bold;
            font-size: 14px;
        }

        .descript {
            width: calc(100% - 292px);
            height: 700px;
            background-color: white;
            border-radius: 4px;

            .header {
                height: 55px;
                border-bottom: 1px solid #F0F1F5;
                display: flex;
                padding: 24px 0 0 30px;
                box-sizing: border-box;
                font-size: 14px;

                .tab {
                    width: auto;
                    line-height: 16px;
                    margin-right: 40px;
                    color: #B0B1B9;
                }

                .tab:hover {
                    color: #2C57FF;
                }

                .acttab {
                    border-bottom: 2px solid #2C57FF;
                    font-weight: bold;
                    color: #474952 !important;
                }
            }

            .descript-main {
                padding: 28px 20px ;
                box-sizing: border-box;
                background: #fff;
                .info-noedit{
                    .icon{
                        width: 14px;
                        height: 14px;
                        margin-left: 5px;
                    }
                }
                .is_edit{
                    color:#F74B4B !important;
                }
                .addimg-box{
                    display: flex;
                    align-items: flex-end;
                    .img-total{
                        margin-left: 10px;
                        font-size: 12px;
                        color: rgb(211, 211, 211);
                    }
                }
                .showimage{
                    width: 120px;
                    height: 80px;
                    .tip{
                        background: rgba(51,54,62,0.5);
                        width: 100%; 
                        height: 100%;
                        justify-content: center;
                        align-items: center;
                        color: rgb(211, 211, 211);
                        display: none;
                    } 
                    img{
                        width: 120px;
                        height: 80px;
                    }
                }
                .showimage:hover{
                    .tip{
                        display: flex;
                    }
                }
                .file-box{
                    width: 100%;
                    max-height: 160px;
                    height: auto;
                    .filelist {
                        width: 100%;
                        max-height: 120px;
                        min-height: 60px;
                        height: auto;
                        overflow-y: auto;
                        padding-right: 8px;
                        box-sizing: border-box;
                        
                        .fileline {
                            display: flex;
                            height: 28px;
                            border-radius: 4px;
                            background: #F4F7FC;
                            width: 100%;
                            align-items: center;
                            margin-bottom: 6px;
                            padding: 0 12px 0 8px;
                            justify-content: space-between;
                            box-sizing: border-box;

                            .icon {
                                margin-right: 7px;
                                width: 12px;
                                height: 14px;
                            }

                            .name {
                                font-size: 12px;
                                color: #474952;
                            }

                            .close {
                                width: 16px;
                                height: 16px;
                                border-radius: 50%;
                                background: rgba(63, 69, 124, 0.1);
                                display: none;
                                align-items: center;
                                justify-content: center;

                                .closeicon {
                                    width: 8px;
                                    height: 8px;
                                    fill: #B0B1B9;
                                }
                            }

                            .close:hover {
                                background: rgba(44, 87, 255, 0.2) !important;

                                .closeicon {
                                    fill: #2C57FF !important;
                                }
                            }
                        }

                        .fileline:hover {
                            background: #EDF3FE !important;

                            .close {
                                display: flex;
                            }
                        }
                    }
                }
                

                .table {
                    .table-scorlltab{
                        height: calc(100vh - 374px);
                    }
                    .table-title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 14px;

                        .title {
                            font-weight: bold;
                            color: #6B6D77;
                            margin-bottom: 14px;
                        }

                        .addpart {
                            .icon {
                                fill: #475B93 !important;
                            }
                        }

                        .addpart:hover {
                            color: #2C57FF !important;

                            .svg-icon {
                                fill: #2C57FF !important;
                            }
                        }

                        .addpart:active {
                            color: #133CDC !important;

                            .svg-icon {
                                fill: #133CDC !important;
                            }
                        }
                    }
                }
            }
        }

        .detail-right {
            margin-left: 10px;
            width: 282px;

            .timetree {
                width: 100%;
                max-height: calc(100% - 256px);
                height: auto;
                border-radius: 4px;
                background: white;
                padding: 24px 20px;
                box-sizing: border-box;

                .timetitle {
                    padding: 0 7px;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 16px;

                    .icon {
                        margin-right: 5px;
                    }
                }

                .tree {
                    height: calc(100% - 38px);
                    margin-top: 20px;
                    width: 100%;
                    overflow-y: auto;
                    padding: 0 4px;
                    box-sizing: border-box;

                    .el-timeline-item__node--normal {
                        width: 14px !important;
                        height: 14px !important;
                        left: -3px;
                    }

                    .el-timeline-item__node {
                        background: #FFF !important;
                        border: 1px solid #E2E5EA !important;
                    }

                    .el-timeline-item__wrapper {
                        top: 0 !important;
                        padding-left: 21px !important;
                    }

                    .el-timeline-item {
                        position: relative;
                        padding-bottom: 1px;
                    }

                    .el-timeline-item__tail {
                        border-left: 1px solid #E4E7ED !important;
                    }

                    .tree-info {
                        min-height: 64px;
                        width: 100%;
                        border-radius: 4px;
                        background: #E9EAEE;
                        padding: 12px 14px 14px;
                        box-sizing: border-box;

                        .timeline {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 12px;
                            line-height: 14px;

                            .date {
                                color: #B0B1B9;
                            }

                            .status {
                                color: #B0B1B9;
                            }
                        }

                        .checkuser {
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: bold;
                            color: #6B6D77;
                            margin-top: 6px;
                        }

                        .reason {
                            color: #6B6D77;
                            line-height: 20px;
                            font-size: 12px;
                            margin-top: 6px;

                            .reason-title {
                                color: #B0B1B9;
                            }
                        }
                    }

                    .tree1 {
                        background: #F0FAF1 !important;

                        .status {
                            color: #10C257 !important;
                        }
                    }

                    .tree2 {
                        background: #FAF5E6 !important;

                        .status {
                            color: #DE8500 !important;
                        }
                    }

                    .tree3{
                        background: #FFF4F4 !important;

                        .status {
                            color: #F74B4B !important;
                        }
                    }
                    .tree4{
                        background: #EAF1FB !important;
                        .status{
                            color: #2C57FF !important;
                        } 
                    }
                    .tree5{
                        background: #EAF7FF !important;
                        .status{
                            color: #0C80EB !important;
                        } 
                    }
                }
            }

            .user {
                margin-top: 10px;
                width: 100%;
                height: 246px;
                border-radius: 4px;
                background: white;
                padding: 24px 20px;
                box-sizing: border-box;

                .table-title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 14px;

                    .title {
                        font-weight: bold;
                        color: #474952;

                        .icon {
                            width: 14px;
                            height: 14px;
                            margin-right: 10px;

                            .svg-icon {
                                fill: #2C57FF !important;
                            }
                        }
                    }

                    .addpart {
                        .icon {
                            fill: #475B93 !important;
                        }
                    }

                    .addpart:hover {
                        color: #2C57FF !important;

                        .svg-icon {
                            fill: #2C57FF !important;
                        }
                    }

                    .addpart:active {
                        color: #133CDC !important;

                        .svg-icon {
                            fill: #133CDC !important;
                        }
                    }
                }

                .userlist {
                    margin-top: 16px;
                    height: 172px;
                    overflow-y: auto;
                    padding-right: 8px;
                    box-sizing: border-box;

                    .el-empty {
                        padding: 10px 0 !important;
                    }

                    .fileline {
                        display: flex;
                        height: 28px;
                        border-radius: 4px;
                        background: #F4F7FC;
                        width: 100%;
                        align-items: center;
                        margin-bottom: 6px;
                        padding: 0 12px 0 14px;
                        justify-content: space-between;
                        box-sizing: border-box;

                        .icon {
                            margin-right: 7px;
                            width: 12px;
                            height: 14px;
                        }

                        .name {
                            font-size: 12px;
                            color: #474952;
                        }

                        .close {
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            background: rgba(63, 69, 124, 0.1);
                            display: none;
                            align-items: center;
                            justify-content: center;

                            .closeicon {
                                width: 8px;
                                height: 8px;
                                fill: #B0B1B9;
                            }
                        }

                        .close:hover {
                            background: rgba(44, 87, 255, 0.2) !important;

                            .closeicon {
                                fill: #2C57FF !important;
                            }
                        }
                    }

                    .fileline:hover {
                        background: #EDF3FE !important;

                        .close {
                            display: flex;
                        }
                    }

                    .closepart {
                        display: flex;
                        align-items: center;

                        .close-box {
                            width: 16px;
                        }

                        span {
                            font-size: 14px;
                            line-height: 16px;
                            color: #B0B1B9;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
    .version-dialog{
        .el-dialog__header{
            display: none !important;
        }
        .el-dialog__body{
            padding: 0 !important;
        }
        .dialog-main{
            width: 100%;
            height: 680px;
            max-height: 680px;
            overflow-y: auto;
            .header{
                width: 100%;
                height: 54px;
                border-bottom: 1px solid #F0F1F5;
                box-sizing: border-box;
                padding: 20px;
                .title{
                    font-size: 16px;
                    font-weight: bold;
                    color: #2B313D;
                    line-height: 16px;
                    margin-top: 4px;
                }
                .close{
                    .icon{
                        height: 16px;
                        width: 16px;
                    }
                }
            }
            .table{
                width: 100%;
                height: calc(100% - 147px);
                padding:  0 36px;
                box-sizing: border-box;
                margin-top: 18px;
                .file-name{
                    width: 190px;
                }
            }
            .el-table__empty-block{
                padding-bottom: 0 !important;
            }
            .page{
                width: 100%;
                text-align: right;
                box-sizing: border-box;
                padding: 0 36px;
                margin-top: 13px;
            }
        }
    }
    .info-desc{
        overflow-x: auto;
        .addimg-box{
            display: flex;
            align-items: flex-end;
            .img-total{
                margin-left: 10px;
                font-size: 12px;
                color: rgb(211, 211, 211);
            }
        }
        .showimage{
            width: 120px;
            height: 80px;
            .tip{
                background: rgba(51,54,62,0.5);
                width: 100%; 
                height: 100%;
                justify-content: center;
                align-items: center;
                color: rgb(211, 211, 211);
                display: none;
            } 
            img{
                width: 120px;
                height: 80px;
            }
        }
        .showimage:hover{
            .tip{
                display: flex;
            }
        }
    }
}
</style>
