import request from '../utils/request'

//获取头部列表
export function headerlist(data){
    return request({
        url:'/common/field',
        method:'get',
        params:data
    })
}

// //获取费用申请列表
// export function constlist(data){
//     return request({
//         url:'/feeapply/table',
//         method:'get',
//         params:data
//     })
// }

//获取费用申请列表(带渠道信息)
export function constlist(data){
    return request({
        url:'/v1/feeapply/feeListChannel',
        method:'get',
        params:data
    })
}

//获取费用申请列表
export function addselect(data){
    return request({
        url:'/feeapply/save',
        method:'get',
        params:data
    })
}

//获取支出类型与明细
export function getbusselect(data){
    return request({
        url:'/feeapply/cascadeOption',
        method:'get',
        params:data
    })
}

//获取收款方账户列表
export function getreceive(data){
    return request({
        url:'/feeapply/accountTable',
        method:'get',
        params:data
    })
}

//添加收款方
export function addreceive(data){
    return request({
        url:'/feeapply/addReceive',
        method:'post',
        data
    })
}

//编辑收款方
export function editreceive(data){
    return request({
        url:'/feeapply/editReceive',
        method:'post',
        data
    })
}

//删除收款方
export function delreceive(data){
    return request({
        url:'/feeapply/delReceive',
        method:'delete',
        data
    })
}

//获取房屋列表
export function houselist(data){
    return request({
        url:'/common/allHouseContract',
        method:'get',
        data
    })
}

//获取合同列表
export function contractist(data){
    return request({
        url:'/common/allContract',
        method:'get',
        params:data
    })
}

//删除费用申请
export function delconst(data){
    return request({
        url:'/feeapply/del',
        method:'delete',
        data
    })
}

//费用申请详情
// export function constdetail(data){
//     return request({
//         url:'/feeapply/detail',
//         method:'get',
//         params:data
//     })
// }

//费用申请详情（带渠道信息）
export function constdetail(data){
    return request({
        url:'/v1/feeapply/feeDetailChannel',
        method:'get',
        params:data
    })
}

//合同详情数据
export function contractinfo(data){
    return request({
        url:'/feeapply/backInfo',
        method:'post',
        data
    })
}

// //添加费用申请
// export function addconst(data){
//     return request({
//         url:'/feeapply/save',
//         method:'post',
//         data
//     })
// }

//添加费用申请(带渠道信息)
export function addconst(data){
    return request({
        url:'/v1/feeapply/addFeeApplyChannel',
        method:'post',
        data
    })
}

//编辑费用申请
export function editconst(data){
    return request({
        url:'/feeapply/edit',
        method:'post',
        data
    })
}

//获取查询下拉列表
export function selectlist(data){
    return request({
        url:'/feeapply/save',
        method:'get',
        data
    })
}

//获取全部创建人
export function alluser(data){
    return request({
        url:'/common/allAdmin',
        method:'get',
        data
    })
}

//获取部门
export function allbranch(data){
    return request({
        url:'/common/branch',
        method:'get',
        data
    })
}

//获取所有收款方
export function receivelist(data){
    return request({
        url:'/common/allBeneficiary',
        method:'get',
        data
    })
}

//获取导出列表
export function exportlist(data){
    return request({
        url:'/feeapply/exportData',
        method:'get',
        data
    })
}

//普通审核
export function constexamine(data){
    return request({
        url:'/feeapply/audit',
        method:'post',
        data
    })
}

//费用申请财务审核
export function financeexamine(data){
    return request({
        url:'/feeapply/financeAudit',
        method:'post',
        data
    })
}

//票据详情
export function billdetail(data){
    return request({
        url:'/feeapply/billDetail',
        method:'get',
        params:data
    })
}

//提交票据
export function submitbill(data){
    return request({
        url:'/feeapply/submitBill',
        method:'post',
        data
    })
}

//财务确认票据
export function confirmexamine(data){
    return request({
        url:'/feeapply/financeConfirmBill',
        method:'post',
        data
    })
}

// 财务票据驳回
export function examreturn(data){
    return request({
        url:'/feeapply/reject',
        method:'post',
        data
    })
}

// 回款单号信息
// export function backdetail(data){
//     return request({
//         url:'/feeapply/backNumberInfo',
//         method:'get',
//         params:data
//     })
// }

// 回款单号信息(获取渠道信息)
export function backdetail1(data){
    return request({
        url:'/v1/sell/feeBackNumberInfo',
        method:'get',
        params:data
    })
}

// 申请回款明细标表
export function applydatalist(data){
    return request({
        url:'/common/feeApplyBackDetail',
        method:'get',
        params:data
    })
}

// 获取全部员工
export function allmember(data){
    return request({
        url:'/common/allAdmin',
        method:'get',
        params:data
    })
}

//审核中的编辑
export function examine_edit(data){
    return request({
        url:'/v1/feeapply/editAuditFeeApply',
        method:'post',
        data
    })
}

//普通审核接口
export function commonexamin(data){
    return request({
        url:'/v1/feeapply/auditFeeApply',
        method:'post',
        data
    })
}

//根据回款单号获取渠道信息
export function channelinfo(data){
    return request({
        url:'/v1/sell/feeBackNumberInfo',
        method:'get',
        params:data
    })
}

//获取费用申请历史列表
export function edithistory_list(data){
    return request({
        url:'/v1/feeapply/feeRecordList',
        method:'get',
        params:data
    })
}

//获取费用申请
export function edithistory(data){
    return request({
        url:'/v1/feeapply/feeRecordDetail',
        method:'get',
        params:data
    })
}

//下载附件压缩包
export function downloadrar(data){
    return request({
        responseType: 'blob',
        url:'/v1/feeapply/exportFeeZip',
        method:'get',
        params:data
    })
}