<template>
    <div class="home">
        <div class="border"  v-if="!isDetail">
            <div class="top-search">
                <el-form :model="form" class="demo-form-inline" label-width="">
                    <div class="select-line bostrap " :style="{ height: (hide == false ? '56px' : 'auto') }" id="searchLine">
                        <!-- <el-input clearable placeholder="请输入内容" v-model="form.NL" class="input-with-select hb">

                            <el-select class="es" v-model="HD" slot="prepend" placeholder="请选择">
                                <el-option label="房屋合同单号" value="1"></el-option>
                                <el-option label="房屋地址" value="2"></el-option>
                            </el-select>

                        </el-input> -->
                        <el-form-item label=" " class="lb">
                            <el-input class="input-height select-input" v-model="form.NL" placeholder="请输入关键字搜索">
                                <el-select slot="prefix" placeholder="请选择" v-model="HD">
                                    <!-- <el-option v-for="item in selectList" :label="item.name" :value="item.id"
                                        :key="item.value"></el-option> -->
                                    <el-option label="房屋合同单号" value="1"></el-option>
                                    <el-option label="房屋地址" value="2"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                        <!-- <el-form-item label="房屋合同单号">
                            <el-input class="input-height" v-model="form.housenum" placeholder="请输入关键字搜索"></el-input>
                        </el-form-item> -->
                        <el-form-item label="押金金额">
                            <div class="rangeinput">
                                <el-input clearable @focus="bordercolor" @blur="borderlost" v-model="form.startmoney"
                                    placeholder="请输入金额"></el-input>
                                至
                                <el-input clearable @focus="bordercolor" @blur="borderlost" v-model="form.endmoney"
                                    placeholder="请输入金额"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item class="cumClass custom-select" label="客户名称" >
                            <!-- <el-cascader filterable class="client_id" popper-class="casccc ssdepart" placeholder="请选择"
                                :options="tradeList" collapse-tags v-model="form.custom"
                                :props="{ multiple: true, value: 'id', checkStrictly: true, label: 'name' }" clearable>
                                <template slot-scope="{ data }">
                                    <span :title="data.name">{{ data.name }}</span>
                                </template>
                            </el-cascader> -->
                            <!-- <el-select multiple collapse-tags filterable v-model="form.custom" placeholder="请选择"
                                tooltip-content>

                                <el-option :title="item.name" v-for="item in xialalist.client" :label="item.name"
                                    :value="item.id" :key="item.id">

                                </el-option>

                            </el-select> -->
                            <el-select filterable v-model="form.custom" placeholder="请选择">
                                <el-option v-for="item in tradeList" :label="item.name"
                                    :value="item.id" :key="item.id">
                                </el-option>

                            </el-select>
                        </el-form-item>
                        <el-form-item label="租金总金额">
                            <div class="rangeinputtwo">
                                <el-input clearable @focus="bordercolortwo" @blur="borderlosttwo" v-model="form.rentstartmoney"
                                    placeholder="请输入金额"></el-input>
                                至
                                <el-input clearable @focus="bordercolortwo" @blur="borderlosttwo" v-model="form.rentendmoney"
                                    placeholder="请输入金额"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="物业费总金额">
                            <div class="rangeinputthree">
                                <el-input clearable @focus="bordercolorthree" @blur="borderlostthree"
                                    v-model="form.prostartmoney" placeholder="请输入金额"></el-input>
                                至
                                <el-input clearable @focus="bordercolorthree" @blur="borderlostthree" v-model="form.proendmoney"
                                    placeholder="请输入金额"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="租赁开始日期">
                            <el-date-picker v-model="form.leasestartdate" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="租赁结束日期">
                            <el-date-picker v-model="form.leaseenddate" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="创建人">
                            <el-select v-model="form.founder" placeholder="请选择" tooltip-content filterable>
                                <el-option v-for="item in ceratList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                            <!-- <el-cascader popper-class="ssdepart" class="client_id" placeholder="请选择" :options="ceratList"
                                collapse-tags v-model="form.founder"
                                :props="{ multiple: true, value: 'id', checkStrictly: true, label: 'name' }" filterable
                                clearable>
                            </el-cascader> -->
                        </el-form-item>
                        <!-- <el-form-item label="房屋地址">
                            <el-input class="input-height" v-model="form.houselocation" placeholder="请输入关键字搜索"></el-input>
                        </el-form-item> -->



                    </div>
                    <div class="line"></div>
                    <div class="btn-line">
                        <div class="btnpart">
                            <!-- <el-button class="act" @click="getList">查询</el-button>
                            <el-button @click="clearSeach">重置</el-button> -->
                            <el-button class="confirm" @click="getList('search')" plain v-permission="'房屋合同-select'">查询</el-button>
                            <el-button class="cancel" @click="clearSeach" plain v-permission="'房屋合同-reset'">重置</el-button>
                        </div>
                        <div style="font-size: 14px;" class="showpart hover fi" @click="showHide">
                            {{ actText }}
                            <!-- <img v-if="!hide" src="../../assets/image/showselect.png" alt=""> -->
                            <!-- <img v-if="hide" src="../../assets/image/showselect.png" alt="" style="transform: rotate(-180deg);"> -->
                            <local-svg iconClass="showdown" class="icon" :class="hide?'acticon':''"></local-svg>

                        </div>
                    </div>
                </el-form>
            </div>
            <!-- 表单 -->
            <div class="tablepart" v-if="!isDetail">
                <div class="top">
                    <span>房屋合同</span>
                    <div class="btnlist btnpart">
                        <el-button class="iconbtn" style="height:32px !important ;padding: 0 14px;" @click="batchDE" v-permission="'房屋合同-batch_del'"
                            plain><local-svg iconClass="delete" class="icon"></local-svg>批量删除</el-button>
                        <el-button class="iconbtn" style="height:32px !important ;padding: 0 14px;" @click="showDialog = true" v-permission="'房屋合同-list'"
                            plain><local-svg iconClass="set" class="icon"></local-svg>设置列表展示列</el-button>
                        <el-button class="iconbtn" style="height:32px !important ;padding: 0 14px;" @click="exportD" v-permission="'房屋合同-export'"
                            plain><local-svg iconClass="expor" class="icon"></local-svg>导出</el-button>
                        <el-button class="iconbtn" @click="newAdd" style="height:32px !important ;padding: 0 14px;" v-permission="'房屋合同-create'"
                            plain><local-svg iconClass="add" class="icon"></local-svg>新建</el-button>
                    </div>
                </div>

                <customTable v-if="headerList.length > 0" ref="dadtable" :list="tableData" :head="showHeader" @detail="Dandetail"
                    @tableedit="wantEdit" @deluser="deluser" @PL="pldel" :loading="load">
                </customTable>
                <div class="pagetab">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        
        <newhouse :show="addDialog" @add="Addhouse" @cancel="addcancel" :xialaList="xialalist" :huiway="huiway"
            :xToken="xToken"></newhouse>
        <Dandetail v-if="isDetail" @back="closeDetail" :danList="danList"></Dandetail>
        <edithouse :show="editDia" :edlist="edlist" @cancel="addcancel" :xialaList="xialalist" :edhuiway="edhuiway"
            :xToken="edxToken" @edit="ed">
        </edithouse>
        <setshowDialog v-if="showDialog" :list="headerList" :show="showDialog" @show="setShow" @cancel="closeShow">
        </setshowDialog>
        <!-- <editsale :show="editDia" :List="sList" :edlist="edlist" @cancel="cancelChange" @change="change"></editsale>
        <detail v-if="isDetail" @back="closeDetail" :info="detailData"></detail>-->

        <exportDialog v-if="exportDialog" :list="expList" :show="exportDialog" @export="exportTable" @cancel="cancelExport">
        </exportDialog>
    </div>
</template>
<script>
import setshowDialog from "@/components/custom/show.vue"
import customTable from "@/components/custom/table.vue"
// import exportDialog from "@/components/custom/export.vue"
import exportDialog from "@/components/houseContract/export.vue"
import newhouse from "@/components/houseContract/newhouse.vue"
import edithouse from "@/components/houseContract/edithouse.vue"
import dandetail from "@/components/houseContract/dandetail.vue"
import { sellAddList, sellList, headlist, allAdmin, dandetails, huikuanway, delhouse, edhuikuanxiala, exportContract, exportdata } from './api'
import {saveheader} from '@/api/common'
import Dandetail from "@/components/houseContract/dandetail.vue"
export default {

    name: 'house-contract',
    data() {
        return {
            danList: {},
            editDia: false,
            addDialog: false,
            showDialog: false,
            exportDialog: false,
            isDetail: false,
            hide: false,
            actText: '展开',
            currentPage: 1,
            pageSize: 10,
            total: 0,
            headerList: [],
            expList: {},
            tableData: [],
            showHeader: [],
            tradeList: [],
            ceratList: [],
            form: {
                housenum: '',//单号
                startmoney: '',//押金
                endmoney: '',
                custom: '',
                rentstartmoney: '',//租金
                rentendmoney: '',
                prostartmoney: '',//物业
                proendmoney: '',
                leasestartdate: ['', ''],//租赁开始日期
                leaseenddate: ['', ''],//租赁结束日期
                founder: '',//创建人
                houselocation: '',//房屋地址
                NL: '',//单号地址合并                                                                                                                                              
            },

            xialalist: {},
            creatadmin: [],
            HD: '1',
            edlist: {},
            huiway: {},
            edhuiway: {},
            xToken: '',
            edxToken: '',
            plnum: [],
            load:true


        }
    },
    components: {
        customTable,
        newhouse,
        Dandetail,
        setshowDialog,
        edithouse,
        exportDialog
    },
    methods: {
        bordercolor() {

            document.querySelector('.rangeinput').classList.add('rangeinputborder')
        },
        borderlost() {
            document.querySelector('.rangeinput').classList.remove('rangeinputborder')

        },
        bordercolortwo() {

            document.querySelector('.rangeinputtwo').classList.add('rangeinputborder')
        },
        borderlosttwo() {
            document.querySelector('.rangeinputtwo').classList.remove('rangeinputborder')

        },
        bordercolorthree() {

            document.querySelector('.rangeinputthree').classList.add('rangeinputborder')
        },
        borderlostthree() {
            document.querySelector('.rangeinputthree').classList.remove('rangeinputborder')

        },
        showHide() {
            this.actText = this.hide ? '展开' : '收起'
            this.hide = !this.hide
        },
        //获取下拉
        async xialaList() {
            let res = await sellAddList()
            this.xialalist = res.data.option
            this.tradeList = res.data.option.client
            // console.log(res);
        },
        async createpeople() {
            let res = await allAdmin()
            this.creatadmin = res.data
            this.ceratList = res.data
            // console.log(res);
        },
        // 表单
        async getList(e) {
            this.load = true
            // let cusArr = []
            // if (this.form.custom.length > 0) {
            //     cusArr = this.form.custom.map((item) => {
            //         return item[0]
            //     })
            // }
            let creatArr = []
            // if (this.form.founder.length > 0) {
            //     creatArr = this.form.founder.map((item) => {
            //         return item[0]
            //     })
            // }
            if(e){
                this.currentPage = 1
            }
            let data = {
                page: this.currentPage,
                count: this.pageSize,
                search: this.HD === '1' ? this.form.NL : '',
                location: this.HD === '2' ? this.form.NL : '',
                deposit: [this.form.startmoney, this.form.endmoney],
                c_id: this.form.custom,
                rent: [this.form.rentstartmoney, this.form.rentendmoney],
                property_costs: [this.form.prostartmoney, this.form.proendmoney],
                start_time: [this.form.leasestartdate[0], this.form.leasestartdate[1]],
                end_time: [this.form.leaseenddate[0], this.form.leaseenddate[1]],
                create_id: this.form.founder

            }
            let res = await sellList(data)
            this.tableData = res.data.table
            this.total = res.data.total
            setTimeout(() => {
                this.load = false
            }, 1000);

        },

        // 
        batchde(val) {
            console.log(val);
            // this.batchId = val
            // console.log(val);
        },
        async newAdd(){
            let xiala = await sellAddList()
            this.xialalist = xiala.data.option
            let hui = await huikuanway()
            localStorage.setItem('x_token',hui.data.token)
            this.huiway = hui.data.option
            this.addDialog = true
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.currentPage = 1
            this.getList()
        },
        handleCurrentChange(val) {
            // console.log(val);
            this.currentPage = val
            this.getList()
        },
        addcancel(val) {
            this.addDialog = val
            this.editDia = val
            // this.getList()
        },
        Addhouse(val) {
            this.addDialog = val
            this.getList()

        },
        ed(val) {
            this.editDia = val,
                this.getList()
        },
        // 表头
        getHeaderList() {
            headlist(6).then(res => {
                this.showHeader = []
                this.headerList = res.data
                this.headerList.forEach(item => {
                    this.$set(item, 'check', item.is_show==1?true:false)
                    if(item.is_show==1){
                        this.showHeader.push(item)
                    }
                })
                this.$nextTick(() => {
                    this.$refs.dadtable.$refs.showTable.doLayout()
                })
            })
        },
        clearSeach() {
            this.form = {
                housenum: '',//单号
                startmoney: '',//押金
                endmoney: '',
                custom: '',
                rentstartmoney: '',//租金
                rentendmoney: '',
                prostartmoney: '',//物业
                proendmoney: '',
                leasestartdate: ['', ''],//租赁开始日期
                leaseenddate: ['', ''],//租赁结束日期
                founder: '',//创建人
                houselocation: '',//房屋地址
                NL: '',//单号地址合并                                                                                                                                              
            },
            this.getList('search')
        },
        async Dandetail(val) {
            // console.log(val.id);
            let res = await dandetails(val.id)
            // console.log(res);
            this.danList = res.data
            this.isDetail = true

        },
        closeDetail() {
            this.isDetail = false
        },
        // 设置展示列保存&取消
        setShow(msg) {
            let ids = []
            msg.forEach(item =>{
                ids.unshift(item.id)
            })
            let data = {
                id:ids.toString(),
                type:6
            }
            saveheader(data).then(res =>{
                if(res.code == 200){
                    this.$message.success(res.msg)
                    this.showHeader = msg
                    this.getHeaderList()
                    this.showDialog = false
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        closeShow(msg) {
            this.showDialog = false
        },
        async wantEdit(val) {
            // console.log(val.info.id);
            let res = await dandetails(val.info.id)
            this.editDia = true,
            this.edlist = res.data
            let xiala = await sellAddList()
            this.xialalist = xiala.data.option
            let hui = await edhuikuanxiala()
            // console.log(hui);
            localStorage.setItem('x_token',hui.data.token)
            this.edhuiway = hui.data.option
            // 回款方式 下拉

        },
        // 删除一个
        async deluser(val) {
            // console.log(val);
            let arr = []
            arr.push(val)
            let res = await delhouse(arr)
            // console.log(res);
            if (res.code === 200) {
                this.$message({
                    type: 'success',
                    message: '数据删除成功'
                });
                this.getList()
            } else {
                this.$message({
                    type: 'error',
                    message: '当前没有被允许删除数据'
                });
            }

        },
        // 批量删除
        batchDE() {
            // let res = await delhouse(this.plnum)
            // console.log(res);
            if (this.plnum) {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    let res = await delhouse(this.plnum)
                    // console.log(res);
                    if (res.code === 403) {
                        this.$message({
                            type: 'error',
                            message: res.msg
                        });
                    } else {
                        this.$message({
                            type: 'success',
                            message: res.msg
                        });
                    }

                    this.getList()
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            } else {
                this.$message({
                    type: 'error',
                    message: '当前没有选中删除内容'
                });
            }
        },
        pldel(val) {
            this.plnum = val
            // let res = await delhouse(val)
            // console.log(res);
        },
        async exportTable(msg) {
            let typelist = ''
            for(var a = 0; a<msg.length;a++){
                let item =  msg[a]
                if( a < msg.length-1){
                    typelist = typelist + `${item.type}=${item.name}&`
                    
                }else{
                    typelist = typelist + `${item.type}=${item.name}` 
                }
            }
            // if(this.form.amount1!=='' && this.form.amount2!==''){
            //     arr.push(this.form.amount1)
            //     arr.push(this.form.amount2)
            // }
            let creatArr = []
            // if (this.form.founder.length > 0) {
            //     creatArr = this.form.founder.map((item) => {
            //         return item[0]
            //     })
            // }
            let data = {
                search:this.HD==1?this.form.NL:'',
                deposit:this.form.startmoney&&this.form.endmoney? [this.form.startmoney, this.form.endmoney].toString():'',
                branch:'',
                c_id :this.form.custom,
                rent:this.form.rentstartmoney&&this.form.rentendmoney? [this.form.rentstartmoney, this.form.rentendmoney].toString():'',
                property_costs:this.form.prostartmoney&&this.form.proendmoney? [this.form.prostartmoney, this.form.proendmoney].toString():'',
                start_time:this.form.leasestartdate[0]&&this.form.leasestartdate[1]?[this.form.leasestartdate[0], this.form.leasestartdate[1]].toString():'',
                end_time:this.form.leaseenddate[0]&&this.form.leaseenddate[1]? [this.form.leaseenddate[0], this.form.leaseenddate[1]].toString():'',
                create_id:this.form.founder,
                location:this.HD==2?this.form.NL:'',
            }
            console.log(typelist)
            let string = `search|${data.search};deposit|${data.deposit};branch|${data.branch};c_id|${data.c_id};rent|${data.rent};property_costs|${data.property_costs};start_time|${data.start_time};end_time|${data.end_time};create_id|${data.create_id};location|${data.location}`
            console.log(string)
            window.open(`${this.$BASE_URL}houseContract/houseContract?${typelist}&token=${localStorage.getItem('token')}&export=${string}`,'_blank')
            this.exportDialog = false
        },
        cancelExport() {
            this.exportDialog = false
        },
        async exportD() {
            // console.log(this.headerList);
            let res = await exportdata()
            // console.log(res.data);
            // this.expList = [{ name: 'leo', check: true }, { name: 'jcak' }, { name: 'pe' }]
            this.expList = [
                { name: res.data.num, check: true, type: 'num' },
                { name: res.data.alter_people, check: true, type: 'alter_people' },
                { name: res.data.client, check: true, type: 'client' },
                { name: res.data.create_people, check: true, type: 'create_people' },
                { name: res.data.create_time, check: true, type: 'create_time' },
                { name: res.data.deposit, check: true, type: 'deposit' },
                { name: res.data.end_time, check: true, type: 'end_time' },
                { name: res.data.location, check: true, type: 'location' },
                { name: res.data.now_area, check: true, type: 'now_area' },
                { name: res.data.old_area, check: true, type: 'old_area' },
                { name: res.data.pay_back, check: true, type: 'pay_back' },
                { name: res.data.preferential_matter, check: true, type: 'preferential_matter' },
                { name: res.data.preferential_policy, check: true, type: 'preferential_policy' },
                { name: res.data.property_costs, check: true, type: 'property_costs' },
                { name: res.data.rent, check: true, type: 'rent' },
                { name: res.data.sign_time, check: true, type: 'sign_time' },
                { name: res.data.start_time, check: true, type: 'start_time' },
                { name: res.data.status, check: true, type: 'status' },
                { name: res.data.update_time, check: true, type: 'update_time' },
            ]
            // console.log(res.data);
            this.exportDialog = true
        }
    },
    mounted() {
        this.xialaList()
        this.getList()
        this.getHeaderList()
        this.createpeople()
        if (this.$route.query.id) {
            let data = {
                id: this.$route.query.id
            }
            this.Dandetail(data)
        }
        if (this.$route.params.id) {
            let data = {
                id: this.$route.params.id
            }
            this.Dandetail(data)
        }
        this.newAdd = this.$debounce(this.newAdd )
        this.exportD = this.$debounce(this.exportD )
    }
}
</script>
<style src="@/assets/scss/formstyle.scss" lang="scss" ></style>

<style lang="scss" scoped>
.home {
    .border{
        padding: 24px;
    }

    .top-search {
        // min-height: 120px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;

        .select-line {
            display: flex;
            padding: 12px 20px 0 0 !important;

            :deep(.el-form-item) {
                margin-top: 0px !important;
                margin-bottom: 12px !important;
            }
        }

        .line {
            width: 100%;
            height: 1px;
            background: #F0F1F5;
            // margin-top: 12px;
        }

        .btn-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 14px 20px;
            height: 27px;

            .act {
                background: #2C57FF;
                color: white;
            }

            .btnpart button {
                height: 32px;
                width: 68px;
                line-height: 32px;
                padding: 0;

            }
        }

        .rangeinput {
            width: 252px;
            height: 32px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #E2E5EA;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;

            :deep(.select-line .el-input__inner) {
                border: none !important;
            }
        }

        .rangeinputtwo,
        .rangeinputthree {
            width: 252px;
            height: 32px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #E2E5EA;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;

            :deep(.select-line .el-input__inner) {
                border: none !important;
            }
        }

        .rangeinputborder {
            border: 1px solid #2C57FF;

        }


    }

    .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
            font-size: 14px;
            color: #474952;
            font-weight: bold;
        }

        .icon {
            width: 16px;
            height: 16px;
        }

        i {
            margin-right: 5px;
        }
    }

    .tablepart {
        min-height: 675px;
        background: white;
        margin-top: 10px;
        border-radius: 4px;
        padding: 16px 20px;

        .icon {
            margin-right: 8px;
        }

        .pagetab {
            text-align: right;
            margin-top: 20px;
        }
    }
}



// :deep(.el-input__inner) {
//     width: 127px !important;
// }
:deep(.el-input-group) {
    height: 32px !important;
    width: 331px !important;
    margin-top: 6px !important;
}

:deep(.el-cascader__search-input) {
    display: none !important;
}

:deep(.el-cascader__tags .el-tag) {
    max-width: 110px !important;
}

:deep(.select-line .el-input__inner) {
    box-sizing: border-box !important;
}

:deep(.el-cascader__dropdown) {
    width: 200px !important;
}

:deep(.el-cascader-menu__list) {
    width: 200px !important;
}

:deep(.el-input.is-focus .el-input__inner) {
    border-color: #2C57FF !important;
}

.fi {
    display: flex;
    align-items: center;
    color: #475B93;
    .icon {
        width: 7px;
        height: 16px;
        margin-left: 5px;
    }
    .acticon{
        transform: rotate(180deg);
    }
}
.fi:hover{
    color: #2C57FF !important;
}
</style>
<style lang="scss">
.rangeinput {
    .el-input__inner {
        border: none !important;
        width: 112px !important;
        background-color: transparent !important;
    }
}

.rangeinputtwo,
.rangeinputthree {
    .el-input__inner {
        border: none !important;
        width: 112px !important;
        background-color: transparent !important;
    }
}

.cumClass {
    .el-select-dropdown {
        width: 204px !important;

    }
}

.hb {
    margin-left: 20px;

    .el-input__inner {
        width: 200px !important;
    }

    .el-input__icon {
        line-height: 33px !important;
    }

    .el-input-group>.el-input__inner {
        width: 130px !important;
    }

    .el-select .el-input {
        width: 130px !important;
    }

    // .el-input-group {
    //     height: 32px !important;
    //     width: 331px !important;
    // }
}

.es {
    .el-select .el-input {
        width: 130px !important;
    }

    .el-input__inner {
        width: 130px !important;
    }
}

.len {
    .el-cascader-menu__list {
        width: 200px !important;
    }

}

.select-input {
    .el-input__inner {
        height: 32px !important;
        padding: 0 14px 0 146px !important;
        width: 320px !important;
    }

    .el-input--suffix {
        width: 133px !important;
    }

    .el-input__prefix {
        height: 100%;
        left: 0;

        .el-input__inner {
            width: 133px !important;
            padding: 0 12px !important;
        }
    }

    .el-input__suffix {
        display: flex;
        align-items: center;
    }

}

.lb {
    .el-form-item__label {
        display: none;
    }

    margin-left: 20px;
}
</style>