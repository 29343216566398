import request from '../utils/request'

//获取头部列表
export function channellist(data){
    return request({
        url:'/v1/channel/channelList',
        method:'get',
        params:data
    })
}

//获取身份证信息
export function getcardinfo(data){
    return request({
        url:'/v1/channel/recognizeIdcard',
        method:'get',
        params:data
    })
}

//新建渠道
export function addchannel(data){
    return request({
        url:'/v1/channel/addChannel',
        method:'post',
        data
    })
}

//渠道名称
export function channelselect(data){
    return request({
        url:'/v1/channel/allChannel',
        method:'get',
        params:data
    })
}

//禁用
export function channelopen(data){
    return request({
        url:'/v1/channel/alterChannelStatus',
        method:'post',
        data
    })
}

//渠道详情
export function channeldetail(data){
    return request({
        url:'/v1/channel/channelDetail',
        method:'get',
        params:data
    })
}

//渠道详情
export function editchannel(data){
    return request({
        url:'/v1/channel/editChannel',
        method:'post',
        data
    })
}

//渠道订单列表
export function orderlist(data){
    return request({
        url:'/v1/channel/channelWaiqin',
        method:'get',
        params:data
    })
}

//渠道开票列表
export function billlist(data){
    return request({
        url:'/v1/channel/channelInvoice',
        method:'get',
        params:data
    })
}

//渠道回款列表
export function backlist(data){
    return request({
        url:'/v1/channel/channelPayBack',
        method:'get',
        params:data
    })
}

//渠道合同列表
export function contractlist(data){
    return request({
        url:'/v1/channel/channelContract',
        method:'get',
        params:data
    })
}



