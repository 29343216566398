<template>
    <div class="number-box">
        <div class="toppart btnpart">
            <div class="span">查询结果</div>
            <el-button class="iconbtn" @click="addUser" plain  v-permission="'成员管理-create'"><local-svg iconClass="add" class="addicon"></local-svg>新增用户</el-button>
        </div>
        <div class="table">
            <el-table
                :data="list"
                height="560px"
                style="width: 100%"
                :header-cell-style="{'background':'#F4F7FC','color':'#B0B1B9','fontWeight':'bold'}"
                tooltip-effect="dark">
                <template slot="empty">
                    <el-empty :image-size="160" :image="image" description='暂无数据'></el-empty>
                </template>
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <!-- <el-table-column type="index" width="100" label="序号"  align="center"></el-table-column> -->
                <el-table-column prop="name" label="用户名" width="130"></el-table-column>
                <el-table-column prop="branch" label="所属部门" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="role" label="角色类型"  show-overflow-tooltip></el-table-column>
                <el-table-column prop="mobile" label="手机号码" width="180"></el-table-column>
                <el-table-column label="电子邮箱" width="250">
                    <template slot-scope="scpoe">
                        <span>{{ scpoe.row.email?scpoe.row.email:'/' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="开启禁用" >
                    <!-- v-if="$store.state.userbtn.indexOf('成员管理-enable')>0" -->
                    <template slot-scope="scope">
                        <div v-permission="'成员管理-enable'">
                            <el-switch
                                v-model="scope.row.is_open"
                                active-color="#13ce66"
                                inactive-color="#DADCE3"
                                @change="setType(scope.row)">
                            </el-switch>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="职位状态"  >
                    <template slot-scope="scope">
                        <span>{{scope.row.resign}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="来源"  >
                    <template slot-scope="scope">
                        <span>{{scope.row.source == 1 ?'滨航门户':'协同办公'}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" prop="create_time" ></el-table-column>
                <el-table-column label="操作" align="center" width="150">
                    <template slot-scope="scope">
                        <div class="chick">
                            <div class="hover handel" @click="editUser(scope.row)" v-permission="'成员管理-edit'">编辑</div>
                            <div class="hover handel" @click="deleteUser(scope.row)" v-permission="'成员管理-del'">删除</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import adduser from '@/utils/mutual.js'
export default {
    name: "NavBarLeft",
    props: {
        list:{
        	type:Array,
        	default:()=>{
        		return []
        	}
        },
    },
    data() {
        return {
            image:require('../../../assets/image/none.png'),
            cellSty:{
                'background-color':'#aaa'
            },
        };
    },
    created(){
    },
    mounted(){
        
    },
    methods: {
        setType(row){
            console.log(row.is_open)
            let data = {
                id:row.id,
                freeze:row.is_open===true?1:0
            }
            this.$emit('stopuser',data)
        },
        addUser(){
            this.$emit('transfer',true)
        },
        editUser(row){
            let data = row
            this.$emit('tableedit',data)
        },
        deleteUser(row){
            this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.$emit('deluser',row.id)
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
            });
        },
    }
}
</script>
<style lang="scss">
.el-table tbody tr:hover>td { background-color: #EDF3FE !important}
</style>

<style lang="scss">

.number-box {
    .toppart{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .span{
            font-size: 14px;
            font-weight: bold;
        }
        .addicon{
            margin-right: 10px !important;
        }
    }
    .table{
        margin-top: 8px;
        width: 100%;
        .chick{
            display: flex;
            justify-content: space-evenly;
        }
        .table-more{
            margin-right: 10px;
        }
        // .el-table__cell{
        //     padding: 12px 10px ;
        //     box-sizing: border-box;
        // }
    }
}
</style>
