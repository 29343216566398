<template>
    <div class="adduser">
        <el-dialog title="新增用户" :visible.sync="showDialog" width="400px" class="addform" @close="cancel">
            <div class="addform">
                <el-form :model="addform" :rules="rules" ref="addform">
                    <el-form-item label="用户名" prop="name">
                        <span slot="label"><span class="check-form-notice">*</span>用户名</span>
                        <el-input v-model="addform.name" autocomplete="off" placeholder="请输入用户名"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="mobile">
                        <span slot="label"><span class="check-form-notice">*</span>手机号</span>
                        <el-input v-model="addform.mobile" autocomplete="off" placeholder="请输入手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="电子邮箱" prop="email">
                        <span slot="label"><span class="check-form-notice">*</span>电子邮箱</span>
                        <el-input v-model="addform.email" autocomplete="off" placeholder="请输入电子邮箱"></el-input>
                    </el-form-item>
                    <el-form-item label="所属部门" prop="part">
                        <span slot="label"><span class="check-form-notice">*</span>所属部门</span>
                        <el-cascader @change="seeArray" collapse-tags v-model="addform.part" :options="typelist" :show-all-levels="false" :props="props1" placeholder="请选择所属部门" ></el-cascader>
                    </el-form-item>
                    <el-form-item label="角色类型">
                        <span slot="label"><span class="check-form-notice">*</span>角色类型</span>
                        <el-select v-model="addform.role" placeholder="请选择角色类型"  multiple collapse-tags>
                            <el-option v-for="item in rolelist" :label="item.name" :value="item.id" :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <span slot="label"><span class="check-form-notice">*</span>密码</span>
                        <el-input v-model="addform.password" type="password" autocomplete="off"  placeholder="请输入密码"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="realpass">
                        <span slot="label"><span class="check-form-notice">*</span>确认密码</span>
                        <el-input v-model="addform.realpass" type="password" autocomplete="off" placeholder="请再次输入密码"></el-input>
                    </el-form-item>
                    
                </el-form>
                
            </div>
            <div slot="footer" class="dialog-footer btnpart">
                <el-button class="confirm" type="primary" @click="addOk('addform')" plain>提 交</el-button>
                <el-button class="cancel" @click="cancel" plain>取 消</el-button>
            </div>
            
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "adduser",
    props: {
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        typelist:{
        	type:Array,
        	default:()=>{
        		return []
        	}
        },
        rolelist:{
        	type:Array,
        	default:()=>{
        		return []
        	}
        },
    },
    data() {
        return {
            props1:{
                multiple: true,
                value:'id',
                checkStrictly: true,
            },
            addform:{
                name:'',
                mobile:'',
                email:'',
                part:[],
                role:[],
                password :'123456',
                realpass:'123456',
            },
            showDialog:false,
            rules: {
                // name: [
                //     { required: true, message: '请输入活动名称', trigger: 'blur' },
                // ],
                // mobile: [
                //     { required: true, message: '请输入手机号码', trigger: 'blur' },
                //     { pattern:/^13[0-9]{1}[0-9]{8}$|15[^4]{1}[0-9]{8}$|18[0-9]{1}[0-9]{8}$|17[0-8]{1}[0-9]{8}$|14[5-9]{1}[0-9]{8}$|16[6]{1}[0-9]{8}$|19[8-9]{1}[0-9]{8}$/, message: "请输入合法手机号/电话号", trigger: "blur" }
                // ],
                // email: [
                //     { required: true, message: '请输入邮箱', trigger: 'blur' },
                //     { pattern:/^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g, message: "请输入正确的邮箱", trigger: "blur"}
                // ],
                // part: [
                //     { required: true, message: '请选择部门', trigger: 'change' },
                // ],
                // password: [
                //     { required: true, message: '请输入密码', trigger: 'blur' },
                //     { min: 6, max: 8, message: '长度在 6 到 8 个字符', trigger: 'blur' }
                // ],
                // realpass: [
                //     { required: true, message: '请再次输入密码', trigger: 'blur' },
                //     {min: 6, max: 8, message: '长度在 6 到 8 个字符', trigger: 'blur' }
                // ],
            }
        };
    },
    mounted(){
        this.showDialog = this.show
    },
    methods: {
        seeArray(){
            // console.log(this.addform.part.toString(),1)
        },
        addOk(addform){
            this.$refs[addform].validate((valid) => {
                if (valid) {
                    if(this.addform.password == this.addform.realpass){
                        let id = []
                        let array = []
                        let str = ''
                        for(var a = 0; a<this.addform.part.length;a++){
                            for(var b = 0; b<this.addform.part[a].length;b++){
                                if(b==0){
                                    str = str + this.addform.part[a][b]
                                }else{
                                    str = str + ';' + this.addform.part[a][b]
                                }
                                
                            }
                            array.push(str)
                            str = ''
                        }
                        this.addform.partArray = array.toString()
                        let item = this.addform.part
                        for(var a=0;a<item.length;a++){
                            id.push(item[a][this.addform.part[a].length-1])
                        }
                        this.addform.b_id = id
                        this.$emit('adduser',this.addform)
                    }else{
                        this.$message({
                            message:'两次输入密码不同，请重新输入',
                            type:'error'
                        })
                    }
                } else {
                    return false;
                }
            });
            
        },
        cancel(){
            this.clean()
            this.$emit('close',false)
            this.showDialog = false
        },
        clean(){
            this.addform = {
                name:'',
                mobile:'',
                email:'',
                part:[],
                role:[],
                password:'',
                realpass:'',
            }
        },
    }
}
</script>

<style lang="scss">
.adduser {
}
</style>
