<template>
	<div>
		<el-container>
			<el-aside class="navbar" style="height: 100% !important;">
				<NavBarLeft :list="navList"></NavBarLeft>
			</el-aside>
			<el-container class="right-main" style=" height: 100% !important;">
				<el-header class="header" style="padding: 0 !important;">
					<Header></Header>
				</el-header>
				<el-main class="main" style="padding: 0 !important;">
					<router-view :key="$route.fullPath" />
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>
<script>
import NavBarLeft from '@/components/Navbar.vue'
import Header from '@/components/Header.vue'
export default {
	name: 'home',
	data() {
		return {
			navList: []
		}
	},
	components: {
		NavBarLeft,
		Header
	},
	mounted() {
		this.setNavbar()
	},
	methods: {
		setNavbar() {
			let array = this.$store.state.userpower
			this.navList = array
			let bill_index = ''
			for (var a = 0; a < this.navList.length; a++) {
				let item = this.navList[a]
				if(item.name == '开票管理'){
					bill_index = a
				}
				item.path = item.route
				if (item.has_child == 1) {
					for (var b = 0; b < item.length; b++) {
						item.page[b].path = item.page[b].route
					}
				}
				if (item.name == '消息中心') {
					item.route = 'office'
				}
				if (item.name == '平台管理') {
					item.route = 'part'
				}
				if (item.name == '系统管理') {
					item.route = 'abc'
				}
			}
			this.navList.splice(bill_index+1,0,{
				comp: "house",
				comp_addr :  "house/house-main",
				has_child :  2,
				icon : "house",
				id :  33,
				name :  "房号管理",
				page : [],
				path :  "/house",
				route :  "/house",
			})
		},
	}
}

</script>

<style lang="scss">
.navbar {
	height: 100vh;
	width: auto !important;

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		// width: 80px;
		width: 220px;
	}

	.el-menu--collapse {
		width: auto !important;
	}

	.right-main {
		width: calc(1920px - 220px);

		.el-header {
			padding: 0 20px;
		}

		.header {
			height: 60px;
			box-sizing: border-box;
			padding: 0 !important;
		}

		.main {
			padding: 0 !important;
			height: calc(100% - 60px) !important;
		}

	}

	.el-aside {
		transition: width 0.15s;
		-webkit-transition: width 0.15s;
		-moz-transition: width 0.15s;
		-webkit-transition: width 0.15s;
		-o-transition: width 0.15s;
	}

	.is-opened {
		display: block;
		background: white !important;
	}
}

.el-main {
	height: calc(100vh - 60px); //181px为顶部header高度+底部footer高度
	overflow-y: auto;
}

.el-aside::-webkit-scrollbar {
	display: none;
}
</style>
