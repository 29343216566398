<template>
    <div class="home">
        <div class="main">
            <img :src="image" alt="">
            <div class="text-part">
                <div class="title">404</div>
                <div class="tip">抱歉，您访问的页面不存在~</div>
                <div class="btnpart">
                    <el-button plain class="confirm" @click="backPage">返回上一页</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    name:'home',
	data(){
		return{
            image:require('../assets/image/lost.png')
        }
	},
    mounted(){
        
    },
    methods:{
        backPage(){
            this.$router.go(-2)
        },
    }
}

</script>

<style lang="scss" scoped>
.home{
    background: #F2F5FB;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .main{
        display: flex;
        image{
            width: 500px;
            height: 413px;
        }
        .text-part{
            box-sizing: border-box;
            margin-left: 85px;
            padding-top: 15px;
            margin-right: 54px;
            .title{
                color:#8C96A6;
                font-size: 80px;
                margin-top: 6px;
            }
            .tip{
                margin-top: 30px;
                color: #B7C0CD;
                font-size: 18px;
            }
            .btnpart{
                text-align: left;
                margin-top: 60px;
            }
        }
    }
} 
</style>
  