<template>
    <div class="form">
        <div class="special-box" v-if="showList.length > 0" >
            <div class="box-item" v-if="checkShow('bank_money')">
                <div class="label-part"><span><span class="red">*</span>银行余额</span></div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入银行余额" class="input" v-model="form.bank_money.bank_balance"></el-input>
                    </div>
                    <div class="content-upload" style="margin-top: 14px;">
                        <div class="upload-line" @click="wantUpload(form.bank_money.fileList)">
                            <el-upload class="upload-element-change hover upload-button" :show-file-list="false" action=""
                                :http-request="uploadFile" accept="image/jpeg,image/png,image/jpg">
                                <local-svg iconClass="upload" class="icon"></local-svg>上传银行余额
                            </el-upload>
                            <span>最多上传9张</span>
                        </div>
                        <div class="image-part" v-show="form.bank_money.fileList.length > 0">
                            <div class="fileline" v-for="(item, index) in form.bank_money.fileList" :key="index">
                                <div class="file-box">
                                    <img :src="item.img_url" alt="" class="image hover" @click="seeImageList(index,form.bank_money.fileList)">
                                    <div class="close hover" @click.stop="delFile(index,form.bank_money.fileList)">
                                        <local-svg iconClass="close" class="closeicon"></local-svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('work_content')">
                <div class="label-part">
                    <span><span class="red">*</span>办事内容</span>
                </div>
                <div class="content-part">
                    <div class="content-input textarea-element-change">
                        <el-input placeholder="请输入办事内容" type="textarea" v-model="form.matter"></el-input>
                    </div>
                </div>
            </div>
            <!-- <div class="box-item" v-if="checkShow('legal_basic')">
                <div class="label-part"><span><span class="red" v-if="!checkShow('is_logoff_bank')">*</span>法人基本信息</span></div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入法人姓名" class="input" v-model="form.legal.card_name" :disabled="!checkShow('is_logoff_bank') "></el-input>
                        <el-input placeholder="请输入法人联系方式" class="input" v-model="form.legal.contact_way"></el-input>
                        <el-input placeholder="请输入身份证地址" class="input" v-model="form.legal.card_address"
                        :disabled="!checkShow('is_logoff_bank') "></el-input>
                        <el-input placeholder="请输入身份证号码" class="input" v-model="form.legal.card_num" :disabled="!checkShow('is_logoff_bank') "></el-input>
                        <el-input placeholder="请输入身份证有效期" class="input" v-model="form.legal.card_validity"
                        :disabled="!checkShow('is_logoff_bank') "></el-input>
                        
                    </div>
                    <div class="card-message" v-if="!checkShow('is_logoff_bank') ">
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!form.legal.card_font" class="card font fl-cloumn hover"
                                @click="uploadChange(form.legal, 1)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加头像页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(form.legal, 1)">
                                <img :src="form.legal.card_font" alt="">
                            </div>
                        </el-upload>
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!form.legal.card_back" class="card back fl-cloumn hover"
                                @click="uploadChange(form.legal, 2)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加国徽页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(form.legal, 2)">
                                <img :src="form.legal.card_back" alt="">
                            </div>
                        </el-upload>
                    </div>
                </div>
            </div> -->
            <div class="box-item" v-if="checkShow('legal')">
                <div class="label-part"><span><span class="red" v-if="!checkShow('is_logoff_bank')">*</span>法人</span></div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入法人姓名" class="input" v-model="form.legal.card_name" :disabled="!checkShow('is_logoff_bank') "></el-input>
                        <el-input placeholder="请输入法人联系方式" class="input" v-model="form.legal.contact_way"></el-input>
                        <el-input placeholder="请输入身份证地址" class="input" v-model="form.legal.card_address"
                        :disabled="!checkShow('is_logoff_bank') "></el-input>
                        <el-input placeholder="请输入身份证号码" class="input" v-model="form.legal.card_num" :disabled="!checkShow('is_logoff_bank') "></el-input>
                        <el-input placeholder="请输入身份证有效期" class="input" v-model="form.legal.card_validity"
                        :disabled="!checkShow('is_logoff_bank') "></el-input>
                        <el-input placeholder="请输入占股比例%" v-if="checkShow('legal_ratio')" class="input" v-model="form.ratio"></el-input>
                    </div>
                    <div class="card-message" v-if="!checkShow('is_logoff_bank') ">
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!form.legal.card_font" class="card font fl-cloumn hover"
                                @click="uploadChange(form.legal, 1)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加头像页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(form.legal, 1)">
                                <img :src="form.legal.card_font" alt="">
                            </div>
                        </el-upload>
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!form.legal.card_back" class="card back fl-cloumn hover"
                                @click="uploadChange(form.legal, 2)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加国徽页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(form.legal, 2)">
                                <img :src="form.legal.card_back" alt="">
                            </div>
                        </el-upload>
                    </div>
                    <div class="content-upload" v-if="!checkShow('is_change_stock') && !checkShow('is_logoff_businesstax') && !checkShow('is_logoff_bank') ">
                        <div class="upload-line" @click="wantUpload(form.legal.fileList)">
                            <el-upload class="upload-element-change hover upload-button" :show-file-list="false" action=""
                                :http-request="uploadFile" accept="image/jpeg,image/png,image/jpg">
                                <local-svg iconClass="upload" class="icon"></local-svg>上传津心办截图
                            </el-upload>
                            <span>最多上传9张</span>
                        </div>
                        <div class="image-part" v-show="form.legal.fileList.length > 0">
                            <div class="fileline" v-for="(item, index) in form.legal.fileList" :key="index">
                                <div class="file-box">
                                    <img :src="item.img_url" alt="" class="image hover" @click="seeImageList(index,form.legal.fileList)">
                                    <div class="close hover" @click.stop="delFile(index,form.legal.fileList)">
                                        <local-svg iconClass="close" class="closeicon"></local-svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('legal_old')">
                <div class="label-part">
                    <span><span class="red">*</span>原法人</span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入原法人姓名" class="input" v-model="form.legal_old.card_name"
                            disabled></el-input>
                        <el-input placeholder="请输入原法人联系方式" class="input" v-model="form.legal_old.contact_way"></el-input>
                        <el-input placeholder="请输入原法人身份证地址" class="input" v-model="form.legal_old.card_address"
                            disabled></el-input>
                        <el-input placeholder="请输入原法人身份证号码" class="input" v-model="form.legal_old.card_num"
                            disabled></el-input>
                        <el-input placeholder="请输入原法人身份证有效期" class="input" v-model="form.legal_old.card_validity"
                            disabled></el-input>
                    </div>
                    <div class="card-message">
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!form.legal_old.card_font" class="card font fl-cloumn hover"
                                @click="uploadChange(form.legal_old, 1)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加头像页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(form.legal_old, 1)">
                                <img :src="form.legal_old.card_font" alt="">
                            </div>
                        </el-upload>
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!form.legal_old.card_back" class="card back fl-cloumn hover"
                                @click="uploadChange(form.legal_old, 2)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加国徽页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(form.legal_old, 2)">
                                <img :src="form.legal_old.card_back" alt="">
                            </div>
                        </el-upload>
                    </div>
                    <div class="content-upload">
                        <div class="upload-line" @click="wantUpload(form.legal_old.fileList)">
                            <el-upload class="upload-element-change hover upload-button" :show-file-list="false" action=""
                                :http-request="uploadFile" accept="image/jpeg,image/png,image/jpg">
                                <local-svg iconClass="upload" class="icon"></local-svg>上传津心办截图
                            </el-upload>
                            <span>最多上传9张</span>
                        </div>
                        <div class="image-part" v-show="form.legal_old.fileList.length > 0">
                            <div class="fileline" v-for="(item, index) in form.legal_old.fileList" :key="index">
                                <div class="file-box">
                                    <img :src="item.img_url" alt="" class="image hover" @click="seeImageList(index,form.legal_old.fileList)">
                                    <div class="close hover" @click.stop="delFile(index,form.legal_old.fileList)">
                                        <local-svg iconClass="close" class="closeicon"></local-svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('legal_new')">
                <div class="label-part">
                    <span><span class="red">*</span>新法人</span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入新法人姓名" class="input" v-model="form.legal_new.card_name"
                            disabled></el-input>
                        <el-input placeholder="请输入新法人联系方式" class="input" v-model="form.legal_new.contact_way"></el-input>
                        <el-input placeholder="请输入新法人身份证地址" class="input" v-model="form.legal_new.card_address"
                            disabled></el-input>
                        <el-input placeholder="请输入新法人身份证号码" class="input" v-model="form.legal_new.card_num"
                            disabled></el-input>
                        <el-input placeholder="请输入新法人身份证有效期" class="input" v-model="form.legal_new.card_validity"
                            disabled></el-input>
                    </div>
                    <div class="card-message">
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!form.legal_new.card_font" class="card font fl-cloumn hover"
                                @click="uploadChange(form.legal_new, 1)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加头像页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(form.legal_new, 1)">
                                <img :src="form.legal_new.card_font" alt="">
                            </div>
                        </el-upload>
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!form.legal_new.card_back" class="card back fl-cloumn hover"
                                @click="uploadChange(form.legal_new, 2)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加国徽页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(form.legal_new, 2)">
                                <img :src="form.legal_new.card_back" alt="">
                            </div>
                        </el-upload>
                    </div>
                    <div class="content-upload">
                        <div class="upload-line" @click="wantUpload(form.legal_new.fileList)">
                            <el-upload class="upload-element-change hover upload-button" :show-file-list="false" action=""
                                :http-request="uploadFile" accept="image/jpeg,image/png,image/jpg">
                                <local-svg iconClass="upload" class="icon"></local-svg>上传津心办截图
                            </el-upload>
                            <span>最多上传9张</span>
                        </div>
                        <div class="image-part" v-show="form.legal_new.fileList.length > 0">
                            <div class="fileline" v-for="(item, index) in form.legal_new.fileList" :key="index">
                                <div class="file-box">
                                    <img :src="item.img_url" alt="" class="image hover" @click="seeImageList(index,form.legal_new.fileList)">
                                    <div class="close hover" @click.stop="delFile(index,form.legal_new.fileList)">
                                        <local-svg iconClass="close" class="closeicon"></local-svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="box-item" v-show="checkShow('shareholder_basic')" v-for="(item, index) in form.shareholder" :key="index">
                <div class="label-part">
                    <span><span class="red">*</span>股东{{ form.shareholder.length > 1 ? index + 1 : '' }}基本信息
                        <local-svg v-if="index == 0" iconClass="add" class="icon handel-icon hover"
                            @click.native="addShareholder(form.shareholder)"></local-svg>
                        <local-svg v-else iconClass="cut" class="icon handel-icon hover"
                        @click.native="delShareholder(form.shareholder,new_index)"></local-svg>
                    </span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入股东姓名" class="input" v-model="item.card_name" disabled></el-input>
                        <el-input placeholder="请输入股东联系方式" class="input" v-model="item.contact_way"></el-input>
                        <el-input placeholder="请输入股东身份证地址" class="input" v-model="item.card_address" disabled></el-input>
                        <el-input placeholder="请输入股东身份证号码" class="input" v-model="item.card_num" disabled></el-input>
                        <el-input placeholder="请输入股东身份证有效期" class="input" v-model="item.card_validity" disabled></el-input>
                    </div>
                    <div class="card-message">
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!item.card_font" class="card font fl-cloumn hover" @click="uploadChange(item, 1)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加头像页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(item, 1)">
                                <img :src="item.card_font" alt="">
                            </div>
                        </el-upload>
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!item.card_back" class="card back fl-cloumn hover" @click="uploadChange(item, 2)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加国徽页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(item, 2)">
                                <img :src="item.card_back" alt="">
                            </div>
                        </el-upload>
                    </div>
                </div>
            </div> -->
            <div class="box-item" v-show="checkShow('shareholder')" v-for="(item, index) in form.shareholder" :key="index">
                <div class="label-part">
                    <span><span class="red"  v-if="!checkShow('is_common_model')">*</span>股东{{ form.shareholder.length > 1 ? index + 1 : '' }}
                        <local-svg v-if="index == 0" iconClass="add" class="icon handel-icon hover"
                            @click.native="addShareholder(form.shareholder)"></local-svg>
                        <local-svg v-else iconClass="cut" class="icon handel-icon hover"
                        @click.native="delShareholder(form.shareholder,index)"></local-svg>
                    </span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入股东姓名" class="input" v-model="item.card_name" disabled></el-input>
                        <el-input placeholder="请输入股东联系方式" class="input" v-model="item.contact_way"></el-input>
                        <el-input placeholder="请输入股东身份证地址" class="input" v-model="item.card_address" disabled></el-input>
                        <el-input placeholder="请输入股东身份证号码" class="input" v-model="item.card_num" disabled></el-input>
                        <el-input placeholder="请输入股东身份证有效期" class="input" v-model="item.card_validity" disabled></el-input>
                        <el-input placeholder="请输入占股比例%"  v-if="checkShow('shareholder_ratio')" class="input" v-model="item.ratio"></el-input>
                    </div>
                    <div class="card-message">
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!item.card_font" class="card font fl-cloumn hover" @click="uploadChange(item, 1)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加头像页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(item, 1)">
                                <img :src="item.card_font" alt="">
                            </div>
                        </el-upload>
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!item.card_back" class="card back fl-cloumn hover" @click="uploadChange(item, 2)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加国徽页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(item, 2)">
                                <img :src="item.card_back" alt="">
                            </div>
                        </el-upload>
                    </div>
                    <div class="content-upload" v-if="!checkShow('is_logoff_businesstax')">
                        <div class="upload-line" @click="wantUpload(item.fileList)">
                            <el-upload class="upload-element-change hover upload-button" :show-file-list="false" action=""
                                :http-request="uploadFile" accept="image/jpeg,image/png,image/jpg">
                                <local-svg iconClass="upload" class="icon"></local-svg>上传津心办截图
                            </el-upload>
                            <span>最多上传9张</span>
                        </div>
                        <div class="image-part" v-show="item.fileList.length > 0">
                            <div class="fileline" v-for="(image, sonIndex) in item.fileList" :key="sonIndex">
                                <div class="file-box">
                                    <img :src="image.img_url" alt="" class="image hover" @click="seeImageList(sonIndex,item.fileList)">
                                    <div class="close hover" @click.stop="delFile(sonIndex,item.fileList)">
                                        <local-svg iconClass="close" class="closeicon"></local-svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-item" v-show="checkShow('shareholder_old')" v-for="(item, old_index) in form.shareholder_old"
                :key="old_index">
                <div class="label-part">
                    <span><span class="red">*</span>原股东{{ form.shareholder_old.length > 1 ? old_index + 1 : '' }}
                        <local-svg v-if="old_index == 0" iconClass="add" class="icon handel-icon hover"
                            @click.native="addShareholder(form.shareholder_old)"></local-svg>
                        <local-svg v-else iconClass="cut" class="icon handel-icon hover"
                        @click.native="delShareholder(form.shareholder_old,new_index)"></local-svg>
                    </span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入原股东姓名" class="input" v-model="item.card_name" disabled></el-input>
                        <el-input placeholder="请输入原股东联系方式" class="input" v-model="item.contact_way"></el-input>
                        <el-input placeholder="请输入原股东身份证地址" class="input" v-model="item.card_address"
                            disabled></el-input>
                        <el-input placeholder="请输入原股东身份证号码" class="input" v-model="item.card_num" disabled></el-input>
                        <el-input placeholder="请输入原股东身份证有效期" class="input" v-model="item.card_validity"
                            disabled></el-input>
                    </div>
                    <div class="card-message">
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!item.card_font" class="card font fl-cloumn hover" @click="uploadChange(item, 1)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加头像页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(item, 1)">
                                <img :src="item.card_font" alt="">
                            </div>
                        </el-upload>
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!item.card_back" class="card back fl-cloumn hover" @click="uploadChange(item, 2)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加国徽页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(item, 2)">
                                <img :src="item.card_back" alt="">
                            </div>
                        </el-upload>
                    </div>
                    <div class="content-upload" v-if="!checkShow('is_change_stock')">
                        <div class="upload-line" @click="wantUpload(item.fileList)">
                            <el-upload class="upload-element-change hover upload-button" :show-file-list="false"
                                action="" :http-request="uploadFile" accept="image/jpeg,image/png,image/jpg">
                                <local-svg iconClass="upload" class="icon"></local-svg>上传津心办截图
                            </el-upload>
                            <span>最多上传9张</span>
                        </div>
                        <div class="image-part" v-show="item.fileList.length > 0">
                            <div class="fileline" v-for="(image, sonIndex) in item.fileList" :key="sonIndex">
                                <div class="file-box">
                                    <img :src="image.img_url" alt="" class="image hover"
                                        @click="seeImageList(sonIndex,item.fileList)">
                                    <div class="close hover" @click.stop="delFile(sonIndex,item.fileList)">
                                        <local-svg iconClass="close" class="closeicon"></local-svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-item" v-show="checkShow('shareholder_new')" v-for="(item, new_index) in form.shareholder_new"
                :key="new_index">
                <div class="label-part">
                    <span><span class="red">*</span>新股东{{ form.shareholder_new.length > 1 ? new_index + 1 : '' }}
                        <local-svg v-if="new_index == 0" iconClass="add" class="icon handel-icon hover"
                            @click.native="addShareholder(form.shareholder_new)"></local-svg>
                        <local-svg v-else iconClass="cut" class="icon handel-icon hover"
                        @click.native="delShareholder(form.shareholder_new,new_index)"></local-svg>
                    </span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入新股东姓名" class="input" v-model="item.card_name" disabled></el-input>
                        <el-input placeholder="请输入新股东联系方式" class="input" v-model="item.contact_way"></el-input>
                        <el-input placeholder="请输入新股东身份证地址" class="input" v-model="item.card_address"
                            disabled></el-input>
                        <el-input placeholder="请输入新股东身份证号码" class="input" v-model="item.card_num" disabled></el-input>
                        <el-input placeholder="请输入新股东身份证有效期" class="input" v-model="item.card_validity"
                            disabled></el-input>
                    </div>
                    <div class="card-message">
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!item.card_font" class="card font fl-cloumn hover" @click="uploadChange(item, 1)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加头像页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(item, 1)">
                                <img :src="item.card_font" alt="">
                            </div>
                        </el-upload>
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!item.card_back" class="card back fl-cloumn hover" @click="uploadChange(item, 2)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加国徽页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(item, 2)">
                                <img :src="item.card_back" alt="">
                            </div>
                        </el-upload>
                    </div>
                    <div class="content-upload" v-if="!checkShow('is_change_stock')">
                        <div class="upload-line" @click="wantUpload(item.fileList)">
                            <el-upload class="upload-element-change hover upload-button" :show-file-list="false"
                                action="" :http-request="uploadFile" accept="image/jpeg,image/png,image/jpg">
                                <local-svg iconClass="upload" class="icon"></local-svg>上传津心办截图
                            </el-upload>
                            <span>最多上传9张</span>
                        </div>
                        <div class="image-part" v-show="item.fileList.length > 0">
                            <div class="fileline" v-for="(image, sonIndex) in item.fileList" :key="sonIndex">
                                <div class="file-box">
                                    <img :src="image.img_url" alt="" class="image hover"
                                        @click="seeImageList(sonIndex,item.fileList)">
                                    <div class="close hover" @click.stop="delFile(sonIndex,item.fileList)">
                                        <local-svg iconClass="close" class="closeicon"></local-svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-item" v-show="checkShow('supervisor_border')" v-for="(item, index) in form.supervisor_border" :key="index">
                <div class="label-part">
                    <span><span class="red" v-if="!checkShow('is_common_model')">*</span>监事{{ form.supervisor_border.length > 1 ? index + 1 : '' }}
                        <local-svg v-if="index == 0" iconClass="add" class="icon handel-icon hover"
                            @click.native="addShareholder(form.supervisor_border)"></local-svg>
                        <local-svg v-else iconClass="cut" class="icon handel-icon hover"
                        @click.native="delShareholder(form.supervisor_border,index)"></local-svg>
                    </span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入监事姓名" class="input" v-model="item.card_name" disabled></el-input>
                        <el-input placeholder="请输入监事联系方式" class="input" v-model="item.contact_way"></el-input>
                        <el-input placeholder="请输入监事身份证地址" class="input" v-model="item.card_address" disabled></el-input>
                        <el-input placeholder="请输入监事身份证号码" class="input" v-model="item.card_num" disabled></el-input>
                        <el-input placeholder="请输入监事身份证有效期" class="input" v-model="item.card_validity" disabled></el-input>
                    </div>
                    <div class="card-message">
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!item.card_font" class="card font fl-cloumn hover" @click="uploadChange(item, 1)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加头像页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(item, 1)">
                                <img :src="item.card_font" alt="">
                            </div>
                        </el-upload>
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!item.card_back" class="card back fl-cloumn hover" @click="uploadChange(item, 2)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加国徽页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(item, 2)">
                                <img :src="item.card_back" alt="">
                            </div>
                        </el-upload>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('supervisor')">
                <div class="label-part">
                    <span><span class="red">*</span>监事</span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入监事姓名" class="input" v-model="form.supervisor.card_name"
                            disabled></el-input>
                        <el-input placeholder="请输入监事联系方式" class="input" v-model="form.supervisor.contact_way"></el-input>
                        <el-input placeholder="请输入监事身份证地址" class="input" v-model="form.supervisor.card_address"
                            disabled></el-input>
                        <el-input placeholder="请输入监事身份证号码" class="input" v-model="form.supervisor.card_num"
                            disabled></el-input>
                        <el-input placeholder="请输入监事身份证有效期" class="input" v-model="form.supervisor.card_validity"
                            disabled></el-input>
                    </div>
                    <div class="card-message">
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!form.supervisor.card_font" class="card font fl-cloumn hover"
                                @click="uploadChange(form.supervisor, 1)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加头像页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(form.supervisor, 1)">
                                <img :src="form.supervisor.card_font" alt="">
                            </div>
                        </el-upload>
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!form.supervisor.card_back" class="card back fl-cloumn hover"
                                @click="uploadChange(form.supervisor, 2)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加国徽页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(form.supervisor, 2)">
                                <img :src="form.supervisor.card_back" alt="">
                            </div>
                        </el-upload>
                    </div>
                    <div class="content-upload" v-if="!checkShow('is_change_stock') && !checkShow('is_logoff_businesstax')">
                        <div class="upload-line" @click="wantUpload(form.supervisor.fileList)">
                            <el-upload class="upload-element-change hover upload-button" :show-file-list="false" action=""
                                :http-request="uploadFile" accept="image/jpeg,image/png,image/jpg">
                                <local-svg iconClass="upload" class="icon"></local-svg>上传津心办截图
                            </el-upload>
                            <span>最多上传9张</span>
                        </div>
                        <div class="image-part" v-show="form.supervisor.fileList.length > 0">
                            <div class="fileline" v-for="(item, index) in form.supervisor.fileList" :key="index">
                                <div class="file-box">
                                    <img :src="item.img_url" alt="" class="image hover" @click="seeImageList(index,form.supervisor.fileList)">
                                    <div class="close hover" @click.stop="delFile(index,form.supervisor.fileList)">
                                        <local-svg iconClass="close" class="closeicon"></local-svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('supervisor_old')">
                <div class="label-part">
                    <span><span class="red">*</span>原监事</span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入原监事姓名" class="input" v-model="form.supervisor_old.card_name"
                            disabled></el-input>
                        <el-input placeholder="请输入原监事联系方式" class="input"
                            v-model="form.supervisor_old.contact_way"></el-input>
                        <el-input placeholder="请输入原监事身份证地址" class="input" v-model="form.supervisor_old.card_address"
                            disabled></el-input>
                        <el-input placeholder="请输入原监事身份证号码" class="input" v-model="form.supervisor_old.card_num"
                            disabled></el-input>
                        <el-input placeholder="请输入原监事身份证有效期" class="input" v-model="form.supervisor_old.card_validity"
                            disabled></el-input>
                    </div>
                    <div class="card-message">
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!form.supervisor_old.card_font" class="card font fl-cloumn hover"
                                @click="uploadChange(form.supervisor_old, 1)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加头像页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(form.supervisor_old, 1)">
                                <img :src="form.supervisor_old.card_font" alt="">
                            </div>
                        </el-upload>
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!form.supervisor_old.card_back" class="card back fl-cloumn hover"
                                @click="uploadChange(form.supervisor_old, 2)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加国徽页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(form.supervisor_old, 2)">
                                <img :src="form.supervisor_old.card_back" alt="">
                            </div>
                        </el-upload>
                    </div>
                    <div class="content-upload">
                        <div class="upload-line" @click="wantUpload(form.supervisor_old.fileList)">
                            <el-upload class="upload-element-change hover upload-button" :show-file-list="false" action=""
                                :http-request="uploadFile" accept="image/jpeg,image/png,image/jpg">
                                <local-svg iconClass="upload" class="icon"></local-svg>上传津心办截图
                            </el-upload>
                            <span>最多上传9张</span>
                        </div>
                        <div class="image-part" v-show="form.supervisor_old.fileList.length > 0">
                            <div class="fileline" v-for="(item, index) in form.supervisor_old.fileList" :key="index">
                                <div class="file-box">
                                    <img :src="item.img_url" alt="" class="image hover" @click="seeImageList(index,form.supervisor_old.fileList)">
                                    <div class="close hover" @click.stop="delFile(index,form.supervisor_old.fileList)">
                                        <local-svg iconClass="close" class="closeicon"></local-svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('supervisor_new')">
                <div class="label-part">
                    <span><span class="red">*</span>新监事</span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入新监事姓名" class="input" v-model="form.supervisor_new.card_name"
                            disabled></el-input>
                        <el-input placeholder="请输入新监事联系方式" class="input"
                            v-model="form.supervisor_new.contact_way"></el-input>
                        <el-input placeholder="请输入新监事身份证地址" class="input" v-model="form.supervisor_new.card_address"
                            disabled></el-input>
                        <el-input placeholder="请输入新监事身份证号码" class="input" v-model="form.supervisor_new.card_num"
                            disabled></el-input>
                        <el-input placeholder="请输入新监事身份证有效期" class="input" v-model="form.supervisor_new.card_validity"
                            disabled></el-input>
                    </div>
                    <div class="card-message">
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!form.supervisor_new.card_font" class="card font fl-cloumn hover"
                                @click="uploadChange(form.supervisor_new, 1)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加头像页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(form.supervisor_new, 1)">
                                <img :src="form.supervisor_new.card_font" alt="">
                            </div>
                        </el-upload>
                        <el-upload class="hover" :show-file-list="false" action="" :http-request="uploadFile"
                            accept="image/jpeg,image/png,image/jpg">
                            <div v-if="!form.supervisor_new.card_back" class="card back fl-cloumn hover"
                                @click="uploadChange(form.supervisor_new, 2)">
                                <local-svg iconClass="addcard" class="icon"></local-svg>
                                <span>添加国徽页</span>
                            </div>
                            <div v-else class="card fl-cloumn hover" @click="uploadChange(form.supervisor_new, 2)">
                                <img :src="form.supervisor_new.card_back" alt="">
                            </div>
                        </el-upload>
                    </div>
                    <div class="content-upload">
                        <div class="upload-line" @click="wantUpload(form.supervisor_new.fileList)">
                            <el-upload class="upload-element-change hover upload-button" :show-file-list="false" action=""
                                :http-request="uploadFile" accept="image/jpeg,image/png,image/jpg">
                                <local-svg iconClass="upload" class="icon"></local-svg>上传津心办截图
                            </el-upload>
                            <span>最多上传9张</span>
                        </div>
                        <div class="image-part" v-show="form.supervisor_new.fileList.length > 0">
                            <div class="fileline" v-for="(item, index) in form.supervisor_new.fileList" :key="index">
                                <div class="file-box">
                                    <img :src="item.img_url" alt="" class="image hover" @click="seeImageList(index,form.supervisor_new.fileList)">
                                    <div class="close hover" @click.stop="delFile(index,form.supervisor_new.fileList)">
                                        <local-svg iconClass="close" class="closeicon"></local-svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-item" v-show="checkShow('alt_company_title')">
                <div class="label-part">
                    <span><span class="red">*</span>备选公司名称</span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change" :style="{'margin-bottom' : index == form.companyList.length - 1 ? '0rpx':'12px'}" v-for="(item,index) in form.companyList" :key="index">
                        <el-input placeholder="请输入备选名称" class="input" v-model="item.name" ></el-input>
                        <local-svg v-if="index == 0" iconClass="add" class="icon handel-icon hover"
                            @click.native="addCompanyName"></local-svg>
                        <local-svg v-else iconClass="cut" class="icon handel-icon hover"
                        @click.native="delCompanyName(index)"></local-svg>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('region')">
                <div class="label-part">
                    <span><span class="red">*</span>所属区域</span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change select-element-change">
                        <el-select ref="selectRef" v-model="form.region" placeholder="请选择" clearable style="width:230px" filterable>
                            <el-option v-for="item in regionList" :label="item.value" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('business_range')">
                <div class="label-part">
                    <span>经营范围</span>
                </div>
                <div class="content-part">
                    <div class="content-input textarea-element-change">
                        <el-input placeholder="请输入经营范围" type="textarea" v-model="form.business_scope"></el-input>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('business_range_new')">
                <div class="label-part">
                    <span><span class="red">*</span>新经营范围</span>
                </div>
                <div class="content-part">
                    <div class="content-input textarea-element-change">
                        <el-input placeholder="请输入新经营范围" type="textarea" v-model="form.new_business_scope"></el-input>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('company_name')">
                <div class="label-part">
                    <span><span class="red">*</span>公司名称</span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入公司名称" class="input" v-model="form.company_title"></el-input>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('company_name_new')">
                <div class="label-part">
                    <span><span class="red">*</span>新公司名称</span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入新公司名称" class="input" v-model="form.new_company_title"></el-input>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('regist_money')">
                <div class="label-part">
                    <span>注册资金</span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入注册资金" class="input" v-model="form.register_capital"></el-input>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('register_address')">
                <div class="label-part">
                    <span>注册地址</span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入注册地址" class="input" v-model="form.register_address"></el-input>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('ratio')">
                <div class="label-part">
                    <span><span class="red">*</span>占股比例</span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入占股比例" class="input" v-model="form.ratio"></el-input>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('change_money')">
                <div class="label-part">
                    <span><span class="red">*</span>变更金额</span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入变更金额" class="input" v-model="form.change_money"></el-input>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('credit_code')">
                <div class="label-part">
                    <span><span class="red">*</span>纳税人识别号</span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入纳税人识别号" class="input" v-model="form.social_credit_code"></el-input>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('special_require')">
                <div class="label-part">
                    <span>客户特殊要求</span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入客户特殊要求" class="input" v-model="form.special_require"></el-input>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('paper_tax')">
                <div class="label-part">
                    <span><span class="red">*</span>核定税率</span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change select-element-change">
                        <el-select ref="selectRef" v-model="form.paper_tax" placeholder="请选择" clearable style="width:230px">
                            <el-option v-for="item in taxList" :label="item.value" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('room_num')">
                <div class="label-part">
                    <span><span class="red">*</span>房号区域</span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change select-element-change">
                        <el-select ref="selectRef" v-model="form.room_num" placeholder="请选择" clearable style="width:230px">
                            <el-option v-for="item in houseList" :label="item.value" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('postcode')">
                <div class="label-part">
                    <span>邮编</span>
                </div>
                <div class="content-part">
                    <div class="content-input input-element-change">
                        <el-input placeholder="请输入邮编" class="input" v-model="form.postcode"></el-input>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('is_tally')">
                <div class="label-part">
                    <span><span class="red">*</span>是否记账</span>
                </div>
                <div class="content-part radio-element-change">
                    <div class="check-select">
                        <el-radio-group v-model="form.is_tally">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="0">否</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('is_local')">
                <div class="label-part">
                    <span><span class="red">*</span>购买方主体是否本地</span>
                </div>
                <div class="content-part radio-element-change">
                    <div class="check-select">
                        <el-radio-group v-model="form.is_local">
                            <el-radio :label="2">是</el-radio>
                            <el-radio :label="3">否</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('legal_person_identity_card_script')">
                <div class="label-part">
                    <span><span class="red">*</span>法人身份证原件</span>
                </div>
                <div class="content-part radio-element-change">
                    <div class="check-select">
                        <el-radio-group v-model="form.legal_person_identity_card_script">
                            <el-radio :label="2">是</el-radio>
                            <el-radio :label="1">否</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('legal_corporate_seal')">
                <div class="label-part">
                    <span><span class="red">*</span>公章、法人章</span>
                </div>
                <div class="content-part radio-element-change">
                    <div class="check-select">
                        <el-radio-group v-model="form.legal_corporate_seal">
                            <el-radio :label="2">是</el-radio>
                            <el-radio :label="1">否</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('license_type')">
                <div class="label-part">
                    <span><span class="red">*</span>营业执照正副本</span>
                </div>
                <div class="content-part radio-element-change">
                    <div class="check-select">
                        <el-radio-group v-model="form.license_type">
                            <el-radio :label="2">是</el-radio>
                            <el-radio :label="1">否</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('license')">
                <div class="label-part">
                    <span><span class="red">*</span>开户许可证原件</span>
                </div>
                <div class="content-part radio-element-change">
                    <div class="check-select">
                        <el-radio-group v-model="form.open_account">
                            <el-radio :label="2">是</el-radio>
                            <el-radio :label="1">否</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('house_contract')">
                <div class="label-part">
                    <span><span class="red">*</span>租房合同扫描件或照片</span>
                </div>
                <div class="content-part">
                    <div class="content-upload">
                        <div class="upload-line" @click="wantUpload(form.lease_contract)">
                            <el-upload class="upload-element-change hover upload-button" :show-file-list="false" action=""
                                :http-request="uploadFile" accept="image/jpeg,image/png,image/jpg">
                                <local-svg iconClass="upload" class="icon"></local-svg>上传文件
                            </el-upload>
                            <span>最多上传9张</span>
                        </div>
                        <div class="file-part">
                            <div class="file-item hover" v-for="(item, index) in form.lease_contract" :key="index" @click="seeFile(item)">
                                <div>
                                    <local-svg iconClass="file" class="icon"></local-svg>
                                    <span class="name">{{ item.name }}</span>
                                </div>
                                <div class="close" @click.stop="delFile(index,form.lease_contract)">
                                    <local-svg iconClass="close" class="closeicon"></local-svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('certificate')">
                <div class="label-part">
                    <span><span class="red">*</span>房产证扫描件或照片</span>
                </div>
                <div class="content-part">
                    <div class="content-upload">
                        <div class="upload-line" @click="wantUpload(form.house_property)">
                            <el-upload class="upload-element-change hover upload-button" :show-file-list="false" action=""
                                :http-request="uploadFile" accept="image/jpeg,image/png,image/jpg">
                                <local-svg iconClass="upload" class="icon"></local-svg>上传文件
                            </el-upload>
                            <span>最多上传9张</span>
                        </div>
                        <div class="file-part">
                            <div class="file-item hover" v-for="(item, index) in form.house_property" :key="index" @click="seeFile(item)">
                                <div>
                                    <local-svg iconClass="file" class="icon"></local-svg>
                                    <span class="name">{{ item.name }}</span>
                                </div>
                                <div class="close" @click.stop="delFile(index,form.house_property)">
                                    <local-svg iconClass="close" class="closeicon"></local-svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('tax')">
                <div class="label-part">
                    <span><span class="red">*</span>税控盘</span>
                </div>
                <div class="content-part radio-element-change">
                    <div class="check-select">
                        <el-radio-group v-model="form.tax_tray">
                            <el-radio :label="2">是</el-radio>
                            <el-radio :label="1">否</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('bill')">
                <div class="label-part">
                    <span><span class="red">*</span>空白发票</span>
                </div>
                <div class="content-part radio-element-change">
                    <div class="check-select">
                        <el-radio-group v-model="form.invoice">
                            <el-radio :label="2">是</el-radio>
                            <el-radio :label="1">否</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('book')">
                <div class="label-part">
                    <span><span class="red">*</span>发票领购簿</span>
                </div>
                <div class="content-part radio-element-change">
                    <div class="check-select">
                        <el-radio-group v-model="form.Invoice_book">
                            <el-radio :label="2">是</el-radio>
                            <el-radio :label="1">否</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('seal')">
                <div class="label-part">
                    <span><span class="red">*</span>公章</span>
                </div>
                <div class="content-part radio-element-change">
                    <div class="check-select">
                        <el-radio-group v-model="form.official_seal">
                            <el-radio :label="2">是</el-radio>
                            <el-radio :label="1">否</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('legal_person_official_seal')">
                <div class="label-part">
                    <span><span class="red">*</span>公章、法人章</span>
                </div>
                <div class="content-part">
                    <div class="content-upload">
                        <div class="upload-line" @click="wantUpload(form.legal_person_official_seal)">
                            <el-upload class="upload-element-change hover upload-button" :show-file-list="false" action=""
                                :http-request="uploadFile" accept="image/jpeg,image/png,image/jpg">
                                <local-svg iconClass="upload" class="icon"></local-svg>上传文件
                            </el-upload>
                            <span>最多上传9张</span>
                        </div>
                        <div class="file-part">
                            <div class="file-item hover" v-for="(item, index) in form.legal_person_official_seal" @click="seeFile(item)"
                                :key="index">
                                <div>
                                    <local-svg iconClass="file" class="icon"></local-svg>
                                    <span class="name">{{ item.name }}</span>
                                </div>
                                <div class="close" @click.stop="delFile(index,form.legal_person_official_seal)">
                                    <local-svg iconClass="close" class="closeicon"></local-svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('financial_seal')">
                <div class="label-part">
                    <span><span class="red">*</span>财务章原件</span>
                </div>
                <div class="content-part radio-element-change">
                    <div class="check-select">
                        <el-radio-group v-model="form.financial_seal">
                            <el-radio :label="2">是</el-radio>
                            <el-radio :label="1">否</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('licence')">
                <div class="label-part">
                    <span><span class="red">*</span>营业执照正副本扫描件或照片及原件</span>
                </div>
                <div class="content-part">
                    <div class="content-upload">
                        <div class="upload-line" @click="wantUpload(form.license_type_file)">
                            <el-upload class="upload-element-change hover upload-button" :show-file-list="false" action=""
                                :http-request="uploadFile" accept="image/jpeg,image/png,image/jpg">
                                <local-svg iconClass="upload" class="icon"></local-svg>上传文件
                            </el-upload>
                            <span>最多上传9张</span>
                        </div>
                        <div class="file-part">
                            <div class="file-item hover" v-for="(item, index) in form.license_type_file" :key="index" @click="seeFile(item)">
                                <div>
                                    <local-svg iconClass="file" class="icon"></local-svg>
                                    <span class="name">{{ item.name }}</span>
                                </div>
                                <div class="close" @click.stop="delFile(index,form.license_type_file)">
                                    <local-svg iconClass="close" class="closeicon"></local-svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('receipt')">
                <div class="label-part">
                    <span><span class="red">*</span>注销回执</span>
                </div>
                <div class="content-part">
                    <div class="content-upload">
                        <div class="upload-line" @click="wantUpload(form.cancel_receipt_file)">
                            <el-upload class="upload-element-change hover upload-button" :show-file-list="false" action=""
                                :http-request="uploadFile" accept="image/jpeg,image/png,image/jpg">
                                <local-svg iconClass="upload" class="icon"></local-svg>上传文件
                            </el-upload>
                            <span>最多上传9张</span>
                        </div>
                        <div class="file-part">
                            <div class="file-item hover" v-for="(item, index) in form.cancel_receipt_file" :key="index" @click="seeFile(item)">
                                <div>
                                    <local-svg iconClass="file" class="icon"></local-svg>
                                    <span class="name">{{ item.name }}</span>
                                </div>
                                <div class="close" @click.stop="delFile(index,form.cancel_receipt_file)">
                                    <local-svg iconClass="close" class="closeicon"></local-svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('cancel_receipt')">
                <div class="label-part">
                    <span><span class="red">*</span>注销回执</span>
                </div>
                <div class="content-part radio-element-change">
                    <div class="check-select">
                        <el-radio-group v-model="form.cancel_receipt">
                            <el-radio :label="2">是</el-radio>
                            <el-radio :label="1">否</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('E-bank')">
                <div class="label-part">
                    <span><span class="red">*</span>网银</span>
                </div>
                <div class="content-part radio-element-change">
                    <div class="check-select">
                        <el-radio-group v-model="form.e_bank">
                            <el-radio :label="2">是</el-radio>
                            <el-radio :label="1">否</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('coder')">
                <div class="label-part">
                    <span><span class="red">*</span>密码器</span>
                </div>
                <div class="content-part radio-element-change">
                    <div class="check-select">
                        <el-radio-group v-model="form.cipher">
                            <el-radio :label="2">是</el-radio>
                            <el-radio :label="1">否</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('seal_legal')">
                <div class="label-part">
                    <span><span class="red">*</span>法人章</span>
                </div>
                <div class="content-part radio-element-change">
                    <div class="check-select">
                        <el-radio-group v-model="form.corporate_seal">
                            <el-radio :label="2">是</el-radio>
                            <el-radio :label="1">否</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('legal_card')">
                <div class="label-part">
                    <span><span class="red">*</span>法人身份证复印件</span>
                </div>
                <div class="content-part">
                    <div class="content-upload">
                        <div class="upload-line" @click="wantUpload(form.legal_person_identity_card_copy)">
                            <el-upload class="upload-element-change hover upload-button" :show-file-list="false" action=""
                                :http-request="uploadFile" accept="image/jpeg,image/png,image/jpg">
                                <local-svg iconClass="upload" class="icon"></local-svg>上传文件
                            </el-upload>
                            <span>最多上传9张</span>
                        </div>
                        <div class="file-part">
                            <div class="file-item hover" v-for="(item, index) in form.legal_person_identity_card_copy" @click="seeFile(item)"
                                :key="index">
                                <div>
                                    <local-svg iconClass="file" class="icon"></local-svg>
                                    <span class="name">{{ item.name }}</span>
                                </div>
                                <div class="close" @click.stop="delFile(index,form.legal_person_identity_card_copy)">
                                    <local-svg iconClass="close" class="closeicon"></local-svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('stock_conviersion')">
                <div class="label-part">
                    <span><span class="red">*</span>转股明细</span>
                </div>
                <div class="content-part">
                    <div class="content-input textarea-element-change">
                        <el-input placeholder="请输入转股明细" type="textarea" v-model="form.stock_conviersion"></el-input>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('abnormal')">
                <div class="label-part">
                    <span><span class="red">*</span>具体异常原因</span>
                </div>
                <div class="content-part">
                    <div class="content-input textarea-element-change">
                        <el-input placeholder="请输入具体异常原因" type="textarea" v-model="form.abnormal_cause"></el-input>
                    </div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('remark')">
                <div class="label-part">
                    <span>备注</span>
                </div>
                <div class="content-part">
                    <div class="content-input textarea-element-change">
                        <el-input placeholder="请输入备注" type="textarea" v-model="form.remark"></el-input>
                    </div>
                    <div style="color:#F74B4B;font-size: 12px;margin-top: 8px ">请务必写清办理内容</div>
                </div>
            </div>
            <div class="box-item" v-if="checkShow('files')">
                <div class="label-part">
                    <span>上传附件</span>
                </div>
                <div class="content-part">
                    <div class="content-upload">
                        <div class="upload-line" @click="wantUpload(form.files)">
                            <el-upload class="upload-element-change hover upload-button" :show-file-list="false" action=""
                                :http-request="uploadFile" accept="image/jpeg,image/png,image/jpg">
                                <local-svg iconClass="upload" class="icon"></local-svg>上传文件
                            </el-upload>
                            <span>最多上传9张</span>
                        </div>
                        <div class="file-part">
                            <div class="file-item hover" v-for="(item, index) in form.files" :key="index" @click="seeFile(item)">
                                <div>
                                    <local-svg iconClass="file" class="icon"></local-svg>
                                    <span class="name">{{ item.name }}</span>
                                </div>
                                <div class="close" @click.stop="delFile(index,form.files)">
                                    <local-svg iconClass="close" class="closeicon"></local-svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <showImage :previewImg="imageData"></showImage>
    </div>
</template>

<script>

import md5 from 'js-md5'
import service from "@/utils/outrequest.js";
import { Loading } from 'element-ui';
import { cardinfo } from '@/api/outwork'
import showImage from '@/components/showImage.vue'
export default {
    name: 'home',
    components:{
        showImage
    },
    props: {
        showList: {
            type: Array,
            default: () => {
                return []
            }
        },
        taxList: {
            type: Array,
            default: () => {
                return []
            }
        },
        changeForm: {
            type: Object,
            default: () => {
                return {}
            }
        },
        regionList:{
            type: Array,
            default: () => {
                return []
            }
        },
        houseList:{
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            form: {
                bank_money: {
                    bank_balance: '',
                    fileList: [],
                },
                matter: '',
                legal: {
                    card_name: '',
                    contact_way: '',
                    card_address: '',
                    card_num: '',
                    card_validity: '',
                    card_font_id: '',
                    card_back_id: '',
                    card_font: '',
                    card_back: '',
                    ratio:'',
                    fileList: []
                },
                legal_new: {
                    card_name: '',
                    contact_way: '',
                    card_address: '',
                    card_num: '',
                    card_validity: '',
                    card_font_id: '',
                    card_back_id: '',
                    card_font: '',
                    card_back: '',
                    fileList: []
                },
                legal_old: {
                    card_name: '',
                    contact_way: '',
                    card_address: '',
                    card_num: '',
                    card_validity: '',
                    card_font_id: '',
                    card_back_id: '',
                    card_font: '',
                    card_back: '',
                    fileList: []
                },
                shareholder: [
                    {
                        card_name: '',
                        contact_way: '',
                        card_address: '',
                        card_num: '',
                        card_validity: '',
                        card_font_id: '',
                        card_back_id: '',
                        card_font: '',
                        card_back: '',
                        ratio:'',
                        fileList: []
                    }
                ],
                shareholder_old: [
                    {
                        card_name: '',
                        contact_way: '',
                        card_address: '',
                        card_num: '',
                        card_validity: '',
                        card_font_id: '',
                        card_back_id: '',
                        card_font: '',
                        card_back: '',
                        fileList: []
                    }
                ],
                shareholder_new: [
                    {
                        card_name: '',
                        contact_way: '',
                        card_address: '',
                        card_num: '',
                        card_validity: '',
                        card_font_id: '',
                        card_back_id: '',
                        card_font: '',
                        card_back: '',
                        fileList: []
                    }
                ],
                supervisor_border:[
                    {
                        card_name: '',
                        contact_way: '',
                        card_address: '',
                        card_num: '',
                        card_validity: '',
                        card_font_id: '',
                        card_back_id: '',
                        card_font: '',
                        card_back: '',
                        fileList: [],
                    }
                ],
                supervisor: {
                    card_name: '',
                    contact_way: '',
                    card_address: '',
                    card_num: '',
                    card_validity: '',
                    card_font_id: '',
                    card_back_id: '',
                    card_font: '',
                    card_back: '',
                    fileList: []
                },
                supervisor_old: {
                    card_name: '',
                    contact_way: '',
                    card_address: '',
                    card_num: '',
                    card_validity: '',
                    card_font_id: '',
                    card_back_id: '',
                    card_font: '',
                    card_back: '',
                    fileList: []
                },
                supervisor_new: {
                    card_name: '',
                    contact_way: '',
                    card_address: '',
                    card_num: '',
                    card_validity: '',
                    card_font_id: '',
                    card_back_id: '',
                    card_font: '',
                    card_back: '',
                    fileList: []
                },
                business_scope: '',
                new_business_scope: '',
                region:'',
                company_title: '',
                new_company_title: '',
                register_capital: '',
                register_address:'',
                ratio: '',
                change_money: '',
                social_credit_code: '',
                special_require: '',
                paper_tax: '',
                postcode: '',
                is_local: '',
                is_tally:'',
                room_num:'',
                open_account: '',
                license_type: '',
                legal_person_identity_card_script: '',
                lease_contract: [],
                house_property: [],
                companyList:[
                    {name:''}
                ],
                tax_tray: '',
                invoice: '',
                Invoice_book: '',
                official_seal: '',
                legal_corporate_seal:'',
                financial_seal: '',
                license_type_file: [],
                cancel_receipt_file: [],
                legal_person_official_seal: [],
                e_bank: '',
                cancel_receipt:'',
                cipher: '',
                corporate_seal: '',
                legal_person_identity_card_copy: [],
                files:[],
                abnormal_cause: '',
                remark:'',
                stock_conviersion:''
            },
            image: require('../../assets/image/none.png'),
            image_card_font: require('../../assets/image/card-font.png'),
            image_card_back: require('../../assets/image/card-back.png'),
            showFile: [],
            fileList: [],
            actIndex: 0,
            cloneArray: [],
            cloneObject: [],
            areaList:[{value:'111',id:1}],
            change_index: '',
            imageData:{
                imgList:[], // 所有图片数组
                index: 0, // 当前点击的图片的索引
                infinite: true,  // 是否可以循环切换
                popup: false // 弹窗的显示隐藏
            },
        }
    },
    mounted(){
        if(this.changeForm.id){
            let bank_list = []
            let legal_jin = []
            let new_legal_jin = []
            let old_legal_jin = []
            let supervisor_jin = []
            let new_supervisor_jin = []
            let old_supervisor_jin = []
            let shareholder = []
            let shareholder_old = []
            let shareholder_new = []
            let supervisor_border = []
            let lease_contract = []
            let alt_company_title = []
            let house_property = []
            let license_type_file = []
            let cancel_receipt_file = []
            let legal_person_official_seal = []
            let legal_person_identity_card_copy = []
            let files = []
            this.changeForm.bank_capture.forEach(item =>{
                bank_list.push({
                    id:item.file_info.id,
                    img_url:item.file_info.file_path,
                    name:item.file_info.title,
                })
            })
            this.changeForm.jin_xin_ban.forEach(item =>{
                legal_jin.push({
                    id:item.file_info.id,
                    img_url:item.file_info.file_path,
                    name:item.file_info.title,
                })
            })
            this.changeForm.new_legal_person_jin_xin_ban.forEach(item =>{
                new_legal_jin.push({
                    id:item.file_info.id,
                    img_url:item.file_info.file_path,
                    name:item.file_info.title,
                })
            })
            this.changeForm.original_legal_person_jin_xin_ban.forEach(item =>{
                old_legal_jin.push({
                    id:item.file_info.id,
                    img_url:item.file_info.file_path,
                    name:item.file_info.title,
                })
            })
            this.changeForm.supervisor_jin_xin_ban.forEach(item =>{
                supervisor_jin.push({
                    id:item.file_info.id,
                    img_url:item.file_info.file_path,
                    name:item.file_info.title,
                })
            })
            this.changeForm.new_supervisor_jin_xin_ban.forEach(item =>{
                new_supervisor_jin.push({
                    id:item.file_info.id,
                    img_url:item.file_info.file_path,
                    name:item.file_info.title,
                })
            })
            this.changeForm.original_supervisor_jin_xin_ban.forEach(item =>{
                old_supervisor_jin.push({
                    id:item.file_info.id,
                    img_url:item.file_info.file_path,
                    name:item.file_info.title,
                })
            })
            this.changeForm.shareholder.forEach(item =>{
                let file = []
                item.shareholder_jin_xin_ban.forEach(value =>{
                    file.push({
                        id:value.file_info.id,
                        img_url:value.file_info.file_path,
                        name:value.file_info.title,
                    })
                })
                shareholder.push({
                    card_name:item.shareholder,
                    contact_way:item.shareholder_contact_way,
                    card_address:item.shareholder_card_address,
                    card_num:item.shareholder_card_num,
                    card_validity:item.shareholder_card_validity,
                    card_font_id:item.shareholder_identity_card.length > 0 ? item.shareholder_identity_card[0].id:'',
                    card_back_id:item.shareholder_identity_card.length > 0 ? item.shareholder_identity_card[1].id:'',
                    card_font:item.shareholder_identity_card.length > 0 ? item.shareholder_identity_card[0].file_path:'',
                    card_back:item.shareholder_identity_card.length > 0 ? item.shareholder_identity_card[1].file_path:'',
                    ratio:item.ratio ? item.ratio : '',
                    fileList:file,
                })
            })
            this.changeForm.new_shareholder.forEach(item =>{
                let file = []
                item.shareholder_jin_xin_ban.forEach(value =>{
                    file.push({
                        id:value.id,
                        img_url:value.file_path,
                        name:value.title,
                    })
                })
                shareholder_new.push({
                    card_name:item.shareholder,
                    contact_way:item.shareholder_contact_way,
                    card_address:item.shareholder_card_address,
                    card_num:item.shareholder_card_num,
                    card_validity:item.shareholder_card_validity,
                    card_font_id:item.shareholder_identity_card.length > 0 ? item.shareholder_identity_card[0].id:'',
                    card_back_id:item.shareholder_identity_card.length > 0 ? item.shareholder_identity_card[1].id:'',
                    card_font:item.shareholder_identity_card.length > 0 ? item.shareholder_identity_card[0].file_path:'',
                    card_back:item.shareholder_identity_card.length > 0 ? item.shareholder_identity_card[1].file_path:'',
                    fileList:file,
                })
            })
            this.changeForm.supervisor_border.forEach(item =>{
                supervisor_border.push({
                    card_name:item.supervisor,
                    contact_way:item.supervisor_contact_way,
                    card_address:item.supervisor_card_address,
                    card_num:item.supervisor_card_num,
                    card_validity:item.supervisor_card_validity,
                    card_font_id:item.supervisor_identity_card.length > 0 ? item.supervisor_identity_card[0].id:'',
                    card_back_id:item.supervisor_identity_card.length > 0 ? item.supervisor_identity_card[1].id:'',
                    card_font:item.supervisor_identity_card.length > 0 ? item.supervisor_identity_card[0].file_path:'',
                    card_back:item.supervisor_identity_card.length > 0 ? item.supervisor_identity_card[1].file_path:'',
                })
            })
            this.changeForm.original_shareholder.forEach(item =>{
                let file = []
                item.shareholder_jin_xin_ban.forEach(value =>{
                    file.push({
                        id:value.file_info.id,
                        img_url:value.file_info.file_path,
                        name:value.file_info.title,
                    })
                })
                shareholder_old.push({
                    card_name:item.shareholder,
                    contact_way:item.shareholder_contact_way,
                    card_address:item.shareholder_card_address,
                    card_num:item.shareholder_card_num,
                    card_validity:item.shareholder_card_validity,
                    card_font_id:item.shareholder_identity_card.length > 0 ? item.shareholder_identity_card[0].id:'',
                    card_back_id:item.shareholder_identity_card.length > 0 ? item.shareholder_identity_card[1].id:'',
                    card_font:item.shareholder_identity_card.length > 0 ? item.shareholder_identity_card[0].file_path:'',
                    card_back:item.shareholder_identity_card.length > 0 ? item.shareholder_identity_card[1].file_path:'',
                    fileList:file,
                })
            })
            this.changeForm.lease_contract.forEach(item =>{
                lease_contract.push({
                    id:item.id,
                    img_url:item.file_path,
                    name:item.title,
                })
            })
            this.changeForm.alt_company_title.split(',').forEach(item =>{
                alt_company_title.push({
                    name:item,
                })
            })
            this.changeForm.house_property.forEach(item =>{
                house_property.push({
                    id:item.id,
                    img_url:item.file_path,
                    name:item.title,
                })
            })
            this.changeForm.license_type_file.forEach(item =>{
                license_type_file.push({
                    id:item.id,
                    img_url:item.file_path,
                    name:item.title,
                })
            })
            this.changeForm.cancel_receipt_file.forEach(item =>{
                cancel_receipt_file.push({
                    id:item.id,
                    img_url:item.file_path,
                    name:item.title,
                })
            })
            this.changeForm.legal_person_official_seal.forEach(item =>{
                legal_person_official_seal.push({
                    id:item.id,
                    img_url:item.file_path,
                    name:item.title,
                })
            })
            this.changeForm.legal_person_identity_card_copy.forEach(item =>{
                legal_person_identity_card_copy.push({
                    id:item.id,
                    img_url:item.file_path,
                    name:item.title,
                })
            })
            this.changeForm.files.forEach(item=>{
                files.push({
                    id:item.id,
                    img_url:item.file_path,
                    name:item.title,
                })
            })
            this.form.bank_money.bank_balance = this.changeForm.bank_balance
            this.form.bank_money.fileList = bank_list
            this.form.matter = this.changeForm.matter
            this.form.legal.card_name = this.changeForm.legal_person
            this.form.legal.contact_way = this.changeForm.contact_way
            this.form.legal.card_address = this.changeForm.card_address
            this.form.legal.card_num = this.changeForm.card_num
            this.form.legal.card_validity = this.changeForm.card_validity
            this.form.legal.ratio = this.changeForm.ratio
            this.form.legal.card_font_id = this.changeForm.identity_card.length > 0 ? this.changeForm.identity_card[0].id:'' 
            this.form.legal.card_back_id = this.changeForm.identity_card.length > 0 ? this.changeForm.identity_card[1].id:''
            this.form.legal.card_font = this.changeForm.identity_card.length > 0 ? this.changeForm.identity_card[0].file_path:'' 
            this.form.legal.card_back = this.changeForm.identity_card.length > 0 ? this.changeForm.identity_card[1].file_path:''
            this.form.legal.fileList = legal_jin
            this.form.legal_new.card_name = this.changeForm.new_legal_person
            this.form.legal_new.contact_way = this.changeForm.new_legal_person_contact_way
            this.form.legal_new.card_address = this.changeForm.new_legal_person_card_address
            this.form.legal_new.card_num = this.changeForm.new_legal_person_card_num
            this.form.legal_new.card_validity = this.changeForm.new_legal_person_card_validity
            this.form.legal_new.card_font_id = this.changeForm.new_legal_person_identity_card.length > 0 ? this.changeForm.new_legal_person_identity_card[0].id:'' 
            this.form.legal_new.card_back_id = this.changeForm.new_legal_person_identity_card.length > 0 ? this.changeForm.new_legal_person_identity_card[1].id:''
            this.form.legal_new.card_font = this.changeForm.new_legal_person_identity_card.length > 0 ? this.changeForm.new_legal_person_identity_card[0].file_path:'' 
            this.form.legal_new.card_back = this.changeForm.new_legal_person_identity_card.length > 0 ? this.changeForm.new_legal_person_identity_card[1].file_path:''
            this.form.legal_new.fileList = new_legal_jin
            this.form.legal_old.card_name = this.changeForm.original_legal_person
            this.form.legal_old.contact_way = this.changeForm.original_legal_person_contact_way
            this.form.legal_old.card_address = this.changeForm.original_legal_person_card_address
            this.form.legal_old.card_num = this.changeForm.original_legal_person_card_num
            this.form.legal_old.card_validity = this.changeForm.original_legal_person_card_validity
            this.form.legal_old.card_font_id = this.changeForm.new_supervisor_identity_card.length > 0 ? this.changeForm.new_supervisor_identity_card[0].id:'' 
            this.form.legal_old.card_back_id = this.changeForm.new_supervisor_identity_card.length > 0 ? this.changeForm.new_supervisor_identity_card[1].id:''
            this.form.legal_old.card_font = this.changeForm.new_supervisor_identity_card.length > 0 ? this.changeForm.new_supervisor_identity_card[0].file_path:'' 
            this.form.legal_old.card_back = this.changeForm.new_supervisor_identity_card.length > 0 ? this.changeForm.new_supervisor_identity_card[1].file_path:''
            this.form.legal_old.fileList = old_legal_jin
            this.form.supervisor.card_name = this.changeForm.supervisor
            this.form.supervisor.contact_way = this.changeForm.supervisor_contact_way
            this.form.supervisor.card_address = this.changeForm.supervisor_card_address
            this.form.supervisor.card_num = this.changeForm.supervisor_card_num
            this.form.supervisor.card_validity = this.changeForm.supervisor_card_validity
            this.form.supervisor.card_font_id = this.changeForm.supervisor_identity_card.length > 0 ? this.changeForm.supervisor_identity_card[0].id:'' 
            this.form.supervisor.card_back_id = this.changeForm.supervisor_identity_card.length > 0 ? this.changeForm.supervisor_identity_card[1].id:''
            this.form.supervisor.card_font = this.changeForm.supervisor_identity_card.length > 0 ? this.changeForm.supervisor_identity_card[0].file_path:'' 
            this.form.supervisor.card_back = this.changeForm.supervisor_identity_card.length > 0 ? this.changeForm.supervisor_identity_card[1].file_path:''
            this.form.supervisor.fileList = supervisor_jin
            this.form.supervisor_new.card_name = this.changeForm.new_supervisor
            this.form.supervisor_new.contact_way = this.changeForm.new_supervisor_contact_way
            this.form.supervisor_new.card_address = this.changeForm.new_supervisor_card_address
            this.form.supervisor_new.card_num = this.changeForm.new_supervisor_card_num
            this.form.supervisor_new.card_validity = this.changeForm.new_supervisor_card_validity
            this.form.supervisor_new.card_font_id = this.changeForm.new_supervisor_identity_card.length > 0 ? this.changeForm.new_supervisor_identity_card[0].id:'' 
            this.form.supervisor_new.card_back_id = this.changeForm.new_supervisor_identity_card.length > 0 ? this.changeForm.new_supervisor_identity_card[1].id:''
            this.form.supervisor_new.card_font = this.changeForm.new_supervisor_identity_card.length > 0 ? this.changeForm.new_supervisor_identity_card[0].file_path:'' 
            this.form.supervisor_new.card_back = this.changeForm.new_supervisor_identity_card.length > 0 ? this.changeForm.new_supervisor_identity_card[1].file_path:''
            this.form.supervisor_new.fileList = new_supervisor_jin
            this.form.supervisor_old.card_name = this.changeForm.original_supervisor
            this.form.supervisor_old.contact_way = this.changeForm.original_supervisor_contact_way
            this.form.supervisor_old.card_address = this.changeForm.original_supervisor_card_address
            this.form.supervisor_old.card_num = this.changeForm.original_supervisor_card_num
            this.form.supervisor_old.card_validity = this.changeForm.original_supervisor_card_validity
            this.form.supervisor_old.card_font_id = this.changeForm.original_supervisor_identity_card.length > 0 ? this.changeForm.supervisor_identity_card[0].id:'' 
            this.form.supervisor_old.card_back_id = this.changeForm.original_supervisor_identity_card.length > 0 ? this.changeForm.supervisor_identity_card[1].id:''
            this.form.supervisor_old.card_font = this.changeForm.original_supervisor_identity_card.length > 0 ? this.changeForm.supervisor_identity_card[0].file_path:'' 
            this.form.supervisor_old.card_back = this.changeForm.original_supervisor_identity_card.length > 0 ? this.changeForm.supervisor_identity_card[1].file_path:''
            this.form.supervisor_old.fileList = old_supervisor_jin
            if(shareholder.length > 0){
                this.form.shareholder = shareholder
            }
            if(shareholder_old.length > 0){
                this.form.shareholder_old = shareholder_old
            }
            if(shareholder_new.length > 0){
                this.form.shareholder_new = shareholder_new
            }
            if(supervisor_border.length > 0){
                this.form.supervisor_border = supervisor_border
            }
            this.form.business_scope = this.changeForm.business_scope
            this.form.new_business_scope = this.changeForm.new_business_scope
            this.form.region = this.changeForm.region
            this.form.company_title = this.changeForm.company_title
            this.form.new_company_title = this.changeForm.new_company_title
            this.form.register_capital = this.changeForm.register_capital
            this.form.register_address = this.changeForm.register_address
            this.form.ratio = this.changeForm.ratio
            this.form.change_money = this.changeForm.change_money
            this.form.social_credit_code = this.changeForm.social_credit_code
            this.form.special_require = this.changeForm.special_require
            this.form.paper_tax = this.changeForm.paper_tax.id
            this.form.postcode = this.changeForm.postcode
            this.form.is_local = this.changeForm.is_local
            this.form.is_tally = this.changeForm.is_tally
            // this.form.room_num = this.changeForm.room_num
            this.$set(this.form,'room_num', this.changeForm.room_num &&  this.changeForm.room_num.id ? this.changeForm.room_num.id : '')
            this.form.lease_contract = lease_contract
            this.form.companyList = alt_company_title
            this.form.house_property = house_property
            this.form.license_type_file = license_type_file
            this.form.cancel_receipt_file = cancel_receipt_file
            this.form.legal_person_official_seal = legal_person_official_seal
            this.form.legal_person_identity_card_copy = legal_person_identity_card_copy
            this.form.files = files
            this.form.open_account = this.changeForm.open_account
            this.form.license_type = this.changeForm.license_type
            this.form.legal_person_identity_card_script = this.changeForm.legal_person_identity_card_script
            this.form.tax_tray = this.changeForm.tax_tray
            this.form.invoice = this.changeForm.invoice
            this.form.Invoice_book = this.changeForm.Invoice_book
            this.form.official_seal = this.changeForm.official_seal
            this.form.financial_seal = this.changeForm.financial_seal
            this.form.legal_corporate_seal = this.changeForm.legal_corporate_seal,
            this.form.e_bank = this.changeForm.e_bank
            this.form.cancel_receipt = this.changeForm.cancel_receipt
            this.form.cipher = this.changeForm.cipher
            this.form.corporate_seal = this.changeForm.corporate_seal
            this.form.abnormal_cause = this.changeForm.abnormal_cause
            this.form.remark = this.changeForm.remark
            this.form.stock_conviersion = this.changeForm.stock_conviersion
        }
    },
    methods: {
        handleScroll() {
            this.$refs.selectRef.blur(); // 关闭 select 下拉菜单
        },
        wantUpload(wantUpload) {
            this.cloneArray = wantUpload
            this.change_index = ''
        },
        submitForm() {
            if(this.checkShow('legal_ratio')){
                let legal_ratio = Number(this.form.ratio )
                let shareholder_ratio = 0
                this.form.shareholder.forEach(item =>{
                    shareholder_ratio = shareholder_ratio + Number(item.ratio)
                })
                let pie = legal_ratio + shareholder_ratio
                if(pie != 100){
                    this.$message.error('占股比例之和须达到100')
                    return
                }
            }
            if(this.checkShow('legal_ratio')){
                let legal_ratio = Number(this.form.ratio )
                let shareholder_ratio = 0
                this.form.shareholder.forEach(item =>{
                    shareholder_ratio = shareholder_ratio + Number(item.ratio)
                })
                let pie = legal_ratio + shareholder_ratio
                if(pie != 100){
                    this.$message.error('占股比例之和须达到100')
                    return
                }
            }
            if(this.checkShow('shareholder') && this.checkShow('shareholder_ratio') ){
                let is_valited = false
                this.form.shareholder.forEach(item =>{
                    if(!item.card_name || !item.contact_way ){
                        is_valited = true
                    }
                })
                if(is_valited){
                    this.$message.error('请完善股东信息')
                    return
                }
            }
            let bank_capture = []
            let legal_list = []
            let new_legal_list = []
            let old_legal_list = []
            let supervisor_list = []
            let old_supervisor_list = []
            let new_supervisor_list = []
            let shareholder = []
            let new_shareholder = []
            let supervisor_border = []
            let original_shareholder = []
            let lease_contract_list = []
            let alt_company_title = []
            let house_property_list = []
            let license_list = []
            let cancel_list = []
            let legal_person_list = []
            let legal_person_official_seal = []
            let files = []
            this.form.bank_money.fileList.forEach(item => {
                bank_capture.push(item.id)
            })
            this.form.legal.fileList.forEach(item => {
                legal_list.push(item.id)
            })
            this.form.legal_new.fileList.forEach(item => {
                new_legal_list.push(item.id)
            })
            this.form.legal_old.fileList.forEach(item => {
                old_legal_list.push(item.id)
            })
            this.form.supervisor.fileList.forEach(item => {
                supervisor_list.push(item.id)
            })
            this.form.supervisor_old.fileList.forEach(item => {
                old_supervisor_list.push(item.id)
            })
            this.form.supervisor_new.fileList.forEach(item => {
                new_supervisor_list.push(item.id)
            })
            this.form.shareholder.forEach(item => {
                let shareholder_list = []
                item.fileList.forEach(item => {
                    shareholder_list.push(item.id)
                })
                shareholder.push({
                    shareholder: item.card_name,
                    shareholder_contact_way: item.contact_way,
                    shareholder_card_address: item.card_address,
                    shareholder_card_num: item.card_num,
                    shareholder_card_validity: item.card_validity,
                    shareholder_identity_card: item.card_font_id + ',' + item.card_back_id,
                    shareholder_jin_xin_ban: shareholder_list,
                    ratio:item.ratio
                })
            })
            this.form.shareholder_new.forEach(item => {
                let new_shareholder_list = []
                item.fileList.forEach(item => {
                    new_shareholder_list.push(item.id)
                })
                new_shareholder.push({
                    shareholder: item.card_name,
                    shareholder_contact_way: item.contact_way,
                    shareholder_card_address: item.card_address,
                    shareholder_card_num: item.card_num,
                    shareholder_card_validity: item.card_validity,
                    shareholder_identity_card: item.card_font_id + ',' + item.card_back_id,
                    shareholder_jin_xin_ban: new_shareholder_list,
                })
            })
            this.form.supervisor_border.forEach(item => {
                supervisor_border.push({
                    supervisor: item.card_name,
                    supervisor_contact_way: item.contact_way,
                    supervisor_card_address: item.card_address,
                    supervisor_card_num: item.card_num,
                    supervisor_card_validity: item.card_validity,
                    supervisor_identity_card: item.card_font_id + ',' + item.card_back_id,
                })
            })
            this.form.shareholder_old.forEach(item => {
                let old_shareholder_list = []
                item.fileList.forEach(item => {
                    old_shareholder_list.push(item.id)
                })
                original_shareholder.push({
                    shareholder: item.card_name,
                    shareholder_contact_way: item.contact_way,
                    shareholder_card_address: item.card_address,
                    shareholder_card_num: item.card_num,
                    shareholder_card_validity: item.card_validity,
                    shareholder_identity_card: item.card_font_id + ',' + item.card_back_id,
                    shareholder_jin_xin_ban: old_shareholder_list,
                })
            })
            this.form.lease_contract.forEach(item => {
                lease_contract_list.push(item.id)
            })
            this.form.companyList.forEach(item =>{
                alt_company_title.push(item.name)
            })
            this.form.house_property.forEach(item => {
                house_property_list.push(item.id)
            })
            this.form.license_type_file.forEach(item => {
                license_list.push(item.id)
            })
            this.form.cancel_receipt_file.forEach(item => {
                cancel_list.push(item.id)
            })
            this.form.legal_person_identity_card_copy.forEach(item => {
                legal_person_list.push(item.id)
            })
            this.form.files.forEach(item=>{
                files.push(item.id)
            })
            this.form.legal_person_official_seal.forEach(item => {
                legal_person_official_seal.push(item.id)
            })
            let data = {
                bank_balance: this.form.bank_money.bank_balance,
                bank_capture: bank_capture.toString(),
                matter: this.form.matter,
                //法人
                legal_person: this.form.legal.card_name,
                contact_way: this.form.legal.contact_way,
                card_address: this.form.legal.card_address,
                card_num: this.form.legal.card_num,
                card_validity: this.form.legal.card_validity,
                ratio:this.form.legal.ratio,
                identity_card: this.form.legal.card_font_id + ',' + this.form.legal.card_back_id,
                jin_xin_ban: legal_list,
                new_legal_person: this.form.legal_new.card_name,
                new_legal_person_contact_way: this.form.legal_new.contact_way,
                new_legal_person_card_address: this.form.legal_new.card_address,
                new_legal_person_card_num: this.form.legal_new.card_num,
                new_legal_person_card_validity: this.form.legal_new.card_validity,
                new_legal_person_identity_card: this.form.legal_new.card_font_id + ',' + this.form.legal_new.card_back_id,
                new_legal_person_jin_xin_ban: new_legal_list,
                original_legal_person: this.form.legal_old.card_name,
                original_legal_person_contact_way: this.form.legal_old.contact_way,
                original_legal_person_card_address: this.form.legal_old.card_address,
                original_legal_person_card_num: this.form.legal_old.card_num,
                original_legal_person_card_validity: this.form.legal_old.card_validity,
                original_legal_person_identity_card: this.form.legal_old.card_font_id + ',' + this.form.legal_old.card_back_id,
                original_legal_person_jin_xin_ban: old_legal_list,
                //监事
                supervisor: this.form.supervisor.card_name,
                supervisor_contact_way: this.form.supervisor.contact_way,
                supervisor_card_address: this.form.supervisor.card_address,
                supervisor_card_num: this.form.supervisor.card_num,
                supervisor_card_validity: this.form.supervisor.card_validity,
                supervisor_identity_card: this.form.supervisor.card_font_id + ',' + this.form.supervisor.card_back_id,
                supervisor_jin_xin_ban: supervisor_list,
                new_supervisor: this.form.supervisor_new.card_name,
                new_supervisor_contact_way: this.form.supervisor_new.contact_way,
                new_supervisor_card_address: this.form.supervisor_new.card_address,
                new_supervisor_card_num: this.form.supervisor_new.card_num,
                new_supervisor_card_validity: this.form.supervisor_new.card_validity,
                new_supervisor_identity_card: this.form.supervisor_new.card_font_id + ',' + this.form.supervisor_new.card_back_id,
                new_supervisor_jin_xin_ban: new_supervisor_list,
                original_supervisor: this.form.supervisor_old.card_name,
                original_supervisor_contact_way: this.form.supervisor_old.contact_way,
                original_supervisor_card_address: this.form.supervisor_old.card_address,
                original_supervisor_card_num: this.form.supervisor_old.card_num,
                original_supervisor_card_validity: this.form.supervisor_old.card_validity,
                original_supervisor_identity_card: this.form.supervisor_old.card_font_id + ',' + this.form.supervisor_old.card_back_id,
                original_supervisor_jin_xin_ban: old_supervisor_list,
                //股东
                shareholder: this.checkShow('shareholder') ? shareholder : [],
                new_shareholder: this.checkShow('shareholder_new') ? new_shareholder : [],
                supervisor_border:this.checkShow('supervisor_border') ? supervisor_border : [],
                original_shareholder: this.checkShow('shareholder_old') ? original_shareholder : [],
                business_scope: this.form.business_scope,
                new_business_scope: this.form.new_business_scope,
                region:this.form.region,
                company_title: this.form.company_title,
                new_company_title: this.form.new_company_title,
                register_capital: this.form.register_capital,
                register_address:this.form.register_address,
                change_money: this.form.change_money,
                ratio:this.form.ratio,
                social_credit_code: this.form.social_credit_code,
                open_account: this.form.open_account,
                license_type: this.form.license_type,
                legal_person_identity_card_script: this.form.legal_person_identity_card_script,
                special_require: this.form.special_require,
                paper_tax: this.form.paper_tax,
                postcode: this.form.postcode,
                is_local: this.form.is_local,
                is_tally: this.form.is_tally,
                room_num:this.form.room_num,
                lease_contract: lease_contract_list,
                alt_company_title:alt_company_title.toString(),
                house_property: house_property_list,
                tax_tray: this.form.tax_tray,
                invoice: this.form.invoice,
                Invoice_book: this.form.Invoice_book,
                official_seal: this.form.official_seal,
                financial_seal: this.form.financial_seal,
                legal_corporate_seal:this.form.legal_corporate_seal,
                license_type_file: license_list.toString(),
                cancel_receipt_file: cancel_list.toString(),
                legal_person_official_seal: legal_person_official_seal.toString(),
                e_bank: this.form.e_bank,
                cancel_receipt:this.form.cancel_receipt,
                cipher: this.form.cipher,
                corporate_seal: this.form.corporate_seal,
                abnormal_cause: this.form.abnormal_cause,
                remark:this.form.remark,
                stock_conviersion:this.form.stock_conviersion,
                legal_person_identity_card_copy: legal_person_list.toString(),
                files:files.toString()
            }
            this.$emit('submit', data)
        },
        uploadChange(object, index) {
            this.cloneObject = object
            this.change_index = index
        },
        seeImageList(index,item){
            this.imageData.popup = true
            this.imageData.imgList = item
            this.imageData.index = index
        },
        seeFile(item){
            let url = item.img_url
            window.open(url,'_blank')
        },
        delFile(index,list) {
            list.splice(index,1)
        },
        addShareholder(array) {
            array.push({
                card_name: '',
                contact_way: '',
                card_address: '',
                card_num: '',
                card_validity: '',
                card_font_id: '',
                card_back_id: '',
                card_font: '',
                card_back: '',
                fileList: []
            })
        },
        delShareholder(array,index) {
            array.splice(index,1)
        },
        addCompanyName(){
            this.form.companyList.push({
                name:'',
            })
        },
        delCompanyName(index){
            this.form.companyList.splice(index,1)
        },
        checkShow(key) {
            if (this.showList.indexOf(key) >= 0) {
                return true
            } else {
                return false
            }
        },
        getFileName(url) {
            const name = url.split('/');
            return name.pop();
        },
        getCardInfo(form) {
            if (form.card_font_id && form.card_back_id) {
                let data = {
                    card_front: form.card_font_id,
                    card_back: form.card_back_id
                }
                cardinfo(data).then(res => {
                    if (res.code == 200) {
                        form.card_address = res.data.code_data.address
                        form.card_num = res.data.code_data.num
                        form.card_validity = res.data.code_data.start_date + '-' + res.data.code_data.end_date
                        form.card_name = res.data.code_data.name
                    } else {
                        this.$message.error(res.msg)
                        form.card_address = ''
                        form.card_num = ''
                        form.card_validity = ''
                        form.card_name = ''
                    }
                }).catch(err => {
                    this.$message.error("请重新上传")
                })
            }
        },
        uploadFile(obj) {
            let fileObj = obj.file
            const { size, name } = fileObj
            let md5code = md5(name + new Date().getTime())
            let length = 500000
            let start = 0
            let end = 0
            let i = 1;
            Loading.service({
                text: "上传中...."
            });
            this.testHandle(md5code, length, start, end, size, name, fileObj, i)
        },
        testHandle(md5code, length, start, end, size, name, fileObj, i) {
            if (length > size) {
                length = size
            }
            if (end < size) {
                end = start + length
                if (end > size) {
                    end = size
                }
                let blob = ""
                blob = fileObj.slice(start, end)
                start = end
                let fd = new FormData()
                fd.append("file", blob)
                fd.append("code", md5code)
                let suc = 2
                if (end == size) {
                    suc = 1
                }
                fd.append("tag", suc)
                fd.append("sort", i)
                fd.append("name", name)
                fd.append("ext", name.split('.')[1])
                i = i + parseInt(1)
                service({
                    url: "/back/v1/oa/uploadFile",
                    method: "post",
                    data: fd,
                }).then(res => {
                    if (res.code == 200 || res.code == 201) {
                        if (suc == 2) {
                            this.testHandle(md5code, length, start, end, size, name, fileObj, i)
                        } else {
                            this.$message.success(res.msg)
                            Loading.service().close()
                            if (!this.change_index) {
                                this.cloneArray.push({
                                    img_url: res.data.file_url,
                                    id: res.data.file_id,
                                    name: this.getFileName(res.data.file_url)
                                })
                            } else {
                                if (this.change_index == 1) {
                                    this.cloneObject.card_font = res.data.file_url
                                    this.cloneObject.card_font_id = res.data.file_id
                                } else {
                                    this.cloneObject.card_back = res.data.file_url
                                    this.cloneObject.card_back_id = res.data.file_id
                                }
                                if (this.cloneObject.card_back_id && this.cloneObject.card_font_id) {
                                    this.getCardInfo(this.cloneObject)
                                }
                            }
                        }
                    } else {
                        this.$message.error(res.msg)
                        Loading.service().close()
                    }
                })
                    .catch(err => {
                        this.$message.error("请重新上传")
                        Loading.service().close()
                    })
            }
        },
        resetForm(){
            this.form = {
                bank_money: {
                    bank_balance: '',
                    fileList: [],
                },
                matter: '',
                legal: {
                    card_name: '',
                    contact_way: '',
                    card_address: '',
                    card_num: '',
                    card_validity: '',
                    card_font_id: '',
                    card_back_id: '',
                    card_font: '',
                    card_back: '',
                    ratio:'',
                    fileList: []
                },
                legal_new: {
                    card_name: '',
                    contact_way: '',
                    card_address: '',
                    card_num: '',
                    card_validity: '',
                    card_font_id: '',
                    card_back_id: '',
                    card_font: '',
                    card_back: '',
                    fileList: []
                },
                legal_old: {
                    card_name: '',
                    contact_way: '',
                    card_address: '',
                    card_num: '',
                    card_validity: '',
                    card_font_id: '',
                    card_back_id: '',
                    card_font: '',
                    card_back: '',
                    fileList: []
                },
                shareholder: [
                    {
                        card_name: '',
                        contact_way: '',
                        card_address: '',
                        card_num: '',
                        card_validity: '',
                        card_font_id: '',
                        card_back_id: '',
                        card_font: '',
                        card_back: '',
                        ratio:'',
                        fileList: []
                    }
                ],
                shareholder_old: [
                    {
                        card_name: '',
                        contact_way: '',
                        card_address: '',
                        card_num: '',
                        card_validity: '',
                        card_font_id: '',
                        card_back_id: '',
                        card_font: '',
                        card_back: '',
                        fileList: []
                    }
                ],
                shareholder_new: [
                    {
                        card_name: '',
                        contact_way: '',
                        card_address: '',
                        card_num: '',
                        card_validity: '',
                        card_font_id: '',
                        card_back_id: '',
                        card_font: '',
                        card_back: '',
                        fileList: []
                    }
                ],
                supervisor_border:[
                    {
                        card_name: '',
                        contact_way: '',
                        card_address: '',
                        card_num: '',
                        card_validity: '',
                        card_font_id: '',
                        card_back_id: '',
                        card_font: '',
                        card_back: '',
                        fileList: []
                    }
                ],
                supervisor: {
                    card_name: '',
                    contact_way: '',
                    card_address: '',
                    card_num: '',
                    card_validity: '',
                    card_font_id: '',
                    card_back_id: '',
                    card_font: '',
                    card_back: '',
                    fileList: []
                },
                supervisor_old: {
                    card_name: '',
                    contact_way: '',
                    card_address: '',
                    card_num: '',
                    card_validity: '',
                    card_font_id: '',
                    card_back_id: '',
                    card_font: '',
                    card_back: '',
                    fileList: []
                },
                supervisor_new: {
                    card_name: '',
                    contact_way: '',
                    card_address: '',
                    card_num: '',
                    card_validity: '',
                    card_font_id: '',
                    card_back_id: '',
                    card_font: '',
                    card_back: '',
                    fileList: []
                },
                business_scope: '',
                new_business_scope: '',
                region:'',
                company_title: '',
                new_company_title: '',
                register_capital: '',
                register_address:'',
                ratio: '',
                change_money: '',
                social_credit_code: '',
                special_require: '',
                paper_tax: '',
                postcode: '',
                is_local: '',
                is_tally:'',
                room_num:'',
                open_account: '',
                license_type: '',
                legal_person_identity_card_script: '',
                lease_contract: [],
                companyList:[
                    {name:''}
                ],
                house_property: [],
                tax_tray: '',
                invoice: '',
                Invoice_book: '',
                official_seal: '',
                financial_seal: '',
                legal_corporate_seal:'',
                license_type_file: [],
                cancel_receipt_file: [],
                legal_person_official_seal: [],
                e_bank: '',
                cancel_receipt:'',
                cipher: '',
                corporate_seal: '',
                legal_person_identity_card_copy: [],
                files:[],
                abnormal_cause: '',
                remark:'',
                stock_conviersion:''
            }
        }
    },
}

</script>

<style lang="scss" scoped>
.form {
    .special-box {
        margin-top: 16px;
        width: 100%;
        height: auto;
        border-radius: 4px;
        border: 1px solid #F0F1F5;
        box-sizing: border-box;

        .box-item {
            height: auto;
            display: flex;
            width: 100%;
            border-bottom: 1px #F0F1F5 solid;
            height: auto;
            box-sizing: border-box;

            .card-message {
                width: 330px;
                height: 80px;
                display: flex;
                align-items: center;
                margin-top: 14px;
                margin-bottom: 14px;

                .card {
                    width: 126px;
                    height: 80px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    border: 1px dashed #E2E5EA;
                    margin-right: 10px;

                    .icon {
                        color: #B0B1B9;
                        width: 24px;
                        height: 24px;
                    }

                    span {
                        margin-top: 8px;
                        font-size: 12px;
                        color: #B0B1B9;
                        line-height: 12px;
                    }

                    img {
                        width: 126px;
                        height: 80px;
                        border-radius: 4px;
                    }
                }

                .card:hover {
                    .icon {
                        color: #9fa0ab;
                    }

                    span {
                        color: #9fa0ab;
                    }
                }

                .font {
                    background: url('../../assets/image/card-font.png') no-repeat;
                    background-size: 100% 100%;

                }

                .back {
                    background: url('../../assets/image/card-back.png') no-repeat;
                    background-size: 100% 100%;
                }
            }

            .upload-part {
                width: 330px;

                .upload-line {
                    width: 100%;
                    display: flex;
                    height: 32px;
                    align-items: flex-end;

                    span {
                        margin-left: 10px;
                        font-size: 12px;
                        color: #D0D0D2;
                        line-height: 14px;
                    }
                }
            }

            .label-part {
                width: 140px;
                background: #F8F8FA;
                display: flex;
                align-items: center;
                padding: 0 18px;
                box-sizing: border-box;
                .red{
                    color:#F74B4B;
                }

                span {
                    font-size: 14px;
                    color: #6B6D77;
                    line-height: 22px;

                    .icon {
                        margin-left: 3px;
                    }
                }
            }

            .content-part {
                width: calc(100% - 140px);
                height: auto;
                padding: 20px;
                box-sizing: border-box;

                .content-input {
                    width: 100%;

                    .input {
                        width: 230px;
                        margin-right: 10px;
                    }

                    .input:nth-child(n+3) {
                        width: 230px;
                        margin-right: 10px;
                        margin-top: 14px;
                    }
                }

                .content-card {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    margin-top: 14px;

                    .card-item {
                        width: 126px;
                        height: 80px;
                        box-sizing: border-box;
                        border: 1px dashed #F0F1F5;
                        margin-right: 10px;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        position: relative;
                        margin-bottom: 16px;

                        img {
                            width: 126px;
                            height: 80px;
                        }

                        .tip {
                            position: absolute;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            padding: 17px 0 0;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            box-sizing: border-box;

                            .icon {
                                width: 24px;
                                height: 24px;
                                color: #B0B1B9;
                            }

                            span {
                                margin-top: 8px;
                                font-size: 12px;
                                color: #B0B1B9;
                                line-height: 12px;
                            }
                        }
                    }
                }

                .content-upload {
                    width: 100%;
                    height: auto;

                    .upload-line {
                        width: 100%;
                        display: flex;
                        height: 32px;
                        align-items: flex-end;

                        span {
                            margin-left: 10px;
                            font-size: 12px;
                            color: #D0D0D2;
                            line-height: 14px;
                        }
                    }

                    .image-part {
                        width: 100%;
                        height: auto;
                        min-height: 64px;
                        margin-top: 14px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        position: relative;
                        flex-wrap: wrap;

                        .fileline {
                            .file-box {
                                width: 64px;
                                height: 64px;
                                position: relative;
                                margin-right: 10px;
                                margin-bottom: 10px;

                                .image {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 4px;
                                }

                                .close {
                                    position: absolute;
                                    right: -6px;
                                    top: -6px;
                                    width: 16px;
                                    height: 16px;
                                    border-radius: 50%;
                                    background: #E6E6E6;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    .closeicon {
                                        width: 8px;
                                        height: 8px;
                                        fill: #B0B1B9;
                                    }
                                }

                                .close:hover {
                                    background: rgba(44, 87, 255, 0.2) !important;

                                    .closeicon {
                                        fill: #2C57FF !important;
                                    }
                                }
                            }
                        }

                        .left-btn {
                            width: 20px;
                            height: 64px;
                            border-radius: 4px 0px 0px 4px;
                            background: rgba(0, 0, 0, 0.2);
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            // margin-right: 4px;
                            .lefticon {
                                width: 7px;
                                height: 12px;
                            }
                        }

                        .right-btn {
                            width: 20px;
                            height: 64px;
                            border-radius: 0px 4px 4px 0px;
                            background: rgba(0, 0, 0, 0.2);
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            // margin-left: -4px;
                            .righticon {
                                width: 7px;
                                height: 12px;
                                transform: rotate(180deg);
                            }
                        }

                        .change:hover {
                            background: rgba(0, 0, 0, 0.3) !important;
                        }

                        .leftpart {
                            width: 20px;
                            position: absolute;
                            left: 0;
                        }

                        .rightpart {
                            width: 20px;
                            position: absolute;
                            right: 0;
                        }
                    }
                }

                .file-part {
                    height: auto;
                    width: 100%;
                    margin-top: 14px;

                    .file-item {
                        display: flex;
                        height: 28px;
                        border-radius: 4px;
                        background: #F4F7FC;
                        width: 100%;
                        align-items: center;
                        margin-bottom: 6px;
                        padding: 0 12px 0 8px;
                        justify-content: space-between;
                        box-sizing: border-box;

                        .icon {
                            margin-right: 7px;
                            width: 12px;
                            height: 14px;
                        }

                        .name {
                            font-size: 12px;
                            color: #474952;
                        }

                        .close {
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            background: rgba(63, 69, 124, 0.1);
                            display: none;
                            align-items: center;
                            justify-content: center;

                            .closeicon {
                                width: 8px;
                                height: 8px;
                                fill: #B0B1B9;
                            }
                        }

                        .close:hover {
                            background: rgba(44, 87, 255, 0.2) !important;

                            .closeicon {
                                fill: #2C57FF !important;
                            }
                        }
                    }

                    .file-item:hover {
                        background: #EDF3FE !important;

                        .close {
                            display: flex;
                        }
                    }
                }
            }
        }

        .box-item:last-child {
            border: none;
        }
    }
}</style>
  