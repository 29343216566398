<template>
    <div class="change">
        <el-dialog :visible.sync="chgangeDialog"  class="dialog-element-change center-dialog top-dialog" @close="cancelCustom" style="overflow: hidden;" top="0" width="640px">
            <div class="dialog-box">
                <div class="dialog-main">
                    <div class="header fl-between">
                        <span class="title">客户信息</span>
                        <span class="close" @click="cancelCustom"><local-svg iconClass="close" class="icon hover handel-header-icon"></local-svg></span>
                    </div>
                    <div class="custom-mian">
                        <el-form :form="addform" label-width="" class="form-element-change select-element-change textarea-element-change input-element-change add-custom">
                            <el-form-item label="公司名称">
                                <span slot="label"><span class="check-form-notice">*</span>公司名称</span>
                                <el-input v-model="addform.name" placeholder="请输入公司名称" ></el-input>
                            </el-form-item>
                            <el-form-item label="统一社会信用代码">
                                <span slot="label"><span class="check-form-notice">*</span>统一社会信用代码</span>
                                <el-input v-model="addform.social_code" placeholder="请输入统一社会信用代码" ></el-input>
                            </el-form-item>
                            <el-form-item label="法定代表人">
                                <el-input v-model="addform.legal" placeholder="请输入法定代表人" ></el-input>
                            </el-form-item>
                                <el-form-item label="开业核准日期"> <el-date-picker v-model="addform.set_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
                            </el-form-item>
                            <el-form-item label="所属行业">
                                <el-input v-model="addform.industry" placeholder="请输入所属行业" ></el-input>
                            </el-form-item>
                            <el-form-item label="住所/经营场所">
                                <el-input v-model="addform.address" placeholder="请输入地址" ></el-input>
                            </el-form-item>
                            <el-form-item label="注册号">
                                <el-input v-model="addform.res_code" placeholder="请输入地址" ></el-input>
                            </el-form-item>
                            <el-form-item label="联系电话">
                                <el-input v-model="addform.connect" placeholder="请输入联系电话" ></el-input>
                            </el-form-item>
                            <el-form-item label="注册资本/金(万元)">
                                <el-input v-model="addform.res_capital" placeholder="请输入金额" ></el-input>
                            </el-form-item>
                            <el-form-item label="经营范围">
                                <el-input placeholder="请输入经营范围" type="textarea"   v-model="addform.business" style="width: 452px;"></el-input>
                            </el-form-item>
                        </el-form>
                        <div class="button-part" style="margin-top: 21px;">
                            <button class="confirm hover" @click="submitCustom">确定</button>
                            <button class="cancel hover" @click="cancelCustom">取消</button>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { com_editcustom  } from '@/api/custom'
export default{
    name:'monthchange',
    props:{
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        form:{
        	type:Object,
        	default:()=>{
        		return {}
        	}
        },
        type:{
            type:Number,
        	default:()=>{
        		return 1
        	}
        }
    },
	data(){
		return{
            chgangeDialog:false,
            addform:{
                name:'',
                social_code:'',
                legal:'',
                set_time:'',
                industry:'',
                address:'',
                res_code:'',
                connect:'',
                res_capital:'',
                business:'',
            },
        }
	},
    mounted() {
        this.addform = this.form
        console.log(this.addform)
        this.chgangeDialog = this.show
    },
    methods: {
        submitCustom(){
            let data = {
                id:this.addform.id,
                name:this.addform.name,
                social_code:this.addform.social_code,
                legal:this.addform.legal,
                set_time:this.addform.set_time,
                industry:this.addform.industry,
                address:this.addform.address,
                res_code:this.addform.res_code,
                connect:this.addform.connect,
                res_capital:this.addform.res_capital,
                business:this.addform.business,
                type:this.addform.client_type,
                linkman:this.addform.linkman
            }
            com_editcustom(data).then(res=>{
                if(res.code == 200){
                    this.$message.success('添加成功')
                    this.cancelCustom()
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        cancelCustom(){
            this.chgangeDialog = false
            this.$emit('cancel')
            this.resetChangeform()
        },
        resetChangeform(){
            this.addform = {
                name:'',
                social_code:'',
                legal:'',
                set_time:'',
                industry:'',
                address:'',
                res_code:'',
                connect:'',
                res_capital:'',
                business:'',
            }
        },
        closeEnterprise(){
            this.$emit('close',true)
        },
    },
}

</script>

<style lang="scss" scoped>
.change{
    .dialog-box{
        width:100%;
        height: 94vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        padding-top: 2vh;
        box-sizing: border-box;
        .dialog-main{
            box-sizing: border-box;
            border-radius: 6px;
            width: 100%;
            background: #fff;
            .header{
                width: 100%;
                height: 54px;
                border-bottom: 1px solid #F0F1F5;
                box-sizing: border-box;
                padding: 20px;
                border-radius: 6px 6px 0 0 ;
                .title{
                    font-size: 16px;
                    font-weight: bold;
                    color: #2B313D;
                    line-height: 16px;
                    margin-top: 4px;
                }
                .close{
                    .icon{
                        height: 16px;
                        width: 16px;
                    }
                }
            }
            .custom-mian{
                width: 100%;
                height: auto;
                // min-height: 727px;
                padding: 20px 24px 24px;
                box-sizing: border-box;
                background: #Fff;
                border-radius: 0 0 6px 6px;
                .search{
                    width: 100%;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    .label{
                        font-size: 14px;
                        color: #6B6D77;
                        line-height: 16px;
                        margin-right: 12px;
                    }
                    .search-select{
                        width: 640px;
                        height: auto;
                        box-sizing: border-box;
                        padding: 17px 0 16px;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.1);
                        border-radius: 8px;
                        position: absolute;
                        top: 45px;
                        margin: 0 calc(50% - 96px) 0 50%;
                        .search-part{
                            width: 100%;
                            height: auto;
                            max-height: 160px;
                            overflow-y: auto;
                            .search-item{
                                width: 100%;
                                text-align: left;
                                font-size: 14px;
                                color: #6B6D77;
                                line-height: 30px;
                                box-sizing: border-box;
                                padding: 0 20px;
                                span{
                                    color: #B0B1B9;
                                }
                            }
                            .search-item:hover{
                                background: #EDF3FE;
                            }
                        }
                        .button-part{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 26px;
                            margin-top: 14px;
                            span{
                                font-size: 12px;
                                color: #B0B1B9;
                                line-height: 12px;
                            }
                            button{
                                padding: 0 16px !important;
                                height: 26px;
                                line-height: 26px;
                                margin-left: 14px;
                                font-size: 12px;
                                color: #FFFFFF;
                                line-height: 12px;
                            }
                        }
                    }
                }
                .info{
                    width: 100%;
                    height: auto;
                    background: #FAFAFB;
                    border-radius: 6px;
                    box-sizing: border-box;
                    padding: 14px 20px 18px;
                    margin-top: 23px;
                    display: flex;
                    justify-content: space-between;
                    .info-left{
                        width: 500px;
                        height: auto;
                        .inline{
                            width: 100%;
                            display: flex;
                            align-items: center;
                            .label{
                                width: 128px;
                                color: #B0B1B9;
                                line-height: 28px;
                            }
                            .content{
                                font-size: 14px;
                                color: #474952;
                                line-height: 28px;
                                margin-left: 4px;
                                width: calc(100% - 132px);
                                display: flex;
                                height: 28px;
                                .content-old{
                                    width: 74px;
                                    height: 22px;
                                    background: #FFFFFF;
                                    border-radius: 3px;
                                    border: 1px solid #E2E5EA;
                                    font-size: 12px;
                                    // color: #2C57FF;
                                    line-height: 22px;
                                    text-align: center;
                                    margin-left: 16px;
                                }
                            }
                            .name{
                                width: calc(100% - 212px);
                                .icon{
                                    width: 10px;
                                    height: 5px;
                                    margin-left: 8px;
                                }
                            }
                        }
                    }
                    .info-right{
                        width: 477px;
                        height: auto;
                        .inline{
                            width: 100%;
                            display: flex;
                            align-items: center;
                            .label{
                                width: 104px;
                                color: #B0B1B9;
                                line-height: 28px;
                            }
                            .content{
                                font-size: 14px;
                                color: #474952;
                                line-height: 28px;
                                height: 28px;
                                margin-left: 4px;
                                width: calc(100% - 108px);
                                display: flex;
                                align-items: center;
                                .out-text{
                                    width: 336px;
                                }
                                .more{

                                }
                            }
                        }
                    }
                }
                .other{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    height: 370px;
                    margin-top: 12px;
                    .order-table{
                        width: 770px;
                        height: 100%;
                        .table-header{
                            height: 32px;
                            span{
                                font-weight: bold;
                                font-size: 14px;
                                color: #474952;
                                line-height: 14px; 
                            }
                        }
                        .table{
                            width: 100%;
                            margin-top: 8px;
                            height:calc(100% - 74px);
                        }
                        .page-change{
                            margin-top: 16px;
                        }
                    }
                    .process-part{
                        width: 300px;
                        height: 743px;
                        .title{
                            height: 32px;
                            display: flex;
                            align-items: center;
                            span{
                                font-weight: bold;
                                font-size: 14px;
                                color: #474952;
                                line-height: 14px;
                                margin-top: 1px;
                            }
                        }
                        .process{
                            margin-top: 15px;
                            max-height: 300px;
                            overflow-y: auto;
                            .process-item{
                                height: auto;
                                width: 100%;
                                margin-bottom: 14px;
                                .item-header{
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    .icon{
                                       width: 14px;
                                       height: 14px; 
                                    }
                                    .date{
                                        font-size: 12px;
                                        color: #B0B1B9;
                                        line-height: 12px;
                                        margin-left: 14px;
                                    }
                                }
                                .item-info{
                                    margin-top: 2px;
                                    height: auto;
                                    padding: 0 0 0 29px;
                                    box-sizing: border-box;
                                    position: relative;
                                    .line{
                                        width: 1px;
                                        height: 100%;
                                        background: #F0F1F5;
                                        position: absolute;
                                        left: 8px;
                                    }
                                    .main-box{
                                        width: 100%;
                                        height: auto;
                                        box-sizing: border-box;
                                        padding: 14px;
                                        background: #F3F7FE;
                                        border-radius: 4px;
                                        margin-top:7px;
                                        .name{
                                            font-weight: bold;
                                            font-size: 12px;
                                            color: #474952;
                                            line-height: 12px;
                                        }
                                        .desc{
                                            font-size: 12px;
                                            color: #6B6D77;
                                            line-height: 18px;
                                            margin-top: 6px;
                                        }
                                    }
                                }
                            }
                            .process-item:last-child{
                                margin-bottom: 0 !important;
                            }
                            .none{
                                width: 100%;
                                display: flex;
                                align-items: center;
                                flex-direction: column;
                                .tip{
                                    font-size: 12px;
                                    color: #D0D0D2;
                                    line-height: 14px;
                                    letter-spacing:1px;
                                }
                            }
                        }
                    }
                }
                .button-part{
                    text-align: right;
                    button{
                        margin-left: 10px;
                    }
                }
            }
            .button-part{
                text-align: right;
                box-sizing: border-box;
                width: 100%;
            }
        }
    }
} 
</style>
  