import request from '../utils/request'

//获取用户列表
// export function contarctlist(data){
//     return request({
//         url:'/contract/table',
//         method:'get',
//         params:data
//     })
// }

//获取用户列表（带渠道）
export function contarctlist(data){
    return request({
        url:'/v1/sell/contractList',
        method:'get',
        params:data
    })
}

//获取用户列表表头
export function headlist(data){
    return request({
        url:'/common/field',
        method:'get',
        params:data
    })
}

//获取合同下拉选择
export function selectlist(data){
    return request({
        url:'/contract/save',
        method:'get',
        params:data
    })
}

//添加合同
export function addnewcontart(data){
    return request({
        url:'/v1/contract/saveNewContract',
        method:'post',
        data
    })
}


//添加合同
export function addcontart(data){
    return request({
        url:'/contract/save',
        method:'post',
        data
    })
}

//合同详情
// export function contarctdetail(data){
//     return request({
//         url:'/contract/contractDetail',
//         method:'get',
//         params:data
//     })
// }

// 合同详情(渠道信息)
export function contarctdetail(data){
    return request({
        url:'/v1/sell/contractDetail',
        method:'get',
        params:data
    })
}

//合同批量删除
export function moredel(data){
    return request({
        url:'/contract/del',
        method:'get',
        params:data
    })
}

//获取跟进人选择框
export function getfollow(data){
    return request({
        url:'/contract/addFollower',
        method:'get',
        params:data
    })
}

//添加跟进人
export function addfollow(data){
    return request({
        url:'/contract/addFollower',
        method:'post',
        data
    })
}

//添加跟进人
export function delfollow(data){
    return request({
        url:'/contract/delFollower',
        method:'get',
        params:data
    })
}

//编辑合同
export function editcontarct(data){
    return request({
        url:'/contract/edit',
        method:'post',
        data
    })
}

//编辑合同
export function editnewcontarct(data){
    return request({
        url:'/v1/contract/editContract',
        method:'post',
        data
    })
}

//审核合同
export function examinecontarct(data){
    return request({
        url:'/contract/auditContract',
        method:'post',
        data
    })
}

//获取合同下单项明细
export function getcontarctdown(data){
    return request({
        url:'/waiqin/contractSingle',
        method:'get',
        params:data
    })
}
