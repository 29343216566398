<template>
    <div class="refuse">
        <el-dialog :title="title" :visible.sync="showDialog" width="400px" class="addform"  @close="cancel">
            <div class="addform" style="padding-bottom:10px">
                <el-form :model="checkform" :rules="rules" ref="checkform">
                    <el-form-item label="拒绝原因" class="long-input" prop="reason">
                        <span slot="label"><span class="check-form-notice">*</span>拒绝原因</span>
                        <el-input v-model="checkform.reason" type="textarea"  resize="none" placeholder="请输入拒绝原因"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            
            <div slot="footer" class="dialog-footer btnpart">
                <el-button class="confirm" type="primary" @click="refuseOk('checkform')">提 交</el-button>
                <el-button class="cancel" @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "adduser",
    props: {
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
    },
    mounted(){
        this.showDialog = this.show
    },
    data() {
        return {
            checkform:{
                reason:'',
            },
            showDialog:false,
            title:'审核意见',
            rules: {
                // reason: [
                //     { required: true, message: '请输入拒绝原因', trigger: 'blur' },
                // ]
            },
        };
    },
    methods: {
        cancel(){
            this.$emit('close',false)
            this.clean()
        },
        refuseOk(checkform){
            this.$refs[checkform].validate((valid) => {
                if (valid) {
                    this.$emit('examine',this.checkform)
                    this.clean()
                } else {
                    return false;
                }
            });
            
        },
        clean(){
        },
    }
}
</script>

<style lang="scss">
.refuse{
    .long-input{
        min-height: 100px !important;
        .el-textarea__inner{
            min-height: 100px !important;
            font-size: 14px;
            padding: 10 14px !important;
            font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
        }
        .el-input__suffix {
            right: 8px;
            transition: all .3s;
            pointer-events: none;
        } 
        .el-form-item__error{
            top: 103px !important;
        }
    }

}
</style>
