<template>
    <div class="custom">
        <div class="border" v-if="!isDetail">
            <div class="top-search" >
                <el-form :model="form" class="demo-form-inline" label-width="">
                    <div class="select-line bostrap contract" :style="{ height: (hide == false ? '43px' : 'auto') }" id="searchLine">
                        <el-form-item label="合同单号" class="">
                            <el-input v-model="form.number" placeholder="请输入关键字">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="客户名称">
                            <el-select v-model="form.client" placeholder="请选择" clearable filterable  popper-class="custom-select">
                                <el-option v-for="item in clientList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="所属部门" >
                            <el-select v-model="form.branch_id" placeholder="请选择" clearable filterable>
                                <el-option v-for="item in branchAllList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="销售机会">
                            <el-select v-model="form.sell_id" placeholder="请选择" clearable multiple collapse-tags filterable>
                                <el-option v-for="item in sellList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="负责人">
                            <el-select v-model="form.admin_id" placeholder="请选择" clearable multiple collapse-tags filterable>
                                <el-option v-for="item in adminList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="合同金额" class="between">
                            <div class="rangeinput">
                                <el-input class="left" v-model="form.start_total_amount" placeholder="请输入金额"></el-input>
                                至
                                <el-input class="right" v-model="form.end_total_amount" placeholder="请输入金额"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="累计回款" class="between">
                            <div class="rangeinput">
                                <el-input class="left" v-model="form.startmoney" placeholder="请输入金额"></el-input>
                                至
                                <el-input class="right" v-model="form.endmonet" placeholder="请输入金额"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="合同审核状态" >
                            <el-select v-model="form.is_del" placeholder="请选择" clearable multiple collapse-tags>
                                <el-option v-for="item in typeList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="是否有纸质合同" >
                            <el-select v-model="form.paper" placeholder="请选择" clearable>
                                <el-option v-for="item in paperList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="创建时间" class="time">
                            <el-date-picker v-model="form.set_time" value-format="yyyy-MM-dd" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" type="daterange" placeholder="请选择"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="业务类型" class="time">
                            <el-select v-model="form.business_type" placeholder="请选择" clearable>
                                <el-option v-for="item in singleList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="line"></div>
                    <div class="btn-line">
                        <div class="btnpart">
                            <el-button class="confirm" @click="getList" plain v-permission="'合同管理-select'">查询</el-button>
                            <el-button class="cancel" @click="clearSeach" plain v-permission="'合同管理-reset'">重置</el-button>
                        </div>
                        <div class="showpart hover" @click="showHide">
                            {{ actText }}
                            <local-svg iconClass="showdown" class="icon" :class="hide?'acticon':''"></local-svg>
                        </div>
                    </div>

                </el-form>
            </div>
            <div class="tablepart main-table">
                <div class="top">
                    <div class="span">合同管理</div>
                    <div class="btnlist btnpart">
                        <el-button class="iconbtn" @click="deleteMoreUser" plain v-permission="'合同管理-batch_del'"><local-svg iconClass="delete"
                                class="icon"></local-svg>批量删除</el-button>
                        <el-button class="iconbtn" @click="showDialog = true" plain v-permission="'合同管理-list'"><local-svg iconClass="set"  
                                class="icon"></local-svg>设置列表展示列</el-button>
                        <el-button class="iconbtn" @click="exprotFile" plain v-permission="'合同管理-export'"><local-svg iconClass="expor"
                                class="icon"></local-svg>导出</el-button>
                        <el-button class="iconbtn" @click="beginAdd" plain v-permission="'合同管理-create'"><local-svg iconClass="add"
                                class="icon"></local-svg>新建</el-button>
                    </div>
                </div>
                <customTable ref="dadtable" v-if="headerList.length > 0" :list="tableData" :head="showHeader" @transfer="wantAdd"
                    @tableedit="wantEdit" @stopuser="setUserType" @deluser="deleteUser" @detail="seeDetail" @select="selectRow" :loading="load"
                    @outwork="changeOutwork">
                </customTable>
                <div v-else style="height:560px"></div>
                <div class="pagetab">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <setshowDialog v-if="showDialog" :list="headerList" :show="showDialog" @show="setShow" @cancel="closeShow">
        </setshowDialog>
        <exportDialog v-if="exportDialog" :list="headerList" :show="exportDialog" @export="exportTable"
            @cancel="cancelExport"></exportDialog>
        <changeDialog v-if="changeDialog" :form="detailData" :show="changeDialog" @cancel="cancelChange" @add="addUser" ref="changeDialog"
            :type="addtype" @edit="deitUser" :admin='clientList' :sell='sellList' :paper='paperList' :pay='payList'
            :team='teamList' :branch='branchList' :sign="singleList" :detail="detailList" :a_s="typeList"></changeDialog>
        <detailPart v-if="isDetail" @back="closeDetail" :info="detailData" @add="addFollow" :follow="followList" @refuse="wantRefuse" :isDetail="isExamine"></detailPart>
        <addSelect v-if="followDialog" :show="followDialog" :list="headerList" @add="checkUser" @close="closeFollow"></addSelect>
        <OutWork v-if="ourworkDialog" :showdialog="ourworkDialog" :id="outwork_id" @closereceive="closeOutWork" :contract_num="actContract_name" :channel="channel" :source="source"></OutWork>
        <!-- <iframe src='http://192.168.110.198/storage/image/16a8c947f3f5fdc640267a9984515462.jpg' frameborder="0" style="width:100% ; height: 700px"></iframe> -->
    </div>
</template>

<script>
import {contarctlist,headlist,selectlist,addcontart,addnewcontart,contarctdetail,moredel,editcontarct,getcontarctdown, editnewcontarct,} from '@/api/contarct'
import { getbackstatus } from '@/api/outwork'
import {saveheader} from '@/api/common'
import customTable from "@/components/custom/table.vue"
import setshowDialog from "@/components/custom/show.vue"
import exportDialog from "@/components/custom/export.vue"
import changeDialog from "@/components/contract/change.vue"
import detailPart from "@/components/contract/detail.vue"
import addSelect from "@/components/contract/follow.vue"
import OutWork from "@/components/contract/outwork.vue"
import refuseExamine from "@/components/examine/refuse.vue"
export default {
    name: 'custom',
    data() {
        return {
            refuseDialog:false,
            fileDialog:false,
            followDialog:false,
            showDialog: false,  //显示列表弹窗
            hide: false,
            exportDialog: false,
            changeDialog: false,
            ourworkDialog:false,
            isDetail: false,
            isExamine:false,
            load: false,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            outwork_id:'',
            actContract_name:'',
            actText: '展开',
            channel:'',
            source:'',
            userList: [],
            typeList: [],     //审核状态列表
            clientList:[],   //客户列表
            branchList:[],   //部门列表
            sellList:[],    //销售机会
            paperList:[],    //纸质合同列表
            adminList:[],    //负责人列表
            teamList:[],    //合作方式列表
            payList:[],    //付款方式
            singleList:[],   //业务类型
            detailList:[],   //业务明细
            branchAllList:[],
            partList: [],  
            form: {
                branch_id:'',
                sell_id:[],
                admin_id:[],
                is_del:[],
                business_type:'',
                paper:[],
                number:'',
                set_time:['',''],
                client:[],
                start_total_amount:'',
                end_total_amount:'',
                startmoney:'',
                endmoney:'',
            },
            tableData: [],
            showHeader: [],
            headerList: [],
            selectionList: [],
            tradeList: [],
            addtype: 1,
            detailData:{},
            followList:[],
            actId:'',
            load:true
        }
    },
    components: {
        customTable,
        setshowDialog,
        exportDialog,
        changeDialog,
        detailPart,
        addSelect,
        refuseExamine,
        OutWork
    },
    mounted() {
        if(this.$route.query.id){
            if(this.$route.query.type && this.$route.query.type=='examine'){
                let data = {
                    id:this.$route.query.id
                }
                this.seeDetail(data)
                this.isExamine = true
            }
            if(this.$route.query.type && this.$route.query.type=='detail'){
                let data = {
                    id:this.$route.query.id
                }
                this.seeDetail(data)
            }
            if(this.$route.query.type && this.$route.query.type=='generate'){
                this.detailData.sell_name = this.$route.query.sell_name
                this.detailData.sell = this.$route.query.id
                this.detailData.id = this.$route.query.client_id
                this.detailData.source = this.$route.query.source
                this.detailData.channel = this.$route.query.channel
                this.addtype = 1
                this.changeDialog = true
            }
            if(this.$route.query.type && this.$route.query.type=='add'){
                this.addtype = 1
                this.changeDialog = true
            }
        }
        if(this.$route.params.id){
            if(this.$route.params.type && this.$route.params.type=='examine'){
                let data = {
                    id:this.$route.params.id
                }
                this.seeDetail(data)
                this.isExamine = true
            }
            if(this.$route.params.type && this.$route.params.type=='detail'){
                let data = {
                    id:this.$route.params.id
                }
                this.seeDetail(data)
            }
            if(this.$route.params.type && this.$route.params.type=='generate'){
                this.detailData.sell_name = this.$route.params.sell_name
                this.detailData.sell = this.$route.params.id
                this.detailData.id = this.$route.params.client_id
                this.detailData.source = this.$route.params.source
                this.detailData.channel = this.$route.params.channel
                this.addtype = 1
                this.changeDialog = true
            }
            if(this.$route.params.type && this.$route.params.type=='add'){
                this.addtype = 1
                this.changeDialog = true
            }
        }
        this.getSelect()
        this.getList()
        this.getHeaderList()
        this.beginAdd = this.$debounce(this.beginAdd )
        this.exprotFile = this.$debounce(this.exprotFile )
    },
    methods: {
        changeOutwork(msg){
            this.channel = msg.channel
            this.source = msg.source
            this.outwork_id = msg.id
            this.ourworkDialog = true
            this.actContract_name = msg.number
        },
        closeOutWork(){
            this.ourworkDialog = false
        },
        closeFollow(){
            this.followDialog = false
        },
        wantRefuse(msg){
            this.refuseDialog = true
            this.actId = msg
        },
        cancelRefuse(){
            this.refuseDialog = false
        },
        checkUser(msg){
            this.followList.push(msg)
            this.followDialog = false
        },
        addFollow(){
            this.followDialog = true
        },
        getSelect() {
            selectlist().then(res => {
                this.typeList = res.data.option.audit_status
                this.clientList = res.data.option.client
                this.branchList = res.data.option.branch
                this.sellList = res.data.option.sells
                this.paperList = res.data.option.paper
                this.adminList = res.data.option.admin
                this.teamList = res.data.option.team_type
                this.payList = res.data.option.pay_type
                this.singleList = res.data.option.single
                this.detailList = res.data.option.detail
                this.branchAllList = res.data.option.branchAll
            })
        },
        selectRow(row) {
            this.selectionList = row
            console.log(this.selectionList, 111)
        },
        deleteMoreUser() {
            if (this.selectionList.length == 0) {
                this.$message({
                    message: '请先选择要删除的数据！',
                    type: 'error'
                })
                return
            }

            this.$confirm('此操作将永久删除所选数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let array = []
                this.selectionList.forEach(item => {
                    array.push(item.id)
                })
                let data = {
                    b_id: array.toString()
                }
                moredel(data).then(res => {
                    if (res.code == 200) {
                        this.getList()
                        this.selectionList = []
                        this.changeDialog = false
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        deitUser(msg) {
            msg.single.forEach(item =>{
                item.amount = Number(item.amount.replace(/,/g, ""))
            })
            let data = {
                id:msg.id,
                client:msg.client,
                sell:msg.sell,
                paper:msg.paper,
                branch:msg.branch,
                discount_info:msg.discount_info,
                remark:msg.remark,
                end_time:msg.end_time,
                start_time:msg.start_time,
                sign_time:msg.sign_time,
                pay_type:msg.pay_type,
                team_type:msg.team_type,
                audit_status:msg.audit_status,
                team_type:msg.team_type,
                audit_status:msg.audit_status,
                files:msg.files,
                single:msg.single,
                audit_status:msg.audit_status,
                handle_remark:msg.handle_remark,
            }
            data.one_key_sell = msg.a_s == 16 && msg.one_key_sell == 1 ? 1 : 2
            this.$loading({ text: '加载中...' });
            editnewcontarct(data).then(res => {
                if (res.code == 200) {
                    this.getList()
                    this.changeDialog = false
                    this.detailData = {}
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        exprotFile() {
            this.exportDialog = true
        },
        seeDetail(msg) {
            let data = {
                contract_id: msg.id
            }
            contarctdetail(data).then(res => {
                if(res.code == 200){
                    this.detailData = res.data
                    this.isDetail = true
                }
                else{
                    this.$message.error(res.msg)
                }
            })
        },
        beginAdd() {
            this.addtype = 1
            this.detailData = {}
            this.changeDialog = true
        },
        addUser(data) {
            this.$loading({ text: '加载中...' });
            console.log(data.single)
            data.single.forEach(item =>{
                item.amount = Number(item.amount.replace(/,/g, ""))
            })
            data.one_key_sell = this.$route.query.type=='generate' ? 1 : 2
            addnewcontart(data).then(res => {
                if (res.code == 200) {
                    this.getList()
                    this.changeDialog = false
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        getHeaderList() {
            let data = {
                id:3
            }
            headlist(data).then(res => {
                this.showHeader = []
                this.headerList = res.data
                this.headerList.forEach(item => {
                    this.$set(item, 'check', item.is_show==1?true:false)
                    if(item.is_show==1){
                        this.showHeader.push(item)
                    }
                })
                this.$nextTick(() => {
                    this.$refs.dadtable.$refs.showTable.doLayout()
                })
            })
        },
        closeDetail() {
            this.getList()
            this.isDetail = false
        },
        cancelChange() {
            this.changeDialog = false
            this.detailData = {}
        },
        exportTable(msg) {
            let idlist = []
            msg.forEach(item =>{
                idlist.push(item.id)
            })
            let data = {
                number:this.form.number,
                client:this.form.client,
                branch:this.form.branch_id,
                sell:this.form.sell_id.toString(),
                admin_id:this.form.admin_id.toString(),
                start_total_amount:this.form.start_total_amount,
                end_total_amount:this.form.end_total_amount,
                startmoney:this.form.startmoney,
                endmoney:this.form.endmoney,
                audit_status:this.form.is_del.toString(),
                paper:this.form.paper,
                start_time:this.form.set_time[0],
                end_time:this.form.set_time[1],
            }
            window.open(`${this.$BASE_URL}contract/export?contract=${idlist.toString()}&client_id=${data.client.toString()}&admin_id=${data.admin_id}
            &number=${data.number}&sell_id=${data.sell}&start_total_amount=${data.start_total_amount}&end_total_amount=${data.end_total_amount}
            &startmoney=${data.startmoney}&endmoney=${data.endmoney}&audit_status=${data.audit_status}&paper=${data.paper}&branch_id=${data.branch}
            &start_time=${data.start_time}&end_time=${data.end_time}&token=${localStorage.getItem('token')}`,'_blank')
            this.exportDialog = false
        },
        cancelExport() {
            this.exportDialog = false
        },
        // 设置展示列保存&取消
        setShow(msg) {
            let ids = []
            msg.forEach(item =>{
                ids.unshift(item.id)
            })
            let data = {
                id:ids.toString(),
                type:3
            }
            saveheader(data).then(res =>{
                if(res.code == 200){
                    this.$message.success(res.msg)
                    this.showHeader = msg
                    this.showDialog = false
                    this.getHeaderList()
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        closeShow(msg) {
            this.showDialog = false
        },
        bindUser() { },
        clearSeach() {
            this.form = {
                branch: '',
                type: '',
                user: '',
                set_time:['',''],
                keyword:'',
                keytype:'',
                type_id:[],
                business_type:'',
                sell_id:[],
                admin_id:[],
                is_del:[],
            }
        },
        deleteUser(msg) {
            let data = {
                b_id: msg
            }
            moredel(data).then(res => {
                if (res.code == 200) {
                    this.getList()
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
            })
        },
        wantAdd() { },
        // 获取用户列表
        getList() {
            this.load = true
            let data = {
                page: this.currentPage,
                count: this.pageSize,
                number:this.form.number,
                client:this.form.client,
                branch:this.form.branch_id,
                sell:this.form.sell_id.toString(),
                admin_id:this.form.admin_id.toString(),
                start_total_amount:this.form.start_total_amount,
                end_total_amount:this.form.end_total_amount,
                startmoney:this.form.startmoney,
                endmoney:this.form.endmoney,
                business_type:this.form.business_type,
                audit_status:this.form.is_del.toString(),
                paper:this.form.paper,
                start_time:this.form.set_time[0],
                end_time:this.form.set_time[1],
            }
            contarctlist(data).then(res => {
                this.tableData = res.data.table
                this.total = res.data.total
                let data_list = []
                this.tableData.forEach(item =>{
                    item.channel = item.sell_info && item.sell_info.channel ? item.sell_info.channel : '/'
                    item.source = item.sell_info && item.sell_info.source ? item.sell_info.source : '/'
                    item.sell_id = item.sell_info && item.sell_info.name ? item.sell_info.name : '/'
                    data_list.push(item.id)
                })
                let data = {
                    contract_id : data_list.toString()
                }
                let a = 0
                getbackstatus(data).then(res=>{
                    let response_data = res.data
                    for(let key in response_data){
                        if(response_data.hasOwnProperty(key)){
                            this.$set(this.tableData[a],'is_perfect',response_data[key])
                        }
                        a ++
                    }
                    console.log(this.tableData)
                })
                setTimeout(() => {
                    this.load = false
                }, 2000);
            })
        },
        wantEdit(msg) {
            let data = {
                contract_id: msg.info.id
            }
            contarctdetail(data).then(res => {
                this.addtype = 2
                this.detailData = res.data
                this.detailData.branch_name = res.data.branch
                this.detailData.single.forEach(item =>{
                    item.amount = Number(item.amount).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
                })
                this.changeDialog = true
            })
        },
        setUserType() { },
        showHide() {
            this.actText = this.hide ? '展开' : '收起'
            this.hide = !this.hide
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.currentPage = 1
            this.getList()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getList()
        },
    }
}

</script>

<style src="@/assets/scss/formstyle.scss" lang="scss" ></style>
<style lang="scss" scoped>
* {
    box-sizing: content-box;
}

.custom {
    
    .border{
        padding: 24px;
    }
    .top-search {
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;

        .select-line {
            display: flex;
            padding: 0 20px 0 0;
        }

        .line {
            width: 100%;
            height: 1px;
            background: #F0F1F5;
        }

        .btn-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 11px 20px;

            .showpart {
                height: 100%;
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #475B93;
                .icon {
                    margin-left: 6px;
                    width: 7px;
                    height: 18px;
                }
                .acticon{
                    transform: rotate(180deg);
                }
            }
            .showpart:hover{
                color: #2C57FF !important;
            }
            .act {
                background: #2C57FF;
                color: white;
            }

            .btnpart button {
                height: 32px;
                width: 68px;
                line-height: 32px;
                padding: 0;

            }
        }
    }

    .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
            font-size: 14px;
            font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
            font-weight: bold;
            color: #474952;
            line-height: 16px;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .icon {
            width: 16px;
            height: 16px;
        }

        i {
            margin-right: 5px;
        }
    }

    .tablepart {
        min-height: 658px;
        background: white;
        margin-top: 10px;
        border-radius: 4px;
        padding: 16px 20px;

        .icon {
            margin-right: 8px;
        }

        .pagetab {
            text-align: right;
            margin-top: 20px;
        }

        .span {
            font-size: 14px;
            font-weight: bold;
            color: #474952;
        }

        .top {
            display: flex;
            align-items: center;
        }
    }
    .iframe-body-sty{position: relative;overflow: hidden;height:600px;width: 1200px;background-color: red;}
	.iframe-body-sty>#iframe-shrink{position: absolute;transform:scale(0.55);left: -400px;top: -170px;}
}
</style>