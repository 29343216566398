<template>
    <div class="role">
        <div class="rolebox">
            <!-- <div class="role-search">
                <el-input v-model="keyword" placeholder="请输入关键字" @input="searchUser">
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
            </div> -->
            <div class="role-scroll">
                <div class="rolelist hover" v-for="(item,index) in list" :key="index" :class="index==actindex?'active':''" @click="changRole(index,item)">
                    <div class="left handel">
                    <local-svg iconClass="role" class="img" style="width:18px; height:18px"></local-svg>
                    {{ item.name }}
                    </div>
                    <el-dropdown trigger="click">
                        <span class="el-dropdown-link">
                            <div class="link-box handelicon">
                                <local-svg iconClass="more" class="img hover "></local-svg>
                            </div>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click.native="editRole(item)" v-permission="'角色管理-edit'">编辑</el-dropdown-item>
                            <el-dropdown-item @click.native="delRole(item)" v-permission="'角色管理-del'">删除</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {roledetail} from '@/api/terrace'
import follow from '@/assets/js/addfollow'
export default {
    name: "role",
    props: {
        list:{
        	type:Array,
        	default:()=>{
        		return []
        	}
        },
    },
    data() {
        return {
            actindex:0,
            keyword:''
        };
    },
    mounted(){
    },
    methods: {
        editRole(item){
            let data = {
                id:item.id
            }
            roledetail(data).then(res=>{
                this.$emit('change',res.data)
            })
        },
        searchUser(){
            this.$emit('search',this.keyword)
        },
        changRole(index,item){
            this.actindex = index
            this.$emit('ckeck',item.id)
            follow.$emit('close',true)
        },
        delRole(data){
            this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.$emit('delete',data)
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
            });
        },
        handleCheckChange(data, checked, indeterminate) {
            console.log(data, checked, indeterminate);
        },
        handleNodeClick(data) {
            console.log(data);
        },
    }
}
</script>

<style lang="scss" scoped>
.role {
    // overflow-y: auto;

    .rolebox{
        width: 100%;
        box-sizing: border-box;
        // border-top:  1px solid ;
        // padding-top: 20px;
        .role-search{
            box-sizing: border-box;
            padding: 16px;
        }
        .active{
            background: #EDF3FE !important;
            .left{
                color: #2C57FF !important;  
            }
            border-right: 4px solid #2C57FF !important;
        }
        .role-scroll{
            overflow-y: scroll;
            height: calc(100vh - 240px);
        }
        .role-scroll::-webkit-scrollbar {
            display: none;
        }
        .rolelist{
            width: 100%;
            height: 54px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding:  0 16px 0 20px;
            box-sizing: border-box;
            border-right: 4px solid #fff;
            
            .left{
                font-size: 14px;
                line-height: 16px;
                color: #6B6D77;
                display: flex;
                .img{
                    width: 20px;
                    height: 18px;
                    margin-right: 9px;
                }
            }
            .el-dropdown-link{
                display: flex;
                align-items: center;
                .link-box{
                    width: 100%;
                    height: 20px;
                    display: flex;
                    align-items: center;
                }
            }
        }

    }
}
.el-dropdown-menu__item:not(.is-disabled):hover{
    color: #2C57FF !important;
    background: #F4F6FE !important;
}
</style>
