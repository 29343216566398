<template>
    <div class="detail">
        <div class="top-banner">
            <div class="background"
                :style="{ 'background': 'url(' + image + ') no-repeat', 'backgroundSize': '1012px 100%', 'backgroundPosition': 'right', 'opacity': '0.9' }">
                <div class="banner-left">
                    <div class="title">{{ info.name }}<div class="tip">{{ info.system }}</div>
                    </div>
                    <div class="message">
                        <div class="line" :class="{ 'active': showWidth < 1350 }">行业：<span>{{ info.trade }}</span></div>
                        <div class="line" :class="{ 'active': showWidth < 1350 }">企业类型：<span>{{ info.type }}</span></div>
                    </div>
                </div>
                <div class="banner-right">
                    <button class="print hover" @click="generateSell">
                        <local-svg iconClass="generate"></local-svg>
                        <span>生成销售机会</span>
                    </button>
                    <button class="btn hover" @click="goBack">
                        <local-svg iconClass="back"></local-svg>
                        <span>返回上一页</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="main auto-detail-part">
            <div class="descript">
                <el-descriptions title="详细资料" :column="2" border>
                    <el-descriptions-item label="法定代表人">{{ info.legal ? info.legal : '/' }}</el-descriptions-item>
                    <el-descriptions-item label="联系方式">{{ info.connect ? info.connect : '/' }}</el-descriptions-item>
                    <el-descriptions-item label="成立日期">{{ info.set_time ? info.set_time : '/' }}</el-descriptions-item>
                    <el-descriptions-item label="备注">{{ info.remark ? info.remark : '/' }}</el-descriptions-item>
                    <el-descriptions-item label="住所" :span="2">{{ info.domicile ? info.domicile : '/' }}</el-descriptions-item>
                    <el-descriptions-item label="详细地址" :span="2">{{ info.address ? info.address : '/' }}</el-descriptions-item>
                    <el-descriptions-item label="经营范围" :span="2">{{ info.business ? info.business : '/' }}</el-descriptions-item>
                    <el-descriptions-item label="负责人">{{ info.admin ? info.admin : '/' }}</el-descriptions-item>
                    <el-descriptions-item label="所属部门">{{ info.branch ? info.branch : '/' }}</el-descriptions-item>
                    <el-descriptions-item label="创建日期">{{ info.create_time ? info.create_time : '/' }}</el-descriptions-item>
                    <el-descriptions-item label="是否创业客户">{{ info.pionner == 1 ? '是' : '否' }}</el-descriptions-item>
                    <el-descriptions-item label="来源">{{ info.system ? info.system : '/' }}</el-descriptions-item>
                    <el-descriptions-item label="客户类别">{{ info.category ? info.category : '/' }}</el-descriptions-item>
                </el-descriptions>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "detail",
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        info: {
            type: Object,
            default: () => {
                return {}
            }
        },
        readDetail:{
            type: Number,
            default: () => {
                return 0
            }
        }
    },
    data() {
        return {
            image: require('../../assets/image/detail.png'),
            ischange: false,
            showWidth: 1920,
        };
    },
    mounted() {
        let that = this
        window.onresize = () => {
            return (() => {
                that.showWidth = document.body.clientWidth
            })()
        }
        this.generateSell = this.$debounce(this.generateSell )
    },
    methods: {
        generateSell(){
            let data = {
                id:this.info.id,
                type:'generate'
            }
            this.$router.push({
                path:'/sellchance',
                query:data
            })
        },
        goBack() {
            // if (this.readDetail == 1 ) {
            //     this.$router.go(1)
            // }
            // else {
                this.$emit('back', false)
            // }
        }
    }
}
</script>

<style lang="scss">
.detail {
    .top-banner {
        width: 100%;
        height: 112px;
        border-radius: 4px;
        background: linear-gradient(90deg, #FFF 0%, #F0F4FE 50%, #F0F4FE 100%);
        display: flex;
        justify-content: flex-end;

        .background {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 26px 30px;
            display: flex;
            justify-content: space-between;

            .banner-left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .title {
                    font-size: 22px;
                    font-weight: bold;
                    color: #474952;
                    max-width: 570px;
                    display: flex;
                    align-items: center;

                    .tip {
                        width: 38px;
                        height: 24px;
                        line-height: 24px;
                        color: #FFF;
                        font-size: 14px;
                        border-radius: 4px;
                        background: #2C57FF;
                        margin-left: 10px;
                        text-align: center;
                        font-weight: normal;
                    }
                }

                .message {
                    display: flex;

                    .line {
                        font-size: 14px;
                        color: #C4C5CB;
                        margin-right: 20px;

                        span {
                            color: #7D7F88;
                        }
                    }

                    .active {
                        color: #8999B1;
                    }
                }
            }

            .banner-right {
                display: flex;
                align-items: center;

                .btn {
                    width: 128px;
                    height: 32px;
                    box-shadow: 0px 2px 4px 0px rgba(55, 59, 92, 0.1);
                    background: linear-gradient(180deg, #F8F8FB 0%, #ECEFF6 100%);
                    border-radius: 4px 4px 4px 4px;
                    outline: none;
                    border: none;
                    font-size: 14px;
                    color: #6B6D77;

                    span {
                        margin-left: 8px;
                    }
                }

                .btn:hover {
                    background: linear-gradient(180deg, #ECEFF6 0%, #F8F8FB 100%);
                }
                .print {
                    width: auto;
                    height: 32px;
                    // box-shadow: 0px 2px 4px 0px rgba(55, 59, 92, 0.1);
                    background: linear-gradient(180deg, #355EFF 0%, #1744F2 100%);
                    border-radius: 4px 4px 4px 4px;
                    outline: none;
                    border: none;
                    font-size: 14px;
                    color: white;
                    margin-right: 10px;
                    padding: 0 16px;
                    span {
                        margin-left: 8px;
                    }
                }
                
                .print:hover {
                    background: linear-gradient(180deg, #1744F2 0%, #355EFF 100%);
                }
            }
        }
    }

    .main {
        width: 100%;
        height: 700px;
        background: white;
        margin-top: 10px;
        border-radius: 4px;
        padding: 24px 30px !important;
        box-sizing: border-box;

        .title {
            font-weight: bold;
            font-size: 14px;
        }
        .el-descriptions__header{
            margin-bottom: 16px !important;
        }
        .el-descriptions__header{
            margin-bottom: 16px !important;
        }
        .descript {}
    }
}
</style>
