<template>
    <div class="home">
        {{ text }}
    </div>
</template>

<script>
import {jumplogin} from '@/api/login'
import {routesData} from '@/utils/permission'
export default{
    name:'home',
	data(){
		return{
            token:'',
            platform:'',
            text:'正在跳转中，请稍候......',
            type_id:'',
            data_id:'',
            info_id:''
        }
	},
    created(){
        if(this.$route.query.token){
            this.token = this.$route.query.token
            this.platform = this.$route.query.platform
            this.type_id = this.$route.query.type_id?this.$route.query.type_id:''
            this.data_id = this.$route.query.data_id?this.$route.query.data_id:''
            this.handle = this.$route.query.handle?this.$route.query.handle:''
            this.info_id = this.$route.query.info_id?this.$route.query.info_id:''
            localStorage.setItem('tmp_token',this.token)
            this.checkToken()
        }

    },
    methods:{
        
        checkToken(){
            let data = {
                platform:this.platform
            }
            jumplogin(data).then(res=>{
                if(res.code == 200){
                    localStorage.clear()
                    window.localStorage.setItem('phone', '')
                    window.localStorage.setItem('password', '')
                    window.localStorage.setItem('token', res.data.token)
                    this.$store.commit('setUserPower', res.data.permission)
                    this.$store.commit('setUserName', res.data.name)
                    this.$store.commit('setUserImage', res.data.image)
                    this.$store.commit('setUserRole', res.data.role)
                    routesData(this.$store.state.userpower)
                    this.setBtnPower(res.data.permission)
                    if(this.type_id){
                        let key = this.type_id
                        let value = this.data_id
                        if(key == 1){
                            this.$router.replace({
                                path:'/contract',
                                query:{
                                    id:value,
                                    type:'examine'
                                }
                            })
                        }
                        else if(key == 2){
                            this.$router.replace({
                                path:'/contractback',
                                query:{
                                    id:value,
                                    type:'examine'
                                }
                            })
                        }
                        else if(key == 3){
                            this.$router.replace({
                                path:'/constapply',
                                query:{
                                    id:value,
                                    type:'examine'
                                }
                            })
                        }
                        else if(key == 4){
                            this.$router.replace({
                                path:'/houseCollection',
                                query:{
                                    id:value,
                                    type:'examine'
                                }
                            })
                        }
                        else if(key == 7){
                            this.$router.replace({
                                path:'/openbill',
                                query:{
                                    id:value,
                                    type:'examine'
                                }
                            })
                        }
                        else if(key == 5){
                            this.$router.push({
                                path:'/constapply',
                                query:{
                                    id:value,
                                    type:'examinebill'
                                }
                            })
                        }
                        else if(key == 6){
                            this.$router.push({
                                path:'/constapply',
                                query:{
                                    id:value,
                                    type:'examineconfirm'
                                }
                            })
                        }
                        else if(key == 10){
                            this.$router.push({
                                path:'/openbill',
                                query:{
                                    id:value,
                                    type:'improvebill'
                                }
                            })
                        }
                    }else if(this.handle){
                        if(this.handle == 'addcontract'){
                            this.$router.push({
                                path:'/contract',
                                query:{
                                    id:0,
                                    type:'add'
                                }
                            })
                        }
                        else if(this.handle == 'sell'){
                            this.$router.push({
                                path:'/sellchance',
                                query:{
                                    id:this.info_id,
                                    type:'detail'
                                }
                            })
                        }
                        else if(this.handle == 'contract'){
                            this.$router.push({
                                path:'/contract',
                                query:{
                                    id:this.info_id,
                                    type:'detail'
                                }
                            })
                        }
                        else if(this.handle == 'house'){
                            this.$router.push({
                                path:'/housecontract',
                                query:{
                                    id:this.info_id,
                                    type:'detail'
                                }
                            })
                        }
                        else if(this.handle == 'contract_back'){
                            this.$router.push({
                                path:'/contractback',
                                query:{
                                    id:this.info_id,
                                    type:'detail'
                                }
                            })
                        }
                        else if(this.handle == 'house_back'){
                            this.$router.push({
                                path:'/housecontract',
                                query:{
                                    id:this.info_id,
                                    type:'detail'
                                }
                            })
                        }
                        else if(this.handle == 'apply'){
                            this.$router.push({
                                path:'/constapply',
                                query:{
                                    id:this.info_id,
                                    type:'detail'
                                }
                            })
                        }
                        else if(this.handle == 'bill'){
                            this.$router.push({
                                path:'/openbill',
                                query:{
                                    id:this.info_id,
                                    type:'detail'
                                }
                            })
                        }
                    }
                    else{
                        this.$router.push('/custom')
                    }
                }else{
                    this.$message({
                        messgae:res.msg,
                        type:'error'
                    })
                }
            })
        },
        setBtnPower(array){
            let arr = []
            for(var a = 0;a<array.length;a++){
                let item = array[a]
                for(var b=0;b<item.page.length;b++){
                    let initem = item.page[b]
                    if(initem.button){
                        for(var c = 0;c<initem.button.length;c++){
                            let btnname = item.has_child==1?initem.name+'-'+initem.button[c]:item.name+'-'+initem.button[c]
                            arr.push(btnname)
                        }
                    }
                    
                }
            }
            this.$store.commit('setUserBtn',arr)
        },
    }
}

</script>

<style lang="scss" scoped>
.home{
    background: #fff !important;
} 
</style>
  