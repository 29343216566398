<template>
    <div class="detail">
        <div class="top-banner">
            <div class="background"
                :style="{ 'background': 'url(' + image + ') no-repeat', 'backgroundSize': '1012px 100%', 'backgroundPosition': 'right', 'opacity': '0.9' }">
                <div class="banner-left">
                    <div class="title">{{ info.name }}
                        <el-tooltip placement="bottom" popper-class="popper-element-class" effect="light" v-if="old_name">
                            <div slot="content">
                                <div class="popper-main">
                                    <!-- <div class="name-item" v-for="(item,index) in old" :key="index">
                                        {{ item.name }}
                                        <span class="date">({{ item.date }})</span>
                                    </div> -->
                                    <div class="name-item">
                                        {{ item.old_name }}
                                    </div>
                                </div>
                            </div>
                            <div class="content-old fl-center handel-icon hover">曾用名
                                <local-svg iconClass="down" class="icon"></local-svg>
                            </div>
                        </el-tooltip>
                        <local-svg iconClass="edit" class="edit-icon hover handel-onlyicon" @click.native="changeInfo(1)" v-permission="'客户管理-edit'"></local-svg>
                    </div>
                    <div class="message">
                        <div class="line" :class="{ 'active': showWidth < 1350 }">来源：<span>{{ from }}</span></div>
                        <!-- <div class="line" :class="{ 'active': showWidth < 1350 }">企业类型：<span>{{ info.type }}</span></div> -->
                    </div>
                </div>
                <div class="banner-right">
                    <button class="print hover" @click="generateSell"  v-permission="'客户管理-generateSell'">
                        <local-svg iconClass="generate"></local-svg>
                        <span>生成销售机会</span>
                    </button>
                    <button class="btn hover" @click="goBack">
                        <local-svg iconClass="back"></local-svg>
                        <span>返回上一页</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="custom-detail">
            <div class="detail-left">
                <div class="info-part">
                    <div class="header-line fl-between button-part">
                        <span>详细信息</span>
                        <!-- <button class="confirm-icon hover"  @click="beginEditInfo(2)"><local-svg iconClass="edit" class="icon" v-permission="'客户管理-edit'"></local-svg>编辑</button> -->
                    </div>
                    <div class="descript descriptions-element-change">
                        <el-descriptions  :column="2" border>
                            <el-descriptions-item label="法定代表人" >{{ info.legal?info.legal:'/' }}</el-descriptions-item>
                            <el-descriptions-item label="联系方式">{{ info['60_company_phone']?info['60_company_phone']:'/' }}</el-descriptions-item>
                            <el-descriptions-item label="注册号">{{ info.res_code?info.res_code:'/' }}</el-descriptions-item>
                            <el-descriptions-item label="统一社会信用代码">{{ info.social_code?info.social_code:'/' }}</el-descriptions-item>
                            <el-descriptions-item label="所属行业">{{ info.industry?info.industry:'/' }}</el-descriptions-item>
                            <el-descriptions-item label="注册资本/金(万元)">{{ info.res_capital?info.res_capital:'/' }}</el-descriptions-item>
                            <el-descriptions-item label="开业核准日期">{{ info.set_time?info.set_time:'/' }}</el-descriptions-item>
                            <el-descriptions-item label="住所/经营场所">{{ info.address?info.address:'/' }}</el-descriptions-item>
                            <el-descriptions-item label="经营范围" :span="2">{{ info.business?info.business:'/' }}</el-descriptions-item>
                            <el-descriptions-item label="拒绝原因" :span="2" v-if="info.alter_type_record && info.alter_type_record.status == 2">{{ info.alter_type_record.reason?info.alter_type_record.reason:'/' }}</el-descriptions-item>
                        </el-descriptions>
                    </div>
                </div>
                <div class="table-part">
                    <div class="header-line fl-between button-part">
                        <span>联系方式</span>
                        <button class="confirm-icon hover" @click="addTable" v-permission="'客户管理-addClueEvent'"><local-svg iconClass="add" class="icon"></local-svg>添加</button>
                    </div>
                    <div class="table-element-change dialog-table custom-table" >
                        <el-table :data="tableData" v-loading="loading" height="100%" style="width: 100%;" ref="attractTable" tooltip-effect="light">
                            <template slot="empty">
                                <el-empty :image-size="250" :image="image1" description='暂无数据'></el-empty>
                            </template>
                            <el-table-column  type='index' width="50" align="center" label="序号"></el-table-column>
                            <el-table-column props="tel" width="180" align="left" label="手机号">
                                <template slot-scope="scope">
                                    <div v-if="!scope.row.is_edit">{{ scope.row.phone }}</div>
                                    <el-input v-else v-model="scope.row.phone" class="input-element-change" placeholder="请输入"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column props="tel" align="left" label="客户名称">
                                <template slot-scope="scope">
                                    <div v-if="!scope.row.is_edit">{{ scope.row.name }}</div>
                                    <el-input v-else v-model="scope.row.name" class="input-element-change" placeholder="请输入"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column props="tel" width="180" align="left" label="职位">
                                <template slot-scope="scope">
                                    <div v-if="!scope.row.is_edit">{{ scope.row.job?scope.row.job:'-' }}</div>
                                    <el-input v-else v-model="scope.row.job" class="input-element-change" placeholder="请输入"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column props="tel" width="180" align="left" label="负责人">
                                <template slot-scope="scope">
                                    <div v-if="!scope.row.is_edit">{{ scope.row.admin_name }}</div>
                                    <el-select v-else v-model="scope.row.admin_id" placeholder="请选择" clearable filterable  class="select-element-change option-element-change">
                                        <el-option v-for="item in selectList" :label="item.name" :value="item.id" 
                                            :key="item.id"></el-option>
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column props="tel" width="100" align="center" label="操作">
                                <template slot-scope="scope">
                                    <div class="fl-between">
                                        <span v-if="scope.row.is_edit" class="handel-text hover" @click="confirmTable(scope.row)">确定</span>
                                        <span v-if="scope.row.is_edit" class="handel-text hover" @click="cancelTable(scope.row)">取消</span>
                                        <span v-if="!scope.row.is_edit" class="handel-text hover" @click="editTable(scope.row)">编辑</span>
                                        <span v-if="!scope.row.is_edit" class="handel-text hover" @click="delTable(scope.row)">删除</span>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="number-page-element-change page-change">
                        <el-pagination
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-size="pageSize"
                            layout="total, prev, pager, next"
                            :total="total"
                            :pager-count="5"
                            popper-class="select-element-change">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <div class="detail-right">
                <div class="right-line fl-between">
                    <span class="name">跟进记录</span>
                    <span class="handel-icon hover" @click="changeInfo(2)" v-permission="'客户管理-addClueEvent'"><local-svg iconClass="add" class="icon"></local-svg>添加跟进记录</span>
                </div>
                <div class="process" v-if="porcessList.length>0">
                    <div class="process-item" v-for="(item,index) in porcessList" :key="index">
                        <div class="item-header">
                            <local-svg iconClass="sellchance" class="icon"></local-svg>
                            <span class="date">{{ item.create_time }}</span>
                        </div>
                        <div class="item-info">
                            <div class="line" v-if="index != porcessList.length - 1"></div>
                            <div class="main-box">
                                <div class="name">{{ item.admin }}</div>
                                <div class="desc">{{ item.follow_content }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="process" v-else>
                    <div class="none">
                        <img src="../../assets/image/none.png" alt="">
                        <div class="tip">暂无跟进记录!</div>
                    </div>
                </div>
            </div>
            <div class="button-part examine-part" v-if="is_examine">
                <button class="confirm hover" type="button" style="margin-right: 16px;" @click="agreeChange" >审核通过</button>
                <button class="cancel hover" type="button" @click="refuse_dialog = true">审核拒绝</button>
            </div>
        </div>
        <el-dialog :visible.sync="chgangeDialog"  class="dialog-element-change center-dialog top-dialog" @close="cancelCustom" style="overflow: hidden;" top="0" width="640px">
            <div class="dialog-box">
                <div class="dialog-main">
                    <div class="header fl-between">
                        <span class="title">客户信息</span>
                        <span class="close" @click="cancelCustom"><local-svg iconClass="close" class="icon hover handel-header-icon"></local-svg></span>
                    </div>
                    <div class="custom-mian">
                        <el-form :form="addform" label-width="" class="form-element-change select-element-change textarea-element-change input-element-change add-custom">
                            <el-form-item label="统一社会信用代码">
                                <span slot="label"><span class="check-form-notice">*</span>统一社会信用代码</span>
                                <el-input v-model="addform.social_code" placeholder="请输入统一社会信用代码" ></el-input>
                            </el-form-item>
                            <el-form-item label="法定代表人">
                                <el-input v-model="addform.legal" placeholder="请输入法定代表人" ></el-input>
                            </el-form-item>
                            <el-form-item label="开业核准日期"> 
                                <el-date-picker v-model="addform.set_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
                            </el-form-item>
                            <el-form-item label="所属行业">
                                <el-input v-model="addform.industry" placeholder="请输入所属行业" ></el-input>
                            </el-form-item>
                            <el-form-item label="住所/经营场所">
                                <el-input v-model="addform.address" placeholder="请输入地址" ></el-input>
                            </el-form-item>
                            <el-form-item label="注册号">
                                <el-input v-model="addform.res_code" placeholder="请输入注册号" ></el-input>
                            </el-form-item>
                            <el-form-item label="联系电话">
                                <el-input v-model="addform.connect" placeholder="请输入联系电话" ></el-input>
                            </el-form-item>
                            <el-form-item label="注册资本/金(万元)">
                                <el-input v-model="addform.res_capital" placeholder="请输入金额" ></el-input>
                            </el-form-item>
                            <el-form-item label="经营范围">
                                <el-input placeholder="请输入经营范围" type="textarea"   v-model="addform.business" style="width: 452px;"></el-input>
                            </el-form-item>
                        </el-form>
                        <div class="button-part" style="margin-top: 21px;">
                            <button class="confirm hover" @click="submitChangeInfo">确定</button>
                            <button class="cancel hover" @click="cancelChangeInfo">取消</button>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="perfectDialog"  class="dialog-element-change center-dialog top-dialog" @close="closePerfect" style="overflow: hidden;" top="0" width="400px">
            <div class="dialog-box">
                <div class="dialog-main">
                    <div class="header fl-between">
                        <span class="title">{{change_type == 1 ? '编辑客户信息' :'添加跟进记录'}}</span>
                        <span class="close" @click="closePerfect"><local-svg iconClass="close" class="icon hover handel-header-icon"></local-svg></span>
                    </div>
                    <div class="custom-mian">
                        <el-form :form="addform" label-width="" class="form-element-change select-element-change textarea-element-change input-element-change add-follow">
                            <el-form-item label="企业名称" v-if="change_type == 1">
                                <span slot="label"><span class="check-form-notice">*</span>企业名称</span>
                                <el-input v-model="addform.name" placeholder="请输入企业名称" ></el-input>
                            </el-form-item>
                            <!-- <el-form-item label="跟进人员" v-if="change_type == 2">
                                <span slot="label"><span class="check-form-notice">*</span>跟进人员</span>
                                <el-select v-model="addform.follow_admin" placeholder="请选择" clearable filterable  >
                                    <el-option v-for="item in selectList" :label="item.name" :value="item.id"
                                        :key="item.id"></el-option>
                                </el-select>
                            </el-form-item> -->
                            <el-form-item label="跟进内容" v-if="change_type == 2">
                                <span slot="label"><span class="check-form-notice">*</span>跟进内容</span>
                                <el-input placeholder="请输入跟进内容" type="textarea" v-model="addform.follow_content" style="width: 270px;"></el-input>
                            </el-form-item>
                        </el-form>
                        <div class="button-part" style="margin-top: 21px;">
                            <button class="confirm hover" v-if="change_type == 1" @click="submitChangeInfo">确定</button>
                            <button class="confirm hover" v-else @click="submitFollow">确定</button>
                            <button class="cancel hover" @click="closePerfect">取消</button>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <refuseDialog v-if="refuse_dialog" :show="refuse_dialog" @close="cancelRefuse" @examine="disagreeChange"></refuseDialog>
    </div>
</template>

<script>
import { com_editcustom,com_customlist,com_delcustom,addfollow,followlist,com_customdetail,customedit } from '@/api/custom'
import { alluser } from '@/api/terrace'
import refuseDialog from '@/components/examine/refuse.vue'
export default {
    name: "detail",
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        form: {
            type: Object,
            default: () => {
                return {}
            }
        },
        readDetail:{
            type: Number,
            default: () => {
                return 0
            }
        },
        from: {
            type: String,
            default: () => {
                return 'OA'
            }
        },
        is_examine:{
            type: Boolean,
            default: () => {
                return false
            }
        },
        out_number:{
            type: String,
            default: () => {
                return ''
            }
        },
        remind_id:{
            type: String,
            default: () => {
                return ''
            }
        },
    },
    components:{
        refuseDialog
    },
    data() {
        return {
            image: require('../../assets/image/detail.png'),
            image1: require('../../assets/image/none.png'),
            ischange: false,
            loading:false,
            chgangeDialog:false,
            perfectDialog:false,
            refuse_dialog:false,
            showWidth: 1920,
            currentPage:1,
            total:0,
            pageSize:10,
            change_type:1,
            old_name:'',
            old:[
                {name:'旧名字',date:'2020-2020'},
                {name:'旧名字1',date:'2020-2020'},
            ],
            info:{},
            addform:{
                id:'',
                name:'',
                social_code:'',
                legal:'',
                set_time:'',
                industry:'',
                address:'',
                res_code:'',
                connect:'',
                res_capital:'',
                business:'',
                linkman:[],
                follow_admin:'',
                follow_content:'',
            },
            tableData:[],
            selectList:[],
            porcessList:[
            ],
        };
    },
    mounted() {
        let that = this
        window.onresize = () => {
            return (() => {
                that.showWidth = document.body.clientWidth
            })()
        }
        this.generateSell = this.$debounce(this.generateSell )
        this.getAllUser()
        this.getInfo(this.form)
        this.getFollowList()
        this.getOldName()
    },
    methods: {
        agreeChange(){
            let data = {
                client_id:this.form.id,
                status:1,
                reason:'',
                waiqin_num:this.out_number,
                remind_id:this.remind_id
            }
            customedit(data).then(res=>{
                if(res.code == 200){
                    this.$message.success('操作成功')
                    // this.getInfo(this.form)
                    // this.$emit('examine')
                    this.$router.push({
                        path:'/custom',
                        query:{
                            id:this.form.id,
                            type:'detail',
                        }
                    })
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        disagreeChange(msg){
            let data = {
                client_id:this.form.id,
                status:2,
                reason:msg.reason,
                waiqin_num:this.out_number,
                remind_id:this.remind_id
            }
            customedit(data).then(res=>{
                if(res.code == 200){
                    this.$message.success('操作成功')
                    this.refuse_dialog = false
                    // this.getInfo(this.form)
                    // this.$emit('examine')
                    this.$router.push({
                        path:'/custom',
                        query:{
                            id:this.form.id,
                            type:'detail',
                        }
                    })
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        cancelRefuse(){
            this.refuse_dialog = false
        },
        getOldName(){
            let data = {
                client_id:this.form.id
            }
            com_customdetail(data).then(res=>{
                this.old_name = res.data.old_name
            })
        },
        getInfo(e){
            this.info = e
            this.tableData = e.linkman
            this.tableData.forEach(item =>{
                this.$set(item,'is_edit',false)
            })
            this.total = e.linkman.length
        },
        getAllUser(){
            alluser().then(res=>{
                this.selectList = res.data
            })
        },
        getDetail(e){
            let data = {
                client_id:e.id
            }
            com_customdetail(data).then(res=>{
                if(res.code == 200) {
                    this.getInfo(res.data)
                    this.$message.success('操作成功！')
                    this.chgangeDialog = false
                    this.perfectDialog = false
                }
            })
        },
        getFollowList(){
            let data = {
                client_id:this.form.id
            }
            followlist(data).then(res=>{
                this.porcessList = res.data
            })
        },
        beginEditInfo(e){
            this.addform.id = this.info.id
            this.addform.name = this.info.name
            this.addform.social_code = this.info.social_code
            this.addform.legal = this.info.legal
            this.addform.set_time = this.info.set_time
            this.addform.industry = this.info.industry
            this.addform.address = this.info.address
            this.addform.res_code = this.info.res_code
            this.addform.connect = this.info.connect
            this.addform.res_capital = this.info.res_capital
            this.addform.business = this.info.business
            this.addform.linkman = this.info.tableData
            this.addform.type = this.info.client_type
            this.addform.follow_admin = ''
            this.addform.follow_content = ''
            if(e == 2){
                this.chgangeDialog = true
            }else{
                this.perfectDialog = true
            }
        },
        closePerfect(){
            this.perfectDialog = false
        },
        cancelChangeInfo(){
            this.chgangeDialog = false
        },
        submitChangeInfo(){
            let data = this.addform
            com_editcustom(data).then(res=>{
                if(res.code == 200){
                    let data = {
                        name:this.addform.name
                    }
                    this.getDetail(data)
                    this.$emit('change',this.addform)
                    this.getOldName()
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        submitFollow(){
            let data = {
                client_id:this.info.id,
                follow_admin:1,
                follow_content:this.addform.follow_content
            } 
            addfollow(data).then(res=>{
                if(res.code == 200){
                    this.$message.success('添加成功！')
                    this.perfectDialog = false
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        generateSell(){
            let data = {
                id:this.info.id,
                type:'generate'
            }
            this.$router.push({
                path:'/sellchance',
                query:data
            })
        },
        changeInfo(index){
            this.change_type = index
            if(index == 1){
                this.beginEditInfo(index)
            }else{
                this.perfectDialog = true
                this.addform.follow_admin = ''
                this.addform.follow_content = ''
            }
        },
        addTable(){
            this.tableData.push({
                id:'',
                name:'',
                phone:'',
                admin:'',
                admin_id:'',
                is_edit:true
            })
        },
        confirmTable(row){
            let list = []
            this.tableData.forEach(item =>{
                list.push({
                    id:item.id,
                    name:item.name,
                    phone:item.phone,
                    job:item.job,
                    admin_id:item.admin_id,
                })
            })
            let data = {
                name:this.info.name,
                social_code:this.info.social_code,
                legal:this.info.legal,
                set_time:this.info.set_time,
                industry:this.info.industry,
                address:this.info.address,
                res_code:this.info.res_code,
                connect:this.info.connect,
                res_capital:this.info.res_capital,
                business:this.info.business,
                id:this.info.id,
                type:this.info.client_type,
                linkman:list
            }
            com_editcustom(data).then(res=>{
                if(res.code == 200){
                    let data = {
                        id:this.info.id
                    }
                    this.getDetail(data)
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        cancelTable(row){
            let data = {
                name:this.info.name
            }
            this.getDetail(data)
        },
        editTable(row){
            row.is_edit = true
        },
        delTable(row){
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id:row.id
                }
                com_delcustom(data).then(res=>{
                    if(res.code == 200){
                        let data = {
                            name:this.info.name
                        }
                        this.getDetail(data)
                    }else{
                        this.$message.error(res.msg)
                    }
                })  
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        goBack() {
            this.$emit('back', false)
        },
        cancelCustom(){
            this.chgangeDialog = false
        },
        handleCurrentChange(val){
            this.currentPage = val
        },
    }
}
</script>

<style lang="scss" scoped>
.detail {
    .top-banner {
        width: 100%;
        height: 112px;
        border-radius: 4px;
        background: linear-gradient(90deg, #FFF 0%, #F0F4FE 50%, #F0F4FE 100%);
        display: flex;
        justify-content: flex-end;

        .background {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 26px 30px;
            display: flex;
            justify-content: space-between;

            .banner-left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .title {
                    font-size: 22px;
                    font-weight: bold;
                    color: #474952;
                    max-width: 1000px !important;
                    display: flex;
                    align-items: center;
                    .content-old{
                        width: 78px;
                        height: 24px;
                        background: #FFFFFF;
                        border-radius: 3px;
                        border: 1px solid #E2E5EA;
                        font-size: 14px;
                        color: #6B6D77;
                        line-height: 14px;
                        text-align: center;
                        margin-left: 10px;
                        font-weight: normal;
                    }
                    
                    .icon{
                        width: 10px;
                        height: 5px;
                        margin-left: 8px;
                    }
                    .edit-icon{
                        width: 14px;
                        height: 14px;
                        margin-left: 13px;
                    }
                    .tip {
                        width: 38px;
                        height: 24px;
                        line-height: 24px;
                        color: #FFF;
                        font-size: 14px;
                        border-radius: 4px;
                        background: #2C57FF;
                        margin-left: 10px;
                        text-align: center;
                        font-weight: normal;
                    }
                }

                .message {
                    display: flex;

                    .line {
                        font-size: 14px;
                        color: #C4C5CB;
                        margin-right: 20px;

                        span {
                            color: #7D7F88;
                        }
                    }

                    .active {
                        color: #8999B1;
                    }
                }
            }

            .banner-right {
                display: flex;
                align-items: center;

                .btn {
                    width: 128px;
                    height: 32px;
                    box-shadow: 0px 2px 4px 0px rgba(55, 59, 92, 0.1);
                    background: linear-gradient(180deg, #F8F8FB 0%, #ECEFF6 100%);
                    border-radius: 4px 4px 4px 4px;
                    outline: none;
                    border: none;
                    font-size: 14px;
                    color: #6B6D77;

                    span {
                        margin-left: 8px;
                    }
                }

                .btn:hover {
                    background: linear-gradient(180deg, #ECEFF6 0%, #F8F8FB 100%);
                }
                .print {
                    width: auto;
                    height: 32px;
                    // box-shadow: 0px 2px 4px 0px rgba(55, 59, 92, 0.1);
                    background: linear-gradient(180deg, #355EFF 0%, #1744F2 100%);
                    border-radius: 4px 4px 4px 4px;
                    outline: none;
                    border: none;
                    font-size: 14px;
                    color: white;
                    margin-right: 10px;
                    padding: 0 16px;
                    span {
                        margin-left: 8px;
                    }
                }
                
                .print:hover {
                    background: linear-gradient(180deg, #1744F2 0%, #355EFF 100%);
                }
            }
        }
    }
    .custom-detail{
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        height: calc(100vh - 230px);
        margin-top: 10px;
        position: relative;
        .detail-left{
            width: calc(100% - 350px);
            height: auto;
            .info-part{
                width: 100%;
                height: auto;
                padding: 16px 30px 30px;
                box-sizing: border-box;
                background: #FFFFFF;
                border-radius: 4px;
                .header-line{
                    width: 100%;
                    height: 32px;
                    span{
                        font-weight: bold;
                        font-size: 14px;
                        color: #474952;
                        line-height: 16px;
                    }
                }
                .descript{
                    margin-top: 10px;
                }
            }
            .table-part{
                width: 100%;
                height: calc(100vh - 584px);
                padding: 16px 30px 30px;
                box-sizing: border-box;
                background: #FFFFFF;
                border-radius: 4px;
                margin-top: 10px;
                margin-bottom: 24px;
                .header-line{
                    width: 100%;
                    height: 32px;
                    span{
                        font-weight: bold;
                        font-size: 14px;
                        color: #474952;
                        line-height: 16px;
                    }
                }
                .custom-table{
                    height: calc(100% - 76px);
                }
                .table-element-change{
                    margin-top: 10px;
                }
                .page-change{
                    margin-top: 16px;
                }
            }

        }
        .detail-right{
            width: 340px;
            height: auto;
            box-sizing: border-box;
            padding: 22px 20px 26px;
            background: #FFFFFF;
            border-radius: 4px;
            .right-line{
                height: 18px;
                width: 100%;
                .name{
                    font-weight: bold;
                    font-size: 14px;
                    color: #474952;
                    line-height: 14px;
                }
                .handel-icon{
                    font-size: 14px;
                    color: #475B93;
                    line-height: 16px;
                    .icon{
                        width: 14px;
                        height: 14px;
                        margin-right: 6px;
                    }
                }
            }
            .process{
                margin-top: 22px;
                overflow-y: auto;
                // max-height: calc(100% - 40px);
                max-height: calc(100vh - 318px);
                .process-item{
                    height: auto;
                    width: 100%;
                    margin-bottom: 14px;
                    .item-header{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        .icon{
                            width: 14px;
                            height: 14px; 
                        }
                        .date{
                            font-size: 12px;
                            color: #B0B1B9;
                            line-height: 12px;
                            margin-left: 14px;
                        }
                    }
                    .item-info{
                        margin-top: 2px;
                        height: auto;
                        padding: 0 0 0 29px;
                        box-sizing: border-box;
                        position: relative;
                        .line{
                            width: 1px;
                            height: 100%;
                            background: #F0F1F5;
                            position: absolute;
                            left: 8px;
                        }
                        .main-box{
                            width: 100%;
                            height: auto;
                            box-sizing: border-box;
                            padding: 14px;
                            background: #F3F7FE;
                            border-radius: 4px;
                            margin-top:7px;
                            .name{
                                font-weight: bold;
                                font-size: 12px;
                                color: #474952;
                                line-height: 12px;
                            }
                            .desc{
                                font-size: 12px;
                                color: #6B6D77;
                                line-height: 18px;
                                margin-top: 6px;
                            }
                        }
                    }
                }
                .process-item:last-child{
                    margin-bottom: 0 !important;
                }
                .none{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    .tip{
                        font-size: 12px;
                        color: #D0D0D2;
                        line-height: 14px;
                        letter-spacing:1px;
                    }
                }
            }
        }
        .examine-part{
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    .main {
        width: 100%;
        height: 700px;
        background: white;
        margin-top: 10px;
        border-radius: 4px;
        padding: 24px 30px !important;
        box-sizing: border-box;

        .title {
            font-weight: bold;
            font-size: 14px;
        }
        .el-descriptions__header{
            margin-bottom: 16px !important;
        }
        .el-descriptions__header{
            margin-bottom: 16px !important;
        }
        .descript {}
    }
    .dialog-box{
        width:100%;
        height: 94vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        padding-top: 2vh;
        box-sizing: border-box;
        .dialog-main{
            box-sizing: border-box;
            border-radius: 6px;
            width: 100%;
            background: #fff;
            .header{
                width: 100%;
                height: 54px;
                border-bottom: 1px solid #F0F1F5;
                box-sizing: border-box;
                padding: 20px;
                border-radius: 6px 6px 0 0 ;
                .title{
                    font-size: 16px;
                    font-weight: bold;
                    color: #2B313D;
                    line-height: 16px;
                    margin-top: 4px;
                }
                .close{
                    .icon{
                        height: 16px;
                        width: 16px;
                    }
                }
            }
            .custom-mian{
                width: 100%;
                height: auto;
                // min-height: 727px;
                padding: 20px 24px 24px;
                box-sizing: border-box;
                background: #Fff;
                border-radius: 0 0 6px 6px;
                .button-part{
                    text-align: right;
                    button{
                        margin-left: 10px;
                    }
                }
            }
            .button-part{
                text-align: right;
                box-sizing: border-box;
                width: 100%;
            }
        }
    }

}
</style>
