<template>
    <div class="box">
        <div class="line">
            <a @click.stop="closeNav" class=" handel">
                <local-svg class="icon hover" iconClass="navbtn" :class="{'acthaer':isClose}"></local-svg>
            </a>
            <div class="tab-list">
                <div class="list-box hover handel-span " v-for="(item, index) in tabList" :key="index"
                    :class="{ 'acttab': index == actindex }" @click="changeTab(item, index)">
                    <div class="box-msg ">
                        {{ item.name }}
                        <div class="box-close handel-span" @click.stop="closeTab(index)">
                            <local-svg v-if="index == actindex" class="img hover" iconClass="close"></local-svg>
                            <local-svg v-else class="img hover" iconClass="close1"></local-svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="line2">
            <div class="user avatar-handel">
                <img class="user-avatar" :src="$store.state.userimage" alt="" style="border-radius: 50%;">
                <el-popover placement="bottom" title="" width="96" popper-class="showbox hover" trigger="hover">
                    <div class="showbox">
                        <!-- <div v-for="(item, index) in mineList" :key="index" class="showbox-list" @click.stop="toPage(item)">
                            {{ item.name }}
                        </div> -->
                        <div class="showbox-list" @click="toPersonal">个人中心</div>

                        <div @click="logout" class="showbox-list">退出登录</div>
                    </div>
                    <button class="button hover" slot="reference">{{ info.name }}</button>
                </el-popover>
                <local-svg class="user-show hover" iconClass="admindown"></local-svg>
            </div>
            <div class="iconlist">
                <div>
                    <a class="handel" @click="examine">
                        <el-badge :value="totalMessage" :max="999" class="item hover" :hidden="totalMessage==0?true:false">
                            <local-svg class="list-image msg hover " iconClass="msg"></local-svg>
                        </el-badge>
                    </a>
                </div>
                <div>
                    <a @click="cleanTab()" class="handel">
                        <local-svg class="list-image hover " iconClass="clean"></local-svg>
                    </a>
                </div>
                <div>
                    <a @click="refsh" class="handel">
                        <local-svg class="list-image hover " iconClass="newload"></local-svg>
                    </a>
                </div>
                <div class="more-box" v-if="moreList.length > 0">
                    <el-popover placement="bottom" title="" width="96" popper-class="showbox hover" trigger="hover">
                        <div class="showbox">
                            <div v-for="(item, index) in moreList" :key="index" class="showbox-list"
                                @click.stop="toPage(item)">
                                {{ item.name }}
                            </div>
                        </div>
                        <button class="button hover" slot="reference">
                            <local-svg class="list-image hover handel" iconClass="more"></local-svg>
                        </button>
                    </el-popover>
                </div>
            </div>
        </div>
        <el-drawer
            :visible.sync="messageDialog"
            :with-header="false"
            class="messageBox">
            <div class="message-info">
                <div class="msg-header">
                    <div class="headtab">
                        <div class="tab-box hover">
                            <div class="tab" v-for="(item,index) in msgTabList" :key="index" :class="{'acttab':actMsgIndex==index}" @click="changTab(index)">
                                <el-badge :value="item.num" :max="999" class="item" :hidden="item.num==0?true:false">{{ item.name }}</el-badge>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="msg-list " v-if="actMsgIndex ==0">
                    <div v-for="(item,index) in msgList" :key="index" class="msg hover" @click="toExamine(item.data_id,item.type_id,item)">
                        <div class="msg-top">
                            <div class="msg-title"><div v-show="item.is_read=='未读'" class="msg-point"></div>{{ item.type?item.type:item.type_id == 12 ? '客户管理' : '' }}</div>
                            <span   class="msg-date">{{ item.create_time }}</span>
                        </div>
                        <div class="msg-main" v-if="item.type_id==5">{{ item.num }}该笔费用申请未提交相关票据</div>
                        <div class="msg-main" v-else-if="item.type_id==6">{{ item.admin }}发起的{{ item.num }}已提交相关票据，请及时处理</div>
                        <div class="msg-main" v-else-if="item.type_id==12">{{ item.admin }}于{{ item.create_time }}提交了{{ item.remind_other }}的营业执照，判断是否更新客户名称</div>
                        <div class="msg-main" v-else>{{ item.admin }}于{{ item.create_time }}提交了{{ item.num }}审批，请及时处理</div>
                    </div>
                </div>
                <div class="msg-list " v-if="actMsgIndex ==1">
                    <div v-for="(item,index) in noticeList" :key="index" class="msg hover" @click="toNotice(item)">
                        <!-- <div class="msg-top">
                            <div class="msg-title"><div v-show="item.is_read=='未读'" class="msg-point"></div>{{ item.title }}</div>
                            <span   class="msg-date">{{ item.create_time }}</span>
                        </div> -->
                        <div class="report" style="color: #6B6D77;"><span class="msg-point" v-if="!item.is_read"></span>{{ item.create_id }}于{{ item.create_time }}发布了{{ item.title }}公告，请及时查看</div>
                    </div>
                </div>
                <div class="msg-list" v-if="actMsgIndex ==2">
                    <div v-for="(item,index) in systemList" :key="index" class="msg1">
                        <div class="msg1-main">{{ item.content }}</div>
                        <div class="msg1-date">{{ item.create_time }}</div>
                    </div>
                </div>
                <div class="msg-bottom">
                    <div class="msg-more handel hover" @click="toMore">查看更多>></div>
                    <div class="msg-clear handel hover"  v-if="actMsgIndex==2||actMsgIndex==1">
                        <div class="img1">   
                            <local-svg iconClass="delete" ></local-svg>
                        </div>
                        <span @click="clearSystem">清空</span>
                    </div>
                </div>
            </div>
        </el-drawer>
        <el-dialog title="" :visible.sync="reportDialog" width="720px" class="report-dialog">
            <div class="report-dialog-mian">
                <p class="report-title">{{ report.title }}</p>
                <div class="report-main" v-html="report.content"></div>
                <div class="report-end"></div>
                <div class="btnpart button-part">
                    <el-button class="cancel" @click="readReport">我知道了</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { message,readmsg,readsystem,clearmessage,clearreport} from '@/api/common'
import { noticedetail} from '@/api/message'
import vm from '@/assets/js/closeNav'
export default {
    name: "Header",
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    data() {
        return {
            report:{
                title:'',
                content:''
            },
            total:'',
            msgList:[],
            messageDialog:false,
            msgTabList:[
                {name:'待办事项',num:0},
                {name:'系统公告',num:0},
                {name:'系统消息',num:0},
            ],
            actMsgIndex:0,
            info: {
                avatar: require('../assets/image/user.png'),
                name: this.$store.state.username,
            },
            actindex: 0,
            isClose: false,
            tabList: [
            ],
            visible: true,
            moreList: [
                // {name:'个人中心',path:'/personal'},
                // {name:'费用申请',path:'/'},
            ],
            mineList: [
                { name: '个人中心', path: '/personal' },
                { name: '退出登录', path: '/personal' },
            ],
            totalMessage:'',
            noticeList:[],
            systemList:[],
            timer:null,
            reportDialog:false,
        };
    },
    mounted() {
        this.getMsg()
        this.beginGet()
        this.getTab()
    },
    methods: {
        readReport(){
            let data = {
                id: this.report.id
            }
            noticedetail(data).then(res => {
                this.reportDialog = false
                this.getMsg()
            })
        },
        showReport(){
            if(this.reportDialog){
                return
            }
            if(this.noticeList.length>0){
                this.noticeList.forEach(item =>{
                    if(!item.is_read){
                        this.report = item
                        this.report.content = this.setHtml(this.report.content) 
                        this.reportDialog = true
                        return
                    }
                })
            }
        },
        toPersonal(){
            this.$router.push({
                path:'/terrace/personal'
            })
        },
        clearSystem(){
            if(this.actMsgIndex==2){
                clearmessage().then(res=>{
                    if(res.code == 200){
                        this.$message({
                            message:res.msg,
                            type:'success'
                        })
                        this.systemList = []
                    }else{
                        this.$message({
                            message:res.msg,
                            type:'error'
                        })
                    }
                })
            }else{
                let ids = []
                this.noticeList.forEach(item=>{
                    ids.push(item.id)
                })
                let data = {
                    id:ids.toString()
                }
                clearreport(data).then(res=>{
                    if(res.code == 200){
                        this.$message({
                            message:res.msg,
                            type:'success'
                        })
                        this.getMsg()
                    }else{
                        this.$message({
                            message:res.msg,
                            type:'error'
                        })
                    }
                })
            }
            
        },
        beginGet(){
            this.timer = setInterval(() => {
                this.getMsg()
            }, 10000);
        },
        toMore(){
            let index = this.actMsgIndex
            if(index == 0 ){
                this.$router.push({
                    name:'todolist'
                })
            }else if(index == 1){
                this.$router.push({
                    name:'notice',
                    query:{
                        type:'read'
                    }
                })
            }else{
                this.$router.push({
                    name:'message'
                })
            }
            this.messageDialog = false

        },
        toExamine(value,key,msg){
            let data = {
                type:key,
                data_id:value
            }
            readmsg(data).then(res=>{
                if(this.actMsgIndex == 0){
                    //1.合同  2.回款  3.费用申请  4.房屋回款  7.开票管理 5.未提交票据  6.财务确认票据提交 
                    if(key == 1){
                        this.$router.replace({
                            path:'/contract',
                            query:{
                                id:value,
                                type:'examine'
                            }
                        })
                    }
                    else if(key == 2){
                        this.$router.replace({
                            path:'/contractback',
                            query:{
                                id:value,
                                type:'examine'
                            }
                        })
                    }
                    else if(key == 3){
                        this.$router.replace({
                            path:'/constapply',
                            query:{
                                id:value,
                                type:'examine'
                            }
                        })
                    }
                    else if(key == 4){
                        this.$router.replace({
                            path:'/housecollection',
                            query:{
                                id:value,
                                type:'examine'
                            }
                        })
                    }
                    else if(key == 7){
                        this.$router.replace({
                            path:'/openbill',
                            query:{
                                id:value,
                                type:'examine'
                            }
                        })
                    }
                    else if(key == 5){
                        this.$router.replace({
                            path:'/constapply',
                            query:{
                                id:value,
                                type:'examinebill'
                            }
                        })
                    }
                    else if(key == 6){
                        this.$router.replace({
                            path:'/constapply',
                            query:{
                                id:value,
                                type:'examineconfirm'
                            }
                        })
                    }
                    else if(key == 10){
                        this.$router.replace({
                            path:'/openbill',
                            query:{
                                id:value,
                                type:'improvebill'
                            }
                        })
                    }
                    else if(key == 11){
                        this.$router.replace({
                            path:'/sellchance',
                            query:{
                                id:value,
                                type:'improvesell'
                            }
                        })
                    }
                    else if(key == 12){
                        this.$router.push({
                            name:'custom',
                            params:{
                                id:value,
                                type:'confirm',
                                out_name:msg.remind_other ? msg.remind_other :'1',
                                remind_id:msg.id
                            }
                        })
                    }
                }
                this.getMsg()
                this.messageDialog = false
            })
        },
        toNotice(item){
            this.$router.push({
                name:'notice',
                query:{
                    type:'detail',
                    id:item.id
                }
            })
            this.messageDialog = false
            this.getMsg()
        },
        getMsg(){
            message().then(res=>{
                this.msgList = res.data.remind.table
                this.msgTabList[0].num = res.data.remind.total
                this.noticeList = res.data.notice.notice
                this.msgTabList[1].num = res.data.notice.notice_total
                this.systemList = res.data.system.system
                this.msgTabList[2].num = res.data.system.system_total
                this.totalMessage = res.data.total_num
                this.showReport()
            })
        },
        changTab(index){
            if(index == 2){
                let data = {
                    is_read : 1
                }
                readsystem(data).then(res =>{
                    this.msgTabList[2].num = 0
                })
            }
            this.actMsgIndex = index
        },
        refsh() {
            // console.log(111111);
            this.$router.go(0);
        },
        async examine() {
            await this.getMsg()
            this.messageDialog = true
            
        },
        cleanTab() {
            this.tabList = []
        },
        toPage(item) {
            this.$router.replace(item.path)
        },
        closeTab(index) {
            this.tabList.splice(index, 1)
            if (this.actindex == index) {
                this.actindex = 0
                this.$router.replace(this.tabList[this.actindex].route)
            }
        },
        changeTab(item, index) {
            this.$router.push(item.route)
            this.actindex = index
        },
        closeNav() {
            if (this.isClose) {
                this.isClose = false
            } else {
                this.isClose = true
            }
            let type = this.isClose
            vm.$emit("closetype", type)
        },
        getTab() {
            vm.$on('closeobj', value => {
                var a = this.tabList.indexOf(value, 0)
                if (a >= 0) {
                    this.tabList.splice(a, 1)
                    this.tabList.unshift(value)
                } else {
                    this.tabList.unshift(value)
                    if(this.tabList.length>8){
                        this.tabList.splice(9,1)
                    }
                    this.actindex = 0
                }
            })
        },
        logout() {
            localStorage.removeItem('userpower')
            localStorage.removeItem('userbtn')
            localStorage.removeItem('username')
            localStorage.removeItem('userrole')
            localStorage.removeItem('route')
            localStorage.removeItem('x_token')
            localStorage.removeItem('userimage')
            localStorage.removeItem('token')
            localStorage.removeItem('tmp_token')
            let url = `${this.$BASE_DOOR_URL}`
            window.location.href = url
        },
        setHtml(str){
            return str
            .replace(str ? /&(?!#?\w+;)/g : /&/g, '&amp;')
            .replace(/&amp;nbsp;/g, "")
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&quot;/g, "\"")
            .replace(/&#39;/g, "\'")
        },
    },
    destroyed(){
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
}

</script>

<style lang="scss">
.el-popover {
    min-width: 96px !important;
}

.showbox {
    padding: 8px 0;
}
.el-drawer__open .el-drawer.rtl{
    width: 480px !important;
}
.messageBox{
    padding: 0 !important;
    // width: 480px;
    .el-badge__content{
        background-color: #F33232 !important;
    }
    .el-badge__content{
        border: none;
        font-weight: normal !important;
        height: 16px;
        line-height: 16px;
        padding: 0 4px;
        border-radius: 8px;
        min-width: 8px;
    }
    .el-badge__content.is-fixed{
        right:  0 !important;
    }
}
.iconlist{
    .el-badge__content{
        background-color: #F33232 !important;
    }
    .el-badge__content{
        border: none;
        font-weight: normal !important;
        height: 16px;
        line-height: 16px;
        padding: 0 4px;
        border-radius: 8px;
        min-width: 8px;
    }
    .el-badge__content.is-fixed{
        right:  37px !important;
    }
}
.report-dialog{
    .el-dialog__header{
        display: none;
    }
    .report-dialog-mian{
        height: 460px;
        padding:0 ;
        border-top: 4px solid #CD0000;
        border-radius: 4px 4px 0px 0px;
        box-sizing: border-box;
        padding: 36px;
        .report-title{
            width: 100%;
            text-align: center;
            font-size: 24px;
            font-weight: bold;
            color: #BD0000;
            line-height: 28px;
        }
        .report-main{
            margin-top: 24px;
            overflow-y: auto;
            height: 256px;
            padding: 0 0 30px;
            position: relative;
            text-align: left;
        }
        .report-end{
            height: 40px;
            width: 100%;
            position: relative;
            top: -40px;
            background: linear-gradient(180deg, #F8F8FB00 0%, #F8F8FB99 100%);
        }
        .button-part{
            margin-top: 4px;
        }
    }
    .el-dialog__body{
        padding: 0 !important;
    }
}
</style>
<style lang="scss" scoped>
* {
    text-align: center;
    box-sizing: border-box;
}

.box {
    width: 100%;
    height: 60px;
    background: #fff;
    padding: 0;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    .messageBox::-webkit-scrollbar {
        display: none;
    }
    .line {
        display: flex;
        align-items: center;
        padding-left: 30px;
        height: 60px;

        .icon {
            font-size: 20px;
        }

        .tab-list {
            display: flex;
            margin-left: 30px;

            .list-box {
                height: 60px;
                color: #B0B1B9;
                font-size: 14px;
                line-height: 16px;
                width: 108px;
                display: flex;
                align-items: center;
                border-top: white 4px solid;

                .box-msg {
                    display: flex;
                    justify-content: center;
                    height: 16px;
                    width: 100%;
                    border-right: 1px solid #e6e6e6;

                    .box-close {
                        width: 14px;
                        height: 14px;
                        background: #E6E6E6;
                        display: flex;
                        border-radius: 50%;
                        margin-left: 8px;

                        .img {
                            width: 6px;
                            height: 6px;
                            margin: auto;
                        }
                    }
                }

            }

            .list-box:last-child {
                .box-msg {
                    border: none;
                }
            }

            .acttab {
                color: #2C57FF !important;
                background: #F4F7FC;
                border-top: #2C57FF 4px solid;

                .box-close {
                    background: rgba(44, 87, 255, 0.2) !important;

                    .svg-icon {
                        fill: #2C57FF !important;
                    }
                }

                .box-msg {
                    border-right: 1px solid F4F7FC;
                }
            }
        }
    }

    .line2 {
        width: 300px;
        height: 60px;
        display: flex;
        flex-direction: row-reverse;

        .user {
            width: auto;
            border-left: #F0F1F5 solid 1px;
            height: 100%;
            padding: 0 0 0 30px;
            text-align: left;
            display: flex;
            align-items: center;

            .user-avatar {
                width: 36px;
                height: 36px;
                margin-right: 14px;
            }

            .user-show {
                width: 10px;
                height: 5px;
                margin-left: 6px;
                margin-right: 30px;
            }

            .button {
                background: transparent;
                border: none;
                outline: none;
                font-size: 14px;
                line-height: 16px;
                white-space:nowrap
                // width: auto;
                // display: flex;
                // flex-wrap: nowrap;
            }
        }

        .avatar-handel {
            button {
                color: #B0B1B9;
            }

            .svg-icon {
                fill: #B0B1B9;
            }
        }

        .avatar-handel:hover {
            button {
                color: #2C57FF !important;
            }

            .svg-icon {
                fill: #2C57FF !important;
            }
        }

        .iconlist {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;

            .more-box {
                height: 20px;
                display: flex;
                align-items: center;
            }

            .list-image {
                width: 20px;
                height: 18px;
                margin-right: 30px;
            }

            .msg {
                width: 18px;
                height: 20px;
            }

            // .more{
            //     width: 20px;
            //     height: 4px;
            // }
            .button {
                background: transparent;
                background-size: 100% 100%;
                border: none;
                outline: none;
            }

        }
    }
    .headtab{
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 28px 0 0px 0 ;
        .tab-box{
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-size: 14px;
            border-bottom: 1px solid #F0F1F5;
            box-sizing: content-box;
            height: 32px;
            .tab{
                color: #B0B1B9;
                height: 100%;
                width: 180px;
            }
            .tab:hover{
                color: #2C57FF;
            }
            .acttab{
                font-weight: bold !important;
                color: #2C57FF !important;
                border-bottom:2px solid #2C57FF !important;
                width: 180px;
                height: calc(100% + 2px);
            }
        }
    }
    .msg-list{
        padding: 8px 31px 8px 48px;
        text-align: left;
        height: calc(100vh - 140px);
        overflow-y:auto ;
        .msg{
            padding: 16px 0;
            border-bottom: 1px #EAECF5 dashed;
            .msg-top{
                display: flex;
                justify-content:  space-between;
                line-height: 22px;
                .msg-title{
                    font-size: 14px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    position: relative;
                    color: #6B6D77;
                    .msg-point{
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: #F33232;
                        position: absolute;
                        left:-18px;
                    }
                }
                .msg-date{
                    font-size: 12px;
                    color: #B0B1B9;
                }
            }
            .msg-main{
                text-align: left;
                margin-top: 16px;
                color: #B0B1B9;
                font-size: 14px;
                line-height: 22px;
            }
            .report{
                text-align: left;
                color: #B0B1B9;
                font-size: 14px;
                line-height: 22px;
                position: relative;
                .msg-point{
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: #F33232;
                    position: absolute;
                    left:-18px;
                    top:6px;
                }
            }
        }
        .msg:last-child{
            border: none;
        }
        .msg1{
            padding: 16px 0;
            border-bottom: 1px #EAECF5 dashed;
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 22px;
            .msg1-main{
                text-align: left;
                width: 276px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap; 
                font-size: 14px;
                color: #6B6D77;
            }
            .msg1-date{
                font-size: 12px;
                color: #B0B1B9;
            }
        }
    }
    
    .msg-bottom{
        border-top:1px solid #F0F1F5 ;
        width: 100%;
        height: 100%;
        padding: 20px 30px 0;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        .msg-more{
            font-size: 14px;
        }
        .msg-clear{
            font-size: 14px;
            display: flex;
            align-items: center;
            .img1{
                margin-right: 8px;
            }
        }
    }
    
}

.showbox {
    .showbox-list {
        width: 100%;
        height: 36px;
        color: #9CA3B6;
        line-height: 36px;
        font-size: 14px;
    }
}
.acthaer{
    transform: rotate(180deg);
}

</style>
<style lang="scss">
.report-dialog-mian{
        .report-main{
            table {
                border: 1px solid #ccc;
                
            }
            table td,
            table th {
                border: 1px solid #ccc;
                
            }
            table th {
                border-bottom: 2px solid #ccc;
            }
        }
        img{
            width: 100%;
        }
    }
</style>
