<template>
    <div class="adduser">
        <el-dialog :title="title" :visible.sync="showDialog" width="400px" class="addform"  @close="cancel">
            <div class="addform">
                <el-form :model="partform" :rules="rules" ref="partform">
                    <el-form-item label="部门代码" prop="code">
                        <span slot="label"><span class="check-form-notice">*</span>部门代码</span>
                        <el-input v-model="partform.code" autocomplete="off"  placeholder="请输入部门代码"></el-input>
                        <el-input v-model="partform.sort" type="text" class="index-select">
                            <span slot="prefix" class="unit-left">第</span>
                            <span slot="suffix" class="unit-right">个</span>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="负责人" prop="admin">
                        <el-select v-model="partform.admin" placeholder="请选择" filterable clearable>
                            <el-option v-for="item in adminList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="部门名称" prop="name">
                        <span slot="label"><span class="check-form-notice">*</span>部门名称</span>
                        <el-input v-model="partform.name" autocomplete="off" placeholder="请输入部门名称" ></el-input>
                    </el-form-item>
                    <el-form-item label="上级部门" v-if="showFather" >
                        <el-cascader v-if="type==1" v-model="partform.p_id" disabled :options="departlist" :show-all-levels="false" :props="props1" placeholder="请选择"></el-cascader>
                        <el-cascader v-if="type==2" v-model="partform.p_id" disabled :options="departlist" :show-all-levels="false" :props="props1" placeholder="请选择" ></el-cascader>
                    </el-form-item>
                    <el-form-item label="描述" >
                        <el-input v-model="partform.intro" autocomplete="off" type="textarea" rows="5" class="describe" placeholder="请输入部门介绍"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            
            <div slot="footer" class="dialog-footer btnpart btnpart">
                <el-button v-if="type==1" class="confirm " type="primary" @click="partOk('partform')" plain>提 交</el-button>
                <el-button v-else class="confirm" type="primary" @click="editPart('partform')" plain>保 存</el-button>
                <el-button class="cancel" @click="cancel" plain>取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {partdetail,alluser} from '@/api/terrace'
export default {
    name: "adduser",
    props: {
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        departlist:{
        	type:Array,
        	default:()=>{
        		return []
        	}
        },
        rolelist:{
        	type:Array,
        	default:()=>{
        		return []
        	}
        },
        type:{
        	type:Number,
        	default:()=>{
        		return 0
        	}
        },
        form:{
        	type:Object,
        	default:()=>{
        		return {}
        	}
        },
    },
    data() {
        return {
            props1:{
                multiple: false,
                value:'id',
                checkStrictly: true
            },
            partform:{
                sort:1,
                name:'',
                code:'',
                p_id:'',
                intro:'',
                admin:''
            },
            adminList:[],
            showDialog:false,
            title:'新建部门',
            rules: {
                // code: [
                //     { required: true, message: '请输入部门代码', trigger: 'blur' },
                // ],
                // name: [
                //     { required: true, message: '请输入部门名称', trigger: 'blur' },
                // ],
                // admin: [
                //     { required: true, message: '请选择部门负责人', trigger: 'blur' },
                // ],
            },
            showFather:false
        };
    },
    mounted(){
        this.showDialog = this.show
        this.getAdminList()
        this.partform = this.form
        if(this.type==1){
            this.title = '新建部门'
        }else{
            this.title = '编辑部门'
        }
        this.$set(this.partform,'sort',this.partform.sort?this.partform.sort:1)
        this.partform.p_id = this.partform.id?this.partform.id:0
        if(this.type==1){
            this.showFather = this.partform.id?true:false
        }else{
            this.showFather = true
            this.getDetail()
        }
    },
    methods: {
        getAdminList(){
            alluser().then(res =>{
                this.adminList = res.data
            })
        },
        cancel(){
            this.$emit('close',false)
            // this.clean()
        },
        partOk(partform){
            this.$refs[partform].validate((valid) => {
                if (valid) {
                    let data = {
                        code:this.partform.code,
                        p_id:this.partform.p_id,
                        sort:this.partform.sort,
                        name:this.partform.name,
                        intro:this.partform.intro,
                        admin:this.partform.admin,
                    }
                    this.$emit('change',data)
                    // this.clean()
                } else {
                    return false;
                }
            });
        },
        editPart(partform){
            this.$refs[partform].validate((valid) => {
                if (valid) {
                    let data = {
                        id:this.partform.id,
                        code:this.partform.code,
                        p_id:this.partform.p_id,
                        sort:this.partform.sort,
                        name:this.partform.name,
                        intro:this.partform.intro,
                        admin:this.partform.admin,
                    }
                    this.$emit('edit',data)
                    // this.clean()
                } else {
                    return false;
                }
            });
            
        },
        getDetail(){
            let data = {
                id:this.partform.id
            }
            partdetail(data).then(res=>{
                let item = this.partform
                this.$set(item,'code',res.data.code)
                this.$set(item,'intro',res.data.intro)
                this.$set(item,'p_id',res.data.p_id)
                this.$set(item,'sort',res.data.sort)
                this.$set(item,'name',res.data.name)
                this.$set(item,'id',res.data.id)
            })
        },
        clean(){
            this.partform = {
                sort:1,
                name:'',
                code:'',
                p_id:'',
                intro:''
            }
        },
    }
}
</script>

<style lang="scss">
.adduser {
    .index-select{
        width: 50%;
        margin-left: 8px;
        .el-input__inner {
            height: 32px !important;
            padding: 0 0 0 40px !important;
        }
        .el-input__suffix{
            // right: 20px !important;
        } 
        .describe textarea{
            height: 120px !important;
        }
        
    }
    .unit-left{
        margin-left: 10px;
    }
    .unit-right{
        margin-right: 10px;
    }
    .el-textarea{
        margin-top: 5px !important;
    }
}
</style>
