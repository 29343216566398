<template>
    <div class="custom">
        <div class="border" v-if="!isDetail">
            <div class="top-search" >
                <el-form :model="form" class="demo-form-inline" label-width="">
                    <div class="select-line bostrap contract" :style="{ height: (hide == false ? '43px' : 'auto') }"
                        id="searchLine">
                        <el-form-item label="回款单号" class="">
                            <el-input v-model="form.number" placeholder="请输入">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="客户名称">
                            <el-select v-model="form.cl_id" placeholder="请选择" clearable filterable collapse-tags  popper-class="custom-select">
                                <el-option v-for="item in clientList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="所属部门">
                            <el-cascader class="client_id longselect" placeholder="请选择" :options="branchList" collapse-tags
                                v-model="form.branch_id" :props="{ value: 'id', checkStrictly: true, label: 'name' }" filterable
                                clearable>
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="创建人">
                            <el-select v-model="form.create_id" placeholder="请选择" clearable multiple collapse-tags filterable>
                                <el-option v-for="item in createList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="业务类型">
                            <el-select v-model="form.business_type" placeholder="请选择" clearable @change="getBusDetail" filterable>
                                <el-option v-for="item in bustypeList" :label="item.name" :value="item.id" 
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="业务明细">
                            <el-select v-model="form.business_detail"
                                :placeholder="form.business_type == '' ? '请先选择业务明细' : '请选择'" clearable
                                :disabled="form.business_type == ''" filterable>
                                <el-option v-for="item in busdetailList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="回款金额" class="between">
                            <div class="rangeinput">
                                <el-input class="left" v-model="form.start_amount" placeholder="请输入金额"></el-input>
                                至
                                <el-input class="right" v-model="form.end_amount" placeholder="请输入金额"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="到账账户">
                            <el-select v-model="form.get_account" placeholder="请选择" clearable multiple collapse-tags filterable>
                                <el-option v-for="item in accountList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="实际到账时间" class="time">
                            <el-date-picker v-model="form.real_time" value-format="yyyy-MM-dd" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" type="daterange"
                                placeholder="请选择"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="合同管理">
                            <el-select v-model="form.ca_id" placeholder="请选择" clearable collapse-tags filterable>
                                <el-option v-for="item in contractList" :label="item.number" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="回款审核状态">
                            <el-select v-model="form.type" placeholder="请选择" clearable multiple collapse-tags>
                                <el-option v-for="item in typeList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="回款摘要" class="">
                            <el-input v-model="form.summary" placeholder="请输入">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="销售机会">
                            <el-select v-model="form.s_id" placeholder="请选择" clearable multiple collapse-tags filterable>
                                <el-option v-for="item in sellList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="回款方式">
                            <el-select v-model="form.pay_back" placeholder="请选择" clearable collapse-tags>
                                <el-option v-for="item in backtypeList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="回款日期" class="time">
                            <el-date-picker v-model="form.back_time" value-format="yyyy-MM-dd" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" type="daterange"
                                placeholder="请选择"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="开始时间" class="time">
                            <el-date-picker v-model="form.begin_time" value-format="yyyy-MM-dd" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" type="daterange"
                                placeholder="请选择"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="结束时间" class="time">
                            <el-date-picker v-model="form.end_time" value-format="yyyy-MM-dd" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" type="daterange"
                                placeholder="请选择"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="创建时间" class="time">
                            <el-date-picker v-model="form.create_time" value-format="yyyy-MM-dd" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" type="daterange"
                                placeholder="请选择"></el-date-picker>
                        </el-form-item>
                    </div>
                    <div class="line"></div>
                    <div class="btn-line">
                        <div class="btnpart">
                            <el-button class="confirm" @click="getList('search')" plain v-permission="'回款记录-select'">查询</el-button>
                            <el-button class="cancel" @click="clearSeach" plain v-permission="'回款记录-reset'">重置</el-button>
                        </div>
                        <div class="showpart hover" @click="showHide">
                            {{ actText }}
                            <local-svg iconClass="showdown" class="icon" :class="hide?'acticon':''"></local-svg>
                        </div>
                    </div>

                </el-form>
            </div>
            <div class="tablepart">
                <div class="top">
                    <div class="span">回款记录</div>
                    <div class="btnlist btnpart">
                        <el-button class="iconbtn" @click="deleteMoreUser" plain v-permission="'回款记录-batch_del'"><local-svg iconClass="delete"
                                class="icon"></local-svg>批量删除</el-button>
                        <el-button class="iconbtn" @click="showDialog = true" plain v-permission="'回款记录-list'"><local-svg iconClass="set"  
                                class="icon"></local-svg>设置列表展示列</el-button>
                        <el-button class="iconbtn" @click="exprotFile" plain v-permission="'回款记录-export'"><local-svg iconClass="expor"
                                class="icon"></local-svg>导出</el-button>
                        <el-button class="iconbtn" @click="beginAdd" plain v-permission="'回款记录-create'"><local-svg iconClass="add"
                                class="icon"></local-svg>新建</el-button>
                    </div>
                </div>
                <customTable v-if="headerList.length > 0" ref="dadtable" :list="tableData" :head="showHeader" @transfer="wantAdd"
                    @tableedit="wantEdit" @stopuser="setUserType" @deluser="deleteUser" @detail="seeDetail" @select="selectRow" :loading="load" @seebus="seeBusiness">
                </customTable>
                <div v-else style="height:560px"></div>
                <div class="pagetab">
                    <div class="totalmoney">回款总金额合计<span>{{ totalMoney }}</span>元</div>
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <setshowDialog v-if="showDialog" :list="headerList" :show="showDialog" @show="setShow" @cancel="closeShow">
        </setshowDialog>
        <exportDialog v-if="exportDialog" :list="headerList" :show="exportDialog" @export="exportTable"
            @cancel="cancelExport"></exportDialog>
        <changeDialog v-if="changeDialog" :form="detailData" :show="changeDialog" @cancel="cancelChange" @add="addUser"
            :type="addtype" @edit="deitUser" :client="clientList" :backtype="backtypeList" :branch="branchList">
        </changeDialog>
        <detailPart v-if="isDetail" @back="closeDetail" :info="detailData" @add="addFollow" :follow="followList" :isDetail="isExamine" @print="printIt"  @reload="seeDetail" :showList1="backtypeList" :showList2="accountList">
        </detailPart>
        <busDetail v-if="busDetailDialog" :show="busDetailDialog" @cancel="closebusDetail" :form="busDetailData">
        </busDetail>
        <addSelect v-if="followDialog" :show="followDialog" :list="headerList" @check="checkUser" @closedialog="closeFollow">
        </addSelect>
        <el-dialog title="预览文件" :visible.sync="fileDialog" class="filedialog" width="988px">

            <div class="filebox">
                <iframe src='http://test.tjlhyg.com/storage/image/16a8c947f3f5fdc640267a9984515462.jpg' frameborder="0"
                    style="width:100% ; height: 700px"></iframe>
            </div>
        </el-dialog>
        <div class="printpart" v-show="isPrint">
            <section id="printArea" style="margin-top: 60px;" v-if="printElem">
                <p class="printArea-title">
                    <img height="50" src="@/assets/image/paybacktext.png" alt="">
                    <img src="@/assets/image/printLogo.png" class="printArea-title-img" />
                </p>
                <div style="display:flex;justify-content:space-around;padding-bottom:5px; margin-top: 20px;">
                    <p style="color: #000 !important;">回款日期： {{ inputs.back_time ? this.$date1(inputs.back_time).split(' ')[0] : '' }}</p>
                    <p style="color: #000 !important;">实际到账时间： {{ inputs.real_time }}</p>
                    <p style="color: #000 !important;">回款审核状态： {{ inputs.type_name==42? '未提交':inputs.type_name==43? '审核中':inputs.type_name==44? '审核同意':inputs.type_name==45? '审核拒绝': '财务已确认'}}</p>
                </div>
                <table class="printTable">
                    <tbody>
                        <tr>
                            <td>回款单号</td>
                            <td>{{ inputs.number }}</td>
                            <td>所属部门</td>
                            <td>{{ inputs.branch }}</td>
                            <td>合同单号</td>
                            <td>{{ inputs.contract   ? "HT#0000" + inputs.contract  : '/'  }}</td>
                        </tr>
                        <tr>
                            <td>单项业务名称</td>
                            <td>{{ inputs.single_name }}</td>
                            <td>业务明细</td>
                            <td>{{ inputs.business_detail }}</td>
                            <td>销售机会</td>
                            <td>{{ inputs.sell }}</td>
                        </tr>
                        <tr>
                            <td>业务类型</td>
                            <td>{{ inputs.business_type }}</td>
                            <td>开始日期</td>
                            <td>{{ inputs.start_time }}</td>
                            <td>结束日期</td>
                            <td>{{ inputs.end_time }}</td>
                        </tr>
                        <tr>
                            <td>客户</td>
                            <td colspan="5">{{ inputs.client }}</td>
                        </tr>
                        <tr>
                            <td>回款方式</td>
                            <td>{{ getReturn(backtypeList,inputs.back_type) }}</td>
                            <td>到款账户</td>
                            <td>{{  getReturn(accountList,inputs.get_account)}}</td>
                            <td>回款金额</td>
                            <td>{{ inputs.amount }}元</td>
                        </tr>
                        <tr>
                            <td>回款摘要</td>
                            <td colspan="5">{{ inputs.summary }}</td>
                        </tr>
                        <tr>
                            <td>备注</td>
                            <td colspan="5">{{ inputs.remark == "undefined" ? "" : inputs.remark }}</td>
                        </tr>
                    </tbody>
                </table>
                <div style="display:flex;justify-content:space-around;padding-top:10px;">
                    <!-- <p>财务：  {{inputs.finance}}</p> -->
                    <p style="color: #000 !important;">财务： 耿玉兰</p>
                    <p style="color: #000 !important;">制单人：{{ inputs.create_name }}</p>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import {saveheader} from '@/api/common'
import { headerlist, selectlist, addback, tablelist, delback, backdetail, editback,businessdetail } from '@/api/contanctback'
import { scrm_wechat } from '@/api/scrm_custom'
import customTable from "@/components/custom/table.vue"
import setshowDialog from "@/components/custom/show.vue"
import exportDialog from "@/components/custom/export.vue"
import changeDialog from "@/components/contractback/change.vue"
import detailPart from "@/components/contractback/detail.vue"
import addSelect from "@/components/contractback/follow.vue"
import busDetail from "@/components/contractback/busdetail"
export default {
    name: 'custom',
    data() {
        return {
            busDetailDialog:false,
            fileDialog: false,
            followDialog: false,
            showDialog: false,  //显示列表弹窗
            hide: false,
            exportDialog: false,
            changeDialog: false,
            isDetail: false,
            isExamine:false,
            load: false,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            actText: '展开',
            userList: [],
            createList: [],   //创建人列表
            clientList: [],    //客户列表
            branchList: [],    //部门列表
            bustypeList: [],   //业务类型列表
            busdetailList: [], //业务明细列表
            accountList: [],   //到账账户列表
            contractList: [],  //合同管理列表
            typeList: [],      //回款状态
            sellList: [],      //销售机会
            backtypeList: [],   //回款方式
            partList: [],
            form: {
                number: '',
                cl_id: '',
                branch_id: [],
                create_id: '',
                business_type: '',
                business_detail: '',
                get_account: '',
                type: '',
                summary: '',
                s_id: '',
                end_amount: '',
                start_amount: '',
                pay_back: '',
                real_time: [],
                begin_time: [],
                end_time: [],
                create_time: [],
                back_time: []
            },
            tableData: [],
            showHeader: [],
            headerList: [],
            selectionList: [],
            tradeList: [],
            addtype: 1,
            detailData: {},
            followList: [],
            load:true,
            totalMoney:0,
            printElem:false,
            inputs:{},
            isPrint:false,
            busDetailData:{}
        }
    },
    components: {
        customTable,
        setshowDialog,
        exportDialog,
        changeDialog,
        detailPart,
        addSelect,
        busDetail
    },
    mounted() {
        if(this.$route.query.id){
            if(this.$route.query.type && this.$route.query.type=='examine'){
                let data = {
                    id:this.$route.query.id
                }
                this.seeDetail(data)
                this.isExamine = true
            }
            if(this.$route.query.type && this.$route.query.type=='detail'){
                let data = {
                    id:this.$route.query.id
                }
                this.seeDetail(data)
            }
            if(this.$route.query.type && this.$route.query.type=='generate'){
                this.detailData.id = this.$route.query.id
                this.detailData.sell = this.$route.query.sell
                this.detailData.s_id = this.$route.query.sell_id
                this.detailData.contract = this.$route.query.number
                this.detailData.ca_id = this.$route.query.contract
                this.detailData.channel = this.$route.query.channel
                this.detailData.source = this.$route.query.source
                this.addtype = 1
                this.changeDialog = true
            }
        }
        if(this.$route.params.id){
            if(this.$route.params.type && this.$route.params.type=='examine'){
                let data = {
                    id:this.$route.params.id
                }
                this.seeDetail(data)
                this.isExamine = true
            }
            if(this.$route.params.type && this.$route.params.type=='detail'){
                let data = {
                    id:this.$route.params.id
                }
                this.seeDetail(data)
            }
            if(this.$route.params.type && this.$route.params.type=='generate'){
                this.detailData.id = this.$route.params.id
                this.detailData.sell = this.$route.params.sell
                this.detailData.s_id = this.$route.params.sell_id
                this.detailData.contract = this.$route.params.number
                this.detailData.ca_id = this.$route.params.contract
                this.detailData.channel = this.$route.params.channel
                this.detailData.source = this.$route.params.source
                this.addtype = 1
                this.changeDialog = true
            }
        }
        this.getSelect()
        this.getList()
        this.getHeaderList()
        this.beginAdd = this.$debounce(this.beginAdd )
        this.exprotFile = this.$debounce(this.exprotFile )
    },
    methods: {
        closebusDetail(){
            this.busDetailDialog = false
        },
        seeBusiness(msg){
            let data = {
                c_s_id:msg
            }
            businessdetail(data).then(res=>{
                this.busDetailData = res.data
                this.busDetailDialog = true
            })
        },
        printIt(msg){
            this.printElem = true;
            this.inputs = msg
            this.inputs.single_name = this.inputs.single[0].name
            var that = this;
            setTimeout(() => {
                printJS({
                    style: ['@page { margin: 0cm 1.7cm 0cm 1.8cm;  margin-top: 1mm; margin-bottom: 1mm;}  body{margin-top: 60px;}'],
                    // style: [' @media print{@page {size:landscape}} @page { margin: 10px; } html { padding: 0 40px 0 40px; }  body{margin-top: 40px;}'], // @page { margin: 0px; } body {margin-top: 50px;}
                    printable: "printArea",
                    type: "html",
                    targetStyles: ['*'],
                    onLoadingEnd: function () {
                        that.printElem = false;
                    },
                    ignoreElements: ["ignore"],
                });
            }, 200);
        },
        closeFollow(){
            this.followDialog = false
        },
        checkUser(msg) {
            console.log(msg,111)
            this.followList.push(msg)
            this.followDialog = false
        },
        addFollow() {
            this.followDialog = true
        },
        getSelect() {
            let data = {
                id: ''
            }
            selectlist(data).then(res => {
                this.createList = res.data.admin
                this.clientList = res.data.client
                this.branchList = res.data.branch_id
                this.bustypeList = res.data.business_type
                this.accountList = res.data.get_account
                this.contractList = res.data.contract
                this.typeList = res.data.type
                this.sellList = res.data.sell
                this.backtypeList = res.data.back_type
            })
        },
        getBusDetail() {
            let data = {
                id: this.form.business_type
            }
            selectlist(data).then(res => {
                this.busdetailList = res.data.business_detail
            })
        },
        selectRow(row) {
            this.selectionList = row
            console.log(this.selectionList, 111)
        },
        deleteMoreUser() {
            if (this.selectionList.length == 0) {
                this.$message({
                    message: '请先选择要删除的客户！',
                    type: 'error'
                })
                return
            }

            this.$confirm('此操作将永久删除所选用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let array = []
                this.selectionList.forEach(item => {
                    array.push(item.id)
                })
                let data = {
                    b_id: array
                }
                delback(data).then(res => {
                    if (res.code == 200) {
                        this.getList()
                        this.selectionList = []
                        this.changeDialog = false
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        deitUser(msg) {
            this.$loading({ text: '加载中...' });
            let data = {
                id: msg.id,
                cl_id: msg.cl_id,
                ca_id: msg.ca_id,
                c_s_id: msg.c_s_id,
                s_id: msg.s_id,
                amount:  Number(msg.amount.replace(/,/g, "")),
                amount_yuan:msg.amount_yuan,
                back_time: msg.back_time,
                end_time: msg.end_time,
                start_time: msg.start_time,
                remark: msg.remark,
                summary: msg.summary,
                audit_status: msg.audit_status,
                back_type: msg.back_type,
                branch_id: msg.branch_id,
                files: msg.files,
                real_time:msg.type == 46?msg.real_time:'',
                get_account_id:msg.type == 46?msg.get_account_id:'',

            }
            editback(data).then(res => {
                if (res.code == 200) {
                    this.getList()
                    this.changeDialog = false
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        exprotFile() {
            this.exportDialog = true
        },
        seeDetail(msg) {
            let data = {
                id: msg.id
            }
            backdetail(data).then(res => {
                this.detailData = res.data
                let obj = JSON.parse(JSON.stringify(this.detailData.contract))
                this.$set(this.detailData,'contract',obj.id)
                this.detailData.channel = obj.sell_info && obj.sell_info.channel ? obj.sell_info.channel : '暂无'
                this.detailData.source = obj.sell_info && obj.sell_info.source ? obj.sell_info.source : '暂无'
                let num = Number(this.detailData.amount.replace(/,/g, "")) -  Number(this.detailData.fee_apply_amount)
                this.detailData.differ_amount = num.toFixed(2)
                this.detailData.team_type = this.detailData.contract && this.detailData.contract.team_type == 3 ? '新签' : '续约'
                this.detailData.bill_num = this.detailData.releate_invoice && this.detailData.releate_invoice.number? this.detailData.releate_invoice.number : ''
                this.detailData.bill_id = this.detailData.releate_invoice && this.detailData.releate_invoice.id? this.detailData.releate_invoice.id : ''
                this.isDetail = true
            })
        },
        beginAdd() {
            this.addtype = 1
            this.detailData = {}
            this.changeDialog = true
        },
        addUser(data) {
            this.$loading({ text: '加载中...' });
            data.branch_id = data.branch_id
            data.amount = Number(data.amount.replace(/,/g, ""))
            addback(data).then(res => {
                if (res.code == 200) {
                    this.getList()
                    this.changeDialog = false
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        getHeaderList() {
            let data = {
                id: 4
            }
            headerlist(data).then(res => {
                this.showHeader = []
                this.headerList = res.data
                this.headerList.forEach(item => {
                    this.$set(item, 'check', item.is_show==1?true:false)
                    if(item.is_show==1){
                        this.showHeader.push(item)
                    }
                })
                this.$nextTick(() => {
                    this.$refs.dadtable.$refs.showTable.doLayout()
                })
            })
        },
        closeDetail() {
            this.getList()
            this.isDetail = false
        },
        cancelChange() {
            this.changeDialog = false
            this.detailData = {}
        },
        exportTable(msg) {
            let branch = []
            let idlist = []
            msg.forEach(item => {
                idlist.push(item.id)
            })
            let data = {
                number: this.form.number,
                cl_id: this.form.cl_id,
                branch_id: this.form.branch_id[this.form.branch_id.length-1]?this.form.branch_id[this.form.branch_id.length-1]:'',
                create_id: this.form.create_id,
                business_type: this.form.business_type,
                business_detail: this.form.business_detail,
                get_account: this.form.get_account,
                type: this.form.type,
                summary: this.form.summary,
                s_id: this.form.s_id,
                end_amount: this.form.end_amount,
                start_amount: this.form.start_amount,
                back_type: this.form.pay_back,
                ca_id: this.form.ca_id,
                s_start_time: this.form.begin_time.length > 0 ? this.form.begin_time[0] : '',
                s_end_time: this.form.begin_time.length > 0 ? this.form.begin_time[1] : '',
                e_start_time: this.form.end_time.length > 0 ? this.form.end_time[0] : '',
                e_end_time: this.form.end_time.length > 0 ? this.form.end_time[1] : '',
                s_real_time: this.form.real_time.length > 0 ? this.form.real_time[0] : '',
                e_real_time: this.form.real_time.length > 0 ? this.form.real_time[1] : '',
                s_create_time: this.form.create_time.length > 0 ? this.form.create_time[0] : '',
                e_create_time: this.form.create_time.length > 0 ? this.form.create_time[1] : '',
                s_back_time: this.form.back_time.length > 0 ? this.form.back_time[0] : '',
                e_back_time: this.form.back_time.length > 0 ? this.form.back_time[1] : '',
            }
            window.open(`${this.$BASE_URL}payback/export?payback=${idlist.toString()}&number=${data.number}&cl_id=${data.cl_id}&branch_id=${data.branch_id}
            &create_id=${data.create_id}&business_type=${data.business_type}&business_detail=${data.business_detail}&get_account=${data.get_account}&type=${data.type}
            &summary=${data.summary}&s_id=${data.s_id}&end_amount=${data.end_amount}&start_amount=${data.start_amount}&back_type=${data.back_type}
            &s_start_time=${data.s_start_time}&s_end_time=${data.s_end_time}&e_start_time=${data.e_start_time}&e_end_time=${data.e_end_time}&s_real_time=${data.s_real_time}
            &e_real_time=${data.e_real_time}&s_create_time=${data.s_create_time}&e_create_time=${data.e_create_time}&s_back_time=${data.s_back_time}&e_back_time=${data.e_back_time}&token=${localStorage.getItem('token')}`, '_blank');
            this.exportDialog = false
        },
        cancelExport() {
            this.exportDialog = false
        },
        // 设置展示列保存&取消
        setShow(msg) {
            let ids = []
            msg.forEach(item =>{
                ids.unshift(item.id)
            })
            let data = {
                id:ids.toString(),
                type:4
            }
            saveheader(data).then(res =>{
                if(res.code == 200){
                    this.$message.success(res.msg)
                    this.showHeader = msg
                    this.showDialog = false
                    this.getHeaderList()
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        closeShow(msg) {
            this.showDialog = false
        },
        bindUser() { },
        clearSeach() {
            this.form = {
                number: '',
                cl_id: '',
                branch_id: [],
                create_id: '',
                business_type: '',
                business_detail: '',
                get_account: '',
                type: '',
                summary: '',
                s_id: '',
                ca_id :'',
                end_amount: '',
                start_amount: '',
                pay_back: '',
                real_time: [],
                begin_time: [],
                end_time: [],
                create_time: [],
                back_time: []
            }
            this.getList('search')
        },
        deleteUser(msg) {
            let data = {
                b_id: [msg]
            }
            delback(data).then(res => {
                if (res.code == 200) {
                    this.getList()
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
            })
        },
        wantAdd() { },
        // 获取用户列表
        getList(e) {
            if(e){
                this.currentPage = 1
            }
            this.load = true
            // let branch = []
            // this.form.branch_id.forEach(item => {
            //     branch.push(item[0])
            // })
            console.log(this.form.branch_id)
            let data = {
                page: this.currentPage,
                count: this.pageSize,
                number: this.form.number,
                cl_id: this.form.cl_id,
                branch_id: this.form.branch_id[this.form.branch_id.length-1]?this.form.branch_id[this.form.branch_id.length-1]:'',
                create_id: this.form.create_id,
                business_type: this.form.business_type,
                business_detail: this.form.business_detail,
                get_account: this.form.get_account,
                type: this.form.type,
                summary: this.form.summary,
                s_id: this.form.s_id,
                ca_id:this.form.ca_id,
                end_amount: this.form.end_amount,
                start_amount: this.form.start_amount,
                back_type: this.form.pay_back,
                s_start_time: this.form.begin_time ? this.form.begin_time[0] : '',
                s_end_time: this.form.begin_time ? this.form.begin_time[1] : '',
                e_start_time: this.form.end_time ? this.form.end_time[0] : '',
                e_end_time: this.form.end_time ? this.form.end_time[1] : '',
                s_real_time: this.form.real_time ? this.form.real_time[0] : '',
                e_real_time: this.form.real_time ? this.form.real_time[1] : '',
                s_create_time: this.form.create_time ? this.form.create_time[0] : '',
                e_create_time: this.form.create_time ? this.form.create_time[1] : '',
                s_back_time: this.form.back_time ? this.form.back_time[0] : '',
                e_back_time: this.form.back_time ? this.form.back_time[1] : '',
            }
            tablelist(data).then(res => {
                this.tableData = res.data.table
                this.tableData.forEach(item =>{
                    item.channel = item.contract.sell_info && item.contract.sell_info.channel ? item.contract.sell_info.channel : '/'
                    item.source = item.contract.sell_info && item.contract.sell_info.source ? item.contract.sell_info.source : '/'
                })
                this.tableData.forEach(item =>{
                    this.checkWechat(item)
                    let num = Number(item.amount.replace(/,/g, "")) -  Number(item.fee_apply_amount)
                    item.differ_amount = num.toFixed(2)
                    item.team_type = item.contract && item.contract.team_type == 3 ? '新签' : '续约'
                    item.bill_num = item.releate_invoice && item.releate_invoice.number? item.releate_invoice.number : ''
                    item.bill_id = item.releate_invoice && item.releate_invoice.id? item.releate_invoice.id : ''
                    this.$set(item,'ca_id',item.contract && item.contract.id ?'HT#0000'+item.contract.id : '' )
                    this.$set(item,'new_ca_id',item.contract && item.contract.id ?item.contract.id : '' )
                })
                this.totalMoney = res.data.total_amount
                this.total = res.data.total
                console.log(this.tableData)
                setTimeout(() => {
                    this.load = false
                }, 1000);
            })
        },
        checkWechat(item){
            let data = {
                client_id:item.new_client_id,
                token:localStorage.getItem('token')
            }
            scrm_wechat(data).then(res =>{
                this.$set(item,'wechat',res.data.bind_type == 1 ? '是' : '否')
                // item.wechat = res.data.bind_type == 1 ? '是' : '否'
            })
        },
        wantEdit(msg) {
            let data = {
                id: msg.info.id
            }
            backdetail(data).then(res => {
                this.addtype = 2
                this.detailData = res.data
                let obj = JSON.parse(JSON.stringify(this.detailData.contract))
                this.$set(this.detailData,'contract',obj.id_num)
                this.detailData.channel = obj.sell_info && obj.sell_info.channel ? obj.sell_info.channel : '暂无'
                this.detailData.source = obj.sell_info && obj.sell_info.source ? obj.sell_info.source : '暂无'
                this.detailData.branch_name = res.data.branch
                this.changeDialog = true
            })
        },
        setUserType() { },
        showHide() {
            this.actText = this.hide ? '展开' : '收起'
            this.hide = !this.hide
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.currentPage = 1
            this.getList()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getList()
        },
        getReturn(array,key){
            let value = ''
            array.forEach(item =>{
                if(item.id == key){
                    value = item.name
                }
            })
            return value
        }
    }
}

</script>

<style src="@/assets/scss/formstyle.scss" lang="scss" ></style>
<style lang="scss" scoped>
* {
    box-sizing: content-box;
}

.custom {
    .border{
        padding: 24px;
    }

    .top-search {
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;

        .select-line {
            display: flex;
            padding: 0 20px 0 0;
        }

        .line {
            width: 100%;
            height: 1px;
            background: #F0F1F5;
        }

        .btn-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 11px 20px;

            .showpart {
                height: 100%;
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #475B93;
                .icon {
                    margin-left: 6px;
                    width: 7px;
                    height: 16px;
                }
                .acticon{
                    transform: rotate(180deg);
                }
            }
            .showpart:hover{
                color: #2C57FF !important;
            }
            .act {
                background: #2C57FF;
                color: white;
            }

            .btnpart button {
                height: 32px;
                width: 68px;
                line-height: 32px;
                padding: 0;

            }
        }
    }

    .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
            font-size: 14px;
            font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
            font-weight: bold;
            color: #474952;
            line-height: 16px;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .icon {
            width: 16px;
            height: 16px;
        }

        i {
            margin-right: 5px;
        }
    }

    .tablepart {
        min-height: 658px;
        background: white;
        margin-top: 10px;
        border-radius: 4px;
        padding: 16px 20px;

        .icon {
            margin-right: 8px;
        }

        .pagetab {
            // text-align: right;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            .totalmoney{
                font-size: 14px;
                color: #B0B1B9;
                span{
                    margin: 0 8px;
                    color: #2C57FF;
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }

        .span {
            font-size: 14px;
            font-weight: bold;
            color: #474952;
        }

        .top {
            display: flex;
            align-items: center;
        }
    }
    .printpart{
        #printArea {
            height: 200px;
            background-color: #fff;
        }

        .printArea-title {
            font-size: 33px;
            font-weight: bold;
            position: relative;
            line-height: 60px;
            height: 60px;
            text-align: center;
        }

        .printArea-title-img {
            height: 45px;
            float: left;
            position: absolute;
            left: 0vw;
            top: 7px;
        }

        .printTable {
            width: 900px;
            border-collapse: collapse;
            text-align: center;
            table-layout: fixed;
            /* margin: 0 100px; */

        }

        .printTable tbody tr td {
            border: 1px solid black;
            font-size: 24px;
            height: 50px;
            word-break: break-all;
            word-wrap: break-word;
            overflow: hidden;
            color: #000 !important;
        }
    }
}
</style>