import request from '../utils/request'

//获取下拉列表
export function select(data){
    return request({
        url:'/drawer/create',
        method:'get',
        params:data
    })
}

//获取开票人列表
export function openbill(data){
    return request({
        url:'/drawer/index',
        method:'get',
        params:data
    })
}


//创建开票人
export function adddrawer(data){
    return request({
        url:'/drawer/create',
        method:'post',
        data
    })
}

//创建开票人
export function drawerdetail(data){
    return request({
        url:'/drawer/detail',
        method:'get',
        params:data
    })
}

//编辑开票人
export function deitdrawer(data){
    return request({
        url:'/drawer/update',
        method:'post',
        data
    })
}

//删除开票人
export function deldrawer(data){
    return request({
        url:'/drawer/delete',
        method:'delete',
        data
    })
}

//获取开票人列表
export function openuser(data){
    return request({
        url:'/drawer/option',
        method:'get',
        params:data
    })
}
