import request from '../utils/request'

//系统下拉列表
export function systemselect(data){
    return request({
        url:'/common/allAdmin',
        method:'get',
        params:data
    })
}

//系统日志列表
export function systemlist(data){
    return request({
        url:'/common/list',
        method:'get',
        params:data
    })
}

//参数列表
export function paramslist(data){
    return request({
        url:'/back/optionTable',
        method:'get',
        params:data
    })
}

//编辑参数
export function editparams(data){
    return request({
        url:'/back/saveOption',
        method:'post',
        data
    })
}

//删除参数
export function delparams(data){
    return request({
        url:'/back/saveOption',
        method:'delete',
        data
    })
}

//获取全部业务类型
export function alltype(data){
    return request({
        url:'/Back/typeDown',
        method:'get',
        params:data
    })
}
