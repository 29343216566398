<template>
    <div class="table-part">
        <div class="table">
            <el-table :data="list" height="560px" style="width: 100%"
                :header-cell-style="{ 'background': '#F4F7FC', 'color': '#B0B1B9', 'fontWeight': 'bold' }"
                tooltip-effect="dark" :row-key="getRowKeys">
                <template slot="empty">
                    <el-empty :image-size="160" :image="image" description='暂无数据'></el-empty>
                </template>
                <el-table-column type="selection" width="50"></el-table-column>
                <el-table-column width="150" label="创建人" align="left" prop="create_id"></el-table-column>
                <el-table-column width="" label="支出公司" align="center" prop="company"></el-table-column>
                <el-table-column width="" label="所属部门" align="center" prop="branch_id"></el-table-column>
                <el-table-column width="" label="开票人" align="center" prop="payer_id"></el-table-column>
                <el-table-column width="" label="创建时间" align="center" prop="create_time"></el-table-column>
                <el-table-column label="操作" align="center" width="150">
                    <template slot-scope="scope">
                        <div class="chick">
                            <div class="hover handel" @click="edit(scope.row)" v-permission="'开票人设置-edit'" >编辑</div>
                            <div class="hover handel" @click="del(scope.row)"  v-permission="'开票人设置-del'">删除</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    name: "NavBarLeft",
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    data() {
        return {
            image: require('../../assets/image/none.png'),
            cellSty: {
                'background-color': '#aaa'
            },
            multipleSelection: [],
        };
    },
    mounted() {
    },
    methods: {
        getRowKeys(row){
            return row.id
        },
        edit(row) {
            this.$emit('edit', row.id)
        },
        del(row) {
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$emit('delete', row.id)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
    }
}
</script>
<style lang="scss">
.el-table tbody tr:hover>td {
    background-color: #EDF3FE !important
}
</style>

<style lang="scss">
.table-part {
    .table {
        margin-top: 8px;
        width: 100%;
        height: 560px;

        .chick {
            display: flex;
            justify-content: space-evenly;
        }

        // .is-scrolling-left{
        //     height:512px !important;
        // }
        // .el-table__fixed-body-wrapper{
        //     height: 512px !important;
        //     top:48px !important;
        // }
        .type-status {
            span {
                font-size: 14px;
                border-radius: 4px;
                width: auto;
                padding: 3px 10px;
                line-height: 24px;
            }

            .status1 {
                color: #B0B1B9;
                background: #E9EAEE;
            }

            .status2 {
                color: #2C57FF;
                background: #EAF1FB;
            }

            .status3 {
                color: #10C257;
                background: #EDFBEF;
            }

            .status4 {
                color: #F74B4B;
                background: #FFF3F3;
            }
        }
        .sell{
            width: 76px;
            height: 24px;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            text-align: center;
            line-height: 24px;
            color: #B0B1B9;
            background: #E9EAEE;
        }
        .sell-status1{
            color: #DE8500;
            background: #FAF5E6;
        }
        .sell-status2{
            color: #0C80EB;
            background: #EAF7FF;
        }
        .sell-status3{
            color: #2C57FF;
            background: #EAF1FB;
        }
        .sell-status4{
            color: #08B54D;
            background:#EDFBEF;
        }

    }
}
</style>
