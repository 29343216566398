import { render, staticRenderFns } from "./sellChance-main.vue?vue&type=template&id=e086ae46&scoped=true&"
import script from "./sellChance-main.vue?vue&type=script&lang=js&"
export * from "./sellChance-main.vue?vue&type=script&lang=js&"
import style0 from "@/assets/scss/formstyle.scss?vue&type=style&index=0&prod&lang=scss&"
import style1 from "./sellChance-main.vue?vue&type=style&index=1&id=e086ae46&prod&lang=scss&scoped=true&"
import style2 from "./sellChance-main.vue?vue&type=style&index=2&id=e086ae46&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e086ae46",
  null
  
)

export default component.exports