<template>
    <div class="adduser">
        <el-dialog :title="title" :visible.sync="showDialog" width="800px" class="addform" @close="cancel" :close-on-click-modal="false" style="margin-top:-5vh !important" v-loading="load">
            <div style="line-height:1px;color:#F56C6C;font-size: 12px;position: relative;top: -22px;left: 64px;">( 如果非对公收款账户与开票企业不一致的，开票申请人需要给开票人提供现金收据。)</div>
            <div class="addform billform"  >
                <div class="formbox" v-show="actIndex==0" >
                    <el-form :model="addform" :rules="rules" ref="addform" class="autoform">
                        <el-row>
                            <el-col :span="11">
                                <el-form-item prop="way" class="left" label="开票形式">
                                    <span slot="label"><span class="check-form-notice">*</span>开票形式</span>
                                    <el-select v-model="addform.way" placeholder="请选择" @change="changeWay">
                                        <el-option v-for="item in openList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="客户名称" prop="client">
                                    <span slot="label"><span class="check-form-notice">*</span>客户名称</span>
                                    <el-select v-model="addform.client" :placeholder="addform.way?'请选择':'请先选择开票形式'" filterable @change="getDownSelect" popper-class="custom-select" 
                                    clearable :disabled="addform.way?false:true">
                                        <el-option v-for="item in clientList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="开票公司" class="left" prop="company">
                                    <span slot="label"><span class="check-form-notice">*</span>开票公司</span>
                                    <el-select v-model="addform.company" placeholder="请选择" @change="getCheck" filterable>
                                        <el-option v-for="item in companyList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right" >
                                <el-form-item label="开票申请人" prop="applicant">
                                    <span slot="label"><span class="check-form-notice">*</span>开票申请人</span>
                                    <el-input v-model="addform.applicant" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="发票类型" class="left" prop="type">
                                    <span slot="label"><span class="check-form-notice">*</span>发票类型</span>
                                    <el-select v-model="addform.type" placeholder="请选择" @change="changeBillType">
                                        <el-option v-for="item in typeList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            
                            <el-col :span="11" class="right">
                                <el-form-item label="购买方名称" prop="purchaser">
                                    <span slot="label"><span class="check-form-notice">*</span>购买方名称</span>
                                    <!-- <el-input v-model="addform.purchaser" placeholder="请输入"></el-input> -->
                                    <div class="handel bank hover" @click="checkBusUser">{{addform.purchaser_name?addform.purchaser_name:'-请选择购买方名称-'}}</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="是否回款" class="left" prop="payback">
                                    <span slot="label"><span class="check-form-notice">*</span>是否回款</span>
                                    <el-select v-model="addform.payback" placeholder="请选择">
                                        <el-option v-for="item in backList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="纳税人识别号" prop="identifier">
                                    <span slot="label"><span class="check-form-notice">*</span>纳税人识别号</span>
                                    <el-input v-model="addform.identifier" placeholder="请选择购买方" :maxlength="20" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="OA回款单号" class="left">
                                    <span slot="label"><span class="check-form-notice">*</span>OA回款单号</span>
                                    <el-select v-model="addform.back_number"  :placeholder="addform.client?'请选择':'请先选择客户'"
                                     :disabled="addform.payback==9?true:addform.client?false:true" multiple auto-complete collapse-tags @change="checkBack" popper-class="OAselect" >
                                        <el-option v-for="item in payList" :label="item.number" :value="item.id" :key="item.id" >
                                            <span style="float: left;">{{item.number}}</span>
                                            <span style="position: absolute; right: 35px; font-size: 12px; color: #8492a6; ">{{ item.create_time}}</span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right" v-if="bill_type_name == '电子专票' ||bill_type_name == '纸质专票' ||bill_type_name == '专票'">
                                <el-form-item label="购买方地址">
                                    <span slot="label"><span class="check-form-notice">*</span>购买方地址</span>
                                    <el-input v-model="addform.purchaser_address" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" v-if="bill_type_name == '电子专票' ||bill_type_name == '纸质专票' ||bill_type_name == '专票'">
                                <el-form-item label="开户行" class="left" >
                                    <span slot="label"><span class="check-form-notice">*</span>开户行</span>
                                    <div class="handel bank hover" @click="checkReceive">{{receivename}}</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right" v-if="bill_type_name == '电子专票' ||bill_type_name == '纸质专票' ||bill_type_name == '专票'">
                                <el-form-item label="购买方联系方式" prop="purchaser_phone">
                                    <span slot="label"><span class="check-form-notice">*</span>购买方联系方式</span>
                                    <el-input v-model="addform.purchaser_phone" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" v-if="bill_type_name == '电子专票' ||bill_type_name == '纸质专票' ||bill_type_name == '专票'">
                                <el-form-item label="开户行账户" class="left">
                                    <span slot="label"><span class="check-form-notice">*</span>开户行账户</span>
                                    <el-input v-model="addform.account" placeholder="请选择开户行" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right" v-if="addform.way == 1||addform.way == ''">
                                <el-form-item label="项目名称" prop="project">
                                    <span slot="label"><span class="check-form-notice">*</span>项目名称</span>
                                    <el-input v-model="addform.project" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right" v-if="addform.way == 2">
                                <el-form-item label="项目名称" prop="project">
                                    <span slot="label">
                                        <span slot="label"><span class="check-form-notice">*</span>项目名称</span>
                                        <el-tooltip content="供货/应税劳务等服务名称" placement="bottom" effect="light"  popper-class="handlerTab_tooltip">
                                            <local-svg iconClass="tips" class="handelicon hover" style="margin-left: 4px;"></local-svg>
                                        </el-tooltip>
                                    </span>
                                    <el-input v-model="addform.project" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" v-if="addform.way == 1">
                                <el-form-item label="系统来源" class="left">
                                    <el-input v-model="addform.source" placeholder="请输入" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right" v-if="addform.way == 1">
                                <el-form-item label="渠道名称">
                                    <el-input v-model="addform.channel" placeholder="请输入" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            
                        </el-row>
                        <el-row v-if="addform.way == 1||addform.way == ''">
                            <el-col :span="11">
                                <el-form-item label="税率" class="left" prop="rate">
                                    <span slot="label"><span class="check-form-notice">*</span>税率</span>
                                    <el-select v-model="addform.rate" placeholder="请选择">
                                        <el-option v-for="item in rateList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="含税金额(元)">
                                    <span slot="label"><span class="check-form-notice">*</span>含税金额</span>
                                    <el-input v-model="addform.amount_tax" placeholder="请输入" @blur="changeThousand('amount_tax')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="规格型号" class="left">
                                    <el-input v-model="addform.models" placeholder="请输入" ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="单位">
                                    <el-input v-model="addform.unit" placeholder="请输入" ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="数量" class="left">
                                    <el-input v-model="addform.quantity" placeholder="请输入" ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="不含税金额">
                                    <el-input v-model="addform.no_tax_amount" placeholder="请输入"  @blur="changeThousand('no_tax_amount')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="单价" class="left">
                                    <el-input v-model="addform.unit_price" placeholder="请输入"  @blur="changeThousand('unit_price')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="发票备注">
                                    <el-input v-model="addform.remark" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="所属部门" class="left">
                                    <span slot="label"><span class="check-form-notice">*</span>所属部门</span>
                                    <el-input v-model="addform.branch_name" disabled v-if="type==2&&addform.audit_status!=17"></el-input>
                                    <el-select v-model="addform.branch_id" placeholder="请选择" v-else>
                                        <el-option v-for="item in branchList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="选择开票人">
                                    <span slot="label"><span class="check-form-notice">*</span>选择开票人</span>
                                    <el-select v-model="addform.approver" placeholder="请选择" :disabled="haveDrawer">
                                        <el-option v-for="item in adminList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row v-if="addform.way == 2">
                            <el-col :span="11">
                                <el-form-item label="房屋合同单号" class="left" prop="house_num">
                                    <span slot="label"><span class="check-form-notice">*</span>房屋合同单号</span>
                                    <el-select v-model="addform.house_num" :placeholder="addform.client?'请选择':'请先选择客户'" :disabled="addform.client?false:true">
                                        <el-option v-for="item in houseList" :label="item.num" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="房屋租赁面积(m²)" prop="area">
                                    <span slot="label"><span class="check-form-notice">*</span>房屋租赁面积(m²)</span>
                                    <el-input v-model="addform.area" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <!-- <el-col :span="11">
                                <el-form-item label="房屋租赁费(元)" class="left" prop="rent">
                                    <span slot="label"><span class="check-form-notice">*</span>房屋租赁费(元)</span>
                                    <el-input v-model="addform.rent" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col> -->
                            <el-col :span="11">
                                <el-form-item prop="rate" class="left">
                                    <span slot="label"><span class="check-form-notice">*</span>税率</span>
                                    <el-select v-model="addform.rate" placeholder="请选择">
                                        <el-option v-for="item in rateList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="含税金额(元)" prop="amount_tax">
                                    <span slot="label"><span class="check-form-notice">*</span>含税金额(元)</span>
                                    <el-input v-model="addform.amount_tax" placeholder="请输入" @blur="changeThousand('amount_tax')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="不含税金额" class="left">
                                    <el-input v-model="addform.no_tax_amount" placeholder="请输入" @blur="changeThousand('no_tax_amount')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="单价">
                                    <el-input v-model="addform.unit_price" placeholder="请输入" @blur="changeThousand('unit_price')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="租赁地址" prop="address" class="left">
                                    <span slot="label"><span class="check-form-notice">*</span>租赁地址</span>
                                    <el-input v-model="addform.address" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11"  class="right">
                                <el-form-item label="租赁起止时间" prop="set_time">
                                    <span slot="label"><span class="check-form-notice">*</span>租赁起止时间</span>
                                    <el-date-picker v-model="addform.set_time" value-format="yyyy-MM-dd" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" type="daterange" placeholder="请选择"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="所属部门" prop="branch_id" class="left">
                                    <span slot="label"><span class="check-form-notice">*</span>所属部门</span>
                                    <el-input v-model="addform.branch_name" disabled v-if="type==2&&addform.audit_status!=17"></el-input>
                                    <el-select v-model="addform.branch_id" placeholder="请选择" v-else>
                                        <el-option v-for="item in branchList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="发票备注" >
                                    <el-input v-model="addform.remark" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11"  class="left">
                                <el-form-item label="选择开票人" prop="approver">
                                    <span slot="label"><span class="check-form-notice">*</span>选择开票人</span>
                                    <el-select v-model="addform.approver" placeholder="请选择" :disabled="haveDrawer">
                                        <el-option v-for="item in adminList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </div>
            <div slot="footer" class="dialog-footer btnpart">
                <el-button v-if="type==1" class="confirm" type="primary" @click="addOk('addform','1')">保存并提交</el-button>
                <el-button v-if="type==1" class="savebtn" type="primary" @click="addOk('addform','2')">保存</el-button>
                <el-button v-if="type==2" class="confirm" type="primary" @click="editOk('addform','1')">保存并提交</el-button>
                <el-button v-if="type==2" class="savebtn" type="primary" @click="editOk('addform','2')">保存</el-button>
                <el-button class="cancel" @click="cancel">取消</el-button>
            </div>
        </el-dialog>
        <receiveCost v-if="receiveDialog" :showdialog="receiveDialog" @add="checkUser" @closereceive="closereceive" :actuser="addform.bank"></receiveCost>
        <buyInfo v-if="buyDialog" :showdialog="buyDialog" @add="checkBus"  @closereceive="closeBus" :actuser="addform.purchaser" :actnumber="addform.identifier" :actname="addform.purchaser_name"></buyInfo>
    </div>
</template>

<script>
import {addselect,adddownselect,totalmoney,checkuser,bankchannel} from '@/api/bill'
import { Loading } from 'element-ui';
import receiveCost from '@/components/constapply/receive.vue'
import buyInfo from '@/components/openbill/buyinfo.vue'
export default {
    name: "adduser",
    props: {
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        form:{
            type:Object,
        	default:()=>{
        		return false
        	}
        },
        type:{
            type:Number,
            default:() =>{
                return 1
            }
        },
    },
    components:{
        receiveCost,
        buyInfo
    },
    data() {
        return {
            load:true,
            receiveDialog:false,
            buyDialog:false,
            uploadUrl:process.env.VUE_APP_BASE_URL+'/common/uploadImg',
            image:require('../../assets/image/none.png'),
            list:[],
            actIndex:0,
            openList:[],
            receivename:'-请选择开户行-',
            bill_type_name:'',
            fileList:[],
            clientList:[],    //客户列表
            companyList:[], //开票公司列表
            typeList:[],   //开票类型列表
            backList:[],    //是否回款
            payList:[],     //回款单号
            rateList:[],    //税率列表
            houseList:[],   //房屋合同
            adminList:[],
            branchList:[],
            contracttyprList:[
                {id:2,name:'其他合同'},
                {id:1,name:'房屋合同'},
            ],
            havenoList:[
                {id:1,name:'有'},
                {id:2,name:'无'},
            ],
            contractList:[],
            addform:{
                way:'',
                client:'',
                company:'',
                applicant:'',
                channel:'',
                source:'',
                type:'',
                identifier:'',
                payback:'',
                purchaser:'',
                purchaser_name:'',
                back_number:[],
                purchaser_address:'',
                purchaser_phone:'',
                account:'',
                project:'',
                rate:'',
                amount_tax:'',
                remark:'',
                branch_id:'',
                house_num:'',
                area:'',
                rent:'',
                set_time:[],
                address:'',
                unit_price:'',
                no_tax_amount:'',
                models:'',
                quantity:'',
                unit:'',
            },
            sellList:[],
            title:'新建发票',
            showDialog:false,
            haveDrawer:false,
            rules: {
                // way: [
                //     { required: true, message: '请选择开票形式', trigger: 'change' },
                // ],
                // client: [
                //     { required: true, message: '请选择客户名称', trigger: 'change' },
                // ],
                // company: [
                //     { required: true, message: '请选择开票公司', trigger: 'change' },
                // ],
                // applicant: [
                //     { required: true, message: '请输入开票申请人', trigger: 'blur' },
                // ],
                // type: [
                //     { required: true, message: '请选择发票类型', trigger: 'change' },
                // ],
                // identifier: [
                //     { required: true, message: '请选择纳税人识别号', trigger: 'change' },
                // ],
                // payback: [
                //     { required: true, message: '请选择是否回款', trigger: 'change' },
                // ],
                // purchaser: [
                //     { required: true, message: '请输入购买方名称', trigger: 'blur' },
                // ],
                // back_number: [
                //     { required: true, message: '请选择回款单号', trigger: 'change' },
                // ],
                // project: [
                //     { required: true, message: '请输入名称', trigger: 'blur' },
                // ],
                // // rate: [
                // //     { required: true, message: '请选择税率', trigger: 'change' },
                // // ],
                // house_num: [
                //     { required: true, message: '请选择房屋合同单号', trigger: 'change' },
                // ],
                // area: [
                //     { required: true, message: '请输入房屋租赁面积', trigger: 'blur' },
                // ],
                // rent: [
                //     { required: true, message: '请输入房屋租赁费用', trigger: 'blur' },
                // ],
                // amount_tax: [
                //     { required: true, message: '请输入含税金额', trigger: 'blur' },
                // ],
                // address: [
                //     { required: true, message: '请输入租赁地址', trigger: 'blur' },
                // ],
                // set_time: [
                //     { required: true, message: '请选择租赁起止时间', trigger: 'change' },
                // ],
                // // approver: [
                // //     { required: true, message: '请选择审批人', trigger: 'change' },
                // // ],
                // branch_id: [
                //     { required: true, message: '请选择所属部门', trigger: 'change' },
                // ],
            },
        };
    },
    mounted(){
        this.showDialog = this.show
        this.getSelectList()
        this.addform.applicant = localStorage.getItem('username')
        this.addOk = this.$debounce(this.addOk )
        this.editOk = this.$debounce(this.editOk )
    },
    methods: {
        changeThousand(key){
            console.log(key,this.addform[key])
            let value = Number(this.addform[key].replace(/,/g, "")).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 })
            this.$set(this.addform,key,value)
            // this.addform.amount_tax = Number(this.addform.amount_tax.replace(/,/g, "")).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 })
        },
        getCheck(){
            let data = {
                id:this.addform.company
            }
            checkuser(data).then(res =>{
                if(res.data.drawer.payer_id){
                    this.$set(this.addform,'approver',res.data.drawer.payer_id)
                    this.haveDrawer = true
                }else{
                    this.haveDrawer = false
                    this.$set(this.addform,'approver','')
                }
            })
        },
        changeBillType(e){
            this.bill_type_name = this.typeList.find(item => item.id == e).name
        },
        checkBack(){
            if(this.addform.way == 1){
                let obj = {
                    pay_back_id:this.addform.back_number.toString()
                }
                bankchannel(obj).then(res =>{
                    this.$set(this.addform,'channel',res.data.channel.length>0 ? res.data.channel.join('、') : '无')
                    this.$set(this.addform,'source',res.data.source.length>0 ? res.data.source.join('、') : '无')
                    console.log(this.addform.source,1)
                }) 
            }
            if(this.addform.back_number.length==0){
                this.addform.amount_tax = ''
                this.addform.channel = ''
                this.addform.source = ''
                console.log(this.addform.source,2)
                return
            }
            console.log(this.addform)
            let data = {
                id:this.addform.back_number.toString(),
                way:this.addform.way
            }
            totalmoney(data).then(res=>{
                this.addform.amount_tax = Number(res.data.sum).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 })
            })
        },
        removeDuplicates(array){
            return [...new Set(array)];
        },
        getDownSelect(e,bill){
            let data = {
                id:this.addform.client
            }
            adddownselect(data).then(res=>{
                if(this.addform.way == 1){
                    this.payList = res.data.pay
                    this.addform.channel = ''
                    this.addform.source = ''
                    if(e != 0){
                        this.addform.back_number = []
                        console.log(1)
                    }
                    if(bill){
                        this.$set(this.addform,'back_number',[Number(this.form.back_id)])
                        this.checkBack()
                    }
                }else{
                    this.payList = res.data.houseBack
                    this.houseList = res.data.houseContract
                    this.addform.channel = ''
                    this.addform.source = ''
                    if(e != 0){
                        this.addform.back_number = []
                    }
                    if(bill){
                        this.$set(this.addform,'back_number',[Number(this.form.back_id)])
                        this.checkBack()
                    }
                }
            })
        },
        changeWay(){
            this.addform.client = ''
        },
        checkBusUser(){
            this.buyDialog = true
        },
        checkBus(msg){
            this.addform.purchaser_name = msg.name
            this.addform.purchaser  = msg.id
            this.addform.identifier = msg.number
            this.buyDialog = false
        },
        closeBus(msg){
            this.buyDialog = false
        },
        checkReceive(){
            this.receiveDialog = true
        },
        checkUser(msg){
            this.receivename = msg.name
            this.addform.bank  = msg.id
            this.addform.account = msg.account
            this.receiveDialog = false
        },
        closereceive(msg){
            this.receiveDialog = false
        },
        getSelectList(){
            addselect().then(res =>{
                this.openList = res.data.way
                this.clientList = res.data.client
                this.companyList = res.data.company
                this.typeList = res.data.type
                this.backList = res.data.payback
                // this.payList = res.data.pay
                this.rateList = res.data.rate
                // this.houseList = res.data.houseBack
                this.branchList = res.data.branch
                this.adminList  = res.data.admin
                localStorage.setItem('x_token',res.data.token)
                if(this.branchList.length==1&&this.type==1){
                    this.$set(this.addform,'branch_id',this.branchList[0].id)
                }
                if(this.type == 2 ){
                    this.addform = this.form
                    this.addform.type = Number(this.form.type)
                    this.changeBillType(this.addform.type)
                    let arr =  this.form.back_number_id ? this.form.back_number_id.split(',') : []
                    this.addform.back_number = []
                    for(var a = 0;a<arr.length;a++){
                        this.$set(this.addform.back_number,a,Number(arr[a]))
                    }
                    this.addform.company = Number(this.form.company)
                    this.addform.client = this.form.client_id
                    this.getDownSelect(0)
                    if(this.form.payback_id != '否'){
                        this.checkBack()
                    }
                    this.changeThousand('amount_tax')
                    this.receivename = this.form.bank_id?this.form.bank_id:'-请选择开户行-'
                    this.addform.house_num = Number(this.form.house_num)
                    this.addform.set_time = [this.form.start_time,this.form.end_time]
                    // this.addform.branch_id = this.form.branch
                    this.addform.approver = this.form.approver_id
                    this.title = '编辑发票'
                    if(Number(this.form.type) >6){
                        this.typeList = this.typeList.slice(4,this.typeList.length)
                    }
                    this.$set(this.addform,'channel',this.addform.channel)
                    this.$set(this.addform,'source',this.addform.source)
                }else{
                    this.typeList = this.typeList.slice(4,this.typeList.length)
                    if(this.form.back_id){
                        this.addform.way = Number(this.form.way)
                        this.addform.client = Number(this.form.client)
                        this.addform.payback = this.backList.length > 0 ? this.backList[0].id : ''
                        this.getDownSelect(1,'bill')
                        this.addform.amount_tax = this.form.amount
                        if(this.addform.way == 2){
                            this.addform.house_num = Number(this.form.house_id)
                            this.addform.address = this.form.address
                            console.log(this.form.date)
                            this.$set(this.addform,'set_time',this.form.date.split(','))
                        }
                    }
                }
                this.load = false
            })
        },
        getDetailList(row,index){
            row.backlist.forEach(item =>{
                if(row.back_id==item.id){
                    row.business_type_id = item.business_type?item.business_type:'无'
                    row.business_detail_id = item.business_detail?item.business_detail:'无'
                    row.business_detail = item.business_detail_id
                    row.business_type = item.business_type_id
                }
            })
        },
        delRow(row,index){
            this.addform.fee_apply_details.splice(index,1)
        },
        addDetail(){
            this.addform.fee_apply_details.push({
                contract_id:'',
                back_id:'',
                business_type:'',
                business_detail:'',
                type:'',
                backlist:[],
            })
        },
        addOk(addform,type){
            this.$refs[addform].validate((valid) => {
                if (valid) {
                    if(this.addform.way==2){
                        this.addform.start_time =  this.addform.set_time[0]
                        this.addform.end_time =  this.addform.set_time[1]
                    }
                    if(type==2){
                        this.addform.audit_status=17
                    }else{
                        this.addform.audit_status=18
                    }
                    delete this.addform.set_time
                    let data = JSON.parse(JSON.stringify(this.addform))
                    this.$emit('add',data)
                } else {
                    return false;
                }
            });
            
        },
        editOk(addform,type){
            this.$refs[addform].validate((valid) => {
                if (valid) {
                    if(this.addform.way==2){
                        this.addform.start_time =  this.addform.set_time[0]
                        this.addform.end_time =  this.addform.set_time[1]
                    }
                    if(type==2){
                        this.addform.audit_status=17
                    }else{
                        this.addform.audit_status=18
                    }
                    let data = JSON.parse(JSON.stringify(this.addform))
                    this.$emit('edit',data)
                } else {
                    return false;
                }
            });
        },
        cancel(){
            this.$emit('cancel',false)
        },
        clean(){
            
        },
    }
}
</script>

<style lang="scss">
.adduser {
    .headtab{
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 15px 0 10px 0 ;
        .tab-box{
            display: flex;
            justify-content: space-between;
            width: 132px;
            font-size: 14px;
            border-bottom: 1px solid #F0F1F5;
            box-sizing: content-box;
            height: 25px;
            .tab{
                color: #B0B1B9;
                height: 100%;
            }
            .tab:hover{
                color: #2C57FF;
            }
            .acttab{
                font-weight: bold !important;
                color: #6B6D77 !important;
                border-bottom:2px solid #2C57FF !important;
                width: auto;
                height: 100%;
            }
        }
    }
    .right{
        margin-left: 45px;
        .filelist{
            width: 100%;
            height: 200px;
            overflow-y: auto;
            padding-right: 8px ;
            box-sizing: border-box;
            .fileline{
                display: flex;
                height: 28px;
                border-radius: 4px;
                background: #F4F7FC;
                width: 100%;
                align-items: center;
                margin-bottom: 6px;
                padding: 0 12px 0 8px;
                justify-content: space-between;
                box-sizing: border-box;
                .icon{
                    margin-right: 7px;
                    width: 12px;
                    height: 14px;
                }
                .name{
                    font-size: 12px;
                    color: #474952;
                }
                .close{
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background: rgba(63,69,124,0.1);
                    display: none;
                    align-items: center;
                    justify-content: center;
                    .closeicon{
                        width: 8px;
                        height: 8px;
                        fill: #B0B1B9;
                    }
                }
                .close:hover{
                    background: rgba(44, 87, 255, 0.2) !important;
                    .closeicon{
                        fill: #2C57FF !important;
                    }
                }
            }
            .fileline:hover{
                background:#EDF3FE !important;
                .close{
                    display: flex;
                }
            }
        }
    }
    .file{
        width: 100%;
        height: auto;
            .filepart{
            width: 100%;
            display: flex;
            .filetip{
                font-size: 10px;
                color: #D0D0D2;
                line-height: 45px;
                margin-left: 8px;
            }
            .icon{
                margin-right: 7px;
            }
        }
    }
    .formbox{
        height: auto !important;
        .el-select .el-input .el-select__caret{
            display: flex; 
            justify-content: center;
            align-items: center;
        }
        .table-main{
            padding: 14px 0 ;
            box-sizing: border-box;
            .table-title{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                .title{
                    font-weight: bold;
                    color: #6B6D77;
                }
                .addpart{
                    .icon{
                        fill: #475B93 !important;
                    }
                }
                .addpart:hover{
                    color: #2C57FF !important;
                    .svg-icon{
                        fill: #2C57FF !important;
                    }
                }
                .addpart:active{
                    color: #133CDC !important;
                    .svg-icon{
                        fill: #133CDC !important;
                    }
                }
            }
            .table{
                margin-top: 12px;
            }
        }
    }
}
.handlerTab_tooltip{
    border: none !important;
        box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
        color: #6B6D77 !important;
        padding: 8px 16px !important;
        line-height: 20px !important;
}
.handlerTab_tooltip.is-light{
    border: none !important;
}
.handlerTab_tooltip .popper__arrow {
    /* 上方箭头 */
    border-top-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
    /* 下方箭头 */
    border-bottom-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
}
.remark{
    .el-textarea__inner{
        height: 80px !important;
        line-height: 26px !important;
        padding: 5px 12px !important;
        font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    }
    .el-textarea__inner:hover{
        border-color: #2C57FF !important;
        
    }
}
.checkcontract{
    width: auto !important;
}
</style>
