<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
import "@/assets/scss/element-style.scss"
export default {
	name: 'home',
	data() {
		return {

		}
	},
}

</script>

<style lang="scss" >
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// text-align: center;
	background: #F2F5FB;
	color: #2c3e50;
}

::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}

::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: #C2C8D5;
}

::-webkit-scrollbar-track {
	border-radius: 10px;
	background: #EFF1F5;
}

.hover {
	cursor: pointer !important;
}

.disabled {
	cursor: not-allowed;
}

* {
	margin: 0;
	padding: 0;
	// -webkit-user-select: none;
	// -moz-user-select: none;
	// user-select: none;
	// -ms-user-select: none;
}

// @import "@/assets/scss/formstyle.scss"
@import "@/assets/scss/flex.scss"
</style>
