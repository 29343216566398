import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import Fragment from 'vue-fragment'
import axios from 'axios'
import { message } from './utils/message';
import { Loading } from 'element-ui';
import _ from 'lodash';
import { dateFormat1,dateFormat2 } from './utils/app'

import 'element-ui/lib/theme-chalk/index.css';
import '@/icons'
import Directives from "./utils/index"
Vue.use(Directives);
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Loading);
Vue.prototype.axios = axios
Vue.use(Fragment.Plugin);
Vue.prototype.$bus = new Vue()
Vue.prototype.$message = message;
Vue.prototype.$date1 = dateFormat1;
Vue.prototype.$date2 = dateFormat2;
Vue.prototype.$debounce = function (fn, immediate = true, delay) {
  let timer;
  let delayTime = delay || 3000
  return function () {
    if (timer) clearTimeout(timer);
    if (immediate) {
      let bool = !timer;
      timer = setTimeout(() => (timer = 0), delayTime);
      return bool && fn.apply(this, [...arguments]);
    }
    timer = setTimeout(() => fn.apply(this, [...arguments]), delayTime);
  };
}
Vue.prototype.$BASE_URL = 'https://test.tjlhyg.com/'
Vue.prototype.$BASE_OUT_URL = 'https://bhfw.tjbinhang.cn/web/'
Vue.prototype.$BASE_DOOR_URL = 'https://bhmh.tjbinhang.cn/#/home/plat-work'
// Vue.prototype.$BASE_URL = 'https://devoa.tjlhyg.com/'
// Vue.prototype.$BASE_OUT_URL = 'https://waiqin.lianghang.cc/web/'
// Vue.prototype.$BASE_DOOR_URL = 'https://bhmhtest.tjlhyg.com/bhmh/#/home/plat-work'
ElementUI.Dialog.props.closeOnClickModal.default = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
