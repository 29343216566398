import request from '../utils/request'

//获取头部待办
export function message(data){
    return request({
        url:'/common/message',
        method:'get',
        params:data
    })
}

//获取代办已读
export function readmsg(data){
    return request({
        url:'/remind/read',
        method:'get',
        params:data
    })
}

//读取系统消息
export function readsystem(data){
    return request({
        url:'/system/read',
        method:'get',
        params:data
    })
} 

//清空消息
export function clearmessage(data){
    return request({
        url:'/system/deal',
        method:'get',
        params:data
    })
}


//保存展示列
export function saveheader(data){
    return request({
        url:'/common/fieldOrder',
        method:'post',
        data
    })
}

// 清空公告
export function clearreport(data){
    return request({
        url:'/notice/clear',
        method:'get',
        params:data
    })
}

// 所属部门下拉
export function branch() {
    return request({
        url: '/common/branch',
        method: 'get'
    })
}

//所有客户
export function custom(data) {
    return request({
        url: '/common/allClient',
        method: 'get',
        params:data
    })
}


//客户获取销售机会
// export function customsell(data) {
//     return request({
//         url: '/payback/sell',
//         method: 'get',
//         params:data
//     })
// }


//客户获取销售机会(新增渠道信息)
export function customsell(data) {
    return request({
        url: '/v1/sell/clientSell',
        method: 'get',
        params:data
    })
}

//客户获取合同
export function customcont(data) {
    return request({
        url: '/payback/contract',
        method: 'get',
        params:data
    })
}

//客户合同获取单项明细
export function contdetail(data) {
    return request({
        url: '/payback/single',
        method: 'get',
        params:data
    })
}

//版本号
export function Vnumber(data) {
    return request({
        url: '/common/version',
        method: 'get',
        params:data
    })
}