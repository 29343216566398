import request from '../utils/request'

//获取用户列表
export function customlist(data){
    return request({
        url:'/client/table',
        method:'get',
        params:data
    })
}

//获取头部列表
export function headlist(data){
    return request({
        url:'/common/field',
        method:'get',
        params:data
    })
}

//获取头部列表
export function saerchlist(data){
    return request({
        url:'/client/search',
        method:'get',
        params:data
    })
}

//获取标单选择列表
export function selectlist(data){
    return request({
        url:'/client/save',
        method:'get',
        params:data
    })
}

//添加客户
export function addcustom(data){
    return request({
        url:'/client/save',
        method:'post',
        data
    })
}

//修改客户信息
export function editcustom(data){
    return request({
        url:'/client/edit',
        method:'post',
        data
    })
}

// //获取客户详情
// export function customdetail(data){
//     return request({
//         url:'/client/detail',
//         method:'get',
//         params:data
//     })
// }

//获取客户详情
export function customdetail(data){
    return request({
        url:'/v1/client/clientDetail',
        method:'get',
        params:data
    })
}

//导出文件
export function exportfile(data){
    return request({
        url:'/client/export',
        method:'post',
        data
    })
}

//单个删除客户
export function deluser(data){
    return request({
        url:'/client/delete',
        method:'delete',
        params:data
    })
}

// 批量删除客户
export function delmoreuser(data){
    return request({
        url:'/client/del',
        method:'delete',
        data
    })
}

//获取企业信息
export function com_customlist(data){
    return request({
        url:'/v1/client/addTypeClient',
        method:'get',
        params:data
    })
}

//添加线索客户
export function com_addcustom(data){
    return request({
        url:'/v1/client/addTypeClient',
        method:'post',
        data
    })
}

//编辑联系人
export function com_editcustom(data){
    return request({
        url:'/v1/client/addTypeClient',
        method:'post',
        data
    })
}

//删除联系人
export function com_delcustom(data){
    return request({
        url:'/v1/client/delLinkMan',
        method:'delete',
        data
    })
}

//添加跟进记录
export function addfollow(data){
    return request({
        url:'/v1/client/addFollowRecord',
        method:'post',
        data
    })
}

//获取跟进记录
export function followlist(data){
    return request({
        url:'/v1/client/followRecordList',
        method:'get',
        params:data
    })
}

//获取企业详情
export function com_customdetail(data){
    return request({
        url:'/v1/client/clientDetail',
        method:'get',
        params:data
    })
}

//修改客户名称
export function customedit(data){
    return request({
        url:'/v1/client/alterClientType',
        method:'post',
        data
    })
}
