<template>
    <div class="table-part">
        <div class="table">
            <el-table :data="list" height="560px" style="width: 100%"
                :header-cell-style="{ 'background': '#F4F7FC', 'color': '#B0B1B9', 'fontWeight': 'bold' }"
                tooltip-effect="dark"
                v-loading="loading" >
                <template slot="empty">
                    <el-empty :image-size="160" :image="image" description='暂无数据'></el-empty>
                </template>
                <!-- <el-table-column type="index" width="100" label="序号" align="center"></el-table-column> -->
                <el-table-column width="150" label="创建人" align="center" prop="create_id"></el-table-column>
                <el-table-column width="" label="标题" align="left" prop="title"></el-table-column>
                <el-table-column width="150" label="创建时间" align="left" prop="update_time" sortable></el-table-column>
                <el-table-column width="150" v-if="type==2" label="读取状态" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.is_read == 0?'未读':'已读' }}</span>
                    </template>
                </el-table-column>
                <el-table-column width="150" v-if="type==1" label="发布时间" align="left" prop="date" sortable></el-table-column>
                <el-table-column width="180" v-if="type==1" label="发送方式" align="left" prop="way">
                </el-table-column>
                <el-table-column width="180" v-if="type==1" label="发送状态" align="left" prop="status">
                </el-table-column>
                <el-table-column label="操作" align="center" width="150">
                    <template slot-scope="scope">
                        <div class="chick">
                            <div class="hover handel" @click="edit(scope.row)" v-permission="'系统公告-edit'" v-if="type==1">编辑</div>
                            <div class="hover handel" @click="detail(scope.row)" v-permission="''">详情</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    name: "NavBarLeft",
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        type:{
            type: String,
            default: () => {
                return '1'
            }
        },
        loading:{
            type: Boolean,
            default: () => {
                return true
            } 
        }
    },
    data() {
        return {
            image: require('../../../assets/image/none.png'),
            // BatchStr: ''
        };
    },
    mounted() {
    },
    methods: {
        detail(row) {
            this.$emit('detail', row)
        },
        edit(row) {
            let data = {
                info: row,
                type: true
            }
            this.$emit('tableedit', data)
        },
    }
}
</script>
<style lang="scss">
.el-table tbody tr:hover>td {
    background-color: #EDF3FE !important
}
</style>

<style lang="scss">
.table-part {
    .table {
        margin-top: 8px;
        width: 100%;
        height: 560px;

        .chick {
            display: flex;
            justify-content: space-evenly;
        }

        // .is-scrolling-left{
        //     height:512px !important;
        // }
        // .el-table__fixed-body-wrapper{
        //     height: 512px !important;
        //     top:48px !important;
        // }
        .type-status {
            span {
                font-size: 14px;
                border-radius: 4px;
                width: auto;
                padding: 3px 10px;
                line-height: 24px;
            }

            .status1 {
                color: #B0B1B9;
                background: #E9EAEE;
            }

            .status2 {
                color: #2C57FF;
                background: #EAF1FB;
            }

            .status3 {
                color: #10C257;
                background: #EDFBEF;
            }

            .status4 {
                color: #F74B4B;
                background: #FFF3F3;
            }
        }
        .sell{
            width: 76px;
            height: 24px;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            text-align: center;
            line-height: 24px;
            color: #B0B1B9;
            background: #E9EAEE;
        }
        .sell-status1{
            color: #DE8500;
            background: #FAF5E6;
        }
        .sell-status2{
            color: #0C80EB;
            background: #EAF7FF;
        }
        .sell-status3{
            color: #2C57FF;
            background: #EAF1FB;
        }
        .sell-status4{
            color: #08B54D;
            background:#EDFBEF;
        }

    }
}
</style>
