// 房屋回款
import request from '@/utils/request'
// 获取表单列表
// export function housebackList(data) {
//     return request({
//         url: '/houseBack/table',
//         method: 'get',
//         params: data
//     })
// }
export function housebackList(data) {
    return request({
        url: '/v1/house_back/dataTable',
        method: 'get',
        params: data
    })
}
// 表头
export function headlist(id) {
    return request({
        url: '/common/field',
        method: 'get',
        params: {
            id
        }
    })
}
// 房屋回款详情
// export function backde(id) {
//     return request({
//         url: '/houseBack/detail',
//         method: 'get',
//         params: {
//             id
//         }
//     })
// }
export function backde(id) {
    return request({
        url: '/v1/house_back/detail',
        method: 'get',
        params: {
            id
        }
    })
}
// 所属部门下拉
export function branchxl() {
    return request({
        url: '/common/branch',
        method: 'get'
    })
}
// 创建人下拉
export function alladmin() {
    return request({
        url: '/common/allAdmin',
        method: 'get'
    })
}
// 客户下拉
export function kehu() {
    return request({
        url: '/common/allClient',
        method: 'get',

    })
}
// 业务明细，到款账户，租赁回款审核状态，汇款方式
export function ydzh() {
    return request({
        url: '/houseBack/save',
        method: 'get'
    })
}
// 租赁合同
export function zlht() {
    return request({
        url: '/common/allHouseContract',
        method: 'get'
    })
}
// 新建房屋回款
// export function backsave(data) {
//     return request({
//         url: '/houseBack/save',
//         method: 'post',
//         data
//     })
// }
export function backsave(data) {
    return request({
        url: '/v1/house_back/addHouseBack',
        method: 'post',
        data
    })
}
// 删除
export function backdel(data) {
    return request({
        url: '/houseBack/del',
        method: 'delete',
        params: {
            b_id: data

        }
    })
}
// 添加跟进人
export function addFollower(data) {
    return request({
        url: '/houseBack/addFollower',
        method: 'get',
        params: {
            id: data.id,
            follower: data.follower
        }
    })
}
// 所有跟进人
export function allfollow(id) {
    return request({
        url: '/common/allAdmin',
        method: 'get',
        params: {
            b_id: id
        }
    })
}
// 删除跟进人
export function delfollower(id) {
    return request({
        url: '/houseBack/delFollower',
        method: 'delete',
        params: {
            id
        }
    })
}
// 导出字段
export function exportdata() {
    return request({
        url: '/houseBack/houseBack',
        method: 'get',

    })
}
// 导出房屋合同
export function exportContract(data) {
    return request({
        url: '/houseBack/exportData',
        method: 'get',
        params: data
    })
}
// 编辑房屋回款
// export function edhouseback(data) {
//     return request({
//         url: '/houseBack/edit',
//         method: 'post',
//         data
//     })
// }
export function edhouseback(data) {
    return request({
        url: '/v1/house_back/editHouseBack',
        method: 'post',
        data
    })
}

// 审核房屋回款
export function examineback(data) {
    return request({
        url: '/houseBack/audit',
        method: 'post',
        data
    })
}

// 财务审核房屋回款
export function financeback(data) {
    return request({
        url: '/houseBack/financeAudit',
        method: 'post',
        data
    })
}

//用户获取房屋合同
export function housecontract(data) {
    return request({
        url: '/houseBack/list',
        method: 'get',
        params:data
    })
}
