<template>
    <div class="home">
        <div class="main">
            <div class="left">
                <div class="top">
                    <div class="top-title">平台角色</div>
                    <div class="top-add hover" @click="addNewRole"  v-permission="'角色管理-create'">
                        <local-svg iconClass="addrole" class="img1"></local-svg>
                        <span>新建角色</span>
                    </div>
                </div>
                <roleList :list="roleList" @change="editRole" @delete="deleteRole" @ckeck="checkList"></roleList>
            </div>
            <div class="right">
                <div class="top-serch">
                    <el-form :model="form" class="demo-form-inline" label-width="">
                        <div class="select-line">
                            <el-form-item label="用户名">
                                <el-input class="input-height" v-model="form.name" placeholder="请输入关键字搜索"></el-input>
                            </el-form-item>
                        </div>
                        <div class="line"></div>
                        <div class="btn-line">
                            <div class="btnpart ">
                                <el-button class="confirm" @click="getSearch('search')" plain v-permission="'角色管理-select'">查询</el-button>
                                <el-button class="cancel" @click="cleanSearch" plain v-permission="'角色管理-reset'">重置</el-button>
                            </div>
                            <div class="showpart hover handel" style="display: none;">
                                {{ actText }}
                                <local-svg iconClass="showdown" class="icon"></local-svg>
                            </div>
                        </div>
                    </el-form>
                </div>
                <div class="bottom-table">
                    <partTable :list="tableData" @bind="bindUser" @bindout="bindOut"></partTable>
                    <div class="pagetab">
                        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper" :total="total">
                        </el-pagination>
                    </div>
                    <addRole v-if="showadd" :show="showadd" :form="roleForm" @close="cancelAdd" :type="partType" @change="partChange"  @edit="editRoleDetail"></addRole>
                    <bindPart v-if="showbind" :show="showbind" :selectlist="selectList" @close="cancelBind" @bind="bindOk" :id="r_id"></bindPart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getrole,addrole,delrole,binduser,rolemenber,bindout,editrole} from '@/api/terrace'
import bindPart from '@/components/terrace/role/bindrole.vue'
import addRole from '@/components/terrace/role/addrole.vue'
import partTable from '@/components/terrace/role/roletable.vue'
import roleList from '@/components/terrace/role/role.vue'
export default {
    name: 'depart-terrace',
    data() {
        return {
            hide:false,
            roleForm:{},
            currentPage:1,
            total:0,
            pageSize:10,
            actText:'展开',
            showadd: false,
            showbind:false,
            roleList:[
            ],
            tableData: [
            ],
            form: {
                name: '',
                date: []
            },
            props: {
                label: 'name',
                children: 'zones'
            },
            selectList:[
                {
                    label:'全部',
                    value:''
                },
                {
                    label:'用户名',
                    value:'name'
                },
                {
                    label:'手机号',
                    value:'mobile'
                },
                {
                    label:'电子邮箱',
                    value:'email'
                },
            ],
            userData:[
            ],
            partType:1,//状态 1、添加  2、修改
            r_id:''
        }
    },
    components: {
        roleList,
        partTable,
        addRole,
        bindPart
    },
    mounted(){
        this.getRoleList()
    },
    methods: {
        getSearch(){
            this.currentPage = 1
            this.getList()
        },
        editRoleDetail(msg){
            let data = {
                id:msg.id,
                name:msg.name,
                intro:msg.intro,
                code:msg.code,
                sort:msg.sort,
            }
            editrole(data).then(res=>{
                if(res.code==200){
                    this.getRoleList()
                    this.showadd = false
                    this.$message({
                        type: 'success',
                        message: '修改成功！'
                    });
                }else{
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                }
            })
        },
        bindOut(msg){
            let data = {
                r_id:this.r_id,
                u_id:msg
            }
            bindout(data).then(res=>{
                if(res.code==200){
                    this.getList()
                    this.showadd = false
                    this.$message({
                        type: 'success',
                        message: '修改成功！'
                    });
                }else{
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                }
            })
        },
        cleanSearch(){
            this.form = {
                name:'',
                date:[],
            }
            this.getList('search')
        },
        getList(e){
            if(e){
                this.currentPage = 1
            }
            let data = {
                page:this.currentPage,
                count:this.pageSize,
                name:this.form.name,
                r_id:this.r_id
            }
            rolemenber(data).then(res=>{
                this.tableData = res.data.table
                let item = this.tableData
                let partarray = ''
                // for(var a=0;a<item.length;a++){
                //     if(item[a].b_name){
                //         for(var b=0;b<item[a].b_name.length;b++){
                //             if(b==item[a].b_name.length-1){
                //                 partarray = partarray + item[a].b_name[b]
                //             }else{
                //                 partarray = partarray + item[a].b_name[b]+'/'
                //             }
                //         }
                //     }
                //     item[a].partarray = partarray
                //     partarray = ''
                // }
                this.total = res.data.total
            })
        },
        checkList(msg){
            this.r_id = msg
            this.getList()
        },
        deleteRole(msg){
            let data = {
                id:msg.id
            }
            delrole(data).then(res=>{
                if(res.code==200){
                    this.getRoleList()  
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
            })
        },
        getRoleList(){
            getrole().then(res=>{
                this.roleList = res.data
                if(!this.r_id){
                    this.r_id = this.roleList[0].id
                }
                this.getList()
            })
        },
        editRole(msg){
            this.roleForm = msg
            this.showadd = true
            this.partType = 2
        },
        addNewRole(){
            this.showadd = true
            this.partType = 1
        },
        bindOk(msg){
            let data = {
                r_id:this.r_id,
                u_id:msg.toString()
            }
            binduser(data).then(res=>{
                if(res.code==200){
                    this.getList()
                    this.showbind = false
                    this.$message({
                        type: 'success',
                        message: '添加成功!'
                    });
                }else{
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                }
            })
        },
        cancelBind(){
            this.showbind = false
        },
        bindUser(){
            this.showbind = true
        },
        partChange(msg){
            addrole(msg).then(res=>{
                if(res.code==200){
                    this.getRoleList()  
                    this.showadd = false
                    this.$message({
                        type: 'success',
                        message: '添加成功!'
                    });
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
            })
        },
        cancelAdd(){
            this.showadd = false
        },
        handleSizeChange(val){
            this.pageSize = val
            this.currentPage = 1
            this.getList()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this.getList()
        },
    },

}

</script>

<style lang="scss" scoped>
* {
    box-sizing: content-box;
}

.home {
    padding: 24px;

    .main {
        display: flex;

        .left {
            height: 100%;
            width: 280px;
            background: white;
            border-radius: 4px;

            .top {
                height: 56px;
                border: 1px solid #F0F1F5;
                padding: 22px 20px 0 20px;
                display: flex;
                justify-content: space-between;
                box-sizing: border-box;

                .top-title {
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: bold;
                    color: #474952;
                }

                .top-add {
                    display: flex;
                    color: #475B93 !important;
                    .img1 {
                        width: 22px;
                        height: 20px;
                        color: #475B93;
                    }
                    span {
                        font-size: 14px;
                        line-height: 18px;
                        margin-left: 8px;
                    }
                }
                .top-add:hover{
                    color: #2C57FF !important;
                    .img1{
                        color: #2C57FF !important;
                    }
                    
                }
                .top-add:active{
                    color: #133CDC !important;
                    .img1{
                        color: #133CDC !important;
                    }
                }
            }
        }

        .right {
            width: calc(100% - 290px);
            margin-left: 10px;

            .top-serch {
                background: white;
                border-radius: 4px;

                .select-line {
                    display: flex;
                    padding: 16px 20px 0 0;
                }

                .line {
                    width: 100%;
                    height: 1px;
                    background: #F0F1F5;
                }

                .btn-line {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 8px 20px;
                    .showpart{
                        height: 100%;
                        display: flex;
                        align-items: baseline;
                        .icon{
                            margin-left: 6px;
                            width: 8px;
                            height: 8px;
                        }
                    }
                    .act {
                        background: #2C57FF;
                        color: white;
                    }

                    .btnpart button {
                        height: 32px;
                        width: 68px;
                        line-height: 32px;
                        padding: 0;

                    }
                }
            }

            .bottom-table {
                min-height: 660px;
                border-radius: 4px;
                background: white;
                margin-top: 14px;
                padding: 16px 20px;

                .pagetab {
                    text-align: right;
                    margin-top: 20px;
                }
            }
        }
    }
}</style>